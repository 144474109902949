import { makeStyles } from '@material-ui/core';
import { colors } from 'theme/colors';

export const useStyles = makeStyles((theme) => ({
  rightBtn: {
    marginLeft: 16,
  },
  logoAndNav: {
    display: 'flex',
    alignItems: 'center',
  },
  navBar: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  logo: {
    textDecoration: 'none',
    marginRight: 16,
    [theme.breakpoints.down('sm')]: {
      marginRight: 60,
    },
  },
  contained: {
    backgroundColor: colors.BACKGROUND,
    boxShadow: 'none',
    color: colors.BLACK,
  },
}));

/* eslint-disable max-len */
import React from 'react'

const FemaleFace = ({ ...props }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.99992 7.83301C5.53992 7.83301 5.16658 8.20634 5.16658 8.66634C5.16658 9.12634 5.53992 9.49967 5.99992 9.49967C6.45992 9.49967 6.83325 9.12634 6.83325 8.66634C6.83325 8.20634 6.45992 7.83301 5.99992 7.83301ZM9.99992 7.83301C9.53992 7.83301 9.16658 8.20634 9.16658 8.66634C9.16658 9.12634 9.53992 9.49967 9.99992 9.49967C10.4599 9.49967 10.8333 9.12634 10.8333 8.66634C10.8333 8.20634 10.4599 7.83301 9.99992 7.83301ZM7.99992 1.33301C4.31992 1.33301 1.33325 4.31967 1.33325 7.99967C1.33325 11.6797 4.31992 14.6663 7.99992 14.6663C11.6799 14.6663 14.6666 11.6797 14.6666 7.99967C14.6666 4.31967 11.6799 1.33301 7.99992 1.33301ZM7.99992 13.333C5.05992 13.333 2.66659 10.9397 2.66659 7.99967C2.66659 7.80634 2.67992 7.61301 2.69992 7.42634C4.27325 6.72634 5.51992 5.43967 6.17325 3.84634C7.37992 5.55301 9.36658 6.66634 11.6133 6.66634C12.1333 6.66634 12.6333 6.60634 13.1133 6.49301C13.2533 6.96634 13.3333 7.47301 13.3333 7.99967C13.3333 10.9397 10.9399 13.333 7.99992 13.333Z"
      fill="#535E83"
    />
    <path
      d="M1.33325 14.667V8.00034C1.33325 8.00034 5.66659 2.33368 6.33325 3.667C3.99992 5.66699 2.66659 8.00034 2.66659 14.667H1.33325Z"
      fill="#535E83"
    />
    <path
      d="M14.6667 14.6669V8.0002C14.6667 8.0002 14.0519 5.70574 12.6667 4.88278C11.2261 4.02686 9.00663 5.32041 8.66675 6.00016C12.0001 7.00016 12.3334 8.00021 12.3334 14.6669H14.6667Z"
      fill="#535E83"
    />
  </svg>
)

export default FemaleFace

import { useQuery } from '@apollo/client';

import { useAuth } from 'hooks/auth';
import { GET_BRAND_USER } from 'services/graphql/brand';
import { GET_CURRENT_TALENT } from 'services/graphql/talent';
import { routes } from 'router/routesList';
import { useErrorHandler } from 'hooks/app/errorHandler';

import { UserTypeEnum } from 'types/common';
import type { UseProfileBar } from './types';

const getUserOptions = (role: UserTypeEnum) => {
  switch (role) {
    case UserTypeEnum.brand:
      return {
        query: GET_BRAND_USER,
        link: routes.BRAND_PUBLIC_PAGE,
        user: 'getBrandUser',
      };
    default: {
      return {
        query: GET_CURRENT_TALENT,
        link: routes.TALENT_PUBLIC_PAGE,
        user: 'getCurrentTalent',
      };
    }
  }
};

const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env;

export const useProfileBar = (): UseProfileBar => {
  const { getRole } = useAuth();
  const userRole = getRole();
  const { redirectOnError } = useErrorHandler();
  const { query, link, user: userSchema } = getUserOptions(userRole);
  const { data: getCurrentUser = {} } = useQuery(query, redirectOnError); // TODO add query type
  const user = getCurrentUser[userSchema] || { firstName: '', lastName: '' };
  const avatarSrc = REACT_APP_AWSS3_URL_IMAGE_BEG + user.image;
  const getStr = (str: string | undefined | null): string => str ?? '';
  const username = `${getStr(user.firstName)} ${getStr(user.lastName)}`;
  const userInitials = `${getStr(user.firstName?.[0])} ${getStr(user.lastName?.[0])}`;

  return {
    publicLink: link,
    avatarSrc,
    username,
    userInitials,
  };
};

import { makeStyles } from '@material-ui/core'
import { colors } from '../../../../theme/colors'

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    marginBottom: theme.spacing(1),
  },
  reversedContainer: {
    flexDirection: 'row-reverse',
  },
  avatar: {
    alignSelf: 'flex-end',
    marginRight: theme.spacing(2),
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
  },
  reversedAvatar: {
    marginLeft: theme.spacing(2),
    marginRight: 0,
  },
  date: {
    marginBottom: theme.spacing(1),
    color: colors.GRAY_LIGHTEN,
    fontSize: 11,
    lineHeight: '13px',
    letterSpacing: -0.07,
  },
  reversedDate: {
    alignSelf: 'flex-end',
  },
  messageContainer: {
    padding: theme.spacing(1),
    background: colors.BACKGROUND,
    borderRadius: 4,
  },
  clientsMessage: {
    background: colors.PRIMARY,
    color: colors.WHITE,
  },
  confirmed: {
    background: `${colors.BACKGROUND3}!important`,
    border: '1px solid rgba(0, 129, 200, 0.1)!important',
  },
  text: {
    fontSize: 15,
    lineHeight: '22px',
    letterSpacing: -0.41,
    whiteSpace: 'break-spaces',
  },
  offerText: {
    marginBottom: theme.spacing(1.5),
    whiteSpace: 'break-spaces',
  },
  offerRedemption: {
    display: 'flex',
    alignItems: 'baseline',
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
  },
  btnDecline: {
    marginRight: theme.spacing(1),
    padding: '7px 50px',
    border: `1px solid ${colors.BORDER3}`,
    background: colors.BACKGROUND,
    color: colors.BLACK,
    fontSize: 12,
  },
  btnAccept: {
    padding: '7px 47px',
    background: colors.PRIMARY,
    color: colors.WHITE,
    fontSize: 12,
    '&:hover': {
      background: colors.PRIMARY_HOVERED,
    },
  },
  boldText: {
    fontWeight: 600,
    lineHeight: '24px',
    textTransform: 'uppercase',
    letterSpacing: '0.03em',
  },
  status: {
    marginLeft: theme.spacing(1.5),
    padding: '7px 12px',
    background: colors.BACKGROUND,
    color: colors.BLACK,
    border: '1px solid rgba(187, 187, 188, 0.1)',
    borderRadius: 4,
  },
  tax: {
    marginLeft: theme.spacing(1),
    opacity: 0.7,
  },
}))

import Intl from 'react-intl-universal'

export const intl = (key, params) => {
  if (!key) return
  if (typeof key !== 'string') return

  return Intl.get(key || '', params) || key
}

export const intlHtml = (key, params) => {
  if (!key || typeof key !== 'string') return

  return Intl.getHTML(key || '', params) || key
}

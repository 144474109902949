import React, { useState } from 'react'
import {
  Paper,
  Box,
  Toolbar,
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Typography,
} from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import clsx from 'clsx'
import { useQuery } from '@apollo/client'
import { useStyles } from './styles'
import { intl, roundTo2 } from '../../../helpers'
import { GET_GENERAL_ANALYTIC } from '../../../services/graphql/analytics'
import { Loader } from '../../../components/Loader'

export const Analytics = () => {
  const classes = useStyles({})
  const [dates, setDates] = useState({
    from: null,
    to: null,
  })

  const handleDateChange = (name) => (date) => {
    setDates((oldDates) => ({ ...oldDates, [name]: date }))
  }
  const { data, loading } = useQuery(GET_GENERAL_ANALYTIC, {
    variables: {
      filter: {
        dateFrom: dates.from?.setHours(0, 0, 0, 0),
        dateTo: dates.to?.setHours(23, 59, 59, 999),
      },
    },
  })

  return (
    <>
      <Paper className={classes.paper}>
        <Box>
          <Toolbar className={classes.toolBar}>
            {intl('SHARED.analytics')}{' '}
            <Box display="flex">
              <KeyboardDatePicker
                format="dd.MM.yy"
                name="startDate"
                fullWidth
                maxDate={dates.to}
                value={dates.from}
                clearable
                onChange={handleDateChange('from')}
                className={classes.datePicker}
                label={intl('ANALYTICS.dateFrom')}
              />
              <KeyboardDatePicker
                format="dd.MM.yy"
                name="startDate"
                className={classes.datePicker}
                minDate={dates.from}
                value={dates.to}
                onChange={handleDateChange('to')}
                clearable
                fullWidth
                label={intl('ANALYTICS.dateTo')}
              />
            </Box>
          </Toolbar>
        </Box>
        {loading ? (
          <Loader className={classes.loader} />
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{intl('ANALYTICS.item')}</TableCell>
                  <TableCell align="right">{intl('ANALYTICS.amount')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{intl('ANALYTICS.createdOpportunities')}</TableCell>
                  <TableCell align="right">
                    {data.getPlatformGeneralAnalytic?.createdOpportunities}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{intl('ANALYTICS.openOpportunities')}</TableCell>
                  <TableCell align="right">
                    {data.getPlatformGeneralAnalytic?.openOpportunities}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{intl('ANALYTICS.confirmed')}</TableCell>
                  <TableCell align="right">
                    {data.getPlatformGeneralAnalytic?.confirmedOpportunities}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{intl('ANALYTICS.cancelledByBrand')}</TableCell>
                  <TableCell align="right">
                    {data.getPlatformGeneralAnalytic?.canceledByBrandOpportunities}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{intl('ANALYTICS.cancelledByTalent')}</TableCell>
                  <TableCell align="right">
                    {data.getPlatformGeneralAnalytic?.canceledByTalentOpportunities}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{intl('ANALYTICS.payment')}</TableCell>
                  <TableCell align="right">
                    {data.getPlatformGeneralAnalytic?.paymentOpportunities}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{intl('ANALYTICS.done')}</TableCell>
                  <TableCell align="right">
                    {data.getPlatformGeneralAnalytic?.doneOpportunities}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
      {!loading && (
        <Paper className={clsx(classes.paper, classes.smallPaper)}>
          <Box>
            <Typography className={classes.average}> {intl('ANALYTICS.averageValue')}</Typography>
            <Typography className={classes.applicantsPerOpportunity}>
              {intl('ANALYTICS.applicantsPerOpportunity')}
            </Typography>
          </Box>
          <Typography className={classes.amount}>
            {roundTo2(data.getPlatformGeneralAnalytic?.applicantsPerOpportunities)}
          </Typography>
        </Paper>
      )}
    </>
  )
}

import React, { FC } from 'react';
import { Box, Hidden, Paper, Typography } from '@material-ui/core';

import { intl } from 'helpers';

import { Feedbacks } from 'components/Feedbacks';

import type { GetBrandById } from 'types/brand';

import { useStyles } from './styles';
import { BrandCard } from '../BrandCard';

type Props = {
  brand: GetBrandById;
};

export const BrandInfo: FC<Props> = ({ brand = {} }) => {
  const styles = useStyles();
  const feedbacks = brand.feedback;

  return (
    <Box>
      <Hidden smDown>
        <BrandCard
          id={brand.id}
          companyName={brand.companyName}
          country={brand.country}
          city={brand.city}
          image={brand.image}
          industry={brand.industry}
          isFavourite={brand.isFavourite}
          verificationStatus={brand.verificationStatus}
        />
      </Hidden>

      {/* About */}
      <Paper className={styles.aboutContainer} hidden={!brand.about}>
        <Typography className={styles.sectionHeader} component="h4">
          {intl('TALENT.BRANDS_PROFILE.about')}
        </Typography>
        <Typography className={styles.aboutBody}>{brand.about || intl('SHARED.notFilledYet')}</Typography>
      </Paper>

      {/* Feedbacks */}
      <Feedbacks feedbacks={feedbacks} />
    </Box>
  );
};

import React, { FC } from 'react';
import { Paper, Box, Avatar, Typography, IconButton, Hidden, Divider, Button } from '@material-ui/core';
import {
  LocationOn as LocationIcon,
  Favorite as FavoriteIcon,
  FavoriteBorder as FavoriteBorderIcon,
  Business as BusinessIcon,
} from '@material-ui/icons';
import { useMutation } from '@apollo/client';

import { getLetterForCompanyNameAvatar, intl } from 'helpers';
import { UserTypeEnum } from 'types/common';
import { GET_BRAND_BY_ID, UPDATE_USER_FAVOURITE } from 'services/graphql/brand';

import { useStyles } from './styles';
import type { Props } from './types';
import { Badge } from '../../../../../components/Badge';

export const BrandCard: FC<Props> = ({ companyName: nameOfTheCompany, id, image, city, country, industry, isFavourite, verificationStatus }) => {
  const styles = useStyles();

  const companyName = nameOfTheCompany || intl('SHARED.notFilledYet');
  const isHideCompanyInfo = !industry?.name && !country?.name;

  const [toggleFavourite] = useMutation(UPDATE_USER_FAVOURITE);

  const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env;

  // TODO: move to custom hook
  const handleClick = () => {
    toggleFavourite({
      variables: {
        entityType: UserTypeEnum.brand,
        entityId: id,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateUserFavourite: {
          __typename: 'UserFavourite',
          entityType: UserTypeEnum.brand,
          entityId: id,
        },
      },
      update: (cache) => {
        try {
          const data = cache.readQuery<any>({
            query: GET_BRAND_BY_ID,
            variables: { brandId: id },
          });
          const { getBrand } = data;

          cache.writeQuery({
            query: GET_BRAND_BY_ID,
            variables: { brandId: id },
            data: {
              getBrand: {
                ...getBrand,
                isFavourite: !getBrand.isFavourite,
              },
            },
          });
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('[Error]:', err);
        }
      },
    });
  };

  return (
    <Paper className={styles.container}>
      <Hidden only={['xs', 'md', 'lg', 'xl']}>
        <Avatar className={styles.avatar} src={REACT_APP_AWSS3_URL_IMAGE_BEG + image} alt={`${companyName}'s_avatar`}>
          {getLetterForCompanyNameAvatar(companyName)}
        </Avatar>
      </Hidden>
      <Box className={styles.content}>
        <Box className={styles.heading}>
          <Hidden smUp>
            <Avatar className={styles.avatarMobile} src={REACT_APP_AWSS3_URL_IMAGE_BEG + image} alt={`${companyName}'s_avatar`}>
              {getLetterForCompanyNameAvatar(companyName)}
            </Avatar>
          </Hidden>
          <Box className={styles.titleBox}>
            {nameOfTheCompany && <Typography className={styles.title}>{companyName}</Typography>}
            <Hidden xsDown>
              <IconButton className={styles.btnFavorite} onClick={handleClick}>
                {isFavourite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
              </IconButton>
            </Hidden>
          </Box>
        </Box>
        <Box className={styles.statusContainer}>
          <Badge text={verificationStatus} />
        </Box>
        <Hidden smUp>
          <Button variant="outlined" fullWidth onClick={handleClick}>
            {isFavourite ? intl('TALENT_CARD.removeFormFavorite') : intl('TALENT.BRANDS_PROFILE.addToFavourite')}
          </Button>
        </Hidden>
        <Hidden xsDown>
          <Divider hidden={isHideCompanyInfo} />
        </Hidden>
        <Box className={styles.mainInfoBox} hidden={isHideCompanyInfo}>
          <Box className={styles.infoBox}>
            <BusinessIcon fontSize="small" className={styles.icon} />
            <Typography variant="body2">{industry?.name || intl('SHARED.notFilledYet')}</Typography>
          </Box>
          <Box className={styles.infoBox}>
            <LocationIcon fontSize="small" className={styles.icon} />
            <Typography variant="body2">{country?.name && city?.name ? `${country?.name}, ${city?.name}` : intl('SHARED.notFilledYet')}</Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

import React, { FC } from 'react'
import { ListItem, ListItemAvatar, ListItemText, Avatar, Badge, Box, Typography } from '@material-ui/core'
import { CheckCircleOutline as CheckCircleOutlineIcon } from '@material-ui/icons'
import clsx from 'clsx'

import { getLetterForAvatar, clipName } from 'helpers'

import { Props } from './types'
import { useStyles } from './styles'

const ApplicantItem: FC<Props> = ({ applicant, handleClick, currentApplicant, unreadCount }) => {
  const classes = useStyles()
  return (
    <ListItem
      className={clsx(classes.listItem, {
        [classes.selected]: currentApplicant === applicant.applicationId,
      })}
      onClick={() => handleClick(applicant)}
    >
      <ListItemAvatar>
        <Badge
          classes={{
            badge: classes.badge,
          }}
          max={9}
          badgeContent={unreadCount?.unreadMessagesCount}
          color="error"
          overlap="circle"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Avatar src={`${process.env.REACT_APP_AWSS3_URL_IMAGE_BEG}${applicant.image}`} alt={`${applicant.lastName}'s_avatar`}>
            {getLetterForAvatar(applicant.firstName, applicant.lastName)}
          </Avatar>
        </Badge>
      </ListItemAvatar>
      <ListItemText className={classes.listItemText}>
        <Box className={classes.listItemTextWrapper}>
          <Typography>{clipName(applicant.firstName, applicant.lastName, 17)}</Typography>
          {applicant.isShortlisted && <CheckCircleOutlineIcon className={classes.checkIcon} />}
        </Box>
      </ListItemText>
    </ListItem>
  )
}

export default ApplicantItem

import React, { FC } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { intl } from 'helpers';
import { NotFoundImage } from 'assets/svg-js';

import { useStyles } from './styles';

const NotFoundPage: FC<{}> = () => {
  const styles = useStyles();

  return (
    <Box className={styles.container}>
      <Box className={styles.infoBox}>
        <Box className={styles.contentBox}>
          <Typography className={styles.text404}>404</Typography>
          <Typography className={styles.txtNotExists}>{intl('EMPTY_STATE.pageNotExists')}</Typography>
          <Button color="primary" variant="contained" className={styles.btnHomepage} component={Link} to="/">
            {intl('EMPTY_STATE.goToHomepage')}
          </Button>
        </Box>
      </Box>
      <NotFoundImage className={styles.icon} />
    </Box>
  );
};

export { NotFoundPage };

import React, { FC, useState, MouseEvent, useRef, useEffect } from 'react';
import { useMutation, ApolloCache } from '@apollo/client';
import { Paper, Box, Avatar, Divider, Typography, Menu, MenuItem, IconButton, Button, Collapse, Hidden } from '@material-ui/core';
import {
  MoreVert as MoreVertIcon,
  ThumbUp as ThumbUpIcon,
  ThumbUpOutlined as ThumbUpOutlinedIcon,
  Room as LocationIcon,
  Event as CalendarIcon,
  Category as TypeIcon,
  Launch as LaunchIcon,
} from '@material-ui/icons';
import clsx from 'clsx';
import { Link, useHistory } from 'react-router-dom';
import { format } from 'date-fns';

import { intl, formatNumber, getLetterForCompanyNameAvatar, getCurrencySymbol } from 'helpers';
import type { OpportunityFeed } from 'types/post';
import { ImagesCarousel } from 'components/ImagesCarousel';
import { routes } from 'router/routesList';
import { UPDATE_USER_FAVOURITE } from 'services/graphql/brand';

import { useStyles } from './styles';

type UserRole = 'talent' | 'brand' | 'admin' | 'none';

type Props = {
  opportunity: OpportunityFeed;
  userRole?: UserRole;
  className?: string;
  onSuccessLike?: (cache: ApolloCache<any>, entityId: string) => void;
};

const TEXT_BASE_HEIGHT = 100;
// const LONG_TEXT_LENGTH = 615;

const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env;

const OpportunityPost: FC<Props> = ({ opportunity, userRole, className, onSuccessLike }) => {
  const styles = useStyles();
  const { push } = useHistory();
  const ref = useRef<HTMLDivElement>(null);

  const [anchorUserMenu, setAnchorUserMenu] = useState<null | HTMLElement>(null);
  const [fullText, setFullText] = useState<boolean>(false);
  const [height, setHeight] = useState<number>(null);

  const [updateFavorite] = useMutation(UPDATE_USER_FAVOURITE, {
    variables: {
      entityType: 'opportunity',
      entityId: opportunity.id,
    },
    optimisticResponse: {
      __typename: 'Mutation',
      updateUserFavourite: {
        __typename: 'UserFavourite',
        entityType: 'opportunity',
        entityId: opportunity.id,
      },
    },
    update: (cache) => {
      try {
        onSuccessLike(cache, opportunity.id);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
  });
  const openUserMenu = !!anchorUserMenu;

  const formatDate = (date: string | Date | number) => format(new Date(date), 'dd.MM.yy');

  const getEditOpportunityRoute = (role: UserRole) =>
    ({
      talent: `${routes.TALENT_EDIT_OPPORTUNITY}/${opportunity.id}`,
      admin: `${routes.ADMIN_EDIT_OPPORTUNITY}/${opportunity.id}`,
      brand: `${routes.BRAND_EDIT_OPPORTUNITY}/${opportunity.id}`,
      none: routes.LOGIN,
    }[role] || routes.LOGIN);

  const getOpportunityRoute = (role: UserRole) =>
    ({
      talent: `/${routes.TALENT}/${routes.OPPORTUNITIES}/${opportunity.id}/details`,
      admin: `/${routes.ADMIN}/${routes.OPPORTUNITIES}/${opportunity.id}/details`,
      brand: `/${routes.BRAND}/${routes.OPPORTUNITIES}/${opportunity.id}/details`,
      none: routes.LOGIN,
    }[role] || routes.LOGIN);

  const getOpportunityPrice = (role: UserRole) =>
    ({
      talent: opportunity.redemption.talentSalary,
      admin: opportunity.redemption.budget,
      brand: opportunity.redemption.budget,
      none: opportunity.redemption.talentSalary,
    }[role] || opportunity.redemption.talentSalary);

  const toggleText = () => setFullText((old) => !old);

  const handleUserMenuClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorUserMenu(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setAnchorUserMenu(null);
  };

  const onLike = () => {
    if (userRole === 'none') {
      push(routes.LOGIN);
    } else {
      updateFavorite();
    }
  };

  useEffect(() => {
    setHeight(ref.current.clientHeight);
  }, [opportunity.description]);

  return (
    <Paper className={clsx(styles.container, className)}>
      <Hidden only="xs">
        <Box className={styles.heading}>
          <Box className={styles.userBox}>
            <Avatar src={REACT_APP_AWSS3_URL_IMAGE_BEG + opportunity.brand.image}>{getLetterForCompanyNameAvatar(opportunity.brand.name)}</Avatar>
            <Typography className={styles.username}>{opportunity.brand.name}</Typography>
          </Box>
          <Box>
            <Typography className={styles.postedAt} variant="caption">
              {`${intl('POST.posted')} ${formatDate(opportunity.createdAt)}`}
            </Typography>
            {opportunity.canInteract && (
              <>
                <IconButton
                  aria-label="user-actions"
                  aria-controls={`post-${opportunity?.id}-menu`}
                  aria-haspopup="true"
                  onClick={handleUserMenuClick}
                >
                  <MoreVertIcon
                    className={clsx({
                      [styles.opened]: openUserMenu,
                    })}
                  />
                </IconButton>
                <Menu
                  classes={{ list: styles.userListMenu }}
                  id={`post-${opportunity?.id}-menu`}
                  anchorEl={anchorUserMenu}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  open={openUserMenu}
                  onClose={handleUserMenuClose}
                >
                  <MenuItem component={Link} to={getEditOpportunityRoute(userRole)}>
                    {intl('POST.edit')}
                  </MenuItem>
                </Menu>
              </>
            )}
          </Box>
        </Box>
      </Hidden>
      <Hidden smUp>
        <Box className={styles.headingMobile}>
          <Box className={styles.headingMobileWrapper}>
            <Avatar className={styles.avatar} src={REACT_APP_AWSS3_URL_IMAGE_BEG + opportunity.brand.image}>
              {getLetterForCompanyNameAvatar(opportunity.brand.name)}
            </Avatar>
            <Box>
              <Typography>{opportunity.brand.name}</Typography>
              <Typography className={styles.postedAt} variant="caption">{`${intl('POST.posted')} ${formatDate(opportunity.createdAt)}`}</Typography>
            </Box>
          </Box>
          {opportunity.canInteract && (
            <>
              <IconButton aria-label="user-actions" aria-controls={`post-${opportunity?.id}-menu`} aria-haspopup="true" onClick={handleUserMenuClick}>
                <MoreVertIcon
                  className={clsx({
                    [styles.opened]: openUserMenu,
                  })}
                />
              </IconButton>
              <Menu
                classes={{ list: styles.userListMenu }}
                id={`post-${opportunity?.id}-menu`}
                anchorEl={anchorUserMenu}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                open={openUserMenu}
                onClose={handleUserMenuClose}
              >
                <MenuItem component={Link} to={getEditOpportunityRoute(userRole)}>
                  {intl('POST.edit')}
                </MenuItem>
              </Menu>{' '}
            </>
          )}
        </Box>
      </Hidden>
      <Divider />
      <Hidden only="xs">
        <Box className={styles.opportunityContent}>
          <Box className={styles.opportunityInfo}>
            <Typography component={Link} to={getOpportunityRoute(userRole)} className={styles.title}>
              {opportunity.name} <LaunchIcon fontSize="small" className={styles.launchIcon} />
            </Typography>
            <Typography className={styles.price}>{`${getCurrencySymbol(opportunity.redemption.currency.code)} ${formatNumber(
              getOpportunityPrice(userRole),
            )} `}</Typography>
          </Box>
          <Box className={styles.opportunityInfo}>
            <Box component="ul" className={styles.list}>
              <Box component="li" className={styles.item}>
                <TypeIcon fontSize="small" className={styles.icon} />
                <Typography variant="caption">{opportunity.type.name}</Typography>
              </Box>
              <Box component="li" className={styles.item}>
                <LocationIcon fontSize="small" className={styles.icon} />
                <Typography variant="caption">{`${opportunity.country.name}, ${opportunity.city.name}`}</Typography>
              </Box>
              <Box component="li" className={styles.item}>
                <CalendarIcon fontSize="small" className={styles.icon} />
                <Typography variant="caption">{`${formatDate(opportunity.startDate)} - ${formatDate(opportunity.endDate)}`}</Typography>
              </Box>
            </Box>
            <Typography variant="caption">{opportunity.redemption.isTaxed ? intl('SHARED.taxIncluded') : intl('SHARED.taxExcluded')}</Typography>
          </Box>
        </Box>
      </Hidden>
      <Hidden smUp>
        <Box className={styles.opportunityMobile}>
          <Typography component={Link} to={getOpportunityRoute(userRole)} className={styles.mobileTitle}>
            {opportunity.name} <LaunchIcon fontSize="small" className={styles.launchIcon} />
          </Typography>
          <Box className={styles.opportunityMobileData}>
            <Typography>{`${getCurrencySymbol(opportunity.redemption.currency.code)} ${formatNumber(getOpportunityPrice(userRole))} `}</Typography>
            <Typography>{`${opportunity.country.name}, ${opportunity.city.name}`}</Typography>
          </Box>
        </Box>
      </Hidden>
      <Box className={styles.content}>
        <Box className={styles.textBox}>
          {height > TEXT_BASE_HEIGHT ? (
            <>
              <Collapse in={fullText} collapsedHeight={TEXT_BASE_HEIGHT} classes={{ container: styles.collapse }}>
                <Typography className={styles.text} variant="body2">
                  {opportunity.description}
                </Typography>
              </Collapse>
              <Button className={styles.btnMore} color="primary" variant="text" onClick={toggleText}>
                {fullText ? intl('POST.showLess') : intl('POST.showMore')}
              </Button>
            </>
          ) : (
            <Typography className={styles.shortText} variant="body2" ref={ref}>
              {opportunity.description ?? intl('SHARED.noDescription')}
            </Typography>
          )}
        </Box>
        {opportunity.images?.length > 0 && <ImagesCarousel images={opportunity.images} />}
      </Box>
      <Box className={styles.actions} component="ul">
        <Box className={styles.socialItem} component="li">
          <IconButton onClick={onLike}>
            {opportunity.isLiked ? <ThumbUpIcon className={styles.filledIcon} /> : <ThumbUpOutlinedIcon className={styles.filledIcon} />}
          </IconButton>
          <Typography variant="body2">{opportunity.feedMeta?.likesCounter ? formatNumber(opportunity.feedMeta?.likesCounter) : null}</Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export { OpportunityPost };

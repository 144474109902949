import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from 'theme/colors';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    maxHeight: 240,
    padding: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      height: 250,
      padding: theme.spacing(2),
    },
  },
  avatar: {
    marginRight: theme.spacing(3),
    width: 176,
    height: 176,
    textTransform: 'uppercase',
  },
  avatarMobile: {
    height: 60,
    width: 60,
  },
  content: {
    flexGrow: 1,
  },
  headingMobile: {
    display: 'flex',
  },
  heading: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {

      justifyContent: 'flex-start',
      marginBottom: theme.spacing(2),
    },
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
  },
  btnFavorite: {},
  title: {
    marginRight: theme.spacing(1),
    fontSize: 20,
    fontWeight: 500,
    marginLeft: 16,
  },
  joinedText: {
    color: colors.TEXT_GRAY,
  },
  contentMobile: {
    marginTop: theme.spacing(2),
  },
  mainInfoBox: {
    marginTop: theme.spacing(2),
  },
  infoBox: {
    display: 'flex',
    alignItems: 'flex-end',
    '&:nth-child(2)': {
      marginTop: 12,
    },
  },
  icon: {
    marginRight: theme.spacing(1),
    fill: colors.PRIMARY_INPUT_HOVERED,
  },
  statusContainer: {
    marginBottom: 16,
  },
}));

export { useStyles };

import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    maxWidth: 392,
  },
  actionsContainer: {
    marginTop: -theme.spacing(4),
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  btnCancel: {
    padding: '5px 50px',
    [theme.breakpoints.only('xs')]: {
      marginRight: theme.spacing(1),
      padding: '5px 40px',
    },
  },
  btnYes: {
    padding: '5px 67px',
    [theme.breakpoints.only('xs')]: {
      padding: '5px 50px',
    },
  },
}))

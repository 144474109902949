const {
  REACT_APP_STRIPE_PK,
  REACT_APP_STRIPE_CA,
  REACT_APP_STRIPE_CONNECT_REDIRECT_URL,
  REACT_APP_STRIPE_CONNECT_ENDPOINT,
} = process.env
export default {
  STRIPE_CA: REACT_APP_STRIPE_CA || null,
  STRIPE_PK: REACT_APP_STRIPE_PK || null,
  STRIPE_CONNECT_ENDPOINT:
    REACT_APP_STRIPE_CONNECT_ENDPOINT || 'https://connect.stripe.com/express/oauth/authorize',
  STRIPE_CONNECT_REDIRECT_URL:
    REACT_APP_STRIPE_CONNECT_REDIRECT_URL || 'https://stage.sportendorse.com/talent/profile/private-info',
  STRIPE_PLATFORM_FEE: 20,
}

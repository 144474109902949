import React from 'react'
import { Typography, Box } from '@material-ui/core'
import clsx from 'clsx'
import { useStyles } from './styles'

const TabPanel = ({ children, className, value, index, ...other }) => {
  const classes = useStyles()

  return (
    <Typography
      component="section"
      role="tabpanel"
      hidden={value !== index}
      id={`opportunity-tabpanel-${index}`}
      aria-labelledby={`opportunity-tabpanel-tab-${index}`}
      {...other}
    >
      <Box className={clsx(classes.container, className)}>{children}</Box>
    </Typography>
  )
}

export const a11yProps = (index) => ({
  id: `opportunity-tabpanel-tab-${index}`,
  'aria-controls': `opportunity-tabpanel-tabpanel-${index}`,
})

export default TabPanel

import { gql } from '@apollo/client'

const GET_PROFIT_MARGINS = gql`
  query getProfitMargins {
    getProfitMargins {
      firstRange {
        percentage
        range {
          from
          to
        }
      }
      secondRange {
        percentage
        range {
          from
          to
        }
      }
      thirdRange {
        percentage
        range {
          from
          to
        }
      }
    }
  }
`

const CREATE_PROFIT_MARGINS = gql`
  mutation createProfitMargins($data: ProfitMarginsInput!) {
    createProfitMargins(data: $data) {
      firstRange {
        percentage
        range {
          from
          to
        }
      }
      secondRange {
        percentage
        range {
          from
          to
        }
      }
      thirdRange {
        percentage
        range {
          from
          to
        }
      }
    }
  }
`

const CREATE_VERIFICATION_REQUEST = gql`
  mutation createVerificationReques($data: CreateVerificationRequestInput!) {
    createVerificationRequest (data: $data) {
        verificationStatus
        identification
    }
  }
`

export { GET_PROFIT_MARGINS, CREATE_PROFIT_MARGINS, CREATE_VERIFICATION_REQUEST }

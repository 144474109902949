import { makeStyles } from '@material-ui/core';
import { colors } from '../../../theme/colors';

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  box: {
    marginTop: 5,
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  fullHeight: {
    height: '100%',
  },
  emptyBox: {
    marginTop: -60,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyText: {
    marginTop: -86,
    marginBottom: theme.spacing(4),
    fontWeight: 500,
    fontSize: 24,
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
      marginTop: -100,
      marginBottom: theme.spacing(2),
    },
  },
  emptyIcon: {
    [theme.breakpoints.only('xs')]: {
      width: 305,
    },
  },
  tabs: {
    [theme.breakpoints.only('xs')]: {
      flexGrow: 1,
    },
  },
  indicator: {
    height: 3,
  },
  divider: {
    marginTop: -1,
  },
  loader: {
    height: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2.5),
  },
  fab: {
    alignSelf: 'flex-end',
    position: 'sticky',
    marginTop: -77,
    right: theme.spacing(2),
    bottom: theme.spacing(3),
    color: colors.WHITE,
  },
  btnNewOpportunity: {
    paddingRight: theme.spacing(2),
    backgroundColor: colors.TRANSPARENT,
    color: colors.PRIMARY_HOVERED,
  },
  btnShowMore: {
    display: 'block',
    margin: '0 auto',
    marginBottom: theme.spacing(4),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    border: `1px solid ${colors.BACKGROUND2}`,
    [theme.breakpoints.down('xs')]: {
      display: 'inline-block',
      width: '100%',
    },
  },
  hidden: {
    display: 'none',
  },
  mobileContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

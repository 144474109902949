import React from 'react'
import MuiChipInput from 'material-ui-chip-input'
import { Chip, IconButton } from '@material-ui/core'
import { ClearRounded as ClearRoundedIcon } from '@material-ui/icons'
import { useStyles } from './styles'

export const ChipInput = ({ value, handleChange, ...props }) => {
  const styles = useStyles({})

  const chipRenderer = ({ text, handleDelete = () => {} }) => (
    <Chip
      key={text}
      label={text}
      className={styles.chip}
      deleteIcon={
        <IconButton classes={{ label: styles.deleteIconLabel }} className={styles.deleteIcon}>
          <ClearRoundedIcon className={styles.icon} />
        </IconButton>
        }
      onDelete={handleDelete}
    />
  )

  return (
    <MuiChipInput {...props} chipRenderer={chipRenderer} value={value} onChange={handleChange} />
  )
}

import { makeStyles } from '@material-ui/core';
import { colors } from 'theme/colors';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: 375,
    maxHeight: 500,
    overflow: 'auto',
    boxShadow: '0px 6px 20px rgba(2, 1, 86, 0.05)',
    borderRadius: 8,
    [theme.breakpoints.only('xs')]: {
      width: 'auto',
      maxHeight: 300,
    },
  },
  header: {
    position: 'sticky',
    top: 0,
    padding: '0px 17px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: colors.PRIMARY,
    color: colors.WHITE,
    zIndex: 3,
  },
  title: {
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '0.03em',
    textTransform: 'uppercase',
  },
  closeIco: {
    fill: colors.WHITE,
  },
  list: {
    height: 500,
  },
  errorBox: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  warnIcon: {
    fill: colors.ERROR,
    marginRight: theme.spacing(1),
  },
}));

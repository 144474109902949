import React, { useState } from 'react';
import * as Yup from 'yup';
import { Box, Paper, TextField, Button, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import Intl from 'react-intl-universal';

import { useAuth } from 'hooks/auth';
import { intl } from 'helpers';
import { SportEndorse } from 'components/SportEndorse';
import { RestoreIcon } from 'assets/svg-js';
import { routes } from 'router/routesList';

import { useStyles } from './styles';

const RestoreSchema = Yup.object({
  email: Yup.string().email('ERRORS.incorrectEmail').required('ERRORS.emptyField'),
});

const errorMap = {
  'Username/client id combination not found.': 'ERRORS.emailIsNotFound',
};

export const Restore = () => {
  const styles = useStyles({});
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { forgotPassword } = useAuth();

  const goToLogIn = () => history.push(routes.LOGIN);

  const handleRestore = async (values, formContext) => {
    const lowerCaseEmail = String(values.email).toLowerCase();
    setLoading(true);
    const { error } = await forgotPassword({
      email: lowerCaseEmail,
      redirectUrl: `/${routes.CREATE_PASSWORD}?email=${lowerCaseEmail}`,
    });
    setLoading(false);
    if (error) {
      if (error.name === 'UserNotFoundException') {
        formContext.setFieldError('email', errorMap['Username/client id combination not found.']);
      } else {
        formContext.setFieldError('email', error.message);
      }
    }
  };

  const { handleChange, values, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: RestoreSchema,
    onSubmit: handleRestore,
  });

  return (
    <Box display="flex" alignItems="center" justifyContent="center" minHeight="100vh" padding="30px" paddingX="8px" flexDirection="column">
      <SportEndorse />
      <Paper className={styles.paper} component="form" onSubmit={handleSubmit}>
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" marginBottom="18px">
          <RestoreIcon />
          <Typography className={styles.restoreText} variant="h5">
            {intl('RESTORE.restorePassword')}
          </Typography>
          <Typography className={styles.text}>{intl('RESTORE.enterEmail')}</Typography>
          <Typography className={styles.text}> {Intl.getHTML('RESTORE.instructions')}</Typography>
        </Box>
        <TextField
          name="email"
          label={intl('SHARED.email')}
          className={styles.input}
          value={values.email}
          onChange={handleChange}
          error={errors.email && touched.email}
          helperText={intl(touched.email && errors.email)}
        />
        <Button type="submit" variant="contained" color="primary" disabled={loading} className={styles.send}>
          {intl('RESTORE.send')}
        </Button>
        <Button onClick={goToLogIn} variant="outlined">
          {intl('AUTH.logIn')}
        </Button>
      </Paper>
    </Box>
  );
};

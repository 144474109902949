import React, { useState } from 'react';
import { Grid, Hidden } from '@material-ui/core';

import Chat, { ChatWidget, ChatFAB } from 'components/Chat';
import { Loader } from 'components/Loader';

import { useStyles } from './styles';
import TalentCard from './components/TalentCard';
import BrandCard from './components/BrandCard';
import { STATUSES, TALENT_STATUS } from '../../../../../constants';

const Deal = ({
  applicants,
  isTalentView,
  isApplied,
  isRated,
  conversationData,
  subscribeToMore,
  fetchMore,
  getConversation,
  status,
  currentApplicant,
  profile,
  brandId,
  finalCondition,
  loadingConversation,
  opportunityId,
  profitMargins,
  togglePaymentPopup,
}) => {
  const [open, setOpen] = useState(false);
  const styles = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const chatProps = {
    conversation: conversationData?.getConversation,
    subscribeToMore,
    fetchMore,
    getConversation,
    currentApplicant,
    disabled:
      status.name === STATUSES.CANCELED_BY_ADMIN.value ||
      status.name === STATUSES.CANCELED_BY_ADMIN_PAYMENT.value ||
      status.name === STATUSES.CANCELED_BY_BRAND.value ||
      status.name === STATUSES.CANCELED_BY_TALENT.value ||
      status.name === STATUSES.DONE.value ||
      status.name === STATUSES.EXPIRED.value ||
      isApplied === TALENT_STATUS.DECLINED,
    opportunityId,
    opportunityStatus: status.name,
    brandId,
  };

  const chatWidgetProps = {
    onClose: handleClose,
    open,
    conversation: conversationData?.getConversation,
    subscribeToMore,
    disabled:
      status.name === STATUSES.CANCELED_BY_BRAND.value ||
      status.name === STATUSES.CANCELED_BY_TALENT.value ||
      status.name === STATUSES.DONE.value ||
      isApplied === TALENT_STATUS.DECLINED,
    fetchMore,
    getConversation,
    currentApplicant,
    opportunityId,
    opportunityStatus: status.name,
  };

  const talentProps = {
    status,
    profile,
    isRated,
    finalCondition,
    profitMargins,
    togglePaymentPopup,
    opportunityId,
    applicants,
  };

  const brandProps = {
    profile,
    brandFrontman: conversationData?.getConversation?.participants?.find((i) => i.type === 'BRAND'),
    brandId,
    isTalentView,
  };

  return (
    <Grid container className={styles.container}>
      <Grid item lg={3} md={4} className={styles.details}>
        {!isTalentView ? <TalentCard {...talentProps} /> : <BrandCard {...brandProps} />}
      </Grid>
      <Grid item lg={9} md={8} className={styles.chat}>
        {loadingConversation ? <Loader /> : <Chat {...chatProps} />}
      </Grid>
      <Hidden mdUp>
        <ChatFAB onClick={handleClickOpen} unreadCount={conversationData?.getConversation?.unreadMessagesCount} />
      </Hidden>
      <ChatWidget {...chatWidgetProps} />
    </Grid>
  );
};

export default Deal;

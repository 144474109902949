import React from 'react';
import { Box, Button, Hidden } from '@material-ui/core';

import { intl } from '../../helpers';
import { useAuth } from 'hooks/auth';

import { SportEndorse } from '../SportEndorse';

import { useStyles } from './styles';

export const Footer = ({ handleOpenModal }) => {
  const { getAssumedRole } = useAuth();
  const styles = useStyles({});

  return (
    <Hidden smDown>
      <Box component="footer" className={styles.footer}>
        <Box className={styles.footerWrapper}>
          <SportEndorse />
          <Box>
            {getAssumedRole() && (
              <Button disableRipple className={styles.footerLink} onClick={handleOpenModal}>
                {intl('SIGN_UP.introVideo')}
              </Button>
            )}
            <a className={styles.footerLink} href="https://sportendorse.com/our-story/">
              {intl('FOOTER.aboutUs')}
            </a>
            <a className={styles.footerLink} href="https://sportendorse.com/faqs/">
              {intl('FOOTER.help')}
            </a>
            <a className={styles.footerLink} href="https://sportendorse.com/contact-us">
              {intl('FOOTER.contactUs')}
            </a>
          </Box>
        </Box>
      </Box>
    </Hidden>
  );
};

import React, { FC } from 'react';
import { Box, Typography, Button } from '@material-ui/core';

import { intl } from 'helpers';
import { HourglassIcon } from 'assets/svg-js/index';

import { Modal } from 'components/Modal';

import { useStyles } from './styles';
import type { Props } from './types';

const PendingVerificationModal: FC<Props> = ({ open, loading, handleClose }) => {
  const styles = useStyles();
  const Actions = () => (
    <Button onClick={handleClose} fullWidth disabled={loading} variant="contained" color="primary">
      {intl('SHARED.ok')}
    </Button>
  );

  return (
    <Modal isOpen={open} handleClose={handleClose} title={null} disabledBorder className={styles.modal} dialogActionsComponent={<Actions />}>
      <Box className={styles.container}>
        <Box className={styles.iconBox}>
          <HourglassIcon />
        </Box>
        <Typography className={styles.txt}>{intl('PENDING_VERIFICATION.manualVerification')}</Typography>
      </Box>
    </Modal>
  );
};

export { PendingVerificationModal };

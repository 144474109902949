import React, { FC } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useAuth } from '../hooks/auth';
import { routes } from './routesList';

type Props = {
  component?: FC<any>;
  componentProps?: any;
  exact?: boolean;
  path?: string;
  history?: any;
};

export const PrivateRoute: FC<Props> = ({ component: Component, componentProps, exact, path }) => {
  const { isAuthenticated } = useAuth();

  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => (isAuthenticated() ? <Component {...props} {...componentProps} /> : <Redirect to={routes.LOGIN} />)}
    />
  );
};

import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../../theme/colors';

export const useStyles = makeStyles({
  profileBarWrapper: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    width: 280,
    minHeight: 240,
    padding: '0 32px',
    paddingBottom: 12,
    borderRadius: 8,
    background: colors.WHITE,
    boxShadow: '0px 6px 20px rgba(2, 1, 86, 0.05)',
    alignSelf: 'flex-start',
  },
  avatarContainer: {
    width: 176,
    height: 176,
    fontSize: 72,
    marginBottom: 32,
    margin: '32px auto',
  },
  buttonsContainer: {
    marginTop: 8,
  },
  tab: {
    borderBottom: `1px solid ${colors.BORDER}`,
  },
  brandCardContainer: {
    height: 240,
  },
});

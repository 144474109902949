import { gql } from '@apollo/client';

const ON_CREATE_CONVERSATION_MESSAGE = gql`
  subscription onCreateConversationMessage($conversationId: ID!) {
    onCreateConversationMessage(conversationId: $conversationId) {
      id
      conversationId
      sender {
        firstName
        id
        image
        lastName
        type
      }
      body
      action
      applicationId
      coverLetter
      offerId
      offer {
        confirmedAt
        declinedAt
        description
        id
        isTaxed
        offerAmount
        talentSalary
        opportunityId
        talentId
      }
      createdAt
    }
  }
`;

export { ON_CREATE_CONVERSATION_MESSAGE };

import { makeStyles } from '@material-ui/core'
import { colors } from '../../../../../theme/colors'

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 32,
    marginBottom: 16,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  sectionHeading: {
    color: colors.TEXT,
    fontWeight: 600,
    textTransform: 'uppercase',
    marginBottom: 20,
  },
  video: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },

  videoItem: {
    maxWidth: 312,
    width: 312,
    height: 186,
    borderRadius: 4,
    marginBottom: 16,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
}))

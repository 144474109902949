import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from 'theme/colors';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  box: {
    padding: '7px 12px',
    borderRadius: 4,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 1,
      paddingRight: theme.spacing(1),
      paddingBottom: 1,
      paddingLeft: theme.spacing(1),
    },
    whiteSpace: 'nowrap',
  },
  iconBox: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  payment: {
    background: 'rgba(255, 81, 81, 0.2)',
    border: '1px solid rgba(255, 81, 81, 0.1)',
  },
  done: {
    background: 'rgba(131, 191, 36, 0.3)',
    border: '1px solid rgba(131, 191, 36, 0.1)',
  },
  waitingForPayment: {
    backgroundColor: '#FEF7E7',
    border: '1px solid rgba(246, 176, 20, 0.1)',
  },
  canceled: {
    backgroundColor: '#F6F6F6',
    border: '1px solid rgba(187, 187, 188, 0.1)',
  },
  open: {
    backgroundColor: '#E6F2F9',
    border: '1px solid rgba(0, 129, 200, 0.1)',
  },
  warningIcon: {
    fill: colors.ERROR,
  },
}));

export { useStyles };

import React from 'react';

const VerifiedIcon = ({ className = '' }) => {
  return (
    <svg width="66" height="64" viewBox="0 0 66 64" className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M66 32.2061L58.68 23.8361L59.7 12.7661L48.87 10.3061L43.2 0.706055L33 5.08605L22.8 0.706055L17.13 10.2761L6.3 12.7061L7.32 23.8061L0 32.2061L7.32 40.5761L6.3 51.6761L17.13 54.1361L22.8 63.7061L33 59.2961L43.2 63.6761L48.87 54.1061L59.7 51.6461L58.68 40.5761L66 32.2061ZM25.14 44.2361L18 37.0361C16.83 35.8661 16.83 33.9761 18 32.8061L18.21 32.5961C19.38 31.4261 21.3 31.4261 22.47 32.5961L27.3 37.4561L42.75 21.9761C43.92 20.8061 45.84 20.8061 47.01 21.9761L47.22 22.1861C48.39 23.3561 48.39 25.2461 47.22 26.4161L29.46 44.2361C28.23 45.4061 26.34 45.4061 25.14 44.2361Z"
        fill="#838CA7"
      />
    </svg>
  );
};

export { VerifiedIcon };

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  brandsPageWrapper: {
    position: 'relative',
    marginTop: 24,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  contentContainer: {
    paddingLeft: 24,
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
}));

import { makeStyles, darken, Theme } from '@material-ui/core';
import { colors } from 'theme/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  description: {
    fontSize: 14,
    whiteSpace: 'break-spaces',
  },
  mobileDesc: {
    height: 155,
    padding: '16px 0px',
    marginBottom: theme.spacing(2),
    overflow: 'auto',
  },
  infoBox: {
    margin: '16px 0px',
  },
  imagesBox: {
    marginBottom: theme.spacing(2),
  },
  mobileTitle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  titleTalent: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  mobileTitleTypo: {
    fontWeight: 600,
    lineHeight: '22px',
    marginRight: theme.spacing(0.5),
  },
  mobileDivider: {
    marginBottom: theme.spacing(2),
  },
  details: {
    minWidth: 280,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3),
    boxShadow: '0px 6px 20px rgba(2, 1, 86, 0.05)',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      overflow: 'auto',
    },
  },
  flexed: {
    display: 'flex',
    width: '100%',
  },
  textContainer: {
    width: '100%',
    height: 518,
    overflow: 'auto',
    padding: 32,
  },
  title: {
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  emptyBlock: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 18,
    opacity: 0.5,
  },
  detailsItem: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,
    marginBottom: theme.spacing(3),
    '& svg': {
      marginRight: theme.spacing(1),
      fill: '#535E83',
    },
  },
  budgetInfo: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    // justifyContent: 'space-between',
    marginTop: 16,
    marginBottom: theme.spacing(3),
    '& span': {
      marginRight: 8,
    },
  },
  brandIcon: {
    marginRight: theme.spacing(2),
  },
  margedTop: {
    marginTop: theme.spacing(3),
  },
  mobileBrand: {
    marginTop: theme.spacing(2),
  },
  descriptionTitle: {
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: '0.03em',
  },
  brandLargeIcon: {
    height: 52,
    width: 52,
    marginRight: 12,
  },
  budget: {
    alignItems: 'baseline',
    // marginTop: theme.spacing(3),
    marginBottom: theme.spacing(0),
    fontWeight: 600,
    whiteSpace: 'nowrap',
    '& small': {
      // marginLeft: theme.spacing(1),
      fontSize: 12,
      fontWeight: 400,
    },
  },
  postedDate: {
    opacity: 0.4,
  },
  btnEdit: {
    fontSize: 12,
    border: `1px solid ${colors.BORDER3}`,
  },
  btnCancel: {
    fontSize: 12,
    marginTop: theme.spacing(2),
    border: `1px solid ${colors.BORDER3}`,
  },
  btnPay: {
    fontSize: 12,
  },
  btnApply: {
    fontSize: 12,
    backgroundColor: colors.PRIMARY,
    color: colors.WHITE,
    letterSpacing: '0.03em',
    '&:hover': {
      backgroundColor: `${darken(colors.PRIMARY, 0.2)}`,
    },
    '&:disabled': {
      color: colors.WHITE,
      opacity: 0.3,
    },
  },
  modalBox: {
    maxWidth: 384,
  },
  modalTitleBox: {
    padding: '16px 32px',
    paddingTop: theme.spacing(3),
  },
  modalContentBox: {
    margin: -theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  modalIconBox: {
    marginBottom: theme.spacing(4),
    background: colors.BACKGROUND,
    padding: '24px 42px',
    borderRadius: '100%',
  },
  modalText: {
    fontSize: 18,
    lineHeight: '27px',
    textAlign: 'center',
  },
  modalTextMargin: {
    marginBottom: theme.spacing(3),
  },
  cancellationText: {
    '& a': {
      color: colors.PRIMARY,
      textDecoration: 'none',
    },
  },
  cancelTalentMessage: {
    color: colors.SECONDARY_TEXT,
    fontSize: 12,
    textAlign: 'center',
    '& a': {
      color: colors.SECONDARY_TEXT,
    },
  },
  errorText: {
    color: colors.ERROR,
  },
  btnYes: {
    padding: '11px 66px',
    background: colors.PRIMARY,
    color: colors.WHITE,
    '&:hover': {
      background: colors.PRIMARY_HOVERED,
    },
  },
  btnNo: {
    border: `1px solid ${colors.BORDER3}`,
    padding: '11px 66px',
  },
  taxInfo: {
    display: 'flex',
    alignItems: 'center',
  },
}));

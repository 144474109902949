import React, { FC, useState, useCallback } from 'react';
import { debounce, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';

import { intl } from 'helpers';

import { DateRangeFilter } from 'components/DateRangeFilter';
import { ExportToCsvIcon, FilterIcon } from 'assets/svg-js';

import { useStyles } from './styles';

import type { Props } from './types';

export const BrandManagerFilters: FC<Props> = ({ saveToCSVLink, applyFilter, isDisabled, onSearchChange }) => {
  const [anchorFilter, setAnchorFilter] = useState(null);
  const styles = useStyles({});
  const [search, setSearch] = useState<string>();

  const [dateRange, setDateRange] = useState({ from: null, to: null });

  const handleDateChange = (field) => (date) => {
    setDateRange((current) => ({
      ...current,
      [field]: date,
    }));
  };

  const handleFilterReset = () => {
    setDateRange({ from: null, to: null });
  };

  const isDateFilter = Boolean(anchorFilter);
  const handleFilterOpen = (event) => setAnchorFilter(event.currentTarget);
  const handleFilterClose = () => setAnchorFilter(null);

  const handleFilterApply = () => {
    applyFilter(dateRange);
    handleFilterClose();
  };

  const handleSearchChange = useCallback(
    debounce((value: string) => {
      onSearchChange(value);
    }, 1000),
    [],
  );

  return (
    <>
      <TextField
        className={styles.input}
        onChange={({ target: { value } }) => {
          setSearch(value);
          handleSearchChange(value);
        }}
        value={search}
        placeholder={intl('SHARED.search')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
      <a href={saveToCSVLink || '#'}>
        <ExportToCsvIcon />
      </a>
      <IconButton onClick={handleFilterOpen}>
        <FilterIcon />
      </IconButton>

      <DateRangeFilter
        renderFilter={anchorFilter}
        open={isDateFilter}
        handleCloseFilter={handleFilterClose}
        handleDateChange={handleDateChange}
        handleFilterReset={handleFilterReset}
        filterDate={dateRange}
        handleFilterApply={handleFilterApply}
        isDisabled={isDisabled}
      />
    </>
  );
};

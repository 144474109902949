import React, { FC } from 'react';
import { Box, Button, Divider, Hidden, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { intl } from 'helpers/intl';
import { routes } from 'router/routesList';
import { useBrandOpportunities } from './hooks';

import { Loader } from 'components/Loader';
import { TalentOpportunityCard, TalentOpportunityCardMobile } from 'components/TalentOpportunityCard';
import { DesertImage } from 'assets/svg-js';

import { useStyles } from './styles';

import type { Props } from './types';

export const BrandOpportunities: FC<Props> = ({ brandId }) => {
  const styles = useStyles();
  const { opportunitiesList, total, loading, onShowMore } = useBrandOpportunities(brandId);

  if (loading && !opportunitiesList.length) {
    return <Loader className={styles.loader} />;
  }

  if (!opportunitiesList.length) {
    return (
      <Box className={styles.emptyContainer}>
        <DesertImage className={styles.emptyIcon} />
        <Typography className={styles.emptyText}>{intl('TALENT.BRANDS_PROFILE.noOpportunities')}</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Hidden smDown>
        <Typography className={styles.quantity}>
          {total} {total > 1 ? intl('SHARED.opportunities') : intl('SHARED.opportunity')}
        </Typography>
        <Divider className={styles.headDivider} />
      </Hidden>
      {opportunitiesList.map((opportunity) => (
        <Link to={`/${routes.TALENT}/${routes.OPPORTUNITIES}/${opportunity.id}/details`} className={styles.opportunityLink} key={opportunity.id}>
          <Hidden xsDown>
            <TalentOpportunityCard opportunity={opportunity} />
          </Hidden>

          <Hidden smUp>
            <TalentOpportunityCardMobile opportunity={opportunity} />
          </Hidden>
        </Link>
      ))}

      {total > opportunitiesList.length && (
        <Box className={styles.showMoreContainer}>
          <Button className={styles.buttonShowMore} variant="outlined" onClick={onShowMore} tabIndex={null} component="div" role="none">
            {intl('SHARED.showMore')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

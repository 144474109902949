export const initialState = {
  isShowModal: false,
  isPersonVerifiedModal: false,
  deletePostModal: {
    open: false,
    postId: null,
    onClose: () => {},
    onSuccess: () => {},
  },
  successModal: {
    isOpen: false,
    title: '',
    onCloseFunction: () => {},
  },
  isAvatarEmpty: false,
};

export const appReducer = (state, { type, payload }) => {
  switch (type) {
    case 'INTRO_MODAL':
      return {
        ...state,
        isShowModal: payload,
      };
    case 'PERSON_VERIFIED_MODAL':
      return {
        ...state,
        isPersonVerifiedModal: payload,
      };
    case 'DELETE_POST_MODAL':
      return {
        ...state,
        deletePostModal: {
          open: payload.open,
          postId: payload.postId,
          onClose: payload.onClose,
          onSuccess: payload.onSuccess,
        },
      };
    case 'SUCCESS_MODAL':
      return {
        ...state,
        successModal: {
          isOpen: payload.isOpen,
          title: payload.title,
          onCloseFunction: payload.onCloseFunction,
        },
      };
    default:
      throw new Error();
  }
};

import { useEffect, useState } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { useLazyQuery } from '@apollo/client';

import { useAuth } from 'hooks/auth';
import { routes } from 'router/routesList';

import { UserTypeEnum } from 'types/common';
import type { ThCells } from './types';

const getCells = (isXs: boolean, role: UserTypeEnum): ThCells => {
  const thMobile = [{ name: 'PAYMENT_HISTORY.opportunityName' }, { name: 'PAYMENT_HISTORY.amount', align: 'right' }];
  const thReal = [
    { name: 'PAYMENT_HISTORY.opportunityName', minWidth: '130px', width: '130px' },
    { name: role === UserTypeEnum.talent ? 'PAYMENT_HISTORY.brand' : 'PAYMENT_HISTORY.talent', minWidth: '120px', width: '120px' },
    { name: 'PAYMENT_HISTORY.method', minWidth: '106px', width: '106px' },
    { name: 'PAYMENT_HISTORY.date', minWidth: '92px', width: '92px', sortField: 'createdAt' },
    { name: 'PAYMENT_HISTORY.opportunityStatus', minWidth: '180px', width: '180px' },
    { name: 'PAYMENT_HISTORY.amount', minWidth: '95px', width: '95px', align: 'right' },
  ];
  // return isXs ? thMobile : thReal;
  return thReal;
};

export const usePaymentHistoryTable = ({ query, fieldName, talentOrBrandId }) => {
  const { getAssumedRole } = useAuth();
  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [renderFilter, setRenderFilter] = useState(null);
  const [filterDate, setFilterDate] = useState({ from: null, to: null });
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const thCells = getCells(isXs, getAssumedRole());
  const [orderBy, setOrderBy] = useState(thCells[3]?.sortField);

  const createSortHandler = (sortField: string) => (): void => {
    setOrderBy(sortField);
    setOrder((current) => (current === 'asc' ? 'desc' : 'asc'));
  };
  const getOpportunityLink = (opportunityId) => `/${getAssumedRole()}/${routes.OPPORTUNITIES}/${opportunityId}/details`;
  const queryOptions = {
    variables: {
      talentOrBrandId,
      pagination: {
        offset: rowsPerPage * page,
        limit: rowsPerPage,
      },
      filter: {
        dateFrom: null,
        dateTo: null,
      },
      sort: {
        field: orderBy,
        order,
      },
    },
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      setDataToDisplay(data[fieldName]?.items?.slice(0, rowsPerPage) || []);
    },
  };
  // @ts-ignore
  const [loadHistory, { loading: paymentHistoryLoading, data: paymentHistoryData }] = useLazyQuery(query, queryOptions);

  useEffect(loadHistory, []);

  useEffect(() => {
    setDataToDisplay(paymentHistoryData?.[fieldName]?.items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) || []);
  }, [page, rowsPerPage]);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
  };

  const isFilterOpen = Boolean(renderFilter);

  const handleOpenFilter = (event) => {
    setRenderFilter(event.currentTarget);
  };

  const handleCloseFilter = () => setRenderFilter(null);

  const handleDateChange = (field) => (date) => {
    setFilterDate((current) => ({
      ...current,
      [field]: date,
    }));
  };

  const handleFilterReset = () => {
    loadHistory();
    setFilterDate({ from: null, to: null });
  };

  const handleFilterApply = () => {
    queryOptions.variables.filter = {
      dateFrom: filterDate.from?.setHours(0, 0, 0, 0),
      dateTo: filterDate.to?.setHours(23, 59, 59, 999),
    };
    loadHistory(queryOptions);
    handleCloseFilter();
  };

  const isFilterDisabled = !filterDate.from && !filterDate.to;

  // TODO: add type for returned object
  return {
    thCells,
    getOpportunityLink,
    dataToDisplay,
    createSortHandler,
    handleChangePage,
    handleChangeRowsPerPage,
    isFilterOpen,
    handleOpenFilter,
    handleDateChange,
    handleFilterReset,
    handleFilterApply,
    isFilterDisabled,
    paymentHistoryLoading,
    paymentHistoryData,
    page,
    rowsPerPage,
    order,
    orderBy,
    renderFilter,
    handleCloseFilter,
    filterDate,
  };
};

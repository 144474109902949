import React, { FC, memo } from 'react';
import { nanoid } from 'nanoid';
import { Box, Typography } from '@material-ui/core';
import moment from 'moment';

import { intl } from 'helpers';

import { CardWrapper } from './CardWrapper';
import { ItemWrapper } from './ItemWrapper';

import { useStyles } from '../styles';

import type { ProfileViewedProps } from '../types';
import {isEqual} from "lodash";

export const ProfileViewed: FC<ProfileViewedProps> = memo(({ brandsTalentProfileViewed = [], profileViewsCount, loadMore }) => {
  const styles = useStyles({});
  const renderItems: Array<JSX.Element> = brandsTalentProfileViewed.map(({ brandImage, brandName, dateProfileView }) => (
    <ItemWrapper brandImage={brandImage} key={nanoid()}>
      <Box className={styles.blockDescription}>
        <Box className={styles.name}>{brandName}</Box>
        <Typography noWrap className={styles.opportunities}>
          {moment(dateProfileView).format('DD.MM.YYYY')}
        </Typography>
      </Box>
    </ItemWrapper>
  ));
  return (
    <CardWrapper itemsQuantity={profileViewsCount} title={intl('TALENT.ANALYTICS.profileViews')} loadMore={loadMore} tableType="viewed">
      {renderItems}
    </CardWrapper>
  );
},
  (prevProps, nextProps) => isEqual(prevProps.brandsTalentProfileViewed, nextProps.brandsTalentProfileViewed),
);

import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from 'theme/colors';

const useStyles = makeStyles((theme: Theme) => ({
  container: {},
  heading: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headingMobile: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  headingMobileWrapper: {
    display: 'flex',
  },
  avatar: {
    marginRight: theme.spacing(1.5),
  },
  userBox: {
    display: 'flex',
    alignItems: 'center',
  },
  username: {
    marginLeft: theme.spacing(2),
  },
  postedAt: {
    [theme.breakpoints.up('xs')]: {
      marginRight: theme.spacing(1),
    },
    color: colors.SECONDARY_TEXT,
  },
  opened: {
    fill: colors.PRIMARY_FOCUSED,
  },
  userListMenu: {
    minWidth: 128,
  },
  content: {},
  collapse: {
    paddingTop: theme.spacing(2),
  },
  textBox: {
    padding: theme.spacing(0, 2),
    paddingBottom: theme.spacing(2),
  },
  text: {
    whiteSpace: 'break-spaces',
  },
  shortText: {
    marginTop: theme.spacing(3),
    display: 'block',
    whiteSpace: 'break-spaces',
  },
  btnMore: {
    marginTop: theme.spacing(1),
    padding: '0px 8px',
    height: 30,
    fontSize: 12,
  },
  actions: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    display: 'flex',
  },
  filledIcon: {
    fill: colors.PRIMARY_INPUT_HOVERED,
  },
  shareAction: {
    padding: 0,
  },
  shareActionButton: {
    paddingLeft: `${theme.spacing(2)}px !important`,
    minHeight: theme.spacing(6),
    width: '100%',
    textAlign: 'left',
    outline: 'none',
  },
  socialItem: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginRight: theme.spacing(1),
    },
    '&:first-child': {
      marginRight: theme.spacing(3),
    },
  },
}));

export { useStyles };

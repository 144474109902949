import React from 'react'
import { Box, Button, Typography } from '@material-ui/core'
import { useStyles } from './styles'
import { Modal } from '../../../../../components/Modal'
import { intl } from '../../../../../helpers'
import { ListCheckIcon } from '../../../../../assets/svg-js'

const TargetModal = ({ open = false, handleClose = () => {} }) => {
  const classes = useStyles()

  return (
    <Modal
      className={classes.modal}
      isOpen={open}
      title={null}
      handleClose={handleClose}
      disabledBorder
      dialogActionsComponent={
        <Button fullWidth color="primary" variant="contained" onClick={handleClose}>
          {intl('SHARED.ok')}
        </Button>
      }
    >
      <Box className={classes.container}>
        <Box className={classes.iconBox}>
          <ListCheckIcon />
        </Box>
        <Typography className={classes.text}>{intl('TARGET_MODAL.text')}</Typography>
      </Box>
    </Modal>
  )
}

export default TargetModal

import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';

import { INVITE_USER } from 'services/graphql/admin';
import { useErrorHandler } from 'hooks/app/errorHandler';

export const useInviteUser = (role) => {
  const [isInvitationModal, setIsInvitationModal] = useState(false);
  const [invitationResponseError, setInvitationResponseError] = useState('');
  const { getError } = useErrorHandler();

  useEffect(() => {
    if (!isInvitationModal) {
      setInvitationResponseError('');
    }
  }, [isInvitationModal])

  const handleInvitationModal = () => setIsInvitationModal((current) => !current);

  const [sendUserInvitation] = useMutation(INVITE_USER, {
    onError: (error) => {
      const normalizedErrorMessage = getError(error.message);
      setInvitationResponseError(normalizedErrorMessage);
    },
    onCompleted: () => {
      handleInvitationModal();
    }
  });

  const submitInvitation = ({ email }) => {
    sendUserInvitation({
      variables: {
        email,
        role,
      },
    });
  };

  return {
    isInvitationModal,
    invitationResponseError,
    handleInvitationModal,
    submitInvitation,
  };
};

// TODO: add types


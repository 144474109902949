import { makeStyles, Theme } from '@material-ui/core';
import { colors } from 'theme/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  modalBox: {
    maxWidth: 384,
  },
  modalTitleBox: {
    padding: '16px 32px',
    paddingTop: theme.spacing(3),
  },
  modalContentBox: {
    margin: -theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  modalIconBox: {
    marginBottom: theme.spacing(4),
    background: colors.BACKGROUND,
    padding: '24px 42px',
    borderRadius: '100%',
  },
  modalText: {
    fontSize: 18,
    lineHeight: '27px',
    textAlign: 'center',
  },
  modalTextMargin: {
    marginBottom: theme.spacing(3),
  },
  btnYes: {
    padding: '11px 66px',
    background: colors.PRIMARY,
    color: colors.WHITE,
    '&:hover': {
      background: colors.PRIMARY_HOVERED,
    },
  },
  btnNo: {
    border: `1px solid ${colors.BORDER3}`,
    padding: '11px 66px',
  },
}));

import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from 'theme/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    maxWidth: 384,
    minHeight: 385,
  },
  container: {
    marginTop: -theme.spacing(3),
    marginBottom: -theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 220,
  },
  iconBox: {
    marginBottom: theme.spacing(4),
    padding: '30px 35px 22px',
    width: 'fit-content',
    background: colors.BACKGROUND,
    borderRadius: '100%',
  },
  txt: {
    fontSize: 18,
    textAlign: 'center',
  },
}));

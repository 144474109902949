/* eslint-disable max-len */
import React from 'react'

const ListCheckIcon = (props) => (
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63.0006 38.9992H33.0006C31.3506 38.9992 30.0006 37.6492 30.0006 35.9992C30.0006 34.3492 31.3506 32.9992 33.0006 32.9992H63.0006C64.6506 32.9992 66.0006 34.3492 66.0006 35.9992C66.0006 37.6492 64.6506 38.9992 63.0006 38.9992ZM26.1007 33.3592L16.5907 42.8692C15.4207 44.0392 13.5006 44.0392 12.3306 42.8692L8.10064 38.6092C6.93064 37.4392 6.93064 35.5492 8.10064 34.3792C9.27064 33.2092 11.1606 33.2092 12.3306 34.3792L14.4606 36.5092L21.8707 29.0992C23.0407 27.9292 24.9307 27.9292 26.1007 29.0992L26.1306 29.1292C27.2706 30.2992 27.2707 32.2192 26.1007 33.3592Z"
      fill="#838CA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63 60H33C31.35 60 30 58.65 30 57C30 55.35 31.35 54 33 54H63C64.65 54 66 55.35 66 57C66 58.65 64.65 60 63 60Z"
      fill="#838CA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.1213 50.3787C24.2929 51.5503 24.2929 53.4497 23.1213 54.6213L14.1213 63.6213C12.9497 64.7929 11.0503 64.7929 9.87868 63.6213C8.70711 62.4497 8.70711 60.5503 9.87868 59.3787L18.8787 50.3787C20.0503 49.2071 21.9497 49.2071 23.1213 50.3787Z"
      fill="#838CA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.87868 50.3787C8.70711 51.5503 8.70711 53.4497 9.87868 54.6213L18.8787 63.6213C20.0503 64.7929 21.9497 64.7929 23.1213 63.6213C24.2929 62.4497 24.2929 60.5503 23.1213 59.3787L14.1213 50.3787C12.9497 49.2071 11.0503 49.2071 9.87868 50.3787Z"
      fill="#838CA7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63.0006 17.9992H33.0006C31.3506 17.9992 30.0006 16.6492 30.0006 14.9992C30.0006 13.3492 31.3506 11.9992 33.0006 11.9992H63.0006C64.6506 11.9992 66.0006 13.3492 66.0006 14.9992C66.0006 16.6492 64.6506 17.9992 63.0006 17.9992ZM26.1007 12.3592L16.5907 21.8692C15.4207 23.0392 13.5006 23.0392 12.3306 21.8692L8.10064 17.6092C6.93064 16.4392 6.93064 14.5492 8.10064 13.3792C9.27064 12.2092 11.1606 12.2092 12.3306 13.3792L14.4606 15.5092L21.8707 8.09918C23.0407 6.92918 24.9307 6.92918 26.1007 8.09918L26.1306 8.12918C27.2706 9.29918 27.2707 11.2192 26.1007 12.3592Z"
      fill="#838CA7"
    />
  </svg>
)

export default ListCheckIcon

import { makeStyles } from '@material-ui/core'
import { colors } from '../../theme/colors'

export const useStyles = makeStyles(() => ({
  text: {
    fontWeight: 600,
    marginLeft: 8,
    color: colors.BLACK,
  },
  container: {
    minWidth: 156,
  },
}))

import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from 'theme/colors';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
      flexDirection: 'column',
    },
  },
  avatar: {
    marginRight: theme.spacing(3),
    width: 121,
    height: 121,
    textTransform: 'uppercase',
    [theme.breakpoints.up('xs')]: {
      fontSize: 52,
    },
    [theme.breakpoints.only('xs')]: {
      width: 52,
      height: 52,
      marginRight: 12,
      fontSize: 21,
    },
  },
  content: {
    flexGrow: 1,
  },
  headingMobile: {
    display: 'flex',
  },
  heading: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      marginBottom: theme.spacing(2),
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
  },
  btnFavorite: {},
  title: {
    marginRight: theme.spacing(1),
    fontSize: 18,
    fontWeight: 400,
  },
  joinedText: {
    color: colors.TEXT_GRAY,
  },
  contentMobile: {
    marginTop: theme.spacing(2),
  },
  mainInfoBox: {
    marginTop: theme.spacing(2),
    display: 'flex',
  },
  infoBox: {
    display: 'flex',
    alignItems: 'flex-end',
    '&:nth-child(2)': {
      marginTop: 12,
      [theme.breakpoints.up('sm')]: {
        marginLeft: 120,
        marginTop: 0,
      },
    },
  },
  icon: {
    marginRight: theme.spacing(1),
    fill: colors.PRIMARY_INPUT_HOVERED,
  },
}));

export { useStyles };

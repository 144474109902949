import React, { FC, memo } from 'react';
import { nanoid } from 'nanoid';
import { Box, Typography } from '@material-ui/core';

import { intl } from 'helpers';

import { CardWrapper } from './CardWrapper';
import { ItemWrapper } from './ItemWrapper';

import { useStyles } from '../styles';

import type { WorkedForProps } from '../types';
import { isEqual } from 'lodash';

// export const WorkedFor: FC<WorkedForProps> = memo(
//   ({ brandsWorkedFor = [], brandsWorkedForCount, loadMore }) => {
//     const styles = useStyles({});
//     const renderItems: Array<JSX.Element> = brandsWorkedFor.map(({ brandImage, brandName, opportunityCompleteCount }) => (
//       <ItemWrapper brandImage={brandImage} key={nanoid()}>
//         <Box className={styles.blockDescription}>
//           <Box className={styles.name}>{brandName}</Box>
//           <Typography noWrap className={styles.opportunities}>
//             {opportunityCompleteCount} {intl('TALENT.ANALYTICS.opportunities')}
//           </Typography>
//         </Box>
//       </ItemWrapper>
//     ));
//     return (
//       <CardWrapper itemsQuantity={brandsWorkedForCount} title={intl('TALENT.ANALYTICS.workedFor')} loadMore={loadMore} tableType="worked">
//         {renderItems}
//       </CardWrapper>
//     );
//   },
//   (prevProps, nextProps) => isEqual(prevProps.brandsWorkedFor, nextProps.brandsWorkedFor),
// );

export const WorkedFor: FC<WorkedForProps> = ({ brandsWorkedFor = [], brandsWorkedForCount, loadMore }) => {
  const styles = useStyles({});
  const renderItems: Array<JSX.Element> = brandsWorkedFor.map(({ brandImage, brandName, opportunityCompleteCount }) => (
    <ItemWrapper brandImage={brandImage} key={nanoid()}>
      <Box className={styles.blockDescription}>
        <Box className={styles.name}>{brandName}</Box>
        <Typography noWrap className={styles.opportunities}>
          {opportunityCompleteCount} {intl('TALENT.ANALYTICS.opportunities')}
        </Typography>
      </Box>
    </ItemWrapper>
  ));
  return (
    <CardWrapper itemsQuantity={brandsWorkedForCount} title={intl('TALENT.ANALYTICS.workedFor')} loadMore={loadMore} tableType="worked">
      {renderItems}
    </CardWrapper>
  );
};

import React, { useState, useCallback } from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Box, Paper, Tabs, Tab, TextField, Typography, Button } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { useFormik } from 'formik';

import { useAuth } from 'hooks/auth';
import { intl } from 'helpers';
import { routes } from 'router/routesList';
import { TERMS_AND_CONDITIONS_URL } from 'constants/index';

import { TextFieldPassword } from 'components/TextFieldPassword';
import { SportEndorse } from 'components/SportEndorse';

import { useStyles } from './styles';
import ReactGA from 'react-ga';

const SignUpSchema = Yup.object({
  firstName: Yup.string().required('ERRORS.emptyField'),
  lastName: Yup.string().required('ERRORS.emptyField'),
  email: Yup.string().email('ERRORS.incorrectEmail').required('ERRORS.emptyField'),
  password: Yup.string()
    .matches(/((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,})/, 'ERRORS.incorrectPasswordFormat')
    .required('ERRORS.emptyField'),
});

const isCorrectRole = (role) => ['talent', 'brand'].includes(role);

export const SignUp = () => {
  const styles = useStyles({});
  const history = useHistory();
  const { role } = useParams();
  const { signUp } = useAuth();
  const [loading, setLoading] = useState(false);

  const [isPasswordShown, setPasswordShown] = useState(false);
  const togglePasswordShown = useCallback(() => setPasswordShown((passwordStatus) => !passwordStatus), []);

  const goTo = (location) => () => history.push(location);

  const handleSignUp = async (values, formContext) => {
    const lowerCaseEmail = String(values.email).toLowerCase();
    setLoading(true);
    const { error } = await signUp({
      email: lowerCaseEmail,
      password: values.password,
      attributes: {
        'custom:role': role,
        email: lowerCaseEmail,
        given_name: values.firstName.trim(),
        family_name: values.lastName.trim(),
      },
      redirectUrl: `/confirm?email=${lowerCaseEmail}`,
    });
    setLoading(false);
    if (!error) {
      ReactGA.event({
        category: 'User',
        action: `[${role.toUpperCase()}]: Not Full Sign Up`,
        label: `[${role.toUpperCase()}]: ${lowerCaseEmail.replace('@', '*')} - Not Full Sign Up`,
      });
    }
    if (error?.name === 'UsernameExistsException') {
      formContext.setFieldError('email', error.message);
    }
    // * handle custom errors
    if (error?.name === 'UserLambdaValidationException') {
      if (error.message.includes('se-email-already-exist')) {
        formContext.setFieldError('email', intl('ERROR.signUp.userEmailExists'));
      }
    }
  };

  const { handleChange, values, errors, touched, handleSubmit } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    },
    onSubmit: handleSignUp,
    validationSchema: SignUpSchema,
  });

  if (!isCorrectRole(role)) {
    goTo(`/${routes.LOGIN}`)();
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="center" minHeight="100vh" padding="30px" paddingX="8px" flexDirection="column">
      <SportEndorse />

      <Paper className={styles.paper} component="form" onSubmit={handleSubmit}>
        <Tabs value={role} indicatorColor="primary">
          <Tab label={intl('SHARED.brand')} value="brand" className={styles.tab} onClick={goTo(`/${routes.SIGN_UP}/brand`)} />
          <Tab label={intl('SHARED.talent')} value="talent" className={styles.tab} onClick={goTo(`/${routes.SIGN_UP}/talent`)} />
        </Tabs>
        <TextField
          name="firstName"
          label={intl('SHARED.firstName')}
          className={clsx(styles.input, styles.firstInput)}
          value={values.firstName}
          onChange={handleChange}
          error={errors.firstName && touched.firstName}
          helperText={intl(touched.firstName && errors.firstName)}
        />
        <TextField
          name="lastName"
          label={intl('SHARED.lastName')}
          className={styles.input}
          value={values.lastName}
          onChange={handleChange}
          error={errors.lastName && touched.lastName}
          helperText={intl(touched.lastName && errors.lastName)}
        />
        <TextField
          name="email"
          label={intl('SHARED.email')}
          className={styles.input}
          value={values.email}
          onChange={handleChange}
          error={errors.email && touched.email}
          helperText={intl(touched.email && errors.email)}
        />
        <TextFieldPassword
          togglePassword={togglePasswordShown}
          showPassword={isPasswordShown}
          label={intl('SHARED.password')}
          name="password"
          value={values.password}
          onChange={handleChange}
          className={styles.input}
          error={errors.password && touched.password}
          helperText={intl(touched.password && errors.password)}
        />

        <Typography className={styles.terms}>
          {intl('SIGN_UP.termsAndConditionStart')}
          {/* eslint-disable-next-line */}
          <a href={TERMS_AND_CONDITIONS_URL} target="_blank" rel="noopener noreferrer">
            {intl('SIGN_UP.terms')}
          </a>{' '}
          {intl('SIGN_UP.termsAndConditionAnd')}
          <a href="https://sportendorse.com/privacy-policy">{intl('SIGN_UP.privacyPolicy')}</a>.
        </Typography>
        <Button type="submit" disabled={loading} variant="contained" color="primary" className={styles.signup}>
          {intl('AUTH.signUp')}
        </Button>
        <Button onClick={goTo(routes.LOGIN)} variant="outlined">
          {intl('AUTH.logIn')}
        </Button>
      </Paper>
    </Box>
  );
};

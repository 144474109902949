const {
  REACT_APP_AWS_REGION,
  REACT_APP_AWS_USER_POOL_ID,
  REACT_APP_USER_POOL_WEB_CLIENT_ID,
  REACT_APP_GRAPHQL_ENDPOINT,
  REACT_APP_GRAPHQL_AUTH_TYPE,
  REACT_APP_IDENTITY_POOL_ID,
} = process.env

export const amplifyConfig = {
  Auth: {
    identityPoolId: REACT_APP_IDENTITY_POOL_ID,
    region: REACT_APP_AWS_REGION,
    userPoolId: REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_USER_POOL_WEB_CLIENT_ID,
  },
  graphql_endpoint: REACT_APP_GRAPHQL_ENDPOINT,
  graphql_endpoint_iam_region: REACT_APP_AWS_REGION,
  aws_appsync_authenticationType: REACT_APP_GRAPHQL_AUTH_TYPE,
}

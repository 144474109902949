import React, { useState, useEffect, FC, useRef, MouseEvent, ChangeEvent } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { Add, Create } from '@material-ui/icons';
import { Storage } from 'aws-amplify';
import clsx from 'clsx';

import { dataURLtoBlob, intl } from 'helpers';
import { useLocation } from 'react-router-dom';

import { CroppedModal } from './components/CroppedModal';
import { useStyles } from './styles';
import type { Props } from './types';

const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env;

type LocationState = {
  referrer?: string;
};

const AvatarEditable: FC<Props> = ({ image: incomingImg, disabled, error, className, imgAlt, handleSuccess }) => {
  const { state } = useLocation<LocationState>();
  const [imgKey, setImgKey] = useState<string>(null);
  const requiredError = !!state?.referrer && !incomingImg;
  const classes = useStyles({ errorImage: requiredError });
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [uploadedImage, setImage] = useState<File>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const imageRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (incomingImg && incomingImg !== imgKey) {
      const encodedImg = encodeURI(`${REACT_APP_AWSS3_URL_IMAGE_BEG}${incomingImg}`);
      setImgKey(encodedImg);
    }
  }, [incomingImg]);

  const handleOpenModal = () => setModalOpen(true);

  const handleCloseModal = () => {
    setModalOpen(false);
    setImage(null);
  };

  const handleSaveImage = async (ref: any) => {
    setLoading(true);

    const dataURL = ref.current?.getImageScaledToCanvas().toDataURL();
    const { blob, type } = dataURLtoBlob(dataURL);
    const file = new File([blob], uploadedImage.name, { type });

    try {
      const parts = file.name.split('.');
      const normalizedName = `${new Date().valueOf()}-${Math.random()}${parts.length ? '.'.concat(parts[parts.length - 1]) : ''}`;
      const { key }: { key: string } = (await Storage.put(`temp/${normalizedName}`, file, {
        contentType: file.type,
      })) as { key: string };

      setImgKey(`${REACT_APP_AWSS3_URL_IMAGE_BEG}public/${key}`);
      handleCloseModal();
      handleSuccess(key);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('[Uploading error]', err);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const handleImageEditClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    imageRef.current.click();
  };

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      setImage(imageFile);
      handleOpenModal();
      imageRef.current.value = null;
    }
  };

  return (
    <>
      <Box
        className={clsx(classes.box, className, {
          [classes.errorBox]: error,
        })}
        style={{ backgroundImage: `url(${imgKey})` }}
      >
        {!disabled && !imgKey && requiredError && <Typography className={classes.placeholderText}>{intl('OPPORTUNITIES.requiredField')}</Typography>}
        {disabled && !incomingImg && <Typography className={clsx(classes.placeholderText, classes.bigText)}>{imgAlt}</Typography>}
        <input ref={imageRef} onChange={handleImageChange} type="file" accept="image/x-png,image/jpeg" hidden />
        {!disabled && (
          <Button size="small" onClick={handleImageEditClick} className={classes.btnAdd} variant="contained">
            {incomingImg ? (
              <Create color="primary" fontSize="small" className={classes.addIcon} />
            ) : (
              <Add color="primary" className={clsx(classes.addIcon, { [classes.errorButton]: error })} />
            )}
          </Button>
        )}
      </Box>

      {modalOpen && (
        <CroppedModal
          open={modalOpen}
          handleImageEditClick={handleImageEditClick}
          handleSaveImage={handleSaveImage}
          loading={loading}
          image={uploadedImage}
          handleClose={handleCloseModal}
        />
      )}
    </>
  );
};

export { AvatarEditable };

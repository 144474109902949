import { makeStyles } from '@material-ui/core';
import { colors } from 'theme/colors';

export const useStyles = makeStyles({
  modalContainer: {
    width: 552,
    height: 339
  },
  formContainer: {
    marginTop: 24
  },
  formHeader: {
    fontSize: 12,
    lineHeight: '18px',
  },
  btn: {
    width: 152,
    height: 40,
    '&:last-child': {
      marginLeft: 16
    }
  },
  smallError: {
    color: colors.ERROR,
    fontSize: 12,
  },
})


import React, { FC, useState } from 'react';
import { Box, IconButton, Typography } from '@material-ui/core';
import { ArrowBackIosRounded as ArrowBackIcon, ArrowForwardIosRounded as ArrowForwardIcon } from '@material-ui/icons';
import clsx from 'clsx';

import { useStyles } from './styles';
import type { Props, Image } from './type';
import { ImagesModal } from './components/ImagesModal';

const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env;

const ImagesCarousel: FC<Props> = ({ images, className }) => {
  const styles = useStyles();
  const [currentImage, setCurrentImage] = useState<Image>({ src: images[0], index: 0 });
  const [focused, setFocus] = useState<boolean>(false);
  const [imagesModal, setImagesModal] = useState<boolean>(false);

  const showNavigation = focused && images.length > 1;

  const handleBack = () => {
    const currentIdx = images.findIndex((i: string) => i === currentImage.src);
    if (currentIdx === 0) setCurrentImage({ src: images[images.length - 1], index: images.length - 1 });
    else setCurrentImage({ src: images[currentIdx - 1], index: currentIdx - 1 });
  };
  const handleNext = () => {
    const currentIdx = images.findIndex((i: string) => i === currentImage.src);
    if (currentIdx === images.length - 1) setCurrentImage({ src: images[0], index: 0 });
    else setCurrentImage({ src: images[currentIdx + 1], index: currentIdx + 1 });
  };

  const handleChangeFocus = (value: boolean) => {
    setFocus(value);
  };

  const handleCloseModal = () => {
    setImagesModal(false);
    handleChangeFocus(true);
  };

  const handleOpenModal = (target) => {
    if (target.dataset.type === 'button') {
      return;
    }
    setImagesModal(true);
  };

  return (
    <>
      <Box
        className={clsx(styles.container, className)}
        onMouseEnter={() => handleChangeFocus(true)}
        onMouseLeave={() => handleChangeFocus(false)}
        onClick={({ target }) => handleOpenModal(target)}
      >
        <Box style={{ backgroundImage: `url(${REACT_APP_AWSS3_URL_IMAGE_BEG + currentImage.src})` }} className={styles.initialImage} />
        <Box style={{ backgroundImage: `url(${REACT_APP_AWSS3_URL_IMAGE_BEG + currentImage.src})` }} className={styles.stretchedImage} />
        {showNavigation && (
          <>
            <Box className={styles.btnGroup}>
              <Box className={styles.navigationBox}>
                <IconButton className={styles.iconBox} onClick={handleBack} data-type="button">
                  <ArrowBackIcon className={styles.icon} data-type="button" />
                </IconButton>
                <IconButton className={styles.iconBox} onClick={handleNext} data-type="button">
                  <ArrowForwardIcon className={styles.icon} data-type="button" />
                </IconButton>
              </Box>
            </Box>
            <Typography className={styles.imagesCounter}>{`${currentImage.index + 1} / ${images.length}`}</Typography>
          </>
        )}
      </Box>
      <ImagesModal images={images} open={imagesModal} currentImage={currentImage} handleClose={handleCloseModal} />
    </>
  );
};

export { ImagesCarousel };

/* eslint-disable max-len */
import React from 'react'

const LoginIcon = ({ ...props }) => (
  <svg
    width="112"
    height="112"
    viewBox="0 0 112 112"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="56" cy="56" r="56" fill="#F6F6F6" />
    <path
      opacity="0.7"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M62.4465 54.6483C68.0725 52.1687 72 46.543 72 40C72 31.1634 64.8366 24 56 24C47.1634 24 40 31.1634 40 40C40 46.543 43.9275 52.1687 49.5535 54.6483L42 89H70L62.4465 54.6483Z"
      fill="#535E83"
    />
  </svg>
)

export default LoginIcon

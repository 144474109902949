import { gql } from '@apollo/client'

const LIST_COUNTRIES = gql`
  query {
    listCountries {
      id
      name
    }
  }
`

const LIST_CITIES = gql`
  query listCities($countryId: ID, $countryIds: [ID]) {
    listCities(countryId: $countryId, countryIds: $countryIds) {
      id
      name
    }
  }
`

export { LIST_COUNTRIES, LIST_CITIES }

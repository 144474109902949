import React, { FC } from 'react';
import clsx from 'clsx';
import { Box, Typography, Avatar } from '@material-ui/core';

import { useLink } from 'hooks/linkTo';
import { useProfileBar } from './hooks';

import { useStyles } from './styles';

import type { ProfileBarProps } from './types';

export const ProfileBar: FC<ProfileBarProps> = ({ className, setCurrentBtn }) => {
  const { publicLink, avatarSrc, username, userInitials } = useProfileBar();
  const linkTo = useLink();
  const styles = useStyles({});

  const handleClick = () => {
    linkTo(publicLink)();
    setCurrentBtn(null);
  };

  return (
    <Box onClick={handleClick} className={clsx(styles.profileBar, className)}>
      <Typography>{username}</Typography>
      <Avatar src={avatarSrc} alt={username} className={styles.avatar}>
        {userInitials}
      </Avatar>
    </Box>
  );
};

import React from 'react';
import { Typography, Grid, TextField, Box } from '@material-ui/core';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { intl, getCurrencySymbol, formatNumber } from 'helpers';

import { StripeInput } from 'components/StripeInput/StripeInput';

const PaymentForm = ({ classes, opportunity, amount, values, handleChange, paymentError }) => (
  <>
    <Typography className={classes.bigText}>{opportunity?.name}</Typography>
    <Typography className={classes.bigText}>{`${getCurrencySymbol(opportunity.redemption.currency.code)} ${formatNumber(amount)}`}</Typography>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          name="cardNumber"
          fullWidth
          label={intl('PAYMENT_MODAL.cardNumber')}
          required
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardNumberElement,
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          name="expirationDate"
          fullWidth
          label={intl('PAYMENT_MODAL.expirationDate')}
          required
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardExpiryElement,
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          name="cvv"
          fullWidth
          label={intl('PAYMENT_MODAL.cvv')}
          required
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardCvcElement,
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          className={classes.popupInput}
          fullWidth
          name="cardholderName"
          onChange={handleChange}
          value={values.cardholderName}
          label={intl('PAYMENT_MODAL.cardholderName')}
        />
      </Grid>
      {paymentError && (
        <Box className={classes.paymentErrorBox}>
          <Typography variant="caption" className={classes.paymentError}>
            {paymentError}
          </Typography>
        </Box>
      )}
    </Grid>
  </>
);

export default PaymentForm;

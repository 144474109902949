import React, { useEffect, useState, FC } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { Warning as WarningIcon } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import { Modal } from 'components/Modal';
import { intl } from 'helpers';
import { routes } from 'router/routesList';
import type { RouteShape } from 'types/common';

import { useStyles } from './styles';
import type { Props } from './types';

const VerifyModal: FC<Props> = ({ open, errors, handleClose }) => {
  const styles = useStyles();
  const { push, location } = useHistory();
  const [currentRoute, setRoute] = useState<RouteShape | null>(null);

  useEffect(() => {
    if (!errors.isVerified) {
      setRoute({
        pathname: routes.BRAND_VERIFICATION,
        state: { referrer: location.pathname },
      });
    }
    if (!errors.hasProfileInfo) {
      setRoute({
        pathname: `/${routes.BRAND_PROFILE_INFO}`,
        state: { referrer: location.pathname },
      });
    }
    if (!errors.hasBrandInfo) {
      setRoute({
        pathname: `/${routes.BRAND_COMPANY_INFO}`,
        state: { referrer: location.pathname },
      });
    }
  }, [errors]);

  const Actions = () => (
    <Box className={styles.actionsContainer}>
      <Button variant="outlined" onClick={handleClose} className={styles.buttons}>
        {intl('SHARED.cancel')}
      </Button>
      <Button onClick={() => push(currentRoute)} variant="contained" className={styles.buttons} color="primary">
        {intl('APPLYING_MODAL.provide')}
      </Button>
    </Box>
  );

  return (
    <Modal isOpen={open} handleClose={handleClose} title={null} disabledBorder className={styles.modal} dialogActionsComponent={<Actions />}>
      <Box className={styles.content}>
        <WarningIcon className={styles.warnIcon} />
        <Typography className={styles.title}>{intl('VERIFY_MODAL.toCreate')}</Typography>
        <ul className={styles.problemsList}>
          {!errors.hasProfileInfo && <li>{intl('SHARED.profileInfo')}</li>}
          {!errors.hasBrandInfo && <li>{intl('VERIFY_MODAL.brandInfo')}</li>}
          {!errors.isVerified && <li>{intl('SHARED.verifyAcc')}</li>}
        </ul>
      </Box>
    </Modal>
  );
};

export default VerifyModal;

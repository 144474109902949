import { gql } from '@apollo/client';
import { UpdateApplicantOptionsInput, ApplicantOptions } from 'types/brand';

const UPDATE_BRAND_PROFILE = gql`
  mutation UpdateBrandProfile(
    $companyName: String
    $about: String
    $cityId: ID
    $industryId: String
    $image: String
    $privateInfo: BrandPrivateInfoInput
    $isPrivate: Boolean
  ) {
    updateBrandProfile(
      data: {
        companyName: $companyName
        about: $about
        cityId: $cityId
        industryId: $industryId
        image: $image
        privateInfo: $privateInfo
        isPrivate: $isPrivate
      }
    ) {
      id
      companyName
      about
      country {
        id
        name
      }
      city {
        id
        name
      }
      industry {
        id
        name
      }
      privateInfo {
        street
        postalCode
        VATnumber
        IBAN
        bankAccount
      }
      isPrivate
      image
    }
  }
`;

const UPDATE_BRAND_USER = gql`
  mutation UpdateBrandUser($firstName: String, $lastName: String, $phone: String, $image: String) {
    updateBrandUser(data: { firstName: $firstName, lastName: $lastName, phone: $phone, image: $image }) {
      id
      firstName
      phone
      email
      lastName
      image
    }
  }
`;

const UPDATE_USER_FAVOURITE = gql`
  mutation UpdateUserFavourite($entityType: FavouriteEntityTypes!, $entityId: ID!) {
    updateUserFavourite(data: { entityType: $entityType, entityId: $entityId }) {
      entityType
      entityId
    }
  }
`;

const GET_CURRENT_BRAND = gql`
  query GetCurrentBrand {
    getCurrentBrand {
      id
      companyName
      about
      verificationStatus
      country {
        id
        name
      }
      city {
        id
        name
      }
      industry {
        id
        name
      }
      image
      feedback {
        id
        rate
        opportunityId
        description
        createdAt
        author {
          id
          name
          image
        }
      }
      isPrivate
      privateInfo {
        street
        postalCode
        VATnumber
        IBAN
        bankAccount
      }
      verificationStatus
    }
  }
`;

const GET_BRAND_VERIFICATION_STATUS = gql`
  query GetBrandUser {
    getBrandUser {
      verificationStatus
    }
  }
`;

const GET_BRAND_USER = gql`
  query GetBrandUser {
    getBrandUser {
      id
      brandId
      firstName
      lastName
      email
      phone
      image
      verificationStatus
      linkedAccounts {
        providerName
        impressionsCount
        accountName
        accountId
      }
    }
  }
`;

const GET_BRAND_BY_ID = gql`
  query GetBrand($brandId: ID!) {
    getBrand(brandId: $brandId) {
      id
      companyName
      about
      verificationStatus
      createdAt
      country {
        id
        name
      }
      city {
        id
        name
      }
      industry {
        id
        name
      }
      manager {
        firstName
        lastName
        id
      }
      image
      feedback {
        id
        rate
        opportunityId
        description
        createdAt
        author {
          id
          name
          image
        }
      }
      createdAt
      privateInfo {
        street
        postalCode
        VATnumber
        IBAN
        bankAccount
      }
      isPrivate
      isFavourite
    }
  }
`;

const GET_BRAND_TALENTS = gql`
  query BrandListTalents($pagination: PaginationFilter, $search: String, $filter: TalentsBrandFilter) {
    brandListTalents(pagination: $pagination, search: $search, filter: $filter) {
      items {
        id
        firstName
        lastName
        sport {
          id
          name
        }
        country {
          id
          name
        }
        city {
          id
          name
        }
        phone
        competition
        club
        tags
        about
        gender
        image
        email
        createdAt
        averageFeedback
        social {
          impressionsCount
          accountId
          providerName
          accountName
          confirmed
        }
        videos {
          type
          link
        }
        isFavourite
      }
      meta {
        total
      }
      filters {
        cities
        genders
        countries
        tags
        clubs
        sports
      }
    }
  }
`;

const TOGGLE_SHORTLIST_TALENT = gql`
  mutation updateApplicantOptions($applicationId: ID!, $data: UpdateApplicantOptionsInput!) {
    updateApplicantOptions(applicationId: $applicationId, data: $data) {
      shortlisted
      applicationId
    }
  }
`;

const GET_NEWLY_REGISTERED_TALENTS = gql`
  query listNewlyTalents($pagination: PaginationFilter) {
    listNewlyTalents(pagination: $pagination) {
      items {
        id
        firstName
        lastName
        image
        createdAt
      }
      meta {
        total
      }
    }
  }
`;

export {
  UPDATE_BRAND_PROFILE,
  GET_CURRENT_BRAND,
  GET_BRAND_USER,
  UPDATE_BRAND_USER,
  GET_BRAND_BY_ID,
  GET_BRAND_TALENTS,
  TOGGLE_SHORTLIST_TALENT,
  UPDATE_USER_FAVOURITE,
  GET_BRAND_VERIFICATION_STATUS,
  GET_NEWLY_REGISTERED_TALENTS,
};

export type UpdateApplicantOptionsVars = {
  applicationId: string;
  data: UpdateApplicantOptionsInput;
};

export type UpdateApplicantOptionsData = {
  updateApplicantOptions: ApplicantOptions;
};

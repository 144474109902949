import inRange from 'lodash/inRange';

import { ProfitMargins } from 'types/profitMargins';

const getAdditionalSum = (budget: number, percentage: number): number => (budget * percentage) / 100;

const calcFinalBudget = (budget: number, profitMargins: ProfitMargins): number => {
  if (!profitMargins || !budget) return null;

  const { firstRange, secondRange, thirdRange } = profitMargins;

  if (inRange(budget, firstRange.range.from, firstRange.range.to)) return budget + getAdditionalSum(budget, firstRange.percentage);
  if (inRange(budget, secondRange.range.from, secondRange.range.to)) return budget + getAdditionalSum(budget, secondRange.percentage);

  return budget + getAdditionalSum(budget, thirdRange.percentage);
};

const calcTalentSalary = (finalBudget: number, profitMargins: ProfitMargins): number => {
  if (!profitMargins || !finalBudget) return null;

  const { firstRange, secondRange, thirdRange } = profitMargins;

  const budgetWithoutVAT = (finalBudget * 100) / 123;

  if (inRange(finalBudget, firstRange.range.from, firstRange.range.to))
    return +(budgetWithoutVAT - getAdditionalSum(budgetWithoutVAT, firstRange.percentage)).toFixed(2);
  if (inRange(finalBudget, secondRange.range.from, secondRange.range.to)) {
    return +(budgetWithoutVAT - getAdditionalSum(budgetWithoutVAT, secondRange.percentage)).toFixed(2);
  }
  return +(budgetWithoutVAT - getAdditionalSum(budgetWithoutVAT, thirdRange.percentage)).toFixed(2);
};

export { calcFinalBudget, calcTalentSalary };

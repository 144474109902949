import { makeStyles } from '@material-ui/core'
import { constants } from '../../theme/constants'
import { colors } from '../../theme/colors'

export const useStyles = makeStyles(() => ({
  footer: {
    background: colors.HEADER_FOOTER,
  },
  footerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: constants.FOOTER_WRAPPER_WIDTH,
    height: constants.FOOTER_HEIGHT,
    maxWidth: '95%',
    margin: '0 auto',
  },
  footerLink: {
    marginRight: 32,
    textTransform: 'uppercase',
    textDecoration: 'none',
    fontWeight: 500,
    color: colors.SECONDARY_TEXT,
    '&:last-child': {
      marginRight: 0,
    },
    '&:hover': {
      backgroundColor: colors.BORDER,
    },
  },
}))

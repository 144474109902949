import React from 'react'
import { Dialog, Button } from '@material-ui/core'
import { ArrowBackIos } from '@material-ui/icons'
import { useStyles } from './styles'
import { Header } from '../../../Header'
import Chat from '../../Chat'
import { intl } from '../../../../helpers'

const ChatWidget = ({
  open,
  onClose,
  conversation,
  subscribeToMore,
  fetchMore,
  currentApplicant,
  disabled,
  opportunityId,
  opportunityStatus,
}) => {
  const classes = useStyles()

  const chatProps = {
    className: classes.chatContainer,
    conversation,
    subscribeToMore,
    fetchMore,
    currentApplicant,
    disabled,
    opportunityId,
    opportunityStatus,
  }

  return (
    <Dialog
      disableBackdropClick
      fullScreen
      open={open}
      onClose={onClose}
      classes={{
        paperFullScreen: classes.chatModalContent,
      }}
    >
      <Header />
      <Button
        onClick={onClose}
        className={classes.btnBack}
        startIcon={<ArrowBackIos className={classes.arrowIcon} />}
      >
        {intl('CHAT_WIDGET.btnBack')}
      </Button>
      <Chat {...chatProps} />
    </Dialog>
  )
}

export default ChatWidget

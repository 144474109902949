import React from 'react';
import { Switch, Route, Router, Redirect } from 'react-router-dom';

import { ErrorPage } from 'pages/ErrorPage';
import { Login } from 'pages/Auth/Login';
import { Restore } from 'pages/Auth/Restore';
import { SignUp } from 'pages/Auth/SingUp';
import { SignUpUser } from 'pages/Auth/SignUpUser';
import { Confirm } from 'pages/Auth/Confirm';
import { CreateNewPassword } from 'pages/Auth/CreateNewPassword';
import { ComingSoon } from 'pages/ComingSoon';
import { MaintenancePage } from 'pages/MaintenancePage';

import { App } from './app';
import { useAuth } from '../hooks/auth';
import { Admin } from './admin';
import { Unregistered } from './unregistered';
import { routes } from './routesList';

const UNREGISTERED_ROUTES = [`/${routes.NEWS_FEED}`, `/${routes.NEWS_FEED}/:id`];

export const Routes = ({ history }) => {
  const { userLoading, user, getRole } = useAuth();

  if (userLoading && !user) {
    return <div />; //  TODO add loader
  }

  return (
    <Router history={history}>
      <Switch>
        {/* Uncomment to render MaintenancePage only */}
        {/* <Route>
          <MaintenancePage />
        </Route> */}

        <Route path={routes.LOGIN} exact>
          <Login />
        </Route>
        <Route path={`/${routes.SIGN_UP}/:role`} exact>
          <SignUp />
        </Route>
        <Route path={`/${routes.SIGN_UP}/:role/invitation`} exact>
          <SignUpUser />
        </Route>
        <Route path="/restore" exact>
          <Restore />
        </Route>
        <Route path="/create-password" exact>
          <CreateNewPassword />
        </Route>
        <Route path="/confirm" exact>
          <Confirm />
        </Route>
        {getRole() === routes.ADMIN && (
          <Route path={`/${routes.ADMIN}/:page?`}>
            <Admin />
          </Route>
        )}
        <Route path={routes.ERROR_PAGE} exact>
          <ErrorPage />
        </Route>

        {['talent', 'agent', 'brand'].includes(getRole()) && (
          <Route path="/">
            <App />
          </Route>
        )}
        <Route path={UNREGISTERED_ROUTES.map((route) => route)}>
          <Unregistered />
        </Route>
        <Route path="/" exact>
          <Redirect to={`/${routes.NEWS_FEED}`} />
          {/* <ComingSoon /> */}
        </Route>

        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    </Router>
  );
};

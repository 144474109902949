import { makeStyles } from '@material-ui/core';
import { colors } from 'theme/colors';

export const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    position: 'relative',
    flexWrap: 'nowrap',
    [theme.breakpoints.down(1024)]: {
      background: colors.BACKGROUND,
    },
  },
  chat: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      height: 518,
    },
    [theme.breakpoints.down(1024)]: {
      display: 'none',
    },
    '@media (min-height: 1200px)': {
      height: 'auto',
    },
  },
  details: {
    minWidth: 280,
    background: colors.WHITE,
    borderRadius: 8,
    boxShadow: '0px 6px 20px rgba(2, 1, 86, 0.05)',
    [theme.breakpoints.down(1024)]: {
      position: 'relative',
      flexGrow: 1,
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  btnChat: {
    position: 'fixed',
    bottom: 80,
    right: theme.spacing(2),
    background: colors.PRIMARY,
    boxShadow: '0px 7px 20px rgba(0, 0, 0, 0.35)',
    zIndex: 2,
    '& svg': {
      fill: colors.WHITE,
    },
  },
  badge: {
    background: colors.ERROR,
    color: colors.WHITE,
    border: '2px solid',
    left: -9,
    bottom: -3,
  },
}));

/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  TextField,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Hidden,
  Grid,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import YouTube from 'react-youtube';
import { useLocation } from 'react-router-dom';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Add, ClearRounded, DeleteForeverOutlined } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { useFormik } from 'formik';
import { useQuery, useMutation } from '@apollo/client';
import { Storage } from 'aws-amplify';
import * as yup from 'yup';
import clsx from 'clsx';

import { intl, getYoutubeId, getMaxAvailableDate } from 'helpers';
import { useLink } from 'hooks/linkTo';
import { routes } from 'router/routesList';

import { LIST_SPORTS } from 'services/graphql/sports';
import { LIST_COUNTRIES, LIST_CITIES } from 'services/graphql/location';
import { GET_CURRENT_TALENT, UPDATE_TALENT_PROFILE, UPDATE_VIDEO_TALENT, DELETE_VIDEO_TALENT, UPDATE_LINKED_ACCOUNT } from 'services/graphql/talent';

import { SaveBar } from 'components/SaveBar';
import { AvatarEditable } from 'components/AvatarEditable';
import { Select } from 'components/Select';
import { ChipInput } from 'components/ChipInput';
import { VideoPlaceholder } from 'components/VideoPlaceholder';
import { LinkedAccounts } from 'components/LinkedAccounts';
import { Loader } from 'components/Loader';
import { Modal } from 'components/Modal';

import { useStyles } from './styles';

const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env;

export const PublicInfo = ({ handleSetImageKey, imageKey }) => {
  const styles = useStyles({});
  const linkTo = useLink({});
  const isSm = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const { state } = useLocation();

  const isRequiredField = !!state?.referrer;

  const [isInitialLoad, setInitialLoad] = useState(true);

  const [youTubeModalState, setYouTubeModalState] = useState(false);
  const [youTubeLink, setYouTubeLink] = useState(false);
  const [youTubeLinkError, setYouTubeLinkError] = useState(false);

  const [videoIdToDelete, setVideoIdToDelete] = useState(null);
  const [videoDeleteModal, setVideoDeleteModal] = useState(false);
  const toggleVideoDeleteModal = () => setVideoDeleteModal((vModal) => !vModal);

  const handleDeleteVideoBtn = (id) => () => {
    setVideoIdToDelete(id);
    toggleVideoDeleteModal();
  };

  const validationSchema = yup.object({
    firstName: yup.string().nullable(),
    lastName: yup.string().nullable(),
    image: yup.mixed(),
    gender: yup.string().nullable(),
    private: yup.mixed().notRequired(),
    club: yup.array().of(yup.string()).notRequired(),
    tags: yup.array().of(yup.mixed()).notRequired().nullable(),
    country: yup.object({}).nullable(),
    city: yup.object({}).nullable(),
    sport: yup
      .object({
        name: yup.mixed(),
      })
      .nullable(),
    videos: yup.array().of(yup.mixed()).notRequired(),
    competition: yup.string(),
    birth: yup.mixed(),
  });

  const handleDeleteVideoConfirm = async () => {
    await deleteVideoTalent({ variables: { videoId: videoIdToDelete } });
    setVideoIdToDelete(null);
    setFieldValue(
      'videos',
      values.videos.filter((video) => video.id !== videoIdToDelete),
    );
    toggleVideoDeleteModal();
  };

  const handleYouTubeModalOpen = () => setYouTubeModalState(true);
  const handleYouTubeModalClose = () => setYouTubeModalState(false);

  const handleYouTubeModalConfirm = () => {
    if (!getYoutubeId(youTubeLink)) {
      setYouTubeLinkError(true);
      return;
    }
    updateVideoTalent({ variables: { link: youTubeLink, type: 'youtube' } });
    setYouTubeLink('');
    handleYouTubeModalClose();
  };

  const genders = [
    { value: 'male', name: intl('SHARED.male') },
    { value: 'female', name: intl('SHARED.female') },
  ];

  const profile = [
    { value: 'public', name: intl('SHARED.public') },
    { value: 'private', name: intl('SHARED.private') },
  ];

  const activity = [
    { value: 'true', name: intl('SHARED.competitive') },
    { value: 'false', name: intl('SHARED.retired') },
  ];

  const [updateCurrentTalent, { loading: talentIsUpdating }] = useMutation(UPDATE_TALENT_PROFILE, {
    onCompleted({ updateTalentProfile }) {
      if (state?.fromSignUp) {
        linkTo(routes.TALENT_PRIVATE_INFO, { fromSignUp: true })();
        setValues(updateTalentProfile);
      } else if (state?.referrer) {
        linkTo(state.referrer)();
        // delete state.referrer;
      } else {
        linkTo(routes.TALENT_PUBLIC_PAGE)();
        setValues(updateTalentProfile);
      }
    },
  });

  const [updateVideoTalent] = useMutation(UPDATE_VIDEO_TALENT, {
    onCompleted({ uploadVideoTalent: video }) {
      setFieldValue('videos', [...values.videos, video]);
    },
  });

  const [deleteVideoTalent, { loading: deleteLoading }] = useMutation(DELETE_VIDEO_TALENT, {
    refetchQueries: [{ query: GET_CURRENT_TALENT }],
    awaitRefetchQueries: true,
  });

  const [updateLinkedAccount] = useMutation(UPDATE_LINKED_ACCOUNT, {
    refetchQueries: [{ query: GET_CURRENT_TALENT }],
    awaitRefetchQueries: true,
  });

  const handleUserUpdate = (valuesToUpdate) => {
    updateCurrentTalent({
      variables: {
        firstName: valuesToUpdate.firstName.trim() || undefined,
        lastName: valuesToUpdate.lastName.trim() || undefined,
        countryId: valuesToUpdate?.country?.id || undefined,
        cityId: valuesToUpdate?.city?.id || undefined,
        sportId: valuesToUpdate?.sport?.id || undefined,
        competition: valuesToUpdate.competition === 'true' ? true : valuesToUpdate.competition === 'false' ? false : undefined,
        isPrivate: valuesToUpdate.private === profile[1].value,
        club: valuesToUpdate.club?.filter((club) => club).map((club) => club.trim()) || undefined,
        tags: valuesToUpdate.tags?.map((tag) => tag.trim()) || undefined,
        about: valuesToUpdate.about || undefined,
        gender: valuesToUpdate.gender || undefined || undefined,
        image: imageKey || undefined,
        videos: valuesToUpdate.videos || undefined,
        birth: +valuesToUpdate.birth || undefined,
        fieldsVisibility: [
          { fieldName: 'gender', isVisible: !valuesToUpdate.hideGender },
          { fieldName: 'age', isVisible: !valuesToUpdate.hideBirth },
        ],
      },
    });
    handleSetImageKey(null);
  };

  const handleUpdateLinkedAccount = (provider, payload) => {
    updateLinkedAccount({
      variables: {
        provider,
        payload,
      },
    });
  };

  const { values, handleChange, setValues, setFieldValue, setFieldTouched, handleSubmit } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      image: '',
      gender: '',
      birth: null,
      hideBirth: false,
      hideGender: false,
      private: '',
      club: [''],
      tags: [],
      country: '',
      city: '',
      sport: '',
      videos: [],
      status: '',
    },
    validationSchema,
    validateOnChange: true,
    onSubmit: handleUserUpdate,
    // enableReinitialize: true,
  });

  const { loading: talentLoading, data: talentData } = useQuery(GET_CURRENT_TALENT, {
    onCompleted({ getCurrentTalent }) {
      if (isInitialLoad) {
        setInitialLoad(false);
        const getHideField = (field) => getCurrentTalent.fieldsVisibility.find(({ fieldName }) => fieldName === field).isVisible;
        setValues(
          {
            ...getCurrentTalent,
            firstName: getCurrentTalent?.firstName || '',
            lastName: getCurrentTalent?.lastName || '',
            gender: getCurrentTalent?.gender || '',
            club: getCurrentTalent?.club || [''],
            videos: getCurrentTalent?.videos || [],
            private: getCurrentTalent?.isPrivate ? profile[1].value : profile[0].value,
            competition: getCurrentTalent?.competition === null ? undefined : getCurrentTalent?.competition,
            hideBirth: !getHideField('age'),
            hideGender: !getHideField('gender'),
            country: getCurrentTalent?.country?.name ? getCurrentTalent.country : '',
            city: getCurrentTalent?.city?.name ? getCurrentTalent.city : '',
          },
          false,
        );
      }
    },
  });

  useEffect(() => {
    setFieldValue('image', talentData?.getCurrentTalent.image, false);
  }, [talentData?.getCurrentTalent.image]);

  const onCancel = () => {
    if (state?.fromSignUp) linkTo(routes.TALENT_PRIVATE_INFO, { fromSignUp: true })();
    else if (state?.referrer) linkTo(state?.referrer)();
    else linkTo(routes.TALENT_PUBLIC_PAGE)();
  };

  const { data: sports, loading: sportsLoading } = useQuery(LIST_SPORTS);
  const { data: countries, loading: countriesLoading } = useQuery(LIST_COUNTRIES);

  const { data: cities, loading: citiesLoading } = useQuery(LIST_CITIES, {
    skip: !values.country?.id,
    variables: {
      countryId: values.country && values.country.id,
    },
  });

  const handleAutocompleteChange = (name) => (_, value) => {
    if (name === 'country' && value !== values.country) {
      setFieldValue('city', null);
    }

    setFieldValue(name, value);
  };

  const handleAddClub = () => {
    setFieldValue('club', [...values.club, '']);
  };

  const handleRemoveClub = (index) => {
    setFieldValue(
      'club',
      values.club.filter((_, i) => i !== index),
    );
  };

  const handleTagsChange = (tags) => {
    setFieldValue('tags', tags);
  };

  const handleVideoChange = async (e) => {
    const [file] = e.target.files;

    const parts = file.name.split('.');
    const normalizedName = `${new Date().valueOf()}-${Math.random()}${parts.length ? '.'.concat(parts[parts.length - 1]) : ''}`;
    const { key } = await Storage.put(`temp/${normalizedName}`?.toLowerCase(), file, {
      contentType: file.type,
    });

    updateVideoTalent({ variables: { type: 'video', link: key } });
  };

  const handleAgeChange = (date) => {
    setFieldTouched('birth', true);
    setFieldValue('birth', date);
  };

  return (
    <Box className={styles.publicInfo}>
      <Box className={styles.publicInfoFormAndNav}>
        <Box>
          <Paper
            className={clsx(styles.publicInfoForm, {
              [styles.fullWidth]: talentLoading,
            })}
          >
            {talentLoading ? (
              <Loader />
            ) : (
              <>
                <Grid container className={styles.publicInfoBlock}>
                  <Grid container item sm={6} justify="space-between" alignItems="baseline">
                    <Hidden mdUp>
                      <AvatarEditable
                        handleSuccess={handleSetImageKey}
                        error={isRequiredField && !values.image}
                        image={values.image}
                        imgAlt={values.companyName?.[0]}
                      />
                    </Hidden>
                  </Grid>

                  <Grid item md={12} sm={6} container>
                    <Grid item container sm={12} md={6}>
                      <TextField
                        name="firstName"
                        error={isRequiredField && !values.firstName}
                        helperText={isRequiredField && !values.firstName && intl('OPPORTUNITIES.requiredField')}
                        className={styles.input}
                        value={values.firstName}
                        onChange={handleChange}
                        label={intl('SHARED.firstName')}
                      />
                    </Grid>
                    <Grid item container sm={12} md={6}>
                      <TextField
                        name="lastName"
                        error={isRequiredField && !values.lastName}
                        helperText={isRequiredField && !values.lastName && intl('OPPORTUNITIES.requiredField')}
                        className={styles.input}
                        value={values.lastName}
                        onChange={handleChange}
                        label={intl('SHARED.lastName')}
                      />
                    </Grid>
                    <Grid item container sm={12} md={6}>
                      <Select
                        label={intl('SHARED.status')}
                        containerProps={{ className: styles.input }}
                        onChange={handleChange}
                        name="private"
                        options={profile}
                        value={values.private}
                        helperText={
                          values.private === profile[1].value ? intl('TALENT.PUBLIC_INFO.visibleText') : intl('TALENT.PUBLIC_INFO.invisibleText')
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container className={styles.publicInfoBlock}>
                  <Grid item container xs={12} sm={6}>
                    <Select
                      name="gender"
                      helperText={isRequiredField && !values?.gender && intl('OPPORTUNITIES.requiredField')}
                      containerProps={{
                        className: clsx(styles.input, styles.noMarginInput),
                        error: isRequiredField && !values?.gender,
                      }}
                      value={values.gender}
                      onChange={handleChange}
                      label={intl('SHARED.gender')}
                      options={genders}
                    />
                    <FormControlLabel
                      className={styles.checkboxWrapper}
                      classes={{ label: styles.checkboxLabel }}
                      control={<Checkbox size="small" color="primary" name="hideGender" onChange={handleChange} checked={values.hideGender} />}
                      label={intl('SHARED.hideCheckbox')}
                      disabled={!values.gender}
                    />
                  </Grid>

                  <Grid item container xs={12} sm={6}>
                    <KeyboardDatePicker
                      className={clsx(styles.input, styles.noMarginInput, styles.date)}
                      clearable
                      format="dd.MM.yyyy"
                      name="birth"
                      error={isRequiredField && !values?.birth}
                      helperText={isRequiredField && !values?.birth && intl('OPPORTUNITIES.requiredField')}
                      value={values.birth}
                      maxDate={getMaxAvailableDate()}
                      label={intl('SHARED.birthDate')}
                      onChange={handleAgeChange}
                      fullWidth
                      disableFuture
                    />
                    <FormControlLabel
                      className={styles.checkboxWrapper}
                      classes={{ label: styles.checkboxLabel }}
                      control={<Checkbox size="small" color="primary" name="hideBirth" onChange={handleChange} checked={values.hideBirth} />}
                      label={intl('SHARED.hideCheckbox')}
                    />
                  </Grid>
                </Grid>

                <Grid container className={styles.publicInfoBlock}>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      name="country"
                      loading={countriesLoading}
                      className={styles.input}
                      options={countries ? countries.listCountries : []}
                      value={values?.country}
                      onChange={handleAutocompleteChange('country')}
                      getOptionLabel={(option) => option.name || ''}
                      getOptionSelected={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={isRequiredField && !values?.country?.name}
                          helperText={isRequiredField && !values?.country?.name && intl('OPPORTUNITIES.requiredField')}
                          label={intl('SHARED.country')}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      name="city"
                      loading={citiesLoading}
                      className={styles.input}
                      disabled={!values.country?.id}
                      options={cities ? cities.listCities : []}
                      value={values?.city}
                      onChange={handleAutocompleteChange('city')}
                      getOptionLabel={(option) => option.name || ''}
                      getOptionSelected={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={isRequiredField && !values?.city?.name}
                          helperText={isRequiredField && !values?.city?.name && intl('OPPORTUNITIES.requiredField')}
                          name="city"
                          label={intl('SHARED.city')}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container className={styles.publicInfoBlock}>
                  <Grid item container xs={12} sm={6}>
                    <Autocomplete
                      name="sport"
                      loading={sportsLoading}
                      className={styles.input}
                      options={sports ? sports.listSports : []}
                      value={values.sport}
                      onChange={handleAutocompleteChange('sport')}
                      getOptionLabel={(option) => option.name || ''}
                      getOptionSelected={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={intl('SHARED.kindOfSport')}
                          error={isRequiredField && !values?.sport?.name}
                          helperText={isRequiredField && !values?.sport?.name && intl('OPPORTUNITIES.requiredField')}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item container xs={12} sm={6}>
                    <Select
                      label={intl('SHARED.activity')}
                      onChange={handleChange}
                      name="competition"
                      containerProps={{ className: styles.input, error: isRequiredField && !values?.competition }}
                      helperText={isRequiredField && !values?.competition && intl('OPPORTUNITIES.requiredField')}
                      options={activity}
                      className={styles.smallPadding}
                      value={`${values.competition ?? ''}`}
                    />
                  </Grid>
                  <Grid item container xs={12} sm={6}>
                    <TextField
                      name="club.0"
                      className={styles.input}
                      value={values.club?.[0] ?? ''}
                      onChange={(e) => setFieldValue('club.0', e.target.value)}
                      label={intl('SHARED.club')}
                    />
                  </Grid>
                  {values.club?.length > 1 &&
                    values.club?.map((clubName, i) => {
                      if (i !== 0)
                        return (
                          <Grid item key={`${i}`} container xs={12} sm={6}>
                            <TextField
                              name={`club.${i}`}
                              className={styles.input}
                              value={clubName}
                              onChange={handleChange}
                              label={intl('SHARED.club')}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton onClick={() => handleRemoveClub(i)} size="small">
                                      <ClearRounded fontSize="small" />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                        );
                    })}
                </Grid>

                <Button onClick={handleAddClub} startIcon={<Add />} color="primary">
                  {intl('TALENT.PUBLIC_INFO.oneMoreClubOrTeam')}
                </Button>
              </>
            )}
          </Paper>
          {!talentLoading && (
            <>
              <LinkedAccounts social={talentData?.getCurrentTalent?.social} handleUpdateLinkedAccount={handleUpdateLinkedAccount} />
              <Paper className={styles.about}>
                <ChipInput
                  defaultValue={values?.tags}
                  handleChange={handleTagsChange}
                  fullWidth
                  label={isSm ? intl('TALENT.PUBLIC_INFO.tagsShort') : intl('TALENT.PUBLIC_INFO.tags')}
                />
              </Paper>
              <Paper className={styles.about}>
                <TextField name="about" multiline onChange={handleChange} value={values.about || ''} fullWidth label={intl('SHARED.about')} />
              </Paper>
              <Paper className={styles.video}>
                {values.videos?.map((v) =>
                  v.type === 'youtube' ? (
                    <Box position="relative">
                      <YouTube className={styles.videoItem} key={v.link} videoId={getYoutubeId(v.link)} />
                      <Button variant="contained" onClick={handleDeleteVideoBtn(v.id)} className={styles.deleteVideoBtn}>
                        <DeleteForeverOutlined />
                      </Button>
                    </Box>
                  ) : (
                    <Box position="relative">
                      <video key={v.link} className={styles.videoItem} controls>
                        <source src={REACT_APP_AWSS3_URL_IMAGE_BEG + v.link} type="video/mp4" />
                      </video>
                      <Button variant="contained" onClick={handleDeleteVideoBtn(v.id)} className={styles.deleteVideoBtn}>
                        <DeleteForeverOutlined />
                      </Button>
                    </Box>
                  ),
                )}
                <VideoPlaceholder handleYoutubeClick={handleYouTubeModalOpen} handleVideoChange={handleVideoChange} />
              </Paper>
            </>
          )}
        </Box>
      </Box>
      <Modal
        isOpen={youTubeModalState}
        title={intl('TALENT.PUBLIC_INFO.video')}
        handleClose={handleYouTubeModalClose}
        dialogActionsComponent={
          <>
            <Button variant="outlined" onClick={handleYouTubeModalClose} className={styles.buttons}>
              {intl('SHARED.cancel')}
            </Button>
            <Button onClick={handleYouTubeModalConfirm} variant="contained" className={styles.buttons} color="primary">
              {intl('SHARED.confirm')}
            </Button>
          </>
        }
      >
        <Typography>{intl('TALENT.PUBLIC_INFO.pasteYoutubeLink')}</Typography>
        <TextField
          onChange={(e) => {
            setYouTubeLink(e.target.value);
          }}
          onFocus={() => setYouTubeLinkError(false)}
          className={styles.popupInput}
          fullWidth
          error={youTubeLinkError && intl('TALENT.PUBLIC_INFO.incorrectYoutubeLink')}
          helperText={youTubeLinkError && intl('TALENT.PUBLIC_INFO.incorrectYoutubeLink')}
          label={intl('TALENT.PUBLIC_INFO.videoUrl')}
        />
      </Modal>

      <Modal
        isOpen={videoDeleteModal}
        title={intl('TALENT.PUBLIC_INFO.deleteVideo')}
        handleClose={toggleVideoDeleteModal}
        dialogActionsComponent={
          <>
            <Button variant="outlined" onClick={toggleVideoDeleteModal} className={styles.buttons} disabled={deleteLoading}>
              {intl('SHARED.no')}
            </Button>
            <Button onClick={handleDeleteVideoConfirm} variant="contained" className={styles.buttons} color="primary" disabled={deleteLoading}>
              {intl('SHARED.yes')}
            </Button>
          </>
        }
      >
        <Typography>{intl('TALENT.PUBLIC_INFO.areYouSure')}</Typography>
      </Modal>
      <SaveBar sticky className={styles.saveBar} onSave={handleSubmit} loading={talentIsUpdating} isSignUp={state?.fromSignUp} onCancel={onCancel} />
    </Box>
  );
};

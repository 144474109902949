import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { intl } from 'helpers';
import intl_global from 'react-intl-universal';

import { InstagramIcon, FacebookIcon, TwitterIcon } from 'assets/svg-js';
import { routes } from 'router/routesList';
import { UPDATE_LINKED_ACCOUNT, GET_CURRENT_TALENT, GET_TWITTER_REQUEST_TOKEN, UPDATE_INSTAGRAM_ACCOUNT } from 'services/graphql/talent';

import { RemoveSocialModal } from 'components/Modals/RemoveSocialModal';
import { ModalSocialMedia } from 'components/Modals/ModalSocialMedia';

// import { useConnectionModal } from './useConnectionModal';
import { useStyles } from './LinkedAccountsStyle';
import { LinkedAccountActions } from './LinkedAccountActions';
import { SocialIcon } from '../SocialIcon';

const DISCONNECT_PAYLOAD = '?disconnect=true';

const parseCallbackResponse = () => {
  if (window && window.location) {
    return window.location.search;
  }
  return null;
};

export const LinkedAccounts = ({ social, onCompleted = () => {}, admin = false, talentId = undefined }) => {
  const { provider } = useParams();
  const history = useHistory();
  const [removeModalOpened, setRemoveModalOpened] = useState(false);
  const [socialModal, setSocialModal] = useState(false);
  const [err, setErr] = useState('');
  const toggleModal = () => setRemoveModalOpened((old) => !old);
  const toggleSocialModal = () => setSocialModal((old) => !old);
  const [currentProvider, setCurrentProvider] = useState(null);
  const [socialName, setSocialName] = useState('');
  // const { openConnectionModal, toggleConnectionModal, responseError, loading: connectionLoading, modalProps, modalProps2 } = useConnectionModal(
  //   socialName,
  // );

  const refetchQueries = talentId ? [] : [{ query: GET_CURRENT_TALENT }];

  const handleCloseSocialModal = () => {
    setSocialModal(false);
    if (err.length) {
      setErr('');
    }
  };

  const [updateLinkedAccount, { loading }] = useMutation(UPDATE_LINKED_ACCOUNT, {
    refetchQueries,
    awaitRefetchQueries: true,
    onError() {
      if (!admin) {
        history.replace(routes.TALENT_PUBLIC_INFO);
      }
    },
    onCompleted() {
      if (!admin) {
        history.replace(routes.TALENT_PUBLIC_INFO);
      }
      setRemoveModalOpened(false);
    },
  });

  const [updateInstagramAccount, { loading: loadingInstagram }] = useMutation(UPDATE_INSTAGRAM_ACCOUNT, {
    refetchQueries,
    awaitRefetchQueries: true,
    onError(error) {
      setErr(error?.message);
      if (!admin) {
        history.replace(routes.TALENT_PUBLIC_INFO);
      }
    },
    onCompleted() {
      if (!admin) {
        history.replace(routes.TALENT_PUBLIC_INFO);
      }
      onCompleted();
    },
  });

  useEffect(() => {
    const payload = parseCallbackResponse();
    if (provider) {
      if (payload) {
        updateLinkedAccount({ variables: { provider, payload } });
      }
    }
  }, []);

  const { data: twitterPreRequest } = useQuery(GET_TWITTER_REQUEST_TOKEN);

  // Move to actions
  const handleLinkAccount = {
    instagram: () => {
      setSocialName('instagram');
      toggleSocialModal();
      // toggleConnectionModal();
    },
    facebook: () => {
      setSocialName('facebook');
      toggleSocialModal();
      // toggleConnectionModal();
    },

    twitter: async () => {
      const endpoint = 'https://api.twitter.com/oauth/authenticate';
      const {
        getTwitterRequestToken: { oauthToken },
      } = twitterPreRequest;
      window.location.href = `${endpoint}/?oauth_token=${oauthToken}`;
    },
  };

  const getFormattedFollowers = (followersCount) => Intl.NumberFormat().format(followersCount).replaceAll(',', ' ');

  // Move to actions
  const handleUnlinkAccount = {
    instagram: async () => {
      // updateLinkedAccount({ variables: { provider: 'instagram', payload: DISCONNECT_PAYLOAD } });
      await updateInstagramAccount({
        variables: {
          data: {
            accountName: null,
            followersCount: null,
            provider: 'instagram',
          },
          talentId,
        },
      });
      setRemoveModalOpened(false);
    },
    facebook: async () => {
      // updateLinkedAccount({ variables: { provider: 'facebook', payload: DISCONNECT_PAYLOAD } });
      await updateInstagramAccount({
        variables: {
          data: {
            accountName: null,
            followersCount: null,
            provider: 'facebook',
          },
          talentId,
        },
      });
      setRemoveModalOpened(false);
    },
    twitter: () => {
      updateLinkedAccount({ variables: { provider: 'twitter', payload: DISCONNECT_PAYLOAD } });
    },
  };

  const styles = useStyles({});

  const [instAcc, facebookAcc, twitterAcc] = [
    social?.find(({ providerName }) => providerName === 'instagram'),
    social?.find(({ providerName }) => providerName === 'facebook'),
    social?.find(({ providerName }) => providerName === 'twitter'),
  ];

  const toggleAccountVisibility = {
    instagram: () => {
      updateInstagramAccount({
        variables: {
          data: {
            confirmed: !instAcc?.confirmed,
            accountName: instAcc?.accountName,
            followersCount: instAcc?.impressionsCount,
            provider: 'instagram',
          },
          talentId,
        },
      });
    },
    facebook: () => {
      updateInstagramAccount({
        variables: {
          data: {
            confirmed: !facebookAcc?.confirmed,
            accountName: facebookAcc?.accountName,
            followersCount: facebookAcc?.impressionsCount,
            provider: 'facebook',
          },
          talentId,
        },
      });
    },
  };

  const handleUnlink = (currProvider) => () => {
    setCurrentProvider(currProvider);
    setRemoveModalOpened(true);
  };

  const fbProps = {
    title: intl('SOCIAL.connect.facebook.title'),
    label: intl('SOCIAL.connect.facebook.label2'),
    secondLabel: intl('SOCIAL.connect.facebook.secondLabel'),
    numberOfFollowers: facebookAcc?.impressionsCount,
    profileLink: facebookAcc?.accountName,
    matchURL: ['facebook.com', 'fb.com'],
    submit: async ({ profileLink, numberOfFollowers }) => {
      setErr('');
      await updateInstagramAccount({
        variables: {
          data: {
            accountName: profileLink,
            followersCount: numberOfFollowers,
            provider: 'facebook',
          },
          talentId,
        },
      });
      toggleSocialModal();
    },
  };

  const instProps = {
    title: intl('SOCIAL.connect.instagram.title'),
    label: intl('SOCIAL.connect.instagram.label2'),
    secondLabel: intl('SOCIAL.connect.instagram.secondLabel'),
    numberOfFollowers: instAcc?.impressionsCount,
    profileLink: instAcc?.accountName,
    matchURL: ['instagram.com'],
    submit: async ({ profileLink, numberOfFollowers }) => {
      setErr('');
      await updateInstagramAccount({
        variables: {
          data: {
            accountName: profileLink,
            followersCount: numberOfFollowers,
            provider: 'instagram',
          },
          talentId,
        },
      });
      toggleSocialModal();
    },
  };

  const propsModal = socialName === 'instagram' ? instProps : fbProps;

  return (
    <Paper className={styles.socialsBlock}>
      <Box className={styles.socialItem}>
        <Box display="flex" alignItems="center">
          <SocialIcon icon={InstagramIcon} />
          <Box>
            <Typography className={styles.socialText}>
              {instAcc?.accountName
                ? intl_global.getHTML('TALENT.PUBLIC_INFO.instIsConnected', { url: instAcc?.accountName })
                : intl('TALENT.PUBLIC_INFO.connectAccount2', { socialProvider: 'Instagram' })}
            </Typography>
            {instAcc?.impressionsCount >= 0 && (
              <Typography className={styles.socialText}>
                {`${intl('SOCIAL.followersCount')} ${getFormattedFollowers(instAcc?.impressionsCount)}`}
              </Typography>
            )}
          </Box>
        </Box>
        <LinkedAccountActions
          account={instAcc?.accountName}
          confirmed={instAcc?.confirmed}
          handleLink={handleLinkAccount.instagram}
          handleUnlink={handleUnlink('instagram')}
          toggleAccountVisibility={toggleAccountVisibility.instagram}
          admin={admin}
        />
      </Box>
      <Box className={styles.socialItem}>
        <Box display="flex" alignItems="center">
          <SocialIcon icon={FacebookIcon} />
          <Box>
            <Typography className={styles.socialText}>
              {facebookAcc?.accountName
                ? intl_global.getHTML('TALENT.PUBLIC_INFO.fbIsConnected', { url: facebookAcc?.accountName })
                : intl('TALENT.PUBLIC_INFO.connectAccount2', { socialProvider: 'Facebook' })}
            </Typography>
            {facebookAcc?.impressionsCount >= 0 && (
              <Typography className={styles.socialText}>{`${intl('SOCIAL.followersCount')} ${getFormattedFollowers(
                facebookAcc?.impressionsCount,
              )}`}</Typography>
            )}
          </Box>
        </Box>
        <LinkedAccountActions
          account={facebookAcc?.accountName}
          confirmed={facebookAcc?.confirmed}
          handleLink={handleLinkAccount.facebook}
          handleUnlink={handleUnlink('facebook')}
          admin={admin}
          toggleAccountVisibility={toggleAccountVisibility.facebook}
        />
      </Box>
      <Box className={styles.socialItem}>
        <Box display="flex" alignItems="center">
          <SocialIcon icon={TwitterIcon} />
          <Typography className={styles.socialText}>
            {twitterAcc?.accountId
              ? intl('TALENT.PUBLIC_INFO.accIsConnected', { name: twitterAcc?.accountName })
              : intl('TALENT.PUBLIC_INFO.connectAccount', { socialProvider: 'Twitter' })}
          </Typography>
        </Box>
        <LinkedAccountActions twitter account={twitterAcc?.accountId} handleLink={handleLinkAccount.twitter} handleUnlink={handleUnlink('twitter')} />
      </Box>
      <RemoveSocialModal
        isOpen={removeModalOpened}
        handleClose={toggleModal}
        loading={loading}
        provider={currentProvider}
        onRemove={handleUnlinkAccount[currentProvider]}
      />
      {socialModal && (
        <ModalSocialMedia isOpen={socialModal} handleClose={handleCloseSocialModal} responseError={err} loading={loadingInstagram} {...propsModal} />
      )}
    </Paper>
  );
};

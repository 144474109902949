import { differenceInDays, differenceInHours, subYears } from 'date-fns';
import { useLocation } from 'react-router-dom';

import { CURRENCY, MAX_NAME_LENGTH, MIN_TALENT_AGE, PLATFORM_FEE_AMOUNT } from '../constants';
import { intl } from './intl';

/**
 * @public
 * @param {String} currency
 * @returns {String} currency symbol
 */
export const getCurrencySymbol = (currency) => {
  switch (currency) {
    case CURRENCY.EURO.label:
      return CURRENCY.EURO.value;
    case CURRENCY.USD.label:
      return CURRENCY.USD.value;
    default:
      return CURRENCY.EURO.value;
  }
};

/**
 * @public
 * @param {String} firstName
 * @param {String} lastName
 * @param {Number} [maxLength]
 * @returns {String} clipped name
 */
export const clipName = (firstName, lastName, maxLength = MAX_NAME_LENGTH) => {
  // if firstName or lastName is null
  const name = firstName || intl('SHARED.name');
  const surname = lastName || intl('SHARED.surname');
  if (name.length + surname.length > maxLength) {
    const substrEnd = maxLength - name.length;
    return `${name} ${surname.substring(0, substrEnd)}...`;
  }

  return `${name} ${surname}`;
};

/**
 * @public
 * @param {String} text
 * @param {Number} maxLength
 * @returns {String} clipped string
 */
export const clipText = (text, maxLength) => (text.length > maxLength ? `${text.substring(0, maxLength)}...` : text);

/**
 * @public
 * @param {Number} currencyValue
 * @returns {String} formatted number string
 */
export const formatNumber = (currencyValue) => Intl.NumberFormat('en-IN').format(currencyValue).replace(/,/g, ' ');

export const omit = (object = {}, fieldsToOmit = []) => {
  const omitted = {};

  if (typeof object === 'object') {
    Object.keys(object).forEach((key) => {
      if (!fieldsToOmit.includes(key)) {
        omitted[key] = object[key];
      }
    });
  }

  return omitted;
};

export const getLetterForAvatar = (firstName, lastName) => {
  if (!firstName && lastName) {
    return lastName[0];
  }
  if (firstName && !lastName) {
    return firstName[0];
  }
  if (!firstName && !lastName) {
    return '';
  }

  return `${firstName[0]} ${lastName[0]}`.toUpperCase();
};

export const getLetterForCompanyNameAvatar = (companyName) =>
  companyName
    ?.split(' ')
    ?.slice(0, 2)
    ?.map((word) => word?.[0] || '')
    .join(' ') || '';

export const getFromTodayDifference = (date, timeFlag) => {
  const today = new Date();
  const formattedStartDate = new Date(date);
  switch (timeFlag) {
    case 'hours':
      return differenceInHours(formattedStartDate, today);
    default:
      return differenceInDays(formattedStartDate, today);
  }
};

/**
 *
 * @param {Date} opportunityStartDate
 * @param {Number} price
 * @returns {Number | Boolean} cancellation fee
 */
export const getCancellationFine = (opportunityStartDate, price = 0) => {
  const difference = getFromTodayDifference(opportunityStartDate);

  if (difference <= 14) {
    if (difference <= 7) {
      return price * PLATFORM_FEE_AMOUNT * 0.25;
    }
    return price * PLATFORM_FEE_AMOUNT * 0.13;
  }
  return false;
};

export const removeEmpty = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'object') removeEmpty(obj[key]);
    else if (!obj[key]) delete obj[key];
  });

  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0) {
      delete obj[key];
    }
  });

  return obj;
};

export const getUser = () => {
  const localStorageKeys = Object.keys(localStorage);

  for (const key of localStorageKeys) {
    if (key.includes('.userData')) {
      const userData = localStorage.getItem(key);
      const { UserAttributes } = JSON.parse(userData);

      return UserAttributes.reduce(
        (userObj, item) => ({
          ...userObj,
          [item.Name.toLowerCase()]: item.Value,
        }),
        {},
      );
    }
  }
  return null;
};

export const getMaxAvailableDate = () => subYears(new Date(), MIN_TALENT_AGE);

export const useQuery = () => new URLSearchParams(useLocation().search);

import { makeStyles } from '@material-ui/core'
import { colors } from '../../../theme/colors'

export const useStyles = makeStyles((theme) => ({
  publicPage: {
    marginTop: 16,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  publicPageContent: {
    flex: 1,
    marginLeft: 24,
    '@media (max-width: 768px)': {
      marginLeft: 0,
    },
  },

  paper: {
    padding: 32,
    marginBottom: 16,
  },
  sectionHeading: {
    color: colors.TEXT,
    fontWeight: 600,
    textTransform: 'uppercase',
    marginBottom: 20,
  },
  video: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  showMore: {
    display: 'block',
    margin: '0 auto',
  },
  videoItem: {
    maxWidth: 312,
    width: 312,
    height: 186,
    borderRadius: 4,
    marginBottom: 16,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  loaderPlaceholder: {
    minHeight: 250,
    display: 'flex',
    alignItems: 'center',
  },
  backBtn: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
}))

import React from 'react';
import { Container, Avatar, Typography, Hidden } from '@material-ui/core';

import { getLetterForAvatar, getLetterForCompanyNameAvatar, intl } from 'helpers';
import { APPLICANT_STATUSES } from 'constants/index';

import { useStyles } from './styles';

const AvatarWithName = ({ hideNameSize, brand, applicants, isExpired }) => {
  const classes = useStyles();
  let avatar;
  let text;
  let letterAvatar;
  let confirmed;

  if (isExpired && !brand) {
    if (!applicants?.length) {
      return (
        <Typography className={classes.emptyText} variant="caption">
          {intl('AVATAR.noApplicants')}
        </Typography>
      );
    }
    return <Avatar />;
  }

  if (!applicants) {
    avatar = brand?.image;
    text = brand?.name;
    letterAvatar = getLetterForCompanyNameAvatar(brand?.name);
  } else if (!brand) {
    confirmed =
      applicants?.find((t) => t?.status === APPLICANT_STATUSES.CONFIRMED.value) ||
      applicants?.find((t) => t?.status === APPLICANT_STATUSES.CANCELED.value);
    if (confirmed) {
      avatar = confirmed?.image;
      text = `${confirmed?.firstName ?? ''} ${confirmed?.lastName ?? ''}`;
      letterAvatar = getLetterForAvatar(confirmed?.firstName, confirmed?.lastName);
    } else if (!applicants.length) {
      return <Avatar />;
    }
  }

  return (
    <Container className={classes.container}>
      <Avatar src={`${process.env.REACT_APP_AWSS3_URL_IMAGE_BEG}${avatar}`}>{letterAvatar}</Avatar>

      <Hidden {...(hideNameSize && { [hideNameSize]: true })}>
        <Typography variant="caption" className={classes.text}>
          {text}
        </Typography>
      </Hidden>
    </Container>
  );
};

export default AvatarWithName;

import React, { FC, useState, useEffect, useRef, Children, ReactNode } from 'react';
import { Paper, Box, Typography, IconButton, List } from '@material-ui/core';
import { NavigateNext as NextIcon, NavigateBefore as PrevIcon } from '@material-ui/icons';
import clsx from 'clsx';

import { Loader } from 'components/Loader';

import { useStyles } from './styles';

type Props = {
  title: string;
  loading?: boolean;
  className?: string;
};

const INTERVAL_VALUE = 7000;

const SlideWidget: FC<Props> = ({ title, children, loading, className }) => {
  const styles = useStyles();
  const [currentSlide, setCurrentSlide] = useState(0);
  const interval = useRef(null);

  const handleUpdateSlide = () => {
    clearInterval(interval.current);
    const id = setInterval(() => {
      setCurrentSlide((value) => (value === 2 ? 0 : value + 1));
    }, INTERVAL_VALUE);
    interval.current = id;
  };

  const handleNextSlide = () => {
    if (currentSlide === 2) {
      setCurrentSlide(0);
    } else {
      setCurrentSlide(currentSlide + 1);
    }
    handleUpdateSlide();
  };

  const handlePrevSlide = () => {
    if (currentSlide === 0) {
      setCurrentSlide(2);
    } else {
      setCurrentSlide(currentSlide - 1);
    }
    handleUpdateSlide();
  };

  useEffect(() => {
    const id = setInterval(() => {
      setCurrentSlide((value) => (value === 2 ? 0 : value + 1));
    }, INTERVAL_VALUE);
    interval.current = id;
    return () => clearInterval(interval.current);
  }, []);

  const perChunk = 4;

  const dividedItems = Children.toArray(children).reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  return (
    <Paper className={clsx(styles.container, className)}>
      <Box className={styles.heading}>
        <Typography className={styles.title}>{title}</Typography>
      </Box>
      {loading ? <Loader className={styles.loader} /> : <List>{dividedItems[currentSlide]?.map((i: ReactNode) => i)}</List>}
      <Box className={styles.controls}>
        <IconButton className={styles.btnControl} onClick={handlePrevSlide}>
          <PrevIcon />
        </IconButton>
        <Box className={styles.circles}>
          <Box className={clsx(styles.circle, { [styles.selected]: currentSlide === 0 })} />
          <Box className={clsx(styles.circle, { [styles.selected]: currentSlide === 1 })} />
          <Box className={clsx(styles.circle, { [styles.selected]: currentSlide === 2 })} />
        </Box>
        <IconButton className={styles.btnControl} onClick={handleNextSlide}>
          <NextIcon />
        </IconButton>
      </Box>
    </Paper>
  );
};

export { SlideWidget };

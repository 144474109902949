import { format, isYesterday, isToday } from 'date-fns';
import intl from 'react-intl-universal';

const getFormattedDate = (date: Date | number | string): string => {
  const bufferDate = new Date(date);

  if (isYesterday(bufferDate)) {
    return intl.get('SHARED.yesterday');
  }
  if (isToday(bufferDate)) {
    return format(bufferDate, 'hh:mm a');
  }

  return format(bufferDate, 'dd/MM/yyyy');
};

export { getFormattedDate };

import React, { FC } from 'react';
import clsx from 'clsx';
import { Box } from '@material-ui/core';

import type { Props } from './types';

import { useStyles } from './styles';

export const Wrapper: FC<Props> = ({ children, additionalContentHeight = 0, className }) => {
  const styles = useStyles({ additionalContentHeight });
  return <Box className={clsx(className, styles.wrapper)}>{children}</Box>;
};

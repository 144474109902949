import React, { createContext, useReducer } from 'react';

import { appReducer, initialState } from './store';

export const AppContext = createContext(initialState);

export const AppContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  const appState = {
    state,
    dispatch,
  };
  return <AppContext.Provider value={appState}>{children}</AppContext.Provider>;
};

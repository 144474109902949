import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 24,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  fullHeight: {
    height: '100%',
  },
  rightBlock: {
    height: '100%',
    flex: 1,
    marginLeft: 24,
    '@media (max-width: 768px)': {
      marginLeft: 0,
    },
  },
  search: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(2),
    },
  },
  showMoreContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  buttonShowMore: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  emptySearchBox: {
    marginTop: -theme.spacing(6),
    margin: '0 auto',
    height: '100%',
    maxWidth: 406,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      marginTop: -theme.spacing(3),
    },
  },
  emptySearchText: {
    marginTop: theme.spacing(3),
    fontWeight: 500,
    fontSize: 24,
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
      marginBottom: theme.spacing(2),
    },
  },
  emptyStateBox: {
    margin: '0 auto',
    position: 'relative',
    top: 100,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  emptyStateText: {
    fontWeight: 500,
    fontSize: 24,
    marginTop: -75,
  },
}));

/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import { Paper, TableRow, IconButton, TableCell, Button, Hidden, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import clsx from 'clsx';
import { nanoid } from 'nanoid';

import { intl, getCurrencySymbol, formatNumber } from 'helpers';

import { TableLoader } from 'components/TableLoader';
import { TableWithPagination } from 'components/TableWithPagination';
import OpportunityStatus from 'components/OpportunityStatus';
import { DateRangeFilter } from 'components/DateRangeFilter';
import { ExportToCsvIcon, FilterIcon, EuroCardList } from 'assets/svg-js';
import { usePaymentHistoryTable } from './usePaymentHistoryTable';

import type { Props } from './types';

import { useStyles } from './styles';

export const PaymentHistoryTable: FC<Props> = ({ query, fieldName, talentOrBrandId }) => {
  const styles = useStyles({});
  const {
    thCells,
    getOpportunityLink,
    dataToDisplay,
    createSortHandler,
    handleChangePage,
    handleChangeRowsPerPage,
    isFilterOpen,
    handleOpenFilter,
    handleDateChange,
    handleFilterReset,
    handleFilterApply,
    isFilterDisabled,
    paymentHistoryLoading,
    paymentHistoryData,
    page,
    rowsPerPage,
    order,
    orderBy,
    renderFilter,
    handleCloseFilter,
    filterDate,
  } = usePaymentHistoryTable({ query, fieldName, talentOrBrandId });

  const emptyTable = (!dataToDisplay.length && Object.keys(filterDate).every((k) => k !== null)) || paymentHistoryLoading;

  return (
    <Paper className={styles.paper}>
      <TableWithPagination
        thCells={thCells}
        className={clsx({
          [styles.fullHeight]: emptyTable,
        })}
        emptyHead={emptyTable}
        tableLabel={intl('PAYMENT_HISTORY.paymentHistory')}
        toolBarRightComponent={
          <>
            <a href={paymentHistoryData?.[fieldName]?.paymentHistoryCSV} className={styles.exportLink}>
              <Button className={styles.exportBtn} disabled={emptyTable}>
                <Hidden xsDown>{intl('PAYMENT_HISTORY.exportToCsv')}</Hidden>
                <ExportToCsvIcon className={styles.icon} />
              </Button>
            </a>
            <IconButton onClick={handleOpenFilter} disabled={emptyTable && isFilterDisabled}>
              <FilterIcon />
            </IconButton>
          </>
        }
        paginationProps={{
          count: paymentHistoryData?.[fieldName]?.meta?.total,
          page,
          rowsPerPage,
          handleChangePage,
          handleChangeRowsPerPage,
        }}
        createSortHandler={createSortHandler}
        order={order}
        orderBy={orderBy}
      >
        {paymentHistoryLoading ? (
          <TableLoader columnCount={6} />
        ) : dataToDisplay.length ? (
          dataToDisplay.map((row) => (
            <TableRow hover key={nanoid()}>
              <TableCell>
                <Link className={styles.opportunityName} to={getOpportunityLink(row.opportunityId)}>
                  {row.opportunityName}
                </Link>
              </TableCell>
              <TableCell>{row.performer?.performerName}</TableCell>
              <TableCell>{row.method}</TableCell>
              <TableCell>{format(row.date, 'dd.MM.yy')}</TableCell>
              <TableCell>
                <OpportunityStatus status={row.opportunityStatus} />
              </TableCell>
              <TableCell align="right" className={styles.price}>
                {getCurrencySymbol(row.amount?.currencyCode)} {formatNumber(row.amount?.amount)}
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell className={styles.emptyBox}>
              <EuroCardList className={styles.emptyIcon} />
              <Typography className={styles.emptyText}>{intl('EMPTY_STATE.noPaymentHistory')}</Typography>
            </TableCell>
          </TableRow>
        )}
      </TableWithPagination>
      <DateRangeFilter
        renderFilter={renderFilter}
        open={isFilterOpen}
        handleCloseFilter={handleCloseFilter}
        handleDateChange={handleDateChange}
        handleFilterReset={handleFilterReset}
        isDisabled={isFilterDisabled}
        filterDate={filterDate}
        handleFilterApply={handleFilterApply}
      />
    </Paper>
  );
};

import { Auth, Storage } from 'aws-amplify';
import { createAppSyncLink, AUTH_TYPE } from 'aws-appsync';
import { ApolloClient, InMemoryCache, createHttpLink, concat } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const { REACT_APP_GRAPHQL_ENDPOINT, REACT_APP_AWS_REGION, REACT_APP_AWSS3_BUCKET, REACT_APP_APPSYNC_AUTH_API_KEY } = process.env;

const httpLink = createHttpLink({
  uri: REACT_APP_GRAPHQL_ENDPOINT,
});

const awsLinkPrivate = createAppSyncLink({
  url: REACT_APP_GRAPHQL_ENDPOINT,
  region: REACT_APP_AWS_REGION,
  auth: {
    type: AUTH_TYPE.AWS_IAM,
    credentials: async () => {
      const credentials = await Auth.currentCredentials();
      return credentials;
    },
  },
  disableOffline: true,
});

const accessApiLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'x-api-key': REACT_APP_APPSYNC_AUTH_API_KEY,
    },
  };
});

export const AppSyncClientPublic = new ApolloClient({
  link: concat(accessApiLink, httpLink),
  cache: new InMemoryCache({
    possibleTypes: {
      NotificationInterface: [
        'SystemNotification',
        'MessageNotification',
        'OfferNotification',
        'BrandFeedbackNotification',
        'TalentFeedbackNotification',
        'UserVerificationNotification',
        'OpportunityNotification',
      ],
    },
  }),
});

export const AppSyncClientPrivate = new ApolloClient({
  link: concat(awsLinkPrivate, httpLink),
  cache: new InMemoryCache({
    possibleTypes: {
      NotificationInterface: [
        'SystemNotification',
        'MessageNotification',
        'OfferNotification',
        'BrandFeedbackNotification',
        'TalentFeedbackNotification',
        'UserVerificationNotification',
        'OpportunityNotification',
      ],
    },
  }),
});

Storage.configure({
  AWSS3: {
    bucket: REACT_APP_AWSS3_BUCKET,
    region: REACT_APP_AWS_REGION,
  },
});

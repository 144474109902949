import React from 'react'
import { Button, Box } from '@material-ui/core'

import { useStyles } from './styles'
import { intl } from '../../helpers'

export const VideoPlaceholder = ({ handleVideoChange, handleYoutubeClick }) => {
  const styles = useStyles({})
  return (
    <Box className={styles.videoPlaceholder}>
      <Button component="label" fullWidth className={styles.btn} variant="outlined">
        {intl('TALENT.PUBLIC_INFO.VIDEO_PLACEHOLDER.uploadVideo')}
        <input type="file" onChange={handleVideoChange} hidden />
      </Button>
      <Button fullWidth onClick={handleYoutubeClick} className={styles.btn} variant="outlined">
        {intl('TALENT.PUBLIC_INFO.VIDEO_PLACEHOLDER.attachYoutubeVideo')}
      </Button>
    </Box>
  )
}

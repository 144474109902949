import React from 'react';

const AgeIcon = ({ fill, ...props }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill={fill || '#535E83'} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M1 13.3366C1 12.9699 1.3 12.6699 1.66667 12.6699H4.55667V14.0033H1.66667C1.3 14.0033 1 13.7033 1 13.3366Z" fill={fill || '#535E83'} />
      <path
        d="M15 13.3366C15 12.9699 14.7 12.6699 14.3333 12.6699H11.4433V14.0033H14.3333C14.7 14.0033 15 13.7033 15 13.3366Z"
        fill={fill || '#535E83'}
      />
      <path d="M12.3333 12.6699H3.44325V14.0033H12.3333V12.6699Z" fill={fill || '#535E83'} />
      <path d="M1 1.66667C1 1.3 1.3 1 1.66667 1H4.55667V2.33333H1.66667C1.3 2.33333 1 2.03333 1 1.66667Z" fill={fill || '#535E83'} />
      <path d="M15 1.66667C15 1.3 14.7 1 14.3333 1H11.4433V2.33333H14.3333C14.7 2.33333 15 2.03333 15 1.66667Z" fill={fill || '#535E83'} />
      <path d="M12.3333 1H4.44325V2.33333H12.3333V1Z" fill={fill || '#535E83'} />
      <path
        d="M2.01385 10.8333H1.12499C1.01389 10.8333 0.976853 10.7698 1.01389 10.6428L2.47679 5.19051C2.52 5.0635 2.61259 5 2.75456 5H3.98599C4.1403 5 4.23289 5.0635 4.26375 5.19051L5.72666 10.6428C5.76369 10.7698 5.72666 10.8333 5.61555 10.8333H4.7267C4.6588 10.8333 4.61251 10.8212 4.58781 10.797C4.56312 10.7668 4.54461 10.7154 4.53226 10.6428L4.27301 9.64489H2.46753L2.20828 10.6428C2.19594 10.7154 2.17433 10.7668 2.14347 10.797C2.11878 10.8212 2.07557 10.8333 2.01385 10.8333ZM3.35638 6.09772L2.68975 8.71954H4.06006L3.39342 6.09772H3.35638Z"
        fill={fill || '#535E83'}
      />
      <path
        d="M8.96394 10.8333H7.9825C7.49486 10.8333 7.11833 10.7063 6.85291 10.4523C6.58749 10.1983 6.45478 9.83238 6.45478 9.35459V6.47875C6.45478 6.00095 6.58749 5.63504 6.85291 5.38103C7.11833 5.12701 7.49486 5 7.9825 5H8.96394C9.4454 5 9.81884 5.13003 10.0843 5.3901C10.3497 5.64412 10.4824 6.007 10.4824 6.47875V6.75091C10.4824 6.88396 10.4176 6.95049 10.288 6.95049H9.50095C9.37133 6.95049 9.30651 6.88396 9.30651 6.75091V6.53318C9.30651 6.33964 9.26639 6.20356 9.18615 6.12493C9.10591 6.04026 8.96702 5.99793 8.7695 5.99793H8.16767C7.97632 5.99793 7.83744 6.04026 7.75102 6.12493C7.67078 6.20356 7.63066 6.33964 7.63066 6.53318V9.30016C7.63066 9.49369 7.67078 9.6328 7.75102 9.71747C7.83744 9.79609 7.97632 9.83541 8.16767 9.83541H8.7695C8.96702 9.83541 9.10591 9.79609 9.18615 9.71747C9.26639 9.6328 9.30651 9.49369 9.30651 9.30016V8.72862C9.30651 8.66209 9.27257 8.62882 9.20467 8.62882H8.64913C8.51951 8.62882 8.4547 8.56532 8.4547 8.43831V7.82141C8.4547 7.6944 8.51951 7.6309 8.64913 7.6309H10.2694C10.4114 7.6309 10.4824 7.70045 10.4824 7.83955V9.35459C10.4824 9.82633 10.3497 10.1922 10.0843 10.4523C9.81884 10.7063 9.4454 10.8333 8.96394 10.8333Z"
        fill={fill || '#535E83'}
      />
      <path
        d="M14.8148 10.8333H11.7872C11.6575 10.8333 11.5927 10.7698 11.5927 10.6428V5.19051C11.5927 5.0635 11.6575 5 11.7872 5H14.8148C14.9383 5 15 5.0635 15 5.19051V5.80741C15 5.93442 14.9383 5.99793 14.8148 5.99793H12.8797C12.8056 5.99793 12.7686 6.03119 12.7686 6.09772V7.27709C12.7686 7.34361 12.8056 7.37688 12.8797 7.37688H14.463C14.5926 7.37688 14.6574 7.44038 14.6574 7.56739V8.18429C14.6574 8.3113 14.5926 8.3748 14.463 8.3748H12.8797C12.8056 8.3748 12.7686 8.40807 12.7686 8.4746V9.73561C12.7686 9.80214 12.8056 9.83541 12.8797 9.83541H14.8148C14.9383 9.83541 15 9.89891 15 10.0259V10.6428C15 10.7698 14.9383 10.8333 14.8148 10.8333Z"
        fill={fill || '#535E83'}
      />
    </svg>
  );
};

export default AgeIcon;

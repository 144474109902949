import React, { FC } from 'react';
import { List } from '@material-ui/core';
import clsx from 'clsx';

import type { Props } from './types';
import { useStyles } from './styles';
import NotificationItem from '../NotificationItem';

const NotificationList: FC<Props> = ({ className, data, handleOpenRateModal, handleClose }) => {
  const classes = useStyles();

  return (
    <List className={clsx(classes.list, className)}>
      {data.map((n) => (
        <NotificationItem key={n.id} data={n} handleOpenRateModal={handleOpenRateModal} handleClose={handleClose} />
      ))}
    </List>
  );
};

export default NotificationList;

import React from 'react';

const NotFoundImage = ({ className }) => {
  return (
    <svg width="563" height="550" className={className} viewBox="0 0 563 550" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="217" cy="230" r="159" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M211.5 46C211.5 20.871 231.871 0.5 257 0.5H673V11.5H257C237.946 11.5 222.5 26.9462 222.5 46V104H211.5V46Z"
        fill="#535E83"
      />
      <path d="M164 153C164 123.729 187.729 100 217 100C246.271 100 270 123.729 270 153V188H164V153Z" fill="#757E9C" />
      <ellipse cx="217" cy="188" rx="53" ry="13" fill="#535E83" />
      <ellipse cx="199" cy="188" rx="11" ry="3" fill="black" fillOpacity="0.4" />
      <ellipse cx="235" cy="188" rx="11" ry="3" fill="black" fillOpacity="0.4" />
      <rect x="190" y="228" width="18" height="44" rx="9" fill="#757E9C" />
      <rect x="226" y="228" width="18" height="44" rx="9" fill="#757E9C" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 680V488C0.5 462.871 20.871 442.5 46 442.5H177C196.054 442.5 211.5 427.054 211.5 408V356H222.5V408C222.5 433.129 202.129 453.5 177 453.5H46C26.9462 453.5 11.5 468.946 11.5 488V680H0.5Z"
        fill="#535E83"
      />
      <path d="M164 307C164 336.271 187.729 360 217 360C246.271 360 270 336.271 270 307V272H164V307Z" fill="#535E83" />
      <ellipse rx="53" ry="13" transform="matrix(1 0 0 -1 217 272)" fill="#535E83" />
    </svg>
  );
};

export { NotFoundImage };

import { makeStyles } from '@material-ui/core'
import { colors } from '../../theme/colors'

export const useStyles = makeStyles(() => ({
  chip: {
    background: colors.WHITE,
    border: `1px solid ${colors.PROFILE_BORDER}`,
    borderRadius: 4,
    marginRight: 8,
    marginBottom: 8,
  },
  deleteIcon: {
    display: 'flex',
    background: colors.BORDER,
    borderRadius: 2,
    width: 16,
    height: 16,
    padding: 4,
  },
  deleteIconLabel: {
    height: 0,
  },
  icon: {
    fontSize: 14,
    fill: colors.PRIMARY_INPUT_HOVERED,
  },
}))

import { makeStyles } from '@material-ui/core'
import { colors } from '../../../../theme/colors'

export const useStyles = makeStyles((theme) => ({
  btnChat: {
    position: 'fixed',
    bottom: 95,
    right: theme.spacing(2),
    background: colors.PRIMARY,
    boxShadow: '0px 7px 20px rgba(0, 0, 0, 0.35)',
    zIndex: 2,
    '& svg': {
      fill: colors.WHITE,
    },
  },
  badge: {
    background: colors.ERROR,
    color: colors.WHITE,
    border: '2px solid',
    left: -9,
    bottom: -3,
  },
}))

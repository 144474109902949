import { gql } from '@apollo/client'

// data schema
/*
    rate: Int!
    opportunityId: ID!
    description: String
*/

const CREATE_FEEDBACK = gql`
  mutation CreateFeedback($data: CreateFeedbackInput!) {
    createFeedback(data: $data) {
      id
      rate
      opportunityId
      description
      createdAt
      author {
        id
        name
        image
      }
    }
  }
`

export { CREATE_FEEDBACK }

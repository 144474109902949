import React, { FC } from 'react';
import { Dialog, Box, IconButton } from '@material-ui/core';
import { CloseRounded as CloseIcon, ArrowBackIosRounded as ArrowBackIcon, ArrowForwardIosRounded as ArrowForwardIcon } from '@material-ui/icons';
import clsx from 'clsx';

import { useStyles } from './styles';
import { Props } from './types';

const Carousel: FC<Props> = ({ open, currentImage, handleClose, handleNext, handleBack, showNavigation = true }) => {
  const classes = useStyles();

  return (
    <Dialog
      aria-labelledby="carousel-modal"
      aria-describedby="carousel-with-images"
      maxWidth="md"
      className={classes.modal}
      classes={{ paper: classes.paper }}
      open={open}
      onClose={handleClose}
      disableBackdropClick
    >
      <img src={currentImage} className={classes.image} />
      <Box className={classes.btnGroup}>
        <IconButton className={clsx(classes.iconBox, classes.btnClose)} onClick={handleClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>
        {showNavigation && (
          <Box className={classes.navigationBox}>
            <IconButton className={classes.iconBox} onClick={handleBack}>
              <ArrowBackIcon className={classes.icon} />
            </IconButton>
            <IconButton className={classes.iconBox} onClick={handleNext}>
              <ArrowForwardIcon className={classes.icon} />
            </IconButton>
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

export default Carousel;

import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    height: 'calc(100% - 86px)',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
    },
  },
  infoBox: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.only('xs')]: {
      order: 2,
    },
  },
  contentBox: {
    [theme.breakpoints.only('xs')]: {
      marginTop: -theme.spacing(5),
      marginBottom: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
    },
  },
  icon: {
    marginTop: 74,
    [theme.breakpoints.only('xs')]: {
      marginTop: -80,
      width: 360,
    },
    [theme.breakpoints.down(365)]: {
      width: 320
    },
  },
  text404: {
    fontSize: 72,
    fontWeight: 800,
    [theme.breakpoints.only('xs')]: {
      textAlign: 'center',
    },
  },
  txtNotExists: {
    marginBottom: theme.spacing(4),
    fontSize: 24,
    fontWeight: 500,
  },
  btnHomepage: {
    fontSize: 12,    
  },
}));

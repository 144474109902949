import React, { FC } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { NotFoundImage } from 'assets/svg-js';
import { Link, useParams } from 'react-router-dom';
import clsx from 'clsx';

import { InfoPost } from 'components/InfoPost';
import { Loader } from 'components/Loader';
import { GET_POST } from 'services/graphql/query/feed';
import { intl } from 'helpers/intl';

import { useStyles } from './styles';

const Post: FC = () => {
  const { id } = useParams<{ id: string }>();
  const styles = useStyles();

  const { error, data, loading } = useQuery(GET_POST, { variables: { id } });
  const { getPost: post } = data || {};

  if (!loading && (error || !post)) {
    return (
      <Box className={styles.container}>
        <Box className={styles.infoBox}>
          <Box className={styles.contentBox}>
            <Typography className={styles.text404}>{intl('EMPTY_STATE.oops')}</Typography>
            <Typography className={styles.txtNotExists}>{intl('EMPTY_STATE.noPost')}</Typography>
            <Button color="primary" variant="contained" className={styles.btnHomepage} component={Link} to="/">
              {intl('EMPTY_STATE.goToHomepage')}
            </Button>
          </Box>
        </Box>
        <NotFoundImage className={styles.icon} />
      </Box>
    );
  }

  return (
    <Box className={clsx(styles.box, { [styles.loaderBox]: loading })}>
      {loading ? <Loader /> : <InfoPost userRole="none" className={styles.post} post={post} />}
    </Box>
  );
};

export { Post };

import React from 'react'
import { Box, Typography, Hidden, Avatar } from '@material-ui/core'
import { format } from 'date-fns/esm'
import { Rating } from '@material-ui/lab'
import { useStyles } from './styles'

const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env

export const Feedback = ({ feedback }) => {
  const styles = useStyles({})
  return (
    <Box className={styles.feedback}>
      <Hidden smDown>
        <Avatar
          src={REACT_APP_AWSS3_URL_IMAGE_BEG + feedback?.author?.image}
          className={styles.logo}
        >
          {feedback?.author?.name?.split(' ')?.[0]?.[0]}
          {feedback?.author?.name?.split(' ')?.[1]?.[0]}
        </Avatar>
      </Hidden>
      <Box flex="1">
        <Box className={styles.logoWithName}>
          <Hidden mdUp>
            <Avatar
              src={REACT_APP_AWSS3_URL_IMAGE_BEG + feedback?.author?.image}
              className={styles.logo}
            >
              {feedback?.author?.name?.split(' ')?.[0]?.[0]}
              {feedback?.author?.name?.split(' ')?.[1]?.[0]}
            </Avatar>
          </Hidden>
          <Box className={styles.nameAndDate}>
            <Box className={styles.companyNameContainer}>
              <Typography className={styles.companyName}> {feedback?.author?.name}</Typography>
              <Rating
                classes={{
                  root: styles.ratingColor,
                }}
                disabled
                readOnly
                defaultValue={feedback?.rate}
              />
            </Box>
            <Typography className={styles.date}>
              {feedback?.createdAt && format(new Date(feedback?.createdAt), 'dd.MM.yy')}
            </Typography>
          </Box>
        </Box>
        <Box className={styles.mainText}>
          <pre className={styles.pre}>{feedback?.description}</pre>
        </Box>
      </Box>
    </Box>
  )
}

import { makeStyles } from '@material-ui/core'
import { colors } from '../../theme/colors'

export const useStyles = makeStyles((theme) => ({
  chip: {
    marginTop: 12,
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    background: colors.WHITE,
    border: `1px solid ${colors.PROFILE_BORDER}`,
    borderRadius: 4,
  },
  deleteIconLabel: {},
  deleteIcon: {
    padding: 10,
    background: colors.BORDER,
    borderRadius: 4,
  },
  icon: {
    width: 18,
    height: 18,
    fill: colors.PRIMARY_INPUT_HOVERED,
  },
}))

import { gql } from '@apollo/client';

const NOTIFICATION_SHARED_FIELDS = `
  id
  createdAt
  eventType
  message
`;

const LIST_NOTIFICATIONS = gql`
  query listUserNotifications ($filter: PaginationFilter, $onlyUnreadCount: Boolean) {
    listUserNotifications (filter: $filter, onlyUnreadCount: $onlyUnreadCount) {
      items {
        ... on OpportunityNotification { 
          ${NOTIFICATION_SHARED_FIELDS}
          image
          readAt
          actionCode
          opportunity {
            id
            name
            isRated
            brand {
              id
              name
              image
            }
            status {
             name
            }
            redemption {
              budget
              isTaxed
              talentSalary
              currency {
                id
                code
              }
            }
            finalCondition {
              description
              offerAmount
              talentSalary
              isTaxed
              invoiceNumber
            }
          }
        }
        ... on OfferNotification { 
          ${NOTIFICATION_SHARED_FIELDS}
          image
          readAt
          offer {
            confirmedAt
            declinedAt
            description
            id
            isTaxed
            offerAmount
            opportunityId
            talentId
          }
         }
        ... on MessageNotification { 
          ${NOTIFICATION_SHARED_FIELDS}
          readAt
          opportunityId
         }
        ... on SystemNotification { 
          ${NOTIFICATION_SHARED_FIELDS} 
          readAt
        }
        ... on UserVerificationNotification {
          ${NOTIFICATION_SHARED_FIELDS} 
          readAt
        }
      }
      meta { total }
    }
  }
`;

export { LIST_NOTIFICATIONS };

import React from 'react'
import { Typography } from '@material-ui/core'
import clsx from 'clsx'
import { useStyles } from '../Message/styles'

const PlainMessage = ({ message, clientsMessage }) => {
  const classes = useStyles()
  return (
    <Typography
      className={clsx({
        [classes.messageContainer]: true,
        [classes.text]: true,
        [classes.clientsMessage]: !clientsMessage,
      })}
    >
      {message.body !== 'undefined' ? message.body : message.coverLetter}
    </Typography>
  )
}

export default PlainMessage

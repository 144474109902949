import { makeStyles } from '@material-ui/core'
import { colors } from '../../../../../theme/colors'

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 32,
    marginBottom: 16,
  },
  sectionHeading: {
    color: colors.TEXT,
    fontWeight: 600,
    textTransform: 'uppercase',
    marginBottom: 20,
  },
  aboutText: {
    fontSize: 12,
    wordWrap: 'break-word',
    wordBreak: 'break-word',
  },
  pre: {
    fontFamily: theme.typography.fontFamily,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
}))

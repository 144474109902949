import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'theme/colors'

const useStyles = makeStyles((theme) => ({
  button: {
    width: 56,
    fontSize: 12,
    border: `1px solid ${colors.BACKGROUND2}`,
  },
  modalButtons: {
    width: 152,
    marginRight: theme.spacing(2),
    '&:last-child': {
      marginRight: 0,
    },
  },
  smallText: {
    fontSize: 12,
    marginBottom: theme.spacing(2),
  },
  rating: {
    color: colors.PRIMARY_INPUT_HOVERED,
  },
  textField: {
    marginTop: theme.spacing(6),
  },
}))

export { useStyles }

import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../../theme/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  opportunityWrapper: {
    // minHeight: 'calc(100vh - 138px)',
    width: 1024,
    maxWidth: '95%',
    margin: '0 auto',
  },
  loader: {
    marginTop: '200px',
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
      marginTop: 0,
      display: 'block',
    },
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    flexBasis: '30%',
  },
  title: {
    marginRight: theme.spacing(2),
    fontSize: 18,
    lineHeight: '27px',
  },
  tabsContainer: {
    flexBasis: '30%',
  },
  tab: {
    minWidth: 47,
    fontSize: 12,
  },
  detailsContainer: {
    display: 'flex',
    width: '100%',
    marginTop: theme.spacing(3),
    minHeight: 'calc(100vh - 148px)',
  },
  detailsWrapper: {
    width: '100%',
    display: 'flex',
  },
  mainInfo: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '25%',
    padding: theme.spacing(3, 2),
    minWidth: 280,
  },
  detailsItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
    '& svg': {
      marginRight: theme.spacing(1),
      fill: '#535E83',
    },
  },
  financial: {
    flexGrow: 1,
  },
  budget: {
    alignItems: 'baseline',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    fontWeight: 600,
    '& small': {
      marginLeft: theme.spacing(1),
      fontSize: 12,
      fontWeight: 400,
    },
  },
  postedDate: {
    opacity: 0.4,
  },
  buttonsGroup: {
    // height: 70,
  },
  descriptionTitle: {
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: '0.03em',
  },
  margedTop: {
    marginTop: theme.spacing(3),
  },
  textContainer: {
    // height: 518,
    overflow: 'auto',
    padding: 32,
    flexGrow: 1,
  },
  description: {
    fontSize: 14,
    whiteSpace: 'break-spaces',
  },
  emptyBlock: {
    width: '100%',
    height: '70%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 18,
    opacity: 0.5,
  },
  marginBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  btnEdit: {
    fontSize: 12,
    border: `1px solid ${colors.BORDER3}`,
  },
  btnComplete: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  applicantsContainer: {
    width: '100%',
  },
  brandIcon: {
    marginRight: theme.spacing(2),
  },
  viewProfile: {
    marginBottom: 24,
  },
}));

import { makeStyles } from '@material-ui/core';
import { colors } from 'theme/colors';

export const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  fullHeight: {
    height: '80%',
  },
  exportLink: {
    textDecoration: 'none',
  },
  exportBtn: {
    color: colors.PRIMARY_INPUT_HOVERED,
  },
  emptyBox: {
    margin: '0 auto',
    height: '100%',
    maxWidth: 406,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none',
  },
  emptyIcon: {
    [theme.breakpoints.only('xs')]: {
      width: 280,
    },
  },
  emptyText: {
    marginTop: theme.spacing(2),
    fontWeight: 500,
    fontSize: 24,
    textAlign: 'center',
  },
  paper: {
    height: '100%',
    marginBottom: theme.spacing(3),
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
  },
  loaderPlaceholder: {
    minHeight: 250,
    display: 'flex',
    alignItems: 'center',
  },
  opportunityName: {
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.primary.main,
    },
    '&:active': {
      color: theme.palette.primary.main,
    },
  },
  price: {
    whiteSpace: 'nowrap',
  },
}));

import React, { FC } from 'react';
import { Paper, Box, Avatar, Typography, Divider, IconButton, Hidden } from '@material-ui/core';
import {
  LocationOn as LocationIcon,
  Favorite as FavoriteIcon,
  FavoriteBorder as FavoriteBorderIcon,
  Business as BusinessIcon,
} from '@material-ui/icons';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { useMutation, ApolloCache } from '@apollo/client';

import { getLetterForCompanyNameAvatar, intl } from 'helpers';
import { routes } from 'router/routesList';
import { UserTypeEnum } from 'types/common';
import { UPDATE_USER_FAVOURITE } from 'services/graphql/brand';
import { TALENTS_BRAND_LIST } from 'services/graphql/talent';

import { useStyles } from './styles';
import type { Props } from './types';

const updateQueries = (cache: ApolloCache<any>, options: any, id: string) => {
  try {
    const data = cache.readQuery<any>({
      query: TALENTS_BRAND_LIST,
      ...options,
    });
    const { talentListBrands } = data;

    const updatedItems = cloneDeep(talentListBrands.items);
    const item = updatedItems.find(({ id: brandId }) => brandId === id);
    item.isFavourite = !item.isFavourite;

    cache.writeQuery({
      query: TALENTS_BRAND_LIST,
      ...options,
      data: {
        talentListBrands: {
          ...talentListBrands,
          items: updatedItems,
        },
      },
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('[Error]:', err);
  }
};

const BrandCard: FC<Props> = ({ companyName, id, createdAt, image, city, country, industry, isFavourite, queryOptions }) => {
  const styles = useStyles();
  const { push } = useHistory();

  const [toggleFavourite] = useMutation(UPDATE_USER_FAVOURITE);

  const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env;
  const emptyInfo = !industry?.name && !country?.name;

  const handleClick = ({ target }) => {
    const optionsWithDifferentFavouritesFlag = cloneDeep(queryOptions);
    optionsWithDifferentFavouritesFlag.variables.filter.options.onlyFavourite = !queryOptions.variables.filter.options.onlyFavourite;
    if (target.dataset.type === 'favourite' || target.parentElement.dataset.type === 'favourite') {
      toggleFavourite({
        variables: {
          entityType: UserTypeEnum.brand,
          entityId: id,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateUserFavourite: {
            __typename: 'UserFavourite',
            entityType: UserTypeEnum.brand,
            entityId: id,
          },
        },
        update: (cache) => {
          updateQueries(cache, queryOptions, id);
        },
      });
    } else {
      push(`/${routes.TALENT}/${routes.BRAND}/${id}/info`);
    }
  };

  return (
    <Paper onClick={handleClick} className={styles.container} data-type="card">
      <Hidden xsDown>
        <>
          <Avatar className={styles.avatar} src={REACT_APP_AWSS3_URL_IMAGE_BEG + image} alt={`${companyName}'s_avatar`}>
            {getLetterForCompanyNameAvatar(companyName)}
          </Avatar>
          <Box className={styles.content}>
            <Box className={styles.heading}>
              <Box className={styles.titleBox}>
                <Typography className={styles.title}>{companyName}</Typography>
                <IconButton data-type="favourite" className={styles.btnFavorite}>
                  {isFavourite ? <FavoriteIcon data-type="favourite" /> : <FavoriteBorderIcon data-type="favourite" />}
                </IconButton>
              </Box>
              {createdAt && (
                <Typography className={styles.joinedText} variant="caption">
                  {intl('SHARED.joined')} {format(createdAt, 'dd.MM.yy')}
                </Typography>
              )}
            </Box>
            {!emptyInfo && <Divider />}
            <Box className={styles.mainInfoBox}>
              {industry?.name && (
                <Box className={styles.infoBox}>
                  <BusinessIcon fontSize="small" className={styles.icon} />
                  <Typography variant="body2">{industry.name}</Typography>
                </Box>
              )}
              {country?.name && (
                <Box className={styles.infoBox}>
                  <LocationIcon fontSize="small" className={styles.icon} />
                  <Typography variant="body2">{`${country.name}, ${city.name}`}</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </>
      </Hidden>
      <Hidden smUp>
        <>
          <Box className={styles.headingMobile}>
            <Avatar className={styles.avatar} src={REACT_APP_AWSS3_URL_IMAGE_BEG + image} alt={`${companyName}'s_avatar`}>
              {getLetterForCompanyNameAvatar(companyName)}
            </Avatar>
            <Box className={styles.heading}>
              <Box className={styles.titleBox}>
                <Typography className={styles.title}>{companyName}</Typography>
              </Box>
              {createdAt && (
                <Typography className={styles.joinedText} variant="caption">
                  {intl('SHARED.joined')} {format(createdAt, 'dd.MM.yy')}
                </Typography>
              )}
            </Box>
          </Box>
          {!emptyInfo && <Divider />}
          <Box className={styles.contentMobile}>
            {industry?.name && (
              <Box className={styles.infoBox}>
                <BusinessIcon fontSize="small" className={styles.icon} />
                <Typography variant="body2">{industry.name}</Typography>
              </Box>
            )}
            {country?.name && (
              <Box className={styles.infoBox}>
                <LocationIcon fontSize="small" className={styles.icon} />
                <Typography variant="body2">{`${country.name}, ${city.name}`}</Typography>
              </Box>
            )}
          </Box>
        </>
      </Hidden>
    </Paper>
  );
};

export { BrandCard };

import React, { useContext, useState } from 'react';
import * as Yup from 'yup';

import { Box, Paper, TextField, Button, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

import { useAuth } from 'hooks/auth';
import { intl } from 'helpers';
import { ConfirmIcon } from 'assets/svg-js';
import { useLink } from 'hooks/linkTo';
import { routes } from 'router/routesList';
import { AppContext } from 'hooks/app';
import { SportEndorse } from 'components/SportEndorse';

import { useStyles } from './styles';
import ReactGA from 'react-ga';

const ConfirmSchema = Yup.object({
  code: Yup.number().typeError('ERRORS.mustBeANumber').required('ERRORS.emptyField'),
});

export const Confirm = () => {
  const { dispatch } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const styles = useStyles({});
  const { location, push } = useHistory();
  const linkTo = useLink();
  const { confirmSignUp, tempUser, resendSignUp } = useAuth();
  const email = location.search.split('?email=')[1];

  const [isAfterCodeSent, setIsAfterCodeSent] = useState(false);

  const goToLogIn = () => push(routes.LOGIN);

  const handleConfirm = async (values, formContext) => {
    const user = tempUser;
    if (!email) {
      formContext.setFieldError('code', intl('ERRORS.noEmailProvided'));
    }
    setLoading(true);

    const { error } = await confirmSignUp({
      username: email,
      code: values.code,
    });
    setLoading(false);

    const role = user.attributes['custom:role'];

    if (!error) {
      ReactGA.event({
        category: 'User',
        action: `[${role.toUpperCase()}]: Full Sign Up`,
        label: `[${role.toUpperCase()}]: ${email.replace('@', '*')} - Full Sign Up`,
      });
    }
    if (error?.code === 'CodeMismatchException') {
      formContext.setFieldError('code', intl('ERRORS.incorrectCode'));
      return;
    }

    const link = role === 'agent'
      ? 'profile/' + routes.PUBLIC_PAGE
      : role === 'talent'
        ? 'profile/' + routes.PUBLIC_INFO
        : routes.COMPANY_INFO;

    linkTo(link, { fromSignUp: true })();
    // linkTo(`/${role === 'agent' ? 'talent' : role}/${role === routes.BRAND ? routes.COMPANY_INFO : `profile/${routes.PUBLIC_PAGE}`}`, {
    //   fromSignUp: true,
    // })();
    dispatch({ type: 'INTRO_MODAL', payload: true });
  };

  const handleCodeResend = async () => {
    const { error } = await resendSignUp({
      username: email,
    });
    if (error) {
      setFieldError('code', 'ERRORS.somethingWentWrong');
      return;
    }

    setFieldError('code', '');
    setIsAfterCodeSent(true);
  };

  const { handleChange, values, handleSubmit, errors, touched, setFieldError } = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: ConfirmSchema,
    onSubmit: handleConfirm,
  });

  return (
    <Box display="flex" alignItems="center" justifyContent="center" minHeight="100vh" padding="30px" paddingX="8px" flexDirection="column">
      <SportEndorse />
      <Paper className={styles.paper} component="form" onSubmit={handleSubmit}>
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" marginBottom="18px">
          <ConfirmIcon />
          <Typography className={styles.verificationCode} variant="h5">
            {intl('CONFIRM.verificationCode')}
          </Typography>
          <Typography className={styles.text}>{intl('CONFIRM.enterTheCode')}</Typography>
        </Box>
        <TextField
          name="code"
          label={intl('SHARED.code')}
          className={styles.input}
          value={values.code}
          onChange={handleChange}
          onFocus={() => setIsAfterCodeSent(false)}
          error={errors.code && touched.code}
          helperText={intl(isAfterCodeSent ? 'CREATE_NEW_PASSWORD.passwordSuccessfullySent' : touched.code && errors.code)}
        />
        <Box
          className={styles[(touched.code && errors.code) || isAfterCodeSent ? 'dontReceiveContainerError' : 'dontReceiveContainer']}
          onClick={handleCodeResend}
        >
          <Typography component="span" to="/restore" className={styles.dontReceive}>
            {intl('CONFIRM.dontReceiveCode')}
          </Typography>
        </Box>
        <Button type="submit" variant="contained" color="primary" className={styles.send} disabled={loading}>
          {intl('CONFIRM.confirm')}
        </Button>
        <Button onClick={goToLogIn} variant="outlined">
          {intl('AUTH.logIn')}
        </Button>
      </Paper>
    </Box>
  );
};

/* eslint-disable max-len */
import React from 'react'

const OpportunitiesIcon = ({ fill, ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 5H21.02C21.56 5 22 5.44 22 5.98V6.02C22 6.56 21.56 7 21.02 7H19V9.01C19 9.55 18.56 9.99 18.02 9.99H17.99C17.44 10 17 9.55 17 9.01V7H14.99C14.44 7 14 6.56 14 6.01V5.98C14.01 5.44 14.45 5 14.99 5H17V2.98C17 2.44 17.44 2 17.99 2H18.02C18.56 2 19 2.44 19 2.98V5ZM16 8V9.01C16 10.1 16.89 10.99 17.98 11C18.36 11 18.7 10.89 19 10.72V19C19 20.1 18.1 21 17 21H5C3.9 21 3 20.1 3 19V7C3 5.9 3.9 5 5 5H13.27C13.1 5.29 13 5.62 13 5.98C13 6.54 13.21 7.04 13.58 7.42C13.96 7.79 14.46 8 14.99 8H16ZM8 10H14C14.55 10 15 10.45 15 11C15 11.55 14.55 12 14 12H8C7.45 12 7 11.55 7 11C7 10.45 7.45 10 8 10ZM14 13H8C7.45 13 7 13.45 7 14C7 14.55 7.45 15 8 15H14C14.55 15 15 14.55 15 14C15 13.45 14.55 13 14 13ZM8 16H14C14.55 16 15 16.45 15 17C15 17.55 14.55 18 14 18H8C7.45 18 7 17.55 7 17C7 16.45 7.45 16 8 16Z"
      fill={fill || '#535E83'}
    />
  </svg>
)

export default OpportunitiesIcon

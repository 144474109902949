export type ProfitMarginRange = {
  from: number;
  to: number;
};

export type ProfitMarginItem = {
  range: ProfitMarginRange;
  percentage: number;
};

export type ProfitMargins = {
  firstRange: ProfitMarginItem;
  secondRange: ProfitMarginItem;
  thirdRange: ProfitMarginItem;
};

export enum ProfitMarginsTypeEnum {
  opportunity = 'opportunity',
  platform = 'platform',
  series = 'series',
}

import React from 'react'
import { Button } from '@material-ui/core'
import { intl } from '../../../../../helpers'

const PaymentActions = ({ loading, toggle, classes, handleSubmit }) => (
  <>
    <Button variant="outlined" disabled={loading} onClick={toggle} className={classes.buttons}>
      {intl('SHARED.cancel')}
    </Button>
    <Button
      disabled={loading}
      onClick={handleSubmit}
      variant="contained"
      className={classes.buttons}
      color="primary"
    >
      {intl('SHARED.confirm')}
    </Button>
  </>
)

export default PaymentActions

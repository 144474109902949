import React, { FC, useState } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';

import { intl } from 'helpers';
import { VerifiedPersonIcon } from 'assets/svg-js';
import { useLink } from 'hooks/linkTo';
import { useAuth } from 'hooks/auth';
import { routes } from 'router/routesList';
import { UserTypeEnum, verificationStatusEnum } from 'types/common';
import { GET_BRAND_VERIFICATION_STATUS } from 'services/graphql/brand';
import { GET_TALENT_VERIFICATION_STATUS } from 'services/graphql/talent';

import { Wrapper } from 'components/Wrapper';
import { PendingVerificationModal } from 'components/Modals/PendingVerificationModal';

import { useStyles } from './styles';

const VerificationPage: FC<{}> = () => {
  const styles = useStyles();
  const [pendingModal, setPendingModal] = useState<boolean>(false);
  const { state } = useLocation() as { state?: { previousPage?: string } };
  const linkTo = useLink();
  const { getRole } = useAuth();
  const isTalent = getRole() === UserTypeEnum.talent || getRole() === UserTypeEnum.agent;
  const publicPageLink = isTalent ? routes.TALENT_PUBLIC_PAGE : routes.BRAND_PUBLIC_PAGE;

  const { data: brandData, loading: brandLoading } = useQuery(GET_BRAND_VERIFICATION_STATUS, {
    skip: getRole() !== UserTypeEnum.brand && getRole() !== UserTypeEnum.manager,
    fetchPolicy: 'network-only',
  });

  const { data: talentData, loading: talentLoading } = useQuery(GET_TALENT_VERIFICATION_STATUS, {
    skip: getRole() !== UserTypeEnum.talent && getRole() !== UserTypeEnum.agent,
    fetchPolicy: 'network-only',
  });

  const isBrandNotVerified = !brandLoading && brandData?.getBrandUser?.verificationStatus === verificationStatusEnum.not_verified;
  const isTalentNotVerified = !talentLoading && talentData?.getCurrentTalent?.verificationStatus === verificationStatusEnum.not_verified;

  const togglePendingModal = () => setPendingModal((s) => !s);
  const handleClosePending = () => {
    togglePendingModal();
    if (state?.previousPage) {
      linkTo(state.previousPage)();
    } else {
      linkTo(publicPageLink)();
    }
  };

  const handleCompleteVerification = () => {
    togglePendingModal();
  };

  return (
    <Wrapper>
      <Box className={styles.box}>
        <VerifiedPersonIcon />
        <Typography className={styles.title}>{intl('VERIFICATION_PAGE.letsStart')}</Typography>
        <Typography className={styles.subtitle}>{intl('VERIFICATION_PAGE.clickTxt')}</Typography>
        {(isBrandNotVerified || isTalentNotVerified) && (
          <Button variant="contained" color="primary" onClick={handleCompleteVerification}>
            {intl('VERIFY_ME_PANEL.verifyButton')}
          </Button>
        )}
      </Box>
      <PendingVerificationModal loading={false} open={pendingModal} handleClose={handleClosePending} />
    </Wrapper>
  );
};

export { VerificationPage };

import React, { FC, useState } from 'react';
import { Box, Button, CardMedia, Container, IconButton, TextField, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import clsx from 'clsx';
import * as Yup from 'yup';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import VolumeMuteRoundedIcon from '@material-ui/icons/VolumeMuteRounded';
import VolumeOffRoundedIcon from '@material-ui/icons/VolumeOffRounded';

import { intl } from 'helpers';

import { SportEndorse } from 'components/SportEndorse';

import { useStyles } from './styles';

const validationSchema = Yup.object({
  email: Yup.string().email('ERRORS.incorrectEmail').required('ERRORS.emptyField'),
});

const postUrl = 'https://api.hsforms.com/submissions/v3/integration/submit/4025606/704356c6-cc9b-4ec6-8ae8-c65695eefa78';

export const ComingSoon: FC = () => {
  const [isSent, setIsSent] = useState(false);
  const [isMute, setIsMute] = useState(true);
  const styles = useStyles();

  const sendEmail = (values) => {
    const postData = JSON.stringify({
      fields: [
        {
          name: 'email',
          value: values.email,
        },
      ],
    });
    fetch(postUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: postData,
    })
      .then((response) => response.json())
      .then(() => setIsSent(true))
      .catch((error) => console.log('--error--', error));
  };

  const { handleChange, values, errors, touched, handleSubmit } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: sendEmail,
  });

  const toggleMute = () => setIsMute((current) => !current);

  return (
    <Container className={styles.comingSoonWrapper}>
      <SportEndorse className={styles.logoWrapper} />
      <Box className={styles.videoContainer}>
        <CardMedia
          component="video"
          muted={isMute}
          autoPlay
          loop
          src="https://sport-endorse-dev.s3-eu-west-1.amazonaws.com/public/onboarding/onboarding.mp4"
        />
        <Box className={styles.muteBtnContainer} onClick={toggleMute}>
          <IconButton className={styles.muteBtn}>{isMute ? <VolumeOffRoundedIcon /> : <VolumeMuteRoundedIcon />}</IconButton>
        </Box>
      </Box>

      <Typography variant="h5" align="center" className={clsx(styles.title, styles.black)}>
        Version 1.0 Coming Soon
      </Typography>
      <Typography variant="h5" align="center" className={styles.title}>
        Register your interest
      </Typography>
      <Typography align="center" className={styles.subtitle}>
        We will notify you when it will launch
      </Typography>
      <Box component="form" onSubmit={handleSubmit} className={clsx(styles.emailForm, { [styles.centered]: isSent })}>
        {isSent ? (
          <>
            <Typography>Thank you!</Typography>
            <DoneAllIcon />
          </>
        ) : (
          <>
            <TextField
              className={styles.emailInput}
              name="email"
              label="Email"
              onChange={handleChange}
              value={values.email}
              error={!!(errors.email && touched.email)}
              helperText={intl(touched.email && errors.email)}
            />
            <Button type="submit" variant="contained" color="primary" className={styles.sendBtn}>
              Send
            </Button>
          </>
        )}
      </Box>
    </Container>
  );
};

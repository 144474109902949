import { makeStyles } from '@material-ui/core'
import { colors } from '../../../../../theme/colors'

export const useStyles = makeStyles((theme) => ({
  box: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    background: colors.WHITE,
    [theme.breakpoints.down(580)]: {
      justifyContent: 'center',
    },
  },
  btnPay: {
    padding: '11px 64px',
    background: colors.PRIMARY,
    color: colors.WHITE,
    fontSize: 12,
    '&:hover': {
      background: colors.PRIMARY_HOVERED,
    },
    [theme.breakpoints.down(580)]: {
      width: '100%',
    },
  },
}))

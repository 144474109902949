import React, { useEffect } from 'react';
import { AppBar, Typography, Divider, Button, Box, Grid, useMediaQuery, IconButton, Hidden, Chip } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { Autocomplete } from '@material-ui/lab';
import { useFormik } from 'formik';
import { CloseRounded, ClearRounded } from '@material-ui/icons';
import { useAuth } from 'hooks/auth';
import clsx from 'clsx';
import intersectionWith from 'lodash/intersectionWith';
import isEqual from 'lodash/isEqual';

import { intl, omit } from 'helpers';
import { LIST_COUNTRIES, LIST_CITIES } from 'services/graphql/location';
import { LIST_SPORTS } from 'services/graphql/sports';

import { FilterTextField } from 'components/FilterTextField';

import { useStyles } from './styles';

const genders = [
  { id: 'male', name: 'SHARED.male' },
  { id: 'female', name: 'SHARED.female' },
];

const sort = [
  { id: 'createdAt', name: 'BRAND_TALENT_FILTERS.freshest' },
  { id: 'feedback', name: 'BRAND_TALENT_FILTERS.feedback' },
  { id: 'geo', name: 'BRAND_TALENT_FILTERS.nearest' },
  { id: 'popular', name: 'BRAND_TALENT_FILTERS.popular' },
];

export const TalentFilters = ({ className, handleClose, handleApply, filters, availableFilters }) => {
  const styles = useStyles({});
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { getRole } = useAuth();
  const { values, handleChange, setFieldValue, setValues } = useFormik({
    initialValues: {
      country: filters?.country || [],
      city: filters?.city || [],
      sport: filters?.sport || [],
      tags: filters?.tags || [],
      gender: filters?.gender || '',
      instagramFollowers: filters?.instagramFollowers,
      facebookFollowers: filters?.facebookFollowers,
      twitterFollowers: filters?.twitterFollowers,
      age: filters?.age,
      sort: filters?.sort || '',
    },
    enableReinitialize: true,
  });
  const { data: countries, loading: countriesLoading } = useQuery(LIST_COUNTRIES);
  const { data: listSportsData, loading: listSportsLoading } = useQuery(LIST_SPORTS);

  const { data: cities, loading: citiesLoading } = useQuery(LIST_CITIES, {
    skip: !values?.country?.[0]?.id,
    variables: {
      countryIds: values?.country && values?.country?.map((country) => country.id),
    },
  });

  const handleAutocompleteChange = (name) => (_, value) => setFieldValue(name, value);

  useEffect(() => {
    if (cities) {
      const filteredCities = intersectionWith(values.city, cities.listCities, isEqual);
      setFieldValue('city', filteredCities);
    } else if (!values.country.length) {
      setFieldValue('city', []);
    }
  }, [cities]);

  // TODO: add clear age filter
  const handleClear = (section) => {
    if (!values) return;

    if (section === 'all') {
      handleApply({ tab: 'all' });
      setValues({
        ...values,
        country: [],
        city: [],
        sport: [],
        gender: {},
        tags: [],
        instagramFollowers: null,
        facebookFollowers: null,
        twitterFollowers: null,
      });
    }

    if (section === 'location') {
      setValues({ ...values, country: [], city: [] });
      handleApply(omit(values, ['country', 'city']));
    }
    if (section === 'sport') {
      setValues({ ...values, sport: [] });
      handleApply(omit(values, ['sport']));
    }
    if (section === 'tags') {
      setValues({ ...values, tags: [] });
      handleApply(omit(values, ['tags']));
    }
    if (section === 'gender') {
      setValues({ ...values, gender: {} });
      handleApply(omit(values, ['gender']));
    }
    if (section === 'instagram') {
      setValues({ ...values, instagramFollowers: null });
      handleApply(omit(values, ['instagramFollowers']));
    }

    if (section === 'facebook') {
      setValues({ ...values, facebookFollowers: null });
      handleApply(omit(values, ['facebookFollowers']));
    }
    if (section === 'twitter') {
      setValues({ ...values, twitterFollowers: null });
      handleApply(omit(values, ['twitterFollowers']));
    }
    if (section === 'age') {
      setValues({ ...values, age: null });
      handleApply(omit(values, ['age']));
    }
  };

  const checkDisabledClear = {
    location: values?.country?.length || values?.city?.length,
    sport: values?.sport?.length,
    tags: values?.tags?.length,
    gender: values?.gender?.name,
    instagram: values?.instagramFollowers?.from || values?.instagramFollowers?.to,
    facebook: values?.facebookFollowers?.from || values?.facebookFollowers?.to,
    twitter: values?.twitterFollowers?.from || values?.twitterFollowers?.to,
    age: values?.age?.from || values?.age?.to,
  };

  const chipRenderer = (value, getTagProps) =>
    value.map((option, index) => (
      <Chip
        {...getTagProps({ index })}
        className={styles.chip}
        key={option.id}
        label={option.name}
        deleteIcon={
          <IconButton styles={{ label: styles.deleteIconLabel }} className={styles.deleteIcon}>
            <ClearRounded className={styles.icon} />
          </IconButton>
        }
      />
    ));

  const tagChipRenderer = (value, getTagProps) =>
    value.map((option, index) => (
      <Chip
        {...getTagProps({ index })}
        className={styles.chip}
        key={option}
        label={option}
        deleteIcon={
          <IconButton styles={{ label: styles.deleteIconLabel }} className={styles.deleteIcon}>
            <ClearRounded className={styles.icon} />
          </IconButton>
        }
      />
    ));

  const handleFiltersApply = () => {
    if (values?.sort?.id === 'geo') {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          handleApply(
            {
              ...values,
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            },
            true,
          );
        });
      }
    } else handleApply(values, true);
  };

  return (
    <AppBar position={isMobile ? 'static' : 'sticky'} color="transparent" component="header" className={clsx(styles.profileCard, className)}>
      <Box className={styles.topBar}>
        <Typography className={styles.filters}>{intl('BRAND_TALENT_FILTERS.filters')}</Typography>
        <Hidden mdUp>
          <IconButton onClick={handleClose} size="small">
            <CloseRounded />
          </IconButton>
        </Hidden>
      </Box>

      <Divider />
      <Box className={styles.mainBlock}>
        <Box className={styles.location}>
          <Box className={styles.clearBlock}>
            <Typography className={styles.opportunitySectionTitle}>{intl('BRAND_TALENT_FILTERS.location')}</Typography>
            <Button disabled={!checkDisabledClear.location} onClick={() => handleClear('location')} className={styles.clearBtn}>
              {intl('BRAND_TALENT_FILTERS.clear')}
            </Button>
          </Box>
          <Autocomplete
            className={styles.input}
            name="country"
            loading={countriesLoading}
            options={countries ? countries.listCountries : []}
            value={values?.country}
            multiple
            onChange={handleAutocompleteChange('country')}
            getOptionLabel={(option) => option.name || ''}
            getOptionSelected={(option, value) => option.id === value.id}
            renderTags={chipRenderer}
            renderInput={(params) => <FilterTextField {...params} placeholder={intl('TALENT_OPPORTUNITY_FILTERS.selectCountry')} name="country" />}
          />
          <Autocomplete
            className={styles.input}
            name="city"
            loading={citiesLoading}
            disabled={!values?.country?.length}
            options={cities ? cities.listCities : []}
            value={values?.city}
            multiple
            onChange={handleAutocompleteChange('city')}
            getOptionLabel={(option) => option.name || ''}
            getOptionSelected={(option, value) => option.id === value.id}
            renderTags={chipRenderer}
            renderInput={(params) => <FilterTextField {...params} placeholder={intl('TALENT_OPPORTUNITY_FILTERS.selectCity')} name="city" />}
          />
        </Box>

        <Box className={styles.location}>
          <Box className={styles.clearBlock}>
            <Typography className={styles.opportunitySectionTitle}>{intl('BRAND_TALENT_FILTERS.sport')}</Typography>
            <Button disabled={!checkDisabledClear.sport} onClick={() => handleClear('sport')} className={styles.clearBtn}>
              {intl('BRAND_TALENT_FILTERS.clear')}
            </Button>
          </Box>
          <Autocomplete
            className={styles.input}
            name="sport"
            loading={listSportsLoading}
            options={listSportsData ? listSportsData.listSports.filter((sport) => availableFilters?.sports?.includes(sport.id)) : []}
            value={values?.sport}
            multiple
            onChange={handleAutocompleteChange('sport')}
            getOptionLabel={(option) => option.name || ''}
            getOptionSelected={(option, value) => option.id === value.id}
            renderTags={chipRenderer}
            renderInput={(params) => <FilterTextField {...params} placeholder={intl('BRAND_TALENT_FILTERS.allSports')} name="sport" />}
          />
        </Box>

        <Box className={styles.location}>
          <Box className={styles.clearBlock}>
            <Typography className={styles.opportunitySectionTitle}>{intl('BRAND_TALENT_FILTERS.tags')}</Typography>
            <Button disabled={!checkDisabledClear.tags} onClick={() => handleClear('tags')} className={styles.clearBtn}>
              {intl('BRAND_TALENT_FILTERS.clear')}
            </Button>
          </Box>
          <Autocomplete
            className={styles.input}
            name="tags"
            options={availableFilters ? availableFilters.tags : []}
            value={values?.tags}
            multiple
            onChange={handleAutocompleteChange('tags')}
            getOptionLabel={(option) => option || ''}
            getOptionSelected={(option, value) => option === value}
            renderTags={tagChipRenderer}
            renderInput={(params) => <FilterTextField {...params} placeholder={intl('BRAND_TALENT_FILTERS.tags')} name="tags" />}
          />
        </Box>

        <Box className={styles.location}>
          <Box className={styles.clearBlock}>
            <Typography className={styles.opportunitySectionTitle}>{intl('BRAND_TALENT_FILTERS.gender')}</Typography>
            <Button disabled={!checkDisabledClear.gender} onClick={() => handleClear('gender')} className={styles.clearBtn}>
              {intl('BRAND_TALENT_FILTERS.clear')}
            </Button>
          </Box>

          <Autocomplete
            className={styles.input}
            name="gender"
            options={genders}
            value={values?.gender}
            onChange={handleAutocompleteChange('gender')}
            getOptionLabel={(option) => intl(option.name) || ''}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) => <FilterTextField {...params} placeholder={intl('BRAND_TALENT_FILTERS.gender')} name="gender" />}
          />
        </Box>

        <Box className={styles.location}>
          <Box className={styles.clearBlock}>
            <Typography className={styles.opportunitySectionTitle}>{intl('BRAND_TALENT_FILTERS.igFollowers')}</Typography>
            <Button onClick={() => handleClear('instagram')} disabled={!checkDisabledClear.instagram} className={styles.clearBtn}>
              {intl('BRAND_TALENT_FILTERS.clear')}
            </Button>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FilterTextField
                onChange={handleChange}
                fullWidth
                value={values?.instagramFollowers?.from || ''}
                name="instagramFollowers.from"
                inputClassName={styles.centered}
                placeholder={intl('BRAND_TALENT_FILTERS.from')}
              />
            </Grid>
            <Grid item xs={6}>
              <FilterTextField
                onChange={handleChange}
                fullWidth
                value={values?.instagramFollowers?.to || ''}
                name="instagramFollowers.to"
                inputClassName={styles.centered}
                placeholder={intl('BRAND_TALENT_FILTERS.to')}
              />
            </Grid>
          </Grid>
        </Box>

        <Box className={styles.location}>
          <Box className={styles.clearBlock}>
            <Typography className={styles.opportunitySectionTitle}>{intl('BRAND_TALENT_FILTERS.fbFollowers')}</Typography>
            <Button onClick={() => handleClear('facebook')} disabled={!checkDisabledClear.facebook} className={styles.clearBtn}>
              {intl('BRAND_TALENT_FILTERS.clear')}
            </Button>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FilterTextField
                onChange={handleChange}
                fullWidth
                value={values?.facebookFollowers?.from || ''}
                name="facebookFollowers.from"
                inputClassName={styles.centered}
                placeholder={intl('BRAND_TALENT_FILTERS.from')}
              />
            </Grid>
            <Grid item xs={6}>
              <FilterTextField
                onChange={handleChange}
                fullWidth
                value={values?.facebookFollowers?.to || ''}
                name="facebookFollowers.to"
                inputClassName={styles.centered}
                placeholder={intl('BRAND_TALENT_FILTERS.to')}
              />
            </Grid>
          </Grid>
        </Box>

        <Box className={styles.location}>
          <Box className={styles.clearBlock}>
            <Typography className={styles.opportunitySectionTitle}>{intl('BRAND_TALENT_FILTERS.twFollowers')}</Typography>
            <Button onClick={() => handleClear('twitter')} disabled={!checkDisabledClear.twitter} className={styles.clearBtn}>
              {intl('BRAND_TALENT_FILTERS.clear')}
            </Button>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FilterTextField
                onChange={handleChange}
                fullWidth
                value={values?.twitterFollowers?.from || ''}
                name="twitterFollowers.from"
                inputClassName={styles.centered}
                placeholder={intl('BRAND_TALENT_FILTERS.from')}
              />
            </Grid>
            <Grid item xs={6}>
              <FilterTextField
                onChange={handleChange}
                fullWidth
                value={values?.twitterFollowers?.to || ''}
                name="twitterFollowers.to"
                inputClassName={styles.centered}
                placeholder={intl('BRAND_TALENT_FILTERS.to')}
              />
            </Grid>
          </Grid>
        </Box>
        <Box className={styles.location}>
          <Box className={styles.clearBlock}>
            <Typography className={styles.opportunitySectionTitle}>{intl('BRAND_TALENT_FILTERS.age')}</Typography>
            <Button onClick={() => handleClear('age')} disabled={!checkDisabledClear.age} className={styles.clearBtn}>
              {intl('BRAND_TALENT_FILTERS.clear')}
            </Button>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FilterTextField
                onChange={handleChange}
                fullWidth
                value={values?.age?.from || ''}
                name="age.from"
                inputClassName={styles.centered}
                placeholder={intl('BRAND_TALENT_FILTERS.from')}
              />
            </Grid>
            <Grid item xs={6}>
              <FilterTextField
                onChange={handleChange}
                fullWidth
                value={values?.age?.to || ''}
                name="age.to"
                inputClassName={styles.centered}
                placeholder={intl('BRAND_TALENT_FILTERS.to')}
              />
            </Grid>
          </Grid>
        </Box>
        {getRole() !== 'admin' && (
          <Box className={styles.location}>
            <Box className={styles.clearBlock}>
              <Typography className={styles.opportunitySectionTitle}>{intl('BRAND_TALENT_FILTERS.sort')}</Typography>
            </Box>
            <Autocomplete
              className={styles.input}
              name="sort"
              options={sort}
              value={values?.sort}
              onChange={handleAutocompleteChange('sort')}
              getOptionLabel={(option) => intl(option.name) || ''}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => <FilterTextField {...params} placeholder={intl('BRAND_TALENT_FILTERS.sort')} name="sort" />}
            />
          </Box>
        )}
      </Box>

      <Divider />
      <Grid container spacing={2} className={styles.clearFilters}>
        <Grid item xs={6}>
          <Button onClick={() => handleClear('all')} variant="outlined" fullWidth>
            {intl('BRAND_TALENT_FILTERS.clear')}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button variant="contained" fullWidth color="primary" onClick={handleFiltersApply}>
            {intl('BRAND_TALENT_FILTERS.apply')}
          </Button>
        </Grid>
      </Grid>
    </AppBar>
  );
};

import { makeStyles } from '@material-ui/core';
import { colors } from 'theme/colors';

export const useStyles = makeStyles((theme) => ({
  tablePaginationWrapper: {
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 32px)',
    },
    width: '100%',
  },
  toolBar: {
    fontSize: 20,
    fontWeight: 500,
    color: colors.BLACK,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 0,
  },
  label: {
    flexGrow: 1,
  },
  fullHeight: {
    height: '100%',
  },
  fullWidth: {
    width: '100%',
  },
  headCell: {
    cursor: 'pointer',
  },
}));

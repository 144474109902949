import React, { FC } from 'react';
import { Typography, Box, Button } from '@material-ui/core';
import { useMutation } from '@apollo/client';

import { intl } from 'helpers';
import { QuestionMark as QuestionMarkIcon } from 'assets/svg-js';
import { GET_OPPORTUNITY } from 'services/graphql/query/opportunity';
import { CANCEL_OPPORTUNITY } from 'services/graphql/mutation/opportunity';
import type { Opportunity } from 'types/opportunity';

import { Modal } from 'components/Modal';

import { useStyles } from './styles';
import type { Props, ActionProps } from './types';

type GetOpportunity = {
  getOpportunity: Opportunity;
};

const ModalActions: FC<ActionProps> = ({ handleClose, handleConfirm, loading }) => {
  const styles = useStyles();
  return (
    <>
      <Button className={styles.btnNo} disabled={loading} onClick={handleClose}>
        {intl('SHARED.no')}
      </Button>
      <Button className={styles.btnYes} disabled={loading} onClick={handleConfirm}>
        {intl('SHARED.yes')}
      </Button>
    </>
  );
};

const CancelOpportunityModal: FC<Props> = ({ opportunity, isOpen, handleClose }) => {
  const styles = useStyles();

  const [cancelOpportunity, { loading }] = useMutation(CANCEL_OPPORTUNITY, {
    variables: {
      opportunityId: opportunity.id,
      isCharge: false,
    },
    onCompleted() {
      handleClose();
    },
    update: (cache, { data: { cancelOpportunity: response } }) => {
      try {
        const { getOpportunity } = cache.readQuery<GetOpportunity>({ query: GET_OPPORTUNITY, variables: { opportunityId: opportunity.id } });

        cache.writeQuery({
          query: GET_OPPORTUNITY,
          variables: { opportunityId: opportunity.id },
          data: {
            getOpportunity: {
              ...getOpportunity,
              status: response.status,
            },
          },
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('[Updating cache error', error);
      }
    },
  });

  return (
    <Modal
      className={styles.modalBox}
      classNameTitle={styles.modalTitleBox}
      isOpen={isOpen}
      handleClose={handleClose}
      dialogActionsComponent={<ModalActions handleClose={handleClose} handleConfirm={cancelOpportunity} loading={loading} />}
      disabledBorder
      title={null}
    >
      <Box className={styles.modalContentBox}>
        <Box className={styles.modalIconBox}>
          <QuestionMarkIcon />
        </Box>
        <Typography className={styles.modalText}>{intl('OPPORTUNITY.cancelModalOpportunityText')}</Typography>
      </Box>
    </Modal>
  );
};

export { CancelOpportunityModal };

import React, { useEffect, useState } from 'react'
import { Paper, Typography, Button } from '@material-ui/core'
import { useMutation } from '@apollo/client'
import { Loader } from '../../../../components/Loader'
import { intl } from '../../../../helpers'

import { Wrapper } from '../../../../components/Wrapper'

import {
  UPDATE_STRIPE_CONNECT_ACCOUNT,
  GET_CURRENT_TALENT,
} from '../../../../services/graphql/talent'
import { useStyles } from './styles'
import { Modal } from '../../../../components/Modal/Modal'
import { routes } from '../../../../router/routesList'
import { useLink } from '../../../../hooks/linkTo'

export const StripeConnectConfirmation = ({ location }) => {
  const styles = useStyles({})
  const linkTo = useLink({})
  const [modalOpened, setModalOpened] = useState(true)

  const [updateStripeConnectAccount, { loading, data }] = useMutation(
    UPDATE_STRIPE_CONNECT_ACCOUNT,
    {
      variables: {
        payload: location.search,
      },
      onError() {
        window.history.replaceState(null, '', `/${routes.TALENT}/stripe-connect`)
      },
      onCompleted() {
        window.history.replaceState(null, '', `/${routes.TALENT}/stripe-connect`)
      },
      refetchQueries: [{ query: GET_CURRENT_TALENT }],
      awaitRefetchQueries: true,
    },
  )

  const handleClose = () => {
    setModalOpened(false)
    linkTo(routes.TALENT_PRIVATE_INFO)()
  }
  useEffect(() => {
    updateStripeConnectAccount({
      variables: {
        payload: location.search,
      },
    })

    return () => {}
  }, [location.search])

  return (
    <>
      <Paper>
        <Wrapper>
          <Modal
            isOpen={modalOpened}
            handleClose={handleClose}
            title={
              // eslint-disable-next-line
              !loading
                ? data?.updateStripeConnectAccount?.clientId
                  ? intl('TALENT.STRIPE_CONNECT.successTitle')
                  : intl('TALENT.STRIPE_CONNECT.failedTitle')
                : null
            }
            dialogActionsComponent={
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClose}
                  className={styles.modalButtons}
                  disabled={loading}
                >
                  {intl('SHARED.ok')}
                </Button>
              </>
            }
          >
            {loading ? (
              <Loader />
            ) : (
              <Typography className={styles.sectionHeading} component="h4">
                {data?.updateStripeConnectAccount?.clientId
                  ? intl('TALENT.STRIPE_CONNECT.success')
                  : intl('TALENT.STRIPE_CONNECT.failed')}
              </Typography>
            )}
          </Modal>
        </Wrapper>
      </Paper>
    </>
  )
}

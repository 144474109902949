import { makeStyles } from '@material-ui/core';

import { colors } from 'theme/colors';

export const useStyles = makeStyles((theme) => ({
  feedbackContainer: {
    padding: 32,
    marginBottom: 16,
  },
  sectionHeader: {
    display: 'inline-block',
    color: colors.TEXT,
    fontWeight: 600,
    textTransform: 'uppercase',
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 4,
    },
  },
  feedbackCounter: {
    marginLeft: 8,
  },
  showMore: {
    display: 'block',
    margin: '0 auto',
  },
}));

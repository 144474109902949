import React, { useState, useEffect } from 'react';
import { Box, Avatar, Typography, Tabs, Tab, Divider, Paper, TextField, Grid } from '@material-ui/core';
import { useParams, Route } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client/react/hooks';
import { format, isValid } from 'date-fns';

import { UserTypeEnum } from 'types/common';
import { useLink } from 'hooks/linkTo';
import { routes } from 'router/routesList';
import { intl } from 'helpers';

import { ProfileTop } from 'components/ProfileTop/ProfileTop';
import { Loader } from 'components/Loader';
import { Wrapper } from 'components/Wrapper';
import { Option, VisibilityOption } from 'components/BadgeSelect/types';
import { StripeConnect } from 'components/StripeConnect';
import { PaymentHistoryTable } from 'components/PaymentHistoryTable';
import BadgeSelect from 'components/BadgeSelect';
import { TalentAnalytics } from 'components/TalentAnalytics/TalentAnalytics';
import { TalentProfileForm } from './TalentProfileForm';

import { ADMIN_GET_PAYMENT_HISTORY } from 'services/graphql/payments';
import {
  UPDATE_VERIFICATION_STATUS,
  UpdateVerificationStatusVars,
  ADMIN_UPDATE_TALENT_PROFILE,
  UpdateTalentProfileVars,
} from 'services/graphql/admin';
import { GET_TALENT_BY_ID } from 'services/graphql/talent';

import { Feedbacks } from 'components/Feedbacks';
import { TabsEnum } from './tabs';
import { Tags } from '../../Talent/PublicPage/components/Tags';
import { About } from '../../Talent/PublicPage/components/About';
import { Videos } from '../../Talent/PublicPage/components/Videos';

import { useStyles } from './styles';

import { PROFILE_OPTIONS, VisibilityOptions } from '../../../constants';

const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env;

export const Talent = () => {
  const styles = useStyles({});
  const linkTo = useLink();
  const { id, tab } = useParams();
  const [currentTab, setCurrentTab] = useState<TabsEnum>(tab || TabsEnum.PUBLIC);
  const handleTabChange = (_: any, newValue: TabsEnum) => setCurrentTab(newValue);

  const { data, loading, refetch } = useQuery(GET_TALENT_BY_ID, {
    variables: {
      talentId: id,
    },
    skip: !id,
    fetchPolicy: 'no-cache',
  });

  const refetchTalentData = () => {
    refetch({
      talentId: id,
    });
  };

  const [updateProfileStatus] = useMutation<String, UpdateVerificationStatusVars>(UPDATE_VERIFICATION_STATUS);
  const [updateTalentProfile, { loading: talentIsUpdating }] = useMutation<UpdateTalentProfileVars>(ADMIN_UPDATE_TALENT_PROFILE, {
    // refetchQueries: [{ query: GET_TALENT_BY_ID, variables: { talentId: id } }],
    // awaitRefetchQueries: true,
    onCompleted() {
      // populateForm(adminUpdateTalentProfile);
      refetchTalentData()
    },
  });

  const handleUserStatusChange = (option: Option) => {
    updateProfileStatus({
      variables: {
        status: option.value,
        userId: id,
        userType: UserTypeEnum.talent,
      },
    });
  };

  const handleUserVisibilityChange = ({ value }: Option) => {
    // We must use only these two variables in mutation
    updateTalentProfile({
      variables: {
        talentId: id,
        isPrivate: value,
      },
    });
  };

  useEffect(() => {
    linkTo(`/${routes.ADMIN}/${routes.TALENTS}/${id}/${currentTab}`)();
  }, [currentTab]);

  return (
    <Box className={styles.container}>
      <Box className={styles.head}>
        <Box className={styles.avatarAndInfo}>
          <Avatar src={REACT_APP_AWSS3_URL_IMAGE_BEG + data?.getTalentProfile?.image} className={styles.avatar}>
            {data?.getTalentProfile?.firstName?.[0]} {data?.getTalentProfile?.lastName?.[0]}
          </Avatar>
          <Box>
            <Typography className={styles.name}>
              {data?.getTalentProfile?.firstName} {data?.getTalentProfile?.lastName}
            </Typography>
            <Typography className={styles.id}>ID: {id}</Typography>
            {isValid(new Date(data?.getTalentProfile.createdAt)) && (
              <Typography className={styles.id}>
                {intl('ADMIN.TALENTS.registrationDate')}: {format(new Date(data?.getTalentProfile.createdAt), 'dd.MM.yyyy')}
              </Typography>
            )}
          </Box>
        </Box>

        <Box className={styles.badges}>
          {data?.getTalentProfile && (
            <>
              <BadgeSelect
                options={VisibilityOptions}
                onChange={handleUserVisibilityChange}
                selected={VisibilityOptions.find((o: VisibilityOption) => o.value === data.getTalentProfile.isPrivate)}
                className={styles.badgeSelect}
              />
              <BadgeSelect
                options={PROFILE_OPTIONS}
                onChange={handleUserStatusChange}
                selected={PROFILE_OPTIONS.find((o: Option) => o.value === data.getTalentProfile.verificationStatus)}
                className={styles.badgeSelect}
              />
            </>
          )}
        </Box>
      </Box>
      <Tabs indicatorColor="primary" value={currentTab} onChange={handleTabChange}>
        <Tab value={TabsEnum.PUBLIC} label={intl('ADMIN.TALENT.public')} />
        <Tab value={TabsEnum.PRIVATE} label={intl('ADMIN.TALENT.private')} />
        <Tab value={TabsEnum.PAYMENT_HISTORY} label={intl('ADMIN.TALENT.paymentHistory')} />
        <Tab value={TabsEnum.ANALYTICS} label={intl('SHARED.analytics')} />
        <Tab value={TabsEnum.EDIT} label={intl('SHARED.edit')} />
      </Tabs>
      <Divider />

      <Wrapper className={styles.wrapper}>
        <Route exact path={`/${routes.ADMIN}/${routes.TALENTS}/:id/${TabsEnum.PRIVATE}`}>
          <Paper className={styles.privateInfoForm}>
            <Grid container component="div" spacing={2}>
              <Grid item sm={6}>
                <TextField disabled value={data?.getTalentProfile?.email} fullWidth label={intl('SHARED.email')} />
              </Grid>
              <Grid item sm={6}>
                <TextField disabled value={data?.getTalentProfile?.phone} fullWidth label={intl('SHARED.phone')} />
              </Grid>
            </Grid>
            {!loading && (
              <Box paddingTop="40px">
                <StripeConnect isAdmin stripeAccountId={data?.getTalentProfile?.payout?.stripe?.accountId} />
                <Divider />
                <Box paddingTop="32px">
                  <Grid container className={styles.paymentInputsContainer} spacing={3}>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        name="accountName"
                        className={styles.input}
                        value={data?.getTalentProfile?.payout?.wire?.accountName}
                        fullWidth
                        label={intl('SHARED.accountName')}
                        disabled
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        name="iban"
                        className={styles.input}
                        value={data?.getTalentProfile?.payout?.wire?.iban}
                        label={intl('SHARED.IBAN')}
                        fullWidth
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            )}
          </Paper>
        </Route>
        <Route path={[`/${routes.ADMIN}/${routes.TALENTS}/:id/${TabsEnum.PUBLIC}`, `/${routes.ADMIN}/${routes.TALENTS}/:id/`]} exact>
          {loading ? (
            <Loader />
          ) : (
            <>
              <ProfileTop talent={data?.getTalentProfile} isAdmin />
              <Tags talent={data?.getTalentProfile} />
              <About talent={data?.getTalentProfile} fromBrand />
              <Videos talent={data?.getTalentProfile} />
              <Feedbacks feedbacks={data?.getTalentProfile?.feedbacks} />
            </>
          )}
        </Route>
        <Route path={[`/${routes.ADMIN}/${routes.TALENTS}/:id/${TabsEnum.PAYMENT_HISTORY}`]} exact>
          <PaymentHistoryTable fieldName="adminGetPaymentHistory" query={ADMIN_GET_PAYMENT_HISTORY} talentOrBrandId={id} />
        </Route>
        <Route path={[`/${routes.ADMIN}/${routes.TALENTS}/:id/${TabsEnum.ANALYTICS}`]} exact>
          <TalentAnalytics />
        </Route>
        <Route path={[`/${routes.ADMIN}/${routes.TALENTS}/:id/${TabsEnum.EDIT}`]} exact>
          <TalentProfileForm
            talentId={id}
            talent={data?.getTalentProfile}
            refetchTalentData={refetchTalentData}
            updateCurrentTalent={updateTalentProfile}
            talentLoading={loading}
            talentIsUpdating={talentIsUpdating}
          />
        </Route>
      </Wrapper>
    </Box>
  );
};

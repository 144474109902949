import { darken, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../theme/colors';

export const useStyles = makeStyles((theme) => ({
  dateFilter: {
    height: '40vh',
    maxHeight: '90vh',
    width: 320,
    paddingLeft: theme.spacing(2),
  },
  filterContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  rangeGroup: {
    flexGrow: 1,
  },
  filterHeader: {
    fontWeight: 500,
    fontSize: '20px',
    '& > :first-child': {
      flexGrow: 1,
    },
    color: colors.BLACK,
  },
  filterLabel: {
    fontWeight: 500,
    fontSize: 16,
    color: colors.TEXT,
  },
  filterClear: {
    textTransform: 'none',
    opacity: 0.7,
  },
  centered: {
    textAlign: 'center',
    padding: 0,
    lineHeight: 1.5,
    fontSize: 14,
    height: 40,
    textTransform: 'capitalize',
  },
  delimiter: {
    margin: '0 4px',
    opacity: 0.1,
  },
  buttonsGroup: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
  clearBtn: {
    '&:disabled': {
      opacity: 0.7,
    },
  },
  applyBtn: {
    backgroundColor: colors.PRIMARY,
    color: colors.WHITE,
    marginLeft: theme.spacing(3),
    '&:hover': {
      backgroundColor: `${darken(colors.PRIMARY, 0.2)}`,
    },
    '&:disabled': {
      color: colors.WHITE,
      opacity: 0.3,
    },
  },
}));

import React from 'react';
import { Box } from '@material-ui/core';

import { GET_PAYMENT_HISTORY } from 'services/graphql/payments';
import { PaymentHistoryTable } from 'components/PaymentHistoryTable';

import { useStyles } from './styles';

export const PaymentHistory = () => {
  const styles = useStyles({});

  return (
    <Box className={styles.publicPageContent}>
      <PaymentHistoryTable query={GET_PAYMENT_HISTORY} fieldName="getPaymentHistory" />
    </Box>
  );
};

import React, { FC } from 'react';
import { Button } from '@material-ui/core';
import clsx from 'clsx';

import { useLink } from 'hooks/linkTo';
import { intl } from 'helpers';

import { useStyles } from './styles';

type Props  = {
  className?: string;
  fullWidth?: boolean;
  link: string;
};

export const ViewProfile: FC<Props> = ({ className, fullWidth = false, link }) => {
  const linkTo = useLink();
  const styles = useStyles();

  return (
    <Button
      className={clsx(styles.talentsBtn, className, { [styles.withFixedWidth]: !fullWidth })}
      variant="outlined"
      fullWidth={fullWidth}
      onClick={linkTo(link)}
    >
      {intl('SHARED.viewProfile')}
    </Button>
  )
};

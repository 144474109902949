/* eslint-disable curly */
// TODO: deep refactor needed
import React, { useState, useEffect } from 'react';
import { Button, Tabs, Tab, Box, Paper, Container, Divider, TableCell, TableRow, Hidden, withWidth, Fab, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useQuery } from '@apollo/client';
import { format } from 'date-fns';
import clsx from 'clsx';

import { useLink } from 'hooks/linkTo';
import { useAuth } from 'hooks/auth';
import { useBrandsErrors } from 'hooks/brand';
import { routes } from 'router/routesList';
import { intl, getCurrencySymbol, formatNumber, clipText, isBrandValid } from 'helpers';
import { UserTypeEnum } from 'types/common';

import { LIST_OPPORTUNITIES_BRAND, LIST_TALENT_OPPORTUNITIES } from 'services/graphql/query/opportunity';

import VerifyModal from 'components/Modals/VerifyModal';
import ApplicantAvatars from 'components/ApplicantAvatars';
import AvatarWithName from 'components/AvatarWithName';
import OpportunityStatus from 'components/OpportunityStatus';
import RateButton from 'components/RateButton';
import MobileOpportunity from 'components/MobileOpportunity';
import { Wrapper } from 'components/Wrapper';
import { Loader } from 'components/Loader';
import { DesertImage } from 'assets/svg-js';

import { useStyles } from './styles';
import { useStyles as UseTableStyles } from './components/Table/styles';
import TabPanel, { a11yProps } from './components/TabPanel';
import Table from './components/Table';
import {
  completeThCellsDesktop,
  completeThCellsTablet,
  completeThCellsDesktopTalent,
  completeThCellsTabletTalent,
  confirmedThCellsDesktop,
  confirmedThCellsTablet,
  confirmedThCellsDesktopTalent,
  confirmedThCellsTabletTalent,
  openThCellsTablet,
  openThCellsDesktop,
  openThCellsDesktopTalent,
  openThCellsTabletTalent,
} from './cells';
import { TABS, MAX_BRAND_OPPORTUNITIES_PER_QUERY, APPLICANT_STATUSES, STATUSES } from '../../../constants';

const OpportunitiesPage = ({ width }) => {
  const classes = useStyles();
  const tableClasses = UseTableStyles();
  const linkTo = useLink();
  const { getAssumedRole } = useAuth();
  const [currentTab, setCurrentTab] = useState(TABS.OPEN);
  const [hideButton, setHideButton] = useState(false);
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [verifyPopup, setVerifyPopup] = useState(false);
  const skip = getAssumedRole() !== UserTypeEnum.brand;
  const { brandErrors, brandLoading } = useBrandsErrors(skip);

  const toggleVerifyPopup = () => setVerifyPopup((old) => !old);

  const isBrandView = getAssumedRole() === UserTypeEnum.brand;
  const isEmpty = !items.length;

  const currentOpenThDesktop = isBrandView ? openThCellsDesktop : openThCellsDesktopTalent;
  const currentOpenThTablet = isBrandView ? openThCellsTablet : openThCellsTabletTalent;
  const currentConfirmedThDesktop = isBrandView ? confirmedThCellsDesktop : confirmedThCellsDesktopTalent;
  const currentConfirmedThTablet = isBrandView ? confirmedThCellsTablet : confirmedThCellsTabletTalent;
  const currentCompleteThDesktop = isBrandView ? completeThCellsDesktop : completeThCellsDesktopTalent;
  const currentCompleteThTablet = isBrandView ? completeThCellsTablet : completeThCellsTabletTalent;

  const { data, fetchMore, loading, refetch: refetchBrand } = useQuery(LIST_OPPORTUNITIES_BRAND, {
    variables: {
      filter: {
        status: currentTab.gqlStatus,
        limit: MAX_BRAND_OPPORTUNITIES_PER_QUERY,
      },
    },
    skip: !isBrandView,
    fetchPolicy: 'network-only',
  });

  const { data: dataTalent, fetchMore: fetchMoreTalent, loading: loadingTalent, refetch: refetchTalent } = useQuery(LIST_TALENT_OPPORTUNITIES, {
    variables: {
      filter: {
        limit: MAX_BRAND_OPPORTUNITIES_PER_QUERY,
        options: {
          collection: currentTab.talentStatus,
        },
      },
    },
    skip: isBrandView,
    fetchPolicy: 'network-only',
  });

  const refetchOpportunities = isBrandView ? refetchBrand : refetchTalent;

  const onCreateClick = () => {
    if (isBrandValid(brandErrors)) linkTo(`/${routes.BRAND}/${routes.NEW_OPPORTUNITY}`)();
    else toggleVerifyPopup();
  };

  const isDesktopView = (currentWidthView) => currentWidthView === 'lg' || width === 'xl';

  const shouldHideButton = (d) => setHideButton(!!d);

  const canRateOpportunity = (status, applicants = [], talent) => {
    if (getAssumedRole() === UserTypeEnum.brand && (!applicants?.length || !talent)) return false;
    if (getAssumedRole() === UserTypeEnum.talent && status === STATUSES.EXPIRED.value) return false;
    if (status === STATUSES.DECLINED.value) return false;
    if (status === STATUSES.CANCELED_BY_ADMIN_PAYMENT.value) return false;
    if (status === STATUSES.CANCELED_BY_ADMIN.value) return false;
    if (status === STATUSES.CANCELED_BY_TALENT.value) return false;

    return true;
  };

  const talentBudget = (opportunity) => opportunity.finalCondition?.talentSalary ?? opportunity.redemption.talentSalary;

  const createData = (unformattedList = []) =>
    unformattedList.map((i) => ({
      id: i.id,
      name: clipText(i.name, 56),
      talent: i.applicants?.find((a) => a.status === APPLICANT_STATUSES.CONFIRMED.value || a.status === APPLICANT_STATUSES.CANCELED.value),
      applicants: i.applicants,
      brand: i?.brand,
      country: i.country.name,
      city: clipText(i.city.name, 11),
      startDate: format(new Date(i.startDate), 'dd.MM.yyyy'),
      endDate: format(new Date(i.endDate), 'dd.MM.yyyy'),
      currency: getCurrencySymbol(i.redemption.currency.name),
      budget: formatNumber(getAssumedRole() === UserTypeEnum.talent ? talentBudget(i) : i.redemption.budget),
      status: i.status.name,
      isRated: i.isRated,
    }));

  const fetchMoreOpportunities = async () => {
    setFetchMoreLoading(true);
    if (isBrandView) {
      await fetchMore({
        variables: {
          filter: {
            status: currentTab.gqlStatus,
            offset: items.length,
            limit: MAX_BRAND_OPPORTUNITIES_PER_QUERY,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;

          const newItems = createData(fetchMoreResult.listBrandOpportunities.items);
          const fullItemsList = [...items, ...newItems];
          setItems(fullItemsList);

          const updatedData = {
            listBrandOpportunities: {
              items: [...prev.listBrandOpportunities.items, ...fetchMoreResult.listBrandOpportunities.items],
              ...prev.listBrandOpportunities,
            },
          };

          return updatedData;
        },
      });
    } else {
      await fetchMoreTalent({
        variables: {
          filter: {
            offset: items.length,
            limit: MAX_BRAND_OPPORTUNITIES_PER_QUERY,
            options: {
              collection: currentTab.talentStatus,
            },
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;

          const newItems = createData(fetchMoreResult.listTalentOpportunities.items);
          const fullItemsList = [...items, ...newItems];
          setItems(fullItemsList);

          const updatedData = {
            listTalentOpportunities: {
              items: [...prev.listTalentOpportunities.items, ...fetchMoreResult.listTalentOpportunities.items],
              ...prev.listTalentOpportunities,
            },
          };

          return updatedData;
        },
      });
    }
    setFetchMoreLoading(false);
  };

  const handleChange = (_, newValue) => {
    switch (newValue) {
      case TABS.OPEN.value:
        setCurrentTab(TABS.OPEN);
        break;
      case TABS.CONFIRMED.value:
        setCurrentTab(TABS.CONFIRMED);
        break;
      case TABS.COMPLETE.value:
        setCurrentTab(TABS.COMPLETE);
        break;
      default:
        setCurrentTab(TABS.OPEN);
    }
  };
  useEffect(() => {
    if (data?.listBrandOpportunities?.meta.total || data?.listBrandOpportunities?.meta.total === 0) {
      setItems(createData(data.listBrandOpportunities.items));
    }
  }, [data]);

  useEffect(() => {
    if (dataTalent?.listTalentOpportunities?.meta?.total || dataTalent?.listTalentOpportunities?.meta?.total === 0) {
      setItems(createData(dataTalent.listTalentOpportunities.items));
    }
  }, [dataTalent]);

  useEffect(() => {
    const total = isBrandView ? data?.listBrandOpportunities?.meta.total : dataTalent?.listTalentOpportunities?.meta?.total;
    shouldHideButton(total === items.length);
  }, [items]);

  const countOpportunities = isBrandView ? data?.listBrandOpportunities?.applications : dataTalent?.listTalentOpportunities?.applications;

  const itemCounts = (countOpportunities || []).reduce(
    (i, { status, total }) => {
      if (status) {
        i[status] = total;
      }
      return i;
    },
    { open: 0, confirmed: 0, completed: 0 },
  );

  const tabOpenText = `${intl(TABS.OPEN.label)} / ${itemCounts.open}`;
  const tabConfirmedText = `${intl(TABS.CONFIRMED.label)} / ${itemCounts.confirmed}`;
  const tabCompleteText = `${intl(TABS.COMPLETE.label)} / ${itemCounts.completed}`;

  /**
   * @private
   * @param {'xs' | 'sm' | 'md' | 'lg' | 'xl'} currentWidth
   * @param {[]} desktopCols
   * @param {[]} tabletCols
   * @returns {desktopCols | tabletCols}
   */
  const getThColumns = (currentWidth, desktopCols, tabletCols) => (isDesktopView(currentWidth) ? desktopCols : tabletCols);

  const verifyModalProps = {
    open: verifyPopup,
    handleClose: toggleVerifyPopup,
    errors: brandErrors,
  };

  return (
    <>
      <Wrapper>
        <Container className={classes.container}>
          <Box className={classes.box}>
            <Tabs
              indicatorColor="primary"
              aria-label="brand-opportunity-tabs"
              value={currentTab.value}
              variant="fullWidth"
              onChange={handleChange}
              classes={{ indicator: classes.indicator, root: classes.tabs }}
            >
              <Tab label={tabOpenText} {...a11yProps(TABS.OPEN.value)} />
              <Tab label={tabConfirmedText} {...a11yProps(TABS.CONFIRMED.value)} />
              <Tab label={tabCompleteText} {...a11yProps(TABS.COMPLETE.value)} />
            </Tabs>
            {isBrandView && (
              <Hidden xsDown>
                <Button disabled={brandLoading} startIcon={<Add />} className={classes.btnNewOpportunity} onClick={onCreateClick}>
                  {intl('BUTTON.newOpportunity')}
                </Button>
              </Hidden>
            )}
          </Box>

          <Divider className={classes.divider} />

          {loading || loadingTalent ? (
            <Loader className={classes.loader} />
          ) : (
            <Box className={clsx(classes.content, { [classes.fullHeight]: isEmpty })}>
              {isEmpty ? (
                <Box className={classes.emptyBox}>
                  <DesertImage className={classes.emptyIcon} />
                  <Typography className={classes.emptyText}>
                    {isBrandView ? intl('EMPTY_STATE.noOpportunitiesBrand') : intl('EMPTY_STATE.noOpportunitiesTalent')}
                  </Typography>
                  {isBrandView && currentTab.value === TABS.OPEN.value && (
                    <Button color="primary" variant="contained" disabled={brandLoading} onClick={onCreateClick}>
                      {intl('OPPORTUNITIES.btnCreate')}
                    </Button>
                  )}
                  {!isBrandView && currentTab.value === TABS.OPEN.value && (
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={loadingTalent}
                      onClick={linkTo(`/${routes.TALENT}/${routes.OPPORTUNITIES}`)}
                    >
                      {intl('OPPORTUNITIES.btnSearch')}
                    </Button>
                  )}
                </Box>
              ) : (
                <>
                  {/* Tab open */}
                  <TabPanel value={currentTab.value} index={TABS.OPEN.value}>
                    <Hidden xsDown>
                      <Table thCells={getThColumns(width, currentOpenThDesktop, currentOpenThTablet)}>
                        {items.map((row) => (
                          <TableRow key={row.id} className={tableClasses.row} onClick={linkTo(`${routes.OPPORTUNITIES}/${row.id}/details`)}>
                            <TableCell className={clsx(tableClasses.tdBody, tableClasses.nameCell)}>{row.name}</TableCell>
                            <TableCell className={tableClasses.tdBody}>
                              {isBrandView ? (
                                <ApplicantAvatars applicants={row.applicants} />
                              ) : (
                                <AvatarWithName brand={row.brand} applicants={row.applicants} isExpired={row.status === STATUSES.EXPIRED.value} />
                              )}
                            </TableCell>
                            <Hidden mdDown>
                              <TableCell className={tableClasses.tdBody}>{row.country}</TableCell>
                              <TableCell className={tableClasses.tdBody}>{row.city}</TableCell>
                            </Hidden>
                            <TableCell className={tableClasses.tdBody}>{row.startDate}</TableCell>
                            <TableCell className={tableClasses.tdBody}>{row.endDate}</TableCell>
                            <TableCell className={tableClasses.tdBody} align="right">
                              <span className={tableClasses.currency}>{row.currency}</span>
                              {row.budget}
                            </TableCell>
                          </TableRow>
                        ))}
                      </Table>
                    </Hidden>
                    <Hidden smUp>
                      <Paper elevation={1} className={classes.mobileContainer}>
                        {items.map((row) => (
                          <MobileOpportunity key={row.id} data={row} isBrand={isBrandView} />
                        ))}
                      </Paper>
                    </Hidden>
                  </TabPanel>

                  {/* Tab confirmed */}
                  <TabPanel value={currentTab.value} index={TABS.CONFIRMED.value}>
                    <Hidden xsDown>
                      <Table thCells={getThColumns(width, currentConfirmedThDesktop, currentConfirmedThTablet)}>
                        {items.map((row) => (
                          <TableRow key={row.id} className={tableClasses.row} onClick={linkTo(`${routes.OPPORTUNITIES}/${row.id}/details`)}>
                            <TableCell className={clsx(tableClasses.tdBody, tableClasses.nameCell)}>{row.name}</TableCell>
                            <TableCell className={tableClasses.tdBody}>
                              <AvatarWithName brand={row.brand} applicants={row.applicants} isExpired={row.status === STATUSES.EXPIRED.value} />
                            </TableCell>
                            <Hidden mdDown>
                              <TableCell className={tableClasses.tdBody}>{row.country}</TableCell>
                              <TableCell className={tableClasses.tdBody}>{row.city}</TableCell>
                            </Hidden>
                            <TableCell className={tableClasses.tdBody}>{row.startDate}</TableCell>
                            <TableCell className={tableClasses.tdBody}>{row.endDate}</TableCell>
                            <TableCell className={tableClasses.tdBody} align="right">
                              <span className={tableClasses.currency}>{row.currency}</span>
                              {row.budget}
                            </TableCell>
                          </TableRow>
                        ))}
                      </Table>
                    </Hidden>
                    <Hidden smUp>
                      <Paper elevation={1} className={classes.mobileContainer}>
                        {items.map((row) => (
                          <MobileOpportunity key={row.id} data={row} isBrand={isBrandView} />
                        ))}
                      </Paper>
                    </Hidden>
                  </TabPanel>

                  {/* Tab complete */}
                  <TabPanel value={currentTab.value} index={TABS.COMPLETE.value}>
                    <Hidden xsDown>
                      <Table thCells={getThColumns(width, currentCompleteThDesktop, currentCompleteThTablet)}>
                        {items.map((row) => (
                          <TableRow key={row.id} className={tableClasses.row}>
                            <TableCell
                              className={clsx(tableClasses.tdBody, tableClasses.nameCell)}
                              onClick={linkTo(`${routes.OPPORTUNITIES}/${row.id}/details`)}
                            >
                              {row.name}
                            </TableCell>
                            <TableCell className={tableClasses.tdBody} onClick={linkTo(`${routes.OPPORTUNITIES}/${row.id}/details`)}>
                              <AvatarWithName brand={row.brand} applicants={row.applicants} isExpired={row.status === STATUSES.EXPIRED.value} />
                            </TableCell>
                            <TableCell className={tableClasses.tdBody}>
                              {canRateOpportunity(row.status, row.applicants, row.talent) && (
                                <RateButton
                                  allItems={items?.length}
                                  opportunityId={row.id}
                                  isRated={row.isRated}
                                  isBrand={isBrandView}
                                  refetch={refetchOpportunities}
                                />
                              )}
                            </TableCell>
                            <Hidden mdDown>
                              <TableCell className={tableClasses.tdBody} onClick={linkTo(`${routes.OPPORTUNITIES}/${row.id}/details`)}>
                                {row.startDate}
                              </TableCell>
                              <TableCell className={tableClasses.tdBody} onClick={linkTo(`${routes.OPPORTUNITIES}/${row.id}/details`)}>
                                {row.endDate}
                              </TableCell>
                            </Hidden>
                            <TableCell className={tableClasses.tdBody} onClick={linkTo(`${routes.OPPORTUNITIES}/${row.id}/details`)}>
                              <span className={tableClasses.currency}>{row.currency}</span>
                              {row.budget}
                            </TableCell>
                            <TableCell className={tableClasses.tdBody} onClick={linkTo(`${routes.OPPORTUNITIES}/${row.id}/details`)}>
                              <OpportunityStatus status={row.status} isBrandView={isBrandView} />
                            </TableCell>
                          </TableRow>
                        ))}
                      </Table>
                    </Hidden>
                    <Hidden smUp>
                      <Paper elevation={1} className={classes.mobileContainer}>
                        {items.map((row) => (
                          <MobileOpportunity key={row.id} data={row} isBrand={isBrandView} />
                        ))}
                      </Paper>
                    </Hidden>
                  </TabPanel>
                </>
              )}
              {isBrandView && (
                <Hidden smUp>
                  <Fab color="primary" aria-label="create new opportunity" className={classes.fab} disabled={brandLoading} onClick={onCreateClick}>
                    <Add />
                  </Fab>
                </Hidden>
              )}
            </Box>
          )}
          <Button
            className={clsx(classes.btnShowMore, { [classes.hidden]: hideButton || loading })}
            onClick={fetchMoreOpportunities}
            disabled={loading || fetchMoreLoading}
          >
            {intl('BUTTON.showMore')}
          </Button>
        </Container>
      </Wrapper>
      <VerifyModal {...verifyModalProps} />
    </>
  );
};

export default withWidth()(OpportunitiesPage);

import React from 'react';
import { Box, Hidden, Paper } from '@material-ui/core';
import { useQuery } from '@apollo/client';

import { getLetterForCompanyNameAvatar } from 'helpers';
import { verificationStatusEnum } from 'types/common';
import { GET_CURRENT_BRAND } from 'services/graphql/brand';

import { ProfileCard } from 'components/ProfileCard';
import { CompanyProfileTop } from 'components/CompanyProfileTop';
import VerifyMePanel from 'components/VerifyMePanel';
import { Loader } from 'components/Loader';

import { Feedbacks } from 'components/Feedbacks';
import { Wrapper } from 'components/Wrapper';
import { About } from './components/About';

import { useStyles } from './styles';

export const CompanyPublicPage = () => {
  const styles = useStyles({});
  const { data: currentBrandData, loading } = useQuery(GET_CURRENT_BRAND, { fetchPolicy: 'network-only' });

  const displayVerifyPanel = !loading && currentBrandData?.getCurrentBrand?.verificationStatus !== verificationStatusEnum.verified;

  return (
    <>
      {displayVerifyPanel && <VerifyMePanel verificationStatus={currentBrandData?.getCurrentBrand?.verificationStatus} />}
      <Wrapper>
        <Box className={styles.publicPage}>
          <Hidden smDown>
            <ProfileCard
              avatarDisabled
              imgSrc={currentBrandData?.getCurrentBrand?.image}
              imgAlt={getLetterForCompanyNameAvatar(currentBrandData?.getCurrentBrand?.companyName)}
              className={styles.profileCard}
            />
          </Hidden>
          <Box className={styles.publicPageContent}>
            {loading ? (
              <Paper className={styles.loaderPlaceholder}>
                <Loader />
              </Paper>
            ) : (
              <>
                <CompanyProfileTop brand={currentBrandData?.getCurrentBrand} />
                <About brand={currentBrandData?.getCurrentBrand} />
                <Feedbacks feedbacks={currentBrandData?.getCurrentBrand?.feedback} />
              </>
            )}
          </Box>
        </Box>
      </Wrapper>
    </>
  );
};

import { makeStyles } from '@material-ui/core'
import { colors } from '../../../../theme/colors'

export const useStyles = makeStyles((theme) => ({
  modal: {
    maxWidth: 384,
  },
  container: {
    margin: -theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  modalIconBox: {
    marginBottom: theme.spacing(4),
    background: colors.BACKGROUND,
    padding: '24px 42px',
    borderRadius: '100%',
  },
  text: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    fontSize: 18,
    textAlign: 'center',
  },
  buttons: {
    width: 152,
    marginRight: theme.spacing(2),
    '&:last-child': {
      marginRight: 0,
    },
    [theme.breakpoints.only('xs')]: {
      marginRight: theme.spacing(1),
      width: 140,
    },
  },
  btnSmallText: {
    fontSize: 12,
  },
}))

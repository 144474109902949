import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
  },
  text: {
    marginLeft: theme.spacing(1),
  },
}))

export { useStyles }

import { makeStyles, Theme } from '@material-ui/core';
import { colors } from 'theme/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: 24,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  fullHeight: {
    height: '100%',
  },
  rightBlock: {
    height: '100%',
    flex: 1,
    marginLeft: 24,
    '@media (max-width: 768px)': {
      marginLeft: 0,
    },
  },
  mobileFilters: {},
  search: {
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      marginTop: 13,
    },
    [theme.breakpoints.down('xs')]: {
      flex: 'auto',
    },
  },
  showMoreContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  buttonShowMore: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  tabs: {
    borderBottom: `1px solid ${colors.BORDER}`,
    marginTop: -1,
    [theme.breakpoints.down('xs')]: {
      order: -1,
      width: '100%',
      marginBottom: theme.spacing(3),
    },
  },
  inputAndTabs: {
    marginBottom: theme.spacing(3),
    alignItems: 'center',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'initial',
      flexWrap: 'wrap',
    },
  },
  tabItem: {
    [theme.breakpoints.down('xs')]: {
      width: '50%',
    },
  },
  filterIcon: {
    marginLeft: theme.spacing(2),
  },
  zeroMargin: {
    marginBottom: 0,
  },
  emptySearchBox: {
    marginTop: -theme.spacing(6),
    margin: '0 auto',
    height: '100%',
    maxWidth: 406,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      marginTop: -theme.spacing(3),
    },
  },
  emptyBox: {
    marginTop: -60,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      marginTop: -160,
    },
  },
  emptySearchText: {
    marginTop: theme.spacing(3),
    fontWeight: 500,
    fontSize: 24,
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
      marginBottom: theme.spacing(2),
    },
  },
  emptyText: {
    marginTop: -86,
    marginBottom: theme.spacing(4),
    fontWeight: 500,
    fontSize: 24,
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
      marginTop: -100,
      marginBottom: theme.spacing(2),
    },
  },
  emptyIcon: {
    [theme.breakpoints.only('xs')]: {
      width: 305,
    },
  },
}));

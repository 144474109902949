import React, { FC } from 'react';
import { Box, Button, Grid, IconButton, Paper, Popover, Typography } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { CloseRounded as CloseRoundedIcon } from '@material-ui/icons';

import { intl } from 'helpers';

import { FilterTextField } from 'components/FilterTextField';

import { useStyles } from './styles';
import type { Props } from './types';

export const DateRangeFilter: FC<Props> = ({
  renderFilter,
  open,
  handleCloseFilter,
  handleDateChange,
  handleFilterReset,
  filterDate,
  handleFilterApply,
  isDisabled,
}) => {
  const styles = useStyles({});

  return (
    <Popover
      open={open}
      id="date-range-filter"
      onClose={handleCloseFilter}
      anchorEl={renderFilter}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Paper className={styles.dateFilter}>
        <Box className={styles.filterContent}>
          <Box className={styles.rangeGroup}>
            <Grid container item alignItems="center" className={styles.filterHeader}>
              <Grid item>{intl('PAYMENT_HISTORY.filters')}</Grid>
              <IconButton onClick={handleCloseFilter}>
                <CloseRoundedIcon />
              </IconButton>
            </Grid>

            <Box display="flex" alignItems="center" justifyContent="space-between" px={1} my={2}>
              <Typography className={styles.filterLabel}>{intl('PAYMENT_HISTORY.date')}</Typography>
              <Button className={styles.filterClear} onClick={handleFilterReset}>
                {intl('PAYMENT_HISTORY.FILTERS.clear')}
              </Button>
            </Box>
            <Box display="flex" alignItems="center" pr={2}>
              <DatePicker
                format="dd.MM.yy"
                maxDate={filterDate.to || Date.now()}
                value={filterDate.from || null}
                onChange={handleDateChange('from')}
                animateYearScrolling
                TextFieldComponent={(params) => (
                  // @ts-ignore
                  <FilterTextField inputClassName={styles.centered} placeholder={intl('PAYMENT_HISTORY.FILTERS.from')} name="from" {...params} />
                )}
              />
              <Box className={styles.delimiter}>
                <Typography>-</Typography>
              </Box>

              <DatePicker
                format="dd.MM.yy"
                maxDate={Date.now()}
                value={filterDate.to || null}
                onChange={handleDateChange('to')}
                animateYearScrolling
                TextFieldComponent={(params) => (
                  // @ts-ignore
                  <FilterTextField inputClassName={styles.centered} placeholder={intl('PAYMENT_HISTORY.FILTERS.to')} name="to" {...params} />
                )}
              />
            </Box>
          </Box>

          <Box className={styles.buttonsGroup}>
            <Button disabled={isDisabled} variant="outlined" className={styles.clearBtn} onClick={handleFilterReset}>
              {intl('PAYMENT_HISTORY.FILTERS.clearAll')}
            </Button>
            <Button disabled={isDisabled} className={styles.applyBtn} disableElevation onClick={handleFilterApply}>
              {intl('PAYMENT_HISTORY.FILTERS.applyFilters')}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Popover>
  );
};

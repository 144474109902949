import { makeStyles } from '@material-ui/core'
import { colors } from '../../../theme/colors'

export const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 48,
    width: 48,
    marginRight: 16,
  },
  name: {
    fontWeight: 500,
    fontSize: 16,
    color: colors.TEXT_H,
  },
  id: {
    opacity: 0.4,
  },
  brandLink: {},
  inputInvoice: {},
  status: {
    height: 'min-content',
    padding: '3px 12px',
    borderRadius: 4,
  },
  visibleStatus: {
    background: 'rgba(131, 191, 36, 0.3)',
    border: '1px solid rgba(131, 191, 36, 0.3)',
  },
  privateStatus: {
    border: '1px solid #000',
    background: colors.BACKGROUND,
  },
  badges: {
    minWidth: 95,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  badgeSelect: {
    marginTop: 12,
  },
  headerGrid: {
    alignItems: 'center',
  },
  head: {
    display: 'flex',
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  container: {
    padding: theme.spacing(2),
  },
  indicatorsGrid: {
    flexGrow: 1,
  },
  wrapper: {
    marginTop: theme.spacing(2),
    display: 'block',
    marginLeft: 'auto!important',
    marginRight: 'auto',
  },
  privateInfoForm: {
    padding: 30,
    minHeight: 130,
    paddingBottom: 24,
    width: '100%',
  },
  brand: {
    color: colors.INPUT_LABEL,
    fontSize: 12,
  },
  companyInfoForm: {
    padding: 30,
    paddingBottom: 44,
    minHeight: 275,
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  paymentInfoBlock: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),

    [theme.breakpoints.down('xs')]: {
      marginBottom: 0,
    },
  },
}))

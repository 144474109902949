import React, { FC, useContext } from 'react';
import { Box, Typography, Button } from '@material-ui/core';

import { intl } from 'helpers';
import { AppContext } from 'hooks/app';
import { VerifiedIcon } from 'assets/svg-js/index';

import { Modal } from 'components/Modal';

import type { Props } from './types';
import { useStyles } from './styles';

export const SuccessModal: FC<Props> = () => {
  const {
    state: { successModal },
    dispatch,
  } = useContext(AppContext);
  const onClose = () => {
    if (successModal.onCloseFunction) {
      successModal.onCloseFunction();
    }
    dispatch({ type: 'SUCCESS_MODAL', payload: { isOpen: false, title: '', onCloseFunction: null } });
  };

  const styles = useStyles();

  const Actions = () => (
    <Button onClick={onClose} fullWidth variant="contained" color="primary">
      {intl('SHARED.ok')}
    </Button>
  );

  return (
    <Modal
      isOpen={successModal.isOpen}
      handleClose={onClose}
      title={null}
      disabledBorder
      className={styles.modal}
      dialogActionsComponent={<Actions />}
    >
      <Box className={styles.container}>
        <Box className={styles.iconBox}>
          <VerifiedIcon />
        </Box>
        <Typography className={styles.txt}>{intl(successModal.title)}</Typography>
      </Box>
    </Modal>
  );
};

import React, { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment, { MomentInputObject } from 'moment';
import { Event as EventIcon, ArrowRightAlt as ArrowRightAltIcon } from '@material-ui/icons';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

import { ANCHOR_LEFT, HORIZONTAL_ORIENTATION, VERTICAL_ORIENTATION } from 'react-dates/constants';
import { intl } from 'helpers';

import './react_dates_overrides.css';
// import { useTalentAnalytics } from '../hooks';
import { useStyles } from '../styles';

import type { HeaderProps } from '../types';

export const Header: FC<HeaderProps> = ({ startDate, endDate, handleDateChange, handleSetFocus, currentFocus }) => {
  const styles = useStyles({});
  // const { startDate, endDate, handleDateChange, handleSetFocus, currentFocus } = useTalentAnalytics();
  const theme = useTheme();
  const isSmallViewport = useMediaQuery(theme.breakpoints.down('md'));
  const formatRepresentation = 'DD/MM/yyyy';
  const isOutsideRange = (day: MomentInputObject) => {
    if (moment().diff(day) < 0) {
      return true;
    }
    return moment(day) <= moment().subtract(1, 'year');
  };

  return (
    <Grid className={styles.header} container item direction="row" alignItems="center" justify="space-between">
      <Grid>
        <Typography className={styles.headerTitle}>{intl('TALENT.ANALYTICS.analytics')}</Typography>
      </Grid>
      <Grid>
        <DateRangePicker
          startDate={startDate}
          startDateId="startDate"
          displayFormat={formatRepresentation}
          endDate={endDate}
          anchorDirection={ANCHOR_LEFT}
          endDateId="endDate"
          small
          minimumNights={0}
          isOutsideRange={isOutsideRange}
          minDate={moment().subtract(1, 'year')}
          maxDate={moment().add(1, 'year').subtract(1, 'M')}
          showClearDates
          onDatesChange={handleDateChange}
          focusedInput={currentFocus}
          orientation={isSmallViewport ? VERTICAL_ORIENTATION : HORIZONTAL_ORIENTATION}
          onFocusChange={(input: string) => handleSetFocus(input)}
          customInputIcon={<EventIcon className={styles.calendarIcon} />}
          customArrowIcon={<ArrowRightAltIcon />}
        />
      </Grid>
    </Grid>
  );
};

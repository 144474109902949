import React, { FC } from 'react';
import { Box } from '@material-ui/core';

import { Loader } from 'components/Loader';

import { useStyles } from './styles';

const LoadingImage: FC = () => {
  const styles = useStyles({});

  return (
    <Box className={styles.loadingWrapper}>
      <Loader />
    </Box>
  );
};

export default LoadingImage;

import { useState } from 'react';
import { useQuery } from '@apollo/client';

import { GET_TALENT_REQUIRED_FIELDS } from 'services/graphql/query/opportunity';
import type { TalentValidation } from 'types/talent';
import { VerificationRequestStatusesEnum } from 'types/talent';

type FieldType = 'public' | 'private' | 'payment';

const validateTalent = (info, skippedFields: Array<FieldType>) => {
  const errors: TalentValidation = {
    hasPublicInfo: true,
    hasPrivateInfo: true,
    isVerified: true,
  };

  if (
    !skippedFields.includes('public') &&
    (!info.firstName?.length || !info.lastName?.length || !info.city.name || !info.country.name || !info.gender || !info.image || !info.birth)
  ) {
    errors.hasPublicInfo = false;
  }
  if (!skippedFields.includes('private') && (!info.hasWire || !info.hasStripe)) {
    errors.hasPrivateInfo = false;
  }
  if (!skippedFields.includes('payment') && info.verificationStatus !== VerificationRequestStatusesEnum.Verified) {
    errors.isVerified = false;
  }

  return errors;
};

const isTalentValid = (fields: TalentValidation) => Object.values(fields).every((k: boolean) => !!k);

const useTalentErrors = (skip = false, skippedFields = []) => {
  const [fields, setFields] = useState<TalentValidation>({
    hasPublicInfo: true,
    hasPrivateInfo: true,
    isVerified: true,
  });

  const { loading } = useQuery(GET_TALENT_REQUIRED_FIELDS, {
    skip,
    fetchPolicy: 'no-cache',
    onCompleted: ({ getCurrentTalent }) => {
      setFields(validateTalent(getCurrentTalent, skippedFields));
    },
  });

  return {
    talentLoading: loading,
    talentErrors: fields,
    isTalentValid: isTalentValid(fields),
  };
};

export { useTalentErrors };

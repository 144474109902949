import React from 'react';
import clsx from 'clsx';
import { MenuRounded, ChevronLeft, Euro as EuroIcon, ViewDay as ViewDayIcon } from '@material-ui/icons';
import { Drawer, Box, IconButton, Divider, ListItem, List, ListItemText, ListItemIcon } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { intl } from 'helpers';
import { TalentsIcon, BrandManagersIcon, BrandsIcon, OpportunitiesIcon, LogOutIcon, AnalyticsIcon } from 'assets/svg-js';

import { useAuth } from 'hooks/auth';
import { colors } from 'theme/colors';
import { useLink } from 'hooks/linkTo';
import { routes } from 'router/routesList';

import { useStyles } from './styles';
import { SportEndorse } from '../SportEndorse';

const menuItems = [
  { link: routes.TALENTS, name: 'SHARED.talent', icon: TalentsIcon },
  { link: routes.BRAND_MANAGERS, name: 'SHARED.brandManagers', icon: BrandManagersIcon },
  { link: routes.BRANDS, name: 'SHARED.brands', icon: BrandsIcon },
  { link: routes.OPPORTUNITIES, name: 'SHARED.opportunities', icon: OpportunitiesIcon },
  { link: routes.ANALYTICS, name: 'SHARED.analytics', icon: AnalyticsIcon },
  { link: routes.PROFIT_MARGINS, name: 'ADMIN.PROFIT.profitMargins', icon: EuroIcon },
  { link: routes.NEWS_FEED, name: 'SHARED.newsFeed', icon: ViewDayIcon },
];

export const AdminDrawer = ({ open, toggleDrawer }) => {
  const linkTo = useLink();
  const styles = useStyles({ drawerWidth: 256, open });
  const { page } = useParams();
  const { signOut } = useAuth();
  const handleSignOut = () => signOut({ redirectUrl: '/login' });

  return (
    <Drawer
      variant="permanent"
      className={clsx(styles.drawer, {
        [styles.drawerOpen]: open,
        [styles.drawerClose]: !open,
      })}
      classes={{
        paper: clsx(styles.drawer, {
          [styles.drawerOpen]: open,
          [styles.drawerClose]: !open,
        }),
      }}
    >
      <Box>
        <Box className={styles.toolbar}>
          {open ? (
            <>
              <SportEndorse />
              <IconButton size="small" onClick={toggleDrawer}>
                <ChevronLeft />
              </IconButton>
            </>
          ) : (
            <IconButton size="small" className={styles.menuIcon} onClick={toggleDrawer}>
              <MenuRounded />
            </IconButton>
          )}
        </Box>
        <Divider />
        <List className={styles.list}>
          {menuItems.map((item) => (
            <ListItem onClick={linkTo(`/${routes.ADMIN}/${item.link}`)} button key={item.name}>
              <ListItemIcon>
                <item.icon
                  className={clsx({ [styles.hoveredIco]: page === item.link }, styles.icon)}
                  fill={page === item.link ? colors.PRIMARY_HOVERED : undefined}
                />
              </ListItemIcon>
              <ListItemText
                className={clsx({
                  [styles.current]: page === item.link,
                })}
                primary={intl(item.name)}
              />
            </ListItem>
          ))}
        </List>
      </Box>
      <Box>
        <ListItem onClick={handleSignOut} button>
          <ListItemIcon>
            <LogOutIcon />
          </ListItemIcon>
          <ListItemText primary={intl('SHARED.logOut')} />
        </ListItem>
      </Box>
    </Drawer>
  );
};

/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { format, isValid } from 'date-fns';
import { TableRow, TableCell, Paper, Box, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { SearchFilter } from 'assets/svg-js';
import { routes } from 'router/routesList';
import { useLink } from 'hooks/linkTo';
import { formatNumber, getCurrencySymbol, intl } from 'helpers';

import { LIST_TALENT_OPPORTUNITIES } from 'services/graphql/query/opportunity';

import { TableWithPagination } from 'components/TableWithPagination';
import { AdminSearchFilters } from 'components/AdminSearchFilters';
import { OpportunityFilters } from 'components/OpportunityFilters/OpportunityFilters';
import { TableLoader } from 'components/TableLoader';

import { useStyles } from './styles';

const thReal = [
  { name: 'ADMIN.OPPORTUNITIES.opportunityName', minWidth: '163px', width: '330px', sortField: 'name' },
  { name: 'ADMIN.OPPORTUNITIES.type', minWidth: '123px', width: '248px' },
  { name: 'ADMIN.OPPORTUNITIES.budget', minWidth: '93px', width: '100px' },
  { name: 'ADMIN.OPPORTUNITIES.status', minWidth: '100px', width: '176px', sortField: 'statusName' },
  { name: 'ADMIN.OPPORTUNITIES.country', minWidth: '115px', width: '151px', sortField: 'countryId' },
  { name: 'ADMIN.OPPORTUNITIES.city', minWidth: '105px', width: '191px', sortField: 'cityId' },
  { name: 'ADMIN.OPPORTUNITIES.startDate', minWidth: '107px', width: '112px', sortField: 'startDate' },
  { name: 'ADMIN.OPPORTUNITIES.endDate', minWidth: '107px', width: '112px', sortField: 'endDate' },
  { name: 'ADMIN.OPPORTUNITIES.datePosted', minWidth: '107px', width: '124px', sortField: 'createdAt' },
  { name: 'ADMIN.OPPORTUNITIES.brand', minWidth: '153px', width: '161px', align: 'right', sortField: 'brandName' },
];

export const Opportunities = () => {
  const styles = useStyles({});
  const linkTo = useLink();
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(thReal[4].sortField);

  const createSortHandler = (sortField) => () => {
    setPage(0);
    setOrderBy(sortField);
    setOrder((current) => (current === 'asc' ? 'desc' : 'asc'));
  };

  const { data, loading } = useQuery(LIST_TALENT_OPPORTUNITIES, {
    variables: {
      filter: {
        offset: rowsPerPage * page,
        limit: rowsPerPage,
        options: {
          statusId: filters?.status?.map(({ id }) => id),
          typeId: filters?.typeId,
          cityId: filters?.city?.map((city) => city.id),
          countryId: filters?.country?.map((country) => country.id),
          budget: {
            from: +filters?.budgetFrom || 0,
            to: +filters?.budgetTo || 99999999,
          },
          startDate: {
            from: filters?.startDate?.setHours(0, 0, 0, 0) || null,
            to: filters?.endDate?.setHours(23, 59, 59, 999) || null,
          },
        },
        sort: {
          field: orderBy,
          order,
        },
      },
      search,
    },
    onCompleted({ listTalentOpportunities }) {
      setDataToDisplay(listTalentOpportunities?.items || []);
    },
  });

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (e) => {
    setPage(Math.floor((rowsPerPage / e.target.value) * page));
    setRowsPerPage(e.target.value);
  };

  const handleApply = (values) => {
    setPage(0);
    setFilters(values);
  };

  const filtersActive = Boolean(
    filters?.typeId?.length ||
      filters?.city?.length ||
      filters?.country?.length ||
      filters?.status?.length ||
      +filters?.budgetFrom ||
      filters?.budgetTo ||
      filters?.startDate ||
      filters?.endDate,
  );

  useEffect(() => {
    setPage(0);
  }, [search]);

  const emptyTable = !dataToDisplay.length || loading;

  return (
    <Paper className={styles.paper}>
      <TableWithPagination
        thCells={thReal}
        emptyHead={emptyTable}
        className={clsx({
          [styles.fullHeight]: emptyTable,
        })}
        tableLabel="Opportunities"
        toolBarRightComponent={
          <AdminSearchFilters
            hiddenExport
            filterComponent={OpportunityFilters}
            handleApply={handleApply}
            onSearchChange={setSearch}
            filters={filters}
            filtersActive={filtersActive}
          />
        }
        paginationProps={{
          count: data?.listTalentOpportunities?.meta?.total,
          page,
          rowsPerPage,
          handleChangePage,
          handleChangeRowsPerPage,
        }}
        createSortHandler={createSortHandler}
        order={order}
        orderBy={orderBy}
      >
        {loading ? (
          <TableLoader columnCount={10} />
        ) : dataToDisplay.length ? (
          dataToDisplay.map((row) => (
            <TableRow className={styles.row} hover onClick={linkTo(`/${routes.ADMIN}/${routes.OPPORTUNITIES}/${row.id}/details`)} key={row.id}>
              <TableCell className={styles.tCell}>{row.name}</TableCell>
              <TableCell className={styles.tCell}>{row.type?.name}</TableCell>
              <TableCell className={styles.tCell}>
                {getCurrencySymbol(row.redemption?.currency?.code)} {formatNumber(row.redemption?.budget)}
              </TableCell>
              <TableCell className={styles.tCell}>{row.status?.name}</TableCell>
              <TableCell className={styles.tCell}>{row.country?.name}</TableCell>
              <TableCell className={styles.tCell}>{row.city?.name}</TableCell>
              <TableCell className={styles.tCell}>{isValid(row.startDate) && format(row.startDate, 'dd.MM.yyyy')}</TableCell>
              <TableCell className={styles.tCell}>{isValid(row.endDate) && format(new Date(row.endDate), 'dd.MM.yyyy')}</TableCell>
              <TableCell className={styles.tCell}>{isValid(row.createdAt) && format(new Date(row.createdAt), 'dd.MM.yyyy')}</TableCell>
              <TableCell className={styles.tCell} align="right">
                {row.brand?.name}
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <Box className={styles.emptyBox}>
              <SearchFilter />
              <Typography className={styles.emptyText}>{intl('EMPTY_STATE.noResults')}</Typography>
            </Box>
          </TableRow>
        )}
      </TableWithPagination>
    </Paper>
  );
};

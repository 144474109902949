import React, { useState } from 'react';
import { Button, Typography, TextField, Box } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { Rating } from '@material-ui/lab';
import { useFormik } from 'formik';
import { useStyles } from './styles';
import { intl } from '../../helpers';
import { Modal } from '../Modal';
import { CREATE_FEEDBACK } from '../../services/graphql/feedback';
import { useAuth } from '../../hooks/auth';
import { MAX_BRAND_OPPORTUNITIES_PER_QUERY } from 'constants/constants';

const RateButton = ({ allItems, isRated, opportunityId, isBrand, refetch }) => {
  const classes = useStyles();
  const { getRole } = useAuth();
  const [rateModalOpened, setRateModalOpened] = useState(false);

  const toggleRateModal = () =>
    setRateModalOpened((prev) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const refetchOptions = {
        filter: {
          limit: MAX_BRAND_OPPORTUNITIES_PER_QUERY > allItems ? MAX_BRAND_OPPORTUNITIES_PER_QUERY : allItems,
        },
      };
      if (isBrand) {
        refetchOptions.filter.status = 'closed';
      } else {
        refetchOptions.filter.options = {
          collection: 'completed',
        };
      }
      refetch(refetchOptions);
      resetForm();

      return !prev;
    });

  const [createFeedback, { loading }] = useMutation(CREATE_FEEDBACK, {
    onCompleted: toggleRateModal,
    awaitRefetchQueries: true,
  });

  const handleFeedbackCreate = (values) => {
    const feedbackOptions = {
      variables: {
        data: {
          opportunityId,
          rate: values.rate,
        },
      },
    };
    if (values.description) {
      feedbackOptions.variables.data.description = values.description;
    }
    createFeedback(feedbackOptions);
  };

  const { values, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: { description: '', rate: 0 },
    onSubmit: handleFeedbackCreate,
  });
  
  return (
    <>
      <Button
        className={classes.button}
        onClick={toggleRateModal}
        disabled={isRated}
      >
        {isRated ? intl('RATE_BUTTON.rated') : intl('RATE_BUTTON.rate')}
      </Button>

      <Modal
        isOpen={rateModalOpened}
        title={intl(`RATE.rate${getRole() === 'brand' ? 'Talent' : 'Brand'}`)}
        handleClose={toggleRateModal}
        dialogActionsComponent={
          <>
            <Button
              variant="outlined"
              onClick={toggleRateModal}
              className={classes.modalButtons}
              disabled={loading}
            >
              {intl('SHARED.cancel')}
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              className={classes.modalButtons}
              color="primary"
              disabled={loading || values.rate === 0}
            >
              {intl('SHARED.confirm')}
            </Button>
          </>
        }
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography className={classes.smallText}>{intl(`RATE.rateText${getRole() === 'brand' ? 'Talent' : 'Brand'}`)}</Typography>
          <Rating value={+values.rate} onChange={handleChange} name="rate" size="large" classes={{ root: classes.rating }} />
          <TextField
            className={classes.textField}
            label={intl('RATE.addCommentToRating')}
            multiline
            name="description"
            value={values.description}
            onChange={handleChange}
            fullWidth
          />
        </Box>
      </Modal>
    </>
  );
};

export default RateButton;

import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  tab: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
}))

/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { TableRow, TableCell, Paper, Avatar, Box, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import clsx from 'clsx';

import { SearchFilter } from 'assets/svg-js';
import { intl } from 'helpers';
import { routes } from 'router/routesList';
import { useLink } from 'hooks/linkTo';
import { GET_BRAND_MANAGERS_LIST } from 'services/graphql/admin';
import { getStatus } from 'helpers/admin';

import { TableWithPagination } from 'components/TableWithPagination';
import { TableLoader } from 'components/TableLoader';
import { BrandManagerFilters } from 'components/BrandManagerFilters';

import { useStyles } from './styles';

const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env;

const thReal = [
  { name: 'ADMIN.BRAND_MANAGERS.profilePhoto', minWidth: 73, width: 90 },
  { name: 'ADMIN.BRAND_MANAGERS.firstName', minWidth: 100, width: 132, sortField: 'firstName' },
  { name: 'ADMIN.BRAND_MANAGERS.lastName', minWidth: 101, width: 131, sortField: 'lastName' },
  { name: 'ADMIN.BRAND_MANAGERS.userId', minWidth: 137, width: 414 },
  { name: 'ADMIN.BRAND_MANAGERS.phone', minWidth: 117, width: 145 },
  { name: 'ADMIN.BRAND_MANAGERS.email', minWidth: 255, width: 317, sortField: 'email' },
  { name: 'ADMIN.BRAND_MANAGERS.status', minWidth: 111, width: 111, sortField: 'verificationStatus' },
  { name: 'ADMIN.BRANDS.registrationDate', minWidth: 143, width: 223, sortField: 'createdAt' },
  { name: 'ADMIN.BRAND_MANAGERS.brandName', minWidth: 94, width: 156, align: 'right' },
];

export const BrandManagers = () => {
  const styles = useStyles({});
  const linkTo = useLink();
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterDate, setFilterDate] = useState({ from: null, to: null });
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(thReal[7].sortField);
  const [search, setSearch] = useState('');

  const createSortHandler = (sortField) => () => {
    setOrderBy(sortField);
    setOrder((current) => (current === 'asc' ? 'desc' : 'asc'));
  };

  const { loading, data } = useQuery(GET_BRAND_MANAGERS_LIST, {
    onCompleted({ adminListBrandManagers }) {
      setDataToDisplay(adminListBrandManagers?.items?.slice(0, rowsPerPage) || []);
    },
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        offset: rowsPerPage * page,
        limit: rowsPerPage,
        sort: {
          field: orderBy,
          order,
        },
        options: {
          datePeriod:
            filterDate?.from || filterDate?.to
              ? {
                  from: filterDate?.from ? +filterDate.from : undefined,
                  to: filterDate?.to ? +filterDate.to : undefined,
                }
              : undefined,
        },
      },
      search,
    },
  });

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (e) => {
    setPage(Math.floor((rowsPerPage / e.target.value) * page));
    setRowsPerPage(e.target.value);
  };

  const handleFilterApply = (date) => {
    setPage(0);
    setFilterDate(date);
  };

  useEffect(() => {
    setPage(0);
  }, [search]);

  const emptyTable = !dataToDisplay.length || loading;

  return (
    <Paper className={styles.paper}>
      <TableWithPagination
        thCells={thReal}
        tableLabel="BrandManagers"
        emptyHead={emptyTable}
        className={clsx({
          [styles.fullHeight]: emptyTable,
        })}
        paginationProps={{
          count: data?.adminListBrandManagers.meta.total,
          page,
          rowsPerPage,
          handleChangePage,
          handleChangeRowsPerPage,
        }}
        toolBarRightComponent={
          <BrandManagerFilters
            saveToCSVLink={data?.adminListBrandManagers?.listBrandUsersUrl}
            filterDate={filterDate}
            applyFilter={handleFilterApply}
            onSearchChange={setSearch}
          />
        }
        createSortHandler={createSortHandler}
        order={order}
        orderBy={orderBy}
      >
        {loading ? (
          <TableLoader columnCount={10} />
        ) : dataToDisplay.length ? (
          dataToDisplay.map((row) => (
            <TableRow className={styles.row} hover onClick={linkTo(`/${routes.ADMIN}/${routes.BRAND_MANAGERS}/${row.id}`)} key={row.id}>
              <TableCell className={styles.tCell}>
                <Avatar className={styles.avatar} src={REACT_APP_AWSS3_URL_IMAGE_BEG + row.image}>
                  {row.firstName?.[0]} {row.lastName?.[0]}
                </Avatar>
              </TableCell>
              <TableCell className={styles.tCell}>{row.firstName || '-'}</TableCell>
              <TableCell className={styles.tCell}>{row.lastName || '-'}</TableCell>
              <TableCell className={styles.tCell}>{row.id || '-'}</TableCell>
              <TableCell className={styles.tCell}>{row.phone || '-'}</TableCell>
              <TableCell className={styles.tCell}>{row.email || '-'}</TableCell>
              <TableCell className={styles.tCell}>{getStatus(row.verificationStatus) || '-'}</TableCell>
              <TableCell className={styles.tCell}>{format(new Date(row.createdAt), 'dd.MM.yyyy') || '-'}</TableCell>
              <TableCell className={styles.tCell} align="right">
                <Link onClick={(e) => e.stopPropagation()} to={`/${routes.ADMIN}/${routes.BRANDS}/${row.brandId}`}>
                  {row.brandName || '-'}
                </Link>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <Box className={styles.emptyBox}>
              <SearchFilter />
              <Typography className={styles.emptyText}>{intl('EMPTY_STATE.noResults')}</Typography>
            </Box>
          </TableRow>
        )}
      </TableWithPagination>
    </Paper>
  );
};

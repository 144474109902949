const EURO = {
  label: 'Euro',
  value: '€',
}

const USD = {
  label: 'USD',
  value: '$',
}

const CURRENCY = { EURO, USD }

export { CURRENCY }

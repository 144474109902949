import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(3),
    flexDirection: 'column',
    minHeight: 'calc(100vh - 138px)',
    [theme.breakpoints.only('xs')]: {
      minHeight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(2),
    fontSize: 24,
    fontWeight: 500,
  },
  divider: {
    marginBottom: theme.spacing(5),
  },
  description: {
    marginBottom: theme.spacing(4),
  },
  imagesBox: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  saveBar: {
    marginLeft: 0,
    marginBottom: 0,
  },
}));

export { useStyles };

import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from 'theme/colors';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    padding: '11px 24px 12px 24px',
    borderBottom: `1px solid ${colors.BORDER2}`,
  },
  title: {
    letterSpacing: '0.03em',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  loader: {
    padding: theme.spacing(14, 0),
  },
  controls: {
    padding: theme.spacing(2, 0),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: `1px solid ${colors.BORDER2}`,
  },
  btnControl: {
    padding: 4,
  },
  circles: {
    margin: theme.spacing(0, 3),
    display: 'flex',
    alignItems: 'center',
  },
  circle: {
    width: 10,
    height: 10,
    backgroundColor: colors.BORDER2,
    borderRadius: '100%',
    '&:not(:last-child)': {
      marginRight: theme.spacing(1.5),
    },
  },
  selected: {
    backgroundColor: colors.PRIMARY_INPUT_HOVERED,
  },
}));

export { useStyles };

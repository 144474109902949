import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
// import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Button, Typography, Box, Divider, InputAdornment } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { Close } from '@material-ui/icons';
import * as yup from 'yup';
import { useFormik } from 'formik';

import { intl, getCurrencySymbol } from 'helpers';
import { CREATE_OFFER } from 'services/graphql/mutation/opportunity';
import { GET_TALENT_BY_ID_FROM_BRAND } from 'services/graphql/talent';
import { GET_OPPORTUNITY } from 'services/graphql/query/opportunity';
import { MARK_CONVERSATION_AS_READ } from 'services/graphql/mutation/messanger';
import FinalBudgetTooltip from 'components/FinalBudgetTooltip';

import { useStyles } from './styles';

const validationSchema = yup.object({
  budget: yup.number().optional(),
  tax: yup.string().optional(),
  additionalText: yup.string().optional(),
});

const taxes = [
  {
    value: 'included',
    label: 'SHARED.taxIncluded',
  },
  {
    value: 'excluded',
    label: 'SHARED.taxExcluded',
  },
];

const ProposalModal = ({
  open,
  handleClose,
  talentId,
  opportunityId,
  currency,
  budget,
  isTaxed = false,
  conversationId,
  applicationId,
  profitMargins,
}) => {
  const classes = useStyles();

  const initialValues = {
    budget,
    tax: isTaxed ? taxes[0].value : taxes[1].value,
    additionalText: '',
  };
  const {
    values,
    errors,
    handleChange,
    // setFieldValue,
    setValues,
  } = useFormik({
    validationSchema,
    initialValues,
  });

  const [createOffer, { loading }] = useMutation(CREATE_OFFER, {
    refetchQueries: [
      {
        query: GET_TALENT_BY_ID_FROM_BRAND,
        variables: {
          talentId,
        },
      },
      {
        query: GET_OPPORTUNITY,
        variables: {
          opportunityId,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const [markConversationAsRead] = useMutation(MARK_CONVERSATION_AS_READ, { variables: { conversationId } });

  useEffect(
    () => () => {
      setValues(initialValues);
    },
    [handleClose],
  );

  const onSubmit = async () => {
    try {
      markConversationAsRead();
      await createOffer({
        variables: {
          data: {
            talentId,
            opportunityId,
            conversationId,
            description: values.additionalText || null,
            // eslint-disable-next-line radix
            offerAmount: parseInt(typeof values.budget === 'string' ? values.budget.trim() : values.budget),
            isTaxed: values.tax === 'included',
          },
        },
      });

      handleClose();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('[Submitting Error]', error);
    }
  };

  // const handleSelectChange = (event, options, field) => {
  //   const selectedType = options.find((type) => type.value === event.target.value);
  //   setFieldValue(field, selectedType.value);
  // };

  return (
    <Dialog maxWidth="md" open={open} onClose={handleClose} classes={{ paper: classes.container }} aria-labelledby="proposal-dialog-title">
      <Box className={classes.titleBox}>
        <Typography variant="h5">{intl('PROPOSAL_MODAL.title')}</Typography>
        <Button onClick={handleClose}>
          <Close fontSize="small" className={classes.closeIcon} />
        </Button>
      </Box>
      <Divider className={classes.divider} />
      <DialogTitle id="proposal-dialog-description" disableTypography className={classes.description}>
        {intl('PROPOSAL_MODAL.description')}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container spacing={4}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TextField
              name="budget"
              onChange={handleChange}
              value={values.budget}
              error={!!errors.budget}
              InputProps={{
                startAdornment: <InputAdornment position="start">{getCurrencySymbol(currency)}</InputAdornment>,
              }}
              helperText={
                intl(errors.budget) ||
                (values.budget > 0 && (
                  <FinalBudgetTooltip simple isTaxed={values.tax === 'included'} budget={+values.budget} profitMargins={profitMargins} />
                ))
              }
              label={intl('PROPOSAL_MODAL.budget')}
              fullWidth
            />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            {values.tax !== 'included' && <p className={classes.taxText}>{intl('OPPORTUNITY.taxExcluded')}</p>}
            {/* <TextField
              select
              error={!!errors.tax}
              helperText={intl(errors.tax)}
              fullWidth
              name="tax"
              value={values.tax}
              onChange={(e) => handleSelectChange(e, taxes, 'tax')}
              label={intl('PROPOSAL_MODAL.tax')}
            >
              {taxes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {intl(option.label)}
                </MenuItem>
              ))}
            </TextField> */}
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              name="additionalText"
              multiline
              fullWidth
              onChange={handleChange}
              value={values.additionalText}
              error={!!errors.additionalText}
              helperText={errors.additionalText}
              label={intl('PROPOSAL_MODAL.additionText')}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.buttonContainer} disableSpacing>
        <Button onClick={handleClose} className={classes.btnCancel} disabled={loading}>
          {intl('PROPOSAL_MODAL.cancel')}
        </Button>
        <Button onClick={onSubmit} className={classes.btnSend} disabled={loading}>
          {intl('PROPOSAL_MODAL.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProposalModal;

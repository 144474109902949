import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  exportBtn: {
    width: 157,
    display: 'flex',
    justifyContent: 'space-between',
  },
  input: {
    marginRight: theme.spacing(3),
  },
}));

/* eslint-disable max-len */
import React from 'react'

const FilterIcon = ({ ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 18C3 18.55 3.45 19 4 19H9V17H4C3.45 17 3 17.45 3 18ZM3 6C3 6.55 3.45 7 4 7H13V5H4C3.45 5 3 5.45 3 6ZM13 20V19H20C20.55 19 21 18.55 21 18C21 17.45 20.55 17 20 17H13V16C13 15.45 12.55 15 12 15C11.45 15 11 15.45 11 16V20C11 20.55 11.45 21 12 21C12.55 21 13 20.55 13 20ZM7 10V11H4C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13H7V14C7 14.55 7.45 15 8 15C8.55 15 9 14.55 9 14V10C9 9.45 8.55 9 8 9C7.45 9 7 9.45 7 10ZM21 12C21 11.45 20.55 11 20 11H11V13H20C20.55 13 21 12.55 21 12ZM16 9C16.55 9 17 8.55 17 8V7H20C20.55 7 21 6.55 21 6C21 5.45 20.55 5 20 5H17V4C17 3.45 16.55 3 16 3C15.45 3 15 3.45 15 4V8C15 8.55 15.45 9 16 9Z"
      fill="#535E83"
    />
  </svg>
)

export default FilterIcon

import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  text: {
    fontSize: 12,
    '& ins': {
      textDecoration: 'none',
    },
  },
  bold: {
    '& ins': {
      fontWeight: 800,
    },
  },
}))

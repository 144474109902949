import { useState, useEffect, useContext } from 'react';
import { useSubscription, useQuery, useMutation } from '@apollo/client/react/hooks';

import { useAuth } from 'hooks/auth';
import { ON_USER_NOTIFICATION } from 'services/graphql/subscription/notification';
import { LIST_NOTIFICATIONS } from 'services/graphql/query/notification';
import { MARK_NOTIFICATION_AS_READ } from 'services/graphql/mutation/notification';
import { GET_CURRENT_BRAND } from 'services/graphql/brand';
import { GET_CURRENT_TALENT } from 'services/graphql/talent';

import type { UserNotifications } from 'types/notification';
import { UserTypeEnum, verificationStatusEnum } from 'types/common';
import { ApolloCache } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { routes } from '../../router/routesList';
import { AppContext } from '../app';

const useNotification = (ID?: String) => {
  const [id, setId] = useState<String>(ID);
  const { dispatch } = useContext(AppContext);
  const { getAssumedRole } = useAuth();

  const { push, location } = useHistory();
  const isTalent = getAssumedRole() === UserTypeEnum.talent;
  const isPublicPage = location.pathname.includes('/public-page');
  const publicPageLink = isTalent ? routes.TALENT_PUBLIC_PAGE : routes.BRAND_PUBLIC_PAGE;
  const onCloseSuccessModalFunction = () => {
    if (isPublicPage) {
      push('/');
    } else {
      push(publicPageLink);
    }
  };

  useEffect(() => {
    setId(ID);
  }, [ID]);

  const connect = (_id: string) => setId(_id);

  const { data: notificationList, loading, error } = useQuery<UserNotifications>(LIST_NOTIFICATIONS, {
    variables: {
      filter: {
        limit: 50,
      },
      onlyUnreadCount: false,
    },
  });

  const [markNotificationAsRead] = useMutation(MARK_NOTIFICATION_AS_READ);

  const handleReadNotifications = (ids: Array<string>) => {
    markNotificationAsRead({
      variables: {
        data: {
          notificationId: ids,
        },
      },
      update: (cache) => {
        const qr = cache.readQuery<UserNotifications>({
          query: LIST_NOTIFICATIONS,
          variables: {
            filter: {
              limit: 50,
            },
            onlyUnreadCount: false,
          },
        });
        const { items, meta } = qr.listUserNotifications;
        const buffIds = new Set(ids);

        const newItems = items.map((i) => {
          if (buffIds.has(i.id)) {
            return {
              ...i,
              readAt: new Date().getTime(),
            };
          }
          return i;
        });

        cache.writeQuery({
          query: LIST_NOTIFICATIONS,
          variables: {
            filter: {
              limit: 50,
            },
            onlyUnreadCount: false,
          },
          data: {
            listUserNotifications: {
              items: newItems,
              meta,
              __typename: 'UserNotifications',
            },
          },
        });
      },
    });
  };

  const readNotifications = () => {
    if (!notificationList?.listUserNotifications?.items?.length) return;

    const n = notificationList?.listUserNotifications?.items;
    const readIds = n.filter((i) => !i.readAt);

    if (readIds.length > 3) readIds.length = 4;

    handleReadNotifications(readIds.map((i) => i.id));
  };

  const updateUserVerification = (cache: ApolloCache<any>) => {
    if (getAssumedRole() === UserTypeEnum.brand || getAssumedRole() === UserTypeEnum.manager) {
      const brandQuery = cache.readQuery<any>({ query: GET_CURRENT_BRAND });
      cache.writeQuery({
        query: GET_CURRENT_BRAND,
        data: {
          getCurrentBrand: {
            ...brandQuery.getCurrentBrand,
            verificationStatus: verificationStatusEnum.verified,
          },
        },
      });
    } else {
      const talentQuery = cache.readQuery<any>({ query: GET_CURRENT_TALENT });
      cache.writeQuery({
        query: GET_CURRENT_TALENT,
        data: {
          getCurrentTalent: {
            ...talentQuery.getCurrentTalent,
            verificationStatus: verificationStatusEnum.verified,
          },
        },
      });
    }
  };

  useSubscription(ON_USER_NOTIFICATION, {
    variables: { userId: id },
    skip: !id,
    onSubscriptionData: ({ client: { cache }, subscriptionData }) => {
      const newItem = subscriptionData.data?.onUserNotification;

      const qr = cache.readQuery<UserNotifications>({
        query: LIST_NOTIFICATIONS,
        variables: {
          filter: {
            limit: 50,
          },
          onlyUnreadCount: false,
        },
      });
      const { items, meta } = qr.listUserNotifications;
      const newItems = [newItem.data, ...items];
      const newMeta = {
        total: meta.total + 1,
        __typename: 'Meta',
      };

      cache.writeQuery({
        query: LIST_NOTIFICATIONS,
        variables: {
          filter: {
            limit: 50,
          },
          onlyUnreadCount: false,
        },
        data: {
          listUserNotifications: {
            items: newItems,
            meta: newMeta,
            __typename: 'UserNotifications',
          },
        },
      });

      // eslint-disable-next-line no-underscore-dangle
      if (newItem.data.__typename === 'UserVerificationNotification') {
        dispatch({
          type: 'SUCCESS_MODAL',
          payload: {
            isOpen: true,
            title: 'PERSON_VERIFIED_MODAL.txt',
            onCloseFunction: onCloseSuccessModalFunction,
          },
        });
        updateUserVerification(cache);
      }
    },
  });

  return {
    connect,
    handleReadNotifications,
    readNotifications,
    notifications: notificationList?.listUserNotifications?.items || [],
    loading,
    notificationListError: error,
  };
};

export { useNotification };

export const dataURLtoBlob = (dataUrl: string): { blob: Blob; type: string } => {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return {
    blob: new Blob([u8arr], { type: mime }),
    type: mime,
  };
};

import { makeStyles, darken } from '@material-ui/core';
import { colors } from 'theme/colors';

const useStyles = makeStyles(() => ({
  item: {
    '&:hover': {
      backgroundColor: darken(colors.WHITE, 0.05),
    },
  },
  avatar: {
    width: 40,
    height: 40,
  },
  primaryText: {
    color: colors.TEXT,
    letterSpacing: ' -0.0241em',
  },
  secondaryText: {
    fontSize: 12,
    color: colors.SECONDARY_TEXT,
  },
}));

export { useStyles };

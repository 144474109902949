import React from 'react';
import { Box, Avatar, Typography, Tabs, Tab, Divider, Paper, TextField, Grid } from '@material-ui/core';
import { useParams, Route, Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';

import { UserTypeEnum } from 'types/common';
import { Option, VisibilityOption } from 'components/BadgeSelect/types';
import { intl } from 'helpers';
import { routes } from 'router/routesList';

import { Wrapper } from 'components/Wrapper';
import { Loader } from 'components/Loader';
import BadgeSelect from 'components/BadgeSelect';

import { GET_BRAND_MANAGER, UPDATE_VERIFICATION_STATUS, ADMIN_UPDATE_BRAND_PROFILE, UpdateVerificationStatusVars } from 'services/graphql/admin';
import { GET_BRAND_BY_ID } from 'services/graphql/brand';

import { useStyles } from './styles';
import { PROFILE_OPTIONS, VisibilityOptions } from '../../../constants';

const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env;

export const BrandManager = () => {
  const styles = useStyles({});
  const { id } = useParams();

  const { data, loading } = useQuery(GET_BRAND_MANAGER, {
    variables: {
      managerId: id,
    },
    skip: !id,
    fetchPolicy: 'no-cache',
  });

  const { data: brandData } = useQuery(GET_BRAND_BY_ID, {
    variables: {
      brandId: data?.getBrandManager?.brandId,
    },
    skip: !data?.getBrandManager?.brandId,
    fetchPolicy: 'no-cache',
  });

  const [updateProfileStatus] = useMutation<String, UpdateVerificationStatusVars>(UPDATE_VERIFICATION_STATUS);
  const [updateAdminBrandProfile] = useMutation(ADMIN_UPDATE_BRAND_PROFILE);

  const handleUserStatusChange = (option: Option) => {
    updateProfileStatus({
      variables: {
        status: option.value,
        userId: id,
        userType: UserTypeEnum.manager,
      },
    });
  };
  const handleUserVisibilityChange = (option: Option) => {
    updateAdminBrandProfile({
      variables: {
        brandId: brandData?.getBrand?.id,
        data: {
          companyName: brandData?.getBrand?.companyName,
          about: brandData?.getBrand?.about,
          cityId: brandData?.getBrand?.cityId,
          industryId: brandData?.getBrand?.industryId,
          image: brandData?.getBrand?.image,
          privateInfo: {
            IBAN: brandData?.getBrand?.privateInfo?.IBAN || '',
            VATnumber: brandData?.getBrand?.privateInfo?.VATnumber || '',
            bankAccount: brandData?.getBrand?.privateInfo?.bankAccount || '',
            postalCode: brandData?.getBrand?.privateInfo?.postalCode || '',
            street: brandData?.getBrand?.privateInfo?.street || '',
          },
          isPrivate: option.value,
        },
      },
    });
  };

  const verificationStatus = data?.getBrandManager?.verificationStatus ? data.getBrandManager?.verificationStatus : PROFILE_OPTIONS[1].value;
  const visibilityStatus = brandData?.getBrand?.isPrivate;

  return (
    <Box className={styles.container}>
      <Grid container spacing={5} className={styles.headerGrid}>
        <Grid item className={styles.head}>
          <Avatar className={styles.avatar} src={REACT_APP_AWSS3_URL_IMAGE_BEG + data?.getBrandManager?.image}>
            {data?.getBrandManager?.firstName?.[0]} {data?.getBrandManager?.lastName?.[0]}
          </Avatar>
          <Box>
            <Typography className={styles.name}>
              {data?.getBrandManager?.firstName} {data?.getBrandManager?.lastName}
            </Typography>
            <Typography className={styles.id}>ID: {id}</Typography>
          </Box>
        </Grid>
        <Grid item className={styles.head}>
          <Box>
            <Typography className={styles.brand}>{intl('SHARED.brand')}</Typography>
            <Link to={`/${routes.ADMIN}/${routes.BRANDS}/${data?.getBrandManager?.brandId}`} className={styles.brandLink}>
              <Typography>{data?.getBrandManager?.brandName || '-'}</Typography>
            </Link>
          </Box>
        </Grid>

        <Grid item className={styles.indicatorsGrid}>
          <Box className={styles.badges}>
            {data?.getBrandManager && (
              <BadgeSelect
                options={PROFILE_OPTIONS}
                onChange={handleUserStatusChange}
                selected={PROFILE_OPTIONS.find((o: Option) => o.value === verificationStatus)}
                className={styles.badgeSelect}
              />
            )}
            {visibilityStatus !== undefined && (
              <BadgeSelect
                options={VisibilityOptions}
                onChange={handleUserVisibilityChange}
                selected={VisibilityOptions.find((o: VisibilityOption) => o.value === visibilityStatus)}
                className={styles.badgeSelect}
              />
            )}
          </Box>
        </Grid>
      </Grid>

      <Tabs indicatorColor="primary" value="private">
        <Tab value="private" label={intl('ADMIN.BRAND_MANAGER.private')} />
      </Tabs>
      <Divider />

      <Wrapper className={styles.wrapper}>
        <Route exact path={[`/${routes.ADMIN}/${routes.BRAND_MANAGERS}/:id/private`, `/${routes.ADMIN}/${routes.BRAND_MANAGERS}/:id`]}>
          {loading ? (
            <Loader />
          ) : (
            <Paper className={styles.privateInfoForm}>
              <Grid container component="div" spacing={2}>
                <Grid item sm={6}>
                  <TextField disabled value={data?.getBrandManager?.email || ''} fullWidth label={intl('SHARED.email')} />
                </Grid>
                <Grid item sm={6}>
                  <TextField disabled value={data?.getBrandManager?.phone || ''} fullWidth label={intl('SHARED.phone')} />
                </Grid>
              </Grid>
            </Paper>
          )}
        </Route>
      </Wrapper>
    </Box>
  );
};

import React from 'react'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { Button, Typography, Box, Divider } from '@material-ui/core'
import { Close } from '@material-ui/icons'

import * as yup from 'yup'
import { useFormik } from 'formik'
import { intl } from '../../../../../helpers'
import { useStyles } from './styles'

const validationSchema = yup.object({
  coverLetter: yup.string().optional(),
})

const NoteModal = ({ notePopup, toggleNotePopup, handleApply, opportunityId }) => {
  const classes = useStyles()
  const { values, errors, handleChange, setFieldError, setFieldValue } = useFormik({
    validationSchema,
    initialValues: {
      coverLetter: '',
    },
  })

  const placeholderText = intl('SHARED.talentHasApplied')

  const onSubmit = async () => {
    const messageText = values.coverLetter || placeholderText
    try {
      await handleApply(opportunityId, messageText)
      setFieldValue('coverLetter', '')
    } catch (error) {
      setFieldError('coverLetter', error)
    }
    toggleNotePopup()
  }

  return (
    <Dialog
      maxWidth="md"
      open={notePopup}
      onClose={toggleNotePopup}
      classes={{ paper: classes.container }}
      aria-labelledby="note-dialog-title"
    >
      <Box className={classes.titleBox}>
        <Typography variant="h5">{intl('NOTE.title')}</Typography>
        <Button onClick={toggleNotePopup}>
          <Close fontSize="small" className={classes.closeIcon} />
        </Button>
      </Box>
      <Divider className={classes.divider} />
      <DialogContent className={classes.content}>
        <TextField
          name="coverLetter"
          multiline
          onChange={handleChange}
          value={values.coverLetter}
          error={!!errors.coverLetter}
          helperText={errors.coverLetter}
          label={intl('NOTE.coverLetter')}
          fullWidth
        />
      </DialogContent>
      <DialogActions className={classes.buttonContainer} disableSpacing>
        <Button onClick={onSubmit} className={classes.btnSend}>
          {intl('NOTE.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NoteModal

export const openThCellsDesktop = [
  { name: 'OPPORTUNITIES.opportunityName' },
  { name: 'OPPORTUNITIES.applicants' },
  { name: 'OPPORTUNITIES.country' },
  { name: 'OPPORTUNITIES.city' },
  { name: 'OPPORTUNITIES.startDate' },
  { name: 'OPPORTUNITIES.endDate' },
  { name: 'OPPORTUNITIES.budget', align: 'right' },
]

export const openThCellsDesktopTalent = [
  { name: 'OPPORTUNITIES.opportunityName' },
  { name: 'OPPORTUNITIES.client' },
  { name: 'OPPORTUNITIES.country' },
  { name: 'OPPORTUNITIES.city' },
  { name: 'OPPORTUNITIES.startDate' },
  { name: 'OPPORTUNITIES.endDate' },
  { name: 'OPPORTUNITIES.budget', align: 'right' },
]

export const openThCellsTablet = [
  { name: 'OPPORTUNITIES.opportunityName' },
  { name: 'OPPORTUNITIES.applicants' },
  { name: 'OPPORTUNITIES.startDate' },
  { name: 'OPPORTUNITIES.endDate' },
  { name: 'OPPORTUNITIES.budget', align: 'right' },
]

export const openThCellsTabletTalent = [
  { name: 'OPPORTUNITIES.opportunityName' },
  { name: 'OPPORTUNITIES.client' },
  { name: 'OPPORTUNITIES.startDate' },
  { name: 'OPPORTUNITIES.endDate' },
  { name: 'OPPORTUNITIES.budget', align: 'right' },
]

export const confirmedThCellsDesktop = [
  { name: 'OPPORTUNITIES.opportunityName' },
  { name: 'OPPORTUNITIES.talent' },
  { name: 'OPPORTUNITIES.country' },
  { name: 'OPPORTUNITIES.city' },
  { name: 'OPPORTUNITIES.startDate' },
  { name: 'OPPORTUNITIES.endDate' },
  { name: 'OPPORTUNITIES.budget', align: 'right' },
]

export const confirmedThCellsDesktopTalent = [
  { name: 'OPPORTUNITIES.opportunityName' },
  { name: 'OPPORTUNITIES.client' },
  { name: 'OPPORTUNITIES.country' },
  { name: 'OPPORTUNITIES.city' },
  { name: 'OPPORTUNITIES.startDate' },
  { name: 'OPPORTUNITIES.endDate' },
  { name: 'OPPORTUNITIES.budget', align: 'right' },
]

export const confirmedThCellsTablet = [
  { name: 'OPPORTUNITIES.opportunityName' },
  { name: 'OPPORTUNITIES.talent' },
  { name: 'OPPORTUNITIES.startDate' },
  { name: 'OPPORTUNITIES.endDate' },
  { name: 'OPPORTUNITIES.budget', align: 'right' },
]

export const confirmedThCellsTabletTalent = [
  { name: 'OPPORTUNITIES.opportunityName' },
  { name: 'OPPORTUNITIES.client' },
  { name: 'OPPORTUNITIES.startDate' },
  { name: 'OPPORTUNITIES.endDate' },
  { name: 'OPPORTUNITIES.budget', align: 'right' },
]

export const completeThCellsDesktop = [
  { name: 'OPPORTUNITIES.opportunityName' },
  { name: 'OPPORTUNITIES.talent' },
  { name: '' },
  { name: 'OPPORTUNITIES.startDate' },
  { name: 'OPPORTUNITIES.endDate' },
  { name: 'OPPORTUNITIES.budget' },
  { name: 'OPPORTUNITIES.status', align: 'right' },
]

export const completeThCellsDesktopTalent = [
  { name: 'OPPORTUNITIES.opportunityName' },
  { name: 'OPPORTUNITIES.client' },
  { name: '' },
  { name: 'OPPORTUNITIES.startDate' },
  { name: 'OPPORTUNITIES.endDate' },
  { name: 'OPPORTUNITIES.budget' },
  { name: 'OPPORTUNITIES.status', align: 'right' },
]

export const completeThCellsTablet = [
  { name: 'OPPORTUNITIES.opportunityName' },
  { name: 'OPPORTUNITIES.talent' },
  { name: '' },
  { name: 'OPPORTUNITIES.budget' },
  { name: 'OPPORTUNITIES.empty', align: 'right' },
]

export const completeThCellsTabletTalent = [
  { name: 'OPPORTUNITIES.opportunityName' },
  { name: 'OPPORTUNITIES.client' },
  { name: '' },
  { name: 'OPPORTUNITIES.budget' },
  { name: 'OPPORTUNITIES.empty', align: 'right' },
]

import { makeStyles } from '@material-ui/core'
import { colors } from '../../theme/colors'

export const useStyles = makeStyles((theme) => ({
  publicInfo: {
    marginTop: 24,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  publicInfoFormAndNav: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
    marginLeft: 24,
    '@media (max-width: 768px)': {
      marginLeft: 0,
    },
  },
  publicInfoForm: {
    padding: 30,
    minHeight: 512,
    paddingBottom: 24,
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  publicInfoBlock: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '&:nth-child(2)': {
      marginTop: 18,
      marginBottom: 18,
    },
  },
  input: {
    marginBottom: 28,
    maxWidth: 312,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  socialsBlock: {
    padding: 32,
    marginTop: 16,
    marginBottom: 16,
  },
  socialItem: {
    paddingBottom: 16,
    paddingTop: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:first-child': {
      paddingTop: 0,
    },
    '&:last-child': {
      paddingBottom: 0,
    },
    '&:nth-child(2)': {
      borderTop: `1px solid ${colors.BORDER}`,
      borderBottom: `1px solid ${colors.BORDER}`,
    },

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
    },
  },
  connectBlock: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  socialText: {
    marginRight: 'auto',
    marginLeft: 16,
    fontSize: 12,
  },
  about: {
    padding: 32,
    paddingBottom: 44,
    marginBottom: 16,
  },
  video: {
    padding: 32,
    marginBottom: 16,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  videoItem: {
    maxWidth: 312,
    width: 312,
    height: 186,
    borderRadius: 4,
    marginBottom: 16,

    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },

  mainInputBlocks: {
    justifyContent: 'flex-end',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between',
    },
  },
  buttons: {
    width: 152,
    marginRight: theme.spacing(2),
    '&:last-child': {
      marginRight: 0,
    },
  },
  popupInput: {
    marginTop: theme.spacing(3),
  },
  deleteVideoBtn: {
    position: 'absolute',
    top: theme.spacing(1.5),
    right: theme.spacing(1.5),
    maxWidth: 32,
    height: 32,
    '& svg': {
      fill: colors.PRIMARY_INPUT_HOVERED,
    },
  },
}))

import { gql } from '@apollo/client';

const ON_USER_NOTIFICATION = gql`
  subscription onUserNotification($userId: ID!) {
    onUserNotification(userId: $userId) {
      userId
      data {
        ... on OfferNotification {
          id
          userId
          eventType
          createdAt
          offer {
            confirmedAt
            declinedAt
            description
            id
            isTaxed
            offerAmount
            opportunityId
            talentId
            talentSalary
          }
          message
          image
          readAt
        }
        ... on MessageNotification {
          id
          userId
          eventType
          createdAt
          message
          image
          readAt
          opportunityId
        }
        ... on SystemNotification {
          id
          eventType
          createdAt
          message
          readAt
        }
        ... on BrandFeedbackNotification {
          id
          userId
          eventType
          createdAt
          brand {
            id
            companyName
          }
          opportunity {
            id
            applicationId
            isRated
            isApplied
            name
          }
          message
          image
          readAt
        }
        ... on TalentFeedbackNotification {
          id
          userId
          eventType
          createdAt
          talent {
            id
            firstName
            lastName
          }
          opportunity {
            id
            applicationId
            isRated
            isApplied
            name
          }
          message
          image
          readAt
        }
        ... on UserVerificationNotification {
          id
          userId
          eventType
          createdAt
          message
          image
          verificationStatus
          readAt
        }
        ... on OpportunityNotification {
          id
          userId
          eventType
          createdAt
          actionCode
          opportunity {
            id
            applicationId
            isRated
            isApplied
            name
            brand {
              id
              name
              image
            }
            status {
              name
            }
            redemption {
              budget
              isTaxed
              talentSalary
              currency {
                id
                code
              }
            }
            finalCondition {
              description
              offerAmount
              talentSalary
              isTaxed
              invoiceNumber
            }
          }
          message
          image
          readAt
        }
      }
    }
  }
`;
export { ON_USER_NOTIFICATION };

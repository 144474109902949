import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../theme/colors'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    paddingLeft: theme.spacing(2),
  },
  avatar: {
    marginLeft: theme.spacing(-2),
    backgroundColor: colors.PRIMARY,
  },
  emptyText: {
    color: '#949494',
  },
  smallPadding: {
    paddingLeft: 0,
  },
}))

export { useStyles }

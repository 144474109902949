import React, { FC, useState } from 'react';
import { Select, MenuItem, FormControl } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from './styles';
import type { Props, Option } from './types';

const BadgeSelect: FC<Props> = ({ selected, onChange, options, className }) => {
  const classes = useStyles();
  const [val, setVal] = useState<Option>(selected);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedItem = options.find((o: Option) => o.value === event.target.value);
    setVal(selectedItem);
    onChange(selectedItem);
  };

  return (
    <FormControl variant="outlined">
      <Select
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        value={val.value}
        classes={{ icon: classes.icon, outlined: classes.input, select: classes.select }}
        className={clsx(
          classes.container,
          {
            [classes.greenBackground]: val.color === 'green',
            [classes.grayBackground]: val.color === 'gray',
            [classes.redBackground]: val.color === 'red',
          },
          className,
        )}
        onChange={handleChange}
      >
        {options.map((o: Option) => (
          <MenuItem value={o.value} key={o.label}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default BadgeSelect;

import React from 'react'
import clsx from 'clsx'
import YouTube from 'react-youtube'
import { Paper, Typography, Box } from '@material-ui/core'

import { useStyles } from './styles'
import { getYoutubeId, intl } from '../../../../../helpers'

const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env

export const Videos = ({ className = '', talent }) => {
  const styles = useStyles({})
  return talent?.videos?.length ? (
    <Paper className={clsx(styles.paper, styles.publicPageServices, className)}>
      <Typography className={styles.sectionHeading} component="h4">
        {intl('TALENT.PUBLIC_PAGE.VIDEOS.videos')}
      </Typography>
      <Box className={styles.video}>
        {talent?.videos?.map((v) => (v.type === 'youtube' ? (
          <YouTube className={styles.videoItem} key={v.link} videoId={getYoutubeId(v.link)} />
        ) : (
          <video key={v.link} className={styles.videoItem} controls>
            <source src={REACT_APP_AWSS3_URL_IMAGE_BEG + v.link} type="video/mp4" />
          </video>
        )),
        )}
      </Box>
    </Paper>
  ) : null
}

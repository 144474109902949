import { MouseEvent } from 'react';
import { Opportunity } from 'types/opportunity';

export interface Props {
  opportunity: Opportunity;
  open: boolean;
  handleClose: () => void | MouseEvent<HTMLButtonElement, MouseEvent>;
}

export enum ClosedBy {
  done = 'done',
  canceled = 'canceled',
}

import React, { FC } from 'react';
import { Typography, Box, Button } from '@material-ui/core';
import clsx from 'clsx';
import intlUniversal from 'react-intl-universal';

import { getFromTodayDifference } from 'helpers/common';

import { Modal } from 'components/Modal';
import { QuestionMark as QuestionMarkIcon } from 'assets/svg-js';
import { STATUSES, TERMS_AND_CONDITIONS_URL, ADMINISTRATOR_PHONE_NUMBER } from 'constants/index';
import { intl } from 'helpers';

import { useStyles } from './styles';
import type { Props, ActionProps } from './types';

const ModalActions: FC<ActionProps> = ({ handleClose, handleConfirm, loading }) => {
  const classes = useStyles();
  return (
    <>
      <Button className={classes.btnNo} disabled={loading} onClick={handleClose}>
        {intl('SHARED.no')}
      </Button>
      <Button className={classes.btnYes} disabled={loading} onClick={handleConfirm}>
        {intl('SHARED.yes')}
      </Button>
    </>
  );
};

const CancelModal: FC<Props> = ({ opportunity, error, isOpen, loading, handleClose, handleConfirm, isTalent, isBrand, hasConfirmedTalentStripe }) => {
  const classes = useStyles();
  const text = isBrand ? intl('OPPORTUNITY.cancelModalOpportunityText') : intl('OPPORTUNITY.cancelModalApplicationText');
  const isOpportunityConfirmed = opportunity.status.name === STATUSES.CONFIRMED.value;
  const showCancellationPolicy = isBrand && isOpportunityConfirmed;
  const daysBeforeStart = getFromTodayDifference(opportunity.startDate, 'hours');

  const renderAdminsInfoForTalent = () => {
    if (isTalent && isOpportunityConfirmed && daysBeforeStart < 48) {
      return (
        <Typography className={classes.cancelTalentMessage}>
          {intlUniversal.getHTML('SHARED.cancelTalentMessage', {
            link: TERMS_AND_CONDITIONS_URL,
            phone: ADMINISTRATOR_PHONE_NUMBER,
          })}
        </Typography>
      );
    } else {
      return null;
    }
  };
  // Temporary solution for canceling confirmed opportunity by brand
  // if (isBrand && isOpportunityConfirmed && !hasConfirmedTalentStripe) {
  //   return (
  //     <Modal
  //       className={classes.modalBox}
  //       classNameTitle={classes.modalTitleBox}
  //       isOpen={isOpen}
  //       handleClose={handleClose}
  //       dialogActionsComponent={<Button onClick={handleClose} fullWidth color="primary" variant="contained">OK</Button>}
  //       disabledBorder
  //       title={null}
  //     >
  //       <Box className={classes.modalContentBox}>
  //         <Box className={classes.modalIconBox}>
  //           <QuestionMarkIcon />
  //         </Box>
  //         <Typography className={classes.modalText}>
  //           {intlUniversal.getHTML('SHARED.cancelBrandMessage', { phone: ADMINISTRATOR_PHONE_NUMBER })}
  //         </Typography>
  //       </Box>
  //     </Modal>
  //   );
  // }

  return (
    <Modal
      className={classes.modalBox}
      classNameTitle={classes.modalTitleBox}
      isOpen={isOpen}
      handleClose={handleClose}
      dialogActionsComponent={<ModalActions handleClose={handleClose} handleConfirm={handleConfirm} loading={loading} />}
      disabledBorder
      title={null}
    >
      <Box className={classes.modalContentBox}>
        <Box className={classes.modalIconBox}>
          <QuestionMarkIcon />
        </Box>
        <Typography
          className={clsx(classes.modalText, {
            [classes.modalTextMargin]: !showCancellationPolicy,
          })}
        >
          {text}
        </Typography>
        {renderAdminsInfoForTalent()}

        {/* Temporary solution for canceling confirmed opportunity by brand */}
        {showCancellationPolicy && (
          <Typography className={classes.cancellationText}>
            {intl('SHARED.see')}{' '}
            <a href={TERMS_AND_CONDITIONS_URL} target="_blank" rel="noopener noreferrer">
              {intl('SHARED.cancellationPolicy')}
            </a>
            .
          </Typography>
        )}

        <Typography variant="caption" className={classes.errorText}>
          {error}
        </Typography>
      </Box>
    </Modal>
  );
};

export default CancelModal;

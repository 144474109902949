import { gql } from '@apollo/client'

const LIST_INDUSTRIES = gql`
  query {
    listIndustries {
      id
      name
    }
  }
`

export { LIST_INDUSTRIES }

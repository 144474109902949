import { makeStyles } from '@material-ui/core'
import { constants } from '../../theme/constants'
import { colors } from '../../theme/colors'

export const useTitleStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  borderBottom: {
    borderBottom: `1px solid ${colors.BORDER2}`,
  },
  closeButton: {
    color: colors.PRIMARY_INPUT_HOVERED,
  },
}))

export const useStyles = makeStyles((theme) => ({
  root: {
    width: constants.MODAL_WIDTH,
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
    },
  },
  paddings: {
    padding: theme.spacing(2.5),
  },
}))

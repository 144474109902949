import React from 'react';
import { Button, Typography } from '@material-ui/core';

import { Modal } from '../../Modal';
import { QuestionMark } from '../../../assets/svg-js';

import { intl } from '../../../helpers';

import { useStyles } from './styles';

export const RemoveSocialModal = ({ onRemove, isOpen, handleClose, loading, provider }) => {
  const classes = useStyles({});

  const Actions = (
    <>
      <Button variant="outlined" disabled={loading} onClick={handleClose} className={classes.buttons}>
        {intl('SHARED.cancel')}
      </Button>
      <Button onClick={onRemove} disabled={loading} variant="contained" className={classes.buttons} color="primary">
        {intl('SHARED.remove')}
      </Button>
    </>
  );

  return (
    <Modal
      className={classes.container}
      contentClassName={classes.content}
      classNameTitle={classes.title}
      isOpen={isOpen}
      handleClose={handleClose}
      dialogActionsComponent={Actions}
    >
      <QuestionMark />
      <Typography className={classes.text}>{intl('REMOVE_SOCIAL_MODAL.areYouSure', { provider })}</Typography>
    </Modal>
  );
};

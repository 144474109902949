/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { Box, TextField, InputAdornment, Hidden, IconButton, Tabs, Tab, useMediaQuery, Button, Typography } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import clsx from 'clsx';
import { useQuery } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';

import { intl } from 'helpers';
import { removeEmpty } from 'helpers/common';
import { FilterIcon, FilterIconActive, DesertImage, SearchFilter } from 'assets/svg-js';
import { GET_BRAND_TALENTS } from 'services/graphql/brand';

import { Wrapper } from 'components/Wrapper';
import { Loader } from 'components/Loader';
import { TalentListCard } from 'components/TalentListCard';
import { TalentFilters } from 'components/TalentFilters';

import { useStyles } from './styles';

const LIMIT = 10;

const TabPanel = ({ children, value, name, ...other }) => (
  <Box role="tabpanel" hidden={value !== name} id={`simple-tabpanel-${name}`} aria-labelledby={`simple-tab-${name}`} {...other}>
    {value === name && <Box className={other.className}>{children}</Box>}
  </Box>
);

export const BrandTalents = () => {
  const isSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [search, setSearch] = useState('');
  const [mobileFiltersOpened, setMobileFiltersOpened] = useState(false);
  const [filters, setFilters] = useState({ tab: 'all' });
  const classes = useStyles();

  const toggleMobileFilters = () => setMobileFiltersOpened((isOpened) => !isOpened);

  const setA = (value) => {
    setSearch(value);
  };

  const handleChange = useCallback(
    debounce((value) => {
      setA(value);
    }, 1000),
    [],
  );

  const handleTabChange = (_, newValue) => {
    setFilters((oldFilters) => ({
      ...oldFilters,
      tab: newValue,
    }));
  };

  const queryOptions = {
    variables: {
      pagination: {
        offset: 0,
        limit: LIMIT,
      },
      filter: {
        options: {
          cityId: filters?.city?.map((city) => city.id),
          countryId: filters?.country?.map((country) => country.id),
          sportId: filters?.sport?.map((sport) => sport.id),
          tags: filters?.tags,
          gender: filters?.gender?.id,
          instagramFollowers: filters?.instagramFollowers,
          twitterFollowers: filters?.twitterFollowers,
          facebookFollowers: filters?.facebookFollowers,
          geo: filters?.longitude
            ? {
                geo: {
                  lon: filters?.longitude,
                  lat: filters?.latitude,
                },
              }
            : undefined,
          favourites: filters.tab === 'favourite',
          age: filters?.age,
        },
        sort: {
          field: filters?.sort?.id,
          order: filters?.sort?.id ? (filters?.sort?.id === 'geo' ? 'asc' : 'desc') : undefined,
        },
      },
      search,
    },
    // fetchPolicy: 'cache-and-network',
  };

  const { data, loading, fetchMore, refetch } = useQuery(GET_BRAND_TALENTS, queryOptions);

  useEffect(() => {
    refetch(queryOptions);
  }, [filters.tab]);

  const onShowMore = () => {
    const newQueryOptions = cloneDeep(queryOptions);
    newQueryOptions.variables.pagination.offset = data?.brandListTalents?.items?.length;
    fetchMore({
      ...newQueryOptions,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult || fetchMoreResult?.brandListTalents?.meta?.total < LIMIT) {
          return prev;
        }
        const items = [...prev.brandListTalents.items, ...fetchMoreResult?.brandListTalents?.items];
        return {
          ...prev,
          brandListTalents: {
            ...prev.brandListTalents,
            items,
          },
        };
      },
    });
  };

  const onApply = (values, applyFromBtn) => {
    const valuesToSet = removeEmpty({ ...values });

    setFilters({
      tab: filters.tab,
      ...valuesToSet,
    });

    if (applyFromBtn) {
      toggleMobileFilters();
    }
  };
  const onApplyDesktop = (values) => {
    const valuesToSet = removeEmpty({ ...values });

    setFilters({
      tab: filters.tab,
      ...valuesToSet,
    });
  };

  const checkFiltersForNonEmpty = () => {
    const { tab, ...restFilters } = filters;
    return restFilters && Object.values(restFilters).length && Object.values(restFilters).some((val) => (Array.isArray(val) ? val.length : val));
  };

  const a11yProps = (index) => ({
    id: `talents-tab-${index}`,
    'aria-controls': `talents-tab-panel-${index}`,
  });

  const noResults = !!(((filters && Object.keys(filters).length > 1) || search.length) && !data?.brandListTalents?.items.length);

  const favouritesOnly = data?.brandListTalents?.items.filter((talent) => talent.isFavourite);

  return (
    <Wrapper>
      <Box className={classes.container}>
        <Hidden smDown>
          <TalentFilters availableFilters={data?.brandListTalents?.filters} handleApply={onApplyDesktop} filters={filters} />
        </Hidden>
        <Box
          className={clsx(classes.rightBlock, {
            [classes.fullHeight]: filters.tab === 'favourite',
          })}
        >
          <Box className={clsx(classes.inputAndTabs, mobileFiltersOpened && isSm && classes.zeroMargin)}>
            {!mobileFiltersOpened && (
              <TextField
                className={classes.search}
                onChange={({ target: { value } }) => handleChange(value)}
                placeholder={intl('TALENT_OPPORTUNITIES.search')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            <Tabs
              value={filters.tab}
              className={clsx(classes.tabs, mobileFiltersOpened && classes.zeroMargin)}
              indicatorColor="primary"
              onChange={handleTabChange}
            >
              <Tab value="all" className={clsx(classes.tabItem, classes.fullHeight)} label={intl('BRAND.TALENTS_LIST.all')} {...a11yProps(0)} />
              <Tab
                value="favourite"
                className={clsx(classes.tabItem, {
                  [classes.fullHeight]: filters.tab === 'favourite',
                })}
                label={intl('BRAND.TALENTS_LIST.favorite')}
                {...a11yProps(1)}
              />
            </Tabs>
            {!mobileFiltersOpened && (
              <Hidden mdUp>
                <IconButton className={classes.filterIcon} onClick={toggleMobileFilters} size="small">
                  {checkFiltersForNonEmpty() ? <FilterIconActive /> : <FilterIcon />}
                </IconButton>
              </Hidden>
            )}
          </Box>
          <Hidden mdUp>
            {mobileFiltersOpened && (
              <TalentFilters
                handleApply={onApply}
                handleClose={toggleMobileFilters}
                filters={filters}
                availableFilters={data?.brandListTalents?.filters}
                className={classes.mobileFilters}
              />
            )}
          </Hidden>
          {loading ? (
            <Loader className={classes.fullHeight} />
          ) : (
            <>
              <TabPanel value={filters.tab} name="all" className={clsx({ [classes.fullHeight]: noResults })}>
                <Box className={clsx({ [classes.fullHeight]: noResults })}>
                  {noResults && (
                    <Box className={classes.emptySearchBox}>
                      <SearchFilter />
                      <Typography className={classes.emptySearchText}>{intl('EMPTY_STATE.noDataByFilters')}</Typography>
                    </Box>
                  )}
                  {data?.brandListTalents?.items.map((talent) => (
                    <TalentListCard
                      id={talent.id}
                      talent={talent}
                      queryOptions={queryOptions}
                      key={talent.id}
                    />
                  ))}
                </Box>
                {data?.brandListTalents?.meta?.total > data?.brandListTalents?.items?.length && (
                  <Box className={classes.showMoreContainer}>
                    <Button className={classes.buttonShowMore} variant="outlined" onClick={onShowMore} tabIndex="" component="div" role="none">
                      {intl('SHARED.showMore')}
                    </Button>
                  </Box>
                )}
              </TabPanel>

              <TabPanel
                value={filters.tab}
                name="favourite"
                className={clsx({
                  [classes.fullHeight]: filters.tab === 'favourite',
                })}
              >
                {data?.brandListTalents?.items?.length ? (
                  <Box>
                    {favouritesOnly.map((talent) => (
                      <TalentListCard
                        id={talent.id}
                        talent={talent}
                        queryOptions={queryOptions}
                        key={talent.id}
                      />
                    ))}
                  </Box>
                ) : noResults ? (
                  <Box className={classes.emptySearchBox}>
                    <SearchFilter />
                    <Typography className={classes.emptySearchText}>{intl('EMPTY_STATE.noDataByFilters')}</Typography>
                  </Box>
                ) : (
                  <Box className={classes.emptyBox}>
                    <DesertImage className={classes.emptyIcon} />
                    <Typography className={classes.emptyText}>{intl('EMPTY_STATE.noFavoriteTalents')}</Typography>
                  </Box>
                )}
                {data?.brandListTalents?.meta?.total > data?.brandListTalents?.items?.length && (
                  <Box className={classes.showMoreContainer}>
                    <Button className={classes.buttonShowMore} variant="outlined" onClick={onShowMore} tabIndex="" component="div" role="none">
                      {intl('SHARED.showMore')}
                    </Button>
                  </Box>
                )}
              </TabPanel>
            </>
          )}
        </Box>
      </Box>
    </Wrapper>
  );
};

import React, { FC, useContext } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { useMutation } from '@apollo/client';

import { intl } from 'helpers';
import { QuestionMark } from 'assets/svg-js/index';
import { DELETE_POST } from 'services/graphql/mutation/feed';
import { AppContext } from 'hooks/app';

import { Modal } from 'components/Modal';

import { useStyles } from './styles';

const DeletePostModal: FC = () => {
  const styles = useStyles();
  const {
    state: {
      deletePostModal: { postId, open, onSuccess },
    },
    dispatch,
  } = useContext(AppContext);

  const handleClose = () => {
    dispatch({ type: 'DELETE_POST_MODAL', payload: { open: false, postId: null, onClose: () => {}, onSuccess: () => {} } });
  };

  const [deletePost, { loading }] = useMutation(DELETE_POST, {
    variables: { id: postId },
    onCompleted: () => {
      handleClose();
    },
    update: () => {
      try {
        onSuccess();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
  });

  const Actions = () => (
    <>
      <Button onClick={handleClose} fullWidth disabled={loading} variant="outlined">
        {intl('SHARED.no')}
      </Button>
      <Button onClick={deletePost as any} fullWidth disabled={loading} variant="contained" color="primary">
        {intl('SHARED.yes')}
      </Button>
    </>
  );

  return (
    <Modal isOpen={open} handleClose={handleClose} title={null} disabledBorder className={styles.modal} dialogActionsComponent={<Actions />}>
      <Box className={styles.container}>
        <Box className={styles.iconBox}>
          <QuestionMark />
        </Box>
        <Typography className={styles.txt}>{intl('DELETE_POST_MODAL.text')}</Typography>
      </Box>
    </Modal>
  );
};

export { DeletePostModal };

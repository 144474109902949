const DETAILS = {
  label: 'OPPORTUNITY.details',
  value: 0,
  route: 'details',
}

const APPLICANTS = {
  label: 'OPPORTUNITY.applicants',
  value: 1,
  route: 'applicants',
}

const DEAL = {
  label: 'OPPORTUNITY.deal',
  value: 2,
  route: 'deal',
}

const TABS = {
  DETAILS,
  APPLICANTS,
  DEAL,
}

export { TABS }

import React from 'react';

const VerifiedPersonIcon = ({ className = '' }) => (
  <svg width="222" height="222" viewBox="0 0 222 222" className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-inside-1" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M111 0C49.6964 0 0 49.6964 0 111C0 172.304 49.6964 222 111 222C127.665 222 143.472 218.327 157.657 211.748C154.684 206.156 153 199.775 153 193C153 170.909 170.909 153 193 153C199.775 153 206.156 154.684 211.748 157.657C218.327 143.472 222 127.665 222 111C222 49.6964 172.304 0 111 0ZM206.627 167.395C202.565 165.228 197.926 164 193 164C176.984 164 164 176.984 164 193C164 197.926 165.228 202.565 167.395 206.627C183.551 197.078 197.078 183.551 206.627 167.395Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M111 0C49.6964 0 0 49.6964 0 111C0 172.304 49.6964 222 111 222C127.665 222 143.472 218.327 157.657 211.748C154.684 206.156 153 199.775 153 193C153 170.909 170.909 153 193 153C199.775 153 206.156 154.684 211.748 157.657C218.327 143.472 222 127.665 222 111C222 49.6964 172.304 0 111 0ZM206.627 167.395C202.565 165.228 197.926 164 193 164C176.984 164 164 176.984 164 193C164 197.926 165.228 202.565 167.395 206.627C183.551 197.078 197.078 183.551 206.627 167.395Z"
      fill="#F6F6F6"
    />
    <path
      d="M157.657 211.748L159.34 215.376L163.171 213.599L161.189 209.87L157.657 211.748ZM211.748 157.657L209.87 161.189L213.599 163.171L215.376 159.34L211.748 157.657ZM206.627 167.395L210.071 169.43L212.197 165.832L208.509 163.865L206.627 167.395ZM167.395 206.627L163.865 208.509L165.832 212.197L169.43 210.071L167.395 206.627ZM4 111C4 51.9055 51.9055 4 111 4V-4C47.4873 -4 -4 47.4873 -4 111H4ZM111 218C51.9055 218 4 170.094 4 111H-4C-4 174.513 47.4873 226 111 226V218ZM155.973 208.119C142.306 214.459 127.072 218 111 218V226C128.258 226 144.639 222.196 159.34 215.376L155.973 208.119ZM161.189 209.87C158.516 204.842 157 199.104 157 193H149C149 200.445 150.852 207.469 154.124 213.625L161.189 209.87ZM157 193C157 173.118 173.118 157 193 157V149C168.699 149 149 168.699 149 193H157ZM193 157C199.104 157 204.842 158.516 209.87 161.189L213.625 154.124C207.469 150.852 200.445 149 193 149V157ZM218 111C218 127.072 214.459 142.306 208.119 155.973L215.376 159.34C222.196 144.639 226 128.258 226 111H218ZM111 4C170.094 4 218 51.9055 218 111H226C226 47.4873 174.513 -4 111 -4V4ZM208.509 163.865C203.882 161.397 198.598 160 193 160V168C197.253 168 201.247 169.059 204.745 170.924L208.509 163.865ZM193 160C174.775 160 160 174.775 160 193H168C168 179.193 179.193 168 193 168V160ZM160 193C160 198.598 161.397 203.882 163.865 208.509L170.924 204.745C169.059 201.247 168 197.253 168 193H160ZM203.184 165.359C193.978 180.936 180.936 193.978 165.359 203.184L169.43 210.071C186.167 200.179 200.179 186.167 210.071 169.43L203.184 165.359Z"
      fill="#838CA7"
      mask="url(#path-1-inside-1)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M151.712 135.781C141.54 148.261 127.103 157.6 109.06 157.6C91.1752 157.6 76.8326 148.424 66.6762 136.107C45.5018 140.484 27.1519 147.977 13.5215 157.555C30.9096 193.897 68.0234 219 111 219C125.777 219 139.861 216.032 152.688 210.661C150.316 205.255 149 199.281 149 193C149 169.047 168.14 149.565 191.959 149.012C180.362 143.283 166.726 138.753 151.712 135.781Z"
      fill="#535E83"
    />
    <path
      d="M158.76 76.4667C158.76 108.941 139.21 144.6 109.06 144.6C78.9112 144.6 59.3608 108.941 59.3608 76.4667C59.3608 43.9923 81.6121 27 109.06 27C136.509 27 158.76 43.9923 158.76 76.4667Z"
      fill="#B2B7C7"
    />
    <path
      d="M222 193C222 209.016 209.016 222 193 222C176.984 222 164 209.016 164 193C164 176.984 176.984 164 193 164C209.016 164 222 176.984 222 193Z"
      fill="#535E83"
    />
    <path
      d="M188.375 199.675L182.396 193.696C181.729 193.03 180.67 193.03 180.004 193.696C179.338 194.362 179.338 195.421 180.004 196.088L187.162 203.246C187.828 203.912 188.904 203.912 189.571 203.246L207.679 185.154C208.345 184.488 208.345 183.429 207.679 182.763C207.013 182.096 205.954 182.096 205.287 182.763L188.375 199.675Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M207.679 185.154L189.571 203.246C188.904 203.912 187.828 203.912 187.162 203.246L180.004 196.088C179.338 195.421 179.338 194.362 180.004 193.696C180.67 193.03 181.729 193.03 182.396 193.696L188.375 199.675L205.287 182.763C205.954 182.096 207.013 182.096 207.679 182.763C208.345 183.429 208.345 184.488 207.679 185.154ZM188.375 198.261L183.103 192.989C182.046 191.932 180.354 191.932 179.297 192.989C178.24 194.046 178.24 195.738 179.297 196.795L186.455 203.953C187.512 205.009 189.221 205.01 190.278 203.953C190.277 203.953 190.278 203.953 190.278 203.953L208.386 185.862C209.443 184.805 209.443 183.112 208.386 182.056C207.329 180.999 205.637 180.999 204.58 182.056L188.375 198.261Z"
      fill="white"
    />
  </svg>
);

export { VerifiedPersonIcon };

import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from 'theme/colors';

const useStyles = makeStyles((theme: Theme) => ({
  container: {},
  heading: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headingMobile: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  headingMobileWrapper: {
    display: 'flex',
  },
  avatar: {
    marginRight: theme.spacing(1.5),
  },
  userBox: {
    display: 'flex',
    alignItems: 'center',
  },
  username: {
    marginLeft: theme.spacing(2),
  },
  collapse: {
    paddingTop: theme.spacing(2),
  },

  postedAt: {
    [theme.breakpoints.up('xs')]: {
      marginRight: theme.spacing(1),
    },
    color: colors.SECONDARY_TEXT,
  },
  opportunityContent: {
    marginBottom: -theme.spacing(1),
    padding: theme.spacing(2, 4, 0, 3),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 18,
    color: colors.TEXT,
    textDecoration: 'none',
  },
  price: {
    fontSize: 18,
    fontWeight: 600,
  },
  opportunityInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(1),
    fill: colors.PRIMARY_INPUT_HOVERED,
  },
  list: {
    padding: 0,
    marginBottom: 0,
    display: 'flex',
    textDecoration: 'none',
  },
  item: {
    marginRight: theme.spacing(3),
    display: 'flex',
    '&:last-child': {
      marginRight: 0,
    },
  },
  opportunityMobile: {
    marginBottom: -theme.spacing(1),
    padding: theme.spacing(2),
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  mobileTitle: {
    display: 'flex',
    alignItems: 'flex-start',
    fontWeight: 500,
    color: colors.TEXT,
    textDecoration: 'none',
  },
  opportunityMobileData: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    '& p': {
      '&:first-child': {
        marginRight: theme.spacing(2),
      },
      color: colors.TEXT_GRAY,
      fontWeight: 500,
    },
  },
  opened: {
    fill: colors.PRIMARY_FOCUSED,
  },
  userListMenu: {
    minWidth: 128,
  },
  content: {},
  textBox: {
    padding: theme.spacing(0, 4, 2, 3),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(0, 2, 0, 2),
    },
  },
  text: {
    whiteSpace: 'break-spaces',
  },
  launchIcon: {
    fill: colors.PRIMARY_INPUT_HOVERED,
    marginLeft: theme.spacing(1),
  },
  filledIcon: {
    fill: colors.PRIMARY_INPUT_HOVERED,
  },
  shortText: {
    marginTop: theme.spacing(3),
    display: 'block',
    whiteSpace: 'break-spaces',
  },
  btnMore: {
    marginTop: theme.spacing(1),
    padding: '0px 8px',
    height: 30,
    fontSize: 12,
  },
  actions: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    display: 'flex',
  },
  shareAction: {
    padding: 0,
  },
  shareActionButton: {
    paddingLeft: `${theme.spacing(2)}px !important`,
    minHeight: theme.spacing(6),
    width: '100%',
    textAlign: 'left',
    outline: 'none',
  },
  socialItem: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginRight: theme.spacing(1),
    },
    '&:first-child': {
      marginRight: theme.spacing(3),
    },
  },
}));

export { useStyles };

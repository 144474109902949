import React, { FC, useEffect } from 'react';
import { TextField, Button, InputAdornment } from '@material-ui/core';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/client';

import { intl, getCurrentMargin } from 'helpers';
import { ProfitMarginsTypeEnum } from 'types/profitMargins';
import { CREATE_PROFIT_MARGINS } from 'services/graphql/common';
import { GET_OPPORTUNITY } from 'services/graphql/query/opportunity';
import type { Opportunity } from 'types/opportunity';

import { Modal } from 'components/Modal';

import { useStyles } from './styles';
import type { Props } from './types';

type GetOpportunity = {
  getOpportunity: Opportunity;
};

const EditMarginsModal: FC<Props> = ({ open, opportunity, handleClose }) => {
  const styles = useStyles();

  const { profitMargins, id } = opportunity;
  const { budget } = opportunity.redemption;
  const { offerAmount } = opportunity.finalCondition;

  const handleSend = () => {
    // eslint-disable-next-line
    setProfitMargin();
  };

  const { values, handleChange, resetForm, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      profit: getCurrentMargin(offerAmount ?? budget, profitMargins),
    },
    onSubmit: handleSend,
  });

  useEffect(() => {
    setFieldValue('profit', getCurrentMargin(offerAmount ?? budget, profitMargins));
  }, [opportunity]);

  const [setProfitMargin, { loading }] = useMutation(CREATE_PROFIT_MARGINS, {
    variables: {
      data: {
        firstRangePercentage: values.profit,
        secondRangePercentage: values.profit,
        thirdRangePercentage: values.profit,
        opportunityId: id,
        profitMarginsType: ProfitMarginsTypeEnum.series,
      },
    },
    onCompleted() {
      resetForm();
      handleClose();
    },
    update: (cache, { data: { createProfitMargins } }) => {
      try {
        const { getOpportunity } = cache.readQuery<GetOpportunity>({ query: GET_OPPORTUNITY, variables: { opportunityId: id } });
        cache.writeQuery({
          query: GET_OPPORTUNITY,
          variables: { opportunityId: id },
          data: {
            getOpportunity: {
              ...getOpportunity,
              profitMargins: createProfitMargins,
            },
          },
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('[Cache updating error]', error);
      }
    },
  });

  const Actions = () => (
    <>
      <Button variant="outlined" onClick={handleClose} className={styles.buttons} disabled={loading}>
        {intl('SHARED.cancel')}
      </Button>
      <Button onClick={handleSubmit as any} variant="contained" className={styles.buttons} color="primary" disabled={loading}>
        {intl('SHARED.apply')}
      </Button>
    </>
  );

  return (
    <Modal
      isOpen={open}
      handleClose={handleClose}
      dialogActionsComponent={<Actions />}
      title={intl('EDIT_MARGINS_MODAL.title')}
      className={styles.modal}
    >
      <TextField
        label={intl('EDIT_MARGINS_MODAL.profit')}
        name="profit"
        value={values.profit}
        onChange={handleChange}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
      />
    </Modal>
  );
};

export { EditMarginsModal };

import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
import { colors } from './colors';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.PRIMARY,
    },
    background: {
      default: colors.BACKGROUND,
    },
    text: {
      primary: colors.TEXT,
    },
  },
  typography: {
    fontFamily: [
      'Poppins',
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: '8px',
      },
      elevation1: {
        boxShadow: '0px 6px 20px rgba(2, 1, 86, 0.05)',
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: colors.BACKGROUND,
      },
      toolbarBtnSelected: {
        color: `${colors.WHITE}!important`,
      },
    },
    MuiPickersToolbarButton: {
      toolbarBtn: {
        height: 'auto',
      },
    },
    MuiPickersDay: {
      daySelected: {
        color: colors.WHITE,
      },
    },
    MuiPickersTimePickerToolbar: {
      hourMinuteLabel: {
        alignItems: 'center',
      },
      ampmSelection: {
        '& h6': {
          color: colors.BACKGROUND2,
        },
      },
    },
    MuiPickersClockNumber: {
      clockNumberSelected: {
        color: colors.WHITE,
      },
    },
    MuiTabs: {
      root: {
        fontWeight: 500,
      },
      indicator: {
        backgroundColor: colors.PRIMARY_HOVERED,
      },
    },
    MuiAvatar: {
      colorDefault: {
        backgroundColor: colors.PRIMARY,
      },
      root: {
        border: `2px solid ${colors.BACKGROUND}`,
        fontSize: 18,
        letterSpacing: -1.5,
      },
    },
    MuiInputBase: {
      input: {
        fontWeight: 500,
        lineHeight: 'normal',
      },
    },
    MuiCheckbox: {
      root: {
        color: colors.BORDER2,
      },
    },
    MuiInput: {
      underline: {
        '&:before': { borderBottom: `1px solid ${colors.BORDER}` },
        '&:after': {
          borderBottom: `2px solid ${colors.PRIMARY_FOCUSED}`,
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: `2px solid ${colors.PRIMARY_INPUT_HOVERED}`,
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&.Mui-focused': {
          color: colors.INPUT_LABEL,
        },
      },
    },
    MuiButton: {
      root: {
        padding: '9px 16px',
        minWidth: 40,
        height: 42,
      },
      outlined: {
        border: `1px solid ${colors.BORDER}`,
      },
      containedPrimary: {
        color: colors.BUTTON_PRIMARY_TEXT,
      },
    },
    MuiFormHelperText: {
      root: {
        marginTop: 0,
        height: 0,
        lineHeight: 1.2,
      },
    },
    MuiAutocomplete: {
      input: {
        lineHeight: 'normal',
      },
    },
    MuiRating: {
      root: {
        color: '#5B5B5B',
        '&.Mui-disabled': {
          opacity: 1,
        },
      },
    },
    MuiDrawer: {
      root: {
        zIndex: '1200 !important',
      },
    },
  },
});

import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },

  wrapper: {
    minHeight: '100vh',
    padding: '30px',
    paddingX: '8px',
  },
  headerWrapper: {
    marginBottom: 38
  },
  paper: {
    padding: 30,
    paddingBottom: 22,
    paddingTop: theme.spacing(7),
    display: 'flex',
    flexDirection: 'column',
    width: 460,
    maxWidth: '100%',
    marginTop: 24,
  },
  signupText: {
    marginTop: theme.spacing(3)
  },
  input: {
    marginBottom: theme.spacing(3),
  },
  firstInput: {
    marginTop: 40,
  },
  tab: {
    minWidth: '50%',
  },
  terms: {
    color: '#B1B4B8',
    fontSize: '0.75rem',
    '& a': {
      color: '#B1B4B8',
    },
    margin: '16px 0',
    textAlign: 'center'
  },
}))

import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  paper: {
    padding: 30,
    paddingBottom: 22,
    paddingTop: 16,
    display: 'flex',
    flexDirection: 'column',
    width: 460,
    maxWidth: '100%',
    marginTop: 24,
  },
  input: {
    marginBottom: 36,
  },
  firstInput: {
    marginTop: 40,
  },
  tab: {
    minWidth: '50%',
  },
  terms: {
    color: '#B1B4B8',
    fontSize: '0.75rem',
    '& a': {
      color: '#B1B4B8',
    },
  },
  signup: {
    marginTop: 16,
    marginBottom: 16,
  },
}))

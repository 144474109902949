import { makeStyles } from '@material-ui/core';
import { colors } from 'theme/colors';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(2),
    background: colors.BACKGROUND4,
    borderRadius: 4,
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
    },
  },
  input: {
    flexGrow: 1,
    padding: '13px 10px',
    fontSize: 15,
  },
  btnSend: {
    '&:hover': {
      '& svg': {
        fill: colors.PRIMARY_HOVERED,
      },
    },
  },
  icon: {
    fill: '#878EA8',
    height: 19,
  },
}));

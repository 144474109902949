import React, { useRef, useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import InfiniteScrollReverse from 'react-infinite-scroll-reverse';
import { useMutation } from '@apollo/client';
import clsx from 'clsx';
import { nanoid } from 'nanoid';

import { useAuth } from 'hooks/auth';
import { useTalentErrors } from 'hooks/talent';
import { intl } from 'helpers';
import { ChatIcon } from 'assets/svg-js/ChatIcon';
import { STATUSES } from 'constants/index';
import { UserTypeEnum } from 'types/common';

import { UPDATE_OFFER } from 'services/graphql/mutation/opportunity';
import { CREATE_CONVERSATION_MESSAGE, MARK_CONVERSATION_AS_READ } from 'services/graphql/mutation/messanger';
import { GET_CONVERSATION, GET_UNREAD_MESSAGES } from 'services/graphql/query/messanger';
import { GET_OPPORTUNITY } from 'services/graphql/query/opportunity';
import { ON_CREATE_CONVERSATION_MESSAGE } from 'services/graphql/subscription/messanger';

import { TalentValidationModal } from 'components/Modals/TalentValidationModal';

import { useStyles } from './styles';
import Message from './components/Message';
import SendPanel from './components/SendPanel';

const Chat = ({ conversation, className = '', subscribeToMore, fetchMore, currentApplicant, disabled, opportunityId: oppId, opportunityStatus }) => {
  const classes = useStyles();
  const { user, getRole, getAssumedRole } = useAuth();
  const [talentModal, setTalentModal] = useState(false);
  const role = getRole();
  const assumedTalent = getAssumedRole() === UserTypeEnum.talent;
  const { talentErrors, isTalentValid } = useTalentErrors(!assumedTalent);
  const ref = useRef(null);
  const clientID = user.attributes.sub;
  const messages = conversation?.messages.items || [];
  const reversed = new Array(...messages).reverse();
  const isAdmin = role === UserTypeEnum.admin;

  const toggleTalentModal = () => setTalentModal((v) => !v);

  const [markConversationAsRead] = useMutation(MARK_CONVERSATION_AS_READ, {
    variables: { conversationId: conversation?.id },
    refetchQueries: [{ query: GET_UNREAD_MESSAGES, variables: { oppId } }],
    awaitRefetchQueries: true,
  });
  const [updateOffer, { loading: offerUpdating }] = useMutation(UPDATE_OFFER);
  const [createMessage, { loading: messageSending }] = useMutation(CREATE_CONVERSATION_MESSAGE);

  const scrollToTheEnd = () => {
    if (ref && ref?.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (currentApplicant && fetchMore) {
      fetchMore({
        query: GET_CONVERSATION,
        variables: {
          applicationId: currentApplicant,
          filter: { limit: 10, offset: 0 },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          const updatedQuery = {
            getConversation: {
              ...prev.getConversation,
              messages: {
                ...prev.getConversation.messages,
                items: [...fetchMoreResult.getConversation.messages.items],
              },
            },
          };
          return updatedQuery;
        },
      });
    }
  }, [currentApplicant, fetchMore]);

  useEffect(() => {
    if (!conversation || !subscribeToMore) return;
    subscribeToMore({
      document: ON_CREATE_CONVERSATION_MESSAGE,
      variables: { conversationId: conversation.id },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        if (!prev.getConversation.participants.filter((i) => i.id !== clientID).length) return prev;
        const newMessage = subscriptionData.data.onCreateConversationMessage;
        if (prev.getConversation.messages.items.find((m) => m.id === newMessage.id)) return prev;

        const updatedQuery = {
          getConversation: {
            ...prev.getConversation,
            messages: {
              ...prev.getConversation.messages,
              items: [newMessage, ...prev.getConversation.messages.items],
              total: prev.getConversation.messages.total + 1,
            },
          },
        };

        setTimeout(() => scrollToTheEnd(), 100);

        return updatedQuery;
      },
    });
  }, [conversation]);

  const loadMore = () => {
    if (!reversed.length || !fetchMore) return;

    fetchMore({
      query: GET_CONVERSATION,
      variables: {
        applicationId: currentApplicant,
        filter: { limit: 10, offset: reversed.length },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        const updatedQuery = {
          getConversation: {
            ...prev.getConversation,
            messages: {
              ...prev.getConversation.messages,
              items: [...prev.getConversation.messages.items, ...fetchMoreResult.getConversation.messages.items],
            },
          },
        };

        return updatedQuery;
      },
    });
  };

  const handleAddMessage = async (body) => {
    const sender = conversation.participants.filter((participant) => participant.type.toLowerCase() === role.toLowerCase())[0];
    await createMessage({
      variables: {
        data: {
          conversationId: conversation.id,
          body,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        createConversationMessage: {
          id: nanoid(),
          conversationId: conversation.id,
          sender: {
            firstName: sender.firstName,
            id: sender.id,
            image: sender.image,
            lastName: sender.lastName,
            type: sender.type,
            __typename: 'ConversationParticipant',
          },
          body,
          action: 'GET_PLAIN_MESSAGE',
          applicationId: null,
          coverLetter: null,
          offerId: null,
          offer: null,
          createdAt: +new Date(),
          __typename: 'ConversationMessage',
        },
      },
      update: (cache, updatedData) => {
        const query = cache.readQuery({
          query: GET_CONVERSATION,
          variables: { applicationId: currentApplicant },
        });
        const { getConversation } = query;
        const newMessage = updatedData.data.createConversationMessage;
        const tmpMessages = [...getConversation.messages.items];
        tmpMessages.unshift(newMessage);

        cache.writeQuery({
          query: GET_CONVERSATION,
          variables: { applicationId: currentApplicant },
          data: {
            getConversation: {
              ...getConversation,
              messages: {
                ...getConversation.messages,
                total: getConversation.messages.total + 1,
                items: tmpMessages,
              },
            },
          },
        });
        setTimeout(scrollToTheEnd, 100);
      },
    });
    markConversationAsRead();
  };

  const handleUpdateOffer = (opportunityId, offerStatus) => {
    updateOffer({
      variables: {
        data: {
          opportunityId,
          offerStatus,
        },
      },
      update: (cache, updatedData) => {
        const query = cache.readQuery({ query: GET_OPPORTUNITY, variables: { opportunityId } });

        const { getOpportunity } = query;
        const { offerAmount, talentSalary, description, isTaxed } = updatedData.data.updateOfferStatus.offer;

        const statusName = offerStatus === 'confirm' ? STATUSES.CONFIRMED.value : STATUSES.DECLINED.value;

        cache.writeQuery({
          query: GET_OPPORTUNITY,
          variables: { opportunityId },
          data: {
            getOpportunity: {
              ...getOpportunity,
              status: {
                ...getOpportunity.status,
                name: statusName,
              },
              finalCondition: {
                ...getOpportunity.finalCondition,
                offerAmount,
                talentSalary,
                description,
                isTaxed,
              },
            },
          },
        });
      },
    });
  };

  const handleScroll = (e) => {
    e.persist();
    if (e.target.scrollTop === 0 && reversed.length < conversation?.messages?.total) {
      loadMore();
    }
  };

  const sendPanelProps = {
    handleAddMessage,
    messageSending,
    preDisabled: (role === UserTypeEnum.talent || role === UserTypeEnum.agent) && reversed.length < 2,
    postDisabled: disabled,
    messageTo: conversation?.participants.find((i) => i.id !== clientID),
  };

  const messageProps = {
    handleUpdateOffer,
    isTalentValid,
    toggleTalentModal,
    clientID,
    role,
    offerUpdating,
    opportunityStatus,
  };

  return (
    <Box className={clsx(classes.container, className)}>
      <Box className={classes.messageList} ref={ref} onScroll={handleScroll}>
        {reversed.length ? (
          <InfiniteScrollReverse
            className={classes.infiniteScroll}
            hasMore={reversed?.length === conversation?.message?.total}
            isLoading={!reversed?.length}
            loadMore={() => {}}
            loadArea={0}
          >
            {reversed?.map((m) => (
              <Message key={m.id} {...messageProps} message={m} />
            ))}
          </InfiniteScrollReverse>
        ) : (
          <Box className={classes.emptyBox}>
            <Box className={classes.iconBox}>
              <ChatIcon />
            </Box>
            <Typography className={classes.emptyText}>{intl('MESSENGER.notAvailableYet')}</Typography>
          </Box>
        )}
      </Box>
      {!isAdmin && <SendPanel {...sendPanelProps} />}
      {assumedTalent && <TalentValidationModal errors={talentErrors} open={talentModal} handleClose={toggleTalentModal} />}
    </Box>
  );
};

export default Chat;

import React from 'react';
import clsx from 'clsx';
import { Paper, Typography } from '@material-ui/core';
import { intl } from 'helpers';

import { useStyles } from './styles';

export const About = ({ brand, className = '' }) => {
  const styles = useStyles({});
  return (
    <Paper className={clsx(styles.paper, styles.publicPageServices, className)} elevation={0}>
      <Typography className={styles.sectionHeading} component="h4">
        {intl('SHARED.about')}
      </Typography>
      <Typography className={styles.aboutText}>
        <pre className={styles.pre}>{brand?.about || intl('SHARED.notFilledYet')}</pre>
      </Typography>
    </Paper>
  );
};

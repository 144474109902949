import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from 'theme/colors';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    flexGrow: 1,
    padding: theme.spacing(3, 0),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(3, 1),
    },
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  mainGrid: {
    [theme.breakpoints.down('sm')]: {
      order: 1,
    },
  },
  secondGrid: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  select: {
    '&::before': {
      content: 'none',
    },
  },
  tab: {
    fontSize: 12,
  },
  btnAdd: {
    fontSize: 12,
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(1),
      borderRadius: 4,
      backgroundColor: colors.PRIMARY,
      '& svg': {
        fill: colors.WHITE,
      },
    },
  },
  divider: {
    marginTop: -1,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(1),
    },
  },
  showMoreContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
  buttonShowMore: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  mobileLink: {
    textDecoration: 'none',
    color: colors.BLACK,
  },
  loader: {
    paddingTop: 150,
  },
  brands: {
    [theme.breakpoints.up('md')]: {
      position: 'sticky',
      top: 80,
    },
  },
  title: {
    fontSize: 18,
    fontWeight: 500,
  },
  emptyBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(12),
    },
  },
  emptyIcon: {
    '@media screen and (max-width: 320px)': {
      width: 275,
    },
    '@media (max-width: 420px) and (min-width: 321px)': {
      width: 320,
    },
  },
  emptyText: {
    marginTop: -theme.spacing(10),
    letterSpacing: '0.3px',
  },
  btnCreate: {
    marginTop: theme.spacing(4),
  },
}));

export { useStyles };

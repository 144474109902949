import React from 'react'

const QuestionMark = ({ ...props }) => (
  <svg
    width="36"
    height="65"
    viewBox="0 0 36 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
        // eslint-disable-next-line max-len
      d="M22.2802 64.7227H13.1405V55.5752H22.2802V64.7227ZM31.7398 29.2761L27.627 33.4839C25.342 35.8165 23.6969 37.9205 22.8743 41.2136C22.5087 42.6772 22.2802 44.3237 22.2802 46.4277H13.1405V44.1408C13.1405 42.0368 13.5061 40.0244 14.1459 38.1492C15.0599 35.4964 16.5679 33.118 18.4873 31.1971L24.1539 25.4341C26.256 23.4217 27.2614 20.403 26.6673 17.2014C26.0732 13.9083 23.5141 11.1183 20.3152 10.2035C15.2427 8.78568 10.5357 11.6671 9.02767 16.0122C8.47928 17.7045 7.06263 18.9851 5.28039 18.9851H3.90943C1.25891 18.9851 -0.569024 16.4238 0.162152 13.8625C2.12719 7.13913 7.8395 2.01652 14.9228 0.918823C21.8689 -0.178877 28.4952 3.43439 32.6081 9.15157C38.0005 16.6068 36.4011 24.6109 31.7398 29.2761Z"
      fill="#838CA7"
    />
  </svg>
)

export default QuestionMark

import React, { FC, useState } from 'react';
import clsx from 'clsx';
import { Box, Button, Divider, Paper, Tab, Tabs, Typography, IconButton, Avatar, Hidden } from '@material-ui/core';
import { Category, Event, Room, Schedule, Create as CreateIcon } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { format, startOfDay, endOfDay, isEqual } from 'date-fns';

import { formatNumber, getCurrencySymbol, intl, getCurrentMargin } from 'helpers';
import { routes } from 'router/routesList';

import { Loader } from 'components/Loader';
import { CompleteOpportunityModal } from 'components/Modals/CompleteOpportunityModal';
import { CancelOpportunityModal } from 'components/Modals/CancelOpportunityModal';
import { EditMarginsModal } from 'components/Modals/EditMarginsModal';
import OpportunityStatus from 'components/OpportunityStatus';
import FinalBudgetTooltip from 'components/FinalBudgetTooltip';
import ImageGallery from 'components/ImageGallery';
import Applicants from 'pages/Opportunity/SingleOpportunity/components/Applicants';
import Deal from 'pages/Opportunity/SingleOpportunity/components/Deal';

import { SINGLE_OPPORTUNITY_TABS, STATUSES as OPPORTUNITY_STATUS, TALENT_STATUS } from 'constants/index';

import { useStyles } from './styles';
import { useOpportunity } from './useOpportunity';
import { ViewProfile } from '../../../components/Buttons/ViewProfile';

const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env;

// TODO: move to separate module
const TabPanel = (props) => {
  const { children, value, index, component: Component = Box, ...other } = props;
  return value === index ? (
    <Component role="tabpanel" hidden={value !== index} id={`opportunity-tabpanel-${index}`} aria-labelledby={`opportunity-tab-${index}`} {...other}>
      {children}
    </Component>
  ) : null;
};

export const Opportunity: FC = () => {
  const { opportunity, loadingOpportunity, tab, role, applicantsProps, dealProps } = useOpportunity();
  const styles = useStyles({});

  const [editMarginsOpen, setMarginsModal] = useState<boolean>(false);
  const [completeOpportunityOpen, setCompleteOpportunity] = useState<boolean>(false);

  const toggleEditMarginsPopup = () => setMarginsModal((old) => !old);
  const toggleCompleteOpportunityPopup = () => setCompleteOpportunity((old) => !old);

  const formatDate = (date) => (date ? format(new Date(date), 'dd.MM.yy') : null);

  const hasEventTime = (startDate, endDate) => {
    if (!startDate || !endDate) {
      return;
    }

    const formattedStartDate = new Date(startDate);
    const formattedEndDate = new Date(endDate);

    if (!isEqual(formattedStartDate, startOfDay(formattedStartDate)) && !isEqual(formattedEndDate, endOfDay(formattedEndDate))) {
      return `${format(new Date(formattedStartDate), 'hh:mm a')} - ${format(new Date(formattedEndDate), 'hh:mm a')}`;
    }

    return false;
  };

  const firstApplicantId = !!opportunity?.applicants?.length && opportunity?.applicants[0].applicantId;
  const budget = opportunity?.finalCondition?.offerAmount ?? opportunity?.redemption?.budget;
  const isActive =
    opportunity?.status?.name === OPPORTUNITY_STATUS.OPEN.value ||
    opportunity?.status?.name === OPPORTUNITY_STATUS.CONFIRMED.value ||
    opportunity?.status?.name === OPPORTUNITY_STATUS.PAYMENT.value;
  const isOpen = opportunity?.status?.name === OPPORTUNITY_STATUS.OPEN.value;
  const isConfirmed = opportunity?.status?.name === OPPORTUNITY_STATUS.CONFIRMED.value;

  const hasFinalCondition = opportunity?.finalCondition?.offerAmount;

  const isDealDisabled =
    (opportunity?.status?.name === OPPORTUNITY_STATUS.OPEN.value ||
      opportunity?.status?.name === OPPORTUNITY_STATUS.EXPIRED.value ||
      (opportunity?.status?.name === OPPORTUNITY_STATUS.CANCELED_BY_ADMIN.value && !hasFinalCondition) ||
      (opportunity?.status?.name === OPPORTUNITY_STATUS.CANCELED_BY_ADMIN_PAYMENT.value && !hasFinalCondition) ||
      (opportunity?.status?.name === OPPORTUNITY_STATUS.CANCELED_BY_BRAND.value && !hasFinalCondition)) &&
    role === 'admin';

  if (loadingOpportunity) {
    return <Loader className={styles.loader} />;
  }

  // @ts-ignore
  return (
    <Box className={styles.opportunityWrapper}>
      <Box className={styles.heading}>
        <Box className={styles.titleBox}>
          <Typography variant="h6" className={styles.title}>
            {opportunity.name}
          </Typography>
          <OpportunityStatus
            status={opportunity.isApplied === TALENT_STATUS.DECLINED ? OPPORTUNITY_STATUS.DECLINED.value : opportunity.status?.name}
          />
        </Box>
        <Tabs variant="fullWidth" indicatorColor="primary" aria-label="brand-tabs" value={tab} className={styles.tabsContainer}>
          <Tab
            component={Link}
            to={`/${routes.ADMIN}/${routes.OPPORTUNITIES}/${opportunity.id}/details`}
            value="details"
            label={intl(SINGLE_OPPORTUNITY_TABS.DETAILS.label)}
            className={styles.tab}
          />

          <Tab
            component={Link}
            to={`/${routes.ADMIN}/${routes.OPPORTUNITIES}/${opportunity.id}/applicants`}
            value="applicants"
            label={intl(SINGLE_OPPORTUNITY_TABS.APPLICANTS.label)}
            className={styles.tab}
            disabled={!firstApplicantId}
          />

          <Tab
            component={Link}
            disabled={isDealDisabled}
            to={`/${routes.ADMIN}/${routes.OPPORTUNITIES}/${opportunity.id}/deal`}
            value="deal"
            label={intl(SINGLE_OPPORTUNITY_TABS.DEAL.label)}
            className={styles.tab}
          />
        </Tabs>
      </Box>

      <Divider />

      <Paper className={styles.detailsContainer}>
        <TabPanel value={tab} index="details" className={styles.detailsWrapper}>
          <Paper className={styles.mainInfo}>
            <Box className={styles.detailsItem}>
              <Avatar
                className={styles.brandIcon}
                src={`${process.env.REACT_APP_AWSS3_URL_IMAGE_BEG}${opportunity.brand.image}`}
                alt={`${opportunity.name}'s_logo`}
              />
              <Typography variant="body1">{opportunity.brand.name}!</Typography>
            </Box>
            <ViewProfile className={styles.viewProfile} link={routes.ADMIN_BRAND_PROFILE(opportunity.brand.id)} />
            <Typography variant="body2" className={styles.detailsItem}>
              <Category fontSize="small" /> {opportunity.type?.name}
            </Typography>
            <Typography variant="body2" className={styles.detailsItem}>
              <Room fontSize="small" /> {`${opportunity.country?.name}, ${opportunity.city?.name}`}
            </Typography>
            <Typography variant="body2" className={styles.detailsItem}>
              <Event fontSize="small" /> {`${formatDate(opportunity.startDate)} - ${formatDate(opportunity.endDate)}`}
            </Typography>
            {hasEventTime(opportunity.startDate, opportunity.endDate) && (
              <Typography variant="body2" className={styles.detailsItem}>
                <Schedule fontSize="small" /> {hasEventTime(opportunity.startDate, opportunity.endDate)}
              </Typography>
            )}
            <Divider />
            <Box className={styles.financial}>
              <Typography className={clsx(styles.detailsItem, styles.budget)} variant="h5">
                {`${getCurrencySymbol(opportunity.redemption?.currency?.code)} ${formatNumber(budget)} `}
                <small>{opportunity.redemption?.isTaxed ? intl('SHARED.taxIncluded') : intl('SHARED.taxExcluded')}</small>
              </Typography>
              <FinalBudgetTooltip simple bold budget={budget} isTaxed={opportunity.redemption.isTaxed} profitMargins={opportunity.profitMargins} />
              <Typography variant="caption" className={styles.postedDate}>
                {`${intl('OPPORTUNITY.posted')}: ${formatDate(opportunity.createdAt)}`}
              </Typography>
            </Box>
            {isActive && (
              <Box className={styles.buttonsGroup}>
                <Button component={Link} fullWidth to={`${routes.ADMIN_EDIT_OPPORTUNITY}/${opportunity.id}`} className={styles.btnEdit}>
                  {intl('SHARED.edit')}
                </Button>
                <Button onClick={toggleCompleteOpportunityPopup} fullWidth variant="contained" color="primary" className={styles.btnComplete}>
                  {isOpen || isConfirmed ? intl('OPPORTUNITY.cancelOpportunityText') : intl('SHARED.completeOpportunity')}
                </Button>
              </Box>
            )}

            <Divider />
            <Box className={styles.marginBox}>
              <Typography>{`${intl('SHARED.profit')} ${getCurrentMargin(budget, opportunity.profitMargins)} %`}</Typography>
              <IconButton onClick={toggleEditMarginsPopup}>
                <CreateIcon />
              </IconButton>
            </Box>
          </Paper>

          <Box className={clsx(styles.textContainer)}>
            {opportunity.images?.length || opportunity.description || opportunity.finalCondition?.description ? (
              <Box>
                {opportunity.description && (
                  <>
                    <Typography className={styles.title}>{intl('SHARED.description')}</Typography>
                    <Typography paragraph className={styles.description}>
                      {opportunity.description}
                    </Typography>
                  </>
                )}
                {opportunity.images.length && <ImageGallery images={opportunity.images.map((src) => REACT_APP_AWSS3_URL_IMAGE_BEG + src)} />}
              </Box>
            ) : (
              <Typography className={styles.emptyBlock}>{intl('SHARED.noDescription')}</Typography>
            )}
            {opportunity.finalCondition?.description && (
              <Box>
                <Typography className={styles.title}>{intl('OPPORTUNITY.finalConditions')}</Typography>
                <Typography className={styles.description}>{opportunity.finalCondition?.description}</Typography>
              </Box>
            )}
          </Box>
        </TabPanel>

        <TabPanel value={tab} index="applicants" className={styles.applicantsContainer}>
          <Applicants {...applicantsProps} />
        </TabPanel>
        <TabPanel value={tab} index="deal">
          {/* @ts-ignore */}
          <Deal {...dealProps} />
        </TabPanel>
      </Paper>
      {opportunity?.id && <EditMarginsModal open={editMarginsOpen} handleClose={toggleEditMarginsPopup} opportunity={opportunity} />}
      {opportunity?.id && !isOpen && (
        <CompleteOpportunityModal open={completeOpportunityOpen} handleClose={toggleCompleteOpportunityPopup} opportunity={opportunity} />
      )}
      {opportunity?.id && isOpen && (
        <CancelOpportunityModal isOpen={completeOpportunityOpen} handleClose={toggleCompleteOpportunityPopup} opportunity={opportunity} />
      )}
    </Box>
  );
};

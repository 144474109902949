import React, { FC, useRef } from 'react';
import { Box, Divider, Grid, Paper, Typography } from '@material-ui/core';

import { useStyles } from '../styles';

import type { CardWrapperProps } from '../types';

export const CardWrapper: FC<CardWrapperProps> = ({ itemsQuantity, children, title, loadMore, tableType }) => {
  const styles = useStyles({});
  let isFetch = true;
  const handleScroll = (event) => {
    const isCallFetch = event.target.scrollHeight - event.target.scrollTop < 335;
    if (isCallFetch && isFetch) {
      loadMore(tableType);
      isFetch = false;
    }
  };

  if (itemsQuantity) {
    return (
      <Grid item xs={12} sm={6} onScroll={handleScroll}>
        <Paper className={styles.contentBlock}>
          <Grid container className={styles.blockHeader}>
            <Typography>{title}</Typography>
            <Typography>{itemsQuantity}</Typography>
          </Grid>
          <Divider />
          <Box className={styles.itemsWrapper}>{children}</Box>
        </Paper>
      </Grid>
    );
  } else {
    return null;
  }
};

import React, { useState } from 'react';
import { Avatar, Grid, Typography, Button, Box, Hidden } from '@material-ui/core';

import { intl, getCurrencySymbol, formatNumber, getLetterForAvatar } from 'helpers';
import { useAuth } from 'hooks/auth';
import { UserTypeEnum } from 'types/common';
import { routes } from 'router/routesList';
import { STATUSES } from 'constants/index';

import { RateModal } from 'components/Modals/RateModal';
import { ViewProfile } from 'components/Buttons/ViewProfile';
import FinalBudgetTooltip from 'components/FinalBudgetTooltip';

// eslint-disable-next-line import/named
import { useStyles } from './styles';

const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env;

const TalentCard = ({ applicants, status, profile, profitMargins, finalCondition, togglePaymentPopup, isRated, opportunityId }) => {
  const [openRate, setRateModal] = useState(false);
  const { getRole } = useAuth();
  const styles = useStyles();
  const profileData = profile || {};
  const { isTaxed, budget, description } = profileData;
  const isAdmin = getRole() === UserTypeEnum.admin;

  const { applicant } = profileData;
  const canPay = (status.name === STATUSES.PAYMENT.value || status.name === STATUSES.CANCELED_BY_ADMIN_PAYMENT.value) && !isAdmin;

  const isShowRateBtn =
    [STATUSES.PAYMENT.value, STATUSES.CANCELED_BY_TALENT.value, STATUSES.CANCELED_BY_BRAND.value, STATUSES.DONE.value].includes(status.name) &&
    applicants?.length;

  const talentsBtnGrid = isShowRateBtn ? 6 : 12;

  const talentProfileLink = isAdmin
    ? `/${routes.ADMIN}/${routes.TALENTS}/${profile.applicant?.applicantId}/public`
    : `/${routes.BRAND}/${routes.TALENT}/${profile.applicant?.applicantId}`;

  const rateBtnTitle = isRated ? 'RATE_BUTTON.rated' : 'RATE_BUTTON.rate';
  const toggleRateModal = () => setRateModal((current) => !current);

  return (
    <Box className={styles.container}>
      <Box>
        <Box className={styles.heading} mb={3}>
          <Avatar className={styles.avatar} src={`${REACT_APP_AWSS3_URL_IMAGE_BEG}${applicant?.image}`}>
            {getLetterForAvatar(applicant?.firstName, applicant?.lastName)}
          </Avatar>
          <Typography className={styles.name} variant="h6">
            {applicant?.firstName} {applicant?.lastName}
          </Typography>
          <ViewProfile className={styles.viewProfileTablet} link={talentProfileLink} />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={talentsBtnGrid} sm={talentsBtnGrid} md={12} lg={12} className={styles.viewProfileDesktop}>
            <ViewProfile link={talentProfileLink} />
          </Grid>
          <Hidden smUp>
            {isShowRateBtn && (
              <Grid item xs={6} sm={6}>
                <Button className={styles.talentsBtn} variant="outlined" fullWidth disabled={isRated} onClick={toggleRateModal}>
                  {intl(rateBtnTitle)}
                </Button>
              </Grid>
            )}
          </Hidden>
        </Grid>
        <Box className={styles.budgetBox}>
          <Typography className={styles.budget} variant="h5">
            {`${getCurrencySymbol('Euro')} ${formatNumber(finalCondition?.offerAmount || budget)}`}
          </Typography>
          <Typography variant="caption">{finalCondition?.isTaxed || isTaxed ? intl('SHARED.taxIncluded') : intl('SHARED.taxExcluded')}</Typography>
        </Box>
        <FinalBudgetTooltip
          simple
          bold
          isTaxed={finalCondition?.isTaxed}
          budget={finalCondition?.offerAmount || budget}
          profitMargins={profitMargins}
        />
        <Typography className={styles.description} variant="body2">
          {finalCondition?.description || description || intl('SHARED.noDescription')}
        </Typography>
      </Box>
      <Hidden mdDown>
        {canPay && (
          <Button className={styles.btnPay} onClick={togglePaymentPopup} fullWidth>
            {intl('SHARED.pay')}
          </Button>
        )}
      </Hidden>
      <RateModal open={openRate} handleClose={toggleRateModal} opportunityId={opportunityId} />
    </Box>
  );
};

export default TalentCard;

import React from 'react'
import { Box } from '@material-ui/core'
import { useStyles } from './styles'

const TabPanel = ({ children, value, index, ...other }) => {
  const classes = useStyles()

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`opportunity-form-tabpanel-${index}`}
      aria-labelledby={`opportunity-form-tab-${index}`}
      className={classes.tab}
      {...other}
    >
      <Box>{children}</Box>
    </Box>
  )
}

export const a11yProps = (index) => ({
  id: `opportunity-form-tab-${index}`,
  'aria-controls': `opportunity-form-tabpanel-${index}`,
})

export default TabPanel

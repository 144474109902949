/* eslint-disable max-len */
import React from 'react'

const ConfirmIcon = ({ ...props }) => (
  <svg
    width="112"
    height="112"
    viewBox="0 0 112 112"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="56" cy="56" r="56" fill="#F6F6F6" />
    <path
      d="M53.57 24.08L32.57 33.41C30.41 34.37 29 36.53 29 38.9V53C29 69.65 40.52 85.22 56 89C71.48 85.22 83 69.65 83 53V38.9C83 36.53 81.59 34.37 79.43 33.41L58.43 24.08C56.9 23.39 55.1 23.39 53.57 24.08ZM47.87 68.87L40.1 61.1C38.93 59.93 38.93 58.04 40.1 56.87C41.27 55.7 43.16 55.7 44.33 56.87L50 62.51L67.64 44.87C68.81 43.7 70.7 43.7 71.87 44.87C73.04 46.04 73.04 47.93 71.87 49.1L52.1 68.87C50.96 70.04 49.04 70.04 47.87 68.87Z"
      fill="#848CA6"
    />
  </svg>
)

export default ConfirmIcon

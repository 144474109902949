import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { Switch, Redirect } from 'react-router-dom';

import { AdminDrawer } from 'components/AdminDrawer';
import { DeletePostModal } from 'components/Modals/DeletePostModal';
import { Talents } from 'pages/Admin/Talents';
import { Brands } from 'pages/Admin/Brands';
import { BrandManagers } from 'pages/Admin/BrandManagers';
import { Opportunities } from 'pages/Admin/Opportunities';
import { Talent } from 'pages/Admin/Talent';
import { Post } from 'pages/Post/Post';
import { BrandManager } from 'pages/Admin/BrandManager';
import { Brand } from 'pages/Admin/Brand';
import ProfitMargins from 'pages/Admin/ProfitMargins';
import { UpdateOpportunity } from 'pages/Opportunity/OpportunityForm';
import { Opportunity } from 'pages/Admin/Opportunity';
import { Analytics } from 'pages/Admin/Analytics';
import { NewsFeed } from 'pages/Admin/NewsFeed';
import { NewPost } from 'pages/Post/NewPost';
import { EditPost } from 'pages/Post/EditPost';

import { useAdminStyles } from './styles';
import { PrivateRoute } from './PrivateRoute';
import { routes } from './routesList';

export const Admin = () => {
  const styles = useAdminStyles({});
  const [drawerOpened, setDrawerOpened] = useState(false);
  const handleDrawerToggle = () => setDrawerOpened((drawer) => !drawer);
  return (
    <Box className={styles.container}>
      <AdminDrawer toggleDrawer={handleDrawerToggle} open={drawerOpened} />
      <Box className={styles.content}>
        <Box className={styles.adminHeader} />

        <Switch>
          <PrivateRoute exact path={`/${routes.ADMIN}/${routes.TALENTS}`} component={Talents} />
          <PrivateRoute path={`/${routes.ADMIN}/${routes.TALENTS}/:id/:tab?`} component={Talent} />
          <PrivateRoute exact path={`/${routes.ADMIN}/${routes.BRANDS}`} component={Brands} />
          <PrivateRoute exact path={`/${routes.ADMIN}/${routes.ANALYTICS}`} component={Analytics} />
          <PrivateRoute exact path={`/${routes.ADMIN}/${routes.PROFIT_MARGINS}`} component={ProfitMargins} />
          <PrivateRoute exact path={`/${routes.ADMIN}/${routes.BRANDS}/:id/:tab?`} component={Brand} />
          <PrivateRoute exact path={`/${routes.ADMIN}/${routes.BRAND_MANAGERS}`} component={BrandManagers} />
          <PrivateRoute exact path={`/${routes.ADMIN}/${routes.BRAND_MANAGERS}/:id/:tab?`} component={BrandManager} />
          <PrivateRoute exact path={`/${routes.ADMIN}/${routes.OPPORTUNITIES}`} component={Opportunities} />
          <PrivateRoute exact path={`/${routes.ADMIN}/${routes.OPPORTUNITIES}`} component={Opportunities} />
          <PrivateRoute exact path={`${routes.ADMIN_EDIT_POST}/:id`} component={EditPost} />
          <PrivateRoute exact path={routes.ADMIN_NEW_POST} component={NewPost} />
          <PrivateRoute exact path={`/${routes.ADMIN}/${routes.NEWS_FEED}/:tab?`} component={NewsFeed} />
          <PrivateRoute exact path={`/${routes.NEWS_FEED}/${routes.POST}/:id`} component={Post} />
          <PrivateRoute path={`${routes.ADMIN_NEWS_FEED_POST}/:id`} component={Post} />
          <PrivateRoute path={`/${routes.ADMIN}/${routes.OPPORTUNITIES}/:id/:tab?`} component={Opportunity} />
          <PrivateRoute path={`${routes.ADMIN_EDIT_OPPORTUNITY}/:id`} component={UpdateOpportunity} />
          <PrivateRoute path={`/${routes.ADMIN}`} component={() => <Redirect to={`/${routes.ADMIN}/${routes.TALENTS}`} />} />
        </Switch>
      </Box>
      <DeletePostModal />
    </Box>
  );
};

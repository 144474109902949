import { makeStyles, Theme } from '@material-ui/core';
import { colors } from 'theme/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    height: 400,
    cursor: 'pointer',
    overflow: 'hidden',
  },
  initialImage: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    zIndex: 2,
    backgroundPosition: 'center',
  },
  stretchedImage: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    filter: 'blur(4px)',
  },
  btnGroup: {
    height: '100%',
    width: '100%',
    minHeight: 240,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-between',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 4,
  },
  imagesCounter: {
    marginBottom: theme.spacing(2),
    position: 'absolute',
    width: 'max-content',
    bottom: 0,
    left: '50%',
    right: '50%',
    transform: 'translateX(-50%)',
    color: colors.WHITE,
  },
  navigationBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignSelf: 'center',
  },
  icon: {
    fill: colors.WHITE,
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
  },
  iconBox: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    borderRadius: 4,
    '&:first-child': {
      marginLeft: theme.spacing(3),
    },
    '&:last-child': {
      marginRight: theme.spacing(3),
    },
    '&:hover': {
      backgroundColor: colors.BLACK,
    },
  },
}));

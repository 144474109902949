import { makeStyles } from '@material-ui/core'
import { colors } from '../../../theme/colors'

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  main: {
    margin: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: 50,
    width: '100%',
    height: 'min-content',
    background: colors.WHITE,
    borderRadius: 8,
  },
  title: {
    marginBottom: theme.spacing(5),
    letterSpacing: '0.5px',
  },
  saveBar: {
    marginBottom: theme.spacing(2),
    borderRadius: 8,
  },
}))

import { makeStyles, Theme } from '@material-ui/core'
import { colors } from 'theme/colors'

export const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    height: 48,
    width: 48,
    marginRight: 16,
  },
  name: {
    fontWeight: 500,
    fontSize: 16,
    color: colors.TEXT_H,
  },
  paymentInputsContainer: {},
  input: {},
  id: {
    opacity: 0.4,
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  container: {
    padding: theme.spacing(2),
  },
  wrapper: {
    marginLeft: 'auto!important',
    marginRight: 'auto',
    marginTop: theme.spacing(2),
    display: 'block',
  },
  privateInfoForm: {
    padding: 30,
    minHeight: 130,
    paddingBottom: 24,
    width: '100%',
  },
  badges: {
    minWidth: 95,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  badgeSelect: {
    marginTop: 12,
    width: 80,
  },
  avatarAndInfo: {
    display: 'flex',
  },
}))

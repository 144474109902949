import React from 'react';
import clsx from 'clsx';
import { Paper, Typography, Button, Box, Grid, Hidden, Avatar } from '@material-ui/core';
import { People, LocationOn } from '@material-ui/icons';

import { intl } from 'helpers';
import { useLink } from 'hooks/linkTo';
import { routes } from 'router/routesList';

import { Badge } from '../Badge';
import { useStyles } from './styles';
import { PROFILE_STATUS, VisibilityOptions } from '../../constants';

const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env;

export const CompanyProfileTop = ({ brand = {}, isAdmin = false }) => {
  const styles = useStyles({});
  const linkTo = useLink();
  return (
    <Box>
      <Hidden mdUp>
        <Box className={styles.head}>
          <Typography className={styles.name}>{intl('SHARED.publicInfo').toUpperCase()}</Typography>
          <Button variant="contained" color="primary" onClick={linkTo(`/${routes.BRAND_COMPANY_INFO}`)}>
            {intl('BRAND.PUBLIC_PAGE.PROFILE_TOP.editCompanyInfo')}
          </Button>
        </Box>
      </Hidden>

      <Paper className={styles.paper} elevation={0}>
        {!isAdmin && (
          <Box className={styles.heading}>
            <Box className={styles.nameAndPhotoContainer}>
              <Hidden mdUp>
                <Avatar className={styles.photo} src={REACT_APP_AWSS3_URL_IMAGE_BEG + brand?.image} alt={` ${brand?.firstName} ${brand?.lastName}`}>
                  {brand?.firstName?.[0]} {brand?.lastName?.[0]}
                </Avatar>
              </Hidden>

              <Box>
                <Typography className={styles.name}>{brand.companyName}</Typography>
                <Box className={styles.badges}>
                  <Badge text={brand.verificationStatus || PROFILE_STATUS.notVerified} />
                  <Badge text={brand.isPrivate ? VisibilityOptions[1].label : VisibilityOptions[0].label} />
                </Box>
              </Box>
            </Box>

            <Hidden smDown>
              <Button variant="contained" color="primary" onClick={linkTo(`/${routes.BRAND_COMPANY_INFO}`)}>
                {intl('BRAND.PUBLIC_PAGE.PROFILE_TOP.editCompanyInfo')}
              </Button>
            </Hidden>
          </Box>
        )}
        <Grid container className={clsx(styles.mainInfo, isAdmin && styles.adminMainInfo)}>
          <Grid item xs={12} md={6}>
            <Typography className={clsx(styles.typoWithIcon, styles.iconTypoFirst)}>
              <People className={styles.icon} />
              {brand.industry?.name || intl('SHARED.notFilledYet')}
            </Typography>

            <Typography className={styles.typoWithIcon}>
              <LocationOn className={styles.icon} />
              {brand.country?.name ? `${brand.country?.name}, ${brand.city?.name}` : intl('SHARED.notFilledYet')}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import { LIST_TALENT_OPPORTUNITIES } from 'services/graphql/query/opportunity';
// eslint-disable-next-line import/named
import { getQueryOptionsOpportunities } from '../../helpers';

import type { ListOpportunities } from './types';

export const useBrandOpportunities = (brandId: string) => {
  const queryOptions = getQueryOptionsOpportunities(10, brandId);

  const [getOpportunitiesList, { loading, data: { listTalentOpportunities = { items: [], meta: { total: 0 } } } = {}, fetchMore }] = useLazyQuery(
    LIST_TALENT_OPPORTUNITIES,
    queryOptions,
  ) as ListOpportunities;

  const {
    items: opportunitiesList,
    meta: { total },
  } = listTalentOpportunities;

  useEffect(() => {
    getOpportunitiesList();
  }, []);

  const onShowMore = () => {
    queryOptions.variables.filter.offset = opportunitiesList.length;
    fetchMore(queryOptions);
  };

  return { opportunitiesList, total, loading, onShowMore };
};

import React, { FC } from 'react';
import clsx from 'clsx';
import { Typography, Box, Hidden, ListItem, ListItemIcon, ListItemText, List } from '@material-ui/core';

import { InstagramIcon, FacebookIcon, TwitterIcon } from 'assets/svg-js';
import { getSocialCount, getFormattedCount } from 'helpers';
import { SocialIcon } from 'components/SocialIcon';
import type { Talent } from 'types/talent';

import { useStyles } from './styles';

type Props = {
  talent: Talent;
  onlyConfirmed?: boolean;
  className?: string;
};

const SocialList: FC<Props> = ({ talent, onlyConfirmed, className }) => {
  const styles = useStyles({});

  const emptySocials = !talent?.social || !talent?.social.some(({ accountName }) => accountName);
  const socialCount = talent?.social?.filter(({ accountName }) => accountName)?.length;

  return (
    <Box className={clsx(className)}>
      {!emptySocials && (
        <Hidden xsDown>
          <Box className={styles.social}>
            {getSocialCount(talent?.social, 'instagram', onlyConfirmed) !== null && (
              <Box className={styles.iconWithCount}>
                <SocialIcon icon={InstagramIcon} />
                <Typography className={styles.count}>{getFormattedCount(getSocialCount(talent?.social, 'instagram', onlyConfirmed))}</Typography>
              </Box>
            )}
            {getSocialCount(talent?.social, 'facebook', onlyConfirmed) !== null && (
              <Box className={styles.iconWithCount}>
                <SocialIcon icon={FacebookIcon} />
                <Typography className={styles.count}>{getFormattedCount(getSocialCount(talent?.social, 'facebook', onlyConfirmed))}</Typography>
              </Box>
            )}
            {getSocialCount(talent?.social, 'twitter', onlyConfirmed) !== null && (
              <Box className={styles.iconWithCount}>
                <SocialIcon icon={TwitterIcon} />
                <Typography className={styles.count}>{getFormattedCount(getSocialCount(talent?.social, 'twitter', onlyConfirmed))}</Typography>
              </Box>
            )}
          </Box>
        </Hidden>
      )}
      {!emptySocials && (
        <Hidden smUp>
          <List
            className={clsx(styles.socials, {
              [styles.twoItemsList]: socialCount === 2,
            })}
          >
            {getSocialCount(talent?.social, 'instagram', onlyConfirmed) !== null && (
              <ListItem className={styles.socialsItem}>
                <ListItemIcon className={styles.svgIconBox}>
                  <InstagramIcon className={styles.svgIcon} />
                </ListItemIcon>
                <ListItemText className={styles.socialsItemText}>
                  {getFormattedCount(getSocialCount(talent?.social, 'instagram', onlyConfirmed))}
                </ListItemText>
              </ListItem>
            )}
            {getSocialCount(talent?.social, 'facebook', onlyConfirmed) !== null && (
              <ListItem className={styles.socialsItem}>
                <ListItemIcon className={styles.svgIconBox}>
                  <FacebookIcon className={styles.svgIcon} />
                </ListItemIcon>
                <ListItemText className={styles.socialsItemText}>
                  {getFormattedCount(getSocialCount(talent?.social, 'facebook', onlyConfirmed))}
                </ListItemText>
              </ListItem>
            )}
            {getSocialCount(talent?.social, 'twitter', onlyConfirmed) !== null && (
              <ListItem className={styles.socialsItem}>
                <ListItemIcon className={styles.svgIconBox}>
                  <TwitterIcon className={styles.svgIcon} />
                </ListItemIcon>
                <ListItemText className={styles.socialsItemText}>
                  {getFormattedCount(getSocialCount(talent?.social, 'twitter', onlyConfirmed))}
                </ListItemText>
              </ListItem>
            )}
          </List>
        </Hidden>
      )}
    </Box>
  );
};

export { SocialList };

// @ts-nocheck
import React, {FC, memo, useEffect, useRef} from 'react';
import { nanoid } from 'nanoid';
import { Box, Grid, Typography } from '@material-ui/core';
import { isEqual } from 'lodash';

import { intl } from 'helpers';
import { routes } from 'router/routesList';
import { useLink } from 'hooks/linkTo';

import { CardWrapper } from './CardWrapper';

import { ItemWrapper } from './ItemWrapper';

import { useStyles } from '../styles';

import type { AgreementSentProps } from '../types';

export const AgreementSent: FC<AgreementSentProps> = memo(
  ({ brandsAgreementSent = [], agreementsSentCount, loadMore }) => {
    const linkTo = useLink();
    const styles = useStyles({});

    const renderItems: Array<JSX.Element> = brandsAgreementSent.map(({ brandImage, brandName, opportunityId, opportunityName }) => (
      <ItemWrapper
        styleClass={styles.agreementSentItem}
        brandImage={brandImage}
        key={nanoid()}
        clickHandler={linkTo(`/${routes.TALENT}/${routes.OPPORTUNITIES}/${opportunityId}/details`)}
      >
        <Grid item zeroMinWidth className={styles.blockDescription}>
          <Box className={styles.name}>{brandName}</Box>
          <Typography noWrap className={styles.opportunities}>
            {opportunityName}
          </Typography>
        </Grid>
      </ItemWrapper>
    ));

    return (
      <CardWrapper itemsQuantity={agreementsSentCount} title={intl('TALENT.ANALYTICS.agreementSent')} loadMore={loadMore} tableType="agreement">
        {renderItems}
      </CardWrapper>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps.brandsAgreementSent, nextProps.brandsAgreementSent),
);

import React, { FC } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { useQuery, ApolloCache } from '@apollo/client';
import { useParams, Link, useHistory } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import clsx from 'clsx';

import { intl } from 'helpers/intl';
import { useAuth } from 'hooks/auth';
import { NotFoundImage } from 'assets/svg-js';

import { InfoPost } from 'components/InfoPost';
import { Loader } from 'components/Loader';
import { GET_POST } from 'services/graphql/query/feed';
import type { PostFeed } from 'types/post';
import { UserTypeEnum } from 'types/common';

import { useStyles } from './styles';

const Post: FC = () => {
  const { id } = useParams<{ id: string }>();
  const styles = useStyles();
  const { push } = useHistory();

  const { data, loading, error } = useQuery(GET_POST, { variables: { id } });

  const post = data?.getPost;
  const { getRole } = useAuth();

  const role =
    getRole() === UserTypeEnum.talent || getRole() === UserTypeEnum.agent
      ? UserTypeEnum.talent
      : getRole() === UserTypeEnum.brand || getRole() === UserTypeEnum.manager
      ? UserTypeEnum.brand
      : getRole() === UserTypeEnum.admin
      ? UserTypeEnum.admin
      : 'none';

  const handleSuccessLike = (cache: ApolloCache<any>) => {
    try {
      const { getPost } = cache.readQuery({ query: GET_POST, variables: { id } });

      const entity: PostFeed = cloneDeep(getPost);
      entity.isLiked = !entity.isLiked;
      entity.feedMeta.likesCounter = entity.isLiked ? entity.feedMeta.likesCounter + 1 : entity.feedMeta.likesCounter - 1;

      cache.writeQuery({
        query: GET_POST,
        variables: { id },
        data: {
          getPost: entity,
        },
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  const handleSuccessShare = (cache: ApolloCache<any>) => {
    try {
      const { getPost } = cache.readQuery({ query: GET_POST, variables: { id } });

      const entity: PostFeed = cloneDeep(getPost);
      entity.feedMeta.sharesCounter += 1;

      cache.writeQuery({
        query: GET_POST,
        variables: { id },
        data: {
          getPost: entity,
        },
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  const handleSuccessDelete = () => {
    push('/');
  };

  if (!loading && (error || !post?.id)) {
    return (
      <Box className={styles.container}>
        <Box className={styles.infoBox}>
          <Box className={styles.contentBox}>
            <Typography className={styles.text404}>{intl('EMPTY_STATE.oops')}</Typography>
            <Typography className={styles.txtNotExists}>{intl('EMPTY_STATE.noPost')}</Typography>
            <Button color="primary" variant="contained" className={styles.btnHomepage} component={Link} to="/">
              {intl('EMPTY_STATE.goToHomepage')}
            </Button>
          </Box>
        </Box>
        <NotFoundImage className={styles.icon} />
      </Box>
    );
  }

  return (
    <Box className={clsx(styles.box, { [styles.loaderBox]: loading })}>
      {loading ? (
        <Loader />
      ) : (
        <InfoPost
          userRole={role}
          onSuccessDeleteSingle={handleSuccessDelete}
          onSuccessLike={handleSuccessLike}
          onSuccessShareSingle={handleSuccessShare}
          className={styles.post}
          post={post}
        />
      )}
    </Box>
  );
};

export { Post };

import { makeStyles } from '@material-ui/core';
import { colors } from 'theme/colors';
import { constants } from 'theme/constants';

export const useStyles = makeStyles((theme) => ({
  loader: {
    height: '50vh',
  },
  publicInfo: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  publicInfoFormAndNav: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    height: '100%',
    marginLeft: 24,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  fullWidth: {
    width: '100%',
  },
  publicInfoForm: {
    padding: 30,
    minHeight: 512,
    paddingBottom: 24,
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  publicInfoBlock: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
    '&:first-child': {
      marginBottom: 16,
    },
  },
  input: {
    marginBottom: 28,
    maxWidth: 360,
    // maxWidth: 312,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  about: {
    padding: 32,
    paddingBottom: 44,
    marginBottom: 16,
  },
  video: {
    padding: 32,
    // marginBottom: 16,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  videoItem: {
    maxWidth: 312,
    width: 312,
    height: 186,
    borderRadius: 4,
    marginBottom: 16,

    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  //
  mainInputBlocks: {
    justifyContent: 'space-between',
    marginBottom: 28,

    '&:nth-child(2n)': {
      justifyContent: 'flex-end',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-end',
      '&:nth-child(2n)': {
        justifyContent: 'flex-end',
      },
    },
  },
  buttons: {
    width: 152,
    marginRight: theme.spacing(2),
    '&:last-child': {
      marginRight: 0,
    },
  },
  popupInput: {
    marginTop: theme.spacing(3),
  },
  deleteVideoBtn: {
    position: 'absolute',
    top: theme.spacing(1.5),
    right: theme.spacing(1.5),
    maxWidth: 32,
    height: 32,
    '& svg': {
      fill: colors.PRIMARY_INPUT_HOVERED,
    },
  },
  saveBar: {
    marginTop: 16,
    marginBottom: 16,
    [theme.breakpoints.up(768)]: {
    },
    [theme.breakpoints.down(960)]: {
      paddingLeft: 0,
      marginLeft: 0,
    },
  },
  checkboxWrapper: {
    width: '100%',
    marginTop: 0,
    marginRight: 0,
    marginLeft: -8,
    marginBottom: 20,
  },
  checkboxLabel: {
    fontSize: 14,
  },
  noMarginInput: {
    marginBottom: 0,
  },
  smallPadding: {
    '& .MuiSelect-select.MuiSelect-select': {
      paddingBottom: 1,
    },
  },
  date: {
    [theme.breakpoints.up('sm')]: {
      marginTop: 6,
    },
  },
  saveBtnContainer: {
    position: 'sticky',
    bottom: 0,
    padding: 16,
    paddingLeft: 24,
    marginLeft: 24,
    background: colors.WHITE,
    width: '100%',
    height: constants.SAVE_BAR_HEIGHT,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
    marginTop: theme.spacing(3),
  },
  saveBtn: {
    position: 'absolute',
    right: 24,
    width: 120,
  }
}));

import { makeStyles, Theme } from '@material-ui/core';
import { colors } from 'theme/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  item: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    position: 'relative',
    width: 120,
    height: 120,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    borderRadius: 4,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  zoomBtn: {
    padding: 0,
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    bottom: '36%',
    textAlign: 'center',
  },
  zoomIcon: {
    fontSize: '1.5rem',
    fill: colors.WHITE,
  },
  deleteBtn: {
    padding: 10,
    position: 'absolute',
    right: 12,
    top: 12,
    backgroundColor: colors.WHITE,
    borderRadius: 4,
    '&:hover': {
      backgroundColor: colors.WHITE,
    },
  },
  deleteIcon: {
    fontSize: '1rem',
    fill: colors.PRIMARY_INPUT_HOVERED,
  },
  hidden: {
    display: 'none',
  },
}));

import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'theme/colors';

const useStyles = makeStyles(() => ({
  badge: {
    padding: '3px 12px',
    borderRadius: 4,
    color: '#93988c',
    lineHeight: '18px',
  },
  greenBackground: {
    background: 'rgba(131, 191, 36, 0.2)',
  },
  grayBackground: {
    background: colors.BACKGROUND,
  },
  redBackground: {
    background: 'rgba(255, 81, 81, 0.2)',
  },
}));

export { useStyles };

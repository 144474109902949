import React, { useCallback } from 'react'
import clsx from 'clsx'
import {
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  FormHelperText,
  Box,
  Typography,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { Link } from 'react-router-dom'

import { intl } from '../../helpers'
import { useStyles } from './styles'
import { routes } from '../../router/routesList'

export const TextFieldPassword = ({
  showPassword = false,
  value = '',
  label = '',
  name = 'password',
  helperText = '',
  className = '',
  error = false,
  onChange = () => {},
  togglePassword = () => {},
  withForget,
  ...rest
}) => {
  const styles = useStyles({})

  const handleMouseDownPassword = useCallback((event) => {
    event.preventDefault()
  }, [])

  return (
    <Box className={styles.container}>
      <FormControl error={error} className={className} fullWidth>
        <InputLabel htmlFor="password">{label}</InputLabel>
        <Input
          id="password"
          type={showPassword ? 'text' : 'password'}
          name={name}
          value={value}
          onChange={onChange}
          className={styles.input}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                size="small"
                aria-label="toggle password visibility"
                onClick={togglePassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          {...rest}
        />
        {error && (
          <FormHelperText
            className={error ? styles.helperTextLeft : styles.helperTextRight}
            id="password-helper-text"
          >
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
      {withForget && (
        <Link
          to={`/${routes.RESTORE}`}
          className={clsx(styles.link, error && styles.linkWithError)}
        >
          <Typography className={styles.forgotPassword}>{intl('LOGIN.forgot')}</Typography>
        </Link>
      )}
    </Box>
  )
}

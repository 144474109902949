import { makeStyles } from '@material-ui/core';
import { colors } from 'theme/colors';

export const useStyles = makeStyles((theme) => ({
  cardBlock: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(1),
    },
  },
  btn: {
    marginLeft: 'auto',
  },
  errorText: {
    color: colors.ERROR,
  },
  iconAndTextContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {
    backgroundColor: (props) => (props.error ? colors.LIGHT_ERROR2 : colors.BACKGROUND),
    borderRadius: 4,
    display: 'flex',
    height: 32,
    minWidth: 32,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    '& svg': {
      fill: colors.PRIMARY_INPUT_HOVERED,
    },
  },
  paymentInputsContainer: {
    marginTop: theme.spacing(3),
  },
}));

/* eslint-disable no-underscore-dangle */
import React, { FC } from 'react';
import { ListItem, ListItemAvatar, Box, Typography, Button, Avatar } from '@material-ui/core';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { SportEndorseLogo } from 'assets/svg-js';
import { OpportunityNotification, OfferNotification, NotificationAction, MessageNotification } from 'types/notification';
import { routes } from 'router/routesList';
import { intl } from 'helpers';
import { useAuth } from 'hooks/auth';
import { useNotification } from 'hooks/notification';
import { UserTypeEnum } from 'types/common';

import { useStyles } from './styles';
import type { Props } from './types';
import { getFormattedDate } from './helpers';
import { STATUSES } from '../../constants';

const NotificationItem: FC<Props> = ({ data, className, handleOpenRateModal, handleClose }) => {
  const classes = useStyles();
  const { getRole } = useAuth();
  const { handleReadNotifications } = useNotification();

  let viewLink: string;
  let isPayed: boolean;
  let isRated: boolean;
  let isCanceledByBrand: boolean;
  let isCanceledByTalent: boolean;
  let canRate: boolean;
  let canPay: boolean;
  let avatarURL: string;
  let isOpportunity: boolean;

  const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env;

  const onReadNotification = () => {
    if (!data.readAt) handleReadNotifications([data.id]);
  };

  // TODO refactor the logic when backend will send notification status code instead of message text
  switch (data.__typename) {
    case 'OpportunityNotification': {
      // * role check
      if (getRole() === UserTypeEnum.talent || getRole() === UserTypeEnum.agent) {
        viewLink = `/${routes.TALENT}/${routes.OPPORTUNITIES}/${(data as OpportunityNotification).opportunity.id}/details`;
        if ((data as OpportunityNotification).opportunity.status.name === STATUSES.CANCELED_BY_BRAND.value) {
          viewLink = `/${routes.TALENT}/${routes.OPPORTUNITIES}`;
          isCanceledByBrand = true;
        }
      } else {
        viewLink = `/${routes.BRAND}/${routes.OPPORTUNITIES}/${(data as OpportunityNotification).opportunity.id}/applicants`;
        if ((data as OpportunityNotification).opportunity.status.name === STATUSES.CANCELED_BY_TALENT.value) {
          viewLink = `/${routes.BRAND}/${routes.OPPORTUNITIES}`;
        }
      }
      // * action check
      if ((data as OpportunityNotification).actionCode === NotificationAction.rate) {
        canRate = true;
        viewLink = null;
      }
      if ((data as OpportunityNotification).actionCode === NotificationAction.pay) {
        canPay = true;
        isPayed =
          (data as OpportunityNotification).opportunity.status.name !== STATUSES.CANCELED_BY_ADMIN_PAYMENT.value &&
          (data as OpportunityNotification).opportunity.status.name !== STATUSES.PAYMENT.value;
        viewLink = null;
      }
      if ((data as OpportunityNotification).actionCode === NotificationAction.ratePay) {
        isPayed =
          (data as OpportunityNotification).opportunity.status.name !== STATUSES.CANCELED_BY_ADMIN_PAYMENT.value &&
          (data as OpportunityNotification).opportunity.status.name !== STATUSES.PAYMENT.value;
        canRate = true;
        canPay = true;
        viewLink = null;
      }

      isRated = (data as OpportunityNotification).opportunity.isRated;
      avatarURL = (data as OpportunityNotification).image ?? (data as OpportunityNotification).opportunity?.brand?.name;
      isOpportunity = true;
      break;
    }
    case 'OfferNotification': {
      if (getRole() === UserTypeEnum.talent)
        viewLink = `/${routes.TALENT}/${routes.OPPORTUNITIES}/${(data as OfferNotification).offer.opportunityId}/deal`;
      else viewLink = `/${routes.BRAND}/${routes.OPPORTUNITIES}/${(data as OfferNotification).offer.opportunityId}/applicants`;
      avatarURL = (data as OfferNotification).image;
      break;
    }
    case 'MessageNotification': {
      if (getRole() === UserTypeEnum.talent || getRole() === UserTypeEnum.agent)
        viewLink = `/${routes.TALENT}/${routes.OPPORTUNITIES}/${(data as MessageNotification).opportunityId}/deal`;
      else viewLink = `/${routes.BRAND}/${routes.OPPORTUNITIES}/${(data as MessageNotification).opportunityId}/applicants`;
      break;
    }
    default:
      viewLink = null;
  }

  return (
    <>
      <ListItem className={clsx(classes.item, className)} divider onClick={onReadNotification}>
        <ListItemAvatar>
          <Avatar src={REACT_APP_AWSS3_URL_IMAGE_BEG + avatarURL} className={classes.img}>
            <SportEndorseLogo />
          </Avatar>
        </ListItemAvatar>
        <Box className={classes.content}>
          <Box className={classes.content}>
            <Typography
              variant="body2"
              className={clsx({
                [classes.seen]: !!data.readAt,
              })}
            >
              {data.message}
            </Typography>
            <Typography className={classes.date} variant="caption">
              {getFormattedDate(data.createdAt)}
            </Typography>
          </Box>
          <Box className={classes.actions}>
            {viewLink && (
              <Button component={Link} variant="text" className={classes.btn} to={viewLink} onClick={handleClose}>
                {isCanceledByBrand ? intl('NOTIFICATION.btnLookFor') : intl('NOTIFICATION.btnView')}
              </Button>
            )}
            {isOpportunity && (
              <>
                {canPay && (
                  <Button
                    component={Link}
                    onClick={handleClose}
                    to={`/${routes.BRAND}/${routes.OPPORTUNITIES}/${(data as OpportunityNotification).opportunity.id}/details`}
                    variant="outlined"
                    className={classes.btnPay}
                    disabled={isPayed}
                  >
                    {isPayed ? intl('NOTIFICATION.btnPaid') : intl('NOTIFICATION.btnPay')}
                  </Button>
                )}

                {canRate && (!isCanceledByBrand || !isCanceledByTalent) && (
                  <Button
                    onClick={() => handleOpenRateModal((data as OpportunityNotification).opportunity)}
                    disabled={isRated}
                    variant="text"
                    className={classes.btn}
                  >
                    {isRated ? intl('NOTIFICATION.btnRated') : intl('NOTIFICATION.btnRate')}
                  </Button>
                )}
              </>
            )}
          </Box>
        </Box>
      </ListItem>
    </>
  );
};

export default NotificationItem;

import { gql } from '@apollo/client';

const MARK_NOTIFICATION_AS_READ = gql`
  mutation markNotificationAsRead($data: MarkNotificationAsReadInput) {
    markNotificationAsRead(data: $data) {
      meta {
        totalUnread
      }
    }
  }
`;

export { MARK_NOTIFICATION_AS_READ };

import React, { FC, useState } from 'react';
import { Box, Grid, Hidden, Theme, useMediaQuery } from '@material-ui/core';
import { Switch, useLocation } from 'react-router-dom';

import { getLetterForAvatar } from 'helpers';
import { constants } from 'theme/constants';
import { useQuery } from '@apollo/client';
import { GET_CURRENT_TALENT } from 'services/graphql/talent';
import { routes } from 'router/routesList';
import { PrivateRoute } from 'router/PrivateRoute';

import { verificationStatusEnum } from 'types/common';
import { useAuth } from 'hooks/auth';

import VerifyMePanel from 'components/VerifyMePanel';
import { Wrapper } from 'components/Wrapper';
import { ProfileCard } from 'components/ProfileCard';

import { PublicPage } from '../PublicPage';
import { PublicInfo } from '../PublicInfo';
import { ActivityFeed } from '../ActivityFeed';
import { PrivateInfo } from '../PrivateInfo';
import { PaymentHistory } from '../PaymentHistory';
import { TalentAnalytics } from '../TalentAnalytics';

import { useStyles } from './styles';

import type { Props } from './types';

const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env;

export const TalentProfile: FC<Props> = ({ location }) => {
  const [imageKey, setImageKey] = useState(null);
  const { getRole } = useAuth();
  const styles = useStyles({});
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const { pathname } = useLocation();
  const editDisabled = pathname !== routes.TALENT_PUBLIC_INFO;

  const { data, loading: talentLoading } = useQuery(GET_CURRENT_TALENT, {
    fetchPolicy: 'network-only',
  });

  const isAgent: boolean = getRole() === 'agent';
  const displayVerifyPanel = (!talentLoading && data?.getCurrentTalent?.verificationStatus !== verificationStatusEnum.verified) || !isAgent;

  const handleSetImageKey = (key: string): void => setImageKey(key);

  return (
    <>
      {displayVerifyPanel && <VerifyMePanel verificationStatus={data?.getCurrentTalent?.verificationStatus} />}
      <Wrapper additionalContentHeight={isSm ? constants.SAVE_BAR_HEIGHT_MOBILE : constants.SAVE_BAR_HEIGHT}>
        <Box className={styles.profileContainer}>
          <Hidden smDown>
            {/* @ts-ignore */}
            <ProfileCard
              className={styles.profileCard}
              avatarDisabled={editDisabled}
              imgSrc={data?.getCurrentTalent?.image}
              imgAlt={getLetterForAvatar(data?.getCurrentTalent?.firstName, data?.getCurrentTalent?.lastName)}
              handleImageChange={handleSetImageKey}
              isSignUp={location?.state?.fromSignUp}
            />
          </Hidden>
          <Grid container direction="column">
            <Switch>
              <PrivateRoute exact path={routes.TALENT_PUBLIC_PAGE} component={PublicPage} />
              <PrivateRoute
                exact
                path={`${routes.TALENT_PUBLIC_INFO}/:provider?`}
                component={PublicInfo}
                componentProps={{ handleSetImageKey, imageKey }}
              />
              <PrivateRoute path={routes.TALENT_PRIVATE_INFO} component={PrivateInfo} />
              <PrivateRoute path={routes.TALENT_PAYMENT_HISTORY} component={PaymentHistory} />
              {/* <PrivateRoute path={routes.TALENT_ACTIVITY_FEED} component={ActivityFeed} /> */}
              <PrivateRoute path={routes.TALENT_ANALYTICS} component={TalentAnalytics} />
            </Switch>
          </Grid>
        </Box>
      </Wrapper>
    </>
  );
};

import { gql } from '@apollo/client';

const CREATE_TRANSACTION = gql`
  mutation CreateTransaction($data: CreateStripeTransactionInput) {
    createTransaction(data: $data) {
      id
      object
      amount
      authorization
      balanceTransaction
      card
      cardholder
      created
      currency
      livemode
      merchantAmount
      merchantCurrency
      type
      status
    }
  }
`;

const CREATE_PAYMENT = gql`
  mutation CreatePayment($data: CreateStripeTransactionInput) {
    createPayment(data: $data) {
      id
      payment_intent_id
      status
      next_action {
        type
        use_stripe_sdk {
          type
          stripe_js
          source
          known_frame_issues
        }
        redirect_to_url {
          return_url
          url
        }
      }
    }
  }
`;

const GET_PAYMENT_HISTORY = gql`
  query GetPaymentHistory($pagination: PaginationFilter, $filter: DateFilter) {
    getPaymentHistory(pagination: $pagination, filter: $filter) {
      items {
        opportunityId
        opportunityName
        opportunityStatus
        performer {
          performerId
          performerName
        }
        method
        date
        amount {
          currencyCode
          amount
        }
      }
      meta {
        total
      }
      paymentHistoryCSV
    }
  }
`;

const ADMIN_GET_PAYMENT_HISTORY = gql`
  query AdminGetPaymentHistory($pagination: PaginationFilter, $talentOrBrandId: ID!, $filter: DateFilter) {
    adminGetPaymentHistory(pagination: $pagination, talentOrBrandId: $talentOrBrandId, filter: $filter) {
      items {
        opportunityId
        opportunityName
        opportunityStatus
        performer {
          performerId
          performerName
        }
        method
        date
        amount {
          currencyCode
          amount
        }
      }
      meta {
        total
      }
      paymentHistoryCSV
    }
  }
`;

const CONFIRM_WIRE_PAYMENT = gql`
  mutation ConfirmWirePayment($data: ConfirmWirePaymentInput!) {
    confirmWirePayment(data: $data) {
      opportunityId
      offerId
      offerAmount
    }
  }
`;

export { CREATE_TRANSACTION, GET_PAYMENT_HISTORY, ADMIN_GET_PAYMENT_HISTORY, CONFIRM_WIRE_PAYMENT, CREATE_PAYMENT };

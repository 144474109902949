const DETAILS = {
  label: 'OPPORTUNITY.details',
  value: 0,
}

const TARGET = {
  label: 'OPPORTUNITY.target',
  value: 1,
}

const TABS = {
  DETAILS,
  TARGET,
}

export { TABS }

import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Box } from '@material-ui/core';

import { GET_BRAND_BY_ID } from 'services/graphql/brand';

import { Wrapper } from 'components/Wrapper';
import { Loader } from 'components/Loader';
import { ProfileBar } from './components/ProfileBar';
import { BrandInfo } from './components/BrandInfo';
import { BrandOpportunities } from './components/BrandsOpportunities';
import { ActivityFeeds } from './components/ActivitiFeeds/ActivityFeeds';

import type { GetBrandById } from 'types/brand';

import { useStyles } from './styles';

type BrandInfo = {
  data: {
    getBrand: GetBrandById;
  };
  loading: boolean;
};

export const BrandsPage: FC<{}> = () => {
  const styles = useStyles();
  const { id, tab } = useParams();

  const { data: { getBrand = {} } = {}, loading } = useQuery(GET_BRAND_BY_ID, { variables: { brandId: id } }) as BrandInfo;

  if (loading) {
    return <Loader />;
  }
  // console.log('----', getBrand)
  return (
    <Wrapper className={styles.brandsPageWrapper}>
      <ProfileBar brand={getBrand} tab={tab} />
      <Box className={styles.contentContainer}>
        {tab === 'info' && <BrandInfo brand={getBrand} />}
        {tab === 'opportunities' && <BrandOpportunities brandId={getBrand.id} />}
        {/*{tab === 'activity-feed' && <ActivityFeeds />}*/}
      </Box>
    </Wrapper>
  );
};

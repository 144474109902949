import React from 'react';
import { Paper, Toolbar, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { useQuery } from '@apollo/client';

import { intl } from 'helpers';
import { GET_BRAND_ANALYTIC } from 'services/graphql/analytics';

import { Loader } from 'components/Loader';

import { useStyles } from './styles';

export const BrandProfileAnalytics = () => {
  const classes = useStyles({});
  const { data, loading } = useQuery(GET_BRAND_ANALYTIC);
  return (
    <Paper className={classes.paper}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Toolbar className={classes.toolbar}>{intl('SHARED.opportunities')}</Toolbar>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{intl('COMMON_ANALYTICS.typesAndStatuses')}</TableCell>
                  <TableCell align="right">{intl('COMMON_ANALYTICS.amount')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{intl('BRAND_PROFILE_ANALYTICS.postedOpportunities')}</TableCell>
                  <TableCell align="right">{data?.getBrandAnalytic.postedOpportunities}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{intl('BRAND_PROFILE_ANALYTICS.applicants')}</TableCell>
                  <TableCell align="right">{data?.getBrandAnalytic.totalApplicants}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{intl('BRAND_PROFILE_ANALYTICS.accepted')}</TableCell>
                  <TableCell align="right">{data?.getBrandAnalytic.acceptedApplicants}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{intl('BRAND_PROFILE_ANALYTICS.declined')}</TableCell>
                  <TableCell align="right">{data?.getBrandAnalytic.declinedApplicants}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{intl('COMMON_ANALYTICS.cancelledByTalent')}</TableCell>
                  <TableCell align="right">{data?.getBrandAnalytic.canceledByTalentOpportunities}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{intl('COMMON_ANALYTICS.cancelledByBrand')}</TableCell>
                  <TableCell align="right">{data?.getBrandAnalytic.canceledByBrandOpportunities}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Paper>
  );
};

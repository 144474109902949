import React from 'react';
import { Typography, Box, Button } from '@material-ui/core';
import clsx from 'clsx';

import { QuestionMark as QuestionMarkIcon } from 'assets/svg-js';
import { intl } from 'helpers';
import { Modal } from 'components/Modal';

import { useStyles } from './styles';

const ConfirmModal = ({ open = false, loading, handleClose = () => {}, signOut }) => {
  const classes = useStyles();

  const Actions = () => (
    <Box>
      <Button variant="outlined" disabled={loading} onClick={handleClose} className={clsx(classes.buttons, classes.btnSmallText)}>
        {intl('SHARED.no')}
      </Button>
      <Button onClick={signOut} variant="contained" disabled={loading} className={clsx(classes.buttons, classes.btnSmallText)} color="primary">
        {intl('SHARED.yes')}
      </Button>
    </Box>
  );

  return (
    <Modal isOpen={open} title={null} disabledBorder handleClose={handleClose} className={classes.modal} dialogActionsComponent={<Actions />}>
      <Box className={classes.container}>
        <Box className={classes.modalIconBox}>
          <QuestionMarkIcon />
        </Box>
        <Typography className={classes.text}>{intl('CALENDAR.confirmModal')}</Typography>
      </Box>
    </Modal>
  );
};

export default ConfirmModal;

import React, { useEffect } from 'react';
import {
  AppBar,
  Typography,
  Divider,
  Button,
  Box,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
  IconButton,
  Hidden,
  Chip,
  InputAdornment,
} from '@material-ui/core';
import { Euro as EuroIcon, CloseRounded, ClearRounded } from '@material-ui/icons';
import { useQuery } from '@apollo/client';
import { Autocomplete } from '@material-ui/lab';
import { DatePicker } from '@material-ui/pickers';
import { useFormik } from 'formik';
import clsx from 'clsx';
import intersectionWith from 'lodash/intersectionWith';
import isEqual from 'lodash/isEqual';

import { intl, omit } from 'helpers';
import { useAuth } from 'hooks/auth';
import { LIST_COUNTRIES, LIST_CITIES } from 'services/graphql/location';
import { OPPORTUNITY_TYPES, GET_OPPORTUNITY_STATUSES } from 'services/graphql/query/opportunity';
import { UserTypeEnum } from 'types/common';

import { FilterTextField } from 'components/FilterTextField';
import { Loader } from 'components/Loader';

import { useStyles } from './styles';

export const OpportunityFilters = ({
  className = '',
  // handleClear,
  handleClose = () => {},
  handleApply,
  filters,
}) => {
  const styles = useStyles({});
  const { getRole } = useAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isAdmin = getRole() === UserTypeEnum.admin;

  const { values, handleChange, setFieldValue, setValues } = useFormik({
    initialValues: {
      country: filters?.country || [],
      city: filters?.city || [],
      budgetFrom: filters?.budgetFrom || '',
      budgetTo: filters?.budgetTo || '',
      startDate: filters?.startDate,
      endDate: filters?.endDate,
      typeId: filters?.typeId || [],
      status: filters?.status || [],
    },
    enableReinitialize: true,
  });

  const { data: countries, loading: countriesLoading } = useQuery(LIST_COUNTRIES);
  const { data: opportunityTypes, loading: opportunityTypesLoading } = useQuery(OPPORTUNITY_TYPES);
  const { data: cities, loading: citiesLoading } = useQuery(LIST_CITIES, {
    skip: !values?.country.length,
    variables: {
      countryIds: values?.country && values?.country?.map((country) => country.id),
    },
  });

  const { data: statuses, loading: statusesLoading } = useQuery(GET_OPPORTUNITY_STATUSES);

  const handleAutocompleteChange = (name) => (_, value) => setFieldValue(name, value);

  useEffect(() => {
    if (cities) {
      const filteredCities = intersectionWith(values.city, cities.listCities, isEqual);
      setFieldValue('city', filteredCities);
    } else if (!values.country.length) {
      setFieldValue('city', []);
    }
  }, [cities]);

  const handleDateChange = (field) => (date) => setFieldValue(field, new Date(date));

  const handleClear = (section) => {
    if (!values) return;

    if (section === 'all') {
      handleApply({});
      setValues({
        country: filters?.country || [],
        city: filters?.city || [],
        budgetFrom: filters?.budgetFrom || '',
        budgetTo: filters?.budgetTo || '',
        startDate: filters?.startDate,
        endDate: filters?.endDate,
        typeId: filters?.typeId || [],
      });
    }

    if (section === 'location') {
      setValues({ ...values, country: [], city: [] });
      handleApply(omit(values, ['country', 'city']));
    }
    if (section === 'budget') {
      setValues({ ...values, budgetFrom: '', budgetTo: '' });
      handleApply(omit(values, ['budgetFrom', 'budgetTo']));
    }
    if (section === 'date') {
      setValues({ ...values, startDate: null, endDate: null });
      handleApply(omit(values, ['startDate', 'endDate']));
    }
    if (section === 'type') {
      setValues({ ...values, typeId: [] });
      handleApply(omit(values, ['typeId']));
    }
    if (section === 'status') {
      setValues({ ...values, status: [] });
      handleApply(omit(values, ['status']));
    }
  };

  const checkDisabledClear = {
    location: values?.country?.length || values?.city?.length,
    budget: values?.budgetFrom || values?.budgetTo,
    date: values?.startDate || values?.endDate,
    type: values?.typeId?.length,
    status: values?.status?.length,
  };

  const disabledClear = Object.values(checkDisabledClear).some((i) => i);

  const chipRenderer = (value, getTagProps) =>
    value.map((option, index) => (
      <Chip
        {...getTagProps({ index })}
        className={styles.chip}
        key={option.id}
        label={option.name}
        deleteIcon={
          <IconButton styles={{ label: styles.deleteIconLabel }} className={styles.deleteIcon}>
            <ClearRounded className={styles.icon} />
          </IconButton>
        }
      />
    ));

  return (
    <AppBar position={isMobile ? 'static' : 'sticky'} color="transparent" component="header" className={clsx(styles.profileCard, className)}>
      <Box className={styles.topBar}>
        <Typography className={styles.filters}>{intl('TALENT_OPPORTUNITY_FILTERS.filters')}</Typography>
        <Hidden mdUp>
          <IconButton onClick={handleClose} size="small">
            <CloseRounded />
          </IconButton>
        </Hidden>
      </Box>

      <Divider />
      <Box className={styles.mainBlock}>
        <Box className={styles.location}>
          <Box className={styles.clearBlock}>
            <Typography>{intl('BRAND_TALENT_FILTERS.location')}</Typography>
            <Button disabled={!checkDisabledClear.location} onClick={() => handleClear('location')} className={styles.clearBtn}>
              {intl('BRAND_TALENT_FILTERS.clear')}
            </Button>
          </Box>
          <Autocomplete
            className={styles.input}
            loading={countriesLoading}
            options={countries ? countries.listCountries : []}
            value={values?.country}
            multiple
            onChange={handleAutocompleteChange('country')}
            getOptionLabel={(option) => option.name || ''}
            getOptionSelected={(option, value) => option.id === value.id}
            renderTags={chipRenderer}
            renderInput={(params) => <FilterTextField {...params} placeholder={intl('BRAND_TALENT_FILTERS.allCountries')} name="country" />}
          />
          <Autocomplete
            className={styles.input}
            loading={citiesLoading}
            disabled={!values?.country?.length}
            options={cities ? cities.listCities : []}
            value={values?.city}
            multiple
            onChange={handleAutocompleteChange('city')}
            getOptionLabel={(option) => option.name || ''}
            getOptionSelected={(option, value) => option.id === value.id}
            renderTags={chipRenderer}
            renderInput={(params) => <FilterTextField {...params} placeholder={intl('BRAND_TALENT_FILTERS.allCities')} name="city" />}
          />
        </Box>

        {isAdmin && (
          <Box className={styles.status}>
            <Box className={styles.clearBlock}>
              <Typography className={styles.opportunitySectionTitle}>{intl('ADMIN_OPPORTUNITIES_FILTERS.status')}</Typography>
              <Button onClick={() => handleClear('status')} disabled={!checkDisabledClear.status} className={styles.clearBtn}>
                {intl('TALENT_OPPORTUNITY_FILTERS.clear')}
              </Button>
            </Box>
            <Autocomplete
              className={styles.input}
              loading={statusesLoading}
              options={statuses ? statuses.opportunityStatuses : []}
              value={values?.status}
              multiple
              onChange={handleAutocompleteChange('status')}
              getOptionLabel={(option) => option.name || ''}
              getOptionSelected={(option, value) => option.id === value.id}
              renderTags={chipRenderer}
              renderInput={(params) => <FilterTextField {...params} placeholder={intl('ADMIN_OPPORTUNITIES_FILTERS.allStatuses')} name="status" />}
            />
          </Box>
        )}

        <Box className={styles.location}>
          <Box className={styles.clearBlock}>
            <Typography className={styles.opportunitySectionTitle}>{intl('TALENT_OPPORTUNITY_FILTERS.date')}</Typography>
            <Button onClick={() => handleClear('date')} disabled={!checkDisabledClear.date} className={styles.clearBtn}>
              {intl('TALENT_OPPORTUNITY_FILTERS.clear')}
            </Button>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DatePicker
                format="dd.MM.yy"
                name="startDate"
                maxDate={values.endDate}
                value={values.startDate || null}
                TextFieldComponent={(params) => (
                  <FilterTextField inputClassName={styles.centered} {...params} placeholder={intl('TALENT_OPPORTUNITY_FILTERS.from')} name="from" />
                )}
                onChange={handleDateChange('startDate')}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                format="dd.MM.yy"
                name="endDate"
                minDate={values.startDate}
                value={values.endDate || null}
                TextFieldComponent={(params) => (
                  <FilterTextField inputClassName={styles.centered} {...params} placeholder={intl('TALENT_OPPORTUNITY_FILTERS.to')} name="to" />
                )}
                onChange={handleDateChange('endDate')}
                fullWidth
                required
              />
            </Grid>
          </Grid>
        </Box>
        <Box className={styles.location}>
          <Box className={styles.clearBlock}>
            <Typography className={styles.opportunitySectionTitle}>{intl('TALENT_OPPORTUNITY_FILTERS.budget')}</Typography>
            <Button onClick={() => handleClear('budget')} disabled={!checkDisabledClear.budget} className={styles.clearBtn}>
              {intl('TALENT_OPPORTUNITY_FILTERS.clear')}
            </Button>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FilterTextField
                onChange={handleChange}
                fullWidth
                noWrap
                value={values?.budgetFrom}
                name="budgetFrom"
                inputClassName={styles.budgetInput}
                placeholder={intl('TALENT_OPPORTUNITY_FILTERS.from')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" classes={{ positionStart: styles.currency }}>
                      <EuroIcon className={styles.currencyIcon} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FilterTextField
                onChange={handleChange}
                fullWidth
                noWrap
                value={values?.budgetTo}
                inputClassName={styles.budgetInput}
                placeholder={intl('TALENT_OPPORTUNITY_FILTERS.to')}
                name="budgetTo"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" classes={{ positionStart: styles.currency }}>
                      <EuroIcon className={styles.currencyIcon} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Box className={styles.clearBlock}>
          <Typography className={styles.opportunitySectionTitle}>{intl('TALENT_OPPORTUNITY_FILTERS.opportunityType')}</Typography>
          <Button onClick={() => handleClear('type')} disabled={!checkDisabledClear.type} className={styles.clearBtn}>
            {intl('TALENT_OPPORTUNITY_FILTERS.clear')}
          </Button>
        </Box>

        {opportunityTypesLoading ? (
          <Loader />
        ) : (
          <FormGroup className={styles.opportunitySectionTitle}>
            {opportunityTypes?.opportunityTypes?.map((opType) => (
              <FormControlLabel
                key={opType.id}
                styles={{
                  label: styles.checkboxLabel,
                }}
                control={
                  <Checkbox
                    color="primary"
                    checked={values?.typeId?.includes(opType.id)}
                    onChange={() => {
                      if (!values?.typeId?.includes(opType.id)) {
                        setFieldValue('typeId', [...values.typeId, opType.id]);
                      } else {
                        setFieldValue(
                          'typeId',
                          values.typeId.filter((opTypeId) => opTypeId !== opType.id),
                        );
                      }
                    }}
                    name={opType.id}
                  />
                }
                label={opType.name}
              />
            ))}
          </FormGroup>
        )}
      </Box>
      <Divider />
      <Grid container spacing={2} className={styles.clearFilters}>
        <Grid item container justify="flex-end" xs={6} className={styles.fullHeight}>
          <Button onClick={() => handleClear('all')} variant="outlined" className={styles.fullHeight} disabled={!disabledClear}>
            {intl('TALENT_OPPORTUNITY_FILTERS.clear')}
          </Button>
        </Grid>
        <Grid item container justify="flex-start" xs={6} className={styles.fullHeight}>
          <Button variant="contained" color="primary" onClick={() => handleApply(values, true)} className={styles.fullHeight}>
            {intl('TALENT_OPPORTUNITY_FILTERS.apply')}
          </Button>
        </Grid>
      </Grid>
    </AppBar>
  );
};

import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  profileInfo: {
    marginTop: 24,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  profileCard: {
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
  profileInfoFormAndNav: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
    marginLeft: 24,
    '@media (max-width: 768px)': {
      marginLeft: 0,
    },
  },
  profileInfoForm: {
    padding: 30,
    minHeight: 200,
    paddingBottom: 44,
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  input: {
    marginBottom: 28,

    maxWidth: 312,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
}))

import { makeStyles, darken } from '@material-ui/core/styles'
import { colors } from '../../../../../theme/colors'

export const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      width: 720,
    },
    [theme.breakpoints.down('md')]: {
      width: '90%',
      margin: theme.spacing(1),
    },
  },
  titleBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 12,
    paddingLeft: theme.spacing(4),
    paddingBottom: 12,
    paddingRight: theme.spacing(4),
  },
  closeIcon: {
    fill: '#535E83',
  },
  description: {
    fontSize: 14,
  },
  divider: {
    marginBottom: theme.spacing(3),
  },
  content: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  buttonContainer: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  btnSend: {
    paddingLeft: 46,
    paddingRight: 46,
    backgroundColor: colors.PRIMARY,
    color: colors.WHITE,
    '&:hover': {
      backgroundColor: `${darken(colors.PRIMARY, 0.2)}`,
    },
  },
}))

import React from 'react';

const EuroCardList = ({ className }) => {
  return (
    <svg width="316" height="316" className={className} viewBox="0 0 316 316" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="158" cy="158" r="158" fill="#F6F6F6" />
      <rect x="35" y="74" width="246" height="60" rx="8" fill="white" />
      <path d="M38 81C38 78.7909 39.7909 77 42 77H92V131H42C39.7909 131 38 129.209 38 127V81Z" fill="#757E9C" />
      <rect x="120" y="94" width="101" height="9" rx="4.5" fill="#F0F0F2" />
      <rect x="120" y="94" width="101" height="9" rx="4.5" fill="#535E83" />
      <rect x="164" y="108" width="57" height="9" rx="4.5" fill="#757E9C" />
      <path
        d="M256.25 115.375C251.857 115.375 248.06 112.89 246.17 109.25H255.165C255.83 109.25 256.442 108.882 256.722 108.288C257.3 107.132 256.46 105.75 255.165 105.75H245.015C244.927 105.173 244.875 104.595 244.875 104C244.875 103.405 244.927 102.828 245.015 102.25H255.165C255.83 102.25 256.442 101.882 256.722 101.287C257.317 100.115 256.477 98.75 255.165 98.75H246.17C248.06 95.11 251.875 92.625 256.25 92.625C258.437 92.625 260.485 93.255 262.235 94.3225C263.11 94.865 264.247 94.7775 264.982 94.0425C265.997 93.0275 265.77 91.365 264.545 90.6125C262.13 89.125 259.277 88.25 256.25 88.25C249.39 88.25 243.58 92.6425 241.41 98.75H236.335C235.67 98.75 235.057 99.1175 234.76 99.7125C234.182 100.885 235.022 102.25 236.335 102.25H240.605C240.535 102.828 240.5 103.405 240.5 104C240.5 104.595 240.535 105.173 240.605 105.75H236.335C235.67 105.75 235.057 106.118 234.777 106.712C234.182 107.885 235.022 109.25 236.335 109.25H241.41C243.58 115.358 249.39 119.75 256.25 119.75C259.295 119.75 262.13 118.892 264.545 117.387C265.752 116.635 265.98 114.955 264.965 113.94C264.23 113.205 263.092 113.118 262.217 113.678C260.485 114.763 258.455 115.375 256.25 115.375Z"
        fill="#757E9C"
      />
      <path
        d="M64.6667 87C55.4667 87 48 94.4667 48 103.667C48 112.867 55.4667 120.333 64.6667 120.333C73.8667 120.333 81.3333 112.867 81.3333 103.667C81.3333 94.4667 73.8667 87 64.6667 87ZM64.6667 117C57.3167 117 51.3333 111.017 51.3333 103.667C51.3333 96.3167 57.3167 90.3333 64.6667 90.3333C72.0167 90.3333 78 96.3167 78 103.667C78 111.017 72.0167 117 64.6667 117ZM71.1333 97.4833L61.3333 107.283L58.2 104.15C57.55 103.5 56.5 103.5 55.85 104.15C55.2 104.8 55.2 105.85 55.85 106.5L60.1667 110.817C60.8167 111.467 61.8667 111.467 62.5167 110.817L73.5 99.8333C74.15 99.1833 74.15 98.1333 73.5 97.4833C72.85 96.8333 71.7833 96.8333 71.1333 97.4833Z"
        fill="white"
      />
      <rect x="35" y="144" width="246" height="60" rx="8" fill="white" />
      <path d="M38 151C38 148.791 39.7909 147 42 147H92V201H42C39.7909 201 38 199.209 38 197V151Z" fill="#757E9C" />
      <rect x="120" y="164" width="101" height="9" rx="4.5" fill="#F0F0F2" />
      <rect x="120" y="164" width="101" height="9" rx="4.5" fill="#535E83" />
      <rect x="164" y="178" width="57" height="9" rx="4.5" fill="#757E9C" />
      <path
        d="M256.25 185.375C251.857 185.375 248.06 182.89 246.17 179.25H255.165C255.83 179.25 256.442 178.882 256.722 178.288C257.3 177.132 256.46 175.75 255.165 175.75H245.015C244.927 175.173 244.875 174.595 244.875 174C244.875 173.405 244.927 172.828 245.015 172.25H255.165C255.83 172.25 256.442 171.882 256.722 171.287C257.317 170.115 256.477 168.75 255.165 168.75H246.17C248.06 165.11 251.875 162.625 256.25 162.625C258.437 162.625 260.485 163.255 262.235 164.322C263.11 164.865 264.247 164.777 264.982 164.043C265.997 163.028 265.77 161.365 264.545 160.612C262.13 159.125 259.277 158.25 256.25 158.25C249.39 158.25 243.58 162.642 241.41 168.75H236.335C235.67 168.75 235.057 169.118 234.76 169.713C234.182 170.885 235.022 172.25 236.335 172.25H240.605C240.535 172.828 240.5 173.405 240.5 174C240.5 174.595 240.535 175.173 240.605 175.75H236.335C235.67 175.75 235.057 176.118 234.777 176.712C234.182 177.885 235.022 179.25 236.335 179.25H241.41C243.58 185.358 249.39 189.75 256.25 189.75C259.295 189.75 262.13 188.892 264.545 187.387C265.752 186.635 265.98 184.955 264.965 183.94C264.23 183.205 263.092 183.118 262.217 183.678C260.485 184.763 258.455 185.375 256.25 185.375Z"
        fill="#757E9C"
      />
      <path
        d="M64.6667 157C55.4667 157 48 164.467 48 173.667C48 182.867 55.4667 190.333 64.6667 190.333C73.8667 190.333 81.3333 182.867 81.3333 173.667C81.3333 164.467 73.8667 157 64.6667 157ZM64.6667 187C57.3167 187 51.3333 181.017 51.3333 173.667C51.3333 166.317 57.3167 160.333 64.6667 160.333C72.0167 160.333 78 166.317 78 173.667C78 181.017 72.0167 187 64.6667 187ZM71.1333 167.483L61.3333 177.283L58.2 174.15C57.55 173.5 56.5 173.5 55.85 174.15C55.2 174.8 55.2 175.85 55.85 176.5L60.1667 180.817C60.8167 181.467 61.8667 181.467 62.5167 180.817L73.5 169.833C74.15 169.183 74.15 168.133 73.5 167.483C72.85 166.833 71.7833 166.833 71.1333 167.483Z"
        fill="white"
      />
      <rect x="35" y="214" width="246" height="60" rx="8" fill="white" />
      <path d="M38 221C38 218.791 39.7909 217 42 217H92V271H42C39.7909 271 38 269.209 38 267V221Z" fill="#757E9C" />
      <rect x="120" y="234" width="101" height="9" rx="4.5" fill="#F0F0F2" />
      <rect x="120" y="234" width="101" height="9" rx="4.5" fill="#535E83" />
      <rect x="164" y="248" width="57" height="9" rx="4.5" fill="#757E9C" />
      <path
        d="M256.25 255.375C251.857 255.375 248.06 252.89 246.17 249.25H255.165C255.83 249.25 256.442 248.882 256.722 248.288C257.3 247.132 256.46 245.75 255.165 245.75H245.015C244.927 245.173 244.875 244.595 244.875 244C244.875 243.405 244.927 242.828 245.015 242.25H255.165C255.83 242.25 256.442 241.882 256.722 241.287C257.317 240.115 256.477 238.75 255.165 238.75H246.17C248.06 235.11 251.875 232.625 256.25 232.625C258.437 232.625 260.485 233.255 262.235 234.322C263.11 234.865 264.247 234.777 264.982 234.043C265.997 233.028 265.77 231.365 264.545 230.612C262.13 229.125 259.277 228.25 256.25 228.25C249.39 228.25 243.58 232.642 241.41 238.75H236.335C235.67 238.75 235.057 239.118 234.76 239.713C234.182 240.885 235.022 242.25 236.335 242.25H240.605C240.535 242.828 240.5 243.405 240.5 244C240.5 244.595 240.535 245.173 240.605 245.75H236.335C235.67 245.75 235.057 246.118 234.777 246.712C234.182 247.885 235.022 249.25 236.335 249.25H241.41C243.58 255.358 249.39 259.75 256.25 259.75C259.295 259.75 262.13 258.892 264.545 257.387C265.752 256.635 265.98 254.955 264.965 253.94C264.23 253.205 263.092 253.118 262.217 253.678C260.485 254.763 258.455 255.375 256.25 255.375Z"
        fill="#757E9C"
      />
      <path
        d="M64.6667 227C55.4667 227 48 234.467 48 243.667C48 252.867 55.4667 260.333 64.6667 260.333C73.8667 260.333 81.3333 252.867 81.3333 243.667C81.3333 234.467 73.8667 227 64.6667 227ZM64.6667 257C57.3167 257 51.3333 251.017 51.3333 243.667C51.3333 236.317 57.3167 230.333 64.6667 230.333C72.0167 230.333 78 236.317 78 243.667C78 251.017 72.0167 257 64.6667 257ZM71.1333 237.483L61.3333 247.283L58.2 244.15C57.55 243.5 56.5 243.5 55.85 244.15C55.2 244.8 55.2 245.85 55.85 246.5L60.1667 250.817C60.8167 251.467 61.8667 251.467 62.5167 250.817L73.5 239.833C74.15 239.183 74.15 238.133 73.5 237.483C72.85 236.833 71.7833 236.833 71.1333 237.483Z"
        fill="white"
      />
    </svg>
  );
};

export { EuroCardList };

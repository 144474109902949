import { useState, useRef } from 'react';
import { useQuery } from '@apollo/client';

import { GET_TALENT_ANALYTICS } from 'services/graphql/talent';

import { GetTalentAnalytic, UseTalentAnalytics, TableType } from './types';

// Define variables for updateQuery
const getUpdateQueryVariables = (tableType: string): { cardItems: string; itemsCount: string } => {
  // eslint-disable-next-line default-case
  switch (tableType) {
    case TableType.worked:
      return {
        cardItems: 'brandsWorkedFor',
        itemsCount: 'brandsWorkedForCount',
      };
    case TableType.agreement:
      return {
        cardItems: 'brandsAgreementSent',
        itemsCount: 'agreementsSentCount',
      };
    case TableType.viewed:
      return {
        cardItems: 'brandsTalentProfileViewed',
        itemsCount: 'profileViewsCount',
      };
  }
};

export const useTalentAnalytics = (): UseTalentAnalytics => {
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
  const [currentFocus, setCurrentFocus] = useState(null);
  const [offset, setOffset] = useState(10);
  const isAnalyticFetched = useRef(false);

  // TODO: add type for fetchMore
  const { data = { getTalentAnalytic: {} }, loading, fetchMore }: { data: GetTalentAnalytic; loading: boolean; fetchMore: any } = useQuery(
    GET_TALENT_ANALYTICS,
    {
      variables: {
        filter: {
          dateFrom: +new Date(dateRange.startDate),
          dateTo: +new Date(dateRange.endDate),
        },
      },
      onCompleted: () => (isAnalyticFetched.current = true),
      skip: isAnalyticFetched.current && dateRange.startDate && !dateRange.endDate,
    },
  );

  const LIMIT = 10;

  const { getTalentAnalytic = {} } = data as GetTalentAnalytic;
  const {
    brandsWorkedFor = [],
    brandsWorkedForCount,
    brandsAgreementSent = [],
    agreementsSentCount,
    brandsTalentProfileViewed = [],
    profileViewsCount,
    revenueDoneOpportunities,
    revenueCanceledOpportunities,
    revenueAveragePerOpportunity,
  } = getTalentAnalytic;

  const loadMore = (tableType: string) => {
    const { cardItems, itemsCount } = getUpdateQueryVariables(tableType);
    if (data.getTalentAnalytic[itemsCount] === data.getTalentAnalytic[cardItems].length) {
      return;
    }

    fetchMore({
      variables: {
        pagination: {
          limit: LIMIT,
          offset,
        },
        tableType,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setOffset(data.getTalentAnalytic[cardItems].length);
        return {
          ...prev,
          getTalentAnalytic: {
            ...prev.getTalentAnalytic,
            [cardItems]: [...prev.getTalentAnalytic[cardItems], ...fetchMoreResult.getTalentAnalytic[cardItems]],
          },
        };
      },
    });
  };

  const toggleDatePicker = (): void => {
    setOpenDatePicker((current) => !current);
  };

  const handleSetFocus = (focus: string) => setCurrentFocus(focus);

  const handleDateChange = (date) => {
    if (!date.startDate && !date.endDate) {
      isAnalyticFetched.current = false;
    }
    setDateRange(date);
  };
  const resetDateRange = (): void => {
    setDateRange({ startDate: null, endDate: null });
    isAnalyticFetched.current = false;
  };

  return {
    openDatePicker,
    currentFocus,
    loading,
    brandsWorkedFor,
    brandsWorkedForCount,
    brandsAgreementSent,
    agreementsSentCount,
    brandsTalentProfileViewed,
    profileViewsCount,
    revenueDoneOpportunities,
    revenueCanceledOpportunities,
    revenueAveragePerOpportunity,
    toggleDatePicker,
    handleDateChange,
    resetDateRange,
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
    loadMore,
    handleSetFocus,
  };
};

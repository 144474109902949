import React, { FC } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { Warning as WarningIcon } from '@material-ui/icons';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import intlUniversal from 'react-intl-universal';

import { HourglassIcon } from 'assets/svg-js';import { intl } from 'helpers';
import { useAuth } from 'hooks/auth';
import { routes } from 'router/routesList';
import { UserTypeEnum , verificationStatusEnum } from 'types/common';

import { useStyles } from './styles';
import type { Props } from './types';

const VerifyMePanel: FC<Props> = ({ verificationStatus }) => {
  const styles = useStyles();
  const { getRole } = useAuth();
  const { pathname } = useLocation();

  const verifyRoute = getRole() === UserTypeEnum.brand ? routes.BRAND_VERIFICATION : routes.TALENT_VERIFICATION;
  const isVerifyBtnDisabled = verificationStatus !== verificationStatusEnum.not_verified;
  const isRejected = verificationStatus === verificationStatusEnum.rejected;
  const isPending = verificationStatus === verificationStatusEnum.pending;
  const isNotVerified = verificationStatus === verificationStatusEnum.not_verified;

  return (
    <Box
      className={clsx(styles.container, {
        [styles.centerBlock]: isPending,
      })}
    >
      <Box
        className={clsx(styles.textBox, {
          [styles.pendingBlock]: isPending,
        })}
      >
        {(isNotVerified || isRejected) && (
          <WarningIcon
            className={clsx(styles.warnIcon, {
              [styles.warnError]: isRejected,
            })}
          />
        )}
        {isPending && <HourglassIcon className={styles.hourglassIcon} />}
        {isRejected && (
          <Typography className={styles.errorText} variant="caption">
            {intlUniversal.getHTML('VERIFY_ME_PANEL.verifyError')}
          </Typography>
        )}
        {isNotVerified && <Typography variant="caption">{intl('VERIFY_ME_PANEL.verifyText')}</Typography>}
        {isPending && (
          <Typography variant="caption" className={styles.pendingText}>
            {intl('VERIFY_ME_PANEL.verifyInProgress')}
          </Typography>
        )}
      </Box>
      {isNotVerified && (
        <Button

          className={styles.btn}
          variant="text"
          component={Link}
          to={{ pathname: verifyRoute, state: { previousPage: pathname } }}
          disabled={isVerifyBtnDisabled}
        >
          {intl('VERIFY_ME_PANEL.verifyButton')}
        </Button>
      )}
    </Box>
  );
};

export default VerifyMePanel;

/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { Box, Paper, TextField, Hidden, Grid, useMediaQuery, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useFormik } from 'formik';
import { mixed, object, string } from 'yup';
import { useQuery, useMutation } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { intl } from 'helpers';
import { constants } from 'theme/constants';
import { useLink } from 'hooks/linkTo';
import { routes } from 'router/routesList';

import { LIST_COUNTRIES, LIST_CITIES } from 'services/graphql/location';
import { LIST_INDUSTRIES } from 'services/graphql/industry';
import { UPDATE_BRAND_PROFILE, GET_CURRENT_BRAND } from 'services/graphql/brand';

import { SaveBar } from 'components/SaveBar';
import { ProfileCard } from 'components/ProfileCard';
import { Loader } from 'components/Loader';
import { Select } from 'components/Select';
import { Wrapper } from 'components/Wrapper';
import { AvatarEditable } from 'components/AvatarEditable';

import { useStyles } from './styles';
import { getOptions } from './utility';

const validationSchema = object({
  companyName: string().nullable().required('ERRORS.emptyField'),
  about: string().nullable(),
  country: object({
    name: string().required('ERRORS.emptyField').nullable(),
  })
    .required('ERRORS.emptyField')
    .nullable(),
  city: object({
    name: string().required('ERRORS.emptyField').nullable(),
  })
    .required('ERRORS.emptyField')
    .nullable(),
  private: string().nullable().required('ERRORS.emptyField'),
  industry: object({
    name: string().required('ERRORS.emptyField').nullable(),
  })
    .required('ERRORS.emptyField')
    .nullable(),
  image: mixed().required('ERRORS.emptyField').nullable(),
  privateInfo: object({
    street: string().required('ERRORS.emptyField'),
    postalCode: string().required('ERRORS.emptyField'),
    VATnumber: string().required('ERRORS.emptyField'),
    IBAN: string().required('ERRORS.emptyField'),
    bankAccount: string().required('ERRORS.emptyField'),
  }),
});

export const CompanyInfo = () => {
  const styles = useStyles({});
  const linkTo = useLink();
  const { state } = useLocation();

  const isSm = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const isRequiredField = !!state?.referrer;
  const displayError = isRequiredField && intl('ERRORS.required');

  const profile = [
    { value: 'public', name: intl('SHARED.public') },
    { value: 'private', name: intl('SHARED.private') },
  ];

  const [updateBrandProfile, { loading: brandIsUpdating }] = useMutation(UPDATE_BRAND_PROFILE, {
    onCompleted({ updateBrandProfile: updateBrandProfileData }) {
      if (state?.fromSignUp) {
        linkTo(`/${routes.BRAND_PROFILE_INFO}`, { fromSignUp: true })();
        setValues(updateBrandProfileData);
      } else if (state?.referrer) {
        linkTo(state.referrer)();
        delete state.referrer;
      } else {
        linkTo(routes.BRAND_PUBLIC_PAGE)();
        setValues(updateBrandProfileData);
      }
    },
  });

  const populateBrandsDataToForm = (brandData) => {
    setValues({
      ...brandData,
      private: brandData?.isPrivate ? profile[1].value : profile[0].value,
      privateInfo: {
        street: brandData?.privateInfo?.street || '',
        postalCode: brandData?.privateInfo?.postalCode || '',
        VATnumber: brandData?.privateInfo?.VATnumber || '',
        IBAN: brandData?.privateInfo?.IBAN || '',
        bankAccount: brandData?.privateInfo?.bankAccount || '',
      },
    });
  };

  const [updateBrandProfileImage, { loading: brandImageIsUpdating }] = useMutation(UPDATE_BRAND_PROFILE, {
    onCompleted({ updateBrandProfile: updateBrandProfileData }) {
      populateBrandsDataToForm(updateBrandProfileData);
    },
  });

  const handleFormSubmit = (values) => {
    updateBrandProfile({
      variables: {
        companyName: values.companyName.trim(),
        about: values.about,
        countryId: values.country.id,
        cityId: values.city.id,
        industryId: values.industry.id,
        isPrivate: values.private === profile[1].value,
        image: values.image,
        privateInfo: {
          street: values.privateInfo?.street.trim(),
          postalCode: values.privateInfo?.postalCode.trim(),
          VATnumber: values.privateInfo?.VATnumber.trim(),
          IBAN: values.privateInfo?.IBAN.trim(),
          bankAccount: values.privateInfo?.bankAccount.trim(),
        },
      },
    });
  };
  const handleImageFormSubmit = (values) => {
    updateBrandProfileImage({
      variables: {
        companyName: values.companyName,
        about: values.about,
        countryId: values.country.id,
        cityId: values.city.id,
        industryId: values.industry.id,
        isPrivate: values.private === profile[1].value,
        image: values.image,
        privateInfo: {
          street: values.privateInfo?.street,
          postalCode: values.privateInfo?.postalCode,
          VATnumber: values.privateInfo?.VATnumber,
          IBAN: values.privateInfo?.IBAN,
          bankAccount: values.privateInfo?.bankAccount,
        },
      },
    });
  };

  const { values, errors, handleChange, setValues, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      companyName: '',
      about: '',
      country: {},
      city: {},
      industry: {},
      private: '',
      image: '',
      privateInfo: {
        street: '',
        postalCode: '',
        VATnumber: '',
        IBAN: '',
        bankAccount: '',
      },
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: handleFormSubmit,
  });

  // TODO: move it to custom hook, order of queries is important!
  const { data: industries, loading: industriesLoading } = useQuery(LIST_INDUSTRIES);

  const { data: countries, loading: countriesLoading } = useQuery(LIST_COUNTRIES);

  const { data: cities, loading: citiesLoading } = useQuery(LIST_CITIES, {
    skip: !values.country,
    variables: {
      countryId: values.country && values.country.id,
    },
  });

  const { loading: currentBrandLoading } = useQuery(GET_CURRENT_BRAND, {
    onCompleted({ getCurrentBrand }) {
      populateBrandsDataToForm(getCurrentBrand);
    },
  });

  const handleImageChange = (key) => {
    handleImageFormSubmit({ ...values, image: key });
  };

  const handleAutocompleteChange = (name) => (_, value) => {
    if (name === 'country' && value !== values.country) {
      setFieldValue('city', {});
    }
    if ((name === 'industry' || name === 'country' || name === 'city') && value === null) {
      setFieldValue(name, {});
      return;
    }
    setFieldValue(name, value);
  };

  const onCancel = () => {
    if (state?.fromSignUp) linkTo(`/${routes.BRAND_PROFILE_INFO}`, { fromSignUp: true })();
    else linkTo(routes.BRAND_PUBLIC_PAGE)();
  };

  const optionsCountry = getOptions('Country', countries?.listCountries);
  const optionsIndustry = getOptions('Industry', industries?.listIndustries);
  const optionsCities = getOptions('City', cities?.listCities);

  return (
    <Wrapper className={styles.wrapper} additionalContentHeight={isSm ? constants.SAVE_BAR_HEIGHT_MOBILE : constants.SAVE_BAR_HEIGHT}>
      <Box className={styles.companyInfo}>
        <Hidden smDown>
          <ProfileCard
            error={isRequiredField || !!errors.image}
            handleImageChange={handleImageChange}
            imgSrc={values.image}
            isSignUp={state?.fromSignUp}
          />
        </Hidden>

        <Box className={styles.companyInfoFormAndNav}>
          <Paper className={styles.companyInfoForm}>
            {currentBrandLoading ? (
              <Loader />
            ) : (
              <Grid container justify="space-between">
                <Grid item container md={false} sm={6} justify="center" alignItems="baseline">
                  <Hidden mdUp>
                    <AvatarEditable image={values.image} error={isRequiredField || !!errors.image} handleSuccess={handleImageChange} />
                  </Hidden>
                </Grid>
                <Grid item md={12} sm={6} container justify="space-between">
                  <TextField
                    required
                    className={styles.input}
                    name="companyName"
                    value={values.companyName || ''}
                    onChange={handleChange}
                    label={intl('SHARED.companyName')}
                    error={(isRequiredField && !values.companyName) || !!errors.companyName}
                    helperText={intl(errors.companyName) || (!values.companyName && displayError)}
                  />
                  <Select
                    label={intl('SHARED.status')}
                    containerProps={{ className: styles.input }}
                    className={styles.select}
                    onChange={handleChange}
                    name="private"
                    options={profile}
                    value={values.private}
                    helperText={
                      values.private === profile[1].value ? intl('BRAND.COMPANY_INFO.privateProfile') : intl('BRAND.COMPANY_INFO.publicProfile')
                    }
                  />
                  <Grid item lg={12}>
                    <Box className={styles.industryContainer}>
                      <Autocomplete
                        name="industry"
                        loading={industriesLoading}
                        className={styles.input}
                        options={optionsIndustry}
                        value={values.industry}
                        onChange={handleAutocompleteChange('industry')}
                        getOptionLabel={(option) => option.name || ''}
                        getOptionSelected={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            className={styles.input}
                            name="industry"
                            label={intl('SHARED.industry')}
                            error={(isRequiredField && !values.industry?.name) || !!errors.industry}
                            helperText={intl(errors.industry?.name) || (!values.industry?.name && displayError)}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Autocomplete
                    loading={countriesLoading}
                    className={styles.input}
                    options={optionsCountry}
                    value={values.country || ''}
                    onChange={handleAutocompleteChange('country')}
                    getOptionLabel={(option) => option?.name || ''}
                    getOptionSelected={(option, value) => option?.id === value?.id}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={styles.input}
                        name="country"
                        label={intl('SHARED.country')}
                        required
                        error={(isRequiredField && !values.country?.name) || !!errors.country}
                        helperText={intl(errors.country?.name) || (!values.country?.name && displayError)}
                      />
                    )}
                  />
                  <Autocomplete
                    loading={citiesLoading}
                    className={styles.input}
                    disabled={!values.country}
                    options={optionsCities}
                    value={values.city}
                    onChange={handleAutocompleteChange('city')}
                    getOptionLabel={(option) => option.name || ''}
                    getOptionSelected={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={styles.input}
                        name="city"
                        required
                        label={intl('SHARED.city')}
                        error={(isRequiredField && !values.city?.name) || !!errors.city}
                        helperText={intl(errors.city?.name) || (!values.city?.name && displayError)}
                      />
                    )}
                  />
                  <TextField
                    className={styles.input}
                    name="about"
                    multiline
                    value={values.about || ''}
                    onChange={handleChange}
                    label={intl('SHARED.about')}
                  />
                </Grid>
              </Grid>
            )}
          </Paper>
          {!currentBrandLoading && (
            <Paper className={clsx(styles.companyInfoForm, styles.paymentInfoBlock)}>
              <Typography cpmponent="h4" className={styles.infoBlockHeader}>
                {intl('BRAND_PROFILE.forAdmin')}
              </Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6} container justify="space-between">
                  <TextField
                    fullWidth
                    className={styles.inputInvoice}
                    name="privateInfo.street"
                    value={values.privateInfo?.street || ''}
                    onChange={handleChange}
                    label={intl('SHARED.street')}
                    error={!!errors.privateInfo?.street || (isRequiredField && !values.privateInfo?.street)}
                    helperText={intl(errors.privateInfo?.street) || (!values.privateInfo?.street && displayError)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} container justify="space-between">
                  <TextField
                    fullWidth
                    className={styles.inputInvoice}
                    name="privateInfo.postalCode"
                    value={values.privateInfo?.postalCode || ''}
                    onChange={handleChange}
                    label={intl('SHARED.postalCode')}
                    error={!!errors.privateInfo?.postalCode || (isRequiredField && !values.privateInfo?.postalCode)}
                    helperText={intl(errors.privateInfo?.postalCode) || (!values.privateInfo?.postalCode && displayError)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} container justify="space-between">
                  <TextField
                    fullWidth
                    className={styles.inputInvoice}
                    name="privateInfo.VATnumber"
                    value={values.privateInfo?.VATnumber || ''}
                    onChange={handleChange}
                    label={intl('SHARED.VATnumber')}
                    error={!!errors.privateInfo?.VATnumber || (isRequiredField && !values.privateInfo?.VATnumber)}
                    helperText={intl(errors.privateInfo?.VATnumber) || (!values.privateInfo?.VATnumber && displayError)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} container justify="space-between">
                  <TextField
                    fullWidth
                    className={styles.inputInvoice}
                    name="privateInfo.IBAN"
                    value={values.privateInfo?.IBAN || ''}
                    onChange={handleChange}
                    label={intl('SHARED.IBAN')}
                    error={!!errors.privateInfo?.IBAN || (isRequiredField && !values.privateInfo?.IBAN)}
                    helperText={intl(errors.privateInfo?.IBAN) || (!values.privateInfo?.IBAN && displayError)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} container justify="space-between">
                  <TextField
                    fullWidth
                    className={styles.inputInvoice}
                    name="privateInfo.bankAccount"
                    value={values.privateInfo?.bankAccount || ''}
                    onChange={handleChange}
                    label={intl('SHARED.bankAccount')}
                    error={!!errors.privateInfo?.bankAccount || (isRequiredField && !values.privateInfo?.bankAccount)}
                    helperText={intl(errors.privateInfo?.bankAccount) || (!values.privateInfo?.bankAccount && displayError)}
                  />
                </Grid>
              </Grid>
            </Paper>
          )}
        </Box>
      </Box>
      <SaveBar
        sticky
        className={styles.saveBar}
        isSignUp={state?.fromSignUp}
        onSave={handleSubmit}
        loading={brandIsUpdating || brandImageIsUpdating}
        onCancel={onCancel}
      />
    </Wrapper>
  );
};

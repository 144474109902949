import React, { useState } from 'react';
import { Box, Button } from '@material-ui/core';
import { Payment } from '@material-ui/icons';
import clsx from 'clsx';

import stripeConstants from 'services/stripe/constants';
import { intl } from 'helpers';
import { StripeRemoveModal } from 'pages/Talent/PrivateInfo/StripeRemoveModal';

import { useStyles } from './styles';

const { STRIPE_CA, STRIPE_CONNECT_REDIRECT_URL, STRIPE_CONNECT_ENDPOINT } = stripeConstants;

const getStripeUrl = () => {
  const redirectUrl = STRIPE_CONNECT_REDIRECT_URL;

  return `${STRIPE_CONNECT_ENDPOINT}?scope=read_write&client_id=${STRIPE_CA}&redirect_uri=${redirectUrl}`;
};

export const StripeConnect = ({ handleUserUpdate = () => {}, stripeAccountId = false, isAdmin, error = false }) => {
  console.log('error', error);
  const styles = useStyles({
    error: !stripeAccountId && error,
  });

  const [modalOpened, setModalOpened] = useState(false);

  const toggleModal = () => setModalOpened((oldModalState) => !oldModalState);

  const handleStripeConnect = () => {
    handleUserUpdate();
    const endpoint = getStripeUrl();
    window.location.href = endpoint;
  };

  return (
    <Box className={styles.cardBlock}>
      <Box className={styles.iconAndTextContainer}>
        <Box className={styles.iconContainer}>
          <Payment />
        </Box>
        {!stripeAccountId ? (
          <Box className={clsx({ [styles.errorText]: error })}>{intl('STRIPE_CONNECT.connectCardText')}</Box>
        ) : (
          <Box>{intl('STRIPE_CONNECT.connectedCardText')}</Box>
        )}
      </Box>
      {/* eslint-disable-next-line */}
      {!isAdmin ? (
        !stripeAccountId ? (
          <Button color="primary" className={styles.btn} onClick={handleStripeConnect}>
            {intl('STRIPE_CONNECT.connectCard')}
          </Button>
        ) : (
          <Button color="primary" className={styles.btn} onClick={toggleModal}>
            {intl('STRIPE_CONNECT.removeCard')}
          </Button>
        )
      ) : null}

      <StripeRemoveModal isOpen={modalOpened} toggle={toggleModal} />
    </Box>
  );
};

import { makeStyles, Theme } from '@material-ui/core';
import { colors } from 'theme/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${colors.BORDER2}`,
  },
  avatar: {
    width: 60,
    height: 60,
    marginRight: theme.spacing(2),
  },
  name: {
    lineHeight: '30px',
    fontWeight: 600,
  },
  headerBox: {
    display: 'flex',
    alignItems: 'center',
  },
  btnViewProfile: {
    border: '1px solid #e5e5e5',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
  },
  icon: {
    fill: '#5B5B5B',
    marginRight: 10,
  },
  section: {
    display: 'flex',
    marginBottom: theme.spacing(1.5),
  },
  infoItems: {},
  competingBox: {
    alignItems: 'center',
  },
  competing: {
    marginLeft: theme.spacing(1.5),
    padding: '3px 12px',
    opacity: 0.6,
    background: colors.BACKGROUND4,
    borderRadius: 4,
  },
  socials: {
    display: 'flex',
    justifyContent: 'space-between',
    '&::after': {
      content: '""',
      flex: 'auto',
    },
  },
  socialsItem: {
    width: 80,
    height: 66,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: colors.BACKGROUND,
    borderRadius: 4,
    marginRight: theme.spacing(2),
  },
  svgIconBox: {
    justifyContent: 'center',
  },
  svgIcon: {
    width: 20,
    height: 20,
  },
  socialsItemText: {
    flex: 0,
    marginTop: 6,
    marginBottom: 0,
    '& span': {
      fontSize: 14,
      fontWeight: 600,
      textTransform: 'uppercase',
    },
  },
}));

import React from 'react';
import { Box, Hidden } from '@material-ui/core';
import { useQuery } from '@apollo/client';

import { useStyles } from './styles';

import { ProfileCard } from '../../../components/ProfileCard';
import { GET_CURRENT_BRAND } from '../../../services/graphql/brand';
import { Wrapper } from '../../../components/Wrapper';
import { getLetterForCompanyNameAvatar } from '../../../helpers';
import { BrandProfileAnalytics } from '../../../components/BrandProfileAnalytics';

export const BrandAnalytics = () => {
  const styles = useStyles({});

  const { data: currentBrandData } = useQuery(GET_CURRENT_BRAND);

  return (
    <Wrapper>
      <Box className={styles.publicPage}>
        <Hidden smDown>
          <ProfileCard
            avatarDisabled
            imgSrc={currentBrandData?.getCurrentBrand?.image}
            imgAlt={getLetterForCompanyNameAvatar(currentBrandData?.getCurrentBrand?.companyName)}
            className={styles.profileCard}
          />
        </Hidden>
        <Box className={styles.publicPageContent}>
          <BrandProfileAnalytics />
        </Box>
      </Box>
    </Wrapper>
  );
};

/* eslint-disable max-len */
import React from 'react'

const BrandManagersIcon = ({ fill, ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.7946 16.9231C19.1577 16.9972 19.4494 17.1709 19.6696 17.4444C19.8899 17.7123 20 18.0199 20 18.3675C20 18.8689 19.8155 19.2678 19.4464 19.5641C19.0833 19.8547 18.5744 20 17.9196 20H15V14H17.8214C18.4583 14 18.9554 14.1396 19.3125 14.4188C19.6756 14.698 19.8571 15.0769 19.8571 15.5556C19.8571 15.9088 19.7589 16.2023 19.5625 16.4359C19.372 16.6695 19.1161 16.8319 18.7946 16.9231ZM16.5268 16.4274H17.5268C17.7768 16.4274 17.9673 16.3761 18.0982 16.2735C18.2351 16.1652 18.3036 16.0085 18.3036 15.8034C18.3036 15.5983 18.2351 15.4416 18.0982 15.3333C17.9673 15.2251 17.7768 15.1709 17.5268 15.1709H16.5268V16.4274ZM17.6518 18.8205C17.9077 18.8205 18.1042 18.7664 18.2411 18.6581C18.3839 18.5442 18.4554 18.3818 18.4554 18.1709C18.4554 17.9601 18.381 17.7949 18.2321 17.6752C18.0893 17.5556 17.8899 17.4957 17.6339 17.4957H16.5268V18.8205H17.6518Z"
      fill={fill || '#535E83'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 8C16 10.21 14.21 12 12 12C9.79 12 8 10.21 8 8C8 5.79 9.79 4 12 4C14.21 4 16 5.79 16 8ZM4 18C4 15.34 9.33 14 12 14C12.303 14 12.6402 14.0173 13 14.0517V20H5C4.45 20 4 19.55 4 19V18Z"
      fill={fill || '#535E83'}
    />
  </svg>
)

export default BrandManagersIcon

import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../theme/colors';

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  drawerOpen: {
    width: (props) => props.drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7),
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 60,
    padding: (props) => props.open && theme.spacing(0, 1),
    paddingLeft: (props) => props.open && theme.spacing(2.5),
  },
  menuIcon: {
    margin: '0 auto',
  },
  icon: {
    fill: colors.PRIMARY_INPUT_HOVERED,
    '& svg': {
      fill: colors.PRIMARY_INPUT_HOVERED,
    },
  },
  list: {
    paddingTop: 0,
  },
  current: {
    fill: colors.PRIMARY_HOVERED,
    color: colors.PRIMARY_HOVERED,
  },
  hoveredIco: {
    fill: colors.PRIMARY_HOVERED,
  },
}));

export { useStyles };

import { makeStyles, Theme } from '@material-ui/core';
import { colors } from 'theme/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  buttons: {
    width: 152,
    marginRight: theme.spacing(2),
    '&:last-child': {
      marginRight: 0,
    },
  },
  modalContentBox: {
    margin: -theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  modalIconBox: {
    marginBottom: theme.spacing(4),
    background: colors.BACKGROUND,
    padding: '24px 42px',
    borderRadius: '100%',
  },
  radioBox: {
    flexDirection: 'row',
    marginTop: -theme.spacing(2),
    marginBottom: theme.spacing(1.5),
    '& label:first-child': {
      marginRight: theme.spacing(3),
    },
  },
  radio: {
    '&$checked': {
      color: colors.PRIMARY_INPUT_HOVERED,
    },
  },
  checked: {},
  input: {
    marginBottom: theme.spacing(4),
  },
  invoice: {
    marginBottom: theme.spacing(2),
  },
  modal: {
    width: 'min-content',
  },
  hidden: {
    display: 'none',
  },
}));

import React from 'react';

const DesertImage = ({ className = '', sunClassName = '', groundClassName = '' }) => {
  return (
    <svg className={className} width="469" height="301" viewBox="0 0 469 301" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="235" cy="144" r="144" fill="white" className={sunClassName} />
      <path d="M463 184H355H144H6" stroke="#535E83" strokeWidth="11" strokeLinecap="round" />
      <path d="M385 174H366.567H330.554H307" stroke="#535E83" strokeWidth="11" strokeLinecap="round" />
      <path d="M55 174H49.5646H38.9453H32" stroke="#535E83" strokeWidth="11" strokeLinecap="round" />
      <path d="M442 174H436.565H425.945H419" stroke="#535E83" strokeWidth="11" strokeLinecap="round" />
      <rect x="58" y="192" width="361" height="109" fill="#F6F6F6" className={groundClassName} />
      <rect width="12" height="56" rx="6" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 377 147)" fill="#757E9C" />
      <rect width="12" height="26" rx="6" transform="matrix(-1 8.74228e-08 8.74228e-08 1 333 121)" fill="#757E9C" />
      <rect width="12" height="31" rx="6" transform="matrix(-1 8.74228e-08 8.74228e-08 1 377 116)" fill="#757E9C" />
      <rect x="339" y="101" width="21" height="80" rx="10.5" fill="#535E83" />
      <rect x="231" y="162.215" width="8.14285" height="38" rx="4.07143" transform="rotate(-90 231 162.215)" fill="#757E9C" />
      <rect x="260.857" y="144.572" width="8.14286" height="17.6429" rx="4.07143" fill="#757E9C" />
      <rect x="231" y="141.178" width="8.14286" height="21.0357" rx="4.07143" fill="#757E9C" />
      <rect width="14.25" height="54.2857" rx="7.125" transform="matrix(-1 0 0 1 256.786 131)" fill="#535E83" />
      <path d="M172 174H170.818H168.51H167" stroke="#535E83" strokeWidth="11" strokeLinecap="round" />
      <path
        d="M66 157.5L75.5 180.5H144V173L148.5 150L117 142L101 133.5L78.5 142V154L66 157.5Z"
        fill="white"
        stroke="#535E83"
        strokeWidth="11"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { DesertImage };

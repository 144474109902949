/* eslint-disable max-len */
import React from 'react'

const RestoreIcon = ({ ...props }) => (
  <svg
    width="112"
    height="112"
    viewBox="0 0 112 112"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="56" cy="56" r="56" fill="#F6F6F6" />
    <path
      d="M80 32H32C28.7 32 26.03 34.7 26.03 38L26 74C26 77.3 28.7 80 32 80H80C83.3 80 86 77.3 86 74V38C86 34.7 83.3 32 80 32ZM77 74H35C33.35 74 32 72.65 32 71V44L52.82 57.02C54.77 58.25 57.23 58.25 59.18 57.02L80 44V71C80 72.65 78.65 74 77 74ZM56 53L32 38H80L56 53Z"
      fill="#838CA7"
    />
  </svg>
)

export default RestoreIcon

import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Box, Typography, Tab, Tabs, Divider, Paper, Hidden, useMediaQuery, useTheme } from '@material-ui/core';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import clsx from 'clsx';

import { intl, clipText } from 'helpers';
import { useAuth } from 'hooks/auth';
import { routes } from 'router/routesList';
import { DesertImage } from 'assets/svg-js/DesertImage';
import { GET_OPPORTUNITY, GET_TALENT_REQUIRED_FIELDS } from 'services/graphql/query/opportunity';
import { APPLY_OPPORTUNITY } from 'services/graphql/mutation/opportunity';
import { GET_CONVERSATION, GET_UNREAD_MESSAGES } from 'services/graphql/query/messanger';

import OpportunityStatus from 'components/OpportunityStatus';
import { Loader } from 'components/Loader';
import { Wrapper } from 'components/Wrapper';
import { ErrorPage } from 'pages/ErrorPage';

import { useStyles } from './styles';
import TabPanel, { a11yProps } from './components/Tabs';
import Details from './components/Details';
import Applicants from './components/Applicants';
import Deal from './components/Deal';
import PayPanel from './components/PayPanel';
import TargetModal from './components/TargetModal';

import { SINGLE_OPPORTUNITY_TABS, STATUSES, APPLICANT_STATUSES, TALENT_STATUS } from '../../../constants';

const SingleOpportunity = () => {
  const { id, tab } = useParams();
  const { getAssumedRole } = useAuth();
  const classes = useStyles();
  const theme = useTheme();
  const role = getAssumedRole();
  const isSmallView = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.only('sm'));
  const [currentApplicant, setCurrentApplicant] = useState();
  const [targetPopup, setTargetPopup] = useState(false);
  const [paymentPopup, setPaymentPopup] = useState(false);

  const isBrandView = ['brand', 'admin'].includes(role);

  const toggleTargetPopup = () => setTargetPopup((old) => !old);
  const togglePaymentPopup = () => setPaymentPopup((old) => !old);

  const getOpportunityQuery = {
    query: GET_OPPORTUNITY,
    variables: {
      opportunityId: id,
    },
  };

  const [getConversation, { data: conversationData, loading: loadingConversation, subscribeToMore, fetchMore }] = useLazyQuery(GET_CONVERSATION, {
    fetchPolicy: 'network-only',
  });

  const { data, loading, error } = useQuery(getOpportunityQuery.query, {
    variables: getOpportunityQuery.variables,
    fetchPolicy: 'network-only',
    onCompleted: (resData) => {
      if (['talent', 'agent', 'admin'].includes(role)) {
        getConversation({
          variables: {
            applicationId: resData?.getOpportunity.applicationId,
          },
        });
        setCurrentApplicant(resData?.getOpportunity.applicationId);
      } else {
        const applicationId =
          resData.getOpportunity?.applicants?.find((i) => i.status === APPLICANT_STATUSES.CONFIRMED.value)?.applicationId ||
          resData.getOpportunity?.applicants[0]?.applicationId;

        if (applicationId && !isSmallView) {
          getConversation({
            variables: {
              applicationId,
            },
          });
          setCurrentApplicant(applicationId);
        }
      }
    },
  });
  // Temporary solution if Brand cancel confirmed opportunity and Talent has't Stripe
  const hasConfirmedTalentStripe = data?.getOpportunity?.applicants?.find(({ status }) => status === APPLICANT_STATUSES.CONFIRMED.value)?.hasStripe;

  const { data: unreadMessages } = useQuery(GET_UNREAD_MESSAGES, {
    variables: {
      opportunityId: data?.getOpportunity?.id,
    },
    fetchPolicy: 'network-only',
    skip: role !== 'brand' || !data?.getOpportunity?.applicants?.length,
  });

  const { data: talentInfo, loading: talentInfoLoading } = useQuery(GET_TALENT_REQUIRED_FIELDS, {
    skip: role !== 'talent',
    fetchPolicy: 'no-cache',
  });

  const [applyOpportunity, { loading: applyLoading }] = useMutation(APPLY_OPPORTUNITY, {
    onCompleted: (response) => {
      if (response.applyOpportunity.applicationId) {
        getConversation({
          variables: { applicationId: response.applyOpportunity.applicationId },
        });
      }
    },
    onError: (e) => toggleTargetPopup(),
  });

  if (error) return <ErrorPage />;

  const setApplied = (cache) => {
    const qr = cache.readQuery(getOpportunityQuery);

    cache.writeQuery({
      ...getOpportunityQuery,
      data: {
        getOpportunity: {
          ...qr.getOpportunity,
          isApplied: TALENT_STATUS.APPLIED,
        },
      },
    });
  };

  const handleApply = (opportunityId, coverLetterText) => {
    applyOpportunity({
      variables: {
        data: {
          opportunityId,
          coverLetter: coverLetterText,
        },
      },
      update: (cache) => {
        setApplied(cache);
      },
    });
  };

  if (loading || talentInfoLoading) {
    return <Loader />;
  }

  const {
    id: opportunityId,
    name,
    status,
    type,
    city,
    country,
    startDate,
    endDate,
    redemption,
    createdAt,
    description,
    isApplied,
    isRated,
    images,
    applicants,
    brand,
    finalCondition,
    profitMargins,
    recurrence,
  } = data?.getOpportunity;

  const switchToConfirmedTalent = () => {
    if (status.name !== STATUSES.OPEN.value && role !== 'talent' && applicants.length) {
      const applicant = applicants?.find((a) => a.status === APPLICANT_STATUSES.CONFIRMED.value);
      if (applicant) getConversation({ variables: { applicationId: applicant.applicationId } });
    }
  };

  const detailsProps = {
    opportunity: {
      opportunityId,
      type,
      city,
      country,
      startDate,
      endDate,
      redemption,
      createdAt,
      isApplied,
      description,
      brand,
      status,
      name,
      images,
      recurrence,
      applicants,
      finalCondition,
      profitMargins,
    },
    isBrandView,
    handleApply,
    applyLoading,
    talentInfo,
    paymentPopup,
    togglePaymentPopup,
    hasConfirmedTalentStripe,
  };

  const applicantsProps = {
    opportunityId,
    applicants: applicants || [],
    baseQuery: getOpportunityQuery,
    redemption,
    isApplied,
    status,
    conversationData,
    subscribeToMore,
    fetchMore,
    getConversation,
    loadingConversation,
    profitMargins,
    currentApplicant,
    setCurrentApplicant,
    unreadMessages: unreadMessages?.listConversations.items,
  };

  const dealProps = {
    applicants,
    status,
    isApplied,
    conversationData,
    opportunityId,
    subscribeToMore,
    fetchMore,
    getConversation,
    loadingConversation,
    currentApplicant,
    isTalentView: role === 'talent' || role === 'agent',
    isRated,
    brandId: brand?.id,
    finalCondition,
    profitMargins,
    togglePaymentPopup,
    profile: {
      brandName: brand?.name,
      brandImage: brand?.image,
      isTaxed: redemption?.isTaxed,
      budget: redemption?.budget,
      currency: redemption?.currency.code,
      description,
      applicant:
        applicants?.find((i) => i.status === APPLICANT_STATUSES.CONFIRMED.value) ||
        applicants?.find((i) => i.status === APPLICANT_STATUSES.CANCELED.value),
    },
  };

  const targetPopupProps = {
    open: targetPopup,
    handleClose: toggleTargetPopup,
  };

  const firstApplicantId = !!applicants?.length && applicants[0].applicantId;

  const detailRoute = `/${role}/${routes.OPPORTUNITIES}/${id}/details`;
  const applicantRoute = `/${role}/${routes.OPPORTUNITIES}/${id}/applicants`;
  const dealRoute = `/${role}/${routes.OPPORTUNITIES}/${id}/deal`;

  const tabValue = `/${role}/${routes.OPPORTUNITIES}/${id}/${tab}`;

  const isPayPanelShowing =
    ['brand'].includes(role) && (status.name === STATUSES.PAYMENT.value || status.name === STATUSES.CANCELED_BY_ADMIN_PAYMENT.value);

  const hasFinalCondition = data?.getOpportunity?.finalCondition?.offerAmount;

  const isDealDisabled =
    (status.name === STATUSES.OPEN.value ||
      status.name === STATUSES.EXPIRED.value ||
      (status.name === STATUSES.CANCELED_BY_ADMIN.value && !hasFinalCondition) ||
      (status.name === STATUSES.CANCELED_BY_ADMIN_PAYMENT.value && !hasFinalCondition) ||
      (status.name === STATUSES.CANCELED_BY_BRAND.value && !hasFinalCondition)) &&
    ['brand', 'admin'].includes(role);

  return (
    <>
      <Wrapper className={clsx({ [classes.wrapper]: isSmallView })}>
        <Box className={classes.container}>
          <Box className={classes.heading}>
            <Box className={classes.titleBox}>
              <Typography variant="h6" className={classes.title}>
                {isTablet ? clipText(name, 40) : name}
              </Typography>
              <OpportunityStatus
                status={isApplied === TALENT_STATUS.DECLINED ? STATUSES.DECLINED.value : status.name}
                isBrandView={isBrandView}
                hideIcon
              />
            </Box>
            <Tabs
              variant="fullWidth"
              indicatorColor="primary"
              aria-label="brand-opportunity-tabs"
              value={tabValue}
              classes={{ indicator: classes.indicator }}
            >
              <Tab
                component={Link}
                value={detailRoute}
                to={detailRoute}
                label={intl(SINGLE_OPPORTUNITY_TABS.DETAILS.label)}
                {...a11yProps(detailRoute)}
                className={classes.tab}
              />

              {['brand', 'admin'].includes(role) && (
                <Tab
                  // disabled={!firstApplicantId}
                  to={applicantRoute}
                  value={applicantRoute}
                  component={Link}
                  label={intl(SINGLE_OPPORTUNITY_TABS.APPLICANTS.label)}
                  {...a11yProps(applicantRoute)}
                  className={classes.tab}
                />
              )}
              <Tab
                onClick={switchToConfirmedTalent}
                to={dealRoute}
                value={dealRoute}
                component={Link}
                disabled={isDealDisabled}
                label={intl(SINGLE_OPPORTUNITY_TABS.DEAL.label)}
                {...a11yProps(dealRoute)}
                className={classes.tab}
              />
            </Tabs>
          </Box>

          <Divider className={classes.divider} />

          <Paper elevation={0} className={classes.content}>
            {['brand', 'admin'].includes(role) && (
              <TabPanel className={clsx({ [classes.withPayPanel]: isPayPanelShowing })} value={tabValue} index={applicantRoute}>
                {applicants.length ? (
                  <Applicants {...applicantsProps} />
                ) : (
                  <Box
                    className={clsx(classes.emptyBox, {
                      [classes.emptyBoxWrapper]: !firstApplicantId,
                    })}
                  >
                    <DesertImage className={classes.emptyIcon} sunClassName={classes.sun} groundClassName={classes.ground} />
                    <Typography className={classes.emptyText}>{intl('EMPTY_STATE.noApplicants')}</Typography>
                  </Box>
                )}
              </TabPanel>
            )}

            <TabPanel className={clsx({ [classes.withPayPanel]: isPayPanelShowing })} value={tabValue} index={detailRoute}>
              <Details {...detailsProps} />
            </TabPanel>

            <TabPanel className={clsx({ [classes.withPayPanel]: isPayPanelShowing })} value={tabValue} index={dealRoute}>
              <Deal {...dealProps} />
            </TabPanel>
          </Paper>
        </Box>
      </Wrapper>
      {isPayPanelShowing && (
        <Hidden mdUp>
          <PayPanel handlePay={togglePaymentPopup} />
        </Hidden>
      )}
      <TargetModal {...targetPopupProps} />
    </>
  );
};

export default SingleOpportunity;

import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../theme/colors'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(1),
    borderBottom: `1px solid ${colors.BACKGROUND}`,
  },
  avatarBox: {
    alignSelf: 'flex-start',
    marginRight: theme.spacing(1),
  },
  avatar: {
    width: 52,
    height: 52,
    backgroundColor: colors.PRIMARY,
    fontSize: 16,
  },
  grayBackground: {
    background: 'linear-gradient(0deg, #848CA6, #848CA6)',
  },
  content: {
    flexGrow: 1,
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  person: {
    color: colors.INPUT_LABEL,
  },
  name: {
    fontWeight: 500,
  },
  details: {
    display: 'flex',
    marginTop: theme.spacing(1),
  },
  currency: {
    paddingRight: 6,
  },
  detailsText: {
    color: colors.TEXT_GRAY,
    fontWeight: 500,
    '&:first-child': {
      width: 96,
      marginRight: 10,
    },
  },
}))

export { useStyles }

import { makeStyles } from '@material-ui/core';
import { colors } from 'theme/colors';
import { constants } from 'theme/constants';

export const useStyles = makeStyles((theme) => ({
  container: {
    position: 'sticky',
    top: constants.HEADER_HEIGHT,
    zIndex: 1200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: colors.WHITE,
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
  },
  centerBlock: {
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  pendingBlock: {
    [theme.breakpoints.only('xs')]: {
      display: 'block!important',
      marginBottom: theme.spacing(1),
    },
  },
  textBox: {
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(0),
      marginRight: theme.spacing(4),
      alignItems: 'center',
    },
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'flex-start',
  },
  hourglassIcon: {
    marginRight: 4,
    width: 8,
    height: 13,
  },
  warnIcon: {
    [theme.breakpoints.only('xs')]: {
      width: 16,
      height: 16,
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    marginRight: 4,
    width: 14,
    height: 13,
    fill: '#F6B014',
  },
  warnError: {
    fill: colors.ERROR,
  },
  errorText: {
    margin: theme.spacing(1, 0),
    '& a': {
      color: colors.BLACK,
    },
  },
  pendingText: {
    margin: '6px 0px',
  },
  btn: {
    fontSize: 12,
    letterSpacing: '0.03em',
    color: colors.PRIMARY_HOVERED,
    [theme.breakpoints.down('sm')]: {
      height: 34,
      padding: '2px 8px',
    },
  },
  btnContact: {
    margin: theme.spacing(1, 0),
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: '0.03em',
    color: colors.PRIMARY_HOVERED,
    textDecoration: 'none',
    textTransform: 'uppercase',
    [theme.breakpoints.down('xs')]: {
      marginRight: 4,
      marginBottom: 6,
    },
  },
}));

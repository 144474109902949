import { ApolloCache, QueryHookOptions } from '@apollo/client';

type Options = QueryHookOptions & {
  updateQuery?: (a: any, b: any) => ApolloCache<any>;
};

export const getQueryOptionsOpportunities = (limit: number, brandId: string): Options => ({
  variables: {
    filter: {
      offset: 0,
      limit,
      options: {
        brandId,
      },
    },
  },
  updateQuery(prev, { fetchMoreResult }) {
    if (!fetchMoreResult) return prev;
    if (fetchMoreResult?.listTalentOpportunities?.meta?.total < 10) return prev;
    return {
      ...prev,
      listTalentOpportunities: {
        ...prev?.listTalentOpportunities,
        items: [...prev?.listTalentOpportunities?.items, ...fetchMoreResult?.listTalentOpportunities?.items],
      },
    };
  },
});

import { makeStyles } from '@material-ui/core';
import { colors } from 'theme/colors';

export const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(2),
    position: 'relative'
  },
  paperLoading: {
    minHeight: 200,
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(2),
  },
  row: {
    cursor: 'pointer',
  },
  emptyBox: {
    margin: '0 auto',
    padding: theme.spacing(4, 0),
    height: '100%',
    maxWidth: 406,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyText: {
    fontWeight: 500,
    fontSize: 24,
    textAlign: 'center',
  },
  tCell: {
    padding: '8px 16px',
  },
  avatar: {
    color: colors.WHITE,
    backgroundColor: colors.PRIMARY,
    textTransform: 'uppercase',
    border: 'none',
  },
  inviteBtn: {
    position: 'absolute',
    bottom: theme.spacing(0.8),
    left: theme.spacing(4),
    height: 36,
    width: 150,
    padding: theme.spacing(0, 2),
  },
}));

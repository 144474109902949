import React, { FC } from 'react';
import { Avatar, Grid } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from '../styles';

import type { ItemWrapperProps } from './types';

export const ItemWrapper: FC<ItemWrapperProps> = ({ children, brandImage, styleClass, clickHandler }) => {
  const styles = useStyles({});
  const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env;

  return (
    <Grid container alignItems="center" wrap="nowrap" className={clsx(styles.blockItem, styleClass)} onClick={clickHandler}>
      <Avatar src={REACT_APP_AWSS3_URL_IMAGE_BEG + brandImage} alt="B" className={styles.logo} />
      {children}
    </Grid>
  );
};

import { makeStyles } from '@material-ui/core';
import { constants } from 'theme/constants';
import { colors } from 'theme/colors';

export const useStyles = makeStyles(() => ({
  wrapper: {
    width: constants.WRAPPER_WIDTH,
    maxWidth: '95%',
    minHeight: `calc(100vh - ${constants.HEADER_HEIGHT}px)`,
    margin: '0 auto',
    flex: 1,
    display: 'flex',
  },
  unregistered: {
    flexGrow: 1,
  },
  app: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    justifyContent: 'space-between',
  },
}));

export const useAdminStyles = makeStyles(() => ({
  container: {
    display: 'flex',
  },
  content: {
    flex: 1,
  },
  adminHeader: {
    height: 61,
    background: colors.BORDER,
  },
}));

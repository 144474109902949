import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  maintenanceWrapper: {
    position: 'relative',
    paddingTop: 120,
    overflow: 'auto',
    [theme.breakpoints.down(1000)]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
      paddingTop: 100,
    },
  },
  infoContainer: {
    paddingTop: 55,
    height: 400,
    width: '40%',
    minWidth: 600,
    [theme.breakpoints.down(1000)]: {
      paddingTop: 65,
    },
  },
  infoTitle: {
    fontSize: 32,
    fontWeight: 600,
    [theme.breakpoints.down(1000)]: {
      textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  bodyContainer: {
    width: 470,
    [theme.breakpoints.down(1000)]: {
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      width: 260,
      margin: '0 auto',
    },
  },
  infoBody: {
    marginTop: 16,
    fontSize: 18,
    [theme.breakpoints.down(1000)]: {
      textAlign: 'center',
      marginTop: 24,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  imageMaintenance: {
    position: 'absolute',
    top: 120,
    right: 0,
    minWidth: 595,
    [theme.breakpoints.down(1000)]: {
      position: 'relative',
      top: 'auto',
      right: 'auto',
      minWidth: 460,
      width: '60%',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 260,
    },
  },
}));

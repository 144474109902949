import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      // height: 'calc(100vh - 149px)',
    },
    '@media (max-height: 800px)': {
      overflow: 'auto',
    },
  },
}));

export { useStyles };

export enum UserTypeEnum {
  brand = 'brand',
  talent = 'talent',
  agent = 'agent',
  admin = 'admin',
  manager = 'manager',
}

export type RouteShape = {
  pathname: string;
  state: {};
};

export type Meta = {
  total: number;
};

export enum verificationStatusEnum {
  verified = 'verified',
  not_verified = 'not_verified',
  rejected = 'rejected',
  pending = 'pending'
}

import { makeStyles } from '@material-ui/core';
import { colors } from 'theme/colors';
import { constants } from 'theme/constants';

export const useStyles = makeStyles((theme) => ({
  profileCard: {
    width: 320,
    minHeight: 240,
    paddingBottom: 0,
    borderRadius: 8,
    background: colors.WHITE,
    boxShadow: '0px 6px 20px rgba(2, 1, 86, 0.05)',
    top: '88px',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      top: 0,
      marginBottom: theme.spacing(1),
      overflowY: 'initial !important',
      overflowX: 'initial !important',
      maxHeight: 'none',
      minHeight: 'none',
    },
    '@media screen and (min-height: 800px)': {
      overflowY: 'scroll',
      overflowX: 'hidden',
    },
  },
  filters: {
    textTransform: 'uppercase',
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  mainBlock: {
    padding: theme.spacing(2, 3),
    paddingTop: 0,
    [theme.breakpoints.up('md')]: {
      maxHeight: `calc(100vh - ${constants.HEADER_HEIGHT}px - 48px - 94px - 16px)`,
      overflowY: 'scroll',
      overflowX: 'hidden',
    },
  },
  clearBtn: {
    padding: 0,
    textTransform: 'none',
    height: 'auto',
    opacity: 0.7,
    fontSize: 12,
  },
  input: {
    marginBottom: theme.spacing(1),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  clearBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  centered: {
    textAlign: 'center',
  },
  clearFilters: {
    padding: theme.spacing(2, 3),
  },
  location: {
    marginBottom: theme.spacing(2),
  },
  checkboxLabel: {
    fontSize: 14,
  },
  topBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 32px',
    paddingRight: 12,
  },
  opportunitySectionTitle: {
    marginLeft: theme.spacing(1),
  },
  chip: {
    background: colors.WHITE,
    border: `1px solid ${colors.PROFILE_BORDER}`,
    borderRadius: 4,
    marginRight: 8,
    marginBottom: 8,
  },
  deleteIcon: {
    display: 'flex',
    background: colors.BORDER,
    borderRadius: 2,
    width: 16,
    height: 16,
    padding: 4,
  },
  deleteIconLabel: {
    height: 0,
  },
  icon: {
    fontSize: 14,
    fill: colors.PRIMARY_INPUT_HOVERED,
  },
  smallError: {
    color: colors.ERROR,
    fontSize: 10,
    marginLeft: 8,
  },
}));

/* eslint-disable max-len */
import React from 'react'

const TwitterIcon = ({ ...props }) => (
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.52659 11.3337C9.55792 11.3337 12.3099 7.22943 12.3099 3.67063C12.3099 3.5538 12.3099 3.43762 12.3019 3.3221C12.8373 2.94142 13.2993 2.46883 13.6666 1.92799C13.1679 2.1459 12.6379 2.28899 12.0959 2.352C12.6666 2.01529 13.0939 1.48626 13.2986 0.86206C12.7613 1.1758 12.1739 1.397 11.5613 1.5158C10.5253 0.431487 8.79259 0.378978 7.69059 1.39896C6.98059 2.05664 6.67859 3.03724 6.89925 3.97256C4.69992 3.8636 2.65059 2.84099 1.26125 1.15874C0.535252 2.38941 0.906585 3.96337 2.10859 4.75363C1.67325 4.74116 1.24725 4.62564 0.866585 4.41692V4.45105C0.867252 5.73292 1.78525 6.83692 3.06125 7.09094C2.65859 7.19924 2.23592 7.21499 1.82659 7.13688C2.18459 8.23432 3.21192 8.98585 4.38192 9.00751C3.41325 9.75708 2.21659 10.164 0.984585 10.1627C0.767252 10.1621 0.549919 10.1496 0.333252 10.124C1.58459 10.9142 3.03992 11.3337 4.52659 11.3317"
      fill="#5B5B5B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.52659 11.3337C9.55792 11.3337 12.3099 7.22943 12.3099 3.67063C12.3099 3.5538 12.3099 3.43762 12.3019 3.3221C12.8373 2.94142 13.2993 2.46883 13.6666 1.92799C13.1679 2.1459 12.6379 2.28899 12.0959 2.352C12.6666 2.01529 13.0939 1.48626 13.2986 0.86206C12.7613 1.1758 12.1739 1.397 11.5613 1.5158C10.5253 0.431487 8.79259 0.378978 7.69059 1.39896C6.98059 2.05664 6.67859 3.03724 6.89925 3.97256C4.69992 3.8636 2.65059 2.84099 1.26125 1.15874C0.535252 2.38941 0.906585 3.96337 2.10859 4.75363C1.67325 4.74116 1.24725 4.62564 0.866585 4.41692V4.45105C0.867252 5.73292 1.78525 6.83692 3.06125 7.09094C2.65859 7.19924 2.23592 7.21499 1.82659 7.13688C2.18459 8.23432 3.21192 8.98585 4.38192 9.00751C3.41325 9.75708 2.21659 10.164 0.984585 10.1627C0.767252 10.1621 0.549919 10.1496 0.333252 10.124C1.58459 10.9142 3.03992 11.3337 4.52659 11.3317"
      fill="#4065E0"
      fillOpacity="0.3"
    />
  </svg>
)

export default TwitterIcon

/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect } from 'react';
import { Box, Paper, TextField, Button, InputAdornment, IconButton, Grid, useMediaQuery, FormControlLabel, Checkbox } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Add, ClearRounded } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { useFormik } from 'formik';
import { useQuery, useMutation } from '@apollo/client';
import * as yup from 'yup';
import clsx from 'clsx';

import { intl, getMaxAvailableDate } from 'helpers';

import { LIST_SPORTS } from 'services/graphql/sports';
import { LIST_COUNTRIES, LIST_CITIES } from 'services/graphql/location';
import {
  UPDATE_LINKED_ACCOUNT,
  //   UPDATE_INSTAGRAM_ACCOUNT
} from 'services/graphql/talent';

import { AvatarEditable } from 'components/AvatarEditable';
import { Select } from 'components/Select';
import { ChipInput } from 'components/ChipInput';
import { LinkedAccounts } from 'components/LinkedAccounts';
import { Loader } from 'components/Loader';

import { useStyles } from './styles';

export const TalentProfileForm = ({ talentId, talent, refetchTalentData, updateCurrentTalent, talentLoading, talentIsUpdating }) => {
  const styles = useStyles({});
  const isSm = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const { state } = useLocation();

  const isRequiredField = !!state?.referrer;

  const validationSchema = yup.object({
    firstName: yup.string().required('ERRORS.emptyField').nullable(),
    lastName: yup.string().required('ERRORS.emptyField').nullable(),
    image: isRequiredField ? yup.mixed().required('ERRORS.emptyField') : yup.mixed().notRequired(),
    gender: yup.string().required('ERRORS.emptyField').nullable(),
    private: yup.mixed().notRequired(),
    club: yup.array().of(yup.string()).notRequired(),
    tags: yup.array().of(yup.mixed()).notRequired().nullable(),
    country: yup
      .object({
        name: yup.mixed().required('ERRORS.emptyField'),
      })
      .nullable(),
    city: yup
      .object({
        name: yup.mixed().required('ERRORS.emptyField'),
      })
      .nullable(),
    sport: yup
      .object({
        name: yup.mixed().required('ERRORS.emptyField'),
      })
      .nullable(),
    competition: yup.string().required('ERRORS.emptyField'),
    birth: yup.mixed().required('ERRORS.emptyField'),
  });

  const genders = [
    { value: 'male', name: intl('SHARED.male') },
    { value: 'female', name: intl('SHARED.female') },
  ];

  const profile = [
    { value: 'public', name: intl('SHARED.public') },
    { value: 'private', name: intl('SHARED.private') },
  ];

  const activity = [
    { value: 'true', name: intl('SHARED.competitive') },
    { value: 'false', name: intl('SHARED.retired') },
  ];

  const populateForm = (getTalentProfile) => {
    if (getTalentProfile) {
      const getHideField = (field) => getTalentProfile.fieldsVisibility.find(({ fieldName }) => fieldName === field).isVisible;
      setValues(
        {
          ...getTalentProfile,
          firstName: getTalentProfile?.firstName || '',
          lastName: getTalentProfile?.lastName || '',
          gender: getTalentProfile?.gender || '',
          club: getTalentProfile?.club || [''],
          private: getTalentProfile?.isPrivate ? profile[1].value : profile[0].value,
          competition: getTalentProfile?.competition === null ? undefined : getTalentProfile?.competition,
          hideBirth: !getHideField('age'),
          hideGender: !getHideField('gender'),
        },
        false,
      );
    }
  };

  useEffect(() => {
    populateForm(talent);
  }, [talent]);

  const [updateLinkedAccount] = useMutation(UPDATE_LINKED_ACCOUNT);
  // const [updateInstagramAccount] = useMutation(UPDATE_INSTAGRAM_ACCOUNT);

  const handleUserUpdate = async (valuesToUpdate) => {
    await updateCurrentTalent({
      variables: {
        talentId,
        firstName: valuesToUpdate.firstName.trim(),
        lastName: valuesToUpdate.lastName.trim(),
        cityId: valuesToUpdate?.city?.id,
        sportId: valuesToUpdate?.sport?.id,
        competition: valuesToUpdate.competition === 'true' || valuesToUpdate.competition === true,
        isPrivate: valuesToUpdate.private === profile[1].value,
        club: valuesToUpdate.club?.filter((club) => club).map((club) => club.trim()),
        tags: valuesToUpdate.tags?.map((tag) => tag.trim()),
        about: valuesToUpdate.about,
        gender: valuesToUpdate.gender,
        image: valuesToUpdate.uploadImage,
        birth: +valuesToUpdate.birth,
        fieldsVisibility: [
          { fieldName: 'gender', isVisible: !valuesToUpdate.hideGender },
          { fieldName: 'age', isVisible: !valuesToUpdate.hideBirth },
        ],
      },
    });
    await refetchTalentData();
  };

  const handleUpdateLinkedAccount = (provider, payload) => {
    updateLinkedAccount({
      variables: {
        provider,
        payload,
      },
    });
  };

  const { values, errors, handleChange, setValues, setFieldValue, setFieldTouched, handleSubmit } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      image: '',
      gender: '',
      birth: null,
      hideBirth: false,
      hideGender: false,
      private: '',
      club: [''],
      tags: [],
      country: '',
      city: '',
      sport: '',
      status: '',
      uploadImage: '',
    },
    validationSchema,
    validateOnChange: true,
    onSubmit: handleUserUpdate,
  });

  const { data: sports, loading: sportsLoading } = useQuery(LIST_SPORTS);
  const { data: countries, loading: countriesLoading } = useQuery(LIST_COUNTRIES);
  const { data: cities, loading: citiesLoading } = useQuery(LIST_CITIES, {
    skip: !values.country?.id,
    variables: {
      countryId: values.country && values.country.id,
    },
  });

  const handleAutocompleteChange = (name) => (_, value) => {
    if (name === 'country' && value !== values.country) {
      setFieldValue('city', null);
    }

    setFieldValue(name, value);
  };

  const handleAddClub = () => {
    setFieldValue('club', [...values.club, '']);
  };

  const handleRemoveClub = (index) => {
    setFieldValue(
      'club',
      values.club.filter((_, i) => i !== index),
    );
  };

  const handleTagsChange = (tags) => {
    setFieldValue('tags', tags);
  };

  const handleImageChange = (key) => {
    setFieldValue('uploadImage', key);
  };

  const handleAgeChange = (date) => {
    setFieldTouched('birth', true);
    setFieldValue('birth', date);
  };

  if (talentLoading) {
    return <Loader className={styles.loader} />;
  }

  return (
    <Box className={styles.publicInfo}>
      <Box className={styles.publicInfoFormAndNav}>
        <Box>
          <Paper
            className={clsx(styles.publicInfoForm, {
              [styles.fullWidth]: talentLoading,
            })}
          >
            <Grid container className={styles.publicInfoBlock}>
              <Grid container item sm={6} justify="space-between" alignItems="baseline">
                <AvatarEditable handleSuccess={handleImageChange} error={isRequiredField} image={values.image} imgAlt={values.companyName?.[0]} />
              </Grid>

              <Grid item md={6} sm={6} container>
                <Grid item container sm={12} md={12}>
                  <TextField
                    name="firstName"
                    required
                    error={!!errors.firstName}
                    helperText={intl(errors.firstName)}
                    className={styles.input}
                    value={values.firstName}
                    onChange={handleChange}
                    label={intl('SHARED.firstName')}
                  />
                </Grid>
                <Grid item container sm={12} md={12}>
                  <TextField
                    name="lastName"
                    required
                    error={!!errors.lastName}
                    helperText={intl(errors.lastName)}
                    className={styles.input}
                    value={values.lastName}
                    onChange={handleChange}
                    label={intl('SHARED.lastName')}
                  />
                </Grid>
                <Grid item container sm={12} md={12}>
                  <Select
                    label={intl('SHARED.status')}
                    containerProps={{ className: styles.input }}
                    onChange={handleChange}
                    name="private"
                    options={profile}
                    value={values.private}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container className={styles.publicInfoBlock}>
              <Grid item container xs={12} sm={6}>
                <Select
                  name="gender"
                  helperText={intl(errors.gender) || (isRequiredField && !values?.gender && intl('ERRORS.required'))}
                  containerProps={{
                    className: clsx(styles.input, styles.noMarginInput),
                    error: !!errors?.gender || (isRequiredField && !values?.gender),
                  }}
                  value={values.gender}
                  onChange={handleChange}
                  label={intl('SHARED.gender')}
                  options={genders}
                />
                <FormControlLabel
                  className={styles.checkboxWrapper}
                  classes={{ label: styles.checkboxLabel }}
                  control={<Checkbox size="small" color="primary" name="hideGender" onChange={handleChange} checked={values.hideGender} />}
                  label={intl('SHARED.hideCheckbox')}
                  disabled={!values.gender}
                />
              </Grid>

              <Grid item container xs={12} sm={6}>
                <KeyboardDatePicker
                  className={clsx(styles.input, styles.noMarginInput, styles.date)}
                  clearable
                  format="dd.MM.yyyy"
                  name="birth"
                  error={!!errors?.birth || (isRequiredField && !values?.birth)}
                  helperText={intl(errors.birth) || (isRequiredField && !values?.birth && intl('ERRORS.required'))}
                  value={values.birth}
                  maxDate={getMaxAvailableDate()}
                  label={intl('SHARED.birthDate')}
                  onChange={handleAgeChange}
                  fullWidth
                  disableFuture
                />
                <FormControlLabel
                  className={styles.checkboxWrapper}
                  classes={{ label: styles.checkboxLabel }}
                  control={<Checkbox size="small" color="primary" name="hideBirth" onChange={handleChange} checked={values.hideBirth} />}
                  label={intl('SHARED.hideCheckbox')}
                />
              </Grid>
            </Grid>

            <Grid container className={styles.publicInfoBlock}>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  name="country"
                  loading={countriesLoading}
                  className={styles.input}
                  options={countries ? countries.listCountries : []}
                  value={values?.country}
                  onChange={handleAutocompleteChange('country')}
                  getOptionLabel={(option) => {
                    return option.name || '';
                  }}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors?.country?.name || (isRequiredField && !values?.country?.name)}
                      helperText={intl(errors.country?.name) || (isRequiredField && !values?.country?.name && intl('ERRORS.required'))}
                      label={intl('SHARED.country')}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  name="city"
                  loading={citiesLoading}
                  className={styles.input}
                  disabled={!values.country?.id}
                  options={cities ? cities.listCities : []}
                  value={values?.city}
                  onChange={handleAutocompleteChange('city')}
                  getOptionLabel={(option) => option.name || ''}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors?.city?.name || (isRequiredField && !values?.city?.name)}
                      helperText={intl(errors.city?.name) || (isRequiredField && !values?.city?.name && intl('ERRORS.required'))}
                      name="city"
                      label={intl('SHARED.city')}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container className={styles.publicInfoBlock}>
              <Grid item container xs={12} sm={6}>
                <Autocomplete
                  name="sport"
                  loading={sportsLoading}
                  className={styles.input}
                  options={sports ? sports.listSports : []}
                  value={values.sport}
                  onChange={handleAutocompleteChange('sport')}
                  getOptionLabel={(option) => option.name || ''}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors?.sport?.name || (isRequiredField && !values?.sport?.name)}
                      helperText={intl(errors.sport?.name) || (isRequiredField && !values?.sport?.name && intl('ERRORS.required'))}
                      label={intl('SHARED.kindOfSport')}
                    />
                  )}
                />
              </Grid>
              <Grid item container xs={12} sm={6}>
                <Select
                  label={intl('SHARED.activity')}
                  onChange={handleChange}
                  name="competition"
                  containerProps={{ className: clsx(styles.input), error: !!errors.competition || (isRequiredField && !values.competition) }}
                  options={activity}
                  className={styles.smallPadding}
                  value={`${values.competition ?? ''}`}
                  helperText={intl(errors.competition) || (isRequiredField && !values.competition && intl('ERRORS.required'))}
                />
              </Grid>
              <Grid item container xs={12} sm={6}>
                <TextField
                  name="club.0"
                  className={styles.input}
                  value={values.club?.[0] ?? ''}
                  onChange={(e) => setFieldValue('club.0', e.target.value)}
                  label={intl('SHARED.club')}
                />
              </Grid>
              {values.club?.length > 1 &&
                values.club?.map((clubName, i) => {
                  if (i !== 0)
                    return (
                      <Grid item key={`${i}`} container xs={12} sm={6}>
                        <TextField
                          name={`club.${i}`}
                          className={styles.input}
                          value={clubName}
                          onChange={handleChange}
                          label={intl('SHARED.club')}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={() => handleRemoveClub(i)} size="small">
                                  <ClearRounded fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    );
                })}
            </Grid>

            <Button onClick={handleAddClub} startIcon={<Add />} color="primary">
              {intl('TALENT.PUBLIC_INFO.oneMoreClubOrTeam')}
            </Button>
          </Paper>
          <LinkedAccounts
            social={talent?.social}
            onCompleted={refetchTalentData}
            talentId={talentId}
            handleUpdateLinkedAccount={handleUpdateLinkedAccount}
            admin
          />
          <Paper className={styles.about}>
            <ChipInput
              defaultValue={values?.tags}
              handleChange={handleTagsChange}
              fullWidth
              label={isSm ? intl('TALENT.PUBLIC_INFO.tagsShort') : intl('TALENT.PUBLIC_INFO.tags')}
            />
          </Paper>
          <Paper className={styles.about}>
            <TextField name="about" multiline onChange={handleChange} value={values.about || ''} fullWidth label={intl('SHARED.about')} />
          </Paper>
        </Box>
      </Box>

      <Paper className={styles.saveBtnContainer}>
        <Button onClick={handleSubmit} className={styles.saveBtn} disabled={talentIsUpdating} color="primary" variant="contained">
          {intl('SAVE_BAR.save')}
        </Button>
      </Paper>
    </Box>
  );
};

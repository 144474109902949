export const getSocialCount = (socials, provider, onlyConfirmed = true) => {
  if (!socials || !socials.length || !provider) return null;

  let count;

  if (onlyConfirmed) {
    count = socials?.find((social) => social.providerName === provider && social.confirmed)?.impressionsCount;
  } else {
    count = socials?.find((social) => social.providerName === provider)?.impressionsCount;
  }

  if (count !== 0 && !count) return null;

  return count;
};

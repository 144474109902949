import React from 'react'
import { Fab, Badge } from '@material-ui/core'
import { ModeComment } from '@material-ui/icons'
import clsx from 'clsx'

import { useStyles } from './styles'

const ChatFAB = ({ onClick, className = '', unreadCount, ...props }) => {
  const classes = useStyles()
  return (
    <Fab className={clsx(classes.btnChat, className)} onClick={onClick} {...props}>
      <Badge
        classes={{ badge: classes.badge }}
        badgeContent={unreadCount}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ModeComment />
      </Badge>
    </Fab>
  )
}

export default ChatFAB

import React, { FC } from 'react';
import { Button, Typography, TextField, Box } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import { useFormik } from 'formik';
import { useMutation, ApolloCache } from '@apollo/client';

import { intl } from 'helpers';
import { useAuth } from 'hooks/auth/index';

import { CREATE_FEEDBACK } from 'services/graphql/feedback';
import { LIST_NOTIFICATIONS } from 'services/graphql/query/notification';
import { GET_OPPORTUNITY } from 'services/graphql/query/opportunity';
import type { UserNotifications, OpportunityNotification } from 'types/notification';
import type { Opportunity } from 'types/opportunity';
import { UserTypeEnum } from 'types/common';

import { Modal } from 'components/Modal';

import { Props, InitialValues } from './types';
import { useStyles } from './styles';

const RateModal: FC<Props> = ({ open, opportunityId, handleClose, handleSuccess = () => {}, updateNotification }) => {
  const { getRole } = useAuth();
  const styles = useStyles();

  const initialValues: InitialValues = { description: '', rate: 0 };

  const getFeedbackOptions = (values) => ({
    variables: {
      data: {
        opportunityId,
        rate: values.rate,
        description: values.description || undefined,
      },
    },
  });

  const setCacheToNotifications = (cache: ApolloCache<any>) => {
    const qr = cache.readQuery<UserNotifications>({
      query: LIST_NOTIFICATIONS,
      variables: {
        filter: {
          limit: 50,
        },
        onlyUnreadCount: false,
      },
    });
    const { items, meta } = qr.listUserNotifications;
    const item = items.find((i) => (i as OpportunityNotification).opportunity?.id === opportunityId);
    const newItems = items.map((i) => {
      if (i.id === item.id) {
        const buff = i as OpportunityNotification;
        return {
          ...buff,
          opportunity: {
            ...buff.opportunity,
            isRated: true,
          },
        };
      }
      return i;
    });

    cache.writeQuery({
      query: LIST_NOTIFICATIONS,
      variables: {
        filter: {
          limit: 50,
        },
        onlyUnreadCount: false,
      },
      data: {
        listUserNotifications: {
          items: newItems,
          meta,
          __typename: 'UserNotifications',
        },
      },
    });
  };

  const setCacheToOpportunity = (cache: ApolloCache<any>) => {
    // Update opportunity rating
    try {
      const { getOpportunity } = cache.readQuery<{ getOpportunity: Opportunity }>({
        query: GET_OPPORTUNITY,
        variables: {
          opportunityId,
        },
      });
      cache.writeQuery({
        query: GET_OPPORTUNITY,
        variables: {
          opportunityId,
        },
        data: {
          getOpportunity: {
            ...getOpportunity,
            isRated: true,
          },
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('[Updating cache error]', error);
    }
  };

  const [createFeedback, { loading }] = useMutation(CREATE_FEEDBACK, {
    onCompleted: () => {
      handleSuccess();
      handleClose();
    },
    update: (cache) => {
      setCacheToOpportunity(cache);

      if (!updateNotification) return;
      setCacheToNotifications(cache);
    },
  });

  const handleFeedbackCreate = (values: InitialValues) => {
    createFeedback(getFeedbackOptions(values));
  };

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues,
    onSubmit: handleFeedbackCreate,
  });

  return (
    <Modal
      isOpen={open}
      title={intl(`RATE.rate${getRole() === UserTypeEnum.brand ? 'Talent' : 'Brand'}`)}
      handleClose={handleClose}
      dialogActionsComponent={
        <>
          <Button variant="outlined" onClick={handleClose} className={styles.modalButtons} disabled={loading}>
            {intl('SHARED.cancel')}
          </Button>
          <Button
            onClick={() => handleSubmit()}
            variant="contained"
            className={styles.modalButtons}
            color="primary"
            disabled={loading || values.rate === 0}
          >
            {intl('SHARED.confirm')}
          </Button>
        </>
      }
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography className={styles.smallText}>{intl(`RATE.rateText${getRole() === UserTypeEnum.brand ? 'Talent' : 'Brand'}`)}</Typography>
        <Rating value={Number(values.rate)} onChange={handleChange} name="rate" size="large" classes={{ root: styles.rating }} />
        <TextField
          className={styles.textField}
          label={intl('RATE.addCommentToRating')}
          multiline
          name="description"
          value={values.description}
          onChange={handleChange}
          fullWidth
        />
      </Box>
    </Modal>
  );
};

export { RateModal };

import React from 'react';
import { Box, Button } from '@material-ui/core';
import { intl } from 'helpers';
import { useStyles } from './LinkedAccountActionsStyle';

export const LinkedAccountActions = ({
  account,
  handleLink,
  handleUnlink,
  confirmed = true,
  twitter = false,
  admin = false,
  toggleAccountVisibility = () => {},
}) => {
  const styles = useStyles({});

  return (
    <Box className={styles.connectBlock}>
      {account ? (
        <Box display="flex">
          <Button color="primary" onClick={handleUnlink}>
            {intl('SHARED.remove')}
          </Button>
          <Button color="primary" onClick={handleLink}>
            {intl('SHARED.change')}
          </Button>
          {admin && !twitter && (
            <Button onClick={toggleAccountVisibility} color="primary">
              {!confirmed ? intl('SHARED.show') : intl('SHARED.hide')}
            </Button>
          )}
        </Box>
      ) : (
        <Box display="flex">
          <Button onClick={handleLink} color="primary">
            {/* {intl('TALENT.PUBLIC_INFO.connectAnAccount')} */}
            {twitter ? intl('TALENT.PUBLIC_INFO.connectAnAccount') : intl('TALENT.PUBLIC_INFO.addAnAccount')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

import React from 'react'
import clsx from 'clsx'
import { Paper, Typography, Box, Chip } from '@material-ui/core'

import { useStyles } from './styles'
import { intl } from '../../../../../helpers'

export const Tags = ({ className = '', talent }) => {
  const styles = useStyles({})
  return talent?.tags?.length ? (
    <Paper className={clsx(styles.paper, styles.publicPageServices, className)}>
      <Typography className={styles.sectionHeading} component="h4">
        {intl('SHARED.services')}
      </Typography>

      <Box>
        {talent?.tags?.map((el) => (
          <Chip key={el} className={styles.chip} label={el} />
        ))}
      </Box>
    </Paper>
  ) : null
}

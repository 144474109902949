import React, { ChangeEvent } from 'react';
import { Box, Typography, Grid, TextField, InputAdornment } from '@material-ui/core';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useQuery, useMutation } from '@apollo/client';

import { intl } from 'helpers';
import { GET_PROFIT_MARGINS, CREATE_PROFIT_MARGINS } from 'services/graphql/common';
import { ProfitMargins, ProfitMarginsTypeEnum } from 'types/profitMargins';

import { Wrapper } from 'components/Wrapper';
import { Loader } from 'components/Loader';
import { SaveBar } from 'components/SaveBar';

import { useStyles } from './styles';

const validationSchema = yup.object({
  lowPriceRange: yup
    .number()
    .integer('ERRORS.mustBeAnInteger')
    .typeError('ERRORS.numberType')
    .positive()
    .required('ERRORS.emptyField')
    .max(100, 'ERRORS.maxPercentValue')
    .min(1, 'ERRORS.minPercentValue'),
  mediumPriceRange: yup
    .number()
    .integer('ERRORS.mustBeAnInteger')
    .typeError('ERRORS.numberType')
    .positive()
    .required('ERRORS.emptyField')
    .max(100, 'ERRORS.maxPercentValue')
    .min(1, 'ERRORS.minPercentValue'),
  highPriceRange: yup
    .number()
    .integer('ERRORS.mustBeAnInteger')
    .typeError('ERRORS.numberType')
    .positive()
    .required('ERRORS.emptyField')
    .max(100, 'ERRORS.maxPercentValue')
    .min(1, 'ERRORS.minPercentValue'),
});

const ProfitMarginsPage = () => {
  const classes = useStyles();

  const initialValues = {
    lowPriceRange: 0,
    mediumPriceRange: 0,
    highPriceRange: 0,
  };

  const { values, errors, touched, validateForm, setFieldValue, setFieldTouched, setValues, setTouched } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: () => {},
  });

  const setLoadedValues = (getProfitMargins: ProfitMargins) => {
    const { firstRange, secondRange, thirdRange } = getProfitMargins;

    setValues({
      lowPriceRange: firstRange.percentage,
      mediumPriceRange: secondRange.percentage,
      highPriceRange: thirdRange.percentage,
    });
  };

  const [createMargins, { loading, data: newMargins }] = useMutation(CREATE_PROFIT_MARGINS, {
    variables: {
      data: {
        firstRangePercentage: +values.lowPriceRange,
        secondRangePercentage: +values.mediumPriceRange,
        thirdRangePercentage: +values.highPriceRange,
        profitMarginsType: ProfitMarginsTypeEnum.platform,
      },
    },
    onCompleted: () => setTouched({}),
  });

  const { data, loading: loadingMargins } = useQuery(GET_PROFIT_MARGINS, {
    onCompleted: ({ getProfitMargins }) => setLoadedValues(getProfitMargins),
  });

  const handleSubmit = async () => {
    const formErrors = await validateForm();
    if (Object.keys(formErrors).length) return;

    createMargins();
  };

  const handleCancel = () => {
    setLoadedValues(newMargins?.createProfitMargins ?? data?.getProfitMargins ?? initialValues);
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>, name: string) => {
    const { value } = event.target;
    if (initialValues[name] !== +value) setFieldTouched(name, true);
    else setFieldTouched(name, false);

    setFieldValue(name, value);
  };

  const showSaveBar = !!Object.keys(touched).length;

  return (
    <Wrapper className={classes.wrapper}>
      {loadingMargins ? (
        <Loader />
      ) : (
        <>
          <Box className={classes.main}>
            <Typography className={classes.title} variant="h6">
              {intl('ADMIN.PROFIT.profitMargins')}
            </Typography>
            <Grid container spacing={3} component="form">
              <Grid item lg={4}>
                <TextField
                  fullWidth
                  name="lowPriceRange"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                  }}
                  value={values.lowPriceRange}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e, 'lowPriceRange')}
                  error={!!errors.lowPriceRange}
                  helperText={intl(errors.lowPriceRange)}
                  label={intl('ADMIN.PROFIT.lowPriceRange')}
                />
              </Grid>
              <Grid item lg={4}>
                <TextField
                  fullWidth
                  name="mediumPriceRange"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                  }}
                  value={values.mediumPriceRange}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e, 'mediumPriceRange')}
                  error={!!errors.mediumPriceRange}
                  helperText={intl(errors.mediumPriceRange)}
                  label={intl('ADMIN.PROFIT.mediumPriceRange')}
                />
              </Grid>
              <Grid item lg={4}>
                <TextField
                  fullWidth
                  name="highPriceRange"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                  }}
                  value={values.highPriceRange}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e, 'highPriceRange')}
                  error={!!errors.highPriceRange}
                  helperText={intl(errors.highPriceRange)}
                  label={intl('ADMIN.PROFIT.highPriceRange')}
                />
              </Grid>
            </Grid>
          </Box>
          {showSaveBar && <SaveBar loading={loading} className={classes.saveBar} onCancel={handleCancel} onSave={handleSubmit} />}
        </>
      )}
    </Wrapper>
  );
};

export default ProfitMarginsPage;

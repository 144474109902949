/* eslint-disable max-len */
import React from 'react'

const TalentsIcon = ({ fill, ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 8C16 10.21 14.21 12 12 12C9.79 12 8 10.21 8 8C8 5.79 9.79 4 12 4C14.21 4 16 5.79 16 8ZM4 18C4 15.34 9.33 14 12 14C12.303 14 12.6402 14.0173 13 14.0517V17H15V20H5C4.45 20 4 19.55 4 19V18Z"
      fill={fill || '#535E83'}
    />
    <path d="M20 14V15.1709H18.2873V20H16.7127V15.1709H15V14H20Z" fill={fill || '#535E83'} />
  </svg>
)

export default TalentsIcon

import { makeStyles } from '@material-ui/core';
import { colors } from 'theme/colors';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: 392,
  },
  buttons: {
    width: 152,
    marginRight: theme.spacing(2),
    '&:last-child': {
      marginRight: 0,
    },
  },
  bigText: {
    fontWeight: 500,
    fontSize: 18,
    '&:last-of-type': {
      marginBottom: theme.spacing(3),
    },
  },
  error: {
    background: colors.LIGHT_ERROR,
    padding: theme.spacing(1, 1.5),
  },
  paymentErrorBox: {
    minHeight: 38,
    width: '100%',
    padding: '8px 12px',
    background: 'rgba(255, 81, 81, 0.2)',
    border: '1px solid rgba(255, 81, 81, 0.1)',
    borderRadius: 4,
  },
  paymentError: {},
  errorText: {
    fontSize: 12,
  },
  content: {
    paddingBottom: 12,
  },
  success: {
    fontSize: 24,
    fontWeight: 500,
    marginBottom: 130,
    textAlign: 'center',
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
  successContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(3),
  },
  successBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  questionIconBox: {
    padding: 30,
    paddingBottom: 25,
    background: colors.BACKGROUND,
    borderRadius: '100%',
  },
  paymentSuccessfulText: {
    marginTop: theme.spacing(3),
    fontSize: 24,
    fontWeight: 500,
  },
  btnSmallText: {
    fontSize: 12,
  },
}));

import React, { useState, ChangeEvent, FC } from 'react';
import { RadioGroup, FormControlLabel, Radio, Button, Box } from '@material-ui/core';
import { UpdateOpportunityStrategyEnum } from 'types/opportunity';
import { Modal } from 'components/Modal';
import { intl } from 'helpers';

import { useStyles } from './styles';
import { Props } from './types';

const THIS_OPPORTUNITY = UpdateOpportunityStrategyEnum.single;
const THIS_AND_FOLLOWING_OPPORTUNITY = UpdateOpportunityStrategyEnum.series;

const EditRepeatedModal: FC<Props> = ({ open, loading, handleClose, handleUpdateOpportunity }) => {
  const classes = useStyles();
  const [repeat, setRepeat] = useState<UpdateOpportunityStrategyEnum>(THIS_OPPORTUNITY);

  const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => setRepeat(value as UpdateOpportunityStrategyEnum);
  const handleSubmit = () => handleUpdateOpportunity(repeat);

  const ModalActions = () => (
    <Box className={classes.actionsContainer}>
      <Button className={classes.btnCancel} variant="outlined" disabled={loading} onClick={handleClose}>
        {intl('SHARED.cancel')}
      </Button>
      <Button className={classes.btnYes} disabled={loading} onClick={handleSubmit} variant="contained" color="primary">
        {intl('SHARED.yes')}
      </Button>
    </Box>
  );

  return (
    <Modal
      isOpen={open}
      handleClose={handleClose}
      className={classes.modal}
      dialogActionsComponent={<ModalActions />}
      title={intl('BRAND.OPPORTUNITY.editRepeated')}
    >
      <RadioGroup aria-label="edit-repeated-opportunity-modal" value={repeat} onChange={handleChange}>
        <FormControlLabel value={THIS_OPPORTUNITY} control={<Radio color="primary" />} label={intl('BRAND.OPPORTUNITY.thisOpportunity')} />
        <FormControlLabel
          value={THIS_AND_FOLLOWING_OPPORTUNITY}
          control={<Radio color="primary" />}
          label={intl('BRAND.OPPORTUNITY.thisAndFollowingOpp')}
        />
      </RadioGroup>
    </Modal>
  );
};

export default EditRepeatedModal;

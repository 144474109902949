import { makeStyles, Theme } from '@material-ui/core';
import { colors } from 'theme/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  modal: {},
  paper: {
    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
    overflow: 'initial'
  },
  box: {
    height: 700,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundSize: 'auto 100%',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('md')]: {
      height: 360,
    },
  },
  image: {
    objectFit: 'cover',
    maxHeight: 700,
    minHeight: 240,

  },
  btnGroup: {
    height: '100%',
    width: '100%',
    minHeight: 240,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    top: '50%',
    transform: 'translateY(-50%)'
  },
  btnClose: {
    alignSelf: 'flex-end',
    marginTop: theme.spacing(2),
  },
  navigationBox: {
    alignSelf: 'center',
    marginBottom: theme.spacing(2),
  },
  icon: {
    fill: colors.WHITE,
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
  },
  iconBox: {
    marginRight: theme.spacing(2),
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: colors.BLACK,
    },
  },
}));

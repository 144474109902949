const PENDING = {
  label: 'APPLICANT_STATUS.pending',
  value: 'Pending',
}

const DECLINED = {
  label: 'APPLICANT_STATUS.declined',
  value: 'Declined',
}

const CONFIRMED = {
  label: 'APPLICANT_STATUS.confirmed',
  value: 'Confirmed',
}

const CANCELED = {
  label: 'APPLICANT_STATUS.canceled',
  value: 'Canceled',
}

const STATUSES = {
  PENDING,
  DECLINED,
  CONFIRMED,
  CANCELED,
}

export { STATUSES }

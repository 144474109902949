import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  publicPageContent: {
    flex: 1,
    marginLeft: 24,
    '@media (max-width: 768px)': {
      marginLeft: 0,
    },
  },
})

import { useState } from 'react';
import { useQuery } from '@apollo/client';

import { GET_BRAND_REQUIRED_FIELDS } from 'services/graphql/query/opportunity';
import type { BrandMainInfo, BrandValidateError } from 'types/brand';
import { VerificationRequestStatusesEnum } from 'types/talent';

const validateBrand = (info: BrandMainInfo): BrandValidateError => {
  const errors: BrandValidateError = {
    hasProfileInfo: true,
    hasBrandInfo: true,
    isVerified: true,
  };

  if (!info.firstName?.length || !info.lastName?.length || !info.phone?.length || !info?.brandImage) {
    errors.hasProfileInfo = false;
  }
  if (info.verificationStatus !== VerificationRequestStatusesEnum.Verified) {
    errors.isVerified = false;
  }
  if (
    !info.companyName?.length ||
    info.industry?.name === null ||
    info.country?.name === null ||
    info.city?.name === null ||
    !info.privateInfo?.street ||
    !info.privateInfo?.postalCode ||
    !info.privateInfo?.VATnumber ||
    !info.privateInfo?.IBAN ||
    !info.privateInfo?.bankAccount ||
    info.companyImage === null
  ) {
    errors.hasBrandInfo = false;
  }

  return errors;
};

// TODO: add types
export const useBrandsErrors = (skip = false) => {
  const [brandErrors, setBrandErrors] = useState({
    hasProfileInfo: true,
    hasBrandInfo: true,
    isVerified: true,
  });

  const { loading: brandLoading } = useQuery(GET_BRAND_REQUIRED_FIELDS, {
    skip,
    fetchPolicy: 'no-cache',
    onCompleted: ({ getCurrentBrand, getBrandUser }) => {
      // rename to prevent rewriting same properties
      getCurrentBrand.companyImage = getCurrentBrand.image;
      getBrandUser.brandImage = getBrandUser.image;

      setBrandErrors(validateBrand({ ...getCurrentBrand, ...getBrandUser }));
    },
  });

  return {
    brandErrors,
    brandLoading,
  };
};

import { makeStyles } from '@material-ui/core';
import { colors } from 'theme/colors';

export const useStyles = makeStyles((theme) => ({
  chatContainer: {
    height: '100%',
    overflow: 'auto',
  },
  chatModalContent: {},
  arrowIcon: {
    marginLeft: 12,
    fontSize: '14px!important',
  },
  btnBack: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4),
    paddingRight: theme.spacing(2),
    width: 'max-content',
    border: `1px solid ${colors.WHITE_DARKEN}`,
    borderRadius: 4,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: '0.03em',
  },
}));

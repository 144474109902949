import React from 'react';

const ChatIcon = ({ className }) => {
  return (
    <svg width="72" height="72" className={className} viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M27 6H63C64.65 6 66 7.35 66 9V42L57 33H27C25.35 33 24 31.65 24 30V9C24 7.35 25.35 6 27 6Z" fill="#838CA7" />
        <path d="M36 30H9C7.35 30 6 31.35 6 33V66L15 57H36C37.65 57 39 55.65 39 54V33C39 31.35 37.65 30 36 30Z" fill="#838CA7" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 73.2426V33C3 29.6931 5.69315 27 9 27H36C39.3069 27 42 29.6931 42 33V54C42 57.3069 39.3069 60 36 60H16.2426L3 73.2426ZM15 57H36C37.65 57 39 55.65 39 54V33C39 31.35 37.65 30 36 30H9C7.35 30 6 31.35 6 33V66L15 57Z"
          fill="#F6F6F6"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="72" height="72" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { ChatIcon };

import React, { FC } from 'react';
import { TableCell, TableRow } from '@material-ui/core';

import { Loader } from '../Loader';

import { useStyles } from './styles';

type Props = {
  columnCount: number;
  height?: number;
};

export const TableLoader: FC<Props> = ({ columnCount, height }) => {
  const classes = useStyles({});
  return (
    <TableRow style={{ height }}>
      <TableCell colSpan={columnCount} align="right">
        <Loader className={classes.loader} />
      </TableCell>
    </TableRow>
  );
};

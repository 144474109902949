import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  loadingWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: 120,
    height: 120,
  }
})

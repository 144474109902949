/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { Box, TextField, InputAdornment, Hidden, IconButton, Tabs, Tab, useMediaQuery, Button, Typography } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { Link, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { useQuery } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';

import { intl } from 'helpers';
import { routes } from 'router/routesList';
import { removeEmpty } from 'helpers/common';
import { FilterIcon, FilterIconActive, DesertImage, SearchFilter } from 'assets/svg-js';
import { TALENTS_BRAND_LIST } from 'services/graphql/talent';
import type { Country, Industry, City } from 'types/opportunity';

import { Wrapper } from 'components/Wrapper';
import { Loader } from 'components/Loader';
import { BrandCard } from 'components/BrandCard';
import { TalentBrandsFilters } from 'components/TalentBrandsFilters';

import { TabPanel, a11yProps } from './components/TabPanel';
import { BrandTab } from './types';
import { useStyles } from './styles';

const LIMIT = 10;

const Brands = () => {
  const { tab: tabParam } = useParams();
  const isSm = useMediaQuery<any>((theme) => theme.breakpoints.down('sm'));
  const [search, setSearch] = useState('');
  const [mobileFiltersOpened, setMobileFiltersOpened] = useState(false);
  const [filters, setFilters] = useState({ tab: tabParam, country: [], city: [], industry: [] });
  const classes = useStyles();

  const allTabRoute = `${routes.TALENT_BRANDS}/${BrandTab.all}`;
  const favoritesTabRoute = `${routes.TALENT_BRANDS}/${BrandTab.favorites}`;

  const toggleMobileFilters = () => setMobileFiltersOpened((isOpened) => !isOpened);

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleChange = useCallback(
    debounce((value: string) => {
      handleSearch(value);
    }, 1000),
    [],
  );

  const handleTabChange = (_, newValue) => {
    setFilters((oldFilters) => ({
      ...oldFilters,
      tab: newValue,
    }));
  };

  const queryOptions = {
    variables: {
      filter: {
        offset: 0,
        limit: LIMIT,
        options: {
          cityId: filters?.city?.map((city: City) => city.id),
          countryId: filters?.country?.map((country: Country) => country.id),
          industryId: filters?.industry?.map((industry: Industry) => industry.id),
          onlyFavourite: filters.tab === BrandTab.favorites,
        },
      },
      search,
    },
  };

  const { data, loading, fetchMore, refetch } = useQuery<any>(TALENTS_BRAND_LIST, queryOptions);

  useEffect(() => {
    setFilters(({ tab }) => ({ tab, country: [], city: [], industry: [] }));
    refetch(queryOptions);
  }, [filters.tab]);

  const onShowMore = () => {
    const newQueryOptions = cloneDeep(queryOptions);
    newQueryOptions.variables.filter.offset = data?.talentListBrands?.items?.length;
    fetchMore({
      ...newQueryOptions,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        if (fetchMoreResult?.talentListBrands?.meta?.total < LIMIT) return prev;
        return {
          ...prev,
          talentListBrands: {
            ...prev.talentListBrands,
            items: [...prev.talentListBrands.items, ...fetchMoreResult?.talentListBrands?.items],
          },
        };
      },
    });
  };

  const onApply = (values, applyFromBtn) => {
    const valuesToSet = removeEmpty({ ...values });

    setFilters({
      tab: filters.tab,
      ...valuesToSet,
    });

    if (applyFromBtn) {
      toggleMobileFilters();
    }
  };

  const onApplyDesktop = (values) => {
    const valuesToSet = removeEmpty({ ...values });

    setFilters({
      tab: filters.tab,
      ...valuesToSet,
    });
  };

  const checkFiltersForNonEmpty = () => {
    const { tab, ...restFilters } = filters;
    return restFilters && Object.values(restFilters).length && Object.values(restFilters).some((val) => (Array.isArray(val) ? val.length : val));
  };

  const noResults = !!(
    ((filters && Object.keys(filters).some((key) => Array.isArray(filters[key]) && filters[key].length)) || search.length) &&
    !data?.talentListBrands?.items.length
  );

  return (
    <Wrapper>
      <Box className={classes.container}>
        <Hidden smDown>
          <TalentBrandsFilters handleApply={onApplyDesktop} filters={filters} />
        </Hidden>
        <Box
          className={clsx(classes.rightBlock, {
            [classes.fullHeight]: filters.tab === BrandTab.favorites,
          })}
        >
          <Box className={clsx(classes.inputAndTabs, mobileFiltersOpened && isSm && classes.zeroMargin)}>
            {!mobileFiltersOpened && (
              <TextField
                className={classes.search}
                onChange={({ target: { value } }) => handleChange(value)}
                placeholder={intl('TALENT_OPPORTUNITIES.search')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            <Tabs
              value={filters.tab}
              className={clsx(classes.tabs, mobileFiltersOpened && classes.zeroMargin)}
              indicatorColor="primary"
              onChange={handleTabChange}
            >
              <Tab
                value={BrandTab.all}
                component={Link}
                to={allTabRoute}
                className={clsx(classes.tabItem, classes.fullHeight)}
                label={intl('BRANDS.allBrands')}
                {...a11yProps(0)}
              />
              <Tab
                value={BrandTab.favorites}
                component={Link}
                className={clsx(classes.tabItem, {
                  [classes.fullHeight]: filters.tab === BrandTab.favorites,
                })}
                to={favoritesTabRoute}
                label={intl('BRANDS.favorites')}
                {...a11yProps(1)}
              />
            </Tabs>
            {!mobileFiltersOpened && (
              <Hidden mdUp>
                <IconButton className={classes.filterIcon} onClick={toggleMobileFilters} size="small">
                  {checkFiltersForNonEmpty() ? <FilterIconActive /> : <FilterIcon />}
                </IconButton>
              </Hidden>
            )}
          </Box>
          <Hidden mdUp>
            {mobileFiltersOpened && (
              <TalentBrandsFilters handleApply={onApply} handleClose={toggleMobileFilters} filters={filters} className={classes.mobileFilters} />
            )}
          </Hidden>
          {loading ? (
            <Loader className={classes.fullHeight} />
          ) : (
            <>
              <TabPanel value={filters.tab} name="all" className={clsx({ [classes.fullHeight]: noResults })}>
                <Box className={clsx({ [classes.fullHeight]: noResults })}>
                  {noResults && (
                    <Box className={classes.emptySearchBox}>
                      <SearchFilter />
                      <Typography className={classes.emptySearchText}>{intl('EMPTY_STATE.noDataByFilters')}</Typography>
                    </Box>
                  )}
                  {data?.talentListBrands?.items.map((i) => (
                    <BrandCard
                      key={i.id}
                      id={i.id}
                      companyName={i?.companyName}
                      country={i?.country}
                      city={i?.city}
                      createdAt={i?.createdAt}
                      image={i?.image}
                      industry={i?.industry}
                      isFavourite={i?.isFavourite}
                      queryOptions={queryOptions}
                    />
                  ))}
                </Box>
                {data?.talentListBrands?.meta?.total > data?.talentListBrands?.items?.length && (
                  <Box className={classes.showMoreContainer}>
                    <Button className={classes.buttonShowMore} variant="outlined" onClick={onShowMore} tabIndex={NaN} component="div" role="none">
                      {intl('SHARED.showMore')}
                    </Button>
                  </Box>
                )}
              </TabPanel>

              <TabPanel
                value={filters.tab}
                name="favorites"
                className={clsx({
                  [classes.fullHeight]: filters.tab === BrandTab.favorites,
                })}
              >
                {data?.talentListBrands?.items?.length ? (
                  <Box>
                    {data?.talentListBrands?.items
                      .filter(({ isFavourite }) => isFavourite)
                      .map((i) => (
                        <BrandCard
                          key={i.id}
                          id={i.id}
                          companyName={i?.companyName}
                          country={i?.country}
                          city={i?.city}
                          createdAt={i?.createdAt}
                          image={i?.image}
                          industry={i?.industry}
                          isFavourite={i?.isFavourite}
                          queryOptions={queryOptions}
                        />
                      ))}
                  </Box>
                ) : noResults ? (
                  <Box className={classes.emptySearchBox}>
                    <SearchFilter />
                    <Typography className={classes.emptySearchText}>{intl('EMPTY_STATE.noDataByFilters')}</Typography>
                  </Box>
                ) : (
                  <Box className={classes.emptyBox}>
                    <DesertImage className={classes.emptyIcon} />
                    <Typography className={classes.emptyText}>{intl('EMPTY_STATE.noFavoriteBrands')}</Typography>
                  </Box>
                )}
                {data?.talentListBrands?.meta?.total > data?.talentListBrands?.items?.length && (
                  <Box className={classes.showMoreContainer}>
                    <Button className={classes.buttonShowMore} variant="outlined" onClick={onShowMore} tabIndex={NaN} component="div" role="none">
                      {intl('SHARED.showMore')}
                    </Button>
                  </Box>
                )}
              </TabPanel>
            </>
          )}
        </Box>
      </Box>
    </Wrapper>
  );
};

export { Brands };

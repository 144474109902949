const BRAND = 'brand';
const OPPORTUNITIES = 'opportunities';
const NEW_OPPORTUNITY = 'new-opportunity';
const EDIT_OPPORTUNITY = 'edit-opportunity';
const TALENT = 'talent';
const STRIPE_CONNECT = 'stripe-connect';
const PUBLIC_INFO = 'public-info';
const PUBLIC_PAGE = 'public-page';
const COMPANY_INFO = 'company-info';
const PROFILE_INFO = 'profile-info';
const RESTORE = 'restore';
const CREATE_PASSWORD = 'create-password';
const LOGIN = '/login';
const SIGN_UP = 'signup';
const APPLIED = 'applied';
const ADMIN = 'admin';
const BRANDS = 'brands';
const TALENTS = 'talents';
const BRAND_MANAGERS = 'brand-managers';
const PAYMENT_HISTORY = 'payment-history';
const CALENDAR = 'calendar';
const ANALYTICS = 'analytics';
const POST = 'post';
const NEW_POST = 'new-post';
const EDIT_POST = 'edit-post';
const NEWS_FEED = 'news-feed';
const PROFIT_MARGINS = 'profit-margins';
const BRAND_VERIFICATION = '/brand/verification';
const BRAND_NEWS_FEED = '/brand/news-feed';
const BRAND_PAYMENT_HISTORY = '/brand/payment-history';
const BRAND_ANALYTICS = '/brand/analytics';

const TALENT_PROFILE = '/talent/profile';
const TALENT_PUBLIC_PAGE = '/talent/profile/public-page';
const TALENT_PUBLIC_INFO = '/talent/profile/public-info';
const TALENT_PRIVATE_INFO = '/talent/profile/private-info';
const TALENT_ACTIVITY_FEED = '/talent/profile/activity-feed';
const TALENT_PAYMENT_HISTORY = '/talent/profile/payment-history';
const TALENT_ANALYTICS = '/talent/profile/analytics';
const TALENT_BRANDS = '/talent/brands';
const TALENT_NEWS_FEED = '/talent/news-feed';

const TALENT_VERIFICATION = '/talent/verification';
const TALENT_BRAND_PAGE = (id = ':id', tab = ':tab') => `/talent/brand/${id}/${tab}`;

const ADMIN_EDIT_OPPORTUNITY = '/admin/edit-opportunity';
const ADMIN_NEWS_FEED = '/admin/news-feed';
const ADMIN_BRAND_PROFILE = (id = ':id') => `/admin/brands/${id}/company-info`;

const ERROR_PAGE = '/error';
/**
 * @readonly
 */
const routes = {
  BRAND,
  OPPORTUNITIES,
  TALENT,
  COMPANY_INFO,
  PUBLIC_INFO,
  PUBLIC_PAGE,
  PROFIT_MARGINS,
  TALENT_STRIPE_CONFIRMATION: `${TALENT}/${STRIPE_CONNECT}`,
  TALENT_CALENDAR: `${TALENT}/${CALENDAR}`,
  TALENT_NEW_POST: `/${TALENT}/${NEW_POST}`,
  TALENT_EDIT_POST: `/${TALENT}/${EDIT_POST}`,
  BRAND_PROFILE_INFO: `${BRAND}/${PROFILE_INFO}`,
  BRAND_COMPANY_INFO: `${BRAND}/${COMPANY_INFO}`,
  BRAND_PUBLIC_PAGE: `/${BRAND}/${PUBLIC_PAGE}`,
  BRAND_CALENDAR: `${BRAND}/${CALENDAR}`,
  NEW_OPPORTUNITY: `${OPPORTUNITIES}/${NEW_OPPORTUNITY}`,
  EDIT_OPPORTUNITY: `${OPPORTUNITIES}/${EDIT_OPPORTUNITY}`,
  OPPORTUNITY: `${OPPORTUNITIES}/:id`,
  RESTORE,
  NEWS_FEED,
  BRAND_NEWS_FEED,
  BRAND_NEWS_FEED_POST: `${BRAND_NEWS_FEED}/${POST}`,
  BRAND_NEW_POST: `/${BRAND}/${NEW_POST}`,
  BRAND_EDIT_POST: `/${BRAND}/${EDIT_POST}`,
  BRAND_VERIFICATION,
  LOGIN,
  PAYMENT_HISTORY,
  SIGN_UP,
  APPLIED,
  ADMIN,
  POST,
  BRANDS,
  TALENTS,
  BRAND_MANAGERS,
  CREATE_PASSWORD,
  ANALYTICS,
  TALENT_ANALYTICS,
  TALENT_NEWS_FEED,
  TALENT_VERIFICATION,
  TALENT_PROFILE,
  TALENT_PUBLIC_PAGE,
  TALENT_PUBLIC_INFO,
  TALENT_PRIVATE_INFO,
  TALENT_ACTIVITY_FEED,
  TALENT_PAYMENT_HISTORY,
  TALENT_EDIT_OPPORTUNITY: `/${TALENT}/${OPPORTUNITIES}/${EDIT_OPPORTUNITY}`,
  TALENT_BRAND_PAGE,
  TALENT_NEWS_FEED_POST: `${TALENT_NEWS_FEED}/${POST}`,
  TALENT_BRANDS,
  BRAND_PAYMENT_HISTORY,
  BRAND_ANALYTICS,
  BRAND_EDIT_OPPORTUNITY: `/${BRAND}/${OPPORTUNITIES}/${EDIT_OPPORTUNITY}`,
  ADMIN_EDIT_OPPORTUNITY,
  ADMIN_EDIT_POST: `/${ADMIN}/${EDIT_POST}`,
  ADMIN_NEW_POST: `/${ADMIN}/${NEW_POST}`,
  ADMIN_NEWS_FEED_POST: `/${ADMIN}/${NEWS_FEED}/${POST}`,
  ADMIN_NEWS_FEED,
  ADMIN_BRAND_PROFILE,
  ERROR_PAGE,
};

export { routes };

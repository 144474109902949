/* eslint-disable max-len */
import React from 'react'

const BrandsIcon = ({ fill, ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.41 2.58L21.41 11.58C21.77 11.94 22 12.45 22 13C22 13.55 21.78 14.05 21.41 14.41L14.41 21.41C14.05 21.78 13.55 22 13 22C12.45 22 11.95 21.78 11.59 21.42L2.59 12.42C2.22 12.05 2 11.55 2 11V4C2 2.9 2.9 2 4 2H11C11.55 2 12.05 2.22 12.41 2.58ZM4 5.5C4 6.33 4.67 7 5.5 7C6.33 7 7 6.33 7 5.5C7 4.67 6.33 4 5.5 4C4.67 4 4 4.67 4 5.5ZM15.7943 10.0034C15.1702 9.9448 14.5788 10.1023 14.0202 10.476C14.3191 9.94957 14.445 9.41827 14.3979 8.88214C14.3585 8.33836 14.1183 7.84599 13.6774 7.40504C13.08 6.80762 12.3739 6.56775 11.5593 6.68541C10.7523 6.79543 9.93994 7.25928 9.12226 8.07696L5.5 11.6992L12.9891 19.1883L16.7374 15.4399C17.578 14.5993 18.05 13.7646 18.1535 12.9357C18.2574 12.0921 17.9965 11.3573 17.3706 10.7315C16.9368 10.2976 16.4114 10.0549 15.7943 10.0034ZM11.7737 11.485L10.4899 12.7688L8.92168 11.2006L10.2055 9.91677C10.5265 9.59582 10.8386 9.41884 11.1418 9.38585C11.4527 9.34522 11.7362 9.45292 11.9922 9.70895C12.2483 9.96499 12.356 10.2485 12.3153 10.5593C12.2752 10.8555 12.0947 11.164 11.7737 11.485ZM15.4752 13.3524C15.4345 13.6633 15.2499 13.983 14.9213 14.3116L13.477 15.7559L11.8234 14.1024L13.2448 12.681C13.5734 12.3524 13.9041 12.171 14.2369 12.137C14.5773 12.0953 14.879 12.206 15.1422 12.4692C15.4053 12.7323 15.5163 13.0267 15.4752 13.3524Z"
      fill={fill || '#535E83'}
    />
  </svg>
)

export default BrandsIcon

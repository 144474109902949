const APPLIED = 'APPLIED'
const DECLINED = 'DECLINED'
const NOT_APPLIED = 'NOT_APPLIED'

const TALENT_STATUS = {
  APPLIED,
  DECLINED,
  NOT_APPLIED,
}

export { TALENT_STATUS }

import React, { FC, ReactElement } from 'react';
import { Typography, Box } from '@material-ui/core';
import { Warning } from '@material-ui/icons';

import { intl } from 'helpers';

import { useStyles } from './styles';
import { Props } from './types';
import { STATUSES } from '../../constants';

const OpportunityStatus: FC<Props> = ({ status, isBrandView = false, hideIcon = true }) => {
  const styles = useStyles();
  let className: string;
  let text: string;
  let icon: ReactElement;

  switch (status) {
    case STATUSES.PAYMENT.value:
      className = styles.payment;
      text = intl(STATUSES.PAYMENT.label);
      icon = <Warning fontSize="small" className={styles.warningIcon} />;
      break;
    case STATUSES.DONE.value:
      className = styles.done;
      text = intl(STATUSES.DONE.label);
      break;
    case STATUSES.WAITING_FOR_PAYMENT.value:
      className = styles.waitingForPayment;
      text = intl(STATUSES.WAITING_FOR_PAYMENT.label);
      break;
    case STATUSES.CANCELED_BY_BRAND.value:
      className = styles.canceled;
      text = intl(STATUSES.CANCELED_BY_BRAND.label);
      break;
    case STATUSES.CANCELED_BY_TALENT.value:
      className = styles.canceled;
      text = intl(STATUSES.CANCELED_BY_TALENT.label);
      break;
    case STATUSES.DECLINED.value:
      className = styles.canceled;
      text = intl(STATUSES.DECLINED.label);
      break;
    case STATUSES.OPEN.value:
      className = styles.open;
      text = intl(STATUSES.OPEN.label);
      break;
    case STATUSES.CONFIRMED.value:
      className = styles.open;
      text = intl(STATUSES.CONFIRMED.label);
      break;
    case STATUSES.CANCELED_BY_ADMIN.value:
      className = styles.canceled;
      text = intl(STATUSES.CANCELED_BY_ADMIN.label);
      break;
    case STATUSES.CANCELED_BY_ADMIN_PAYMENT.value:
      className = isBrandView ? styles.payment : styles.canceled;
      text = intl(STATUSES.CANCELED_BY_ADMIN_PAYMENT.label) + (isBrandView ? '!' : '');
      break;
    case STATUSES.EXPIRED.value:
      className = styles.canceled;
      text = intl(STATUSES.EXPIRED.label);
      break;
    default:
      break;
  }

  return (
    <Box className={styles.container}>
      {!hideIcon && icon && <Box className={styles.iconBox}>{icon}</Box>}
      <Typography variant="caption" className={className} classes={{ root: styles.box }}>
        {text}
      </Typography>
    </Box>
  );
};

export default OpportunityStatus;

import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { Warning as WarningIcon } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import { intl } from 'helpers';
import { routes } from 'router/routesList';

import { Modal } from 'components/Modal';

import { useStyles } from './styles';

const TalentValidationModal = ({ open, handleClose = () => {}, errors }) => {
  const styles = useStyles();
  const { push, location } = useHistory();
  const [currentRoute, setRoute] = useState(null);

  useEffect(() => {
    if (!errors.isVerified) {
      setRoute({
        pathname: routes.TALENT_VERIFICATION,
        state: { referrer: location.pathname },
      });
    }
    if (!errors.hasPrivateInfo) {
      setRoute({
        pathname: routes.TALENT_PRIVATE_INFO,
        state: { referrer: location.pathname },
      });
    }
    if (!errors.hasPublicInfo) {
      setRoute({
        pathname: routes.TALENT_PUBLIC_INFO,
        state: { referrer: location.pathname },
      });
    }
  }, [errors]);

  const Actions = () => (
    <Box className={styles.actionsContainer}>
      <Button variant="outlined" onClick={handleClose} className={styles.buttons}>
        {intl('SHARED.cancel')}
      </Button>
      <Button onClick={() => push(currentRoute)} variant="contained" className={styles.buttons} color="primary">
        {intl('APPLYING_MODAL.provide')}
      </Button>
    </Box>
  );

  return (
    <Modal isOpen={open} handleClose={handleClose} title={null} disabledBorder className={styles.modal} dialogActionsComponent={<Actions />}>
      <Box className={styles.content}>
        <WarningIcon className={styles.warnIcon} />
        <Typography className={styles.title}>{intl('APPLYING_MODAL.toApply')}</Typography>
        <ul className={styles.problemsList}>
          {!errors.hasPublicInfo && <li>{intl('SHARED.profileInfo')}</li>}
          {!errors.hasPrivateInfo && <li>{intl('APPLYING_MODAL.paymentInfo')}</li>}
          {!errors.isVerified && <li>{intl('SHARED.verifyAcc')}</li>}
        </ul>
      </Box>
    </Modal>
  );
};

export { TalentValidationModal };

import React, { FC, useState } from 'react';
import { AppBar, Avatar, Box, Divider, Hidden, Tab, Tabs } from '@material-ui/core';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { intl } from 'helpers';
import { useLink } from 'hooks/linkTo/index';

import { ProfileButton } from 'components/Buttons/ProfileButton';
import { BrandCard } from '../BrandCard';
import { a11yProps } from 'pages/Talent/Brands/components/TabPanel';

import type { GetBrandById } from 'types/brand';

import { useStyles } from './styles';

type Props = {
  brand: GetBrandById;
  updateQueries?: any;
  tab: string;
};

export const ProfileBar: FC<Props> = ({ brand, tab }) => {
  const [tabValue, setTabValue] = useState(tab);
  const styles = useStyles();
  const linkTo = useLink();
  const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env;

  const companyLetter = brand.companyName?.[0];

  const handleTabChange = (e, newTabValue): void => {
    setTabValue(newTabValue);
  };

  return (
    <>
      <Hidden smDown>
        <AppBar position="sticky" color="transparent" component="header" className={clsx(styles.profileBarWrapper)}>
          <Avatar src={REACT_APP_AWSS3_URL_IMAGE_BEG + brand.image} className={styles.avatarContainer}>
            {companyLetter}
          </Avatar>
          <Divider light />
          <Box className={styles.buttonsContainer}>
            <ProfileButton isCurrent={tab === 'info'} onClick={linkTo('info')}>
              {intl('BRAND.PROFILE_CARD.brandInfo')}
            </ProfileButton>
            <ProfileButton isCurrent={tab === 'opportunities'} onClick={linkTo('opportunities')}>
              {intl('SHARED.opportunities')}
            </ProfileButton>
            {/*<ProfileButton isCurrent={tab === 'activity-feed'} onClick={linkTo('activity-feed')}>*/}
            {/*  {intl('BRAND.PROFILE_CARD.activityFeed')}*/}
            {/*</ProfileButton>*/}
          </Box>
        </AppBar>
      </Hidden>

      <Hidden mdUp>
        <BrandCard
          id={brand.id}
          companyName={brand.companyName}
          country={brand.country}
          city={brand.city}
          image={brand.image}
          industry={brand.industry}
          isFavourite={brand.isFavourite}
          verificationStatus={brand.verificationStatus}
        />

        <Tabs value={tabValue} indicatorColor="primary" onChange={handleTabChange}>
          <Tab value="info" component={Link} to={'info'} label={intl('BRAND.PROFILE_CARD.brandInfo')} {...a11yProps(0)} />
          <Tab value="opportunities" component={Link} to="opportunities" label={intl('SHARED.opportunities')} {...a11yProps(1)} />
          {/*<Tab value="activity-feed" component={Link} to="activity-feed" label={intl('BRAND.PROFILE_CARD.activityFeed')} {...a11yProps(2)} />*/}
        </Tabs>
      </Hidden>
    </>
  );
};

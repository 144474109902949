import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { intl } from 'helpers';

import { Modal } from 'components/Modal';
import { Loader } from 'components/Loader';

import { useStyles } from './styles';

const URL = /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

const validationSchema = yup.object({
  profileLink: yup.string().matches(URL, 'Enter correct url').required('ERRORS.required'),
  numberOfFollowers: yup.number('ERRORS.numberType').min(0, 'ERRORS.mustBeAnPositive').integer('ERRORS.mustBeAnInteger').required('ERRORS.required'),
});

export const ModalSocialMedia = ({
  isOpen,
  handleClose,
  title,
  label,
  secondLabel,
  submit,
  responseError,
  loading,
  numberOfFollowers,
  profileLink,
  matchURL = [],
}) => {
  const [nameOfClickedBtn, setNameOfClickedBtn] = useState('');
  const styles = useStyles();

  const { errors, handleChange, handleSubmit, setFieldError, setFieldValue, values, resetForm } = useFormik({
    initialValues: {
      profileLink: profileLink || '',
      numberOfFollowers: numberOfFollowers || 0,
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: (val) => {
      if (String(values.numberOfFollowers).toLowerCase().includes('e')) {
        setFieldError('numberOfFollowers', 'ERRORS.mustBeAnInteger');
        return;
      }
      if (matchURL.length && !matchURL.some((u) => values.profileLink.indexOf(u) > -1)) {
        setFieldError('profileLink', 'ERRORS.validURL');
        return;
      }
      submit(val);
    },
  });

  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);

  useEffect(() => {
    resetForm();
  }, [label]);

  const handleButtonClick = (btnName) => () => {
    setNameOfClickedBtn(btnName);
    if (btnName === 'cancel') {
      handleClose();
    } else if (btnName === 'ok') {
      handleSubmit();
    }
  };

  const handleFollowerChange = (e) => {
    setFieldValue('numberOfFollowers', e.target.value);
  };

  const renderButtons = () => (
    <Grid>
      <Button className={styles.btn} variant="outlined" onClick={handleButtonClick('cancel')} disabled={loading}>
        {loading && nameOfClickedBtn === 'cancel' ? <Loader inButton /> : intl('INVITATION_MODAL.cancel')}
      </Button>
      <Button className={styles.btn} color="primary" variant="contained" onClick={handleButtonClick('ok')} disabled={loading}>
        {loading && nameOfClickedBtn === 'ok' ? <Loader inButton /> : intl('PROPOSAL_MODAL.confirm')}
      </Button>
    </Grid>
  );

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} title={title} className={styles.modalContainer} dialogActionsComponent={renderButtons()}>
      <Box className={styles.formContainer}>
        <TextField
          name="profileLink"
          error={!!errors.profileLink}
          helperText={intl(errors.profileLink) || responseError}
          value={values.profileLink}
          fullWidth
          onChange={handleChange}
          label={label}
          className={styles.inpProfileLink}
        />
        <TextField
          name="numberOfFollowers"
          error={!!errors.numberOfFollowers}
          helperText={intl(errors.numberOfFollowers) || responseError}
          value={values.numberOfFollowers}
          fullWidth
          type="number"
          onChange={handleFollowerChange}
          label={secondLabel}
        />
      </Box>
      <Box>{responseError && <Typography className={styles.smallError}>{responseError}</Typography>}</Box>
    </Modal>
  );
};

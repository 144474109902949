import { makeStyles, darken } from '@material-ui/core';
import { colors } from 'theme/colors';

export const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  container: {
    flexWrap: 'nowrap',
    borderRadius: 8,
    [theme.breakpoints.down(1024)]: {
      background: colors.WHITE,
    },
    [theme.breakpoints.only('xs')]: {
      height: 'auto',
      justifyContent: 'center',
    },
  },
  paper: {
    height: '100%',
    padding: 0,
    justifyContent: 'flex-start',
  },
  applicants: {
    width: '100%',
  },
  btnBack: {
    marginBottom: theme.spacing(1),
    alignSelf: 'flex-start',
    border: `1px solid ${colors.BORDER3}`,
  },
  backIco: {
    marginLeft: 4,
  },
  hidden: {
    [theme.breakpoints.only('xs')]: {
      display: 'none!important',
    },
  },
  backBox: {
    backgroundColor: colors.BACKGROUND,
  },
  talentBox: {
    display: 'flex',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
      minHeight: '450px',
      width: '100%',
    },
  },
  listTitle: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1.5),
    borderBottom: '1px solid #EFEFEF',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.03em',
    textTransform: 'uppercase',
    color: colors.TEXT,
  },
  talentCard: {
    justifyContent: 'space-between',
    [theme.breakpoints.only('xs')]: {
      height: '100%',
    },
  },
  talentChildrenBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  btnChat: {
    right: theme.spacing(3),
    alignSelf: 'flex-end',
    background: colors.PRIMARY,
    boxShadow: '0px 7px 20px rgba(0, 0, 0, 0.35)',
    '& svg': {
      fill: colors.WHITE,
    },
  },
  badge: {
    background: colors.ERROR,
    color: colors.WHITE,
    border: '2px solid',
    left: -9,
    bottom: -3,
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
  },
  btnDecline: {
    padding: '7px 35px',
    border: `1px solid ${colors.BORDER3}`,
    [theme.breakpoints.down('lg')]: {
      marginRight: theme.spacing(2),
    },
  },
  btnAccept: {
    padding: '7px 40px',
    backgroundColor: colors.PRIMARY,
    color: colors.WHITE,
    letterSpacing: '0.03em',
    '&:hover': {
      backgroundColor: `${darken(colors.PRIMARY, 0.2)}`,
    },
    '&:disabled': {
      color: colors.WHITE,
      opacity: 0.3,
    },
  },
  chatBox: {
    height: 518,
    display: 'flex',
    borderLeft: `1px solid ${colors.BORDER2}`,
    padding: 0,
  },
  list: {
    maxHeight: 230,
    overflow: 'auto',
  },
}));

import React, { useState } from 'react';
import { Box, Button, Input } from '@material-ui/core';
import { Send } from '@material-ui/icons';
import { useStyles } from './styles';
import { intl } from '../../../../helpers';

const SendPanel = ({ handleAddMessage, messageSending, preDisabled, postDisabled, messageTo }) => {
  const classes = useStyles();
  const [messageText, setMessageText] = useState('');

  const handleChange = (e) => e.key !== 'Enter' && setMessageText(e.target.value);

  const onAddMessage = () => {
    if (!messageText.length) return;

    handleAddMessage(messageText);
    setMessageText('');
  };

  const onEnterClick = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      onAddMessage();
    }
  };

  // eslint-disable-next-line no-nested-ternary
  const placeholderMessage = preDisabled
    ? intl('MESSENGER.notAvailableYet')
    : postDisabled
    ? intl('MESSENGER.notAvailable')
    : `${intl('MESSENGER.messageTo')} ${messageTo?.firstName} ${messageTo?.lastName}`;
  return (
    <Box className={classes.container}>
      <Input
        className={classes.input}
        multiline
        value={messageText}
        onKeyDown={onEnterClick}
        onChange={handleChange}
        disableUnderline
        disabled={preDisabled || postDisabled}
        placeholder={placeholderMessage}
      />
      <Button disabled={messageSending} className={classes.btnSend} onClick={onAddMessage}>
        <Send className={classes.icon} />
      </Button>
    </Box>
  );
};

export default SendPanel;

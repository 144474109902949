import { gql } from '@apollo/client';
import { VerificationRequestStatusesEnum } from 'types/talent';
import { UserTypeEnum } from 'types/common';

const GET_TALENTS_LIST = gql`
  query AdminListTalents($filter: TalentsAdminFilter, $search: String) {
    adminListTalents(filter: $filter, search: $search) {
      items {
        id
        firstName
        lastName
        sport {
          id
          name
        }
        country {
          id
          name
        }
        city {
          id
          name
        }
        phone
        competition
        club
        tags
        about
        gender
        image
        email
        isPrivate
        verificationStatus
        averageFeedback
        social {
          impressionsCount
          accountId
          providerName
          accountName
          confirmed
        }
        videos {
          type
          link
        }
        createdAt
      }
      filters {
        cities
        genders
        countries
        tags
        clubs
        sports
      }
      meta {
        total
      }
      listTalentsUrl
    }
  }
`;

const GET_BRANDS_LIST = gql`
  query AdminListBrands($filter: BrandsAdminFilter, $search: String) {
    adminListBrands(filter: $filter, search: $search) {
      items {
        id
        companyName
        about
        averageFeedback
        country {
          id
          name
        }
        city {
          id
          name
        }
        industry {
          id
          name
        }
        image
        verificationStatus
        manager {
          firstName
          lastName
          id
        }
        createdAt
      }
      filters {
        industries
        cities
        countries
      }
      meta {
        total
      }
      listBrandsUrl
    }
  }
`;

const GET_BRAND_MANAGERS_LIST = gql`
  query AdminListBrandManagers($filter: BrandUsersAdminFilter, $search: String) {
    adminListBrandManagers(filter: $filter, search: $search) {
      items {
        id
        firstName
        lastName
        email
        phone
        image
        brandId
        brandName
        verificationStatus
        createdAt
      }
      meta {
        total
      }
      listBrandUsersUrl
    }
  }
`;

const GET_BRAND_MANAGER = gql`
  query GetBrandManager($managerId: ID!) {
    getBrandManager(managerId: $managerId) {
      id
      firstName
      lastName
      email
      phone
      image
      brandId
      brandName
      verificationStatus
      createdAt
    }
  }
`;

const UPDATE_VERIFICATION_STATUS = gql`
  mutation updateVerificationStatus($userType: UserTypeEnum!, $userId: ID!, $status: VerificationRequestStatusesEnum!) {
    updateVerificationStatus(data: { userType: $userType, userId: $userId, status: $status })
  }
`;

const INVITE_USER = gql`
  mutation InviteUser($email: AWSEmail!, $role: GeneralUserTypeEnum!) {
    inviteUser(email: $email, role: $role)
  }
`;

const ADMIN_UPDATE_BRAND_PROFILE = gql`
  mutation adminUpdateBrandProfile($brandId: String!, $data: BrandUpdateInput!) {
    adminUpdateBrandProfile(brandId: $brandId, data: $data) {
      id
      companyName
      about
      verificationStatus
      createdAt
      country {
        id
        name
      }
      city {
        id
        name
      }
      industry {
        id
        name
      }
      manager {
        firstName
        lastName
        id
      }
      image
      feedback {
        id
        rate
        opportunityId
        description
        createdAt
        author {
          id
          name
          image
        }
      }
      createdAt
      privateInfo {
        street
        postalCode
        VATnumber
        IBAN
        bankAccount
      }
      isPrivate
      isFavourite
    }
  }
`;

const ADMIN_UPDATE_TALENT_PROFILE = gql`
  mutation updateTalentProfile(
    $talentId: ID!
    $firstName: String
    $lastName: String
    $sportId: ID
    $cityId: ID
    $competition: Boolean
    $club: [String]
    $tags: [String]
    $about: String
    $gender: GenderEnum
    $image: String
    $isPrivate: Boolean
    $birth: Float
    $fieldsVisibility: [FieldVisibilityInput]
  ) {
    adminUpdateTalentProfile(
      talentId: $talentId
      data: {
        isPrivate: $isPrivate
        firstName: $firstName
        lastName: $lastName
        sportId: $sportId
        cityId: $cityId
        competition: $competition
        club: $club
        tags: $tags
        about: $about
        gender: $gender
        image: $image
        isPrivate: $isPrivate
        birth: $birth
        fieldsVisibility: $fieldsVisibility
      }
    ) {
      id
      firstName
      lastName
      sport {
        id
        name
      }
      country {
        id
        name
      }
      city {
        id
        name
      }
      phone
      competition
      club
      tags
      about
      gender
      isPrivate
      image
      social {
        impressionsCount
        accountId
        providerName
        accountName
        confirmed
      }
      videos {
        type
        link
        id
      }
      payout {
        wire {
          iban
          accountName
        }
        stripe {
          accountId
        }
      }
      feedbacks {
        id
        rate
        opportunityId
        description
        createdAt
        author {
          id
          name
          image
        }
      }
      birth
      fieldsVisibility {
        fieldName
        isVisible
      }
    }
  }
`;

export {
  GET_TALENTS_LIST,
  GET_BRANDS_LIST,
  GET_BRAND_MANAGERS_LIST,
  GET_BRAND_MANAGER,
  UPDATE_VERIFICATION_STATUS,
  INVITE_USER,
  ADMIN_UPDATE_TALENT_PROFILE,
  ADMIN_UPDATE_BRAND_PROFILE,
};

export type UpdateVerificationStatusVars = {
  userType: UserTypeEnum;
  userId: string;
  status: VerificationRequestStatusesEnum;
};

export type UpdateTalentProfileVars = {
  talentId: string;
  isPrivate: boolean;
};

import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import intl from 'react-intl-universal';
import clsx from 'clsx';

import { formatNumber, calcFinalBudget } from 'helpers';

import { useStyles } from './styles';
import type { Props } from './types';
import { PLATFORM_FEE_AMOUNT } from '../../constants';

const FinalBudgetTooltip: FC<Props> = ({ budget, profitMargins, bold = false, isTaxed, currency = '€', simple = false, className }) => {
  const classes = useStyles();

  if (simple) {
    return (
      <Typography
        className={clsx(
          classes.text,
          {
            [classes.bold]: bold,
          },
          className,
        )}
      >
        {intl.getHTML('SHARED.finalBudget', {
          currency,
          budget: formatNumber(isTaxed ? budget : budget * PLATFORM_FEE_AMOUNT),
        })}
      </Typography>
    );
  }

  return (
    <Typography
      className={clsx(
        classes.text,
        {
          [classes.bold]: bold,
        },
        className,
      )}
    >
      {intl.getHTML('SHARED.finalBudget', {
        currency,
        budget: formatNumber(calcFinalBudget(isTaxed ? budget : budget * PLATFORM_FEE_AMOUNT, profitMargins)),
      })}
    </Typography>
  );
};

export default FinalBudgetTooltip;

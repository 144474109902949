import React from 'react';
import { Box, Paper } from '@material-ui/core';
import { useQuery } from '@apollo/client';

import { GET_CURRENT_TALENT } from 'services/graphql/talent';
import { ProfileTop } from 'components/ProfileTop';
import { Loader } from 'components/Loader';

import { Feedbacks } from 'components/Feedbacks';
import { Videos } from './components/Videos';
import { Tags } from './components/Tags';
import { About } from './components/About';
import { useStyles } from './styles';

export const PublicPage = () => {
  const styles = useStyles({});
  const { data: talentData, loading } = useQuery(GET_CURRENT_TALENT, { fetchPolicy: 'network-only' });
  return (
    <>
      <Box className={styles.publicPageContent}>
        {loading ? (
          <Paper className={styles.loaderPlaceholder}>
            <Loader />
          </Paper>
        ) : (
          <>
            <ProfileTop talent={talentData?.getCurrentTalent} />
            <Tags talent={talentData?.getCurrentTalent} />
            <About talent={talentData?.getCurrentTalent} />
            <Videos talent={talentData?.getCurrentTalent} />
            <Feedbacks feedbacks={talentData?.getCurrentTalent?.feedbacks} />
          </>
        )}
      </Box>
    </>
  );
};

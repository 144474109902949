import React, { useState, FC } from 'react';
import {
  Container,
  Grid,
  Box,
  IconButton,
  Select,
  MenuItem,
  Hidden,
  Tabs,
  Tab,
  Button,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import clsx from 'clsx';

import { routes } from 'router/routesList';
import { DesertImage } from 'assets/svg-js';
import { intl } from 'helpers';
import { useNewsFeed } from 'hooks/feed';
import { LIST_NEWS_FEED } from 'services/graphql/query/feed';
import { GET_NEWLY_REGISTERED_TALENTS } from 'services/graphql/brand';
import type { PostFeed, ActivityPost, OpportunityFeed } from 'types/post';
import { NewsFeedCollectionEnum } from 'types/post';

import { Loader } from 'components/Loader';
import { InfoPost } from 'components/InfoPost';
import { OpportunityPost } from 'components/OpportunityPost';
import { SlideWidget } from 'components/SlideWidget';
import { TalentListItem } from 'components/TalentListItem';

import { useStyles } from './styles';
import { TabPanel, a11yProps } from './components/TabPanel';

const LIMIT = 10;

const NewsFeed: FC = () => {
  const { tab } = useParams();
  const styles = useStyles();
  const [currentTab, setCurrentTab] = useState<NewsFeedCollectionEnum>(tab ?? NewsFeedCollectionEnum.general);
  const theme = useTheme();
  const { handleSuccessDelete, handleSuccessLike, handleSuccessShare } = useNewsFeed({ collection: currentTab, limit: LIMIT });
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const allTabRoute = `${routes.BRAND_NEWS_FEED}/${NewsFeedCollectionEnum.general}`;
  const myActivityTabRoute = `${routes.BRAND_NEWS_FEED}/${NewsFeedCollectionEnum.my_activity_feed}`;
  const favoriteTabRoute = `${routes.BRAND_NEWS_FEED}/${NewsFeedCollectionEnum.news_feed_from_favourites}`;

  const { data, loading, refetch, fetchMore } = useQuery<any>(LIST_NEWS_FEED, {
    variables: {
      filter: {
        collection: currentTab,
      },
      pagination: {
        limit: LIMIT,
      },
    },
    fetchPolicy: 'cache-first',
  });

  const { data: newlyTalents, loading: loadingTalents } = useQuery(GET_NEWLY_REGISTERED_TALENTS, {
    variables: {
      pagination: {
        limit: 12,
      },
    },
  });

  const handleTabChange = (_: any, newValue: NewsFeedCollectionEnum) => {
    setCurrentTab(newValue);
    refetch({ filter: { collection: newValue } });
  };

  const onShowMore = () => {
    fetchMore({
      variables: {
        filter: {
          collection: currentTab,
        },
        pagination: {
          offset: data?.listNewsFeed?.items?.length,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        if (fetchMoreResult?.listNewsFeed?.meta?.total < LIMIT) return prev;
        return {
          ...prev,
          listNewsFeed: {
            ...prev.listNewsFeed,
            items: [...prev.listNewsFeed.items, ...fetchMoreResult?.listNewsFeed?.items],
          },
        };
      },
    });
  };

  return (
    <Container className={styles.wrapper}>
      <Grid container spacing={isDesktop ? 3 : 0}>
        <Grid item lg={9} md={9} xs={12} className={styles.mainGrid}>
          <Box className={styles.heading}>
            <Hidden only="xs">
              <Tabs value={currentTab} indicatorColor="primary" onChange={handleTabChange}>
                <Tab
                  className={styles.tab}
                  value={NewsFeedCollectionEnum.general}
                  component={Link}
                  to={allTabRoute}
                  label={intl('NEWS_FEED.allNews')}
                  {...a11yProps(0)}
                />
                <Tab
                  className={styles.tab}
                  value={NewsFeedCollectionEnum.my_activity_feed}
                  component={Link}
                  to={myActivityTabRoute}
                  label={intl('NEWS_FEED.myActivityFeed')}
                  {...a11yProps(1)}
                />
                <Tab
                  className={styles.tab}
                  value={NewsFeedCollectionEnum.news_feed_from_favourites}
                  component={Link}
                  to={favoriteTabRoute}
                  label={intl('NEWS_FEED.newsFromFavorite')}
                  {...a11yProps(2)}
                />
              </Tabs>
              <Button
                className={styles.btnAdd}
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                component={Link}
                to={routes.BRAND_NEW_POST}
              >
                {intl('NEWS_FEED.newPost')}
              </Button>
            </Hidden>
            <Hidden smUp>
              <Select
                className={styles.select}
                labelId="news-tab-switcher-label"
                id="news-tab-switcher"
                value={tab}
                onChange={(e) => handleTabChange(null, e.target.value as NewsFeedCollectionEnum)}
              >
                <MenuItem value={NewsFeedCollectionEnum.general}>
                  <Link className={styles.mobileLink} to={NewsFeedCollectionEnum.general}>
                    {intl('NEWS_FEED.allNews')}
                  </Link>
                </MenuItem>
                <MenuItem value={NewsFeedCollectionEnum.my_activity_feed}>
                  <Link className={styles.mobileLink} to={NewsFeedCollectionEnum.my_activity_feed}>
                    {intl('NEWS_FEED.myActivityFeed')}
                  </Link>
                </MenuItem>
                <MenuItem value={NewsFeedCollectionEnum.news_feed_from_favourites}>
                  <Link className={styles.mobileLink} to={NewsFeedCollectionEnum.news_feed_from_favourites}>
                    {intl('NEWS_FEED.newsFromFavorite')}
                  </Link>
                </MenuItem>
              </Select>
              <IconButton className={styles.btnAdd} component={Link} to={routes.BRAND_NEW_POST}>
                <AddIcon fontSize="small" />
              </IconButton>
            </Hidden>
          </Box>
          <Divider className={styles.divider} />
          {loading ? (
            <Loader className={styles.loader} />
          ) : data?.listNewsFeed?.meta?.total ? (
            <>
              <TabPanel value={currentTab} name={NewsFeedCollectionEnum.general}>
                {data?.listNewsFeed?.items?.map((n: ActivityPost) =>
                  n?.__typename === 'PostFeed' ? (
                    <InfoPost
                      userRole="brand"
                      onSuccessDelete={handleSuccessDelete}
                      post={n as PostFeed}
                      key={(n as PostFeed).id}
                      onSuccessLike={handleSuccessLike}
                      onSuccessShare={handleSuccessShare}
                    />
                  ) : (
                    <OpportunityPost
                      userRole="brand"
                      opportunity={n as OpportunityFeed}
                      key={(n as OpportunityFeed).id}
                      onSuccessLike={handleSuccessLike}
                    />
                  ),
                )}
                {data?.listNewsFeed?.meta?.total > data?.listNewsFeed?.items?.length && (
                  <Box className={styles.showMoreContainer}>
                    <Button className={styles.buttonShowMore} variant="outlined" onClick={onShowMore} tabIndex={NaN} component="div" role="none">
                      {intl('SHARED.showMore')}
                    </Button>
                  </Box>
                )}
              </TabPanel>
              <TabPanel value={currentTab} name={NewsFeedCollectionEnum.my_activity_feed}>
                {data?.listNewsFeed?.items?.map((n: ActivityPost) =>
                  n?.__typename === 'PostFeed' ? (
                    <InfoPost
                      userRole="brand"
                      onSuccessDelete={handleSuccessDelete}
                      post={n as PostFeed}
                      key={(n as PostFeed).id}
                      onSuccessLike={handleSuccessLike}
                      onSuccessShare={handleSuccessShare}
                    />
                  ) : (
                    <OpportunityPost
                      userRole="brand"
                      opportunity={n as OpportunityFeed}
                      key={(n as OpportunityFeed).id}
                      onSuccessLike={handleSuccessLike}
                    />
                  ),
                )}
                {data?.listNewsFeed?.meta?.total > data?.listNewsFeed?.items?.length && (
                  <Box className={styles.showMoreContainer}>
                    <Button className={styles.buttonShowMore} variant="outlined" onClick={onShowMore} tabIndex={NaN} component="div" role="none">
                      {intl('SHARED.showMore')}
                    </Button>
                  </Box>
                )}
              </TabPanel>
              <TabPanel value={currentTab} name={NewsFeedCollectionEnum.news_feed_from_favourites}>
                {data?.listNewsFeed?.items?.map((n: ActivityPost) =>
                  n?.__typename === 'PostFeed' ? (
                    <InfoPost
                      userRole="brand"
                      onSuccessDelete={handleSuccessDelete}
                      post={n as PostFeed}
                      key={(n as PostFeed).id}
                      onSuccessLike={handleSuccessLike}
                      onSuccessShare={handleSuccessShare}
                    />
                  ) : (
                    <OpportunityPost
                      userRole="brand"
                      opportunity={n as OpportunityFeed}
                      key={(n as OpportunityFeed).id}
                      onSuccessLike={handleSuccessLike}
                    />
                  ),
                )}
                {data?.listNewsFeed?.meta?.total > data?.listNewsFeed?.items?.length && (
                  <Box className={styles.showMoreContainer}>
                    <Button className={styles.buttonShowMore} variant="outlined" onClick={onShowMore} tabIndex={NaN} component="div" role="none">
                      {intl('SHARED.showMore')}
                    </Button>
                  </Box>
                )}
              </TabPanel>
            </>
          ) : (
            <Box className={styles.emptyBox}>
              <DesertImage className={styles.emptyIcon} />
              <Typography className={clsx(styles.title, styles.emptyText)}>
                {currentTab === NewsFeedCollectionEnum.my_activity_feed ? intl('EMPTY_STATE.noYourPosts') : intl('EMPTY_STATE.noPosts')}
              </Typography>
              {currentTab === NewsFeedCollectionEnum.my_activity_feed && (
                <Button className={styles.btnCreate} variant="contained" color="primary" component={Link} to={routes.BRAND_NEW_POST}>
                  {intl('BRAND.ACTIVITY_FEED.createPost')}
                </Button>
              )}
            </Box>
          )}
        </Grid>
        <Grid item lg={3} md={3} className={styles.secondGrid}>
          <SlideWidget title={intl('NEWS_FEED.newTalents')} loading={loadingTalents} className={styles.talents}>
            {newlyTalents?.listNewlyTalents?.items?.map((talent) => (
              <TalentListItem
                id={talent.id}
                key={talent.id}
                firstName={talent.firstName}
                lastName={talent.lastName}
                avatar={talent.image}
                joinedAt={talent.createdAt}
              />
            ))}
          </SlideWidget>
        </Grid>
      </Grid>
    </Container>
  );
};

export { NewsFeed };

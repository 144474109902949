import { gql } from '@apollo/client';

const CREATE_OPPORTUNITY = gql`
  mutation createOpportunity($data: CreateOpportunityInput!) {
    createOpportunity(data: $data) {
      id
      name
      city {
        name
      }
      country {
        name
      }
      description
      endDate
      isRated
      startDate
      status {
        name
      }
      applicants {
        firstName
        image
        lastName
        applicantId
        status
      }
      target {
        cities {
          name
        }
        competition
        countries {
          name
        }
        gender
        sports {
          name
        }
      }
      redemption {
        budget
        talentSalary
        currency {
          code
        }
      }
    }
  }
`;

const UPDATE_OPPORTUNITY = gql`
  mutation updateOpportunity($data: UpdateOpportunityInput!) {
    updateOpportunity(data: $data) {
      id
      target {
        cities {
          name
        }
        competition
        countries {
          name
        }
        gender
        sports {
          name
        }
      }
    }
  }
`;

const APPLY_OPPORTUNITY = gql`
  mutation applyOpportunity($data: ApplyOpportunityInput!) {
    applyOpportunity(data: $data) {
      opportunityId
      talentId
      applicationId
    }
  }
`;

const DECLINE_OPPORTUNITY = gql`
  mutation declineApplicant($data: OpportunityApplicant!) {
    declineApplicant(data: $data) {
      opportunityId
      talentId
    }
  }
`;

const CREATE_OFFER = gql`
  mutation createOpportunityOffer($data: CreateOpportunityOfferInput!) {
    createOpportunityOffer(data: $data) {
      id
      conversationId
      sender {
        id
        firstName
        lastName
        image
        type
      }
      body
      action
      applicationId
      coverLetter
      offerId
      offer {
        id
        talentId
        opportunityId
        description
        offerAmount
        talentSalary
        isTaxed
        confirmedAt
        declinedAt
      }
      createdAt
    }
  }
`;

const UPDATE_OFFER = gql`
  mutation updateOfferStatus($data: UpdateOfferStatusInput!) {
    updateOfferStatus(data: $data) {
      id
      conversationId
      sender {
        id
        firstName
        lastName
        image
        type
      }
      body
      action
      applicationId
      coverLetter
      offerId
      offer {
        id
        talentId
        opportunityId
        description
        offerAmount
        talentSalary
        isTaxed
        confirmedAt
        declinedAt
      }
      createdAt
    }
  }
`;

const CANCEL_OPPORTUNITY = gql`
  mutation cancelOpportunity($opportunityId: ID!, $cancelStrategy: UpdateOpportunityStrategyEnum, $isCharge: Boolean) {
    cancelOpportunity(opportunityId: $opportunityId, cancelStrategy: $cancelStrategy, isCharge: $isCharge) {
      id
      applicationId
      isRated
      isApplied
      name
      createdAt
      startDate
      status {
        name
      }
      type {
        name
      }
    }
  }
`;

export { CREATE_OPPORTUNITY, APPLY_OPPORTUNITY, DECLINE_OPPORTUNITY, CREATE_OFFER, UPDATE_OFFER, UPDATE_OPPORTUNITY, CANCEL_OPPORTUNITY };

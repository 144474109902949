import React from 'react'
import {
  Paper,
  Toolbar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { useStyles } from './styles'
import { intl } from '../../helpers'
import { ADMIN_GET_TALENT_ANALYTIC } from '../../services/graphql/analytics'
import { Loader } from '../Loader'

export const TalentAnalytics = () => {
  const classes = useStyles({})
  const { id } = useParams()
  const { data, loading } = useQuery(ADMIN_GET_TALENT_ANALYTIC, {
    variables: {
      talentId: id,
    },
  })
  return (
    <Paper className={classes.paper}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Toolbar className={classes.toolbar}>Opportunities</Toolbar>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{intl('COMMON_ANALYTICS.typesAndStatuses')}</TableCell>
                  <TableCell align="right">{intl('COMMON_ANALYTICS.amount')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{intl('COMMON_ANALYTICS.applied')}</TableCell>
                  <TableCell align="right">{data?.adminGetTalentAnalytic.applied}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{intl('COMMON_ANALYTICS.open')}</TableCell>
                  <TableCell align="right">
                    {data?.adminGetTalentAnalytic.openOpportunities}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{intl('COMMON_ANALYTICS.declined')}</TableCell>
                  <TableCell align="right">{data?.adminGetTalentAnalytic.declined}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{intl('COMMON_ANALYTICS.confirmed')}</TableCell>
                  <TableCell align="right">
                    {data?.adminGetTalentAnalytic.confirmedOpportunities}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{intl('COMMON_ANALYTICS.cancelledByBrand')}</TableCell>
                  <TableCell align="right">
                    {data?.adminGetTalentAnalytic.canceledByBrandOpportunities}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{intl('COMMON_ANALYTICS.cancelledByTalent')}</TableCell>
                  <TableCell align="right">
                    {data?.adminGetTalentAnalytic.canceledByTalentOpportunities}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{intl('COMMON_ANALYTICS.payment')}</TableCell>
                  <TableCell align="right">
                    {data?.adminGetTalentAnalytic.paymentOpportunities}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{intl('COMMON_ANALYTICS.done')}</TableCell>
                  <TableCell align="right">
                    {data?.adminGetTalentAnalytic.doneOpportunities}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Paper>
  )
}

import React, { useState, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { Box, TextField, InputAdornment, Button, Hidden, IconButton, Paper, Typography } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { Search } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { intl } from 'helpers';
import { routes } from 'router/routesList';
import { DesertImage, FilterIcon, FilterIconActive, SearchFilter } from 'assets/svg-js';
import { LIST_TALENT_OPPORTUNITIES } from 'services/graphql/query/opportunity';

import { Loader } from 'components/Loader';
import { Wrapper } from 'components/Wrapper';
import { OpportunityFilters } from 'components/OpportunityFilters';
import { TalentOpportunityCard, TalentOpportunityCardMobile } from 'components/TalentOpportunityCard';

import { useStyles } from './styles';

const LIMIT = 10;

export const TalentOpportunities = () => {
  const classes = useStyles();

  const [search, setSearch] = useState('');
  const [mobileFiltersOpened, setMobileFiltersOpened] = useState(false);

  const [filters, setFilters] = useState({});

  const toggleMobileFilters = () => setMobileFiltersOpened((isOpened) => !isOpened);

  const setA = (value) => {
    setSearch(value);
  };

  const handleChange = useCallback(
    debounce((value) => {
      setA(value);
    }, 1000),
    [],
  );

  const { data, loading, fetchMore } = useQuery(LIST_TALENT_OPPORTUNITIES, {
    variables: {
      filter: {
        offset: 0,
        limit: LIMIT,
        options: {
          typeId: filters?.typeId,
          cityId: filters?.city?.map((city) => city.id),
          countryId: filters?.country?.map((country) => country.id),
          budget: {
            from: +filters?.budgetFrom || 0,
            to: +filters?.budgetTo || 99999999,
          },
          startDate: {
            from: filters?.startDate?.setHours(0, 0, 0, 0) || null,
            to: filters?.endDate?.setHours(23, 59, 59, 999) || null,
          },
        },
      },
      search,
    },
    nextFetchPolicy: 'cache-first',
    fetchPolicy: 'network-only',
  });

  const onShowMore = () => {
    fetchMore({
      variables: {
        filter: {
          offset: data?.listTalentOpportunities?.items?.length,
          limit: LIMIT,
          options: {
            typeId: filters?.typeId,
            cityId: filters?.city?.map((city) => city.id),
            countryId: filters?.country?.map((country) => country.id),
            budget: {
              from: +filters?.budgetFrom || 0,
              to: +filters?.budgetTo || 99999999,
            },
            startDate: filters?.startDate?.setHours(0, 0, 0, 0),
            endDate: filters?.startDate?.setHours(23, 59, 59, 999),
          },
        },
        search,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        if (fetchMoreResult?.listTalentOpportunities?.meta?.total < LIMIT) return prev;

        return {
          ...prev,
          listTalentOpportunities: {
            ...prev.listTalentOpportunities,
            items: [...prev.listTalentOpportunities.items, ...fetchMoreResult?.listTalentOpportunities?.items],
          },
        };
      },
    });
  };

  const onApply = (values, applyFromBtn) => {
    setFilters(values);
    if (applyFromBtn) {
      toggleMobileFilters();
    }
  };
  const onApplyDesktop = (values) => {
    setFilters(values);
  };

  const checkFiltersForNonEmpty = () =>
    filters && Object.values(filters).length && Object.values(filters).some((val) => (Array.isArray(val) ? val.length : val));

  const noResults = !!(((filters && Object.keys(filters).length) || search.length) && !data?.listTalentOpportunities?.items.length);

  return (
    <Wrapper>
      <Box className={classes.container}>
        <Hidden smDown>
          <OpportunityFilters handleApply={onApplyDesktop} filters={filters} />
        </Hidden>
        <Box className={classes.rightBlock}>
          {!mobileFiltersOpened && (
            <Box display="flex" alignItems="flex-start">
              <TextField
                className={classes.search}
                fullWidth
                onChange={({ target: { value } }) => handleChange(value)}
                placeholder={intl('TALENT_OPPORTUNITIES.search')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
              <Hidden mdUp>
                <IconButton onClick={toggleMobileFilters} size="small">
                  {checkFiltersForNonEmpty() ? <FilterIconActive /> : <FilterIcon />}
                </IconButton>
              </Hidden>
            </Box>
          )}

          <Hidden mdUp>
            {mobileFiltersOpened && (
              <OpportunityFilters handleApply={onApply} handleClose={toggleMobileFilters} filters={filters} className={classes.mobileFilters} />
            )}
          </Hidden>
          <Hidden xsDown>
            {loading ? (
              <Loader className={classes.fullHeight} />
            ) : (
              <Box className={clsx({ [classes.fullHeight]: noResults })}>
                {noResults && (
                  <Box className={classes.emptySearchBox}>
                    <SearchFilter />
                    <Typography className={classes.emptySearchText}>{intl('EMPTY_STATE.noDataByFilters')}</Typography>
                  </Box>
                )}
                {!data?.listTalentOpportunities?.meta.total ? (
                  <Box className={classes.emptyStateBox}>
                    <DesertImage className={classes.emptyIcon} />
                    <Typography className={classes.emptyStateText}>{intl('TALENT.BRANDS_PROFILE.noOpportunities')}</Typography>
                  </Box>
                ) : (
                  data?.listTalentOpportunities?.items?.map((opportunity) => (
                    <Link to={`/${routes.TALENT}/${routes.OPPORTUNITIES}/${opportunity?.id}/details`} className={classes.link} key={opportunity?.id}>
                      <TalentOpportunityCard opportunity={opportunity} />
                    </Link>
                  ))
                )}
              </Box>
            )}
          </Hidden>

          <Hidden smUp>
            {loading ? (
              <Loader />
            ) : (
              <Paper>
                {data?.listTalentOpportunities?.items?.map((opportunity) => (
                  <Link to={`/${routes.TALENT}/${routes.OPPORTUNITIES}/${opportunity?.id}/details`} className={classes.link} key={opportunity?.id}>
                    <TalentOpportunityCardMobile opportunity={opportunity} />
                  </Link>
                ))}
              </Paper>
            )}
          </Hidden>

          {data?.listTalentOpportunities?.meta?.total > data?.listTalentOpportunities?.items?.length && (
            <Box className={classes.showMoreContainer}>
              <Button className={classes.buttonShowMore} variant="outlined" onClick={onShowMore}>
                {intl('SHARED.showMore')}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Wrapper>
  );
};

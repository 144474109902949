/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useRef } from 'react';
import { Box, Paper, TextField, Hidden, Grid, useMediaQuery } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { useQuery, useMutation } from '@apollo/client';
import { object, string } from 'yup';

import { intl, findSocialProvider } from 'helpers';
import { constants } from 'theme/constants';
import { useLink } from 'hooks/linkTo';
import { routes } from 'router/routesList';
import { SOCIAL_PROVIDER } from 'constants/index';
import { GET_BRAND_USER, UPDATE_BRAND_USER } from 'services/graphql/brand';

import { SaveBar } from 'components/SaveBar';
import { ProfileCard } from 'components/ProfileCard';
import { AvatarEditable } from 'components/AvatarEditable';
import { Loader } from 'components/Loader';
import { Wrapper } from 'components/Wrapper';
import GoogleCalendarPanel from 'components/GoogleCalendarPanel';

import { useStyles } from './styles';

const validationSchema = object({
  firstName: string().nullable().required('ERRORS.emptyField'),
  lastName: string().nullable().required('ERRORS.emptyField'),
  phone: string().nullable().required('ERRORS.emptyField'),
});

export const ProfileInfo = () => {
  const styles = useStyles({});
  const linkTo = useLink();
  const isShowButtons = useRef(false);
  const { state } = useLocation();

  const isSm = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const isRequiredField = !!state?.referrer;
  // const displayError = isRequiredField && intl('ERRORS.required');

  const [updateBrandUser, { loading: updatingProfile }] = useMutation(UPDATE_BRAND_USER, {
    onCompleted({ updateBrandUser: updateBrandUserData }) {
      if (state?.fromSignUp) {
        linkTo(`/${routes.BRAND}/${routes.OPPORTUNITIES}`)();
        setValues(updateBrandUserData);
      } else if (state?.referrer) {
        linkTo(state.referrer)();
        delete state.referrer;
      } else {
        linkTo(routes.BRAND_PUBLIC_PAGE)();
        setValues(updateBrandUserData);
      }
    },
  });

  const [updateBrandUserImage, { loading: avatarUpdating }] = useMutation(UPDATE_BRAND_USER, {
    onCompleted({ updateBrandUser: updateBrandUserData }) {
      setValues(updateBrandUserData);
    },
  });

  const handleBrandUserUpdate = (values) => {
    updateBrandUser({
      variables: {
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        phone: values.phone,
        image: values.image,
      },
    });
    isShowButtons.current = false;
  };

  const handleBrandUserImageUpdate = (values) => {
    updateBrandUserImage({
      variables: {
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        phone: values.phone,
        image: values.image,
      },
    });
  };

  const { values, errors, handleChange, setValues, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      image: '',
      google: {
        connected: false,
        accountName: '',
      },
    },
    onSubmit: handleBrandUserUpdate,
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema,
  });

  const { loading: brandUserLoading } = useQuery(GET_BRAND_USER, {
    onCompleted({ getBrandUser }) {
      const { linkedAccounts } = getBrandUser;
      setValues({
        ...getBrandUser,
        phone: getBrandUser.phone || '',
        google: {
          connected: !!findSocialProvider(linkedAccounts, SOCIAL_PROVIDER.GOOGLE)?.accountName?.length,
          accountName: findSocialProvider(linkedAccounts, SOCIAL_PROVIDER.GOOGLE)?.accountName,
        },
      });
    },
  });

  const handleImageChange = (key) => {
    handleBrandUserImageUpdate({ ...values, image: key });
    isShowButtons.current = true;
  };

  const handleChangeGoogleStatus = (connected, accountName) => {
    setFieldValue('google', {
      connected,
      accountName,
    });
  };
  const handleFieldChange = (...param) => {
    isShowButtons.current = true;
    handleChange(...param);
  };

  return (
    <>
      <Wrapper additionalContentHeight={isSm ? constants.SAVE_BAR_HEIGHT_MOBILE : constants.SAVE_BAR_HEIGHT}>
        <Box className={styles.profileInfo}>
          <Hidden smDown>
            <ProfileCard
              className={styles.profileCard}
              handleImageChange={handleImageChange}
              imgSrc={values.image}
              imgAlt={intl('LETTER_AVATAR.aspectRatio')}
              isSignUp={state?.fromSignUp}
              error={isRequiredField}
            />
          </Hidden>
          <Box className={styles.profileInfoFormAndNav}>
            <Paper className={styles.profileInfoForm} elevation={0}>
              {brandUserLoading ? (
                <Loader />
              ) : (
                <Grid container>
                  <Grid item container md={false} sm={6} justify="center" alignItems="baseline">
                    <Hidden mdUp>
                      <AvatarEditable error={isRequiredField} image={values.image} handleSuccess={handleImageChange} />
                    </Hidden>
                  </Grid>
                  <Grid item md={12} sm={6} container justify="space-between">
                    <TextField
                      name="firstName"
                      className={styles.input}
                      value={values.firstName}
                      onChange={handleFieldChange}
                      label={intl('SHARED.firstName')}
                      error={(isRequiredField && !values.firstName) || !!errors.firstName}
                      helperText={intl(errors.firstName)}
                    />
                    <TextField
                      name="lastName"
                      className={styles.input}
                      value={values.lastName}
                      onChange={handleFieldChange}
                      label={intl('SHARED.lastName')}
                      error={(isRequiredField && !values.lastName) || !!errors.lastName}
                      helperText={intl(errors.lastName)}
                    />

                    <TextField name="email" label={intl('SHARED.email')} className={styles.input} value={values.email} disabled />

                    <TextField
                      name="phone"
                      className={styles.input}
                      value={values.phone}
                      onChange={handleFieldChange}
                      label={intl('SHARED.phone')}
                      error={(isRequiredField && !values.phone) || !!errors.phone}
                      helperText={intl(errors.phone)}
                    />
                  </Grid>
                </Grid>
              )}
            </Paper>
            {!brandUserLoading && (
              <GoogleCalendarPanel
                connected={values.google?.connected}
                accountName={values.google?.accountName}
                setStatus={handleChangeGoogleStatus}
              />
            )}
          </Box>
        </Box>
      </Wrapper>
      {(isShowButtons.current || state?.fromSignUp) && (
        <SaveBar
          isSignUp={state?.fromSignUp}
          disabled={updatingProfile || avatarUpdating}
          onSave={handleSubmit}
          onCancel={linkTo(state?.fromSignUp ? `/${routes.BRAND}/${routes.OPPORTUNITIES}` : routes.BRAND_PUBLIC_PAGE)}
        />
      )}
    </>
  );
};

import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Paper, Box, List, Typography, Grid, Button, Hidden } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import clsx from 'clsx';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import sortBy from 'lodash/sortBy';

import TalentCard from 'components/TalentCard';
import Chat, { ChatWidget, ChatFAB } from 'components/Chat';
import { Loader } from 'components/Loader';

import { UserTypeEnum } from 'types/common';
import { intl } from 'helpers';
import { useAuth } from 'hooks/auth';
import { routes } from 'router/routesList';
import { APPLICANT_STATUSES, TALENT_STATUS, STATUSES } from 'constants/index';
import type { Applicant, Offer } from 'types/opportunity';

import { GET_TALENT_BY_ID } from 'services/graphql/talent';
import { GET_UNREAD_MESSAGES } from 'services/graphql/query/messanger';
import { DECLINE_OPPORTUNITY } from 'services/graphql/mutation/opportunity';
import { MARK_CONVERSATION_AS_READ } from 'services/graphql/mutation/messanger';
import { TOGGLE_SHORTLIST_TALENT, UpdateApplicantOptionsData, UpdateApplicantOptionsVars, UPDATE_USER_FAVOURITE } from 'services/graphql/brand';

import { useStyles } from './styles';
import ApplicantItem from './components/ApplicantItem';
import ProposalModal from '../ProposalModal';

const Applicants = ({
  applicants,
  opportunityId,
  baseQuery,
  redemption,
  isApplied,
  conversationData,
  subscribeToMore,
  fetchMore,
  status,
  getConversation,
  currentApplicant,
  setCurrentApplicant,
  loadingConversation,
  profitMargins,
  unreadMessages = [],
}) => {
  const { push } = useHistory();
  const { getRole } = useAuth();
  const styles = useStyles();

  const [open, setOpen] = useState(false);
  const [proposalModalOpen, setProposalModalOpen] = useState(false);

  const isAdmin = getRole() === UserTypeEnum.admin;
  const getConversationId = conversationData?.getConversation?.id || null;

  const handleClickOpen = (setter: Dispatch<SetStateAction<boolean>>) => {
    setter(true);
  };

  const handleClose = (setter: Dispatch<SetStateAction<boolean>>) => {
    setter(false);
  };

  const selectApplicationById = (_id: string) => applicants.find((a: Applicant) => a.applicantId === _id).applicationId;

  const [toggleFavorite] = useMutation(UPDATE_USER_FAVOURITE, {
    update: (cache, { data }) => {
      const { entityId } = data.updateUserFavourite;
      const qr = cache.readQuery<any>(baseQuery);

      const selectedApplicant = qr.getOpportunity.applicants.find((a: Applicant) => a.applicantId === entityId);
      const mutatedApplicant: Applicant = { ...selectedApplicant, isFavourite: !selectedApplicant.isFavourite };

      const idx = qr.getOpportunity.applicants.findIndex((a: Applicant) => a.applicantId === entityId);
      const buffApplicants: Array<Applicant> = [...qr.getOpportunity.applicants];
      buffApplicants[idx] = mutatedApplicant;

      cache.writeQuery({
        ...baseQuery,
        data: {
          getOpportunity: {
            ...qr.getOpportunity,
            applicants: buffApplicants,
          },
        },
      });
    },
  });

  const [toggleShortlistTalent] = useMutation<UpdateApplicantOptionsData, UpdateApplicantOptionsVars>(TOGGLE_SHORTLIST_TALENT, {
    update: (cache, { data }) => {
      const { updateApplicantOptions } = data;
      const qr = cache.readQuery<any>(baseQuery);
      const selectedApplicant = qr.getOpportunity.applicants.find((a: Applicant) => a.applicationId === updateApplicantOptions.applicationId);
      const mutatedApplicant: Applicant = { ...selectedApplicant, isShortlisted: updateApplicantOptions.shortlisted };

      const idx = qr.getOpportunity.applicants.findIndex((a: Applicant) => a.applicationId === updateApplicantOptions.applicationId);
      const buffApplicants: Array<Applicant> = [...qr.getOpportunity.applicants];
      buffApplicants[idx] = mutatedApplicant;

      cache.writeQuery({
        ...baseQuery,
        data: {
          getOpportunity: {
            ...qr.getOpportunity,
            applicants: buffApplicants,
          },
        },
      });
    },
  });
  const [markConversationAsRead] = useMutation(MARK_CONVERSATION_AS_READ);
  const [getTalentInfo, { data: talentData, loading: talentLoading }] = useLazyQuery(GET_TALENT_BY_ID, {
    onCompleted: (completedData) => {
      if (!conversationData || !loadingConversation) {
        setCurrentApplicant(selectApplicationById(completedData.getTalentProfile.id));
        getConversation({
          variables: {
            applicationId: selectApplicationById(completedData.getTalentProfile.id),
          },
        });
      }
    },
  });

  useEffect(() => {
    if (currentApplicant) {
      const matched = applicants.find((a: Applicant) => a?.applicationId === currentApplicant)?.applicantId;
      getTalentInfo({
        variables: {
          talentId: matched,
        },
      });
    }
  }, [currentApplicant]);

  const [declineApplicant, { loading: declineLoading }] = useMutation(DECLINE_OPPORTUNITY);

  const getMutatedApplicants = (opportunityStatus: string, applicantId: string, applicationId: string, applicantsArr: Array<Applicant>) => {
    const talent = applicantsArr.find((i) => i.applicantId === applicantId);
    const mutatedTalent = { ...talent, status: opportunityStatus, applicationId };
    const filteredArr = applicantsArr.filter((i) => i.applicantId !== applicantId) || [];
    return [mutatedTalent, ...filteredArr];
  };

  const applicantData: Applicant = applicants.find((a: Applicant) => a.applicationId === currentApplicant);

  const canSendOffer = !applicantData?.offer?.length || applicantData?.offer?.every((o: Offer) => !!o.declinedAt);

  const handleSetDecline = (applicationId: string) => {
    const applicantId = applicants.find((a: Applicant) => a.applicationId === applicationId)?.applicantId;

    declineApplicant({
      variables: {
        data: {
          opportunityId,
          talentId: applicantId,
        },
      },
      update: (cache) => {
        const qr = cache.readQuery<any>(baseQuery);
        const mutatedApplicants = getMutatedApplicants(APPLICANT_STATUSES.DECLINED.value, applicantId, applicationId, qr.getOpportunity.applicants);

        cache.writeQuery({
          ...baseQuery,
          data: {
            getOpportunity: {
              ...qr.getOpportunity,
              applicants: mutatedApplicants,
            },
          },
        });
      },
    });
  };

  const handleTalentClick = (talent: Applicant) => {
    if (currentApplicant === talent.applicationId) return;

    push(`/${isAdmin ? routes.ADMIN : routes.BRAND}/${routes.OPPORTUNITIES}/${opportunityId}/applicants/${talent.applicantId}`);
    getTalentInfo({ variables: { talentId: talent.applicantId } });
    setCurrentApplicant(talent.applicationId);
    const selectedConversation = unreadMessages?.find((i) => i?.applicationId === talent.applicationId)?.id;
    if (unreadMessages.length && selectedConversation) {
      markConversationAsRead({
        variables: {
          conversationId: selectedConversation,
        },
        refetchQueries: [{ query: GET_UNREAD_MESSAGES, variables: { opportunityId } }],
        awaitRefetchQueries: true,
      });
    }
  };

  const handleBackClick = () => {
    setCurrentApplicant(null);
  };

  const handleAddToShortlist = (applicationId: string, shortlisted: boolean) => {
    toggleShortlistTalent({
      variables: {
        applicationId,
        data: { shortlisted },
      },
    });
  };

  const handleFavorite = (id: string) => {
    toggleFavorite({
      variables: {
        entityType: 'talent',
        entityId: id,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateUserFavourite: {
          __typename: 'UserFavourite',
          entityType: 'talent',
          entityId: id,
        },
      },
    });
  };

  const matchApplicant = (applicationId: string) => unreadMessages.find((i) => i.applicationId === applicationId);

  const pendingApplicants = applicants.filter(
    (i: Applicant) => i.status === APPLICANT_STATUSES.PENDING.value || i.status === APPLICANT_STATUSES.CONFIRMED.value,
  );

  const sortedPendingApplicants = sortBy(pendingApplicants, ({ isShortlisted }) => !isShortlisted);

  const declinedApplicants = applicants.filter(
    (i: Applicant) => i.status === APPLICANT_STATUSES.DECLINED.value || i.status === APPLICANT_STATUSES.CANCELED.value,
  );

  const sortedDeclinedApplicants = sortBy(declinedApplicants, ({ isShortlisted }) => !isShortlisted);

  const shouldRenderActions =
    !isAdmin &&
    conversationData?.getConversation &&
    canSendOffer &&
    sortedPendingApplicants.find((i: Applicant) => i.applicationId === currentApplicant) &&
    status?.name !== STATUSES.EXPIRED.value;

  const proposalModalProps = {
    open: proposalModalOpen,
    handleClose: () => handleClose(setProposalModalOpen),
    opportunityId,
    talentId: applicants.find((a: Applicant) => a.applicationId === currentApplicant)?.applicantId,
    applicationId: currentApplicant,
    currency: redemption?.currency.code,
    budget: redemption?.budget,
    isTaxed: redemption?.isTaxed,
    conversationId: getConversationId,
    profitMargins,
  };

  const chatWidget = {
    onClose: () => handleClose(setOpen),
    open,
    conversation: conversationData?.getConversation,
    disabled:
      !!sortedDeclinedApplicants.find((a: Applicant) => currentApplicant === a.applicationId) ||
      isApplied === TALENT_STATUS.DECLINED ||
      status?.name === STATUSES.DONE.value ||
      status?.name === STATUSES.CANCELED_BY_BRAND.value ||
      status?.name === STATUSES.CANCELED_BY_TALENT.value,
    subscribeToMore,
    fetchMore,
    currentApplicant,
    opportunityId,
    opportunityStatus: status?.name,
  };
  const chatProps = {
    conversation: conversationData?.getConversation,
    disabled:
      !!sortedDeclinedApplicants.find((a: Applicant) => currentApplicant === a.applicationId) ||
      isApplied === TALENT_STATUS.DECLINED ||
      status?.name === STATUSES.CANCELED_BY_ADMIN.value ||
      status?.name === STATUSES.CANCELED_BY_ADMIN_PAYMENT.value ||
      status?.name === STATUSES.DONE.value ||
      status?.name === STATUSES.CANCELED_BY_BRAND.value ||
      status?.name === STATUSES.CANCELED_BY_TALENT.value ||
      status?.name === STATUSES.EXPIRED.value,
    subscribeToMore,
    fetchMore,
    currentApplicant,
    opportunityId,
    opportunityStatus: status?.name,
  };

  const applicantItemProps = {
    handleClick: handleTalentClick,
    currentApplicant,
  };

  return (
    <Box className={styles.box}>
      <Grid container className={styles.container}>
        <Grid
          item
          lg={4}
          sm={7}
          className={clsx(styles.applicants, {
            [styles.hidden]: currentApplicant,
          })}
        >
          <Paper className={clsx(styles.paper)}>
            <Typography className={styles.listTitle}>
              {intl('OPPORTUNITY.applicants')} / {sortedPendingApplicants.length}
            </Typography>
            <List disablePadding className={styles.list}>
              {sortedPendingApplicants.map((applicant: Applicant) => (
                <ApplicantItem
                  key={applicant.applicantId}
                  applicant={applicant}
                  unreadCount={matchApplicant(applicant.applicationId)}
                  {...applicantItemProps}
                />
              ))}
            </List>
            <Typography className={styles.listTitle}>
              {intl('OPPORTUNITY.declined')} / {sortedDeclinedApplicants.length}
            </Typography>
            <List disablePadding className={styles.list}>
              {sortedDeclinedApplicants.map((applicant: Applicant) => (
                <ApplicantItem
                  key={applicant.applicantId}
                  applicant={applicant}
                  unreadCount={matchApplicant(applicant.applicationId)}
                  {...applicantItemProps}
                />
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid
          item
          lg={5}
          sm={5}
          className={clsx(styles.talentBox, {
            [styles.hidden]: !currentApplicant,
          })}
        >
          {talentLoading && <Loader />}
          {talentData?.getTalentProfile && (
            <>
              <Hidden smUp>
                <div className={styles.backBox}>
                  <Button
                    onClick={handleBackClick}
                    startIcon={<ArrowBackIos className={styles.backIco} fontSize="small" />}
                    className={styles.btnBack}
                  >
                    {intl('OPPORTUNITY.backToApplicants')}
                  </Button>
                </div>
              </Hidden>
              <TalentCard
                isAdmin={isAdmin}
                handleAddToShortlist={handleAddToShortlist}
                handleFavorite={handleFavorite}
                className={styles.talentCard}
                data={talentData.getTalentProfile}
                applicant={applicants.find((a: Applicant) => a.applicationId === currentApplicant)}
              >
                <Box className={styles.talentChildrenBox}>
                  <Hidden mdUp>
                    <ChatFAB unreadCount={conversationData?.getConversation.unreadMessagesCount} onClick={() => handleClickOpen(setOpen)} />
                  </Hidden>
                  {shouldRenderActions && (
                    <Box className={styles.actions}>
                      <Button className={styles.btnDecline} onClick={() => handleSetDecline(currentApplicant)} disabled={declineLoading}>
                        {intl('OPPORTUNITY.decline')}
                      </Button>
                      <Button className={styles.btnAccept} onClick={() => handleClickOpen(setProposalModalOpen)} disabled={declineLoading}>
                        {intl('OPPORTUNITY.accept')}
                      </Button>
                    </Box>
                  )}
                </Box>
              </TalentCard>
            </>
          )}
        </Grid>
        <Hidden smDown>
          <Grid item lg={7} className={styles.chatBox}>
            {loadingConversation || talentLoading ? <Loader /> : currentApplicant && <Chat {...chatProps} />}
          </Grid>
        </Hidden>
      </Grid>
      <ChatWidget {...chatWidget} />
      <ProposalModal {...proposalModalProps} />
    </Box>
  );
};

export default Applicants;

import React from 'react';
import { Box } from '@material-ui/core';

export const TabPanel = ({ children, value, name, ...other }) => (
  <Box role="tabpanel" hidden={value !== name} id={`news-feed-brand-tabpanel-${name}`} aria-labelledby={`news-feed-brand-tab-${name}`} {...other}>
    {value === name && <Box className={other.className}>{children}</Box>}
  </Box>
);

export const a11yProps = (index: number) => ({
  id: `news-feed-brand-tab-${index}`,
  'aria-controls': `news-feed-brand-tab-panel-${index}`,
});

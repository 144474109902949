import { gql } from '@apollo/client';

const CREATE_CONVERSATION_MESSAGE = gql`
  mutation createConversationMessage($data: CreateConversationMessageInput!) {
    createConversationMessage(data: $data) {
      id
      conversationId
      sender {
        firstName
        id
        image
        lastName
        type
      }
      body
      action
      applicationId
      coverLetter
      offerId
      offer {
        confirmedAt
        declinedAt
        description
        id
        talentSalary
        isTaxed
        offerAmount
        opportunityId
        talentId
      }
      createdAt
    }
  }
`;

const CREATE_CONVERSATION = gql`
  mutation createConversation($data: CreateConversationInput!) {
    createConversation(data: $data) {
      id
      opportunityId
      participants
      messages
      unreadMessagesCount
      createdAt
    }
  }
`;

const MARK_CONVERSATION_AS_READ = gql`
  mutation markConversationAsRead($conversationId: ID!) {
    markConversationAsRead(conversationId: $conversationId)
  }
`;

export { CREATE_CONVERSATION_MESSAGE, CREATE_CONVERSATION, MARK_CONVERSATION_AS_READ };

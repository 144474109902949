import { makeStyles, Theme } from '@material-ui/core';
import { colors } from 'theme/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    maxWidth: 400,
  },
  buttons: {
    width: 152,
    marginRight: theme.spacing(2),
    '&:last-child': {
      marginRight: 0,
    },
  },
}));

import React from 'react';

const HourglassIcon = ({ className = '' }) => (
  <svg width="36" height="60" className={className} viewBox="0 0 36 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 0C2.7 0 0 2.7 0 6L0.0300007 15.54C0.0300007 17.13 0.66 18.63 1.77 19.77L12 30L1.77 40.29C0.66 41.4 0.0300007 42.93 0.0300007 44.52L0 54C0 57.3 2.7 60 6 60H30C33.3 60 36 57.3 36 54V44.52C36 42.93 35.37 41.4 34.26 40.29L24 30L34.23 19.8C35.37 18.66 36 17.13 36 15.54V6C36 2.7 33.3 0 30 0H6ZM30 44.73V51C30 52.65 28.65 54 27 54H9C7.35 54 6 52.65 6 51V44.73C6 43.92 6.33 43.17 6.87 42.6L18 31.5L29.13 42.63C29.67 43.17 30 43.95 30 44.73Z"
      fill="#838CA7"
    />
  </svg>
);

export { HourglassIcon };

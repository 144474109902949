import { ProfitMargins } from './profitMargins';

type IdName = {
  id: string;
  name: string;
};

export enum UpdateOpportunityStrategyEnum {
  single = 'single',
  series = 'series',
}

export enum RecurrenceInterval {
  none = 'none',
  weekly = 'weekly',
  monthly = 'monthly',
  annually = 'annually',
}

export type OpportunityRecurrenceType = {
  repeatFrequency: RecurrenceInterval;
  repeatUntil: string;
};

export type OpportunityType = {} & IdName;

export type Currency = {
  id: string;
  code: string;
};

export type Redemption = {
  currency: Currency;
  budget: number;
  finalPrice: number;
  isTaxed: boolean;
  talentSalary: number;
};

export type Country = {} & IdName;

export type Sport = {} & IdName;

export type City = {
  lon: number;
  lat: number;
} & IdName;

export type Industry = {} & IdName;

export enum AppliedStatusEnum {
  APPLIED = 'APPLIED',
  NOT_APPLIED = 'NOT_APPLIED',
  DECLINED = 'DECLINED',
}

export type OpportunityStatus = {} & IdName;

export type OpportunityBrand = { image: string } & IdName;

export type Offer = {
  id: string;
  talentId: string;
  opportunityId: string;
  description: string;
  offerAmount: number;
  talentSalary: number;
  currencyCode: string;
  isTaxed: boolean;
  confirmedAt: number;
  declinedAt: number;
};

export type Applicant = {
  applicantId: string;
  applicationId: string;
  firstName: string;
  lastName: string;
  image: string;
  statusId: string;
  status: string;
  offer: Array<Offer>;
  hasStripe: boolean;
  hasWire: boolean;
  isShortlisted: boolean;
  isFavourite: boolean;
};

export type FinalCondition = {
  description: string;
  offerAmount: number;
  isTaxed: boolean;
  invoiceNumber: string;
};

export enum GenderEnum {
  male = 'male',
  female = 'female',
}

export type OpportunityTarget = {
  countries: Array<Country>;
  cities: Array<City>;
  sports: Array<Sport>;
  gender: GenderEnum | null;
  competition: boolean | null;
};

export type Opportunity = {
  id: string;
  parentId: string;
  name: string;
  description: string;
  type: OpportunityType;
  startDate: number;
  endDate: number;
  country: Country;
  city: City;
  redemption: Redemption;
  isRated: boolean;
  isApplied: AppliedStatusEnum;
  status: OpportunityStatus;
  brand: OpportunityBrand;
  applicants: Array<Applicant>;
  applicationId: string;
  finalCondition: FinalCondition;
  createdAt: number;
  recurrence: OpportunityRecurrenceType;
  target: OpportunityTarget;
  profitMargins: ProfitMargins;
  offer: {};
  hasStripe: boolean;
  hasWire: boolean;
  isShortlisted: boolean;
  images: Array<string>;
  __typename: string;
};

import React, { FC, useState, useEffect } from 'react';
import { Dialog, Box, IconButton } from '@material-ui/core';
import { CloseRounded as CloseIcon, ArrowBackIosRounded as ArrowBackIcon, ArrowForwardIosRounded as ArrowForwardIcon } from '@material-ui/icons';
import clsx from 'clsx';

import { useStyles } from './styles';
import type { Props } from './types';
import type { Image } from '../../type';

const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env;

const ImagesModal: FC<Props> = ({ open, images, currentImage, handleClose, showNavigation = true }) => {
  const styles = useStyles();
  const [current, setCurrent] = useState<Image>({ src: currentImage.src, index: currentImage.index });

  useEffect(() => {
    setCurrent({ src: currentImage.src, index: currentImage.index });

    return () => {
      setCurrent({ src: currentImage.src, index: currentImage.index });
    };
  }, [currentImage, open]);

  const handleBack = () => {
    const currentIdx = images.findIndex((i: string) => i === current.src);
    if (currentIdx === 0) setCurrent({ src: images[images.length - 1], index: images.length - 1 });
    else setCurrent({ src: images[currentIdx - 1], index: currentIdx - 1 });
  };
  const handleNext = () => {
    const currentIdx = images.findIndex((i: string) => i === current.src);
    if (currentIdx === images.length - 1) setCurrent({ src: images[0], index: 0 });
    else setCurrent({ src: images[currentIdx + 1], index: currentIdx + 1 });
  };

  return (
    <Dialog
      aria-labelledby="carousel-modal"
      aria-describedby="carousel-with-images"
      maxWidth="md"
      className={styles.modal}
      classes={{ paper: styles.paper }}
      open={open}
      onClose={handleClose}
      disableBackdropClick
    >
      <img src={REACT_APP_AWSS3_URL_IMAGE_BEG + current.src} className={styles.image} alt={`${current.src}'s_img`} />
      <Box className={styles.btnGroup}>
        <IconButton className={clsx(styles.iconBox, styles.btnClose)} onClick={handleClose}>
          <CloseIcon className={styles.icon} />
        </IconButton>
        {showNavigation && (
          <>
            <Box className={styles.navigationBox}>
              <IconButton className={styles.iconBox} onClick={handleBack}>
                <ArrowBackIcon className={styles.icon} />
              </IconButton>
              <IconButton className={styles.iconBox} onClick={handleNext}>
                <ArrowForwardIcon className={styles.icon} />
              </IconButton>
            </Box>
          </>
        )}
      </Box>
    </Dialog>
  );
};

export { ImagesModal };

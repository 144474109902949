import React from 'react'
import clsx from 'clsx'
import { Box, Typography } from '@material-ui/core'
import { SportEndorseLogo } from '../../assets/svg-js'
import { useStyles } from './styles'

export const SportEndorse = ({ className = '' }) => {
  const styles = useStyles({})
  return (
    <Box display="flex" className={clsx(styles.container, className)}>
      <SportEndorseLogo />
      <Typography className={styles.text}>SPORT ENDORSE</Typography>
    </Box>
  )
}

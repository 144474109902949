import React, { useState, FC } from 'react';
import { Box, IconButton } from '@material-ui/core';
import { ZoomIn as ZoomIcon, DeleteForeverOutlined as DeleteIcon } from '@material-ui/icons';
import clsx from 'clsx';

import { useStyles } from './styles';
import { Props } from './types';

const Image: FC<Props> = ({ imageSrc, actionable, handleZoom, handleDelete = () => {} }) => {
  const classes = useStyles();
  const [showButtons, setButtons] = useState<boolean>(false);

  const handleMouseIn = () => setButtons(true);
  const handleMouseOut = () => setButtons(false);

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <Box
      style={{
        backgroundImage: `url(${imageSrc})`,
      }}
      className={classes.item}
      onMouseOver={handleMouseIn}
      onMouseOut={handleMouseOut}
    >
      {actionable && (
        <IconButton
          className={clsx(classes.deleteBtn, {
            [classes.hidden]: !showButtons,
          })}
          onClick={() => handleDelete(imageSrc)}
        >
          <DeleteIcon className={classes.deleteIcon} />
        </IconButton>
      )}
      <IconButton
        className={clsx(classes.zoomBtn, {
          [classes.hidden]: !showButtons,
        })}
        onClick={() => handleZoom(imageSrc)}
      >
        <ZoomIcon className={classes.zoomIcon} />
      </IconButton>
    </Box>
  );
};

export default Image;

import React from 'react';
import { Box } from '@material-ui/core';
import { Switch, Redirect } from 'react-router-dom';

import { useAuth } from 'hooks/auth';
import { UserTypeEnum } from 'types/common';

import { Header } from 'components/Header';
import { Footer } from 'components/Footer';
import { IntroModal } from 'components/Modals/IntroModal/IntroModal';
import { useIntroModal } from 'components/Modals/IntroModal/useIntroModal';
import { DeletePostModal } from 'components/Modals/DeletePostModal';

import { CompanyInfo } from 'pages/Brand/CompanyInfo';
import { ProfileInfo } from 'pages/Brand/ProfileInfo';
import { NewPost } from 'pages/Post/NewPost';
import { EditPost } from 'pages/Post/EditPost';
import { NewOpportunity, UpdateOpportunity } from 'pages/Opportunity/OpportunityForm';
import { StripeConnectConfirmation } from 'pages/Talent/PrivateInfo/StripeConnectConfirmation';
import { CompanyPublicPage } from 'pages/Brand/CompanyPublicPage';
import { PaymentHistory } from 'pages/Brand/PaymentHistory';
import { TalentFromBrand } from 'pages/Talent/TalentFromBrand';
import { TalentOpportunities } from 'pages/Opportunity/TalentOpportunities';
import { BrandTalents } from 'pages/Brand/BrandTalents';
import { BrandAnalytics } from 'pages/Brand/BrandAnalytics';
import { TalentProfile } from 'pages/Talent/Profile';
import { NotFoundPage } from 'pages/NotFoundPage';
import { Post } from 'pages/Post/Post';
import { VerificationPage } from 'pages/VerificationPage';
import { Brands } from 'pages/Talent/Brands';
import { NewsFeed as TalentsNewsFeed } from 'pages/Talent/NewsFeed';
import { NewsFeed as BrandsNewsFeed } from 'pages/Brand/NewsFeed';
import OpportunitiesPage from 'pages/Opportunity/OpportunitiesPage';
import SingleOpportunity from 'pages/Opportunity/SingleOpportunity';
import { BrandsPage as TalentBrandsPage } from 'pages/Talent/BrandsPage';

import { routes } from './routesList';
import { PrivateRoute } from './PrivateRoute';
import { useStyles } from './styles';
import { SuccessModal } from '../components/Modals/SuccessModal';

export const App = () => {
  const { isShowModal, videoLink, handleOpenModal, handleCloseModal } = useIntroModal();
  const { user } = useAuth();
  const styles = useStyles({});
  return (
    <Box className={styles.app} id="app">
      <Header handleOpenModal={handleOpenModal} />

      <Switch>
        {user?.attributes['custom:role'] === UserTypeEnum.brand ? (
          <>
            <PrivateRoute path={`/${routes.BRAND_COMPANY_INFO}`} component={CompanyInfo} />
            <PrivateRoute path={`/${routes.BRAND_PROFILE_INFO}`} component={ProfileInfo} />
            <PrivateRoute path={routes.BRAND_ANALYTICS} component={BrandAnalytics} />
            {/* <PrivateRoute path={`/${routes.BRAND_CALENDAR}`} component={Calendar} /> */}
            <PrivateRoute path={routes.BRAND_PAYMENT_HISTORY} component={PaymentHistory} />
            <PrivateRoute exact path={`/${routes.BRAND}/${routes.OPPORTUNITIES}`} component={OpportunitiesPage} />
            <Switch>
              <PrivateRoute exact path={`/${routes.BRAND}/${routes.NEW_OPPORTUNITY}`} component={NewOpportunity} />
              <PrivateRoute exact path={`/${routes.BRAND}/${routes.EDIT_OPPORTUNITY}/:id`} component={UpdateOpportunity} />
              <PrivateRoute path={`/${routes.BRAND}/${routes.OPPORTUNITIES}/:id/:tab?`} component={SingleOpportunity} />
            </Switch>
            <PrivateRoute exact path={`/${routes.BRAND}/${routes.TALENTS}`} component={BrandTalents} />
            <PrivateRoute path={routes.BRAND_NEW_POST} component={NewPost} />
            <PrivateRoute path={`${routes.BRAND_EDIT_POST}/:id`} component={EditPost} />
            <PrivateRoute path={routes.BRAND_PUBLIC_PAGE} component={CompanyPublicPage} />
            <PrivateRoute path={routes.BRAND_VERIFICATION} component={VerificationPage} />
            <PrivateRoute exact path={`${routes.BRAND_NEWS_FEED}/:tab?`} component={BrandsNewsFeed} />
            <PrivateRoute path={`${routes.BRAND_NEWS_FEED_POST}/:id`} component={Post} />
            <PrivateRoute exact path={`/${routes.NEWS_FEED}/${routes.POST}/:id`} component={Post} />
            <PrivateRoute exact path={`/${routes.BRAND}/${routes.TALENT}/:talentId`} component={TalentFromBrand} />

            <PrivateRoute
              // eslint-disable-next-line
              path={`/${routes.BRAND}/${routes.TALENTS}/:talentId/${routes.OPPORTUNITIES}/:opportunityId`}
              exact
              component={TalentFromBrand}
            />
            <PrivateRoute exact path="/" component={() => <Redirect to={`${routes.BRAND}/${routes.OPPORTUNITIES}`} />} />
          </>
        ) : (
          <Switch>
            <PrivateRoute exact path={`/${routes.TALENT}/${routes.APPLIED}`} component={OpportunitiesPage} />
            <PrivateRoute exact path={`${routes.TALENT_BRANDS}/:tab`} component={Brands} />
            <PrivateRoute path={`/${routes.TALENT}/${routes.OPPORTUNITIES}/:id/:tab?`} component={SingleOpportunity} />
            <PrivateRoute exact path={`/${routes.TALENT}/${routes.OPPORTUNITIES}`} component={TalentOpportunities} />
            <PrivateRoute path={`/${routes.TALENT_STRIPE_CONFIRMATION}`} component={StripeConnectConfirmation} />
            <PrivateRoute path={routes.TALENT_VERIFICATION} component={VerificationPage} />
            {/* <PrivateRoute path={`/${routes.TALENT_CALENDAR}`} component={Calendar} /> */}
            <PrivateRoute path={routes.TALENT_PROFILE} component={TalentProfile} />

            <PrivateRoute path={routes.TALENT_BRAND_PAGE()} component={TalentBrandsPage} />
            <PrivateRoute path={routes.TALENT_NEW_POST} component={NewPost} />
            <PrivateRoute path={`${routes.TALENT_EDIT_POST}/:id`} component={EditPost} />
            <PrivateRoute exact path={`${routes.TALENT_NEWS_FEED}/:tab?`} component={TalentsNewsFeed} />
            <PrivateRoute path={`${routes.TALENT_NEWS_FEED_POST}/:id`} component={Post} />
            <PrivateRoute exact path={`/${routes.NEWS_FEED}/${routes.POST}/:id`} component={Post} />
            <PrivateRoute exact path="/" component={() => <Redirect to={`${routes.TALENT}/${routes.OPPORTUNITIES}`} />} />
            <PrivateRoute component={NotFoundPage} />
          </Switch>
        )}
      </Switch>

      <Footer handleOpenModal={handleOpenModal} />
      {isShowModal && <IntroModal isOpen={isShowModal} handleClose={handleCloseModal} src={videoLink} />}
      <SuccessModal />
      <DeletePostModal />
    </Box>
  );
};

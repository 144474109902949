import React, { useEffect, useState } from 'react';
import { Paper, Divider, Typography, Grid, TextField, MenuItem, InputAdornment, Tabs, Tab, Box } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Schedule } from '@material-ui/icons';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import { useQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { set, startOfDay, isBefore, endOfDay, isEqual } from 'date-fns';
import moment from 'moment';
import { useFormik } from 'formik';
import * as yup from 'yup';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { UpdateOpportunityStrategyEnum, RecurrenceInterval } from 'types/opportunity';
import { intl, isBrandValid } from 'helpers';

import FinalBudgetTooltip from 'components/FinalBudgetTooltip';
import MultiChipTextField from 'components/MultiChipTextField';
import VerifyModal from 'components/Modals/VerifyModal';
import ImageUploader from 'components/ImageUploader';
import ImageGallery from 'components/ImageGallery';
import { SaveBar } from 'components/SaveBar';
import { Wrapper } from 'components/Wrapper';

import { LIST_CITIES } from 'services/graphql/location';
import { UPDATE_OPPORTUNITY } from 'services/graphql/mutation/opportunity';

import TabPanel, { a11yProps } from '../TabPanel';
import EditRepeatedModal from '../EditRepeatedModal';
import { useStyles } from '../../styles';
import { TAXES, GENDERS, ACTIVITY, REPEAT } from '../../constants';
import { MAX_ELASTIC_NUMBER, OPPORTUNITY_FORM_TABS, STATUSES, MAX_IMAGES_COUNT } from '../../../../../constants';

const validationSchema = yup.object({
  name: yup.string().min(6, 'ERRORS.tooShortField').max(70, 'ERRORS.tooLongField').required('ERRORS.emptyField'),
  type: yup.mixed().required('ERRORS.emptyField'),
  startDate: yup.date().typeError('ERRORS.dateType').default(undefined).required('ERRORS.emptyField').nullable(false),
  endDate: yup.date().typeError('ERRORS.dateType').default(undefined).nullable().optional().min(yup.ref('startDate'), 'ERRORS.endDateLessStartDate'),
  startTime: yup.mixed().optional(),
  endTime: yup.mixed().optional(),
  repeat: yup.string().optional().nullable(),
  repeatTill: yup.date().typeError('ERRORS.dateType').nullable().optional(),
  parentId: yup.string().nullable(),
  country: yup.mixed().required('ERRORS.emptyField'),
  city: yup.mixed().required('ERRORS.emptyField'),
  budget: yup
    .number()
    .typeError('ERRORS.numberType')
    .required('ERRORS.emptyField')
    .nullable(false)
    .max(MAX_ELASTIC_NUMBER, 'ERRORS.budget')
    .positive(),
  tax: yup.string().required('ERRORS.emptyField'),
  description: yup.string().optional(),
  sport: yup.array().of(yup.mixed()).optional(),
  targetCities: yup.array().of(yup.mixed()).optional(),
  targetCountries: yup.array().of(yup.mixed()).optional(),
  activity: yup.string().optional(),
  gender: yup.string().optional(),
  ageFrom: yup.number().typeError('ERRORS.numberType').positive().optional(),
  ageTo: yup.number().typeError('ERRORS.numberType').positive().optional(),
});

const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env;
const BUCKET_PATH = `${REACT_APP_AWSS3_URL_IMAGE_BEG}`;
const BUCKET_TEMP_PATH = `${REACT_APP_AWSS3_URL_IMAGE_BEG}public/`;

const Form = ({ opportunity, countries, currencies, types, sports, verifyModalProps: { brandErrors, verifyModal, toggleVerifyModal }, isAdmin }) => {
  const classes = useStyles();
  const { goBack } = useHistory();
  const [tab, setTab] = useState(OPPORTUNITY_FORM_TABS.DETAILS);

  const [openModal, setModal] = useState(false);

  const toggleModal = () => setModal(!openModal);

  const formTitle =
    opportunity.status.name === STATUSES.CANCELED_BY_TALENT.value || opportunity.status.name === STATUSES.EXPIRED.value
      ? intl('OPPORTUNITY.renewOpportunityText')
      : intl('OPPORTUNITY.editOpportunity');

  const { values, errors, handleChange, setFieldValue, setFieldError, validateForm } = useFormik({
    initialValues: {
      name: opportunity.name,
      type: opportunity.type.name,
      startDate: new Date(opportunity.startDate),
      endDate: new Date(opportunity.endDate),
      startTime: null,
      endTime: null,
      repeat: opportunity.recurrence.repeatFrequency,
      repeatTill: opportunity.recurrence.repeatUntil ? moment(opportunity.recurrence.repeatUntil).format() : null,
      parentId: opportunity.parentId,
      country: opportunity.country.name,
      city: opportunity.city.name,
      budget: opportunity.redemption.budget,
      tax: opportunity.redemption.isTaxed ? TAXES[0].value : TAXES[1].value,
      description: opportunity.description,
      sport: opportunity.target.sports || [],
      targetCountries: opportunity.target.countries || [],
      targetCities: opportunity.target.cities || [],
      images: opportunity.images || [],
      gender:
        // eslint-disable-next-line no-nested-ternary
        opportunity.target.gender === null ? GENDERS[0].value : opportunity.target.gender === GENDERS[2].value ? GENDERS[2].value : GENDERS[1].value,
      activity:
        // eslint-disable-next-line no-nested-ternary
        opportunity.target.competition === null
          ? ACTIVITY[0].value
          : opportunity.target.competition === false
          ? ACTIVITY[2].value
          : ACTIVITY[1].value,
      // TODO remove optional chaining once the case will be handled on the backend
      ageFrom: opportunity.target.age?.from || '',
      ageTo: opportunity.target.age?.to || '',
    },
    validationSchema,
    validateOnChange: false,
  });

  const { data: citiesList, loading: citiesLoading } = useQuery(LIST_CITIES, {
    variables: {
      countryId: values.country?.id,
    },
  });

  const { data: targetCities, loading: targetCitiesLoading } = useQuery(LIST_CITIES, {
    skip: !values.targetCountries.length,
    variables: {
      countryIds: values.targetCountries?.map((i) => i.id),
    },
  });

  const [updateOpportunity, { loading: updateOpportunityLoading }] = useMutation(UPDATE_OPPORTUNITY);

  /**
   * @param {UpdateOpportunityStrategyEnum} [updateStrategy] strategy for updating repeated opportunities
   */
  const handleUpdateOpportunity = async (updateStrategy) => {
    const formErrors = await validateForm();
    if (Object.keys(formErrors).length) {
      if (tab.value === OPPORTUNITY_FORM_TABS.TARGET.value) setTab(OPPORTUNITY_FORM_TABS.DETAILS);
      return;
    }

    let startDateWithTime;
    let endDateWithTime;

    if (values.startTime) {
      startDateWithTime = set(values.startDate, {
        hours: values.startTime.getHours(),
        minutes: values.startTime.getMinutes(),
      }).toISOString();
    }
    if (values.endTime) {
      endDateWithTime = set(new Date(values.endDate), {
        hours: values.endTime.getHours(),
        minutes: values.endTime.getMinutes(),
      }).toISOString();
    } else if (!values.endDate && values.endTime) {
      endDateWithTime = set(values.startDate, {
        hours: values.endTime.getHours(),
        minutes: values.endTime.getMinutes(),
      }).toISOString();
    }

    const data = {
      opportunityId: opportunity.id,
      name: values.name.trim(),
      description: values.description,
      typeId: values.type.id,
      images: values.images,
      startDate: startDateWithTime || startOfDay(values.startDate).toISOString(),
      endDate: endDateWithTime || endOfDay(values.endDate).toISOString(),
      cityId: values.city.id,
      recurrence: {
        repeatFrequency: values.repeat === RecurrenceInterval.none ? null : values.repeat,
        repeatUntil: values.repeat === RecurrenceInterval.none ? null : values.repeatTill,
      },
      redemption: {
        currencyId: currencies.find(({ code }) => code === 'EUR')?.id ?? currencies[0].id,
        isTaxed: values.tax === TAXES[0].value,
        budget: +String(values.budget).replace(/ /g, ''),
      },
      target: {
        countriesIds: values.targetCountries?.map((i) => i.id),
        citiesIds: values.targetCities.map((i) => i.id),
        sportsIds: values.sport.map((i) => i.id),
        gender: values.gender === GENDERS[0].value ? null : values.gender,
        competition: values.activity === ACTIVITY[0].value ? null : values.activity === ACTIVITY[1].value,
        age: {
          from: +values.ageFrom > 0 ? +values.ageFrom : null,
          to: +values.ageTo > 0 ? +values.ageTo : null,
        },
      },
    };

    if (updateStrategy) data.updateStrategy = updateStrategy;

    try {
      await updateOpportunity({
        variables: {
          data,
        },
      });
      goBack();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  /**
   * @private
   * @param {React.ChangeEvent<HTMLInputElement>} event
   * @param {Array} options
   * @param {String} field
   */
  const handleSelectChange = (event, options, field) => {
    const selectedType = options.find((type) => type.value === event.target.value);
    setFieldValue(field, selectedType.value);
  };

  const handleDateChange = (field) => (date) => {
    if (date === null) {
      setFieldValue(field, null);
      return;
    }

    const endDate = values.endDate || values.startDate || new Date();
    const firstDate = set(values.startDate, {
      hours: values.startTime?.getHours() || 0,
      minutes: values.startTime?.getMinutes() || 0,
    });

    const secondDate = set(endDate, {
      hours: new Date(date).getHours(),
      minutes: new Date(date).getMinutes(),
    });

    if (field === 'endTime' && isBefore(secondDate, firstDate)) {
      setFieldValue('endTime', null);
      setFieldError('endTime', intl('ERRORS.endTimeLessStartTime'));
    } else if (field === 'endTime') {
      setFieldError('endTime', null);
    }

    setFieldValue(field, new Date(date));
  };

  const handleAutocompleteChange = (name) => (_, value) => {
    if (name === 'country' && (!value || value !== values.country)) {
      setFieldValue('city', null);
    }

    setFieldValue(name, value);
  };

  const handleSave = () => {
    const isLastRepeatedOpportunity = opportunity.recurrence.lastChildId === opportunity.id;
    if (opportunity.recurrence.repeatFrequency && opportunity.status.name === STATUSES.OPEN.value && !isLastRepeatedOpportunity) setModal(true);
    else handleUpdateOpportunity();
  };

  const onSave = () => {
    if (isAdmin || isBrandValid(brandErrors)) handleSave();
    else toggleVerifyModal();
  };

  useEffect(() => {
    handleAutocompleteChange('type')(null, opportunity.type);
    handleAutocompleteChange('country')(null, opportunity.country);
    handleAutocompleteChange('city')(null, opportunity.city);

    if (!isEqual(startOfDay(new Date(opportunity.startDate)), new Date(opportunity.startDate))) {
      handleDateChange('startTime')(opportunity.startDate);
    }
    if (!isEqual(endOfDay(new Date(opportunity.endDate)), new Date(opportunity.endDate))) {
      handleDateChange('endTime')(opportunity.endDate);
    }
  }, []);

  const handleTabChange = (_, newValue) => {
    switch (newValue) {
      case OPPORTUNITY_FORM_TABS.DETAILS.value:
        setTab(OPPORTUNITY_FORM_TABS.DETAILS);
        break;
      case OPPORTUNITY_FORM_TABS.TARGET.value:
        setTab(OPPORTUNITY_FORM_TABS.TARGET);
        break;
      default:
        setTab(OPPORTUNITY_FORM_TABS.DETAILS);
    }
  };

  const handleSetImages = (images) => {
    const currentImages = values.images.filter((image) => image !== 'loader');
    setFieldValue('images', [...currentImages, ...images]);
  };

  const handleSetImagesWithLoader = (loaders) => {
    setFieldValue('images', [...values.images, ...loaders]);
  };

  const handleDeleteImage = (image) => {
    const images = values.images.filter((currentImage) => !image.replace(BUCKET_PATH, '').includes(currentImage));
    setFieldValue('images', images);
  };

  const handleAgeChange = (value, name) => {
    if (Number.isNaN(+value)) setFieldError(name, 'ERRORS.numberType');
    else setFieldError(name, null);

    setFieldValue(name, value);
  };

  const mappingImages = (image) => (image.includes('uploads') ? BUCKET_PATH + image : BUCKET_TEMP_PATH + image);

  const editRepeatedModalProps = {
    open: openModal,
    handleClose: toggleModal,
    loading: updateOpportunityLoading,
    handleUpdateOpportunity,
  };

  return (
    <>
      <Wrapper>
        <Paper className={classes.paper}>
          <Box className={classes.header}>
            <Typography variant="h5" className={classes.title}>
              {formTitle}
            </Typography>
            <Tabs indicatorColor="primary" aria-label="brand-opportunity-tabs" value={tab.value} variant="fullWidth" onChange={handleTabChange}>
              <Tab
                label={intl(OPPORTUNITY_FORM_TABS.DETAILS.label)}
                {...a11yProps(OPPORTUNITY_FORM_TABS.DETAILS.value)}
                classes={{ root: classes.tabRoot }}
              />
              <Tab
                label={intl(OPPORTUNITY_FORM_TABS.TARGET.label)}
                {...a11yProps(OPPORTUNITY_FORM_TABS.TARGET.value)}
                classes={{ root: classes.tabRoot }}
              />
            </Tabs>
          </Box>

          <Divider className={classes.divider} />

          <TabPanel value={tab.value} index={OPPORTUNITY_FORM_TABS.DETAILS.value}>
            <Grid container spacing={4} component="form">
              <Grid item lg={6} xl={6} xs={12}>
                <TextField
                  name="name"
                  error={!!errors.name}
                  helperText={intl(errors.name)}
                  value={values.name}
                  onChange={handleChange}
                  label={intl('BRAND.OPPORTUNITY.name')}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item lg={6} xl={6} xs={12}>
                <Autocomplete
                  name="type"
                  loading={false}
                  options={types || []}
                  value={values.type}
                  onChange={handleAutocompleteChange('type')}
                  getOptionLabel={(option) => option?.name || ''}
                  getOptionSelected={(option, value) => option.name === value.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      helperText={intl(errors.type)}
                      error={!!errors.type}
                      name="type"
                      label={intl('BRAND.OPPORTUNITY.type')}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <KeyboardDatePicker
                  clearable
                  format="dd.MM.yy"
                  name="startDate"
                  error={!!errors.startDate}
                  helperText={intl(errors.startDate)}
                  maxDate={values.endDate}
                  maxDateMessage={intl('ERRORS.startDateMoreEndDate')}
                  value={values.startDate || null}
                  label={intl('BRAND.OPPORTUNITY.startDate')}
                  onChange={handleDateChange('startDate')}
                  fullWidth
                  required
                  disablePast
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <KeyboardDatePicker
                  clearable
                  format="dd.MM.yy"
                  name="endDate"
                  error={!!errors.endDate}
                  helperText={intl(errors.endDate)}
                  minDate={values.startDate}
                  value={values.endDate || undefined}
                  label={intl('BRAND.OPPORTUNITY.endDate')}
                  onChange={handleDateChange('endDate')}
                  fullWidth
                  required
                  disablePast
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <KeyboardTimePicker
                  name="startTime"
                  error={!!errors.startTime}
                  helperText={intl(errors.startTime)}
                  fullWidth
                  minutesStep={5}
                  variant="dialog"
                  keyboardIcon={<Schedule />}
                  label={intl('BRAND.OPPORTUNITY.startTime')}
                  value={values.startTime}
                  onChange={handleDateChange('startTime')}
                />
              </Grid>
              <Grid item lg={6} xl={6} xs={12}>
                <KeyboardTimePicker
                  name="endTime"
                  error={!!errors.endTime}
                  helperText={intl(errors.endTime)}
                  fullWidth
                  minutesStep={5}
                  variant="dialog"
                  keyboardIcon={<Schedule />}
                  label={intl('BRAND.OPPORTUNITY.endTime')}
                  value={values.endTime}
                  onChange={handleDateChange('endTime')}
                />
              </Grid>
              <Grid item lg={6} xl={6} xs={12} className={classes.selectContainer}>
                <TextField
                  select
                  error={!!errors.repeat}
                  helperText={intl(errors.repeat)}
                  disabled={values.parentId || !values.repeat}
                  fullWidth
                  name="repeat"
                  value={values.repeat}
                  onChange={(e) => handleSelectChange(e, REPEAT, 'repeat')}
                  label={intl('BRAND.OPPORTUNITY.repeat')}
                >
                  {REPEAT.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {intl(option.label)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item lg={6} xl={6} xs={12}>
                <KeyboardDatePicker
                  clearable
                  format="dd.MM.yy"
                  disabled={values.parentId || !values.repeat}
                  name="repeatTill"
                  error={!!errors.repeatTill}
                  helperText={intl(errors.repeatTill)}
                  minDate={values.startDate}
                  value={values.repeatTill || null}
                  label={intl('BRAND.OPPORTUNITY.repeatTill')}
                  onChange={handleDateChange('repeatTill')}
                  fullWidth
                  disablePast
                />
              </Grid>
              <Grid item lg={6} xl={6} xs={12}>
                <Autocomplete
                  name="country"
                  loading={false}
                  options={countries || []}
                  value={values.country}
                  onChange={handleAutocompleteChange('country')}
                  getOptionLabel={(option) => option?.name || ''}
                  getOptionSelected={(option, value) => option?.name === value?.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      error={!!errors.country}
                      helperText={intl(errors.country)}
                      name="country"
                      label={intl('BRAND.OPPORTUNITY.country')}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={6} xl={6} xs={12}>
                <Autocomplete
                  name="city"
                  loading={citiesLoading}
                  disabled={!values.country}
                  options={citiesList?.listCities ?? []}
                  value={values.city}
                  onChange={handleAutocompleteChange('city')}
                  getOptionLabel={(option) => option?.name}
                  getOptionSelected={(option, value) => option?.name === value?.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      error={!!errors.city}
                      helperText={intl(errors.city)}
                      name="city"
                      label={intl('BRAND.OPPORTUNITY.city')}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={6} xl={6} xs={12}>
                <TextField
                  name="budget"
                  error={!!errors.budget}
                  helperText={
                    intl(errors.budget) ||
                    (values.budget > 0 && <FinalBudgetTooltip simple budget={+values.budget} profitMargins={opportunity.profitMargins} />)
                  }
                  value={values.budget}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">€</InputAdornment>,
                  }}
                  onChange={handleChange}
                  label={intl('BRAND.OPPORTUNITY.budget')}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item lg={5} xl={5} xs={12} className={classes.selectContainer}>
                <p className={classes.taxText}>{intl('OPPORTUNITY.taxExcluded')}</p>
                {/* <TextField
                  select
                  error={!!errors.tax}
                  helperText={intl(errors.tax)}
                  required
                  fullWidth
                  name="tax"
                  value={values.tax}
                  onChange={(e) => handleSelectChange(e, TAXES, 'tax')}
                  label={intl('BRAND.OPPORTUNITY.tax')}
                >
                  {TAXES.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {intl(option.label)}
                    </MenuItem>
                  ))}
                </TextField> */}
              </Grid>
              <Grid item lg={12} xl={12} xs={12}>
                <TextField
                  name="description"
                  multiline
                  error={!!errors.description}
                  helperText={intl(errors.description)}
                  value={values.description}
                  onChange={handleChange}
                  label={intl('BRAND.OPPORTUNITY.description')}
                  fullWidth
                />
              </Grid>
              <Grid item lg={12} xl={12} xs={12} className={classes.imagesBox}>
                <ImageGallery actionable handleDelete={handleDeleteImage} images={values.images.map(mappingImages)} />
                {values.images.length < MAX_IMAGES_COUNT && (
                  <ImageUploader images={values.images} setValue={handleSetImages} setWithLoader={handleSetImagesWithLoader} />
                )}
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={tab.value} index={OPPORTUNITY_FORM_TABS.TARGET.value}>
            <Grid container spacing={4} component="form">
              <Grid item lg={12} xl={12} xs={12}>
                <MultiChipTextField
                  onChange={handleAutocompleteChange('sport')}
                  name="sport"
                  label="SHARED.sport"
                  value={values.sport}
                  options={sports}
                />
              </Grid>
              <Grid item lg={12} xl={12} xs={12}>
                <MultiChipTextField
                  onChange={handleAutocompleteChange('targetCountries')}
                  name="targetCountries"
                  label="SHARED.country"
                  value={values.targetCountries}
                  options={countries}
                />
              </Grid>
              <Grid item lg={12} xl={12} xs={12}>
                <MultiChipTextField
                  onChange={handleAutocompleteChange('targetCities')}
                  name="targetCities"
                  label="SHARED.city"
                  value={values.targetCities}
                  loading={targetCitiesLoading}
                  options={targetCities?.listCities ?? []}
                />
              </Grid>
              <Grid item lg={6} xl={6} xs={12}>
                <TextField
                  select
                  error={!!errors.gender}
                  helperText={intl(errors.gender)}
                  fullWidth
                  name="gender"
                  value={values.gender}
                  onChange={(e) => handleSelectChange(e, GENDERS, 'gender')}
                  label={intl('BRAND.OPPORTUNITY.gender')}
                >
                  {GENDERS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {intl(option.label)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item lg={6} xl={6} xs={12}>
                <TextField
                  select
                  error={!!errors.activity}
                  helperText={intl(errors.activity)}
                  fullWidth
                  name="activity"
                  value={values.activity}
                  onChange={(e) => handleSelectChange(e, ACTIVITY, 'activity')}
                  label={intl('BRAND.OPPORTUNITY.activity')}
                >
                  {ACTIVITY.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {intl(option.label)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item container lg={6} xl={6} xs={12} spacing={3}>
                <Grid item lg={6} xl={6} xs={12}>
                  <TextField
                    fullWidth
                    name="ageFrom"
                    value={values.ageFrom}
                    error={!!errors.ageFrom}
                    helperText={intl(errors.ageFrom)}
                    label={intl('BRAND.OPPORTUNITY.ageFrom')}
                    onChange={(e) => handleAgeChange(e.target.value, 'ageFrom')}
                  />
                </Grid>
                <Grid item lg={6} xl={6} xs={12}>
                  <TextField
                    fullWidth
                    name="ageTo"
                    value={values.ageTo}
                    error={!!errors.ageTo}
                    helperText={intl(errors.ageTo)}
                    label={intl('BRAND.OPPORTUNITY.ageTo')}
                    onChange={(e) => handleAgeChange(e.target.value, 'ageTo')}
                  />
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>
        </Paper>
      </Wrapper>
      <SaveBar disabled={updateOpportunityLoading} onSave={onSave} onCancel={goBack} />
      <EditRepeatedModal {...editRepeatedModalProps} />
      <VerifyModal open={verifyModal} errors={brandErrors} handleClose={toggleVerifyModal} />
    </>
  );
};

export default Form;

import { makeStyles } from '@material-ui/core';
import { colors } from 'theme/colors';

export const useStyles = makeStyles((theme) => ({
  container: {
    height: '98%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    '@media (max-height: 1024px)': {
      height: '97%',
    },
  },
  messageList: {
    height: '100%',
    overflow: 'auto',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  infiniteScroll: {
    marginTop: theme.spacing(2),
  },
  iconBox: {
    padding: '26px 26px 16px',
    backgroundColor: colors.BACKGROUND,
    borderRadius: '100%',
  },
  emptyBox: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyText: {
    marginTop: theme.spacing(2),
    fontSize: 18,
  },
}));

import { makeStyles } from '@material-ui/core';
import { colors } from 'theme/colors';

export const useStyles = makeStyles((theme) => ({
  publicPage: {
    marginTop: 24,
    marginBottom: theme.spacing(3),
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  publicPageContent: {
    flex: 1,
    marginLeft: 24,
    '@media (max-width: 768px)': {
      marginLeft: 0,
    },
  },
  chip: {
    background: colors.WHITE,
    border: `1px solid ${colors.PROFILE_BORDER}`,
    borderRadius: 4,
    marginRight: 8,
    marginBottom: 8,
  },
  paper: {
    padding: 0,
    marginBottom: theme.spacing(3),
  },
  sectionHeading: {
    color: colors.TEXT,
    fontWeight: 600,
    textTransform: 'uppercase',
    marginBottom: 20,
  },
  showMore: {
    display: 'block',
    margin: '0 auto',
  },
  loaderPlaceholder: {
    minHeight: 250,
    display: 'flex',
    alignItems: 'center',
  },
  row: {
    cursor: 'pointer',
  },
}));

import React, { FC } from 'react';
import { Divider, Grid } from '@material-ui/core';

import { Loader } from 'components/Loader';
import { Header } from './Header';
import { GeneralInfo } from './GeneralInfo';
import { WorkedFor, AgreementSent, ProfileViewed } from './AnalyticCards';

import { useTalentAnalytics } from './hooks';

import { useStyles } from './styles';

export const TalentAnalytics: FC = () => {
  const {
    loading,
    brandsWorkedFor,
    brandsWorkedForCount,
    brandsAgreementSent,
    agreementsSentCount,
    brandsTalentProfileViewed,
    profileViewsCount,
    loadMore,
    revenueAveragePerOpportunity,
    revenueCanceledOpportunities,
    revenueDoneOpportunities,
    startDate,
    endDate,
    handleDateChange,
    handleSetFocus,
    currentFocus,
  } = useTalentAnalytics();
  const styles = useStyles({});

  if (loading) {
    return <Loader />;
  }

  return (
    <Grid container direction="column" className={styles.analyticsWrapper}>
      <Header
        // @ts-ignore
        startDate={startDate}
        endDate={endDate}
        handleDateChange={handleDateChange}
        handleSetFocus={handleSetFocus}
        currentFocus={currentFocus}
      />
      <Divider />
      <GeneralInfo
        revenueAveragePerOpportunity={revenueAveragePerOpportunity}
        revenueCanceledOpportunities={revenueCanceledOpportunities}
        revenueDoneOpportunities={revenueDoneOpportunities}
      />
      <Grid container spacing={2} justify="space-between" className={styles.contentContainer}>
        <WorkedFor brandsWorkedFor={brandsWorkedFor} brandsWorkedForCount={brandsWorkedForCount} loadMore={loadMore} />
        <AgreementSent brandsAgreementSent={brandsAgreementSent} agreementsSentCount={agreementsSentCount} loadMore={loadMore} />
        <ProfileViewed brandsTalentProfileViewed={brandsTalentProfileViewed} profileViewsCount={profileViewsCount} loadMore={loadMore} />
      </Grid>
    </Grid>
  );
};

import { makeStyles } from '@material-ui/core';
import { colors } from 'theme/colors';

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 32,
    marginBottom: 16,
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 10,
    alignItems: 'center',
  },
  name: {
    fontSize: 24,
    fontWeight: 500,
    color: colors.TEXT,
  },
  mainInfo: {
    paddingTop: 16,
    borderTop: `1px solid ${colors.BORDER2}`,
  },
  badges: {
    marginTop: 6,
    marginBottom: 4,
    display: 'flex',
    alignItems: 'center',
    '& span': {
      marginRight: theme.spacing(1),
    },
  },
  social: {
    display: 'flex',
    paddingTop: 16,
  },
  iconWithCount: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 45,
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(2),
    },
  },
  count: {
    marginLeft: 12,
    color: colors.TEXT,
    fontWeight: 500,
  },
  typoWithIcon: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  icon: {
    height: 16,
    width: 16,
    marginTop: 3,
    marginRight: 10,
  },
  iconTypoFirst: {
    marginBottom: 12,
  },
  photo: {
    marginRight: 16,
    width: 60,
    height: 60,
    borderRadius: '50%',
    border: 'none',
    fontSize: '28px!important',
  },
  nameAndPhotoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  adminMainInfo: {
    borderTop: 'none',
    paddingTop: 0,
  },
}));

import { VerificationRequestStatusesEnum } from 'types/talent';
import { Option, VisibilityOption } from 'components/BadgeSelect/types';

enum PROFILE_STATUS {
  verified = 'Verified',
  notVerified = 'Not verified',
  rejected = 'Declined',
  pending = ' Pending',
}

enum VisibilityStatus {
  visible = 'Visible',
  private = 'Private',
}

const PROFILE_OPTIONS: Option[] = [
  { label: PROFILE_STATUS.verified, color: 'green', value: VerificationRequestStatusesEnum.Verified },
  { label: PROFILE_STATUS.rejected, color: 'red', value: VerificationRequestStatusesEnum.Rejected },
  { label: PROFILE_STATUS.notVerified, color: 'gray', value: VerificationRequestStatusesEnum.NotVerified },
  { label: PROFILE_STATUS.pending, color: 'gray', value: VerificationRequestStatusesEnum.Pending },
];

const VisibilityOptions: VisibilityOption[] = [
  { label: VisibilityStatus.visible, color: 'green', value: false },
  { label: VisibilityStatus.private, color: 'gray', value: true },
];

export { PROFILE_STATUS, PROFILE_OPTIONS, VisibilityOptions };

import React, { useState } from 'react';
import { CardNumberElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/client';

import { intl } from 'helpers';
import { CREATE_PAYMENT } from 'services/graphql/payments';
import { GET_OPPORTUNITY } from 'services/graphql/query/opportunity';

import { useStyles } from './styles';
import { PaymentActions, PaymentForm } from './components/PaymentForm';
import { SuccessActions, SuccessBlock } from './components/PaymentSuccess';
import { Modal } from '../../Modal';
import { STATUSES } from '../../../constants';

export const PaymentModal = ({ isOpen, toggle, opportunity, amount, paymentPurpose, handleComplete = () => {} }) => {
  const classes = useStyles({});
  const elements = useElements();
  
  // TODO handle it
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [paymentError, setPaymentError] = useState(false);

  const { createPaymentMethod } = useStripe();

  const { values, handleChange, setFieldError } = useFormik({
    initialValues: {
      cardNumber: '',
      cvv: '',
      expDate: '',
    },
  });

  const [createTransaction, { data, loading, called, error: transactionError }] = useMutation(CREATE_PAYMENT, {
    onCompleted({ createPayment }) {
      if (createPayment?.next_action){
        if (createPayment.next_action?.type === 'redirect_to_url') {
          window.location.assign(createPayment.next_action.redirect_to_url.url);
        } else {
          window.location.assign(createPayment.next_action.use_stripe_sdk.stripeJs);
        }
      } else if (createPayment?.status === 'succeeded') {
        window.location.assign(`${window.location.origin}/brand/payment-history?payment_intent_client_secret=false`);
      }
    },
    refetchQueries: [{ query: GET_OPPORTUNITY, variables: { opportunityId: opportunity?.opportunityId } }],
    onError(err) {
      setPaymentError(err?.graphQLErrors?.[0]?.message);
    },
    awaitRefetchQueries: true,
  });

  const confirmedApplicant = opportunity?.applicants?.find((applicant) => applicant?.status === STATUSES.CONFIRMED.value);

  const createStripePaymentMethod = async () => {
    const cardElement = elements.getElement(CardNumberElement);
    setFieldError(null);
    setPaymentError(null);

    const { error, paymentMethod } = await createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      if (error.code === 'incomplete_number') {
        setFieldError('cardNumber', true);
      }
      if (error.code === 'incomplete_expiry') {
        setFieldError('expDate', true);
      }
      if (error.code === 'incomplete_cvc') {
        setFieldError('cvv', true);
      }
    } else {
      await createTransaction({
        variables: {
          data: {
            offerId: confirmedApplicant?.offer?.length ? confirmedApplicant?.offer[0]?.id : confirmedApplicant?.offer?.id,
            token: paymentMethod.id,
            paymentPurpose,
            redirectToUrl: `${window.location.origin}/brand/payment-history`,
          },
        },
      });
    }
  };

  // const resetErrors = (name) => () => {
  //   setFieldError(name, false)
  // }

  // TODO: define what after payment
  // const paymentWasSent = data?.createTransaction && called && !transactionError;

  const formActionProps = {
    loading,
    toggle,
    classes,
    handleSubmit: createStripePaymentMethod,
  };

  const formPaymentProps = {
    classes,
    opportunity,
    amount,
    values,
    handleChange,
    paymentError,
  };

  const successActionsProps = {
    toggle,
    classes,
    onComplete: () => {
      toggle();
      handleComplete();
    },
  };

  const successBlockProps = {
    classes,
  };

  return (
    <Modal
      isOpen={isOpen}
      className={classes.container}
      title={intl('PAYMENT_MODAL.payment')}
      handleClose={toggle}
      contentClassName={classes.content}
      dialogActionsComponent={<PaymentActions {...formActionProps} />}
      // dialogActionsComponent={paymentWasSent ? <SuccessActions {...successActionsProps} /> : <PaymentActions {...formActionProps} />}
    >
      <PaymentForm {...formPaymentProps} />
      {/* TODO: define what after payment */}
      {/*{paymentWasSent ? <SuccessBlock {...successBlockProps} /> : <PaymentForm {...formPaymentProps} />}*/}
    </Modal>
  );
};

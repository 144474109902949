import React from 'react';
import { Button, Paper, Box } from '@material-ui/core';
import clsx from 'clsx';

import { intl } from 'helpers';

import { useStyles } from './styles';

export const SaveBar = ({ isSignUp = false, onSave, onCancel, okText = '', disabled = false, sticky = false, loading = false, className = '' }) => {
  const styles = useStyles({ fromTalentPublic: sticky });
  return (
    <Paper
      className={clsx(styles.SaveBar, className, {
        [styles.stickyBar]: sticky,
      })}
    >
      <Box className={styles.SaveBarWrapper}>
        <Button onClick={onCancel} disabled={loading} className={styles.SaveBarButton} variant="outlined">
          {isSignUp ? intl('SAVE_BAR.skip') : intl('SAVE_BAR.cancel')}
        </Button>
        <Button onClick={onSave} className={styles.SaveBarButton} color="primary" disabled={disabled || loading} variant="contained">
          {okText ? intl(okText) : intl('SAVE_BAR.save')}
        </Button>
      </Box>
    </Paper>
  );
};

import React from 'react';
import { Container, Avatar, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { MAX_APPLICANT_AVATARS } from 'constants/index';
import { intl, getLetterForAvatar } from 'helpers';

import { useStyles } from './styles';

const ApplicantsAvatars = ({ applicants = [], isExpired = false }) => {
  const classes = useStyles();

  let items = [];
  const largeCount = applicants.length > MAX_APPLICANT_AVATARS;

  if (largeCount) {
    items = applicants.slice(0, MAX_APPLICANT_AVATARS);
  } else {
    items = applicants;
  }

  if (!applicants.length) {
    return (
      <Container className={clsx(classes.container, classes.smallPadding)}>
        <Typography className={classes.emptyText} variant="caption">
          {intl('AVATAR.noApplicants')}
        </Typography>
      </Container>
    );
  }

  if (isExpired) {
    if (!applicants.length) {
      return (
        <Container className={clsx(classes.container, classes.smallPadding)}>
          <Typography className={classes.emptyText} variant="caption">
            {intl('AVATAR.noApplicants')}
          </Typography>
        </Container>
      );
    }
    return <Avatar />;
  }

  return (
    <Container className={classes.container}>
      {items.map((applicant) => (
        <Avatar
          key={applicant.applicantId}
          className={classes.avatar}
          src={`${process.env.REACT_APP_AWSS3_URL_IMAGE_BEG}${applicant.image}`}
          alt={`${applicant.lastName}'s_avatar`}
        >
          {getLetterForAvatar(applicant.firstName, applicant.lastName)}
        </Avatar>
      ))}
      {largeCount && <Avatar className={classes.avatar}>+{applicants.length - MAX_APPLICANT_AVATARS}</Avatar>}
    </Container>
  );
};

export default ApplicantsAvatars;

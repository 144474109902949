import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/core/styles';
import { ApolloProvider } from '@apollo/client';
import { CssBaseline } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import * as firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import { Integrations } from '@sentry/tracing';
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';

import { amplifyConfig } from './config/amplify';
import { firebaseConfig } from './config/firebase';
import { AppSyncClientPrivate } from './config/appsync';

import { Routes } from './router/routes';
import { theme } from './theme';
import initLocale from './services/i18n';

import AuthProvider from './hooks/auth';
import { AppContextProvider } from './hooks/app';

const history = createBrowserHistory();

const { REACT_APP_SENTRY_API_KEY, REACT_APP_GOOGLE_ANALYTICS, REACT_APP_STRIPE_PK } = process.env;

Amplify.configure(amplifyConfig);
// * Analytics
hotjar.initialize(2008641, 6);
Sentry.init({
  dsn: REACT_APP_SENTRY_API_KEY,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});
ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS);

firebase.initializeApp(firebaseConfig);
firebase.analytics();

history.listen(({ pathname }) => {
  ReactGA.pageview(pathname);
});

initLocale().then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <ApolloProvider client={AppSyncClientPrivate}>
        <Elements stripe={loadStripe(REACT_APP_STRIPE_PK)}>
          <AuthProvider history={history}>
            <AppContextProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <Routes history={history} />
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            </AppContextProvider>
          </AuthProvider>
        </Elements>
      </ApolloProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
});

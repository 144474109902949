import intl from 'react-intl-universal'
import IntlPolyfill from 'intl'

import 'intl/locale-data/jsonp/en'
import en from './en.json'

window.Intl = IntlPolyfill

export const initLocale = (lang = 'en') => intl.init({
  escapeHtml: false,
  currentLocale: lang,
  locales: { en },
})

import { makeStyles } from '@material-ui/core';
import { colors } from '../../theme/colors';

export const useStyles = makeStyles((theme) => ({
  integrationBox: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    padding: '20px 32px 20px 24px',
    background: colors.WHITE,
    borderRadius: 8,
    [theme.breakpoints.only('xs')]: {
      padding: '17px 16px 10px 13px',
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  googleBox: {
    display: 'flex',
    alignItems: 'center',
  },
  googleBoxText: {
    marginLeft: theme.spacing(2),
    fontSize: '0.875rem',
  },
  googleBoxIconWrapper: {
    padding: theme.spacing(1),
    paddingBottom: 3,
    background: colors.BACKGROUND,
    borderRadius: 4,
  },
  errorText: {
    marginTop: theme.spacing(1),
    color: colors.ERROR,
  },
  btnConnect: {
    fontWeight: 500,
    lineHeight: '18px',
    color: colors.PRIMARY,
    letterSpacing: '0.03em',
    [theme.breakpoints.only('xs')]: {
      float: 'right',
    },
  },
}));

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  toolbar: {
    fontSize: 20,
    fontWeight: 500,
  },
  paper: {
    width: 'fit-content',
    minHeight: 439,
    minWidth: 297,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.only('xs')]: {
      margin: '0 auto',
    },
  },
}));

import React from 'react';
import { Paper, Divider, Box, Typography, Avatar } from '@material-ui/core';
import { LocationOn, InsertInvitation, Category } from '@material-ui/icons';
import { format } from 'date-fns';

import { intl, formatNumber, getCurrencySymbol } from 'helpers';

import { useStyles } from './styles';

const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env;

export const TalentOpportunityCard = ({ opportunity }) => {
  const styles = useStyles({});
  return (
    <Paper className={styles.container}>
      <Box className={styles.header}>
        <Typography className={styles.title}>{opportunity?.name}</Typography>
        <Typography className={styles.price}>
          {getCurrencySymbol(opportunity?.redemption?.currency?.code)} {formatNumber(opportunity?.redemption?.talentSalary)}
        </Typography>
      </Box>
      <Box className={styles.mainInfo}>
        <Box className={styles.infoItem}>
          <Category fontSize="small" />
          {opportunity?.type?.name}
        </Box>
        <Box className={styles.infoItem}>
          <LocationOn fontSize="small" /> {opportunity?.country?.name}, {opportunity?.city?.name}
        </Box>

        <Box className={styles.topRight}>
          <Typography className={styles.startDate}>
            <InsertInvitation fontSize="small" />
            {format(new Date(opportunity?.startDate), 'dd.MM.yyyy')}
          </Typography>
          <Typography className={styles.tax}>
            {opportunity?.redemption?.isTaxed ? intl('TALENT_OPPORTUNITIES_CARD.taxIncluded') : intl('TALENT_OPPORTUNITIES_CARD.taxExcluded')}
          </Typography>
        </Box>
      </Box>

      <Divider />
      <Box className={styles.bottom}>
        <Box className={styles.bottomLeft}>
          <Avatar src={`${REACT_APP_AWSS3_URL_IMAGE_BEG}${opportunity?.brand?.image}`}>{opportunity?.brand?.name?.[0]}</Avatar>
          <Typography className={styles.companyName}>{opportunity?.brand?.name}</Typography>
        </Box>
        <Typography className={styles.bottomRight}>
          {intl('TALENT_OPPORTUNITIES_CARD.posted')} {format(new Date(opportunity?.createdAt), 'dd.MM.yyyy')}
        </Typography>
      </Box>
    </Paper>
  );
};

export const TalentOpportunityCardMobile = ({ opportunity }) => {
  const styles = useStyles({});
  return (
    <Box className={styles.mobileContainer}>
      <Avatar className={styles.mobileAvatar} src={`${REACT_APP_AWSS3_URL_IMAGE_BEG}${opportunity?.brand?.image}`}>
        {opportunity?.brand?.name?.[0]}
      </Avatar>
      <Box>
        <Typography className={styles.mobileCompanyName}>{opportunity?.brand?.name}</Typography>
        <Typography className={styles.mobileTitle}>{opportunity?.name}</Typography>
        <Box>
          <Typography className={styles.mobilePrice}>
            {opportunity?.country?.name}, {opportunity?.city?.name}
          </Typography>
          <Typography className={styles.mobilePrice}>
            {getCurrencySymbol(opportunity?.redemption?.currency?.code)} {formatNumber(opportunity?.redemption?.talentSalary)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

import React, { useState, useCallback } from 'react';
import { object, string } from 'yup';
import clsx from 'clsx';
import { Box, Paper, TextField, Button, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import { useParams, useLocation } from 'react-router-dom';

import { useAuth } from 'hooks/auth';
import { intl } from 'helpers';
import { LoginIcon } from 'assets/svg-js';
import { TERMS_AND_CONDITIONS_URL } from 'constants/index';
import { TextFieldPassword } from 'components/TextFieldPassword';
import { SportEndorse } from 'components/SportEndorse';

import { useStyles } from './styles';
import ReactGA from 'react-ga';

const SignUpUserSchema = object({
  email: string().email('ERRORS.incorrectEmail').required('ERRORS.emptyField'),
  password: string()
    .matches(/((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,})/, 'ERRORS.incorrectPasswordFormat')
    .required('ERRORS.emptyField'),
});

export const SignUpUser = () => {
  const [isPasswordShown, setPasswordShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const { signUp, resendSignUp } = useAuth();
  const styles = useStyles({});
  const { role } = useParams();

  const { search } = useLocation();
  const [email, customInvitationId] = search
    .substr(1)
    .split('&')
    .map((el) => el.substr(el.indexOf('=') + 1));

  const togglePasswordShown = useCallback(() => setPasswordShown((passwordStatus) => !passwordStatus), []);

  const handleSignUp = async (values, formContext) => {
    const lowerCaseEmail = values.email.toLowerCase();
    setLoading(true);
    const { error } = await signUp({
      email: lowerCaseEmail,
      password: values.password,
      redirectUrl: `/confirm?email=${lowerCaseEmail}`,
      attributes: {
        'custom:role': role,
        'custom:invitation_id': customInvitationId,
      },
    });
    setLoading(false);
    if (!error) {
      ReactGA.event({
        category: 'User',
        action: `[${role.toUpperCase()}]: Not Full Sign Up`,
        label: `[${role.toUpperCase()}]: ${lowerCaseEmail.replace('@', '*')} - Not Full Sign Up`,
      });
    }
    if (error && error.name === 'UserNotFoundException') {
      formContext.setFieldError('email', 'ERRORS.emailIsNotFound');
    }
    if (error && error.name === 'UserNotConfirmedException') {
      await resendSignUp({
        username: lowerCaseEmail,
        redirectUrl: `/confirm?email=${lowerCaseEmail}`,
      });
    }
    if (error && error.name === 'NotAuthorizedException') {
      if (error.message === 'Password attempts exceeded') {
        // TODO: define logic
        // formContext.setFieldError('email', 'ERRORS.incorrectEmailOrPassword')
        // formContext.setFieldError('password', 'ERRORS.incorrectEmailOrPassword')
      }
    }
  };

  const { handleChange, values, errors, touched, handleSubmit } = useFormik({
    initialValues: {
      email,
      password: '',
    },
    validationSchema: SignUpUserSchema,
    onSubmit: handleSignUp,
  });

  return (
    <Box className={clsx(styles.flexCenter, styles.wrapper)}>
      <SportEndorse />
      <Paper className={styles.paper} component="form" onSubmit={handleSubmit}>
        <Box className={clsx(styles.flexCenter, styles.headerWrapper)}>
          <LoginIcon />
          <Typography className={styles.signupText} variant="h5">
            {intl('AUTH.signUp')}
          </Typography>
        </Box>
        <TextField
          name="email"
          label={intl('SHARED.email')}
          className={styles.input}
          value={values.email}
          onChange={handleChange}
          error={Boolean(errors.email)}
          helperText={intl(touched.email && errors.email)}
        />
        <TextFieldPassword
          togglePassword={togglePasswordShown}
          showPassword={isPasswordShown}
          label={intl('SHARED.createPassword')}
          name="password"
          value={values.password}
          onChange={handleChange}
          className={styles.input}
          error={errors.password && touched.password}
          helperText={intl(touched.password && errors.password)}
        />
        <Typography className={styles.terms}>
          {intl('SIGN_UP.termsAndConditionStart')}
          {/* eslint-disable-next-line */}
          <a href={TERMS_AND_CONDITIONS_URL} target="_blank" rel="noopener noreferrer">
            {intl('SIGN_UP.terms')}
          </a>{' '}
          {intl('SIGN_UP.termsAndConditionAnd')}
          <a href="https://sportendorse.com/privacy-policy" target="_blank" rel="noopener noreferrer">
            {intl('SIGN_UP.privacyPolicy')}
          </a>
          .
        </Typography>
        <Button type="submit" variant="contained" color="primary" disabled={loading}>
          {intl('AUTH.signUp')}
        </Button>
      </Paper>
    </Box>
  );
};

import React, { useState } from 'react';
import clsx from 'clsx';
import { Button, Box, AppBar, Divider, Typography } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { useApolloClient, useQuery, useMutation } from '@apollo/client/react/hooks';

import { Loader } from 'components/Loader';
import { AvatarEditable } from 'components/AvatarEditable';

import { useAuth } from 'hooks/auth';
import { routes } from 'router/routesList';
import { GET_OPPORTUNITY } from 'services/graphql/query/opportunity';
import { intl } from 'helpers';
// eslint-disable-next-line max-len
import ProposalModal from 'pages/Opportunity/SingleOpportunity/components/ProposalModal/ProposalModal';
import { DECLINE_OPPORTUNITY } from 'services/graphql/mutation/opportunity';
import { APPLICANT_STATUSES } from 'constants/index';
import { GET_CONVERSATION } from 'services/graphql/query/messanger';
import { GET_TALENT_BY_ID_FROM_BRAND } from 'services/graphql/talent';

import { useStyles } from './styles';

/* eslint no-nested-ternary: 0 */
// TODO: refactor it
export const ProfileCard = ({
  imgSrc,
  imgAlt,
  handleImageChange,
  error = false,
  isSignUp = false,
  avatarDisabled = false,
  className = {},
  fromBrand = false,
}) => {
  const styles = useStyles({});
  const history = useHistory();
  const client = useApolloClient();
  const { signOut, user, getRole } = useAuth();
  const { opportunityId, talentId } = useParams();

  const avatarError = error;

  const [proposalModalOpened, setProposalModal] = useState(false);

  const getOpportunityQuery = {
    query: GET_OPPORTUNITY,
    variables: {
      opportunityId,
    },
  };

  const { data, loading } = useQuery(GET_OPPORTUNITY, {
    variables: {
      opportunityId,
    },
    skip: !fromBrand,
  });

  const userRole = getRole();
  const isNotAgent = !(userRole === 'agent');
  const isAdmin = userRole === 'admin';

  const handleSignOut = () => {
    client.clearStore();
    signOut({ redirectUrl: `/${routes.LOGIN}` });
  };
  const handleHistoryPush = (link) => () => {
    history.push(link);
  };

  const getMutatedApplicants = (status, applicantsArr = []) => {
    const talent = applicantsArr.find((i) => i.id === talentId);
    const mutatedTalent = { ...talent, status };
    const filteredArr = applicantsArr.filter((i) => i.id !== talentId) || [];
    return [mutatedTalent, ...filteredArr];
  };

  const handleSetDecline = () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    declineApplicant({
      variables: {
        data: {
          opportunityId,
          talentId,
        },
      },
      update: (cache) => {
        const qr = cache.readQuery(getOpportunityQuery);
        const mutatedApplicants = getMutatedApplicants('Declined', qr.getOpportunity.applicants);
        cache.writeQuery({
          ...getOpportunityQuery,
          data: {
            getOpportunity: {
              ...qr.getOpportunity,
              applicants: mutatedApplicants,
            },
          },
        });
      },
    });
  };

  const toggleProposalModal = () => setProposalModal((modalOpened) => !modalOpened);

  const currenTalentToOpportunityStatus = data?.getOpportunity?.applicants?.find((app) => app.applicantId === talentId)?.status;

  const [currentPageIsPublic, currentPageIsPrivate, currentPageIsPaymentHistory, currentPageIsAnalytics, currentPageIsActivityFeed] = [
    [routes.BRAND_PUBLIC_PAGE, routes.TALENT_PUBLIC_PAGE, routes.TALENT_PUBLIC_INFO, routes.BRAND_COMPANY_INFO].some((route) =>
      history.location.pathname.includes(route),
    ),
    [routes.TALENT_PRIVATE_INFO, routes.BRAND_PROFILE_INFO].some((route) => history.location.pathname.includes(route)),
    history.location.pathname.includes(routes.PAYMENT_HISTORY),
    history.location.pathname.includes(routes.ANALYTICS),
    history.location.pathname.includes(routes.TALENT_ACTIVITY_FEED),
  ];

  const { data: opportunityData } = useQuery(GET_OPPORTUNITY, {
    variables: {
      opportunityId,
    },
    skip: !fromBrand,
  });

  const applicationId = opportunityData?.getOpportunity?.applicants?.find((applicant) => applicant?.applicantId === talentId)?.applicationId;

  const [declineApplicant, { loading: declineLoading }] = useMutation(DECLINE_OPPORTUNITY, {
    refetchQueries: [
      {
        query: GET_TALENT_BY_ID_FROM_BRAND,
        variables: {
          talentId,
        },
      },
      {
        query: GET_OPPORTUNITY,
        variables: {
          opportunityId,
        },
      },
      {
        query: GET_CONVERSATION,
        variables: {
          applicationId,
        },
      },
    ],
    awaitRefetchQueries: true,
  });
  const { data: conversationData } = useQuery(GET_CONVERSATION, {
    variables: {
      applicationId,
    },
    skip: !fromBrand || !applicationId,
  });

  const isOfferSended = !!conversationData?.getConversation?.messages.items.find((i) => i.action === 'GET_OFFER');

  const pendingApplicants = opportunityData?.getOpportunity?.applicants.filter(
    (i) => i.status === APPLICANT_STATUSES.PENDING.value || i.status === APPLICANT_STATUSES.CONFIRMED.value,
  );

  const shouldRenderActions =
    !isAdmin && conversationData?.getConversation && !isOfferSended && !!pendingApplicants.find((i) => i.applicantId === talentId);

  const proposalModalProps = {
    open: proposalModalOpened,
    handleClose: toggleProposalModal,
    currency: data?.getOpportunity?.redemption?.currency?.code,
    budget: data?.getOpportunity?.redemption?.budget,
    isTaxed: data?.getOpportunity?.redemption?.isTaxed,
    opportunityId,
    conversationId: conversationData?.getConversation.id,
    talentId,
    applicationId,
  };

  return (
    <AppBar position="sticky" color="transparent" component="header" className={clsx(styles.profileCard, className)}>
      <AvatarEditable error={avatarError} imgAlt={imgAlt} image={imgSrc} handleSuccess={handleImageChange} disabled={fromBrand || avatarDisabled} />
      {!isSignUp && !fromBrand && (
        <>
          <Box className={styles.profiles}>
            <Button
              className={styles.profileCardBtn}
              color={currentPageIsPublic ? 'primary' : 'default'}
              onClick={handleHistoryPush(user?.attributes['custom:role'] === 'brand' ? routes.BRAND_PUBLIC_PAGE : routes.TALENT_PUBLIC_PAGE)}
            >
              {user?.attributes['custom:role'] === 'brand' ? intl('BRAND.PROFILE_CARD.brandInfo') : intl('TALENT.PROFILE_CARD.publicInfo')}
            </Button>

            {isNotAgent && (
              <Button
                className={styles.profileCardBtn}
                color={currentPageIsPrivate ? 'primary' : 'default'}
                onClick={handleHistoryPush(
                  user?.attributes['custom:role'] === 'brand' ? `/${routes.BRAND_PROFILE_INFO}` : routes.TALENT_PRIVATE_INFO,
                )}
              >
                {user?.attributes['custom:role'] === 'brand' ? intl('BRAND.PROFILE_CARD.profileInfo') : intl('TALENT.PROFILE_CARD.privateInfo')}
              </Button>
            )}

            {/* <Button
              className={styles.profileCardBtn}
              color={currentPageIsActivityFeed ? 'primary' : 'default'}
              onClick={handleHistoryPush(user?.attributes['custom:role'] === 'brand' ? routes.BRAND_ANALYTICS : routes.TALENT_ACTIVITY_FEED)}
            >
              {intl('SHARED.activityFeed')}
            </Button> */}
            <Button
              className={styles.profileCardBtn}
              color={currentPageIsPaymentHistory ? 'primary' : 'default'}
              onClick={handleHistoryPush(user?.attributes['custom:role'] === 'brand' ? routes.BRAND_PAYMENT_HISTORY : routes.TALENT_PAYMENT_HISTORY)}
            >
              {intl('TALENT.PROFILE_CARD.paymentHistory')}
            </Button>

            <Button
              className={styles.profileCardBtn}
              color={currentPageIsAnalytics ? 'primary' : 'default'}
              onClick={handleHistoryPush(user?.attributes['custom:role'] === 'brand' ? routes.BRAND_ANALYTICS : routes.TALENT_ANALYTICS)}
            >
              {intl('SHARED.analytics')}
            </Button>
          </Box>

          <Box className={styles.logout}>
            <Button onClick={handleSignOut} className={styles.profileCardBtn}>
              {intl('SHARED.logout')}
            </Button>
          </Box>
        </>
      )}

      {fromBrand ? (
        loading ? (
          <Box className={styles.loaderContainer}>
            <Loader />
          </Box>
        ) : (
          <Box>
            <Divider />
            <Typography className={styles.appliedFor}>
              {currenTalentToOpportunityStatus === 'Pending' && intl('TALENT.PROFILE_CARD.appliedFor')}
              {currenTalentToOpportunityStatus === 'Declined' && intl('TALENT.PROFILE_CARD.declinedFor')}
              {currenTalentToOpportunityStatus === 'Completed' && intl('TALENT.PROFILE_CARD.acceptedFor')}
            </Typography>
            <Typography className={styles.opName}>{data?.getOpportunity?.name}</Typography>
            {shouldRenderActions && (
              <>
                <Button onClick={handleSetDecline} className={styles.btn} fullWidth disabled={declineLoading} variant="outlined">
                  {intl('SHARED.decline')}
                </Button>
                <Button onClick={toggleProposalModal} className={styles.btn} fullWidth variant="contained" disabled={declineLoading} color="primary">
                  {intl('SHARED.accept')}
                </Button>
              </>
            )}
          </Box>
        )
      ) : null}

      {fromBrand && <ProposalModal {...proposalModalProps} />}
    </AppBar>
  );
};

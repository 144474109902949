import { gql } from '@apollo/client';

const LIST_NEWS_FEED = gql`
  query listNewsFeed($filter: NewsFeedFilter, $pagination: PaginationFilter) {
    listNewsFeed(filter: $filter, pagination: $pagination) {
      items {
        ... on PostFeed {
          description
          feedMeta {
            likesCounter
            sharesCounter
          }
          images
          id
          createdAt
          canInteract
          isLiked
          author {
            authorId
            authorType
            authorName
            imageSrc
            firstLetters
          }
        }
        ... on OpportunityFeed {
          id
          name
          brand {
            id
            image
            name
          }
          city {
            name
            id
          }
          country {
            id
            name
          }
          createdAt
          description
          endDate
          feedMeta {
            likesCounter
            sharesCounter
          }
          images
          isLiked
          canInteract
          redemption {
            budget
            currency {
              code
              id
            }
            finalPrice
            isTaxed
            talentSalary
          }
          startDate
          type {
            id
            name
          }
        }
      }
      meta {
        total
      }
    }
  }
`;

const GET_POST = gql`
  query getPost($id: ID!) {
    getPost(id: $id) {
      id
      description
      images
      author {
        authorId
        authorType
        authorName
        imageSrc
        firstLetters
      }
      canInteract
      createdAt
      feedMeta {
        likesCounter
        sharesCounter
      }
      isLiked
    }
  }
`;

export { LIST_NEWS_FEED, GET_POST };

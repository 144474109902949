import React, { useEffect } from 'react';
import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import { object, string } from 'yup';

import { intl } from 'helpers';

import { Modal } from 'components/Modal';

import { useStyles } from './styles';

const InvitationSchema = object({
  email: string().email('ERRORS.incorrectEmail').required('ERRORS.emptyField'),
});

export const InvitationModal = ({ isOpen, handleClose, title, bodyText, label, submit, responseError, loading }) => {
  const styles = useStyles();

  const { errors, handleChange, handleSubmit, values, resetForm } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: InvitationSchema,
    onSubmit: submit,
  });

  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);

  const renderButtons = () => (
    <Grid>
      <Button className={styles.btn} variant="outlined" onClick={handleClose} disabled={loading}>
        {intl('INVITATION_MODAL.cancel')}
      </Button>
      <Button className={styles.btn} color="primary" variant="contained" onClick={handleSubmit} disabled={loading}>
        {intl('INVITATION_MODAL.invite')}
      </Button>
    </Grid>
  );

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} title={title} className={styles.modalContainer} dialogActionsComponent={renderButtons()}>
      <Typography className={styles.formHeader}>{bodyText}</Typography>
      <Box className={styles.formContainer}>
        <TextField
          name="email"
          error={!!errors.email}
          helperText={intl(errors.email)}
          value={values.email}
          fullWidth
          onChange={handleChange}
          label={label}
        />
      </Box>
      <Box>
        {responseError && <Typography className={styles.smallError}>{responseError}</Typography>}
      </Box>
    </Modal>
  );
};

const OPEN = {
  label: 'OPPORTUNITIES.open',
  value: 0,
  gqlStatus: 'open',
  talentStatus: 'open',
}

const CONFIRMED = {
  label: 'OPPORTUNITIES.confirmed',
  value: 1,
  gqlStatus: 'confirmed',
  talentStatus: 'confirmed',
}

const COMPLETE = {
  label: 'OPPORTUNITIES.complete',
  value: 2,
  gqlStatus: 'closed',
  talentStatus: 'completed',
}

const TABS = { OPEN, CONFIRMED, COMPLETE }

export { TABS }

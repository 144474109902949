import React, { FC } from 'react';
import { Box, Typography, Button } from '@material-ui/core';

import { intl } from 'helpers';
import { QuestionMark as QuestionMarkIcon } from 'assets/svg-js/index';

import { Modal } from 'components/Modal';

import { useStyles } from './styles';
import type { Props } from './types';

const RemoveAgentModal: FC<Props> = ({ open, loading, handleClose, handleConfirm }) => {
  const styles = useStyles();
  const Actions = () => (
    <>
      <Button onClick={handleClose} className={styles.btnNo} fullWidth disabled={loading}>
        {intl('SHARED.no')}
      </Button>
      <Button onClick={handleConfirm} className={styles.btnYes} fullWidth disabled={loading}>
        {intl('SHARED.yes')}
      </Button>
    </>
  );

  return (
    <Modal isOpen={open} handleClose={handleClose} title={null} disabledBorder className={styles.modalBox} dialogActionsComponent={<Actions />}>
      <Box className={styles.modalContentBox}>
        <Box className={styles.modalIconBox}>
          <QuestionMarkIcon />
        </Box>
        <Typography className={styles.modalText}>{intl('REMOVE_AGENT_MODAL.text')}</Typography>
      </Box>
    </Modal>
  );
};

export { RemoveAgentModal };

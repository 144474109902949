import { makeStyles } from '@material-ui/core'
import { colors } from '../../theme/colors'

export const useStyles = makeStyles(() => ({
  iconContainer: {
    minWidth: 32,
    height: 32,
    borderRadius: 4,
    background: colors.BACKGROUND,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

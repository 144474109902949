import { makeStyles } from '@material-ui/core'
import { colors } from '../../../../../../../theme/colors'

export const useStyles = makeStyles((theme) => ({
  box: {
    paddingTop: theme.spacing(2.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1.5),
  },
  flexBox: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(1.5),
  },
  brandName: {
    marginTop: theme.spacing(1.5),
    paddingBottom: 29,
  },
  brandBox: {
    marginLeft: 5,
  },
  avatar: {
    width: 40,
    height: 40,
    marginRight: theme.spacing(2),
  },
  icon: {
    fill: colors.PRIMARY_INPUT_HOVERED,
    marginRight: 14,
  },
  aboutTitle: {
    marginTop: theme.spacing(2),
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  description: {
    maxHeight: 235,
    overflow: 'auto',
  },
}))

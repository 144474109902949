import React from 'react'
import { Typography, Box } from '@material-ui/core'

const TabPanel = ({ children, value, index, ...other }) => (
  <Typography
    component="section"
    role="tabpanel"
    hidden={value !== index}
    id={`brand-opportunities-tabpanel-${index}`}
    aria-labelledby={`brand-opportunities-tab-${index}`}
    {...other}
  >
    <Box>{children}</Box>
  </Typography>
)

export const a11yProps = (index) => ({
  id: `brand-opportunities-tab-${index}`,
  'aria-controls': `brand-opportunities-tabpanel-${index}`,
})

export default TabPanel

import React, { FC, useState, MouseEvent, useEffect } from 'react';
import { Container, Box, Avatar, Typography, IconButton, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';
import { Group as GroupIcon, Face as FaceIcon, Room as RoomIcon, EmojiEvents as EmojiEventsIcon, MoreVert as MoreVertIcon } from '@material-ui/icons';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';

import InstagramIcon from 'assets/svg-js/InstagramIcon';
import FacebookIcon from 'assets/svg-js/FacebookIcon';
import TwitterIcon from 'assets/svg-js/TwitterIcon';

import { useLink } from 'hooks/linkTo';
import { clipName, intl, getLetterForAvatar, getSocialCount, getFormattedCount } from 'helpers';
import { routes } from 'router/routesList';
import type { Applicant } from 'types/opportunity';

import { useStyles } from './styles';
import type { Props } from './types';

const TalentCard: FC<Props> = ({ data, applicant, handleAddToShortlist, handleFavorite, className, children, isAdmin }) => {
  const styles = useStyles();
  const linkTo = useLink();
  const { id: opId } = useParams();
  const { firstName, lastName, sport, club, gender, country, city, image, id, social } = data;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // const [instagramFollowersCount, setInstagramFollowersCount] = useState<null | number>(null);

  const open = Boolean(anchorEl);
  const talentAdminsView = `/${routes.ADMIN}/${routes.TALENTS}/${id}`;
  const talentBrandsView = `/${routes.BRAND}/${routes.TALENTS}/${id}/${routes.OPPORTUNITIES}/${opId}`;
  const talentProfileRoute = isAdmin ? talentAdminsView : talentBrandsView;

  const handleClick = ({ currentTarget }: MouseEvent<HTMLElement>) => setAnchorEl(currentTarget);
  const handleClose = () => setAnchorEl(null);
  const addToShortlist = (currentApplicant: Applicant) => {
    if (!currentApplicant) return;

    const { applicationId, isShortlisted } = currentApplicant;

    handleAddToShortlist(applicationId, !isShortlisted);
    handleClose();
  };

  const toggleFavorite = (currentApplicant: Applicant) => {
    if (!currentApplicant) return;

    const { applicantId } = currentApplicant;

    handleFavorite(applicantId);
    handleClose();
  };

  // useEffect(() => {
  //   if (social) {
  //     const instagram = social.find((item) => item.providerName === 'instagram');
  //     if (instagram?.accountName) {
  //       const igProfileUri = `https://www.instagram.com/${instagram.accountName}/?__a=1`;
  //       fetch(igProfileUri)
  //         .then((res) => res.json())
  //         .then((profile) => {
  //           setInstagramFollowersCount(profile?.graphql?.user?.edge_followed_by?.count || null);
  //         })
  //         // eslint-disable-next-line no-console
  //         .catch((err) => console.error('fetchInstagramFollowers', err));
  //     }
  //   }
  // }, [data?.social]);

  return (
    <Container className={clsx(styles.container, className)}>
      <Box>
        <Box className={styles.heading}>
          <Box className={styles.headerBox}>
            <Avatar src={`${process.env.REACT_APP_AWSS3_URL_IMAGE_BEG}${image}`} alt={`${lastName}'s_avatar`} className={styles.avatar}>
              {getLetterForAvatar(firstName, lastName)}
            </Avatar>
            <Typography variant="h6" className={styles.name}>
              {clipName(firstName, lastName)}
            </Typography>
          </Box>
          <>
            <IconButton onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MenuItem onClick={linkTo(talentProfileRoute)}>{intl('SHARED.viewProfile')}</MenuItem>
              {applicant && (
                <MenuItem onClick={() => addToShortlist(applicant)}>
                  {applicant.isShortlisted ? intl('TALENT_CARD.removeFormShortlist') : intl('TALENT_CARD.addToShortlist')}
                </MenuItem>
              )}
              {applicant && (
                <MenuItem onClick={() => toggleFavorite(applicant)}>
                  {applicant.isFavourite ? intl('TALENT_CARD.removeFormFavorite') : intl('TALENT_CARD.addToFavorite')}
                </MenuItem>
              )}
            </Menu>
          </>
        </Box>
        <Box className={styles.content}>
          {sport.name && (
            <Box className={styles.section}>
              <EmojiEventsIcon fontSize="small" className={styles.icon} />
              <Box className={styles.infoItems}>
                <Typography variant="body2">{sport.name}</Typography>
              </Box>
            </Box>
          )}
          {club?.length > 0 && (
            <Box className={styles.section}>
              <GroupIcon fontSize="small" className={styles.icon} />
              <Box className={styles.infoItems}>
                {club?.map((c) => (
                  <Typography variant="body2" key={c}>
                    {c}
                  </Typography>
                ))}
              </Box>
            </Box>
          )}
          <Box className={clsx(styles.section, styles.competingBox)}>
            {gender && (
              <>
                <FaceIcon fontSize="small" className={styles.icon} />
                <Typography variant="body2">{gender}</Typography>
              </>
            )}
          </Box>
          {city.name && (
            <Box className={clsx(styles.section, styles.competingBox)}>
              <RoomIcon fontSize="small" className={styles.icon} />
              <Typography variant="body2">{`${city.name}, ${country.name}`}</Typography>
            </Box>
          )}
        </Box>
        <List className={styles.socials}>
          {getSocialCount(social, 'instagram') !== null && (
            <ListItem className={styles.socialsItem}>
              <ListItemIcon className={styles.svgIconBox}>
                <InstagramIcon className={styles.svgIcon} />
              </ListItemIcon>
              <ListItemText className={styles.socialsItemText}>{getFormattedCount(getSocialCount(social, 'instagram'))}</ListItemText>
            </ListItem>
          )}

          {getSocialCount(social, 'facebook') !== null && (
            <ListItem className={styles.socialsItem}>
              <ListItemIcon className={styles.svgIconBox}>
                <FacebookIcon className={styles.svgIcon} />
              </ListItemIcon>
              <ListItemText className={styles.socialsItemText}>{getFormattedCount(getSocialCount(social, 'facebook'))}</ListItemText>
            </ListItem>
          )}
          {getSocialCount(social, 'twitter') !== null && (
            <ListItem className={styles.socialsItem}>
              <ListItemIcon className={styles.svgIconBox}>
                <TwitterIcon className={styles.svgIcon} />
              </ListItemIcon>
              <ListItemText className={styles.socialsItemText}>{getFormattedCount(getSocialCount(social, 'twitter'))}</ListItemText>
            </ListItem>
          )}
        </List>
      </Box>
      {children}
    </Container>
  );
};

export default TalentCard;

import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { Box } from '@material-ui/core';
import { Switch, Route } from 'react-router-dom';

import { AppSyncClientPublic } from 'config/appsync';

import { HeaderUnregistered } from 'components/HeaderUnregistered';
import { Footer } from 'components/Footer';
import { IntroModal } from 'components/Modals/IntroModal/IntroModal';
import { useIntroModal } from 'components/Modals/IntroModal/useIntroModal';
import { Post } from 'pages/Unregistered/Post';
import { NewsFeed } from 'pages/Unregistered/NewsFeed';

import { useStyles } from './styles';
import { routes } from './routesList';

const Unregistered = () => {
  const styles = useStyles();
  const { isShowModal, videoLink, handleOpenModal, handleCloseModal } = useIntroModal();

  return (
    <ApolloProvider client={AppSyncClientPublic}>
      <Box className={styles.app} id="app">
        <HeaderUnregistered />
        <Box className={styles.unregistered}>
          <Switch>
            <Route path={`/${routes.NEWS_FEED}`} exact>
              <NewsFeed />
            </Route>
            <Route path={`/${routes.NEWS_FEED}/${routes.POST}/:id`}>
              <Post />
            </Route>
          </Switch>
        </Box>
        <Footer handleOpenModal={handleOpenModal} />
        {isShowModal && <IntroModal isOpen={isShowModal} handleClose={handleCloseModal} src={videoLink} />}
      </Box>
    </ApolloProvider>
  );
};

export { Unregistered };

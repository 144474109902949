import { ApolloCache, useApolloClient } from '@apollo/client';
import { cloneDeep } from 'lodash';

import { LIST_NEWS_FEED } from 'services/graphql/query/feed';
import type { ActivityPost } from 'types/post';

const useNewsFeed = ({ collection, limit }) => {
  const client = useApolloClient();

  const handleSuccessLike = (cache: ApolloCache<any>, entityId: string) => {
    const { listNewsFeed } = cache.readQuery({
      query: LIST_NEWS_FEED,
      variables: {
        filter: {
          collection,
        },
        pagination: {
          limit,
        },
      },
    });
    const bufferList: Array<ActivityPost> = cloneDeep(listNewsFeed.items);
    const entity: ActivityPost = bufferList.find(({ id }) => id === entityId);
    entity.isLiked = !entity.isLiked;
    entity.feedMeta.likesCounter = entity.isLiked ? entity.feedMeta.likesCounter + 1 : entity.feedMeta.likesCounter - 1;
    const newList = bufferList.map((i: ActivityPost) => (i.id === entityId ? entity : i));
    cache.writeQuery({
      query: LIST_NEWS_FEED,
      variables: {
        filter: {
          collection,
        },
        pagination: {
          limit,
        },
      },
      data: {
        listNewsFeed: {
          ...listNewsFeed,
          items: newList,
        },
      },
    });
  };

  const handleSuccessDelete = (entityId: string) => {
    const { cache } = client;
    const { listNewsFeed } = cache.readQuery({
      query: LIST_NEWS_FEED,
      variables: {
        filter: {
          collection,
        },
        pagination: {
          limit,
        },
      },
    });
    const bufferList: Array<ActivityPost> = cloneDeep(listNewsFeed.items);
    const newList: Array<ActivityPost> = bufferList.filter(({ id }) => id !== entityId);
    cache.writeQuery({
      query: LIST_NEWS_FEED,
      variables: {
        filter: {
          collection,
        },
        pagination: {
          limit,
        },
      },
      data: {
        listNewsFeed: {
          ...listNewsFeed,
          items: newList,
        },
      },
    });
  };

  const handleSuccessShare = (entityId: string) => {
    const { cache } = client;
    const { listNewsFeed } = cache.readQuery({
      query: LIST_NEWS_FEED,
      variables: {
        filter: {
          collection,
        },
        pagination: {
          limit,
        },
      },
    });
    const bufferList: Array<ActivityPost> = cloneDeep(listNewsFeed.items);
    const entity: ActivityPost = bufferList.find(({ id }) => id === entityId);
    entity.feedMeta.sharesCounter += 1;
    const newList = bufferList.map((i: ActivityPost) => (i.id === entityId ? entity : i));
    cache.writeQuery({
      query: LIST_NEWS_FEED,
      variables: {
        filter: {
          collection,
        },
        pagination: {
          limit,
        },
      },
      data: {
        listNewsFeed: {
          ...listNewsFeed,
          items: newList,
        },
      },
    });
  };

  return {
    handleSuccessLike,
    handleSuccessDelete,
    handleSuccessShare,
  };
};

export { useNewsFeed };

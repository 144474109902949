import { makeStyles } from '@material-ui/core'
import { colors } from '../../../../../theme/colors'

export const useStyles = makeStyles((theme) => ({
  modal: {
    width: 384,
  },
  container: {
    marginTop: -theme.spacing(4),
    marginBottom: -theme.spacing(4),
  },
  text: {
    marginTop: theme.spacing(4),
    fontSize: 18,
  },
  iconBox: {
    margin: '0 auto',
    padding: '25px 25px 18px 25px',
    width: 'min-content',
    background: colors.BACKGROUND,
    borderRadius: '100%',
  },
}))

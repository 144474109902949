import { makeStyles } from '@material-ui/core';
import { colors } from 'theme/colors';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    flexDirection: 'column',
  },
  companyInfo: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  companyInfoFormAndNav: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
    marginLeft: 24,
    '@media (max-width: 768px)': {
      marginLeft: 0,
    },
  },
  companyInfoForm: {
    padding: 30,
    paddingBottom: 44,
    minHeight: 275,
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  industryContainer: {
    width: 312,
    marginBottom: theme.spacing(3),
  },
  select: {
    height: 32,
  },
  input: {
    marginBottom: 28,
    maxWidth: 312,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
    '&:last-child': {
      width: '100%',
      marginBottom: 0,
      maxWidth: '100%',
    },
  },
  errorText: {
    color: colors.ERROR,
  },
  // inputInvoice: {
  //   marginBottom: 28,
  //   maxWidth: 312,
  //   marginRight: 32,

  //   '&:nth-child(2)': {
  //     marginRight: 0,
  //   },
  //   [theme.breakpoints.down('sm')]: {
  //     maxWidth: '100%',
  //   },
  // },
  avatarEditable: {
    width: '50%',
  },
  paymentInfoBlock: {
    marginTop: theme.spacing(2),
    marginBottom: 'auto',

    [theme.breakpoints.down('xs')]: {
      marginBottom: 0,
    },
  },
  infoBlockHeader: {
    fontWeight: 600,
    marginBottom: theme.spacing(3),
    textTransform: 'uppercase',
  },
  saveBar: {
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
      width: 'calc(100% - 302px)',
      alignSelf: 'flex-end',
    },
    [theme.breakpoints.only('sm')]: {
      width: '100%',
      alignSelf: 'flex-end',
    },
  },
}));

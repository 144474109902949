import { makeStyles } from '@material-ui/core';
import { colors } from 'theme/colors';

export const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  avatar: {
    width: 60,
    height: 60,
    marginRight: theme.spacing(2),
  },
  name: {
    lineHeight: '30px',
    fontWeight: 600,
    flexGrow: 1,
  },
  budgetBox: {
    display: 'flex',
    alignItems: 'baseline',
    whiteSpace: 'pre',
  },
  btnViewProfile: {
    marginTop: theme.spacing(3),
    paddingTop: 7,
    paddingBottom: 7,
    border: '1px solid #e5e5e5',
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: '0.03em',
    [theme.breakpoints.down(1024)]: {
      position: 'absolute',
      top: 3,
      right: 16,
      width: 'auto',
      padding: '6px 41px',
    },
    [theme.breakpoints.down(580)]: {
      position: 'static',
      width: '100%',
      marginTop: theme.spacing(2),
    },
  },
  budget: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    fontWeight: 600,
    lineHeight: '36px',
  },
  description: {
    marginTop: theme.spacing(1),
    maxHeight: 240,
    overflow: 'auto',
  },
  btnPay: {
    paddingTop: 7,
    paddingBottom: 7,
    background: colors.PRIMARY,
    color: colors.WHITE,
    fontSize: 12,
    lineHeight: '18px',
    letterSpacing: '0.03em',
    '&:hover': {
      background: colors.PRIMARY_HOVERED,
    },
  },
  btnRenew: {
    border: `1px solid ${colors.BORDER3}`,
  },
  talentsBtn: {
    height: 32,
    textTransform: 'uppercase',
  },
  viewProfileDesktop: {
    [theme.breakpoints.down(1024)]: {
      display: 'none',
    },
    [theme.breakpoints.down(600)]: {
      display: 'block',
    },
  },

  viewProfileTablet: {
    display: 'none',
    [theme.breakpoints.between(600, 1024)]: {
      display: 'block',
      width: 200,
    },
  },
}));

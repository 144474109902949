import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { useStyles } from './styles'
import { intl } from '../../../../../helpers'

const BaseTable = ({ thCells, children }) => {
  const classes = useStyles()

  return (
    <Paper className={classes.root} elevation={0}>
      <Table className={classes.table} aria-label="brand-opportunities-table">
        <TableHead>
          <TableRow>
            {thCells.map((cell) => (
              <TableCell
                className={classes.thCell}
                key={cell.name}
                align={cell?.align || 'inherit'}
              >
                {cell.name === intl('OPPORTUNITIES.empty') ? '' : intl(cell.name)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </Paper>
  )
}

export default BaseTable

import React, { createContext, useState, useEffect, useMemo, useContext } from 'react'
import { Auth } from 'aws-amplify'
import { routes } from '../../router/routesList'

const AuthContext = createContext(null)

export const AuthProvider = ({ children, history }) => {
  const [user, setUser] = useState(null)
  const [userLoading, setUserLoading] = useState(true)
  const [tempUser, setTempUser] = useState(null)

  const redirectTo = (url) => {
    if (!url) {
      return
    }
    history.push(url)
  }

  const signIn = async ({ email, password }) => {
    try {
      const currentUser = await Auth.signIn(email, password)
      setUser(currentUser)
      const rolePath = currentUser?.attributes?.['custom:role'] === 'agent' ? 'talent' : currentUser?.attributes?.['custom:role']
      redirectTo(`/${rolePath}/${routes.OPPORTUNITIES}`)

      return { user: currentUser }
    } catch (error) {
      return { error }
    }
  }

  const signUp = async ({ email, password, attributes = {}, redirectUrl }) => {
    try {
      const currentUser = await Auth.signUp({
        username: email,
        password,
        attributes,
      })
      setTempUser({ username: email, password, attributes })
      redirectTo(redirectUrl)

      return { user: currentUser }
    } catch (error) {
      return { error }
    }
  }

  const confirmSignUp = async ({ username, code, redirectUrl }) => {
    try {
      const currentUser = await Auth.confirmSignUp(username, code)

      await signIn({ email: tempUser.username, password: tempUser.password, redirectUrl })

      setTempUser(null)

      return { user: currentUser }
    } catch (error) {
      return { error }
    }
  }

  const resendSignUp = async ({ username, redirectUrl }) => {
    try {
      const currentUser = await Auth.resendSignUp(username)

      // setTempUser({ username })
      redirectTo(redirectUrl)

      return { user: currentUser }
    } catch (error) {
      console.error('--error--', error)
      return { error }
    }
  }

  const forgotPassword = async ({ email, redirectUrl }) => {
    try {
      const { CodeDeliveryDetails: details } = await Auth.forgotPassword(email)

      setTempUser({ username: email })

      redirectTo(redirectUrl)

      return details
    } catch (error) {
      return { error }
    }
  }

  const forgotPasswordSubmit = async ({ code, password, email, redirectUrl }) => {
    try {
      await Auth.forgotPasswordSubmit(email, code, password)

      await signIn({ email, password, redirectUrl })

      setTempUser(null)

      return true
    } catch (error) {
      return { error }
    }
  }

  const signOut = async ({ redirectUrl }) => {
    try {
      await Auth.signOut()

      setUser(null)
      redirectTo(redirectUrl)

      return true
    } catch (error) {
      return { error }
    }
  }

  const isAuthenticated = () => {
    return !!user;

  }

  const getRole = () => {
    if (user?.attributes?.['custom:role'] === 'brand') {
      return 'brand'
    }
    if (user?.attributes?.['custom:role'] === 'talent') {
      return 'talent'
    }
    if (user?.attributes?.['custom:role'] === 'agent') {
      return 'agent'
    }
    if (user?.attributes?.['custom:role'] === 'admin') {
      return 'admin'
    }
    return null
  }

  const getAssumedRole = () => {
    const role = getRole();
    if (['agent', 'talent'].includes(role)) {
      return 'talent';
    }
    if (['brand'].includes(role)) {
      return 'brand';
    }
    if (['admin'].includes(role)) {
      return 'admin';
    }
    return null;
  };

  useEffect(() => {
    const initUser = async () => {
      try {
        setUserLoading(true)
        const currentUser = await Auth.currentAuthenticatedUser()
        setUser(currentUser)
        setUserLoading(false)
      } catch (err) {
        setUser(null)
        setUserLoading(false)
        if ([routes.ADMIN, routes.BRAND, routes.TALENT].includes(history?.location?.pathname)) {
          redirectTo(`/${routes.LOGIN}`)
        }
      }
    }
    initUser()
  }, [])

  const values = useMemo(
    () => ({
      user,
      userLoading,
      tempUser,
      signIn,
      signUp,
      confirmSignUp,
      resendSignUp,
      forgotPassword,
      isAuthenticated,
      forgotPasswordSubmit,
      signOut,
      getRole,
      getAssumedRole,
    }),
    [user, tempUser, isAuthenticated, userLoading],
  )

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  const context = useContext(AuthContext)

  if (context === undefined) {
    throw new Error('`useAuth` hook must be used within a `AuthProvider` component')
  }
  return context
}

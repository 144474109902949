import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client/react/hooks';
import { useFormik } from 'formik';
import { mixed, object, string } from 'yup';
import isEmpty from 'lodash/isEmpty';
import flow from 'lodash/flow';
import lodashValues from 'lodash/values';
import compact from 'lodash/compact';

import { useLink } from 'hooks/linkTo';
import { ADMIN_UPDATE_BRAND_PROFILE, UPDATE_VERIFICATION_STATUS, UpdateVerificationStatusVars } from 'services/graphql/admin';
import { GET_BRAND_BY_ID } from 'services/graphql/brand';
import { PROFILE_OPTIONS } from 'constants/index';
import { routes } from 'router/routesList';
import { LIST_INDUSTRIES } from 'services/graphql/industry';
import { LIST_CITIES, LIST_COUNTRIES } from 'services/graphql/location';
import { Option } from 'components/BadgeSelect/types';
import { UserTypeEnum } from 'types/common';
import { BaseEntity } from 'types/brand';
import type { GetBrandById } from 'types/brand';
import { TabsEnum } from './tabs';

const getTrimmedValue = (value) => (value ? value.trim() : '');

const getBrandVariables = (brandData, option) => {
  return {
    variables: {
      brandId: brandData.id,
      data: {
        companyName: getTrimmedValue(brandData.companyName),
        about: brandData.about,
        cityId: brandData.city.id,
        industryId: brandData.industry.id,
        image: brandData.uploadImage,
        privateInfo: {
          street: getTrimmedValue(brandData.privateInfo?.street),
          postalCode: getTrimmedValue(brandData.privateInfo?.postalCode),
          VATnumber: getTrimmedValue(brandData.privateInfo?.VATnumber),
          IBAN: getTrimmedValue(brandData.privateInfo?.IBAN),
          bankAccount: getTrimmedValue(brandData.privateInfo?.bankAccount),
        },
        isPrivate: option ?? brandData.isPrivate,
      },
    },
  };
};

export const useBrand = () => {
  const { id, tab } = useParams();
  const [currentTab, setCurrentTab] = useState<TabsEnum>(tab);
  const linkTo = useLink();
  const handleTabChange = (_: any, newValue: TabsEnum) => setCurrentTab(newValue);

  const [updateProfileStatus] = useMutation<String, UpdateVerificationStatusVars>(UPDATE_VERIFICATION_STATUS);

  const handleUserStatusChange = (option: Option) => {
    updateProfileStatus({
      variables: {
        status: option.value,
        userId: id,
        userType: UserTypeEnum.brand,
      },
    });
  };

  const { data = { getBrand: {} as GetBrandById }, loading, refetch } = useQuery(GET_BRAND_BY_ID, {
    variables: {
      brandId: id,
    },
    skip: !id,
    fetchPolicy: 'no-cache',
  });

  const refetchBrandData = () => {
    refetch({
      brandId: id,
    });
  };

  const { getBrand = {} as GetBrandById } = data;

  const verificationStatus = getBrand?.verificationStatus ? getBrand?.verificationStatus : PROFILE_OPTIONS[1].value;
  const visibilityStatus = getBrand?.isPrivate;

  // TODO: refactor to TabPanel
  useEffect(() => {
    linkTo(`/${routes.ADMIN}/${routes.BRANDS}/${id}/${currentTab}`)();
  }, [currentTab]);

  const [updateBrandProfile] = useMutation(ADMIN_UPDATE_BRAND_PROFILE);

  const { values, setValues } = useFormik({
    initialValues: {
      companyName: '',
      about: '',
      cityId: undefined,
      industryId: { __typename: 'Industry', id: null, name: null },
      image: '',
      privateInfo: {
        street: '',
        postalCode: '',
        VATnumber: '',
        IBAN: '',
        bankAccount: '',
      },
    },
    validationSchema: null,
    validateOnChange: false,
    onSubmit: () => {},
  });

  const populateBrandsDataToForm = (brandInfo) => {
    setValues({
      companyName: brandInfo.companyName,
      about: brandInfo.about,
      cityId: brandInfo?.city?.id,
      industryId: brandInfo?.industry?.id,
      image: brandInfo?.image,
      privateInfo: {
        street: brandInfo?.privateInfo?.street || '',
        postalCode: brandInfo?.privateInfo?.postalCode || '',
        VATnumber: brandInfo?.privateInfo?.VATnumber || '',
        IBAN: brandInfo?.privateInfo?.IBAN || '',
        bankAccount: brandInfo?.privateInfo?.bankAccount || '',
      },
    });
  };

  useEffect(() => {
    populateBrandsDataToForm(getBrand);
  }, [getBrand]);

  const handleUserVisibilityChange = (option: Option) => {
    updateBrandProfile({
      variables: {
        brandId: id,
        data: {
          ...values,
          privateInfo: {
            street: values?.privateInfo?.street || '',
            postalCode: values?.privateInfo?.postalCode || '',
            VATnumber: values?.privateInfo?.VATnumber || '',
            IBAN: values?.privateInfo?.IBAN || '',
            bankAccount: values?.privateInfo?.bankAccount || '',
          },
          isPrivate: option.value,
        },
      },
    });
  };

  return {
    id,
    currentTab,
    getBrand,
    loading,
    verificationStatus,
    visibilityStatus,
    handleTabChange,
    handleUserStatusChange,
    refetchBrandData,
    handleUserVisibilityChange,
  };
};

export const getOptions = (optionName: string, optionsArray: Array<BaseEntity>): Array<BaseEntity> =>
  optionsArray ? [...optionsArray, { __typename: optionName, id: null, name: null }] : [{ __typename: optionName, id: null, name: null }];

const validationSchema = object({
  companyName: string().nullable().required('ERRORS.emptyField'),
  about: string().nullable(),
  country: object({
    name: string().nullable(),
  }).nullable(),
  city: object({
    name: string().nullable(),
  }).nullable(),
  industry: object({
    name: string().nullable(),
  }).nullable(),
  image: mixed().nullable(),
  privateInfo: object({
    street: string(),
    postalCode: string(),
    VATnumber: string(),
    IBAN: string(),
    bankAccount: string(),
  }),
});

export const useBrandProfileForm = (brandData, refetchBrandData) => {
  const { data: industries, loading: industriesLoading } = useQuery(LIST_INDUSTRIES);

  const { data: countries, loading: countriesLoading } = useQuery(LIST_COUNTRIES);

  const [updateBrandProfile, { loading: brandIsUpdating }] = useMutation(ADMIN_UPDATE_BRAND_PROFILE, {
    onCompleted() {
      refetchBrandData();
    },
  });

  const handleFormSubmit = (values) => {
    const isEmptyObject = flow(lodashValues, compact, isEmpty);
    const privateInfo = isEmptyObject(values.privateInfo)
      ? undefined
      : {
          street: getTrimmedValue(values.privateInfo?.street),
          postalCode: getTrimmedValue(values.privateInfo?.postalCode),
          VATnumber: getTrimmedValue(values.privateInfo?.VATnumber),
          IBAN: getTrimmedValue(values.privateInfo?.IBAN),
          bankAccount: getTrimmedValue(values.privateInfo?.bankAccount),
        };
    updateBrandProfile({
      variables: {
        brandId: values.id,
        data: {
          companyName: getTrimmedValue(values.companyName),
          about: values.about,
          cityId: values.city.id,
          industryId: values.industry.id,
          image: values.uploadImage,
          privateInfo,
          isPrivate: values.isPrivate,
        },
      },
    });
  };

  const { values, errors, handleChange, setValues, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      companyName: '',
      about: '',
      country: { __typename: 'Country', id: null, name: null },
      city: undefined,
      // city: { __typename: 'City', id: null, name: null },
      industry: { __typename: 'Industry', id: null, name: null },
      image: '',
      privateInfo: {
        street: '',
        postalCode: '',
        VATnumber: '',
        IBAN: '',
        bankAccount: '',
      },
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: handleFormSubmit,
  });

  const populateBrandsDataToForm = (brandInfo) => {
    setValues({
      ...brandInfo,
      privateInfo: {
        street: brandInfo?.privateInfo?.street || '',
        postalCode: brandInfo?.privateInfo?.postalCode || '',
        VATnumber: brandInfo?.privateInfo?.VATnumber || '',
        IBAN: brandInfo?.privateInfo?.IBAN || '',
        bankAccount: brandInfo?.privateInfo?.bankAccount || '',
      },
    });
  };

  useEffect(() => {
    populateBrandsDataToForm(brandData);
  }, [brandData]);

  const { data: cities, loading: citiesLoading } = useQuery(LIST_CITIES, {
    skip: !values.country,
    variables: {
      countryId: values.country && values.country.id,
    },
  });

  const optionsCountry = getOptions('Country', countries?.listCountries);
  const optionsIndustry = getOptions('Industry', industries?.listIndustries);
  const optionsCities = getOptions('City', cities?.listCities);

  // TODO: ???
  const handleAutocompleteChange = (name) => (_, value) => {
    if (name === 'country' && value !== values.country) {
      setFieldValue('city', { __typename: 'City', id: null, name: null });
    }
    if ((name === 'industry' || name === 'country' || name === 'city') && value === null) {
      setFieldValue(name, { __typename: name[0].toUpperCase() + name.slice(1), id: null, name: null });
      return;
    }
    setFieldValue(name, value);
  };

  const onCancel = () => {};

  const handleImageChange = (key) => {
    setFieldValue('uploadImage', key);
  };

  return {
    values,
    errors,
    brandIsUpdating,
    industriesLoading,
    countriesLoading,
    citiesLoading,
    optionsCountry,
    optionsIndustry,
    optionsCities,
    handleSubmit,
    handleChange,
    handleAutocompleteChange,
    onCancel,
    handleImageChange,
  };
};

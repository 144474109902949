import inRange from 'lodash/inRange';

import type { ProfitMargins } from 'types/profitMargins';

export const getCurrentMargin = (budget: number, profitMargins: ProfitMargins) => {
  if (!budget || !profitMargins) return null;

  const { firstRange, secondRange, thirdRange } = profitMargins;

  if (inRange(budget, firstRange.range.from, firstRange.range.to)) return firstRange.percentage;
  else if (inRange(budget, secondRange.range.from, secondRange.range.to)) return secondRange.percentage;

  return thirdRange.percentage;
};

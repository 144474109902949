import { makeStyles, darken } from '@material-ui/core/styles';
import { colors } from 'theme/colors';

export const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      width: 720,
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
    },
  },
  titleBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 12,
    paddingLeft: theme.spacing(4),
    paddingBottom: 12,
    paddingRight: theme.spacing(4),
  },
  closeIcon: {
    fill: '#535E83',
  },
  divider: {
    marginBottom: theme.spacing(3),
  },
  description: {
    fontSize: 12,
    paddingTop: 0,
    paddingLeft: 30,
    paddingBottom: 28,
  },
  content: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  buttonContainer: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  btnCancel: {
    border: `1px solid ${colors.BORDER3}`,
    paddingLeft: 51,
    paddingRight: 51,
    fontSize: 12,
    fontWeight: 500,
  },
  taxContainer: {
    [theme.breakpoints.up('md')]: {
      paddingTop: '10px!important',
    },
  },
  taxText: {
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: colors.ERROR,
  },
  btnSend: {
    marginLeft: theme.spacing(2),
    paddingLeft: 46,
    paddingRight: 46,
    backgroundColor: colors.PRIMARY,
    color: colors.WHITE,
    fontSize: 12,
    fontWeight: 500,
    '&:hover': {
      backgroundColor: `${darken(colors.PRIMARY, 0.2)}`,
    },
  },
}));

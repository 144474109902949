import React, { FC } from 'react';
import { Container, Grid, Box, Divider, Typography, Button, useMediaQuery, useTheme } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import clsx from 'clsx';

import { intl } from 'helpers';
import { DesertImage } from 'assets/svg-js';
import { LIST_NEWS_FEED } from 'services/graphql/query/feed';
import { GET_NEWLY_REGISTERED_BRANDS } from 'services/graphql/talent';
import type { PostFeed, OpportunityFeed, ActivityPost } from 'types/post';
import { NewsFeedCollectionEnum } from 'types/post';

import { Loader } from 'components/Loader';
import { InfoPost } from 'components/InfoPost';
import { OpportunityPost } from 'components/OpportunityPost';
import { SlideWidget } from 'components/SlideWidget';
import { BrandListItem } from 'components/BrandListItem';

import { useStyles } from './styles';

const LIMIT = 10;

const NewsFeed: FC = () => {
  const styles = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const { data, loading, fetchMore } = useQuery<any>(LIST_NEWS_FEED, {
    variables: {
      filter: {
        collection: NewsFeedCollectionEnum.general,
      },
      pagination: {
        limit: LIMIT,
      },
    },
  });

  const { data: newlyBrands, loading: loadingBrands } = useQuery(GET_NEWLY_REGISTERED_BRANDS, {
    variables: {
      pagination: {
        limit: 12,
      },
    },
  });

  const onShowMore = () => {
    fetchMore({
      variables: {
        filter: {
          collection: NewsFeedCollectionEnum.general,
        },
        pagination: {
          offset: data?.listNewsFeed?.items?.length,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        if (fetchMoreResult?.listNewsFeed?.meta?.total < LIMIT) return prev;
        return {
          ...prev,
          listNewsFeed: {
            ...prev.listNewsFeed,
            items: [...prev.listNewsFeed.items, ...fetchMoreResult?.listNewsFeed?.items],
          },
        };
      },
    });
  };

  return (
    <Container className={styles.wrapper}>
      <Grid container spacing={isDesktop ? 3 : 0}>
        <Grid item lg={9} xs={12} className={styles.mainGrid}>
          <Box className={styles.heading}>
            <Typography className={styles.title}>{intl('SHARED.newsFeed')}</Typography>
          </Box>
          <Divider className={styles.divider} />
          {loading ? (
            <Loader className={styles.loader} />
          ) : data?.listNewsFeed?.meta?.total ? (
            <>
              {data?.listNewsFeed?.items?.map((n: ActivityPost) =>
                n?.__typename === 'PostFeed' ? (
                  <InfoPost userRole="none" post={n as PostFeed} key={n.id} />
                ) : (
                  <OpportunityPost userRole="none" opportunity={n as OpportunityFeed} key={n.id} />
                ),
              )}
              {data?.listNewsFeed?.meta?.total > data?.listNewsFeed?.items?.length && (
                <Box className={styles.showMoreContainer}>
                  <Button className={styles.buttonShowMore} variant="outlined" onClick={onShowMore} tabIndex={NaN} component="div" role="none">
                    {intl('SHARED.showMore')}
                  </Button>
                </Box>
              )}
            </>
          ) : (
            <Box className={styles.emptyBox}>
              <DesertImage className={styles.emptyIcon} />
              <Typography className={clsx(styles.title, styles.emptyText)}>{intl('EMPTY_STATE.noPosts')}</Typography>
            </Box>
          )}
        </Grid>
        <Grid item lg={3} className={styles.secondGrid}>
          <SlideWidget title={intl('NEWS_FEED.newBrands')} loading={loadingBrands} className={styles.brands}>
            {newlyBrands?.listNewlyBrands?.items?.map((brand) => (
              <BrandListItem notAuthorized key={brand.id} id={brand.id} name={brand.name} avatar={brand.image} joinedAt={brand.registeredAt} />
            ))}
          </SlideWidget>
        </Grid>
      </Grid>
    </Container>
  );
};

export { NewsFeed };

import React from 'react';
import { Button, CardMedia } from '@material-ui/core';

import { intl } from 'helpers';
import { Modal } from 'components/Modal';

import { useStyles } from './styles';

export const IntroModal = ({ isOpen, handleClose, src }) => {
  const { skipBtn } = useStyles();

  const renderClose = () => (
    <Button variant="outlined" className={skipBtn} onClick={handleClose}>
      {intl('SIGN_UP.introSkip')}
    </Button>
  );

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} title={intl('SIGN_UP.introVideoTitle')} dialogActionsComponent={renderClose()}>
      <CardMedia component="video" autoPlay loop controls src={src} />
    </Modal>
  );
};

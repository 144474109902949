import React, { FC } from 'react';
import { Paper, Typography, Divider, TextField, Box } from '@material-ui/core';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { intl } from 'helpers';
import { MAX_IMAGES_COUNT } from 'constants/index';
import { CREATE_POST } from 'services/graphql/mutation/feed';
import { LIST_NEWS_FEED } from 'services/graphql/query/feed';
import { NewsFeedCollectionEnum } from 'types/post';

import { Wrapper } from 'components/Wrapper';
import { SaveBar } from 'components/SaveBar';
import ImageUploader from 'components/ImageUploader';
import ImageGallery from 'components/ImageGallery';

import { useStyles } from './styles';

const LIMIT = 10;

const PostSchema = yup.object({
  description: yup.string().min(15, 'ERRORS.tooShortField').required('ERRORS.emptyField'),
  images: yup.array().of(yup.string()).optional(),
});

type Post = yup.InferType<typeof PostSchema>;

const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env;
const BUCKET_PATH = `${REACT_APP_AWSS3_URL_IMAGE_BEG}public/`;

const NewPost: FC = () => {
  const styles = useStyles();
  const { goBack } = useHistory();
  const [createPost, { loading }] = useMutation(CREATE_POST, {
    onCompleted: () => {
      goBack();
    },
    update: (cache, { data: { createPost: response } }) => {
      try {
        const { listNewsFeed } = cache.readQuery<any>({
          query: LIST_NEWS_FEED,
          variables: { filter: { collection: NewsFeedCollectionEnum.general }, pagination: { limit: LIMIT } },
        });
        cache.writeQuery({
          query: LIST_NEWS_FEED,
          variables: { filter: { collection: NewsFeedCollectionEnum.general }, pagination: { limit: LIMIT } },
          data: {
            listNewsFeed: {
              ...listNewsFeed,
              items: [response, ...listNewsFeed.items],
              meta: {
                total: listNewsFeed.meta.total + 1,
              },
            },
          },
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('[Error]', error);
      }
    },
  });

  const initialValues: Post = { description: '', images: [] };

  const { values, errors, handleChange, setFieldValue, handleSubmit } = useFormik({
    initialValues,
    validationSchema: PostSchema,
    validateOnChange: false,
    onSubmit: ({ description, images }) => {
      createPost({
        variables: {
          data: {
            description,
            images,
          },
        },
      });
    },
  });

  const handleSetImages = (value: string[]) => {
    const currentImages = ((values.images as unknown) as Array<string>).filter((image) => image !== 'loader');
    setFieldValue('images', [...currentImages, ...value]);
  };

  const handleSetImagesWithLoader = (loaders) => {
    setFieldValue('images', [...values.images, ...loaders]);
  };

  const handleDeleteImage = (image) => {
    const images = values.images.filter((currentImage) => !image.replace(BUCKET_PATH, '').includes(currentImage));
    setFieldValue('images', images);
  };

  return (
    <>
      <Wrapper className={styles.container}>
        <Paper className={styles.paper}>
          <Typography className={styles.title}>{intl('NEW_POST.title')}</Typography>
          <Divider className={styles.divider} />
          <TextField
            name="description"
            label={intl('NEW_POST.description')}
            value={values.description}
            error={!!intl(errors.description)}
            helperText={intl(errors.description)}
            className={styles.description}
            onChange={handleChange}
            fullWidth
            multiline
          />
          <Box className={styles.imagesBox}>
            <ImageGallery actionable handleDelete={handleDeleteImage} images={[...values.images.map((image) => BUCKET_PATH + image)]} />
            {values.images.length < MAX_IMAGES_COUNT && <ImageUploader setValue={handleSetImages} setWithLoader={handleSetImagesWithLoader} />}
          </Box>
        </Paper>
      </Wrapper>
      <SaveBar className={styles.saveBar} sticky onSave={handleSubmit} onCancel={goBack} disabled={loading} okText="NEW_POST.publish" />
    </>
  );
};

export { NewPost };

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  profileContainer: {
    marginTop: theme.spacing(3),
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  profileCard: {
    marginBottom: theme.spacing(2),
  },
}));

const DONE = { label: 'STATUS.done', value: 'Done' };
const PAYMENT = { label: 'STATUS.payment', value: 'Payment' };
const CANCELED_BY_BRAND = { label: 'STATUS.canceledByBrand', value: 'Canceled by brand' };
const CANCELED_BY_TALENT = { label: 'STATUS.canceledByTalent', value: 'Canceled by talent' };
const DECLINED = { label: 'STATUS.declined', value: 'Declined' };
const WAITING_FOR_PAYMENT = { label: 'STATUS.waitingForPayment', value: 'Waiting for payment' };
const OPEN = { label: 'STATUS.open', value: 'Open' };
const EXPIRED = { label: 'STATUS.expired', value: 'Expired' };
const CONFIRMED = { label: 'STATUS.confirmed', value: 'Confirmed' };
const CANCELED_BY_ADMIN_PAYMENT = { label: 'STATUS.canceledByAdmin', value: 'admin_cancel_payment' };
const CANCELED_BY_ADMIN = { label: 'STATUS.canceledByAdmin', value: 'admin_cancel' };

const STATUSES = {
  DONE,
  PAYMENT,
  CANCELED_BY_BRAND,
  CANCELED_BY_TALENT,
  DECLINED,
  WAITING_FOR_PAYMENT,
  OPEN,
  EXPIRED,
  CONFIRMED,
  CANCELED_BY_ADMIN_PAYMENT,
  CANCELED_BY_ADMIN,
};

export { STATUSES };

/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Button, TableRow, TableCell, Paper, Avatar, Box, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import clsx from 'clsx';
import { SearchFilter } from 'assets/svg-js';
import { intl, roundTo2 } from 'helpers';
import { useLink } from 'hooks/linkTo';
import { routes } from 'router/routesList';
import { GET_TALENTS_LIST } from 'services/graphql/admin';
import { getStatus } from 'helpers/admin';
import { TableWithPagination } from 'components/TableWithPagination';
import { AdminSearchFilters } from 'components/AdminSearchFilters';
import { AdminTalentFilters } from 'components/AdminTalentFilters';
import { TableLoader } from 'components/TableLoader';
import { InvitationModal } from 'components/Modals/InvitationModal';
import { useStyles } from './styles';
import { useInviteUser } from '../hooks';

const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env;

const thReal = [
  { name: 'ADMIN.TALENTS.profilePhoto', minWidth: 73, width: 88 },
  { name: 'ADMIN.TALENTS.firstName', minWidth: 123, width: 199, sortField: 'firstName' },
  { name: 'ADMIN.TALENTS.lastName', minWidth: 123, width: 199, sortField: 'lastName' },
  { name: 'ADMIN.TALENTS.gender', minWidth: 84, width: 102 },
  { name: 'ADMIN.TALENTS.country', minWidth: 115, width: 140, sortField: 'countryId' },
  { name: 'ADMIN.TALENTS.city', minWidth: 86, width: 105, sortField: 'cityId' },
  { name: 'ADMIN.TALENTS.sport', minWidth: 104, width: 127, sortField: 'sportId' },
  { name: 'ADMIN.TALENTS.email', minWidth: 276, width: 337, sortField: 'email' },
  { name: 'ADMIN.TALENTS.status', minWidth: 111, width: 111, sortField: 'verificationStatus' },
  { name: 'ADMIN.BRANDS.registrationDate', minWidth: 143, width: 219, sortField: 'createdAt' },
  { name: 'ADMIN.TALENTS.rating', minWidth: 78, width: 95, align: 'right', sortField: 'feedback' },
];

export const Talents = () => {
  const styles = useStyles();
  const linkTo = useLink();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({});
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(thReal[4].sortField);
  const { isInvitationModal, invitationResponseError, handleInvitationModal, submitInvitation } = useInviteUser('talent');

  const createSortHandler = (sortField) => () => {
    setOrderBy(sortField);
    setOrder((current) => (current === 'asc' ? 'desc' : 'asc'));
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setPage(Math.floor((rowsPerPage / e.target.value) * page));
    setRowsPerPage(e.target.value);
  };

  const [fetchData, { data, loading }] = useLazyQuery(GET_TALENTS_LIST, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    const competition = filters?.statuses?.filter((c) => c.key === 'competing');
    const privates = filters?.statuses?.filter((c) => c.key === 'private');
    const verificationStatus = filters?.statuses?.filter((c) => c.key === 'verificationStatus');
    const options = {
      cityId: filters?.city?.map((city) => city.id),
      countryId: filters?.country?.map((country) => country.id),
      sportId: filters?.sport?.map((sport) => sport.id),
      tags: filters?.tags,
      gender: filters?.gender?.id,
      instagramFollowers: filters?.instagramFollowers,
      twitterFollowers: filters?.twitterFollowers,
      facebookFollowers: filters?.facebookFollowers,
      age: filters?.age,
      competition: competition?.length ? competition.map(({ value }) => value) : [],
      private: privates?.length ? privates.map(({ value }) => value) : [],
      verificationStatus: verificationStatus?.length ? verificationStatus.map(({ value }) => value) : [],
    };

    fetchData({
      variables: {
        filter: {
          offset: rowsPerPage * page,
          limit: rowsPerPage,
          options,
          sort: {
            field: orderBy,
            order,
          },
        },
        search1: Date.now(),
        search,
      },
    });
  }, [filters, rowsPerPage, page, orderBy, order, search]);

  const dataToDisplay = data?.adminListTalents?.items || [];
  const handleApply = (values) => {
    setPage(0);
    setFilters(values);
  };

  useEffect(() => {
    setPage(0);
  }, [search]);

  const filtersActive =
    filters?.city?.map((city) => city.id)?.length ||
    filters?.country?.map((country) => country.id)?.length ||
    filters?.sport?.map((sport) => sport.id)?.length ||
    filters?.tags?.length ||
    filters?.gender?.id ||
    filters?.instagramFollowers?.from ||
    filters?.instagramFollowers?.to ||
    filters?.twitterFollowers?.from ||
    filters?.twitterFollowers?.to ||
    filters?.facebookFollowers?.from ||
    filters?.facebookFollowers?.to;

  const emptyTable = !dataToDisplay.length || loading;

  return (
    <Paper className={styles.paper}>
      <TableWithPagination
        tableLabel="Talent"
        thCells={thReal}
        emptyHead={emptyTable}
        className={clsx({
          [styles.fullHeight]: emptyTable,
        })}
        toolBarRightComponent={
          <AdminSearchFilters
            availableFilters={data?.adminListTalents?.filters}
            filterComponent={AdminTalentFilters}
            onSearchChange={setSearch}
            handleApply={handleApply}
            filters={filters}
            filtersActive={filtersActive}
            saveToCSVLink={data?.adminListTalents?.listTalentsUrl}
          />
        }
        paginationProps={{
          count: data?.adminListTalents?.meta?.total,
          page,
          rowsPerPage,
          handleChangePage,
          handleChangeRowsPerPage,
        }}
        createSortHandler={createSortHandler}
        order={order}
        orderBy={orderBy}
      >
        {loading ? (
          <TableLoader columnCount={thReal.length} height={569} />
        ) : dataToDisplay.length ? (
          dataToDisplay.map((row) => (
            <TableRow className={styles.row} hover onClick={linkTo(`/${routes.ADMIN}/${routes.TALENTS}/${row.id}`)} key={row.id}>
              <TableCell className={styles.tCell}>
                <Avatar className={styles.avatar} src={REACT_APP_AWSS3_URL_IMAGE_BEG + row.image}>
                  {row?.firstName?.[0]} {row?.lastName?.[0]}
                </Avatar>
              </TableCell>
              <TableCell className={styles.tCell}>{row.firstName || '-'}</TableCell>
              <TableCell className={styles.tCell}>{row.lastName || '-'}</TableCell>
              <TableCell className={styles.tCell}>{row.gender || '-'}</TableCell>
              <TableCell className={styles.tCell}>{row.country?.name || '-'}</TableCell>
              <TableCell className={styles.tCell}>{row.city?.name || '-'}</TableCell>
              <TableCell className={styles.tCell}>{row.sport?.name || '-'}</TableCell>
              <TableCell className={styles.tCell}>{row.email || '-'}</TableCell>
              <TableCell className={styles.tCell}>{getStatus(row.verificationStatus) || '-'}</TableCell>
              <TableCell className={styles.tCell}>{format(new Date(row.createdAt), 'dd.MM.yyyy') || '-'}</TableCell>
              <TableCell className={styles.tCell} align="right">
                {roundTo2(row?.averageFeedback) ?? '-'}
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <Box className={styles.emptyBox}>
              <SearchFilter />
              <Typography className={styles.emptyText}>{intl('EMPTY_STATE.noResults')}</Typography>
            </Box>
          </TableRow>
        )}
      </TableWithPagination>
      <Button className={styles.inviteBtn} color="primary" variant="contained" onClick={handleInvitationModal}>
        {intl('ADMIN.TALENTS.inviteUser')}
      </Button>
      <InvitationModal
        isOpen={isInvitationModal}
        handleClose={handleInvitationModal}
        title={intl('INVITATION_MODAL.user')}
        bodyText={intl('INVITATION_MODAL.provideUser')}
        label={intl('INVITATION_MODAL.labelUser')}
        submit={submitInvitation}
        responseError={invitationResponseError}
      />
    </Paper>
  );
};

import React, { FC } from 'react';
import { Paper, Typography, Divider, TextField, Box } from '@material-ui/core';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';

import { intl } from 'helpers';
import { MAX_IMAGES_COUNT } from 'constants/index';
import { GET_POST } from 'services/graphql/query/feed';
import { UPDATE_POST } from 'services/graphql/mutation/feed';

import { Wrapper } from 'components/Wrapper';
import { Loader } from 'components/Loader';
import { SaveBar } from 'components/SaveBar';
import ImageUploader from 'components/ImageUploader';
import ImageGallery from 'components/ImageGallery';

import { useStyles } from './styles';

const PostSchema = yup.object({
  description: yup.string().min(15, 'ERRORS.tooShortField').required('ERRORS.emptyField'),
  images: yup.array().of(yup.string()).optional(),
});

type Post = yup.InferType<typeof PostSchema>;

const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env;
const BUCKET_PATH = `${REACT_APP_AWSS3_URL_IMAGE_BEG}`;
const BUCKET_TEMP_PATH = `${REACT_APP_AWSS3_URL_IMAGE_BEG}public/`;

const EditPost: FC = () => {
  const styles = useStyles();
  const { goBack, push } = useHistory();
  const { id } = useParams();

  const { data, loading } = useQuery(GET_POST, {
    variables: { id },
  });

  const [updatePost, { loading: postCreating }] = useMutation(UPDATE_POST, {
    onCompleted: () => {
      goBack();
    },
    update: (cache, { data: { updatePost: response } }) => {
      try {
        const { getPost } = cache.readQuery<any>({ query: GET_POST, variables: { id } });
        cache.writeQuery({
          query: GET_POST,
          variables: { id },
          data: {
            getPost: {
              ...getPost,
              description: response.description,
              images: response.images,
            },
          },
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('[Cache updating error]', error);
      }
    },
  });

  const initialValues: Post = { description: data?.getPost?.description ?? '', images: data?.getPost?.images ?? [] };

  const { values, errors, handleChange, setFieldValue, handleSubmit } = useFormik({
    initialValues,
    validationSchema: PostSchema,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: ({ description, images }) => {
      updatePost({
        variables: {
          id,
          inputData: {
            description,
            images,
          },
        },
      });
    },
  });

  const mappingImages = (image) => (image.includes('uploads') ? BUCKET_PATH + image : BUCKET_TEMP_PATH + image);

  const handleSetImages = (value: string[]) => {
    const currentImages = ((values.images as unknown) as Array<string>).filter((image) => image !== 'loader');
    setFieldValue('images', [...currentImages, ...value]);
  };

  const handleSetImagesWithLoader = (loaders) => {
    setFieldValue('images', [...values.images, ...loaders]);
  };

  const handleDeleteImage = (image) => {
    const images = values.images.filter((currentImage) => !image.replace(BUCKET_PATH, '').includes(currentImage));
    setFieldValue('images', images);
  };

  if (!loading && !data?.getPost?.canInteract) {
    push('/');
  }

  return (
    <>
      <Wrapper className={styles.container}>
        {loading && <Loader />}
        {!loading && (
          <Paper className={styles.paper}>
            <Typography className={styles.title}>{intl('EDIT_POST.title')}</Typography>
            <Divider className={styles.divider} />
            <TextField
              name="description"
              label={intl('EDIT_POST.description')}
              value={values.description}
              error={!!intl(errors.description)}
              helperText={intl(errors.description)}
              className={styles.description}
              onChange={handleChange}
              fullWidth
              multiline
            />
            <Box className={styles.imagesBox}>
              <ImageGallery actionable handleDelete={handleDeleteImage} images={values.images.map(mappingImages)} />
              {values.images.length < MAX_IMAGES_COUNT && <ImageUploader setValue={handleSetImages} setWithLoader={handleSetImagesWithLoader} />}
            </Box>
          </Paper>
        )}
      </Wrapper>
      {!loading && (
        <SaveBar className={styles.saveBar} sticky onSave={handleSubmit} onCancel={goBack} loading={postCreating} okText="EDIT_POST.publish" />
      )}
    </>
  );
};

export { EditPost };

import React, { useEffect } from 'react';
import { AppBar, Typography, Divider, Button, Box, Grid, useMediaQuery, IconButton, Hidden, Chip } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { Autocomplete } from '@material-ui/lab';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { DatePicker } from '@material-ui/pickers';
import { CloseRounded, ClearRounded } from '@material-ui/icons';
import clsx from 'clsx';
import intersectionWith from 'lodash/intersectionWith';
import isEqual from 'lodash/isEqual';

import { intl, omit } from 'helpers';
import { LIST_INDUSTRIES } from 'services/graphql/industry';
import { LIST_COUNTRIES, LIST_CITIES } from 'services/graphql/location';

import { FilterTextField } from 'components/FilterTextField';

import { useStyles } from './styles';

export const BrandFilters = ({ className, handleClose, handleApply, filters, availableFilters }) => {
  const styles = useStyles({});
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const { values, errors, handleChange, setFieldValue, setValues, handleSubmit } = useFormik({
    initialValues: {
      country: filters?.country || [],
      city: filters?.city || [],
      industry: filters?.industry || [],
      rating: filters?.rating || {
        from: null,
        to: null,
      },
      filterDate: {
        from: null,
        to: null,
      },
      to: null,
    },
    validationSchema: Yup.object({
      rating: Yup.object({
        from: Yup.number().nullable().min(0).max(5),
        to: Yup.number().nullable().min(0).max(5),
      }),
    }),
    onSubmit: (vals) => handleApply(vals, true),
    enableReinitialize: true,
  });
  const { data: countries, loading: countriesLoading } = useQuery(LIST_COUNTRIES);

  const { data: industries, loading: industriesLoding } = useQuery(LIST_INDUSTRIES);

  const { data: cities, loading: citiesLoading } = useQuery(LIST_CITIES, {
    skip: !values?.country?.[0]?.id,
    variables: {
      countryIds: values?.country && values?.country?.map((country) => country.id),
    },
  });

  const handleAutocompleteChange = (name) => (_, value) => setFieldValue(name, value);

  useEffect(() => {
    if (cities) {
      const filteredCities = intersectionWith(values.city, cities.listCities, isEqual);
      setFieldValue('city', filteredCities);
    } else if (!values.country.length) {
      setFieldValue('city', []);
    }
  }, [cities]);

  const handleValuesClear = (field) => {
    setValues({
      ...values,
      [field]: [],
    });
    handleApply(omit(values, [field]));
  };

  const handleClear = (section) => () => {
    switch (section) {
      case 'country':
        handleValuesClear('country');
        break;

      case 'city':
        handleValuesClear('city');
        break;

      case 'industry':
        handleValuesClear('industry');
        break;

      case 'rating':
        setValues({
          ...values,
          rating: {
            from: '',
            to: '',
          },
        });
        handleApply(omit(values, ['rating']));
        break;

      case 'filterDate':
        setValues({
          ...values,
          filterDate: {
            from: null,
            to: null,
          },
        });
        handleApply(omit(values, ['rating']));
        break;

      default:
        handleApply({});
        setValues({
          ...values,
          country: [],
          industry: [],
          rating: {
            from: '',
            to: '',
          },
          filterDate: {
            from: null,
            to: null,
          },
        });
    }
  };

  const checkDisabledClear = {
    country: values?.country?.length,
    city: values?.city?.length,
    industry: values?.industry?.length,
    rating: values?.rating?.from ?? values?.rating?.to,
    filterDate: values?.filterDate.from ?? values.filterDate.to,
  };

  const chipRenderer = (value, getTagProps) =>
    value.map((option, index) => (
      <Chip
        {...getTagProps({ index })}
        className={styles.chip}
        key={option.id}
        label={option.name}
        deleteIcon={
          <IconButton styles={{ label: styles.deleteIconLabel }} className={styles.deleteIcon}>
            <ClearRounded className={styles.icon} />
          </IconButton>
        }
      />
    ));

  const handleDateFrom = (value) => setFieldValue('filterDate.from', value.setHours(0, 0, 0, 0));
  const handleDateTo = (value) => setFieldValue('filterDate.to', value.setHours(23, 59, 59, 999));

  return (
    <AppBar position={isMobile ? 'static' : 'sticky'} color="transparent" component="header" className={clsx(styles.profileCard, className)}>
      <Box className={styles.topBar}>
        <Typography className={styles.filters}>{intl('BRAND_FILTERS.filters')}</Typography>
        <Hidden mdUp>
          <IconButton onClick={handleClose} size="small">
            <CloseRounded />
          </IconButton>
        </Hidden>
      </Box>

      <Divider />
      <Box className={styles.mainBlock}>
        <Box className={styles.location}>
          <Box className={styles.clearBlock}>
            <Typography className={styles.opportunitySectionTitle}>{intl('BRAND_FILTERS.country')}</Typography>
            <Button disabled={!checkDisabledClear.country} onClick={handleClear('country')} className={styles.clearBtn}>
              {intl('BRAND_FILTERS.clear')}
            </Button>
          </Box>
          <Autocomplete
            className={styles.input}
            name="country"
            loading={countriesLoading}
            options={countries ? countries.listCountries : []}
            value={values?.country}
            multiple
            onChange={handleAutocompleteChange('country')}
            getOptionLabel={(option) => option.name || ''}
            getOptionSelected={(option, value) => option.id === value.id}
            renderTags={chipRenderer}
            renderInput={(params) => <FilterTextField {...params} placeholder={intl('BRAND_FILTERS.allCountries')} name="country" />}
          />
        </Box>
        <Box className={styles.location}>
          <Box className={styles.clearBlock}>
            <Typography className={styles.opportunitySectionTitle}>{intl('BRAND_FILTERS.city')}</Typography>
            <Button disabled={!checkDisabledClear.city} onClick={handleClear('city')} className={styles.clearBtn}>
              {intl('BRAND_FILTERS.clear')}
            </Button>
          </Box>
          <Autocomplete
            className={styles.input}
            name="city"
            loading={citiesLoading}
            disabled={!values?.country?.length}
            options={cities ? cities.listCities : []}
            value={values?.city}
            multiple
            onChange={handleAutocompleteChange('city')}
            getOptionLabel={(option) => option.name || ''}
            getOptionSelected={(option, value) => option.id === value.id}
            renderTags={chipRenderer}
            renderInput={(params) => <FilterTextField {...params} placeholder={intl('BRAND_FILTERS.allCities')} name="city" />}
          />
        </Box>

        <Box className={styles.location}>
          <Box className={styles.clearBlock}>
            <Typography className={styles.opportunitySectionTitle}>{intl('BRAND_FILTERS.industry')}</Typography>
            <Button disabled={!checkDisabledClear.industry} onClick={handleClear('industry')} className={styles.clearBtn}>
              {intl('BRAND_FILTERS.clear')}
            </Button>
          </Box>
          <Autocomplete
            className={styles.input}
            name="industry"
            options={industries?.listIndustries?.filter((i) => availableFilters?.industries?.includes(i.id)) || []}
            value={values?.industry}
            multiple
            loading={industriesLoding}
            onChange={handleAutocompleteChange('industry')}
            getOptionLabel={(option) => option.name || ''}
            getOptionSelected={(option, value) => option.id === value.id}
            renderTags={chipRenderer}
            renderInput={(params) => <FilterTextField {...params} placeholder={intl('BRAND_FILTERS.allIndustries')} name="industry" />}
          />
        </Box>

        <Box className={styles.location}>
          <Box className={styles.clearBlock}>
            <Typography className={styles.opportunitySectionTitle}>{intl('BRAND_FILTERS.rating')}</Typography>
            <Button onClick={handleClear('rating')} disabled={!checkDisabledClear.rating} className={styles.clearBtn}>
              {intl('BRAND_FILTERS.clear')}
            </Button>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FilterTextField
                onChange={handleChange}
                fullWidth
                value={values?.rating?.from || ''}
                name="rating.from"
                inputClassName={styles.centered}
                placeholder={intl('BRAND_FILTERS.from')}
              />
            </Grid>
            <Grid item xs={6}>
              <FilterTextField
                onChange={handleChange}
                fullWidth
                value={values?.rating?.to || ''}
                name="rating.to"
                inputClassName={styles.centered}
                placeholder={intl('BRAND_FILTERS.to')}
              />
            </Grid>
            {(errors?.rating?.to || errors?.rating?.from) && (
              <Typography className={styles.smallError}>{intl('BRAND_FILTERS.minMaxError')}</Typography>
            )}
          </Grid>

          <Box display="flex" alignItems="center" justifyContent="space-between" pl={1} mt={2} mb={1}>
            <Typography className={'styles.filterLabel'}>{intl('PAYMENT_HISTORY.date')}</Typography>
            <Button onClick={handleClear('filterDate')} disabled={!checkDisabledClear.filterDate} className={styles.clearBtn}>
              {intl('BRAND_FILTERS.clear')}
            </Button>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DatePicker
                format="dd.MM.yy"
                maxDate={values.filterDate.to || Date.now()}
                value={values.filterDate.from}
                onChange={handleDateFrom}
                animateYearScrolling
                TextFieldComponent={(params) => (
                  <FilterTextField
                    inputClassName={clsx(styles.centered, styles.input)}
                    placeholder={intl('PAYMENT_HISTORY.FILTERS.from')}
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                format="dd.MM.yy"
                maxDate={Date.now()}
                value={values.filterDate.to}
                onChange={handleDateTo}
                animateYearScrolling
                TextFieldComponent={(params) => (
                  <FilterTextField
                    inputClassName={clsx(styles.centered, styles.input)}
                    placeholder={intl('PAYMENT_HISTORY.FILTERS.to')}
                    {...params}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Divider />
      <Grid container spacing={2} className={styles.clearFilters}>
        <Grid item xs={6}>
          <Button onClick={handleClear('all')} variant="outlined" fullWidth>
            {intl('BRAND_FILTERS.clear')}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button variant="contained" fullWidth color="primary" onClick={handleSubmit}>
            {intl('BRAND_FILTERS.apply')}
          </Button>
        </Grid>
      </Grid>
    </AppBar>
  );
};

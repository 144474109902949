import { gql } from '@apollo/client';

const GET_CONVERSATION = gql`
  query GetConversation($applicationId: ID!, $filter: PaginationFilter) {
    getConversation(applicationId: $applicationId, filter: $filter) {
      id
      createdAt
      opportunityId
      unreadMessagesCount
      participants {
        firstName
        id
        image
        lastName
        type
      }
      messages {
        total
        items {
          action
          applicationId
          body
          conversationId
          coverLetter
          createdAt
          id
          offerId
          offer {
            confirmedAt
            declinedAt
            description
            id
            isTaxed
            talentSalary
            offerAmount
            opportunityId
            talentId
          }
          sender {
            firstName
            id
            image
            lastName
            type
          }
        }
      }
    }
  }
`;

const GET_UNREAD_MESSAGES = gql`
  query listConversations($filter: PaginationFilter, $opportunityId: ID) {
    listConversations(filter: $filter, opportunityId: $opportunityId) {
      items {
        id
        applicationId
        unreadMessagesCount
      }
    }
  }
`;

export { GET_CONVERSATION, GET_UNREAD_MESSAGES };

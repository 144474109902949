import { makeStyles, Theme } from '@material-ui/core';
import { constants } from '../../theme/constants';
import { colors } from '../../theme/colors';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    background: colors.HEADER_FOOTER,
    boxShadow: 'none',
    '& $profileBar': {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '& $navBar': {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    zIndex: 1201,
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: constants.HEADER_WRAPPER_WIDTH,
    height: constants.HEADER_HEIGHT,
    maxWidth: '95%',
    margin: '0 auto',
  },
  logoAndNav: {
    display: 'flex',
    alignItems: 'center',
  },
  sportEndorse: {
    marginRight: 120,
    [theme.breakpoints.down('sm')]: {
      marginRight: 60,
    },
  },
  logo: {
    textDecoration: 'none',
    marginRight: 135,
    [theme.breakpoints.down('sm')]: {
      marginRight: 60,
    },
  },
  notification: {
    display: 'flex',
  },
  notificationBtn: {
    marginRight: theme.spacing(2),
    borderRadius: 4,
  },
  notificationBtnOpened: {
    backgroundColor: colors.WHITE,
  },
  navBar: {},
  profileBar: {},
  toolbarBox: {
    display: 'flex',
  },
  burger: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  drawerContainer: {
    width: 232,
    backgroundColor: colors.WHITE,
    padding: theme.spacing(2),
    height: 'calc(100% - 64px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  drawer: {
    top: 64,
  },
  avatar: {
    width: 48,
    height: 48,
    marginRight: theme.spacing(2),
  },
  nameAndAvatar: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  profileCardBtn: {
    height: 40,
  },
  profileCardBtnLabel: {
    textAlign: 'left',
    justifyContent: 'flex-start',
  },
  btnsBlock: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  link: {
    textTransform: 'uppercase',
    fontSize: 12,
    color: colors.LINK_GRAY,
    textDecoration: 'none',
  },
  linksBlock: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export { useStyles };

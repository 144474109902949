import React, { FC } from 'react';
import { Box, Divider, Grid, Paper, Typography } from '@material-ui/core';

import { intl } from 'helpers';
// import { useTalentAnalytics } from '../hooks';

import { useStyles } from '../styles';

const numberFormatter = new Intl.NumberFormat('ru');

type Props = {
  revenueDoneOpportunities: number;
  revenueCanceledOpportunities: number;
  revenueAveragePerOpportunity: number;
};

const getFormattedNumber = (num: number): string => {
  if (!num) {
    return '-';
  }
  return numberFormatter.format(num);
};

export const GeneralInfo: FC<Props> = ({ revenueDoneOpportunities, revenueCanceledOpportunities, revenueAveragePerOpportunity }) => {
  // const { revenueDoneOpportunities, revenueCanceledOpportunities, revenueAveragePerOpportunity } = useTalentAnalytics();
  const styles = useStyles({});

  return (
    <Paper className={styles.generalContainer}>
      <Box className={styles.generalItem}>{intl('TALENT.ANALYTICS.general')}</Box>
      <Divider />
      <Box className={styles.generalItem}>
        <Typography>{intl('TALENT.ANALYTICS.title')}</Typography>
        <Typography>{intl('TALENT.ANALYTICS.amount')}</Typography>
      </Box>
      <Divider />
      <Grid container className={styles.generalItem}>
        <Grid item xs={6}>
          <Typography>{intl('TALENT.ANALYTICS.revDoneOpp')}</Typography>
        </Grid>
        <Grid container item xs={6} justify="flex-end">
          <Typography>{getFormattedNumber(revenueDoneOpportunities)}</Typography>
        </Grid>
      </Grid>
      <Divider />
      <Box className={styles.generalItem}>
        <Typography>{intl('TALENT.ANALYTICS.revCanceled')}</Typography>
        <Typography>{getFormattedNumber(revenueCanceledOpportunities)}</Typography>
      </Box>
      <Divider />
      <Box className={styles.generalItem}>
        <Typography>{intl('TALENT.ANALYTICS.revAverage')}</Typography>
        <Typography>{getFormattedNumber(revenueAveragePerOpportunity)}</Typography>
      </Box>
    </Paper>
  );
};

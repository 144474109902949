import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  talentsBtn: {
    height: 32,
    textTransform: 'uppercase',
  },
  withFixedWidth: {
    width: '100%',
    minWidth: 130,
  },
}));

import React from 'react'
import {
  Paper,
  Toolbar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { useStyles } from './styles'
import { intl } from '../../helpers'
import { ADMIN_GET_BRAND_ANALYTIC } from '../../services/graphql/analytics'
import { Loader } from '../Loader'

export const BrandAnalytics = () => {
  const classes = useStyles({})
  const { id } = useParams()
  const { data, loading } = useQuery(ADMIN_GET_BRAND_ANALYTIC, {
    variables: {
      brandId: id,
    },
  })
  return (
    <Paper className={classes.paper}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Toolbar className={classes.toolbar}>{intl('SHARED.opportunities')}</Toolbar>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{intl('COMMON_ANALYTICS.typesAndStatuses')}</TableCell>
                  <TableCell align="right">{intl('COMMON_ANALYTICS.amount')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{intl('BRAND_PROFILE_ANALYTICS.postedOpportunities')}</TableCell>
                  <TableCell align="right">
                    {data?.adminGetBrandAnalytic.postedOpportunities}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{intl('BRAND_PROFILE_ANALYTICS.applicants')}</TableCell>
                  <TableCell align="right">{data?.adminGetBrandAnalytic.totalApplicants}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{intl('BRAND_PROFILE_ANALYTICS.accepted')}</TableCell>
                  <TableCell align="right">
                    {data?.adminGetBrandAnalytic.acceptedApplicants}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{intl('BRAND_PROFILE_ANALYTICS.declined')}</TableCell>
                  <TableCell align="right">
                    {data?.adminGetBrandAnalytic.declinedApplicants}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{intl('COMMON_ANALYTICS.cancelledByTalent')}</TableCell>
                  <TableCell align="right">
                    {data?.adminGetBrandAnalytic.canceledByBrandOpportunities}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{intl('COMMON_ANALYTICS.cancelledByBrand')}</TableCell>
                  <TableCell align="right">
                    {data?.adminGetBrandAnalytic.canceledByTalentOpportunities}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Paper>
  )
}

import React, { useState } from 'react';
import { Box, IconButton, AppBar, Drawer, Button, Hidden } from '@material-ui/core';
import { useHistory, Link } from 'react-router-dom';
import { MenuRounded } from '@material-ui/icons';

import { routes } from 'router/routesList';
import { intl } from 'helpers';

import { SportEndorse } from 'components/SportEndorse';

import { useStyles } from './styles';

const HeaderUnregistered = () => {
  const styles = useStyles();
  const [drawerOpened, setDrawer] = useState<boolean>(false);

  const toggleDrawer = () => setDrawer((drawerOld) => !drawerOld);

  const history = useHistory();

  const handleHistoryPush = (link: string) => () => {
    history.push(link);

    toggleDrawer();
  };

  return (
    <AppBar position="sticky" color="transparent" component="header" className={styles.header}>
      <Box className={styles.headerWrapper}>
        <Box className={styles.content}>
          <Link to={`/${routes.NEWS_FEED}`} className={styles.logoIcon}>
            <SportEndorse />
          </Link>
          <Hidden smDown>
            <Box>
              <Button variant="outlined" component={Link} to={routes.LOGIN} className={styles.btnLogIn}>
                {intl('AUTH.logIn')}
              </Button>
              <Button variant="contained" color="primary" component={Link} to={`/${routes.SIGN_UP}/${routes.TALENT}`} className={styles.btnSignUp}>
                {intl('AUTH.signUp')}
              </Button>
            </Box>
          </Hidden>
        </Box>
        <Box className={styles.toolbarBox}>
          <IconButton onClick={toggleDrawer} className={styles.burger} size="small">
            <MenuRounded />
          </IconButton>
        </Box>
      </Box>
      <Hidden mdUp>
        <Drawer anchor="right" open={drawerOpened} classes={{ paper: styles.drawer }} onClose={toggleDrawer}>
          <Box className={styles.drawerContainer}>
            <Box>
              <Button
                fullWidth
                className={styles.profileCardBtn}
                classes={{ label: styles.profileCardBtnLabel }}
                onClick={handleHistoryPush(routes.LOGIN)}
              >
                {intl('AUTH.logIn')}
              </Button>
              <Button
                fullWidth
                className={styles.profileCardBtn}
                classes={{ label: styles.profileCardBtnLabel }}
                onClick={handleHistoryPush(`/${routes.SIGN_UP}/${routes.TALENT}`)}
              >
                {intl('AUTH.signUp')}
              </Button>
            </Box>
            <Box className={styles.linksBlock}>
              <a className={styles.link} href="https://sportendorse.com/our-story/">
                {intl('FOOTER.aboutUs')}
              </a>
              <a className={styles.link} href="https://sportendorse.com/faqs/">
                {intl('FOOTER.help')}
              </a>
              <a className={styles.link} href="https://sportendorse.com/contact-us">
                {intl('FOOTER.contactUs')}
              </a>
            </Box>
          </Box>
        </Drawer>
      </Hidden>
    </AppBar>
  );
};

export { HeaderUnregistered };

export const colors = {
  PRIMARY: '#18A0FB',
  PRIMARY_HOVERED: '#1278BC',
  PRIMARY_FOCUSED: '#1278BC',
  ERROR: '#FF5151',
  BACKGROUND: '#F6F6F6',
  BACKGROUND2: '#C4C4C4',
  BACKGROUND2_HOVERED: '#9d9d9d',
  BACKGROUND3: '#E6F2F9',
  BACKGROUND4: '#F6F6F5',
  LAYOUT_BACKGROUND: 'rgba(0, 0, 0, 0.05)',

  BORDER: '#EAEAEA',
  BORDER2: '#EFEFEF',
  BORDER3: '#dfe3ef',

  PRIMARY_INPUT_HOVERED: '#535E83',
  INPUT_LABEL: '#838383',
  BUTTON_PRIMARY_TEXT: '#FFFFFF',

  PROFILE_BORDER: '#F2F4FB',

  TEXT_GRAY: '#8c8c8c',
  GRAY_LIGHTEN: '#8E8E93',
  TEXT_H: '#333333',
  WHITE: '#ffffff',
  LINK_GRAY: '#989898',

  WHITE_DARKEN: '#ECF0FC',
  BLACK: '#000000',
  GRAY: 'rgba(0, 0, 0, 0.54)',
  TEXT: '#3B434E',
  LIGHT_TEXT: '#5C5C5C',
  HEADER_FOOTER: '#EAEAEA',
  SECONDARY_TEXT: '#B1B4B8',

  TRANSPARENT: 'transparent',

  LIGHT_ERROR: 'rgba(255, 81, 81, 0.2)',
  LIGHT_ERROR2: '#FFDCDC',
};

import { makeStyles } from '@material-ui/core';
import { colors } from '../../theme/colors';

export const useStyles = makeStyles((theme) => ({
  icon: {
    height: 16,
    width: 16,
    marginTop: 4,
    marginRight: 10,
    fill: colors.PRIMARY_INPUT_HOVERED,
  },
  iconTypoFirst: {
    marginBottom: 12,
  },
  iconAndText: { display: 'flex' },
  avatar: {
    height: 121,
    width: 121,
    fontSize: '3rem',
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      height: 52,
      width: 52,
      marginRight: theme.spacing(1.5),
      marginBottom: 18,
      fontSize: '1.33rem',
    },
  },
  topBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      marginBottom: 0,
    },
  },
  paper: {
    display: 'flex',
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(1.5),
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
  },
  rightSide: {
    flex: 1,
  },
  joined: {
    marginLeft: 'auto',
    [theme.breakpoints.down('xs')]: { marginLeft: 0 },
    opacity: 0.4,
    fontSize: 12,
  },
  name: {
    fontSize: 18,
  },
  info: {
    marginTop: theme.spacing(2),
  },
  topBarMobile: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
    },
  },
  favourite: {
    fontSize: 20,
    color: colors.PRIMARY_INPUT_HOVERED,
  },
  favouriteBtn: {
    // padding: theme.spacing(0, 0, 0.5, 1),
  },
}));

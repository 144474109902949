import React from 'react';
import { Typography, Divider, Button, Avatar, Box, Hidden } from '@material-ui/core';
import { Schedule, Event, Room, Category } from '@material-ui/icons';
import clsx from 'clsx';

import { useAuth } from 'hooks/auth';
import { getCurrencySymbol, formatNumber, intl } from 'helpers';
import { routes } from 'router/routesList';
import { UserTypeEnum } from 'types/common';
import { STATUSES, TALENT_STATUS } from 'constants/index';

import OpportunityStatus from 'components/OpportunityStatus';
import ImageGallery from 'components/ImageGallery';
import { ViewProfile } from 'components/Buttons/ViewProfile';

import { useStyles } from '../../styles';

const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env;

const TalentView = ({
  opportunity,
  toggleNotePopup,
  formatDate,
  hasEventTime,
  applyLoading,
  finalCondition,
  toggleCancellationPopup,
  toggleApplyingPopup,
  isValid,
}) => {
  const classes = useStyles();
  const { getRole } = useAuth();
  const isTalent = getRole() === UserTypeEnum.talent || getRole() === UserTypeEnum.agent;
  const canApply = opportunity.status.name === STATUSES.OPEN.value && opportunity.isApplied === TALENT_STATUS.NOT_APPLIED;

  const canCancel =
    isTalent &&
    opportunity.isApplied === TALENT_STATUS.APPLIED &&
    (opportunity.status.name === STATUSES.OPEN.value || opportunity.status.name === STATUSES.CONFIRMED.value);

  const budget = opportunity.finalCondition.talentSalary ?? opportunity.redemption?.talentSalary;
  const taxed = finalCondition.isTaxed ?? opportunity.redemption.isTaxed;

  const onApplyClick = () => {
    if (isValid) toggleNotePopup();
    else toggleApplyingPopup();
  };

  const brandsProfileLink = routes.TALENT_BRAND_PAGE(opportunity?.brand?.id, 'info');

  return (
    <>
      <div>
        <Hidden smUp>
          <>
            <Typography variant="body1">{opportunity.name}</Typography>
            <Box className={clsx(classes.mobileTitle, classes.titleTalent)}>
              <Box className={classes.taxInfo}>
                <Typography className={classes.mobileTitleTypo} variant="body1">
                  {`${getCurrencySymbol(opportunity.redemption.currency.code)} ${formatNumber(budget)} `}
                </Typography>
                <Typography variant="body1">
                  <small>{taxed ? intl('SHARED.taxIncluded') : intl('SHARED.taxExcluded')}</small>
                </Typography>
              </Box>

              <OpportunityStatus status={opportunity.status.name} />
            </Box>
            <Divider className={classes.mobileDivider} />
          </>
        </Hidden>
        <Hidden xsDown>
          <>
            <Box className={classes.detailsItem}>
              <Avatar
                className={classes.brandIcon}
                src={`${process.env.REACT_APP_AWSS3_URL_IMAGE_BEG}${opportunity.brand.image}`}
                alt={`${opportunity.name}'s_logo`}
              />
              <Typography variant="body1">{opportunity.brand.name}!</Typography>
            </Box>
            <ViewProfile link={brandsProfileLink} />
          </>
        </Hidden>
        <Typography variant="body2" className={clsx(classes.detailsItem, classes.margedTop)}>
          <Category fontSize="small" /> {opportunity.type.name}
        </Typography>
        <Typography variant="body2" className={classes.detailsItem}>
          <Room fontSize="small" /> {`${opportunity.country.name}, ${opportunity.city.name}`}
        </Typography>
        <Typography variant="body2" className={classes.detailsItem}>
          <Event fontSize="small" /> {`${formatDate(opportunity.startDate)} - ${formatDate(opportunity.endDate)}`}
        </Typography>
        {hasEventTime(opportunity.startDate, opportunity.endDate) && (
          <Typography variant="body2" className={classes.detailsItem}>
            <Schedule fontSize="small" /> {hasEventTime(opportunity.startDate, opportunity.endDate)}
          </Typography>
        )}
        <Divider />
        <Hidden xsDown>
          <Typography className={clsx(classes.budgetInfo, classes.budget)} variant="h5">
            <span>
              {`${getCurrencySymbol(opportunity.redemption.currency.code)} ${formatNumber(budget)} `}
            </span>

            <small>{taxed ? intl('SHARED.taxIncluded') : intl('SHARED.taxExcluded')}</small>
          </Typography>
          <Typography variant="caption" className={classes.postedDate}>
            {`${intl('OPPORTUNITY.posted')}: ${formatDate(opportunity.createdAt)}`}
          </Typography>
        </Hidden>
        <Hidden smUp>
          <Box className={clsx(classes.detailsItem, classes.mobileBrand)}>
            <Avatar
              className={classes.brandLargeIcon}
              src={`${REACT_APP_AWSS3_URL_IMAGE_BEG}${opportunity.brand.image}`}
              alt={`${opportunity.name}'s_logo`}
            />
            <div>
              <Typography variant="body1">{opportunity.brand.name}!!</Typography>
              <Typography variant="caption" className={classes.postedDate}>
                {`${intl('OPPORTUNITY.posted')}: ${formatDate(opportunity.createdAt)}`}
              </Typography>
            </div>
          </Box>
          <ViewProfile link={brandsProfileLink} />
          {opportunity.description && (
            <Typography variant="body1" className={classes.descriptionTitle}>
              {intl('SHARED.description')}
            </Typography>
          )}
        </Hidden>
        <Hidden smUp>
          <Typography
            className={clsx(classes.mobileDesc, {
              [classes.emptyBlock]: !opportunity.description,
            })}
          >
            {opportunity.description || intl('SHARED.noDescription')}
          </Typography>
          {!!opportunity.images.length && (
            <ImageGallery className={classes.imagesBox} images={opportunity.images.map((src: string) => REACT_APP_AWSS3_URL_IMAGE_BEG + src)} />
          )}
        </Hidden>
      </div>
      {canCancel && (
        <Button onClick={toggleCancellationPopup} fullWidth className={classes.btnCancel}>
          {intl('OPPORTUNITY.cancelApplicationText')}
        </Button>
      )}
      {isTalent && canApply && (
        <Button
          fullWidth
          disabled={applyLoading || opportunity.isApplied !== TALENT_STATUS.NOT_APPLIED}
          className={classes.btnApply}
          onClick={onApplyClick}
        >
          {`${opportunity.isApplied === TALENT_STATUS.NOT_APPLIED ? intl('OPPORTUNITY.apply') : intl('OPPORTUNITY.applied')}`}
        </Button>
      )}
    </>
  );
};

export default TalentView;

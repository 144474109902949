import { makeStyles } from '@material-ui/core';
import { colors } from '../../theme/colors';

export const useStyles = makeStyles((theme) => ({
  profileCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 280,
    minHeight: 240,
    padding: 32,
    paddingBottom: 0,
    borderRadius: 8,
    background: colors.WHITE,
    boxShadow: '0px 6px 20px rgba(2, 1, 86, 0.05)',
    top: '88px',
  },
  companyLogoContainer: {
    position: 'relative',
    paddingBottom: 32,
  },
  companyLogo: {
    width: 176,
    height: 176,
    borderRadius: '50%',
    color: colors.WHITE,
    backgroundColor: colors.PRIMARY,
    textTransform: 'uppercase',
    border: 'none',
    margin: 'auto',
    textAlign: 'center',
  },
  imageBtn: {
    maxWidth: 40,
    height: 40,
    borderRadius: '50%',
    position: 'absolute',
    right: 15,
    bottom: 35,
    backgroundColor: colors.WHITE,
  },
  profiles: {
    padding: '12px 0',
    borderTop: `1px solid ${colors.PROFILE_BORDER}`,
    borderBottom: `1px solid ${colors.PROFILE_BORDER}`,
  },
  logout: {
    padding: '12px 0',
  },
  profileCardBtn: {
    height: 48,
    width: '100%',
  },
  opName: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
  },
  appliedFor: {
    textAlign: 'center',
    marginTop: theme.spacing(3),
  },
  btn: {
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginBottom: theme.spacing(3),
    },
  },
  loaderContainer: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
}));

import React from 'react';
import { Avatar, Typography, Box, Divider } from '@material-ui/core';
import { LocationOn, Business } from '@material-ui/icons';
import clsx from 'clsx';
import { useQuery } from '@apollo/client';

import { intl } from 'helpers';
import { GET_BRAND_BY_ID } from 'services/graphql/brand';

import { useStyles } from './styles';

const BrandCard = ({ profile, brandFrontman, brandId, isTalentView }) => {
  const classes = useStyles();
  const { brandName, brandImage } = profile;

  const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env;

  const { data, loading } = useQuery(GET_BRAND_BY_ID, {
    variables: { brandId },
    skip: !isTalentView,
  });

  return (
    <Box className={classes.container}>
      <Box className={classes.box}>
        {brandFrontman && (
          <Box className={classes.flexBox}>
            <Avatar className={classes.avatar} src={`${REACT_APP_AWSS3_URL_IMAGE_BEG}${brandFrontman?.image}`} />
            <Typography>{`${brandFrontman?.firstName} ${brandFrontman?.lastName}`}</Typography>
          </Box>
        )}
        <Divider />
        <Box className={clsx(classes.flexBox, classes.brandName)}>
          <Avatar className={classes.avatar} src={`${REACT_APP_AWSS3_URL_IMAGE_BEG}${brandImage}`} />
          <Typography>{brandName}</Typography>
        </Box>
        <Box className={classes.brandBox}>
          {!loading && (
            <>
              <Box className={classes.flexBox}>
                <Business fontSize="small" className={classes.icon} />
                <Typography variant="body2">{data?.getBrand.industry.name}</Typography>
              </Box>
              <Box className={classes.flexBox}>
                <LocationOn fontSize="small" className={classes.icon} />
                <Typography variant="body2">{`${data?.getBrand.country.name}, ${data?.getBrand.city.name}`}</Typography>
              </Box>
              <Typography className={classes.aboutTitle}>{intl('SHARED.about')}</Typography>
              <Typography className={classes.description} variant="body2">
                {data?.getBrand.about}
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BrandCard;

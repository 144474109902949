import { makeStyles } from '@material-ui/core'
import { colors } from '../../../theme/colors'

export const useStyles = makeStyles(() => ({
  paper: {
    padding: 30,
    paddingBottom: 22,
    paddingTop: 56,
    display: 'flex',
    flexDirection: 'column',
    width: 460,
    maxWidth: '100%',
    marginTop: 24,
  },
  send: {
    marginTop: 16,
    marginBottom: 16,
  },
  verificationCode: {
    marginTop: 27,
    marginBottom: 24,
    fontWeight: 500,
  },
  text: {
    fontSize: 12,
    marginBottom: 12,
    textAlign: 'center',
  },
  dontReceive: {
    color: colors.GRAY,
    textDecoration: 'none',
    cursor: 'pointer',
    fontSize: '0.75rem',
  },
  helperTextAlign: {
    textAlign: 'right',
  },
  dontReceiveContainer: {
    marginTop: -2,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dontReceiveContainerError: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

import { makeStyles } from '@material-ui/core';
import { colors } from 'theme/colors';

export const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    padding: theme.spacing(4),
    [theme.breakpoints.only('xs')]: {
      padding: 0,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
    },
  },
  header: {
    background: '#f3f3f4',
    [theme.breakpoints.up('sm')]: {
      background: colors.WHITE,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  title: {
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
  error: {
    color: colors.ERROR,
  },
  tabRoot: {
    [theme.breakpoints.up('sm')]: {
      minWidth: 100,
    },
  },
  selectContainer: {
    [theme.breakpoints.up('md')]: {
      paddingTop: '10px!important',
    },
  },
  indicator: {
    minWidth: 100,
  },
  imagesBox: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  divider: {
    marginTop: -1,
    marginBottom: theme.spacing(4),
  },
  taxText: {
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: colors.ERROR,
  },
}));

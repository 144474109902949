import React, { useState, useCallback } from 'react';
import * as Yup from 'yup';

import { Box, Grid, Paper, TextField, Button, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

import { useAuth } from 'hooks/auth';
import { intl } from 'helpers';
import { routes } from 'router/routesList';
import { LoginIcon } from 'assets/svg-js';

import { TextFieldPassword } from 'components/TextFieldPassword';
import { SportEndorse } from 'components/SportEndorse';

import { useStyles } from './styles';

const LoginSchema = Yup.object({
  email: Yup.string().email('ERRORS.incorrectEmail').required('ERRORS.emptyField'),
  password: Yup.string()
    // .matches(/((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,})/, 'ERRORS.incorrectPasswordFormat')
    .required('ERRORS.emptyField'),
});

export const Login = () => {
  const styles = useStyles({});
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { signIn } = useAuth();

  const [isPasswordShown, setPasswordShown] = useState(false);
  const togglePasswordShown = useCallback(() => setPasswordShown((passwordStatus) => !passwordStatus), []);

  const goToSignUp = (role) => () => history.push(`/${routes.SIGN_UP}/${role}`);

  const handleSignIn = async (values, formContext) => {
    const lowerCaseEmail = String(values.email).toLowerCase();
    setLoading(true);
    const { error } = await signIn({
      email: lowerCaseEmail,
      password: values.password,
      redirectUrl: '/',
    });
    setLoading(false);
    if (error && error.name === 'UserNotFoundException') {
      formContext.setFieldError('email', 'ERRORS.emailIsNotFound');
    }
    if (error && error.name === 'UserNotConfirmedException') {
      history.push(`/confirm?email=${lowerCaseEmail}`);
    }
    if (error && error.name === 'NotAuthorizedException') {
      if (error.message === 'Password attempts exceeded') {
        formContext.setFieldError('email', 'ERRORS.incorrectEmailOrPassword');
        formContext.setFieldError('password', 'ERRORS.incorrectEmailOrPassword');
      } else {
        formContext.setFieldError('email', 'ERRORS.incorrectEmailOrPassword');
        formContext.setFieldError('password', 'ERRORS.incorrectEmailOrPassword');
      }
    }
  };

  const { handleChange, values, errors, touched, handleSubmit } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: handleSignIn,
  });

  return (
    <Box display="flex" alignItems="center" justifyContent="center" minHeight="100vh" padding="30px" paddingX="8px" flexDirection="column">
      <SportEndorse />
      <Paper className={styles.paper} component="form" onSubmit={handleSubmit}>
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" marginBottom="38px">
          <LoginIcon />
          <Typography className={styles.loginText} variant="h5">
            {intl('AUTH.logIn')}
          </Typography>
        </Box>
        <TextField
          name="email"
          label={intl('SHARED.email')}
          className={styles.input}
          value={values.email}
          onChange={handleChange}
          error={errors.email && touched.email}
          helperText={intl(touched.email && errors.email)}
        />
        <TextFieldPassword
          togglePassword={togglePasswordShown}
          showPassword={isPasswordShown}
          label={intl('SHARED.password')}
          name="password"
          value={values.password}
          onChange={handleChange}
          className={styles.input}
          error={errors.password && touched.password}
          helperText={intl(touched.password && errors.password)}
          withForget
        />
        <Button type="submit" variant="contained" disabled={loading} color="primary" className={styles.login}>
          {intl('AUTH.logIn')}
        </Button>
        <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={12} sm={6}>
            <Button onClick={goToSignUp('brand')} variant="outlined" fullWidth>
              {intl('SIGN_UP.asBrand')}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button onClick={goToSignUp('talent')} variant="outlined" fullWidth>
              {intl('SIGN_UP.asTalent')}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import clsx from 'clsx';
import { useMutation } from '@apollo/client';
import intlUniversal from 'react-intl-universal';
import { useHistory } from 'react-router-dom';

import { GoogleCalendarIcon } from 'assets/svg-js';
import { intl } from 'helpers';
import { UPDATE_LINKED_ACCOUNT } from 'services/graphql/talent';

import { useStyles } from './styles';
import ConfirmationModal from './components/ConfirmationModal';
import { SOCIAL_PROVIDER, GOOGLE_CALENDAR_SCOPES } from '../../constants';

const { REACT_APP_GOOGLE_CLIENT_ID } = process.env;
const CODE_TEXT = '?code=';

const GoogleCalendarPanel = ({ className, connected, accountName = '', setStatus = () => {} }) => {
  const classes = useStyles();
  const { location, replace } = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [hasError, setError] = useState(false);

  const [connectGoogleAccount, { loading }] = useMutation(UPDATE_LINKED_ACCOUNT, {
    onCompleted: ({ updateLinkedAccount }) => {
      if (updateLinkedAccount.accountName) {
        setStatus(true, updateLinkedAccount.accountName);
        replace({ pathname: location.pathname });
      } else setStatus(false, null);
    },
    onError: (err) => {
      replace({ pathname: location.pathname });
      // eslint-disable-next-line no-console
      console.error('[Connect mutation error]', err);
    },
  });

  const toggleModal = () => setOpenModal((old) => !old);

  useEffect(() => {
    if (location.search.includes(CODE_TEXT)) {
      const code = location.search.substring(CODE_TEXT.length).split('&')[0];
      connectGoogleAccount({
        variables: {
          provider: SOCIAL_PROVIDER.GOOGLE,
          payload: JSON.stringify({ client: 'web', code, redirectUrl: window.location.href.split('?')[0] }),
        },
      });
    }
  }, []);

  const signOut = async () => {
    await connectGoogleAccount({
      variables: { provider: SOCIAL_PROVIDER.GOOGLE, payload: 'disconnect' },
    });
    setOpenModal(false);
  };

  const signIn = () => {
    const endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';
    const args = [
      'response_type=code',
      'access_type=offline',
      'prompt=consent',
      'include_granted_scopes=true',
      `client_id=${REACT_APP_GOOGLE_CLIENT_ID}`,
      `scope=${GOOGLE_CALENDAR_SCOPES}`,
      `redirect_uri=${window.location.href}`,
    ];
    window.location.href = `${endpoint}?${args.join('&')}`;
  };

  const onClickAction = connected ? () => toggleModal() : () => signIn();

  return (
    <>
      <Box className={clsx(classes.integrationBox, className)}>
        <Box className={classes.content}>
          <Box className={classes.googleBox}>
            <Box className={classes.googleBoxIconWrapper}>
              <GoogleCalendarIcon className={classes.googleBoxIcon} />
            </Box>
            <Typography className={classes.googleBoxText}>
              {connected ? intlUniversal.get('CALENDAR.googleCalendarDisconnect', { accountName }) : intl('CALENDAR.googleCalendarConnect')}
            </Typography>
          </Box>
          {!loading ? (
            <Button className={classes.btnConnect} onClick={onClickAction}>
              {connected ? intl('SHARED.remove') : intl('SHARED.connect')}
            </Button>
          ) : (
            <Button className={classes.btnConnect} disabled>
              {intl('SHARED.loading')}
            </Button>
          )}
        </Box>
        {hasError && (
          <Typography className={classes.errorText} variant="caption">
            {intl('ERROR.tryAgain')}
          </Typography>
        )}
      </Box>
      <ConfirmationModal open={openModal} loading={loading} handleClose={toggleModal} signOut={signOut} />
    </>
  );
};

export default GoogleCalendarPanel;

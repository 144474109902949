import { ConversationMessages, Offer } from './message';
import { Meta } from './common';
import { Opportunity } from './opportunity';
import { VerificationRequestStatusesEnum } from './talent';

export enum NotificationEventEnum {
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export enum NotificationAction {
  pay = 'pay',
  rate = 'rate',
  ratePay = 'rate_pay',
}

export interface NotificationInterface {
  id: string;
  eventType: string;
  createdAt: number | Date;
  message: string | ConversationMessages;
  readAt: number;
  __typename?: string;
}

export interface SystemNotification extends NotificationInterface {
  userId: string;
  code: string;
  image: string;
}

export interface MessageNotification extends NotificationInterface {
  userId: string;
  message: ConversationMessages | string;
  image: string;
  opportunityId: string;
}

export interface OfferNotification extends NotificationInterface {
  userId: string;
  offer: Offer;
  image: string;
}

export interface OpportunityNotification extends NotificationInterface {
  userId: string;
  image: string;
  opportunity: Opportunity;
  message: ConversationMessages;
  actionCode: string;
}

export interface BrandFeedbackNotification extends NotificationInterface {
  userId: string;
  brand: {};
  opportunity: Opportunity;
  image: string;
  readAt: number;
}

export interface TalentFeedbackNotification extends NotificationInterface {
  userId: string;
  talent: {};
  opportunity: Opportunity;
  image: string;
  readAt: number;
}

export interface UserVerificationNotification extends NotificationInterface {
  userId: string;
  verificationStatus: VerificationRequestStatusesEnum;
  image: string;
  readAt: number;
}

export interface UserNotifications {
  listUserNotifications: {
    items: Array<
      | SystemNotification
      | MessageNotification
      | OfferNotification
      | OpportunityNotification
      | BrandFeedbackNotification
      | TalentFeedbackNotification
      | UserVerificationNotification
    >;
    meta: Meta;
    __typename: string;
  };
}

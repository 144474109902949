/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useRef } from 'react';
import { Box, Paper, TextField, Divider, Grid, Button, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import { useQuery, useMutation } from '@apollo/client';
import { useLocation } from 'react-router-dom';

import { StripeConnect } from 'components/StripeConnect';
import { InvitationModal } from 'components/Modals/InvitationModal';
import { SaveBar } from 'components/SaveBar';
import { Loader } from 'components/Loader';
import GoogleCalendarPanel from 'components/GoogleCalendarPanel';

import { routes } from 'router/routesList';
import { intl, findSocialProvider } from 'helpers';
import { SOCIAL_PROVIDER } from 'constants/index';
import { GET_CURRENT_TALENT, UPDATE_TALENT_PRIVATE_PROFILE, INVITE_AGENT, DELETE_AGENT } from 'services/graphql/talent';
import { useAuth } from 'hooks/auth';
import { useErrorHandler } from 'hooks/app/errorHandler';
import { useLink } from 'hooks/linkTo';
import { RemoveAgentModal } from 'components/Modals/RemoveAgentModal';

import { SupportAgent } from 'assets/svg-js';
import { useStyles } from './styles';

export const PrivateInfo = () => {
  const [isInvitationModal, setInvitationModal] = useState(false);
  const [isRemoveAgentModal, setRemoveAgentModal] = useState(false);
  const isShowButtons = useRef(false);
  const styles = useStyles({});
  const { user } = useAuth();
  const linkTo = useLink();
  const { state } = useLocation();
  const [responseError, setResponseError] = useState('');
  const { getError } = useErrorHandler();

  const isRequiredField = !!state?.referrer;
  const displayError = isRequiredField && intl('ERRORS.required');

  const toggleAgentModal = () => setRemoveAgentModal((v) => !v);

  const [updateTalentPrivateProfile, { loading: talentUpdating }] = useMutation(UPDATE_TALENT_PRIVATE_PROFILE, {
    onCompleted({ updateTalentPrivateProfile: uTalentPrivateProfile }) {
      setValues({
        phone: uTalentPrivateProfile?.phone,
        email: uTalentPrivateProfile?.email,
        accountName: uTalentPrivateProfile?.payout?.wire?.accountName,
        iban: uTalentPrivateProfile?.payout?.wire?.iban,
        google: {
          connected: !!findSocialProvider(uTalentPrivateProfile.social, SOCIAL_PROVIDER.GOOGLE)?.accountName?.length,
          accountName: findSocialProvider(uTalentPrivateProfile.social, SOCIAL_PROVIDER.GOOGLE)?.accountName,
        },
      });
      if (state?.fromSignUp) {
        linkTo(`/${routes.TALENT}/${routes.OPPORTUNITIES}`)();
      } else if (state?.referrer) {
        linkTo(state.referrer)();
        delete state.referrer;
      } else {
        linkTo(routes.TALENT_PUBLIC_PAGE);
      }
    },
  });

  const { loading: talentLoading, data } = useQuery(GET_CURRENT_TALENT, {
    fetchPolicy: 'network-only',
    onCompleted({ getCurrentTalent }) {
      setValues({
        phone: getCurrentTalent?.phone ?? '',
        email: getCurrentTalent?.email,
        accountName: getCurrentTalent?.payout?.wire?.accountName ?? '',
        iban: getCurrentTalent?.payout?.wire?.iban ?? '',
        google: {
          connected: !!findSocialProvider(getCurrentTalent.social, SOCIAL_PROVIDER.GOOGLE)?.accountName?.length,
          accountName: findSocialProvider(getCurrentTalent.social, SOCIAL_PROVIDER.GOOGLE)?.accountName,
        },
      });
    },
  });

  const [sendInvitation, { loading }] = useMutation(INVITE_AGENT, {
    onError: (error) => {
      const normalizedErrorMessage = getError(error.message);
      setResponseError(normalizedErrorMessage);
    },
    onCompleted: () => {
      handleCloseInvitationModal();
    },
  });

  const [removeAgent, { loading: removeAgentLoading }] = useMutation(DELETE_AGENT, {
    update: (cache) => {
      const { getCurrentTalent } = cache.readQuery({ query: GET_CURRENT_TALENT });
      cache.writeQuery({
        query: GET_CURRENT_TALENT,
        data: {
          getCurrentTalent: {
            ...getCurrentTalent,
            agent: null,
          },
        },
      });
    },
  });

  const handleCloseInvitationModal = () => {
    setInvitationModal(false);
    setResponseError('');
  };
  const handleOpenInvitationModal = () => setInvitationModal(true);

  const submitInvitation = ({ email }) => {
    sendInvitation({
      variables: { email },
    });
  };

  const handleRemoveAgent = () => {
    setRemoveAgentModal(true);
  };

  const onRemoveAgent = () => {
    removeAgent({
      variables: {
        agentId: data?.getCurrentTalent?.agent?.id,
      },
    });
    toggleAgentModal();
  };

  const getInvitationOptions = data?.getCurrentTalent?.agent?.id
    ? {
        text: `${intl('TALENT.PRIVATE_INFO.alreadyInvited')} ${data?.getCurrentTalent?.agent?.email}`,
        handler: handleRemoveAgent,
        btnText: intl('TALENT.PRIVATE_INFO.removeButton'),
      }
    : { text: intl('TALENT.PRIVATE_INFO.inviteText'), handler: handleOpenInvitationModal, btnText: intl('TALENT.PRIVATE_INFO.inviteButton') };

  const handleUserUpdate = ({ phone, accountName, iban }) => {
    updateTalentPrivateProfile({
      variables: {
        phone,
        payout: {
          wire: {
            accountName: accountName?.trim(),
            iban: iban?.trim(),
          },
        },
      },
    });
    isShowButtons.current = false;
  };

  const onCancel = () => {
    if (state?.fromSignUp) linkTo(`/${routes.TALENT}/${routes.OPPORTUNITIES}`)();
    else if (state?.referrer) linkTo(state?.referrer)();
    else linkTo(routes.TALENT_PUBLIC_PAGE)();
  };

  const { handleChange, handleSubmit, values, setValues, setFieldValue } = useFormik({
    initialValues: {
      phone: '',
      accountName: '',
      iban: '',
      google: {
        connected: false,
        accountName: '',
      },
    },
    onSubmit: handleUserUpdate,
  });

  const handleFieldChange = (...param) => {
    isShowButtons.current = true;
    handleChange(...param);
  };

  const handleChangeGoogleStatus = (connected, accountName) => {
    setFieldValue('google', {
      connected,
      accountName,
    });
  };

  return (
    <>
      <Box className={styles.privateInfoFormAndNav}>
        <Box>
          <Paper className={styles.privateInfoForm}>
            {talentLoading ? (
              <Loader />
            ) : (
              <Grid container spacing={3}>
                <Grid item sm={6} xs={12}>
                  <TextField name="email" className={styles.input} value={user?.attributes?.email} disabled fullWidth label={intl('SHARED.email')} />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    name="phone"
                    error={isRequiredField && !values.phone}
                    helperText={!values.phone && displayError}
                    className={styles.input}
                    value={values.phone}
                    fullWidth
                    onChange={handleFieldChange}
                    label={intl('SHARED.phone')}
                  />
                </Grid>
              </Grid>
            )}
          </Paper>
          {!talentLoading && (
            <Paper className={styles.paymentContainer}>
              <StripeConnect
                error={isRequiredField}
                handleUserUpdate={() => handleUserUpdate(values)}
                stripeAccountId={data?.getCurrentTalent?.payout?.stripe?.accountId}
              />
              <Divider />
              <Grid container className={styles.paymentInputsContainer} spacing={3}>
                <Grid item sm={6} xs={12}>
                  <TextField
                    name="accountName"
                    error={!values.accountName && displayError}
                    helperText={!values.accountName && displayError}
                    className={styles.input}
                    value={values.accountName}
                    fullWidth
                    onChange={handleFieldChange}
                    label={intl('SHARED.accountName')}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    error={!values.iban && displayError}
                    helperText={!values.iban && displayError}
                    name="iban"
                    className={styles.input}
                    value={values.iban}
                    label={intl('SHARED.IBAN')}
                    onChange={handleFieldChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Paper>
          )}

          <Paper>
            <Box className={styles.inviteContainer}>
              <Grid item className={styles.iconContainer}>
                <SupportAgent />
              </Grid>
              <Grid component={Box} item style={{ flexGrow: 1 }}>
                <Typography className={styles.textContent}>{getInvitationOptions.text}</Typography>
              </Grid>
              <Grid item>
                <Button color="primary" className={styles.btn} onClick={getInvitationOptions.handler}>
                  {getInvitationOptions.btnText}
                </Button>
              </Grid>
            </Box>
          </Paper>

          {!talentLoading && (
            <GoogleCalendarPanel connected={values.google.connected} accountName={values.google.accountName} setStatus={handleChangeGoogleStatus} />
          )}
        </Box>
      </Box>
      <Box className={styles.saveBarContainer}>
        {!isShowButtons.current && !state?.fromSignUp && !state?.referrer ? (
          <></>
        ) : (
          <SaveBar isSignUp={state?.fromSignUp} loading={talentUpdating} onSave={handleSubmit} onCancel={onCancel} />
        )}
      </Box>
      <InvitationModal
        loading={loading}
        isOpen={isInvitationModal}
        handleClose={handleCloseInvitationModal}
        title={intl('INVITATION_MODAL.agent')}
        bodyText={intl('INVITATION_MODAL.provideAgent')}
        label={intl('INVITATION_MODAL.labelAgent')}
        submit={submitInvitation}
        responseError={responseError}
      />
      <RemoveAgentModal open={isRemoveAgentModal} loading={removeAgentLoading} handleClose={toggleAgentModal} handleConfirm={onRemoveAgent} />
    </>
  );
};

import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../../../../theme/colors'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: 6,
    background: colors.BACKGROUND,
  },
  table: {
    minWidth: 650,
    borderCollapse: 'separate',
    borderSpacing: `${0}px ${theme.spacing(1)}px`,
  },
  row: {
    cursor: 'pointer',
    '&:hover': {
      // transform: 'scale(1.01)',
      // boxShadow: '0px 6px 20px rgba(2, 1, 86, 0.1)',
    },
  },
  thCell: {
    paddingBottom: 0,
    paddingTop: 0,
    borderBottom: 0,
    opacity: 0.4,
    fontSize: 12,
  },
  nameCell: {
    width: 230,
  },
  currency: {
    paddingRight: 6,
    opacity: 0.6,
  },
  tdBody: {
    height: 73,
    fontSize: 12,
    backgroundColor: colors.WHITE,
    '&:first-child': {
      borderRadius: '8px 0 0 8px',
    },
    '&:last-child': {
      borderRadius: '0 8px 8px 0',
    },
  },
}))

export { useStyles }

import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';

import { useAuth } from 'hooks/auth';
import { UserTypeEnum } from 'types/common';

import { LIST_COUNTRIES } from 'services/graphql/location';
import { GET_OPPORTUNITY_FOR_EDIT, OPPORTUNITY_TYPES, LIST_CURRENCIES } from 'services/graphql/query/opportunity';
import { GET_BRAND_USER } from 'services/graphql/brand';
import { LIST_SPORTS } from 'services/graphql/sports';
import { useBrandsErrors } from 'hooks/brand';

import { Loader } from 'components/Loader';

import Form from './Form';
import { STATUSES } from '../../../../../constants';

const Wrapper = () => {
  const { id } = useParams();
  const { push } = useHistory();
  const { getRole } = useAuth();
  const [verifyModal, setVerifyModal] = useState(false);
  const { brandErrors, brandLoading } = useBrandsErrors();

  const userRole = getRole();
  const isAdmin = userRole === UserTypeEnum.admin;
  const isBrand = userRole === UserTypeEnum.brand;

  const toggleVerifyModal = () => setVerifyModal((old) => !old);

  const { data: opportunityData, loading: opportunityLoading } = useQuery(GET_OPPORTUNITY_FOR_EDIT, {
    variables: { opportunityId: id },
    fetchPolicy: 'no-cache',
  });

  const { data: currentUserBrand, loading: loadingCurrentUserBrand } = useQuery(GET_BRAND_USER, {
    skip: !isBrand,
  });

  const { data: types, loading: typesLoading } = useQuery(OPPORTUNITY_TYPES);

  const { data: currencies, loading: currenciesLoading } = useQuery(LIST_CURRENCIES);

  const { data: countries, loading: countriesLoading } = useQuery(LIST_COUNTRIES);

  const { data: sports, loading: sportsLoading } = useQuery(LIST_SPORTS);

  if (opportunityLoading || loadingCurrentUserBrand || typesLoading || currenciesLoading || countriesLoading || sportsLoading || brandLoading)
    return <Loader />;

  const isSameBrand = currentUserBrand?.getBrandUser?.brandId === opportunityData?.getOpportunity?.brand?.id;

  const isOpportunityOpen = opportunityData?.getOpportunity?.status.name === STATUSES.OPEN.value;

  const canRenew =
    opportunityData?.getOpportunity?.status.name === STATUSES.CANCELED_BY_TALENT.value ||
    opportunityData?.getOpportunity?.status.name === STATUSES.EXPIRED.value;

  if (!isAdmin && !(isBrand && isSameBrand) && !canRenew && !isOpportunityOpen) {
    push('/');
  }

  const props = {
    opportunity: opportunityData.getOpportunity,
    types: types.opportunityTypes,
    currencies: currencies.listCurrencies,
    countries: countries.listCountries,
    sports: sports.listSports,
    verifyModalProps: {
      brandErrors,
      verifyModal,
      toggleVerifyModal,
    },
    isAdmin,
  };

  return <Form {...props} />;
};

export default Wrapper;

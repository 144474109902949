import React, { FC, useState, MouseEvent, useContext, useRef, useEffect } from 'react';
import { useLazyQuery, useMutation, ApolloCache, useApolloClient } from '@apollo/client';
import { Paper, Box, Avatar, Divider, Typography, Menu, MenuItem, IconButton, Button, Collapse, Hidden } from '@material-ui/core';
import { MoreVert as MoreVertIcon, ThumbUp as ThumbUpIcon, ThumbUpOutlined as ThumbUpOutlinedIcon, Share as ShareIcon } from '@material-ui/icons';
import clsx from 'clsx';
import { Link, useHistory } from 'react-router-dom';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  ViberShareButton,
  ViberIcon,
} from 'react-share';
import { format } from 'date-fns';

import { intl, formatNumber } from 'helpers';
import { AppContext } from 'hooks/app';
import { routes } from 'router/routesList';
import type { PostFeed } from 'types/post';
import { ImagesCarousel } from 'components/ImagesCarousel';
import { Loader } from 'components/Loader';
import { SHARE_LINK } from 'services/graphql/query/sharing';
import { UPDATE_USER_FAVOURITE } from 'services/graphql/brand';

import { useStyles } from './styles';

type UserRole = 'talent' | 'brand' | 'admin' | 'none';

type Props = {
  post: PostFeed;
  userRole?: UserRole;
  className?: string;
  onSuccessLike?: (cache: ApolloCache<any>, entityId: string) => void;
  onSuccessDelete?: (entityId: string) => void;
  onSuccessDeleteSingle?: () => void;
  onSuccessShare?: (entityId: string) => void;
  onSuccessShareSingle?: (cache: ApolloCache<any>) => void;
};

const TEXT_BASE_HEIGHT = 100;
// const LONG_TEXT_LENGTH = 615;

const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env;

const InfoPost: FC<Props> = ({
  post,
  userRole,
  className,
  onSuccessLike,
  onSuccessDelete,
  onSuccessShare,
  onSuccessShareSingle = null,
  onSuccessDeleteSingle = null,
}) => {
  const styles = useStyles();
  const { push } = useHistory();
  const client = useApolloClient();
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>(null);

  const [anchorUserMenu, setAnchorUserMenu] = useState<null | HTMLElement>(null);
  const [anchorShareMenu, setAnchorShareMenu] = useState<null | HTMLElement>(null);
  const [fullText, setFullText] = useState<boolean>(false); // * 455 symbols
  const openUserMenu = !!anchorUserMenu;
  const openShareMenu = !!anchorShareMenu;
  const [getSharingLink, { data }] = useLazyQuery(SHARE_LINK, {
    onError: () => setAnchorShareMenu(null),
    onCompleted: () => {
      try {
        if (onSuccessShareSingle) {
          onSuccessShareSingle(client.cache);
        } else {
          onSuccessShare(post.id);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
    fetchPolicy: 'network-only',
  });
  const [updateFavorite] = useMutation(UPDATE_USER_FAVOURITE, {
    variables: {
      entityType: 'post',
      entityId: post.id,
    },
    optimisticResponse: {
      __typename: 'Mutation',
      updateUserFavourite: {
        __typename: 'UserFavourite',
        entityType: 'post',
        entityId: post.id,
      },
    },
    update: (cache) => {
      try {
        onSuccessLike(cache, post.id);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
  });

  const { dispatch } = useContext(AppContext);
  const formatDate = (date: string | Date | number) => format(new Date(date), 'dd.MM.yy');

  const getEditPostRoute = (role: UserRole) =>
    ({
      talent: `${routes.TALENT_EDIT_POST}/${post.id}`,
      admin: `${routes.ADMIN_EDIT_POST}/${post.id}`,
      brand: `${routes.BRAND_EDIT_POST}/${post.id}`,
      none: routes.LOGIN,
    }[role] || routes.LOGIN);

  const toggleText = () => setFullText((old) => !old);

  const handleUserMenuClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorUserMenu(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setAnchorUserMenu(null);
  };

  const handleShareMenuClick = (event: MouseEvent<HTMLElement>) => {
    if (userRole === 'none') {
      push(routes.LOGIN);
    } else {
      getSharingLink({ variables: { id: post.id } });
      setAnchorShareMenu(event.currentTarget);
    }
  };

  const handleShareMenuClose = (e?: any) => {
    if (e) {
      e.preventDefault();
    }
    setAnchorShareMenu(null);
  };

  const onDelete = () => {
    handleShareMenuClose();
    dispatch({
      type: 'DELETE_POST_MODAL',
      payload: {
        open: true,
        postId: post.id,
        onSuccess: () => {
          if (onSuccessDeleteSingle) {
            onSuccessDeleteSingle();
          } else {
            onSuccessDelete(post.id);
          }
        },
      },
    });
  };

  const onLike = () => {
    if (userRole === 'none') {
      push(routes.LOGIN);
    } else {
      updateFavorite();
    }
  };

  useEffect(() => {
    setHeight(ref.current.clientHeight);
  }, [post.description]);

  const shareUrl = data?.getSharePostLink;

  return (
    <Paper className={clsx(styles.container, className)}>
      <Hidden only="xs">
        <Box className={styles.heading}>
          <Box className={styles.userBox}>
            <Avatar src={REACT_APP_AWSS3_URL_IMAGE_BEG + post.author.imageSrc}>{post.author.firstLetters}</Avatar>
            <Typography className={styles.username}>{post.author.authorName}</Typography>
          </Box>
          <Box>
            <Typography className={styles.postedAt} variant="caption">
              {`${intl('POST.posted')} ${formatDate(post.createdAt)}`}
            </Typography>
            {post.canInteract && (
              <>
                <IconButton aria-label="user-actions" aria-controls={`post-${post.id}-menu`} aria-haspopup="true" onClick={handleUserMenuClick}>
                  <MoreVertIcon
                    className={clsx({
                      [styles.opened]: openUserMenu,
                    })}
                  />
                </IconButton>
                <Menu
                  classes={{ list: styles.userListMenu }}
                  id={`post-${post.id}-menu`}
                  anchorEl={anchorUserMenu}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  open={openUserMenu}
                  onClose={handleUserMenuClose}
                >
                  <MenuItem component={Link} to={getEditPostRoute(userRole)}>
                    {intl('POST.edit')}
                  </MenuItem>
                  <MenuItem onClick={onDelete}>{intl('POST.delete')}</MenuItem>
                </Menu>
              </>
            )}
          </Box>
        </Box>
      </Hidden>
      <Hidden smUp>
        <Box className={styles.headingMobile}>
          <Box className={styles.headingMobileWrapper}>
            <Avatar className={styles.avatar} src={REACT_APP_AWSS3_URL_IMAGE_BEG + post.author.imageSrc}>
              {post.author.firstLetters}
            </Avatar>
            <Box>
              <Typography>{post.author.authorName}</Typography>
              <Typography className={styles.postedAt} variant="caption">{`${intl('POST.posted')} ${formatDate(post.createdAt)}`}</Typography>
            </Box>
          </Box>
          {post.canInteract && (
            <>
              <IconButton aria-label="user-actions" aria-controls={`post-${post.id}-menu`} aria-haspopup="true" onClick={handleUserMenuClick}>
                <MoreVertIcon
                  className={clsx({
                    [styles.opened]: openUserMenu,
                  })}
                />
              </IconButton>
              <Menu
                classes={{ list: styles.userListMenu }}
                id={`post-${post.id}-menu`}
                anchorEl={anchorUserMenu}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                open={openUserMenu}
                onClose={handleUserMenuClose}
              >
                <MenuItem component={Link} to={getEditPostRoute(userRole)}>
                  {intl('POST.edit')}
                </MenuItem>
                <MenuItem onClick={onDelete}>{intl('POST.delete')}</MenuItem>
              </Menu>
            </>
          )}
        </Box>
      </Hidden>
      <Divider />
      <Box className={styles.content}>
        <Box className={styles.textBox}>
          {height > TEXT_BASE_HEIGHT ? (
            <>
              <Collapse in={fullText} collapsedHeight={TEXT_BASE_HEIGHT} classes={{ container: styles.collapse }}>
                <Typography className={styles.text} variant="body2">
                  {post.description}
                </Typography>
              </Collapse>
              <Button className={styles.btnMore} color="primary" variant="text" onClick={toggleText}>
                {fullText ? intl('POST.showLess') : intl('POST.showMore')}
              </Button>
            </>
          ) : (
            <Typography className={styles.shortText} variant="body2" ref={ref}>
              {post.description ?? intl('SHARED.noDescription')}
            </Typography>
          )}
        </Box>
        {post.images?.length > 0 && <ImagesCarousel images={post.images} />}
      </Box>
      <Box className={styles.actions} component="ul">
        <Box className={styles.socialItem} component="li">
          <IconButton onClick={onLike}>
            {post.isLiked ? <ThumbUpIcon className={styles.filledIcon} /> : <ThumbUpOutlinedIcon className={styles.filledIcon} />}
          </IconButton>
          <Typography variant="body2">{post.feedMeta?.likesCounter ? formatNumber(post.feedMeta?.likesCounter) : null}</Typography>
        </Box>
        <Box className={styles.socialItem} component="li">
          <IconButton
            onClick={handleShareMenuClick}
            aria-label="user-share-actions"
            aria-controls={`post-${post.id}-share-menu`}
            aria-haspopup="true"
          >
            <ShareIcon
              className={clsx(
                {
                  [styles.opened]: openShareMenu,
                },
                styles.filledIcon,
              )}
            />
          </IconButton>
          <Menu
            classes={{ list: styles.userListMenu }}
            id={`post-${post.id}-share-menu`}
            anchorEl={anchorShareMenu}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={openShareMenu}
            onClose={handleShareMenuClose}
          >
            {!shareUrl ? (
              <Loader />
            ) : (
              <>
                <MenuItem onClick={handleShareMenuClose} className={styles.shareAction}>
                  <FacebookShareButton url={shareUrl} className={styles.shareActionButton}>
                    {'  '}
                    <FacebookIcon size={14} round /> Facebook
                  </FacebookShareButton>
                </MenuItem>
                <MenuItem onClick={handleShareMenuClose} className={styles.shareAction}>
                  <TwitterShareButton url={shareUrl} className={styles.shareActionButton}>
                    {'  '}
                    <TwitterIcon size={14} round /> Twitter
                  </TwitterShareButton>
                </MenuItem>
                <MenuItem onClick={handleShareMenuClose} className={styles.shareAction}>
                  <WhatsappShareButton url={shareUrl} className={styles.shareActionButton}>
                    {'  '}
                    <WhatsappIcon size={14} round /> WhatsApp
                  </WhatsappShareButton>
                </MenuItem>
                <MenuItem onClick={handleShareMenuClose} className={styles.shareAction}>
                  <LinkedinShareButton url={shareUrl} className={styles.shareActionButton}>
                    {'  '}
                    <LinkedinIcon size={14} round /> LinkedIn
                  </LinkedinShareButton>
                </MenuItem>
                <MenuItem onClick={handleShareMenuClose} className={styles.shareAction}>
                  <TelegramShareButton url={shareUrl} className={styles.shareActionButton}>
                    {'  '}
                    <TelegramIcon size={14} round /> Telegram
                  </TelegramShareButton>
                </MenuItem>
                <MenuItem onClick={handleShareMenuClose} className={styles.shareAction}>
                  <ViberShareButton url={shareUrl} className={styles.shareActionButton}>
                    {'  '}
                    <ViberIcon size={14} round /> Viber
                  </ViberShareButton>
                </MenuItem>
              </>
            )}
          </Menu>
          <Typography variant="body2">{post.feedMeta?.sharesCounter ? formatNumber(post.feedMeta?.sharesCounter) : null}</Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export { InfoPost };

import { makeStyles } from '@material-ui/core';
import { colors } from 'theme/colors';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    minHeight: 'calc(100vh - 138px)',
    [theme.breakpoints.only('xs')]: {
      minHeight: 'calc(100vh - 149px)',
    },
  },
  container: {
    width: '100%',
    paddingLeft: 0,
    paddingRight: 0,
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
      marginTop: 0,
      display: 'block',
    },
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down(580)]: {
      display: 'none',
    },
  },
  title: {
    marginRight: theme.spacing(2),
    fontSize: 18,
    lineHeight: '27px',
  },
  indicator: {
    width: 79,
  },
  divider: {
    marginTop: -1,
  },
  tab: {
    [theme.breakpoints.up('sm')]: {
      minWidth: 47,
      fontSize: 12,
    },
  },
  content: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  withPayPanel: {
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 213px)!important',
    },
  },
  emptyBoxWrapper: {
    minHeight: 'calc(100vh - 168px)',
  },
  emptyBox: {
    margin: '0 auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      marginTop: -50,
    },
  },
  emptyText: {
    marginTop: -86,
    fontWeight: 500,
    fontSize: 24,
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
      marginTop: -100,
      marginBottom: theme.spacing(2),
    },
  },
  emptyIcon: {
    [theme.breakpoints.only('xs')]: {
      width: 305,
    },
  },
  sun: {
    fill: colors.BACKGROUND,
  },
  ground: {
    fill: colors.WHITE,
  },
}));

import { ReactNode } from 'react';

type BrandData = {
  brandId: string;
  brandName: string;
  brandImage?: string;
};

type BrandWorkedFor = BrandData & {
  opportunityCompleteCount: number;
};

type BrandAgreementSent = BrandData & {
  opportunityId?: string;
  opportunityName?: string;
};

type BrandProfileView = BrandData & {
  dateProfileView?: number;
};

type LoadMore = (tableType: string) => void;

export type GetTalentAnalytic = {
  getTalentAnalytic: {
    revenueDoneOpportunities?: number;
    revenueCanceledOpportunities?: number;
    revenueAveragePerOpportunity?: number;
    brandsWorkedForCount?: number;
    brandsWorkedFor?: Array<BrandWorkedFor>;
    agreementsSentCount?: number;
    brandsAgreementSent?: Array<BrandAgreementSent>;
    profileViewsCount?: number;
    brandsTalentProfileViewed?: Array<BrandProfileView>;
  };
};

export type WorkedForProps = {
  brandsWorkedFor: Array<BrandWorkedFor>;
  brandsWorkedForCount: number;
  loadMore: LoadMore;
};

export type AgreementSentProps = {
  brandsAgreementSent: Array<BrandAgreementSent>;
  agreementsSentCount: number;
  loadMore: LoadMore;
};

export type ProfileViewedProps = {
  brandsTalentProfileViewed: Array<BrandProfileView>;
  profileViewsCount: number;
  loadMore: LoadMore;
};

export type CardWrapperProps = {
  itemsQuantity: number;
  children: ReactNode;
  title: string;
  loadMore: LoadMore;
  tableType: string;
};

export type UseTalentAnalytics = {
  openDatePicker: boolean;
  currentFocus: boolean;
  loading: boolean;
  brandsWorkedFor: Array<BrandWorkedFor>;
  brandsWorkedForCount: number;
  brandsAgreementSent: Array<BrandAgreementSent>;
  agreementsSentCount: number;
  brandsTalentProfileViewed: Array<BrandProfileView>;
  profileViewsCount: number;
  revenueDoneOpportunities: number;
  revenueCanceledOpportunities: number;
  revenueAveragePerOpportunity: number;
  toggleDatePicker: () => void;
  handleDateChange: (range: any) => void;
  resetDateRange: () => void;
  startDate: null | string;
  endDate: null | string;
  loadMore: LoadMore;
  handleSetFocus: (focus: string) => void;
};

export enum TableType {
  worked = 'worked',
  agreement = 'agreement',
  viewed = 'viewed',
}

export type HeaderProps = {
  startDate: null | string;
  endDate: null | string;
  handleDateChange: (range: any) => void;
  handleSetFocus: (focus: string) => void;
  currentFocus: boolean;
};

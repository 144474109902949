import { makeStyles } from '@material-ui/core';
import { colors } from 'theme/colors';

export const useStyles = makeStyles({
  modalContainer: {
    width: 552,
    height: 385,
    // '& div': {
    //   '&:nth-child(2)': {
    //     paddingBottom: 0,
    //   },
    // },
  },
  formContainer: {},
  inpProfileLink: {
    marginBottom: 28,
  },
  formHeader: {
    fontSize: 12,
    lineHeight: '18px',
  },
  btn: {
    width: 152,
    height: 40,
    '&:last-child': {
      marginLeft: 16,
    }
  },
  smallError: {
    color: colors.ERROR,
    fontSize: 12,
  },
});

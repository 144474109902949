/* eslint-disable max-len */
export const MAX_APPLICANT_AVATARS = 3;
export const MAX_NAME_LENGTH = 18;
export const MAX_BRAND_OPPORTUNITIES_PER_QUERY = 10;
export const MAX_ELASTIC_NUMBER = 2147483647;
export const TERMS_AND_CONDITIONS_URL = 'https://sportendorse.com/terms-conditions';
export const ADMINISTRATOR_PHONE_NUMBER = '+353 1 546 1103';
export const GOOGLE_CALENDAR_SCOPES = 'profile email https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar';
export const MAX_IMAGES_COUNT = 5;
export const MIN_TALENT_AGE = 16;
export const PLATFORM_FEE_AMOUNT = 1.23;

/* eslint-disable max-len */
import React from 'react'

const SportEndorseLogo = ({ ...props }) => (
  <svg
    width="19"
    height="24"
    viewBox="0 0 19 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="4"
      width="19"
      height="20"
    >
      <path
        d="M7.82922e-05 4H19V15.7953C19 17.1678 18.2964 18.4444 17.136 19.1773L9.50004 24L1.8641 19.1773C0.703712 18.4444 7.82922e-05 17.1678 7.82922e-05 15.7953V4Z"
        fill="#C4C4C4"
      />
    </mask>
    <g mask="url(#mask0)">
      <rect x="-0.00012207" y="8" width="3" height="16" fill="#0081C8" />
      <rect x="4" y="6" width="3" height="18" fill="#FCB130" />
      <rect x="8" y="4" width="3" height="20" fill="#231F20" />
      <rect x="12" y="6" width="3" height="18" fill="#00A651" />
      <rect x="16" y="8" width="3" height="16" fill="#EE2D4E" />
    </g>
    <circle cx="9.50012" cy="1.5" r="1.5" fill="#231F20" />
    <circle cx="5.50012" cy="3.5" r="1.5" fill="#FCB130" />
    <circle cx="1.5" cy="5.5" r="1.5" fill="#0081C8" />
    <circle cx="13.5001" cy="3.5" r="1.5" fill="#00A651" />
    <circle cx="17.5001" cy="5.5" r="1.5" fill="#EE2D4E" />
  </svg>
)

export default SportEndorseLogo

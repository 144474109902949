import { RecurrenceInterval } from 'types/opportunity'

const TAXES = [
  {
    value: 'included',
    label: 'SHARED.taxIncluded',
  },
  {
    value: 'excluded',
    label: 'SHARED.taxExcluded',
  },
]

const GENDERS = [
  {
    value: 'all',
    label: 'SHARED.all',
  },
  {
    value: 'male',
    label: 'SHARED.male',
  },
  {
    value: 'female',
    label: 'SHARED.female',
  },
]

const ACTIVITY = [
  {
    value: 'all',
    label: 'SHARED.all',
  },
  {
    value: 'competing',
    label: 'SHARED.competing',
  },
  {
    value: 'retired',
    label: 'SHARED.retired',
  },
]

const REPEAT = [
  {
    value: RecurrenceInterval.none,
    label: 'BRAND.OPPORTUNITY.doesNotRepeat',
  },
  {
    value: RecurrenceInterval.weekly,
    label: 'BRAND.OPPORTUNITY.weekly',
  },
  {
    value: RecurrenceInterval.monthly,
    label: 'BRAND.OPPORTUNITY.monthly',
  },
  {
    value: RecurrenceInterval.annually,
    label: 'BRAND.OPPORTUNITY.annually',
  },
]

export { TAXES, GENDERS, ACTIVITY, REPEAT }

// eslint-disable-next-line no-unused-vars
import { SOCIAL_PROVIDER } from '../constants'
/**
 *
 * @param {Array} linkedAccounts accounts array
 * @param {SOCIAL_PROVIDER} provider social provider name
 */
export const findSocialProvider = (linkedAccounts, provider) => {
  const socialAccounts = linkedAccounts || [];
  return socialAccounts.find((a) => a.providerName === provider);
};

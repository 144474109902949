/* eslint-disable max-len */
import React from 'react';

const SearchFilter = ({ className = '' }) => {
  return (
    <svg width="305" height="250" className={className} viewBox="0 0 305 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M237.205 30.8504C241.737 34.8121 240.935 41.8814 235.927 45.2202C233.786 46.6475 231.166 47.1658 228.642 46.6612L211.03 43.1387L206.385 41.5906C200.973 39.7864 195.158 39.5631 189.623 40.9468L183.521 42.4721C181.78 42.9075 179.95 42.8372 178.248 42.2696C172.798 40.4532 170.221 34.2316 172.79 29.094L173.535 27.6026C175.379 23.9149 179.148 21.5855 183.271 21.5855H189.021C192.155 21.5855 195.246 20.8558 198.049 19.4543L199.226 18.8657C206.444 15.2571 215.029 15.0936 221.853 19.4011C227.258 22.8136 232.391 26.6416 237.205 30.8504Z"
        fill="#EAEAEA"
      />
      <path
        d="M136.949 20.0149C141.896 20.8393 144.935 25.8882 143.35 30.6458C142.537 33.0829 140.625 34.9952 138.188 35.8075L134.626 36.9947C131.078 38.1775 128.069 40.5908 126.145 43.7982L120.054 53.95C117.52 58.1723 113.986 61.7062 109.764 64.2396L99.5781 70.3512C96.3201 72.3061 92.7041 73.5899 88.9427 74.1273L73.1316 76.386C68.1601 77.0962 64.4673 81.3539 64.4673 86.3759C64.4673 89.0523 65.5305 91.619 67.423 93.5115L70.8762 96.9647C72.2927 98.3812 73.0884 100.302 73.0884 102.306C73.0884 108.338 66.3647 111.937 61.3452 108.59L60.1573 107.798L55.7979 104.892C50.242 101.188 46.0805 95.7366 43.9726 89.4007L43.2812 87.3226C40.9883 80.4308 41.1444 72.8705 44.5463 66.4533C56.6678 43.5885 75.6057 25.039 98.7316 13.3938C104.579 10.4495 111.519 11.262 116.96 14.9022L117.117 15.0071C119.351 16.5015 121.877 17.5029 124.528 17.9447L136.949 20.0149Z"
        fill="#EAEAEA"
      />
      <path
        d="M143.215 142.282C149.307 142.282 153.877 147.856 152.682 153.83L151.538 159.55C150.979 162.344 149.605 164.911 147.59 166.926L137.581 176.935C134.928 179.588 133.438 183.186 133.438 186.938C133.438 191.39 131.342 195.583 127.78 198.254L120.763 203.516C117.888 205.673 116.195 209.057 116.195 212.652V218.279C116.195 223.305 111.272 226.854 106.504 225.265C104.449 224.58 102.8 223.025 101.995 221.014L96.788 207.996C95.3704 204.452 94.6421 200.671 94.6421 196.854V173.292C94.6421 165.287 88.992 158.395 81.1426 156.825C76.0611 155.809 71.7334 152.503 69.4159 147.868L66.5672 142.171C65.1866 139.409 64.4678 136.364 64.4678 133.277C64.4678 128.003 66.563 122.944 70.2926 119.215L73.0889 116.418L76.5904 112.917C81.2299 108.277 89.0512 109.547 91.9853 115.415C93.6136 118.672 96.9421 120.729 100.583 120.729H112.249C120.046 120.729 127.271 124.82 131.283 131.506L134.936 137.595C136.681 140.503 139.824 142.282 143.215 142.282Z"
        fill="#EAEAEA"
      />
      <path
        d="M262.872 64.6273C263.522 64.259 264.35 64.4915 264.708 65.1473C285.569 103.325 284.947 149.353 263.695 186.73C260.544 192.272 253.033 192.908 248.525 188.401C246.598 186.473 245.515 183.859 245.515 181.133V174.407C245.515 170.291 244.557 166.231 242.716 162.549L236.894 150.905L230.633 138.383C229.081 135.278 228.273 131.855 228.273 128.385V124.929C228.273 119.613 225.616 114.648 221.193 111.7C217.466 109.215 212.863 108.418 208.518 109.504L205.553 110.245C200.717 111.454 195.618 111.046 191.036 109.082L182.75 105.531C173.543 101.585 167.059 93.1249 165.643 83.209L165.028 78.9044C164.137 72.671 166.234 66.3821 170.686 61.9296C174.438 58.178 179.526 56.0703 184.832 56.0703H193.82C199.186 56.0703 204.197 58.7522 207.174 63.2171C209.634 66.9082 213.513 69.4162 217.889 70.1455L229.544 72.0881C234.371 72.8925 239.321 72.5041 243.963 70.9568L262.634 64.7333C262.716 64.7058 262.796 64.6703 262.872 64.6273Z"
        fill="#EAEAEA"
      />
      <path
        d="M180.855 43.137L198.098 38.8265L211.03 43.137L232.583 47.4476L245.516 38.8265C252.736 46.3831 258.965 54.8275 264.051 63.9574L262.758 64.6903L236.894 73.3114L211.031 69.0008L202.41 56.0687H176.546L163.614 69.0008L167.924 99.1752L198.098 112.107L215.341 107.797L228.273 116.418V133.661L236.894 150.903L245.516 168.146V185.389L257.154 197.027C217.346 253.437 139.344 266.89 82.9391 227.081C35.0347 193.268 17.0634 130.708 39.7246 76.6307L47.2251 99.1752L60.1573 107.796L73.0894 116.417L64.4678 125.039V137.971L73.0889 155.214L94.6422 159.524V202.631L103.263 224.184L116.195 228.495V206.942L133.438 194.009V181.077L150.681 163.835L154.991 142.281H137.749L124.817 120.728H94.6422L86.0211 103.485L73.0889 116.417V99.1747L64.4678 90.5541V77.622L94.6422 73.3114L116.195 60.3792L129.128 38.826L142.06 34.5154L146.37 21.5833L120.507 17.2727L108.264 9.08227C141.413 -4.26786 178.692 -2.79373 210.685 13.1345L193.787 21.5833H176.545L167.924 38.826L180.855 43.137Z"
        fill="white"
      />
      <circle cx="163" cy="106" r="65.5" fill="white" fillOpacity="0.4" stroke="#535E83" strokeWidth="15" />
      <circle cx="163" cy="106" r="65.5" stroke="#535E83" strokeWidth="15" />
      <rect width="30.8765" height="102.244" rx="15.4382" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 283.008 247.84)" fill="#757E9C" />
    </svg>
  );
};

export { SearchFilter };

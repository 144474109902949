import { makeStyles } from '@material-ui/core'
import { colors } from '../../../../../theme/colors'

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 32,
    marginBottom: 16,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  sectionHeading: {
    color: colors.TEXT,
    fontWeight: 600,
    textTransform: 'uppercase',
    marginBottom: 20,
  },
  chip: {
    background: colors.WHITE,
    border: `1px solid ${colors.PROFILE_BORDER}`,
    borderRadius: 4,
    marginRight: 8,
    marginBottom: 8,
  },
}))

import { makeStyles, Theme } from '@material-ui/core';
import { colors } from 'theme/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  item: {
    marginTop: theme.spacing(2),
    padding: 0,
    paddingBottom: theme.spacing(1),
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
  },
  img: {
    backgroundColor: colors.WHITE,
    marginRight: theme.spacing(2),
    width: 52,
    height: 52,
    fontSize: 24,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  date: {
    color: colors.SECONDARY_TEXT,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexGrow: 1,
  },
  btn: {
    fontSize: 12,
    color: colors.PRIMARY_HOVERED,
  },
  btnPay: {
    padding: '5px 12px',
    fontSize: 12,
    color: colors.PRIMARY_HOVERED,
  },
  seen: {
    opacity: 0.4,
  },
}));

import { useLink } from 'hooks/linkTo';
import { routes } from 'router/routesList';
import { intl } from "helpers";

export const useErrorHandler = () => {
  const linkTo = useLink();

  const getError = (errorMessage: string): string => {
    // TODO: may be it should be needed to refactor to 'switch case'
    let message = errorMessage;
    if (errorMessage.startsWith('error.')) {
      message = intl(`ERROR.${message.slice(6)}`);
    } else {
      message = intl('EMPTY_STATE.wentWrong');
    }
    return message;
  };

  const redirectOnError = {
    onError: ({ message }) => {
      console.log('--error handler--', message)
     linkTo(routes.ERROR_PAGE, { error: getError(message) } )();
    },
  };
  return {
    redirectOnError,
    getError
  }
};

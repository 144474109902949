import React from 'react';
import { Box, Avatar, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { getCurrencySymbol, clipName, getLetterForAvatar } from 'helpers';
import { STATUSES } from 'constants/opportunityStatuses';
import { routes } from 'router/routesList';
import { useLink } from 'hooks/linkTo';

import OpportunityStatus from '../OpportunityStatus';

import { useStyles } from './styles';

const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env;

const MobileOpportunity = ({ data, isBrand }) => {
  const classes = useStyles();
  const linkTo = useLink();

  const isOpen = data.status === STATUSES.OPEN.value;
  const isCompleted = !isOpen && data.status !== STATUSES.CONFIRMED.value;

  const { talent } = data;

  return (
    <Box className={classes.container} onClick={linkTo(`${routes.OPPORTUNITIES}/${data.id}/details`)}>
      <Box className={classes.avatarBox}>
        <Avatar
          className={clsx(classes.avatar, {
            [classes.grayBackground]: isOpen,
          })}
          src={!isOpen ? REACT_APP_AWSS3_URL_IMAGE_BEG + talent?.image : null}
        >
          {!isOpen ? getLetterForAvatar(talent?.firstName, talent?.lastName) : String(data?.applicants?.length)}
        </Avatar>
      </Box>
      <Box className={classes.content}>
        <Box className={classes.heading}>
          <Typography className={classes.person} variant="caption">
            {isBrand ? clipName(talent?.firstName, talent?.lastName) : data?.brand?.name}
          </Typography>
          {isCompleted && <OpportunityStatus status={data.status} />}
        </Box>
        <Typography className={classes.name}>{data.name}</Typography>
        <Box className={classes.details}>
          <Typography className={classes.detailsText}>
            <span className={classes.currency}>{getCurrencySymbol(data.currency)}</span>
            {data.budget}
          </Typography>
          <Typography className={classes.detailsText}>{`${data.country}, ${data.city}`}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default MobileOpportunity;

import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'theme/colors';

export const useStyles = makeStyles((theme) => ({
  social: {
    display: 'flex',
    paddingTop: 16,
  },
  iconWithCount: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 45,
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(2),
    },
  },
  count: {
    marginLeft: 12,
    color: colors.TEXT,
    fontWeight: 500,
  },
  typoWithIcon: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  icon: {
    height: 16,
    width: 16,
    marginTop: 3,
    marginRight: 10,
    fill: colors.PRIMARY_INPUT_HOVERED,
  },
  twoItemsList: {
    justifyContent: 'flex-start!important',
    '& li': {
      marginRight: theme.spacing(2),
    },
  },
  socials: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  socialsItem: {
    width: 80,
    height: 66,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: '#F6F6F6',
    borderRadius: 4,
  },
  svgIconBox: {
    justifyContent: 'center',
  },
  svgIcon: {
    width: 20,
    height: 20,
  },
  socialsItemText: {
    flex: 0,
    marginTop: 6,
    marginBottom: 0,
    '& span': {
      fontSize: 14,
      fontWeight: 600,
      textTransform: 'uppercase',
    },
  },
}));

/* eslint-disable no-unused-expressions */
import React, { useContext, useEffect } from 'react';
import { Box, Hidden } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { useStripe } from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';

import { useQuery as getQueryParams } from 'helpers/common';
import { intl } from 'helpers';
import { AppContext } from 'hooks/app';

import { ProfileCard } from '../../../components/ProfileCard';
import { GET_CURRENT_BRAND } from '../../../services/graphql/brand';
import { Wrapper } from '../../../components/Wrapper';
import { getLetterForCompanyNameAvatar } from '../../../helpers';
import { PaymentHistoryTable } from '../../../components/PaymentHistoryTable';
import { GET_PAYMENT_HISTORY } from '../../../services/graphql/payments';

import { useStyles } from './styles';

export const PaymentHistory = () => {
  const stripe = useStripe();
  const query = getQueryParams();
  const paymentIntentSecret = query.get('payment_intent_client_secret');
  const styles = useStyles({});
  const { dispatch } = useContext(AppContext);
  const history = useHistory();
  
  useEffect(() => {
    (async () => {
      try {
        let stripeStatus;
        if (stripe && paymentIntentSecret && paymentIntentSecret !== 'false') {
          stripeStatus = await stripe.retrievePaymentIntent(paymentIntentSecret);
        }
        if (paymentIntentSecret === 'false' || stripeStatus?.paymentIntent?.status === 'succeeded') {
          const changeUrl = () => history.push('/brand/payment-history');
          dispatch({ type: 'SUCCESS_MODAL', payload: { isOpen: true, title: intl('PAYMENT_MODAL.paymentSuccessful'), onCloseFunction: changeUrl } });
        }
      } catch (e) {
        console.log('-Error-stripe.retrievePaymentIntent--', e.message);
      }
    })();
  }, [stripe]);

  const { data: currentBrandData } = useQuery(GET_CURRENT_BRAND);

  return (
    <Wrapper>
      <Box className={styles.publicPage}>
        <Hidden smDown>
          <ProfileCard
            avatarDisabled
            imgSrc={currentBrandData?.getCurrentBrand?.image}
            imgAlt={getLetterForCompanyNameAvatar(currentBrandData?.getCurrentBrand?.companyName)}
            className={styles.profileCard}
          />
        </Hidden>
        <Box className={styles.publicPageContent}>
          <PaymentHistoryTable query={GET_PAYMENT_HISTORY} fieldName="getPaymentHistory" />
        </Box>
      </Box>
    </Wrapper>
  );
};

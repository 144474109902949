import { makeStyles } from '@material-ui/core';
import { colors } from '../../theme/colors';

export const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
    maxWidth: '100%',
    '&:hover': {
      boxShadow: '0px 5px 20px rgba(18, 28, 62, 0.11)',
    },
  },
  header: {
    height: 60,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 24px',
  },
  title: {
    fontSize: 18,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    flexGrow: 1,
    flexBasis: '80%',
  },
  price: {
    fontSize: 18,
    fontWeight: 600,
    flexBasis: '20%',
    textAlign: 'end',
  },
  mainInfo: {
    padding: '0 24px',
    display: 'flex',
    marginBottom: 24,
    height: 55,
    position: 'relative',
    flexGrow: 1,
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
    },
  },
  topRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap-reverse',
    flexGrow: 1,
    alignItems: 'center',
    [theme.breakpoints.down(700)]: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
  },
  infoItem: {
    fontSize: 12,
    marginRight: theme.spacing(3),
    '&:last-child': {
      marginRight: 0,
    },
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: 10,
    },
    [theme.breakpoints.down(700)]: {
      '&:first-child': {
        marginBottom: 16,
      },
    },
  },
  startDate: {
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
  },
  tax: {
    fontSize: 12,
    marginLeft: 16,
    flexShrink: 0,
  },
  bottom: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  info: {
    display: 'flex',
  },
  companyName: {
    fontSize: 16,
    marginLeft: theme.spacing(2),
  },
  bottomLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  bottomRight: {
    opacity: 0.4,
    fontSize: 12,
  },
  mobileContainer: {
    display: 'flex',
    padding: theme.spacing(2, 1),
    borderBottom: `1px solid ${colors.BORDER}`,
  },
  mobileAvatar: {
    marginRight: theme.spacing(2),
  },
  mobileTitle: {
    fontWeight: 500,
    fontSize: 16,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    marginBottom: theme.spacing(1),
  },
  mobileCompanyName: {
    fontSize: 12,
    color: colors.TEXT_GRAY,
    marginBottom: theme.spacing(1),
  },
  mobilePrice: {
    fontWeight: 500,
    fontSize: 16,
    color: colors.TEXT_GRAY,
    marginTop: 16,
    marginRight: 16,
    '&:last-child': {
      marginRight: 0,
    },
  },
}));

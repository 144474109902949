import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  toolbar: {
    fontSize: 20,
    fontWeight: 500,
  },
  paper: {
    width: 'fit-content',
    minWidth: 297,
    minHeight: 545,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

import React, { FC, useEffect } from 'react';
import { AppBar, Typography, Divider, Button, Box, Grid, useMediaQuery, IconButton, Hidden, Chip, Theme } from '@material-ui/core';
import { CloseRounded, ClearRounded } from '@material-ui/icons';
import { useQuery } from '@apollo/client';
import { Autocomplete } from '@material-ui/lab';
import { useFormik } from 'formik';
import clsx from 'clsx';
import intersectionWith from 'lodash/intersectionWith';
import isEqual from 'lodash/isEqual';

import { intl, omit } from 'helpers';
import { LIST_COUNTRIES, LIST_CITIES } from 'services/graphql/location';
import { LIST_INDUSTRIES } from 'services/graphql/industry';
import { FilterTextField } from 'components/FilterTextField';
import type { IdName } from 'types/talent';

import { useStyles } from './styles';
import type { Props } from './types';

const TalentBrandsFilters: FC<Props> = ({ filters, className, handleClose, handleApply }) => {
  const styles = useStyles({});
  const isMobile = useMediaQuery<any>((theme: Theme) => theme.breakpoints.down('sm'));

  const { values, setFieldValue, setValues } = useFormik({
    initialValues: {
      country: filters?.country || [],
      city: filters?.city || [],
      industry: filters?.industry || [],
    },
    enableReinitialize: true,
    onSubmit: (val) => handleApply(val),
  });

  const { data: countries, loading: countriesLoading } = useQuery(LIST_COUNTRIES);
  const { data: industries, loading: industriesLoading } = useQuery(LIST_INDUSTRIES);
  const { data: cities, loading: citiesLoading } = useQuery(LIST_CITIES, {
    skip: !values?.country.length,
    variables: {
      countryIds: values?.country && values?.country?.map((country) => country.id),
    },
  });

  const handleAutocompleteChange = (name: string) => (_, value) => setFieldValue(name, value);

  useEffect(() => {
    if (cities) {
      const filteredCities = intersectionWith(values.city, cities.listCities, isEqual);
      setFieldValue('city', filteredCities);
    } else if (!values.country.length) {
      setFieldValue('city', []);
    }
  }, [cities]);

  const handleClear = (section: string) => {
    if (!values) return;

    if (section === 'all') {
      handleApply({});
      setValues({
        country: filters?.country || [],
        city: filters?.city || [],
        industry: filters?.industry || [],
      });
    }

    if (section === 'country') {
      setValues({ ...values, country: [], city: [] });
      handleApply(omit(values, ['country', 'city']));
    }

    if (section === 'city') {
      setValues({ ...values, city: [] });
      handleApply(omit(values, ['city']));
    }

    if (section === 'industry') {
      setValues({ ...values, industry: [] });
      handleApply(omit(values, ['industry']));
    }
  };

  const checkDisabledClear = {
    location: !!(values?.country?.length || values?.city?.length),
    industry: !!values?.industry?.length,
  };

  const disabledClear = Object.values(checkDisabledClear).some((i: boolean) => i);

  const chipRenderer = (value: Array<IdName>, getTagProps) =>
    value.map((option: IdName, index: number) => (
      <Chip
        {...getTagProps({ index })}
        className={styles.chip}
        key={option.id}
        label={option.name}
        deleteIcon={
          <IconButton classes={{ label: styles.deleteIconLabel }} className={styles.deleteIcon}>
            <ClearRounded className={styles.icon} />
          </IconButton>
        }
      />
    ));

  return (
    <AppBar position={isMobile ? 'static' : 'sticky'} color="transparent" component="header" className={clsx(styles.profileCard, className)}>
      <Box className={styles.topBar}>
        <Typography className={styles.filters}>{intl('TALENT_OPPORTUNITY_FILTERS.filters')}</Typography>
        <Hidden mdUp>
          <IconButton onClick={handleClose} size="small">
            <CloseRounded />
          </IconButton>
        </Hidden>
      </Box>

      <Divider />
      <Box className={styles.mainBlock}>
        <Box className={styles.location}>
          <Box className={styles.clearBlock}>
            <Typography>{intl('BRAND_FILTERS.country')}</Typography>
            <Button disabled={!checkDisabledClear.location} onClick={() => handleClear('country')} className={styles.clearBtn}>
              {intl('BRAND_TALENT_FILTERS.clear')}
            </Button>
          </Box>
          <Autocomplete
            className={styles.input}
            loading={countriesLoading}
            options={countries ? countries.listCountries : []}
            value={values?.country}
            multiple
            onChange={handleAutocompleteChange('country')}
            getOptionLabel={(option: IdName) => option.name || ''}
            getOptionSelected={(option: IdName, value: IdName) => option.id === value.id}
            renderTags={chipRenderer}
            renderInput={(params) => <FilterTextField {...params} placeholder={intl('BRAND_FILTERS.allCountries')} name="country" />}
          />
        </Box>

        <Box className={styles.location}>
          <Box className={styles.clearBlock}>
            <Typography>{intl('BRAND_FILTERS.city')}</Typography>
            <Button disabled={!checkDisabledClear.location} onClick={() => handleClear('city')} className={styles.clearBtn}>
              {intl('BRAND_TALENT_FILTERS.clear')}
            </Button>
          </Box>
          <Autocomplete
            className={styles.input}
            loading={citiesLoading}
            disabled={!values?.country?.length}
            options={cities ? cities.listCities : []}
            value={values?.city}
            multiple
            onChange={handleAutocompleteChange('city')}
            getOptionLabel={(option: IdName) => option.name || ''}
            getOptionSelected={(option: IdName, value: IdName) => option.id === value.id}
            renderTags={chipRenderer}
            renderInput={(params) => <FilterTextField {...params} placeholder={intl('BRAND_FILTERS.allCities')} name="city" />}
          />
        </Box>

        <Box className={styles.location}>
          <Box className={styles.clearBlock}>
            <Typography>{intl('BRAND_FILTERS.industry')}</Typography>
            <Button disabled={!checkDisabledClear.industry} onClick={() => handleClear('industry')} className={styles.clearBtn}>
              {intl('BRAND_TALENT_FILTERS.clear')}
            </Button>
          </Box>
          <Autocomplete
            className={styles.input}
            loading={industriesLoading}
            options={industries ? industries.listIndustries : []}
            value={values?.industry}
            multiple
            onChange={handleAutocompleteChange('industry')}
            getOptionLabel={(option: IdName) => option.name || ''}
            getOptionSelected={(option: IdName, value: IdName) => option.id === value.id}
            renderTags={chipRenderer}
            renderInput={(params) => <FilterTextField {...params} placeholder={intl('BRAND_FILTERS.allIndustries')} name="industry" />}
          />
        </Box>
      </Box>
      <Divider />
      <Grid container spacing={2} className={styles.clearFilters}>
        <Grid item container justify="flex-end" xs={6} className={styles.fullHeight}>
          <Button onClick={() => handleClear('all')} variant="outlined" className={styles.fullHeight} disabled={!disabledClear}>
            {intl('TALENT_OPPORTUNITY_FILTERS.clear')}
          </Button>
        </Grid>
        <Grid item container justify="flex-start" xs={6} className={styles.fullHeight}>
          <Button variant="contained" color="primary" onClick={() => handleApply(values, true)} className={styles.fullHeight}>
            {intl('TALENT_OPPORTUNITY_FILTERS.apply')}
          </Button>
        </Grid>
      </Grid>
    </AppBar>
  );
};

export { TalentBrandsFilters };

import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { useMutation } from '@apollo/client'
import { useStyles } from './styles'
import { Modal } from '../../../../components/Modal'
import { intl } from '../../../../helpers'
import {
  GET_CURRENT_TALENT,
  UPDATE_STRIPE_CONNECT_ACCOUNT,
} from '../../../../services/graphql/talent'
import { QuestionMark } from '../../../../assets/svg-js'

export const StripeRemoveModal = ({ isOpen, toggle }) => {
  const classes = useStyles({})

  const [updateStripeConnectAccount, { loading }] = useMutation(UPDATE_STRIPE_CONNECT_ACCOUNT, {
    variables: {
      payload: '',
    },
    errorPolicy: 'none',
    onError() {},
    onCompleted: toggle,
    refetchQueries: [{ query: GET_CURRENT_TALENT }],
    awaitRefetchQueries: true,
  })

  const disconnectStatus = 'DISCONNECT'

  const handleStripeRemove = () => {
    updateStripeConnectAccount({
      variables: {
        payload: disconnectStatus,
      },
    })
  }
  return (
    <Modal
      isOpen={isOpen}
      className={classes.container}
      title=""
      handleClose={toggle}
      contentClassName={classes.content}
      classNameTitle={classes.title}
      dialogActionsComponent={
        <>
          <Button
            variant="outlined"
            disabled={loading}
            onClick={toggle}
            className={classes.buttons}
          >
            {intl('SHARED.cancel')}
          </Button>
          <Button
            disabled={loading}
            onClick={handleStripeRemove}
            variant="contained"
            className={classes.buttons}
            color="primary"
          >
            {intl('SHARED.confirm')}
          </Button>
        </>
      }
    >
      <QuestionMark />
      <Typography className={classes.text}>{intl('STRIPE_REMOVE_MODAL.areYouSure')}</Typography>
    </Modal>
  )
}

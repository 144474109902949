import React, { FC } from 'react';
import { Box, Paper, TextField, Grid, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import clsx from 'clsx';

import { intl } from 'helpers';

import { SaveBar } from 'components/SaveBar';
import { Loader } from 'components/Loader';
import { Wrapper } from 'components/Wrapper';
import { AvatarEditable } from 'components/AvatarEditable';

import type { GetBrandById } from 'types/brand';
import { useStyles } from './styles';

import { useBrandProfileForm } from '../hooks';

type Props = {
  brandData: GetBrandById;
  loading: boolean;
  refetchBrandData: () => void;
};

export const BrandProfileForm: FC<Props> = ({ brandData, loading, refetchBrandData }) => {
  const styles = useStyles({});
  const {
    values,
    errors,
    brandIsUpdating,
    industriesLoading,
    countriesLoading,
    citiesLoading,
    optionsCountry,
    optionsIndustry,
    optionsCities,
    handleSubmit,
    handleChange,
    handleAutocompleteChange,
    onCancel,
    handleImageChange,
  } = useBrandProfileForm(brandData, refetchBrandData);

  return (
    <Wrapper className={styles.wrapper}>
      <Box className={styles.companyInfo}>
        <Box className={styles.companyInfoFormAndNav}>
          <Paper className={styles.companyInfoForm}>
            {loading ? (
              <Loader />
            ) : (
              <Grid container justify="space-between">
                <Grid item container md={4} sm={4} justify="center" alignItems="baseline">
                  <AvatarEditable image={values.image} error={false} handleSuccess={handleImageChange} />
                </Grid>
                <Grid item md={8} sm={8} container justify="space-between">
                  <TextField
                    required
                    className={styles.input}
                    name="companyName"
                    value={values.companyName || ''}
                    onChange={handleChange}
                    label={intl('SHARED.companyName')}
                    error={!values.companyName || !!errors.companyName}
                    helperText={intl(errors.companyName) || (!values.companyName && intl('ERRORS.required'))}
                  />
                  <Autocomplete
                    // name="industry"
                    loading={industriesLoading}
                    className={styles.input}
                    options={optionsIndustry}
                    value={values.industry || { __typename: 'Industry', id: null, name: null }}
                    onChange={handleAutocompleteChange('industry')}
                    getOptionLabel={(option) => option.name || ''}
                    getOptionSelected={(option, value) => option?.id === value?.id}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        className={styles.input}
                        name="industry"
                        label={intl('SHARED.industry')}
                        // error={(isRequiredField && !values.industry?.name) || !!errors.industry}
                        // helperText={intl(errors.industry?.name) || (!values.industry?.name && displayError)}
                      />
                    )}
                  />
                  <Autocomplete
                    loading={countriesLoading}
                    className={styles.input}
                    options={optionsCountry}
                    value={values.country || { __typename: 'Country', id: null, name: null }}
                    onChange={handleAutocompleteChange('country')}
                    getOptionLabel={(option) => option?.name || ''}
                    getOptionSelected={(option, value) => option?.id === value?.id}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={styles.input}
                        name="country"
                        label={intl('SHARED.country')}
                        required
                        // error={(isRequiredField && !values.country?.name) || !!errors.country}
                        // helperText={intl(errors.country?.name) || (!values.country?.name && displayError)}
                      />
                    )}
                  />
                  <Autocomplete
                    loading={citiesLoading}
                    className={styles.input}
                    disabled={!values.country?.id}
                    options={optionsCities}
                    value={values.city || { __typename: 'City', id: null, name: null }}
                    onChange={handleAutocompleteChange('city')}
                    getOptionLabel={(option) => option?.name || ''}
                    getOptionSelected={(option, value) => option?.id === value?.id}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={styles.input}
                        name="city"
                        // value={values.city}
                        // required
                        label={intl('SHARED.city')}
                        // error={(isRequiredField && !values.city?.name) || !!errors.city}
                        // helperText={intl(errors.city?.name) || (!values.city?.name && displayError)}
                      />
                    )}
                  />
                  <TextField
                    className={styles.input}
                    name="about"
                    multiline
                    value={values.about || ''}
                    onChange={handleChange}
                    label={intl('SHARED.about')}
                  />
                </Grid>
              </Grid>
            )}
          </Paper>
          {!loading && (
            <Paper className={clsx(styles.companyInfoForm, styles.paymentInfoBlock)}>
              <Typography component="h4" className={styles.infoBlockHeader}>
                {intl('BRAND_PROFILE.forAdmin')}
              </Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6} container justify="space-between">
                  <TextField
                    fullWidth
                    // className={styles.inputInvoice}
                    name="privateInfo.street"
                    value={values.privateInfo?.street || ''}
                    onChange={handleChange}
                    label={intl('SHARED.street')}
                    // error={!!errors.privateInfo?.street || (isRequiredField && !values.privateInfo?.street)}
                    // helperText={intl(errors.privateInfo?.street) || (!values.privateInfo?.street && displayError)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} container justify="space-between">
                  <TextField
                    fullWidth
                    // className={styles.inputInvoice}
                    name="privateInfo.postalCode"
                    value={values.privateInfo?.postalCode || ''}
                    onChange={handleChange}
                    label={intl('SHARED.postalCode')}
                    // error={!!errors.privateInfo?.postalCode || (isRequiredField && !values.privateInfo?.postalCode)}
                    // helperText={intl(errors.privateInfo?.postalCode) || (!values.privateInfo?.postalCode && displayError)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} container justify="space-between">
                  <TextField
                    fullWidth
                    // className={styles.inputInvoice}
                    name="privateInfo.VATnumber"
                    value={values.privateInfo?.VATnumber || ''}
                    onChange={handleChange}
                    label={intl('SHARED.VATnumber')}
                    // error={!!errors.privateInfo?.VATnumber || (isRequiredField && !values.privateInfo?.VATnumber)}
                    // helperText={intl(errors.privateInfo?.VATnumber) || (!values.privateInfo?.VATnumber && displayError)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} container justify="space-between">
                  <TextField
                    fullWidth
                    // className={styles.inputInvoice}
                    name="privateInfo.IBAN"
                    value={values.privateInfo?.IBAN || ''}
                    onChange={handleChange}
                    label={intl('SHARED.IBAN')}
                    // error={!!errors.privateInfo?.IBAN || (isRequiredField && !values.privateInfo?.IBAN)}
                    // helperText={intl(errors.privateInfo?.IBAN) || (!values.privateInfo?.IBAN && displayError)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} container justify="space-between">
                  <TextField
                    fullWidth
                    // className={styles.inputInvoice}
                    name="privateInfo.bankAccount"
                    value={values.privateInfo?.bankAccount || ''}
                    onChange={handleChange}
                    label={intl('SHARED.bankAccount')}
                    // error={!!errors.privateInfo?.bankAccount || (isRequiredField && !values.privateInfo?.bankAccount)}
                    // helperText={intl(errors.privateInfo?.bankAccount) || (!values.privateInfo?.bankAccount && displayError)}
                  />
                </Grid>
              </Grid>
            </Paper>
          )}
        </Box>
      </Box>
      <SaveBar
        sticky
        className={styles.saveBar}
        // isSignUp={state?.fromSignUp}
        onSave={handleSubmit}
        loading={brandIsUpdating}
        onCancel={onCancel}
      />
    </Wrapper>
  );
};

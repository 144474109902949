import React, { FC } from 'react';
import { Input, TextField } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from './styles';
import type { Props } from './types';

const CustomInput = ({ ...props }) => {
  const styles = useStyles({});
  return <Input className={styles.input} {...props} />;
};

const FilterTextField: FC<Props> = ({ inputClassName, className, noWrap, InputProps, ...props }) => {
  const styles = useStyles({});

  return (
    <TextField
      variant="standard"
      InputProps={{
        ...InputProps,
        className: clsx(styles.input, className, {
          [styles.noWrap]: noWrap,
        }),
        classes: {
          input: inputClassName,
        },
      }}
      {...props}
    />
  );
};

export { FilterTextField, CustomInput };

import React, { FC } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import clsx from 'clsx';

import { useTitleStyles, useStyles } from './styles';
import type { TitleProps, Props } from './types';

const CustomDialogTitle: FC<TitleProps> = ({ onClose, classNameTitle, children, disabledBorder = false }) => {
  const styles = useTitleStyles({});
  return (
    <DialogTitle
      disableTypography
      className={clsx(styles.root, classNameTitle, {
        [styles.borderBottom]: !disabledBorder,
      })}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton size="small" aria-label="close" className={styles.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export const Modal: FC<Props> = ({
  isOpen = false,
  handleClose = () => {},
  dialogActionsComponent = null,
  title = '',
  children,
  className,
  disabledBorder,
  classNameTitle,
  contentClassName,
  disableBackdropClick,
}) => {
  const styles = useStyles({});

  return (
    <Dialog PaperProps={{ className: clsx(styles.root, className) }} disableBackdropClick={disableBackdropClick} open={isOpen} onClose={handleClose}>
      <CustomDialogTitle onClose={handleClose} disabledBorder={disabledBorder} classNameTitle={classNameTitle}>
        {title}
      </CustomDialogTitle>
      <DialogContent className={clsx(styles.paddings, contentClassName)}>{children}</DialogContent>
      <DialogActions className={styles.paddings}>{dialogActionsComponent}</DialogActions>
    </Dialog>
  );
};

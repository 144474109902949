import { makeStyles } from '@material-ui/core';
import { colors } from 'theme/colors';
import { constants } from 'theme/constants';

export const useStyles = makeStyles((theme) => ({
  SaveBar: {
    padding: 16,
    paddingLeft: 24,
    background: colors.WHITE,
    width: '100%',
    height: constants.SAVE_BAR_HEIGHT,
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
    marginTop: (props) => (props.fromTalentPublic ? '24px' : 0),
    marginBottom: (props) => (props.fromTalentPublic ? '24px' : 0),
    marginLeft: (props) => (props.fromTalentPublic ? '24px' : 0),
  },
  SaveBarWrapper: {
    display: 'flex',
    margin: '0 auto',
    justifyContent: 'flex-end',
    maxWidth: '95%',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  SaveBarButton: {
    marginRight: 16,
    width: 148,

    '&:last-child': {
      marginRight: 0,
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,

      width: '100%',
      '&:last-child': {
        marginTop: 8,
      },
    },
  },
  stickyBar: {
    position: 'sticky',
    bottom: 0,
  },
}));

/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { FC, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, TextField, InputAdornment, Radio, RadioGroup, FormControlLabel, Box, Checkbox } from '@material-ui/core';
import { useFormik } from 'formik';
import { KeyboardDatePicker } from '@material-ui/pickers';
import intlUniversal from 'react-intl-universal';
import clsx from 'clsx';
import * as yup from 'yup';

import { intl, getCurrencySymbol, calcTalentSalary } from 'helpers';
import { STATUSES as OPPORTUNITY_STATUS, STATUSES } from 'constants/index';
import { GET_OPPORTUNITY } from 'services/graphql/query/opportunity';
import { CANCEL_OPPORTUNITY } from 'services/graphql/mutation/opportunity';
import { CONFIRM_WIRE_PAYMENT } from 'services/graphql/payments';

import { Modal } from 'components/Modal';

import { useStyles } from './styles';
import { Props, ClosedBy } from './types';
import { PLATFORM_FEE_AMOUNT } from '../../../constants';

const validationSchema = yup.object({
  invoiceNumber: yup.number().required('ERRORS.emptyField'),
  brandPaid: yup.number().required('ERRORS.emptyField').typeError('ERRORS.numberType').positive(),
  date: yup.date().typeError('ERRORS.dateType'),
});

export const CompleteOpportunityModal: FC<Props> = ({ opportunity, open, handleClose }) => {
  const styles = useStyles();
  const isConfirmed = opportunity.status.name === STATUSES.CONFIRMED.value;
  const [closeType, setCloseType] = useState<ClosedBy>(isConfirmed ? ClosedBy.canceled : ClosedBy.done);

  const { currency, budget } = opportunity.redemption;
  const { offerAmount } = opportunity.finalCondition;
  const isOpen = opportunity.status.name === OPPORTUNITY_STATUS.OPEN.value;

  const handleChangeType = (value: string) => {
    setCloseType(value as ClosedBy);
  };

  const handleSend = (formValues) => {
    if (closeType === ClosedBy.done && !isOpen) {
      // eslint-disable-next-line
      confirmWirePayment({
        variables: {
          data: {
            opportunityId: opportunity.id,
            amountBrandPaid: formValues.brandPaid,
            date: formValues.date ? +formValues.date : new Date().getTime(),
          },
        },
      });
    } else {
      cancelOpportunity({
        variables: {
          opportunityId: opportunity.id,
          isCharge: formValues.chargeMoney,
        },
      });
    }
  };

  const { values, errors, handleChange, setFieldValue, resetForm, handleSubmit } = useFormik({
    initialValues: {
      invoiceNumber: opportunity.finalCondition.invoiceNumber,
      brandPaid: opportunity.redemption.isTaxed ? offerAmount ?? budget : (offerAmount ?? budget) * PLATFORM_FEE_AMOUNT,
      amountVAT: opportunity.redemption.isTaxed ? 0 : (offerAmount ?? budget) * PLATFORM_FEE_AMOUNT - (offerAmount ?? budget),
      date: new Date(),
      chargeMoney: false,
    },
    validationSchema,
    onSubmit: handleSend,
  });

  const toggleCheckbox = (checked: boolean) => {
    setFieldValue('chargeMoney', checked);
  };

  const [confirmWirePayment, { loading }] = useMutation(CONFIRM_WIRE_PAYMENT, {
    onCompleted() {
      resetForm();
      handleClose();
    },
    refetchQueries: [{ query: GET_OPPORTUNITY, variables: { opportunityId: opportunity.id } }],
    awaitRefetchQueries: true,
  });

  const [cancelOpportunity, { loading: cancelLoading }] = useMutation(CANCEL_OPPORTUNITY, {
    onCompleted() {
      resetForm();
      handleClose();
    },
    refetchQueries: [{ query: GET_OPPORTUNITY, variables: { opportunityId: opportunity.id } }],
    awaitRefetchQueries: true,
  });

  const handleDateChange = (field: string) => (date: Date) => {
    setFieldValue(field, date);
  };

  const actions = (
    <>
      <Button variant="outlined" disabled={loading || cancelLoading} onClick={handleClose} className={styles.buttons}>
        {intl('SHARED.cancel')}
      </Button>
      <Button onClick={handleSubmit as any} variant="contained" className={styles.buttons} color="primary" disabled={loading || cancelLoading}>
        {intl('SHARED.confirm')}
      </Button>
    </>
  );

  const talentSalaryLabel = intlUniversal.get('SHARED.talentSalary', {
    currency: getCurrencySymbol(currency.code),
    budget: calcTalentSalary(values.brandPaid, opportunity.profitMargins),
  });

  return (
    <Modal
      isOpen={open}
      title={intl('COMPLETE_OPPORTUNITY.completeOpportunity')}
      handleClose={handleClose}
      className={styles.modal}
      dialogActionsComponent={actions}
    >
      {!isConfirmed && (
        <RadioGroup aria-label="type" name="type" className={styles.radioBox} value={closeType} onChange={(e) => handleChangeType(e.target.value)}>
          <FormControlLabel
            value={ClosedBy.done}
            control={<Radio classes={{ root: styles.radio, checked: styles.checked }} />}
            label={intl('COMPLETE_OPPORTUNITY.done')}
          />
          <FormControlLabel
            value={ClosedBy.canceled}
            control={<Radio classes={{ root: styles.radio, checked: styles.checked }} />}
            label={intl('COMPLETE_OPPORTUNITY.canceled')}
          />
        </RadioGroup>
      )}

      <Box className={clsx({ [styles.hidden]: closeType === ClosedBy.canceled || isConfirmed })}>
        <TextField
          name="brandPaid"
          required
          error={!!errors.brandPaid}
          helperText={talentSalaryLabel}
          value={values.brandPaid || ''}
          InputProps={{
            startAdornment: <InputAdornment position="start">{getCurrencySymbol(currency.code)}</InputAdornment>,
          }}
          onChange={handleChange}
          label={intl('COMPLETE_OPPORTUNITY.brandPaid')}
          className={styles.input}
          fullWidth
        />
        <TextField
          name="invoiceNumber"
          required
          error={!!errors.invoiceNumber}
          value={values.invoiceNumber}
          disabled
          onChange={handleChange}
          label={intl('COMPLETE_OPPORTUNITY.invoiceNumber')}
          className={styles.invoice}
          fullWidth
        />
        <KeyboardDatePicker
          clearable
          required
          format="dd.MM.yy"
          name="startDate"
          error={!!errors.date}
          helperText={intl(errors.date)}
          value={values.date || null}
          label={intl('COMPLETE_OPPORTUNITY.date')}
          onChange={handleDateChange('date')}
          fullWidth
        />
      </Box>

      <Box className={clsx({ [styles.hidden]: closeType === ClosedBy.done })}>
        <FormControlLabel
          disabled={isOpen}
          control={<Checkbox checked={values.chargeMoney} onChange={(e) => toggleCheckbox(e.target.checked)} name="chargeMoney" color="primary" />}
          label={intl('COMPLETE_OPPORTUNITY.chargeMoney')}
        />
      </Box>
    </Modal>
  );
};

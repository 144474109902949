import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  loader: {
    // height: '100%',
    width: '100%',
    flex: '1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

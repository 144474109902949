import { makeStyles } from '@material-ui/core';

import { constants } from 'theme/constants';

import type { MinHeight } from './types';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: constants.WRAPPER_WIDTH,
    maxWidth: '95%',
    minHeight: (props: MinHeight) => `calc(100vh - ${constants.HEADER_HEIGHT + props.additionalContentHeight}px)`,
    marginLeft: 'auto',
    marginRight: 'auto',
    flex: 1,
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      minHeight: (props: MinHeight) => `calc(100vh - ${constants.HEADER_HEIGHT + props.additionalContentHeight}px - 16px)`,
      marginBottom: 16,
    },
  },
}))

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  loader: {
    marginTop: 200,
  },
  quantity: {
    marginBottom: 8,
  },
  headDivider: {
    marginBottom: 24,
  },
  emptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 150,
  },
  emptyIcon: {
    [theme.breakpoints.only('xs')]: {
      width: 305,
    },
  },
  emptyText: {
    fontWeight: 500,
    fontSize: 24,
    marginTop: -75,
  },
  opportunityLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
  showMoreContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 24,
    marginBottom: 32,
  },
  buttonShowMore: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

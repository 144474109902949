import { makeStyles, Theme } from '@material-ui/core'
import { colors } from 'theme/colors'

export const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    maxWidth: 384,
  },
  content: {
    marginTop: -theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  warnIcon: {
    width: 110,
    height: 100,
    fill: colors.ERROR,
  },
  title: {
    marginTop: 10,
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '27px',
  },
  problemsList: {
    marginTop: 5,
    fontSize: 18,
  },
  actionsContainer: {
    marginTop: -theme.spacing(4),
  },
  buttons: {
    width: 152,
    marginRight: theme.spacing(2),
    '&:last-child': {
      marginRight: 0,
    },
    [theme.breakpoints.only('xs')]: {
      width: 135,
      marginRight: theme.spacing(1),
    },
    '@media (max-width: 325px)': {
      width: 115,
    },
  },
}))

import React, { useEffect } from 'react';
import { Box, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useStyles } from './styles';
import { SportEndorse } from '../SportEndorse';

export const NavBar = ({ className, links, history, currentBtn, setCurrentBtn }) => {
  const styles = useStyles();

  useEffect(() => {
    const currentBtnIndex = links[0].findIndex((btn) => history.location.pathname.includes(btn.link));
    setCurrentBtn(currentBtnIndex);
  }, []);

  const handleClick = (name, i) => () => {
    setCurrentBtn(i);
    history.push(name);
  };

  return (
    <Box className={styles.logoAndNav}>
      <Link
        className={styles.logo}
        to="/"
        onClick={() => {
          setCurrentBtn(0);
        }}
      >
        <SportEndorse />
      </Link>
      <Box className={className}>
        {links[0].map((btn, i) => (
          <Button
            key={btn.label}
            className={clsx(styles.rightBtn, {
              [styles.contained]: currentBtn === i,
            })}
            onClick={handleClick(btn.link, i)}
          >
            {btn.label}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

import React, { FC } from 'react';
import { Box, Avatar, Typography, Tabs, Tab, Divider, Grid, TextField, Paper } from '@material-ui/core';
import { Route, Link } from 'react-router-dom';
import { isValid, format } from 'date-fns';
import clsx from 'clsx';

import { PROFILE_OPTIONS, VisibilityOptions } from 'constants/index';
import { routes } from 'router/routesList';
import { intl } from 'helpers';
import { ADMIN_GET_PAYMENT_HISTORY } from 'services/graphql/payments';

import BadgeSelect from 'components/BadgeSelect';
import { Option, VisibilityOption } from 'components/BadgeSelect/types';
import { Wrapper } from 'components/Wrapper';
import { CompanyProfileTop } from 'components/CompanyProfileTop/CompanyProfileTop';
import { Loader } from 'components/Loader';
import { PaymentHistoryTable } from 'components/PaymentHistoryTable';
import { BrandAnalytics } from 'components/BrandAnalytics';
import { Feedbacks } from 'components/Feedbacks';
import { TabsEnum } from './tabs';
import { About } from '../../Brand/CompanyPublicPage/components/About';
import { BrandProfileForm } from './BrandProfileForm';

import { useBrand } from './hooks';

import { useStyles } from './styles';

const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env;

export const Brand: FC = () => {
  const styles = useStyles({});
  const {
    id,
    currentTab,
    getBrand,
    loading,
    verificationStatus,
    visibilityStatus,
    handleTabChange,
    handleUserStatusChange,
    refetchBrandData,
    handleUserVisibilityChange,
  } = useBrand();

  return (
    <Box className={styles.container}>
      <Grid container spacing={5} className={styles.headerGrid}>
        <Grid item className={styles.head}>
          <Avatar className={styles.avatar} src={REACT_APP_AWSS3_URL_IMAGE_BEG + getBrand?.image}>
            {getBrand?.companyName?.[0]}
          </Avatar>
          <Box>
            <Typography className={styles.name}>{getBrand?.companyName}</Typography>
            <Typography className={styles.id}>ID: {id}</Typography>
            {isValid(new Date(getBrand.createdAt)) && (
              <Typography className={styles.id}>
                {intl('ADMIN.BRANDS.registrationDate')}: {format(new Date(getBrand.createdAt), 'dd.MM.yyyy')}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item className={styles.head}>
          <Box>
            <Typography className={styles.brand}>{intl('SHARED.brandManager')}</Typography>
            <Link className={styles.brandLink} to={`/${routes.ADMIN}/${routes.BRAND_MANAGERS}/${getBrand?.manager?.[0].id}`}>
              <Typography>
                {getBrand?.manager?.[0].firstName} {getBrand?.manager?.[0].lastName}
                {!getBrand?.manager?.[0].firstName && !getBrand?.manager?.[0].lastName && '-'}
              </Typography>
            </Link>
          </Box>
        </Grid>

        <Grid item className={styles.indicatorsGrid}>
          <Box className={styles.badges}>
            {getBrand && !loading && (
              <>
                <BadgeSelect
                  options={PROFILE_OPTIONS}
                  onChange={handleUserStatusChange}
                  selected={PROFILE_OPTIONS.find((o: Option) => o.value === verificationStatus)}
                  className={styles.badgeSelect}
                />
                <BadgeSelect
                  options={VisibilityOptions}
                  onChange={handleUserVisibilityChange}
                  selected={VisibilityOptions.find((o: VisibilityOption) => o.value === visibilityStatus)}
                  className={styles.badgeSelect}
                />
              </>
            )}
          </Box>
        </Grid>
      </Grid>

      <Tabs indicatorColor="primary" onChange={handleTabChange} value={currentTab}>
        <Tab value={TabsEnum.COMPANY_INFO} label={intl('ADMIN.BRAND.companyInfo')} />
        <Tab value={TabsEnum.PAYMENT_HISTORY} label={intl('ADMIN.BRAND.paymentHistory')} />
        <Tab value={TabsEnum.ANALYTICS} label={intl('SHARED.analytics')} />
        <Tab value={TabsEnum.EDIT} label={intl('SHARED.edit')} />
      </Tabs>
      <Divider />

      <Wrapper className={styles.wrapper}>
        <Route exact path={[`/${routes.ADMIN}/${routes.BRANDS}/:id/${TabsEnum.PAYMENT_HISTORY}`]}>
          <PaymentHistoryTable query={ADMIN_GET_PAYMENT_HISTORY} talentOrBrandId={id} fieldName="adminGetPaymentHistory" />
        </Route>
        <Route exact path={[`/${routes.ADMIN}/${routes.BRANDS}/:id/${TabsEnum.ANALYTICS}`]}>
          <BrandAnalytics />
        </Route>
        <Route exact path={[`/${routes.ADMIN}/${routes.BRANDS}/:id/${TabsEnum.COMPANY_INFO}`, `/${routes.ADMIN}/${routes.BRANDS}/:id`]}>
          {loading ? (
            <Loader />
          ) : (
            <>
              <CompanyProfileTop brand={getBrand} isAdmin />
              <About brand={getBrand} />
              <Paper className={clsx(styles.companyInfoForm, styles.paymentInfoBlock)}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6} container justify="space-between">
                    <TextField
                      fullWidth
                      disabled
                      className={styles.inputInvoice}
                      name="privateInfo.street"
                      value={getBrand?.privateInfo?.street || ''}
                      label={intl('SHARED.street')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} container justify="space-between">
                    <TextField
                      fullWidth
                      disabled
                      className={styles.inputInvoice}
                      name="privateInfo.postalCode"
                      value={getBrand?.privateInfo?.postalCode || ''}
                      label={intl('SHARED.postalCode')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} container justify="space-between">
                    <TextField
                      fullWidth
                      disabled
                      className={styles.inputInvoice}
                      name="privateInfo.VATnumber"
                      value={getBrand?.privateInfo?.VATnumber || ''}
                      label={intl('SHARED.VATnumber')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} container justify="space-between">
                    <TextField
                      fullWidth
                      disabled
                      className={styles.inputInvoice}
                      name="privateInfo.IBAN"
                      value={getBrand?.privateInfo?.IBAN || ''}
                      label={intl('SHARED.IBAN')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} container justify="space-between">
                    <TextField
                      fullWidth
                      disabled
                      className={styles.inputInvoice}
                      name="privateInfo.bankAccount"
                      value={getBrand?.privateInfo?.bankAccount || ''}
                      label={intl('SHARED.bankAccount')}
                    />
                  </Grid>
                </Grid>
              </Paper>
              <Feedbacks feedbacks={getBrand?.feedback} />
            </>
          )}
        </Route>
        <Route exact path={[`/${routes.ADMIN}/${routes.BRANDS}/:id/${TabsEnum.EDIT}`]}>
          <BrandProfileForm brandData={getBrand} loading={loading} refetchBrandData={refetchBrandData} />
        </Route>
      </Wrapper>
    </Box>
  );
};

import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from './styles';

export const Loader = ({ className = '', inButton = false }) => {
  const styles = useStyles({});
  const circularProps = {
    color: inButton ? 'inherit' : 'primary',
    size: inButton ? 30 : 40,
  };

  return (
    <Box className={clsx(styles.loader, className)}>
      <CircularProgress {...circularProps} />
    </Box>
  );
};

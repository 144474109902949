/* eslint-disable max-len */
import React from 'react'

const FacebookIcon = ({ ...props }) => (
  <svg
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.21427 13.6663V7.66634H7.03588L7.33341 4.99967H5.21427V3.70101C5.21427 3.01434 5.23181 2.33301 6.19134 2.33301H7.16321V0.426341C7.16321 0.397674 6.32841 0.333008 5.48387 0.333008C3.72008 0.333008 2.61569 1.43767 2.61569 3.46634V4.99967H0.666748V7.66634H2.61569V13.6663H5.21427Z"
      fill="#5B5B5B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.21427 13.6663V7.66634H7.03588L7.33341 4.99967H5.21427V3.70101C5.21427 3.01434 5.23181 2.33301 6.19134 2.33301H7.16321V0.426341C7.16321 0.397674 6.32841 0.333008 5.48387 0.333008C3.72008 0.333008 2.61569 1.43767 2.61569 3.46634V4.99967H0.666748V7.66634H2.61569V13.6663H5.21427Z"
      fill="#4065E0"
      fillOpacity="0.3"
    />
  </svg>
)

export default FacebookIcon

import React from 'react';
import clsx from 'clsx';
import { Box } from '@material-ui/core';

import { useStyles } from './styles';

export const SocialIcon = ({ icon: Icon, className = '' }) => {
  const styles = useStyles({});
  return (
    <Box className={clsx(styles.iconContainer, className)}>
      <Icon />
    </Box>
  );
};

import React, { FC, useState } from 'react';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { nanoid } from 'nanoid';

import { useStyles } from './styles';
import { Props } from './types';
import Image from './components/Image';
import Carousel from './components/Carousel';
import LoadingImage from './components/LoadingImage';

const ImageGallery: FC<Props> = ({ images, actionable = false, handleDelete = () => {}, className }) => {
  const classes = useStyles();
  const [carouselOpen, setCarouselState] = useState<boolean>(false);
  const [currentImage, setCurrentImage] = useState<string | null>(null);

  const onDelete = (image: string) => {
    handleDelete(image);
  };

  const onSelectImage = (image: string) => setCurrentImage(image);

  const handleCarouselClose = () => setCarouselState(false);

  const handleCarouselOpen = (image: string) => {
    onSelectImage(image);
    setCarouselState(true);
  };

  const onNextImage = () => {
    const currentIdx = images.findIndex((i: string) => i === currentImage);
    if (currentIdx === images.length - 1) setCurrentImage(images[0]);
    else setCurrentImage(images[currentIdx + 1]);
  };

  const onBackImage = () => {
    const currentIdx = images.findIndex((i: string) => i === currentImage);
    if (currentIdx === 0) setCurrentImage(images[images.length - 1]);
    else setCurrentImage(images[currentIdx - 1]);
  };

  return (
    <>
      <Box className={clsx(classes.container, className)}>
        {images.map((image: string) => {
          if (image.endsWith('loader')) {
            return <LoadingImage key={nanoid()} />;
          }
          return <Image actionable={actionable} key={image} imageSrc={image} handleZoom={handleCarouselOpen} handleDelete={onDelete} />;
        })}
      </Box>
      <Carousel
        open={carouselOpen}
        currentImage={currentImage}
        handleClose={handleCarouselClose}
        handleNext={onNextImage}
        handleBack={onBackImage}
        showNavigation={images.length > 1}
      />
    </>
  );
};

export default ImageGallery;

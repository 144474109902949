import { makeStyles } from '@material-ui/core';
import { colors } from 'theme/colors';

export const useStyles = makeStyles(() => ({
  input: {
    background: colors.BACKGROUND4,
    borderRadius: 4,
    padding: '6px 8px',
    fontSize: 12,
    display: 'flex',
    flexWrap: 'wrap',
    borderBottom: 'none',
    '&:hover': {
      borderBottom: 'none',
    },
    '&.MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '&.MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
  },
  noWrap: {
    flexWrap: 'nowrap!important',
  },
}));

import { makeStyles } from '@material-ui/core';
import { colors } from 'theme/colors';

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 32,
    marginBottom: 16,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  badges: {
    marginTop: 6,
    marginBottom: 4,
    display: 'flex',
    alignItems: 'center',
    '& span': {
      marginRight: theme.spacing(1),
    },
  },
  name: {
    fontSize: 24,
    fontWeight: 500,
    color: colors.TEXT,
  },
  mainInfo: {
    [theme.breakpoints.up('sm')]: {
      borderTop: 'none',
      paddingTop: 16,
    },
  },
  borderedBottom: {
    paddingBottom: 16,
    borderBottom: `1px solid ${colors.BORDER2}`,
    [theme.breakpoints.up('md')]: {
      borderTop: `1px solid ${colors.BORDER2}`,
    },
  },
  social: {
    display: 'flex',
    paddingTop: 16,
  },
  iconWithCount: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 45,
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(2),
    },
  },
  count: {
    marginLeft: 12,
    color: colors.TEXT,
    fontWeight: 500,
  },
  typoWithIcon: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  icon: {
    height: 16,
    width: 16,
    marginTop: 3,
    marginRight: 10,
    fill: colors.PRIMARY_INPUT_HOVERED,
  },
  iconTypoFirst: {
    marginBottom: 12,
  },
  btnToggleFavorite: {
    marginBottom: theme.spacing(2),
  },
  photo: {
    width: 60,
    height: 60,
    borderRadius: '50%',
    border: 'none',
    marginRight: 16,
  },
  nameAndPhotoContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'space-between',
    },
  },
  twoItemsList: {
    justifyContent: 'flex-start!important',
    '& li': {
      marginRight: theme.spacing(2),
    },
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  adminMain: {
    borderTop: 'none',
    paddingTop: 0,
  },
  competitionStatus: {
    fontSize: 12,
    background: colors.BACKGROUND,
    color: colors.LIGHT_TEXT,
    opacity: 0.7,
    borderRadius: 4,
    padding: '3px 12px',
  },
  socials: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  socialsItem: {
    width: 80,
    height: 66,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: '#F6F6F6',
    borderRadius: 4,
  },
  svgIconBox: {
    justifyContent: 'center',
  },
  svgIcon: {
    width: 20,
    height: 20,
  },
  socialsItemText: {
    flex: 0,
    marginTop: 6,
    marginBottom: 0,
    '& span': {
      fontSize: 14,
      fontWeight: 600,
      textTransform: 'uppercase',
    },
  },
  avatarMd: {
    height: 176,
    width: 176,
    marginRight: theme.spacing(4),
  },
  insidePaper: {
    flex: 1,
  },
}));

import React, { useState, MouseEvent } from 'react';
import { Box, IconButton, AppBar, Drawer, Avatar, Typography, Divider, Button, Hidden, Popover, Badge } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { MenuRounded, NotificationsNone as NotificationsNoneIcon } from '@material-ui/icons';
import { useApolloClient, useQuery } from '@apollo/client/react/hooks';
import clsx from 'clsx';

import { routes } from 'router/routesList';
import { useAuth } from 'hooks/auth';
import { useNotification } from 'hooks/notification';
import { intl } from 'helpers';
import { UserTypeEnum } from 'types/common';

import { GET_BRAND_USER } from 'services/graphql/brand';
import { GET_CURRENT_TALENT } from 'services/graphql/talent';

import { useStyles } from './styles';
import { NavBar } from '../NavBar/NavBar';
import { ProfileBar } from '../ProfileBar';
import NotificationPanel from '../NotificationPanel';

const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env;

export const Header = ({ handleOpenModal }) => {
  const styles = useStyles();
  const [drawerOpened, setDrawer] = useState<boolean>(false);

  const toggleDrawer = () => setDrawer((drawerOld) => !drawerOld);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [currentBtn, setCurrentBtn] = useState(null);

  const history = useHistory();
  const client = useApolloClient();
  const { signOut, getRole, user: us } = useAuth();
  const { connect, notifications, loading, notificationListError } = useNotification();

  const { data: brandUserData } = useQuery(GET_BRAND_USER, {
    skip: getRole() !== UserTypeEnum.brand,
    onCompleted: () => {
      connect(us?.attributes['sub']);
    },
  });
  const { data: talentUserData } = useQuery(GET_CURRENT_TALENT, {
    skip: getRole() !== UserTypeEnum.talent,
    onCompleted: () => {
      connect(us?.attributes['sub']);
    },
  });

  const user = brandUserData?.getBrandUser || talentUserData?.getCurrentTalent;
  const open = !!anchorEl;
  const id = open ? 'notification-popover' : undefined;

  const handleSignOut = () => {
    client.clearStore();
    signOut({ redirectUrl: `/${routes.LOGIN}` });
  };
  const handleHistoryPush = (link: string) => () => {
    history.push(link);

    toggleDrawer();
  };

  const handleClick = ({ currentTarget }: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getLinks = () => {
    if (getRole() === UserTypeEnum.brand) {
      return [
        [
          {
            label: intl('SHARED.opportunities'),
            link: `/${routes.BRAND}/${routes.OPPORTUNITIES}`,
            isCurrent: history.location.pathname.includes(`/${routes.BRAND}/${routes.OPPORTUNITIES}`),
          },
          {
            label: intl('SHARED.talent'),
            link: `/${routes.BRAND}/${routes.TALENTS}`,
            isCurrent: history.location.pathname.includes(`/${routes.BRAND}/${routes.TALENTS}`),
          },
          {
            label: intl('SHARED.newsFeed'),
            link: `${routes.BRAND_NEWS_FEED}/general`,
            isCurrent: history.location.pathname.includes(routes.BRAND_NEWS_FEED),
          },
        ],
        [
          {
            label: intl('BRAND.PROFILE_CARD.brandInfo'),
            link: routes.BRAND_PUBLIC_PAGE,
            isCurrent: [routes.BRAND_PUBLIC_PAGE, routes.BRAND_COMPANY_INFO].some((route) => history.location.pathname.includes(route)),
          },
          {
            label: intl('BRAND.PROFILE_CARD.profileInfo'),
            link: `/${routes.BRAND_PROFILE_INFO}`,
            isCurrent: [routes.BRAND_PROFILE_INFO].some((route) => history.location.pathname.includes(route)),
          },
          {
            label: intl('TALENT.PROFILE_CARD.paymentHistory'),
            link: routes.BRAND_PAYMENT_HISTORY,
            isCurrent: [routes.BRAND_PAYMENT_HISTORY].some((route) => history.location.pathname.includes(route)),
          },
          {
            label: intl('SHARED.analytics'),
            link: routes.BRAND_ANALYTICS,
            isCurrent: [routes.BRAND_ANALYTICS].some((route) => history.location.pathname.includes(route)),
          },
        ],
      ];
    }
    return [
      [
        {
          label: intl('SHARED.brands'),
          link: `${routes.TALENT_BRANDS}/all`,
          isCurrent: history.location.pathname.includes(routes.TALENT_BRANDS),
        },
        {
          label: intl('SHARED.opportunities'),
          link: `/${routes.TALENT}/${routes.OPPORTUNITIES}`,
          isCurrent: history.location.pathname.includes(`/${routes.TALENT}/${routes.OPPORTUNITIES}`),
        },
        {
          label: intl('SHARED.applied'),
          link: `/${routes.TALENT}/${routes.APPLIED}`,
          isCurrent: history.location.pathname.includes(`/${routes.TALENT}/${routes.APPLIED}`),
        },
        {
          label: intl('SHARED.newsFeed'),
          link: `${routes.TALENT_NEWS_FEED}/general`,
          isCurrent: history.location.pathname.includes(routes.TALENT_NEWS_FEED),
        },
      ],
      [
        {
          label: intl('TALENT.PROFILE_CARD.publicInfo'),
          link: routes.TALENT_PUBLIC_PAGE,
          isCurrent: [routes.TALENT_PUBLIC_PAGE, routes.TALENT_PUBLIC_INFO].some((route) => history.location.pathname.includes(route)),
        },
        {
          label: intl('TALENT.PROFILE_CARD.privateInfo'),
          link: routes.TALENT_PRIVATE_INFO,
          isCurrent: [routes.TALENT_PRIVATE_INFO].some((route) => history.location.pathname.includes(route)),
        },
        {
          label: intl('TALENT.PROFILE_CARD.paymentHistory'),
          link: routes.TALENT_PAYMENT_HISTORY,
          isCurrent: [routes.TALENT_PAYMENT_HISTORY].some((route) => history.location.pathname.includes(route)),
        },
        {
          label: intl('SHARED.analytics'),
          link: routes.TALENT_ANALYTICS,
          isCurrent: [routes.TALENT_ANALYTICS].some((route) => history.location.pathname.includes(route)),
        },
      ],
    ];
  };

  return (
    <AppBar position="sticky" color="transparent" component="header" className={styles.header}>
      <Box className={styles.headerWrapper}>
        <NavBar currentBtn={currentBtn} setCurrentBtn={setCurrentBtn} className={styles.navBar} links={getLinks()} history={history} />
        <Box className={styles.toolbarBox}>
          {getRole() !== UserTypeEnum.admin && (
            <Box className={styles.notification}>
              <IconButton
                aria-describedby={id}
                onClick={handleClick}
                className={clsx(styles.notificationBtn, {
                  [styles.notificationBtnOpened]: open,
                })}
                disabled={loading}
              >
                <Badge
                  variant="dot"
                  overlap="circle"
                  color="secondary"
                  invisible={notifications ? notifications.every((n) => !!n.readAt) || !!notificationListError : true}
                >
                  <NotificationsNoneIcon />
                </Badge>
              </IconButton>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <NotificationPanel handleClose={handleClose} error={notificationListError} data={notifications} />
              </Popover>
              <ProfileBar setCurrentBtn={setCurrentBtn} className={styles.profileBar} />
            </Box>
          )}
          <IconButton onClick={toggleDrawer} className={styles.burger} size="small">
            <MenuRounded />
          </IconButton>
        </Box>
      </Box>
      <Hidden mdUp>
        <Drawer anchor="right" open={drawerOpened} classes={{ paper: styles.drawer }} onClose={toggleDrawer}>
          <Box className={styles.drawerContainer}>
            <Box>
              <Box className={styles.nameAndAvatar}>
                <Avatar src={REACT_APP_AWSS3_URL_IMAGE_BEG + user?.image} className={styles.avatar}>
                  {user?.firstName?.[0]} {user?.lastName?.[0]}
                </Avatar>
                <Typography>
                  {user?.firstName} {user?.lastName}
                </Typography>
              </Box>

              <Divider />
              <Box className={styles.btnsBlock}>
                <Button disableRipple onClick={handleOpenModal}>
                  {intl('SIGN_UP.introVideo')}
                </Button>
                {getLinks()[0]?.map((btn) => (
                  <Button
                    fullWidth
                    key={btn.label}
                    className={styles.profileCardBtn}
                    color={btn.isCurrent ? 'primary' : 'default'}
                    classes={{ label: styles.profileCardBtnLabel }}
                    onClick={handleHistoryPush(btn.link)}
                  >
                    {btn.label}
                  </Button>
                ))}
              </Box>
              <Divider />
              <Box className={styles.btnsBlock}>
                {getLinks()[1]?.map((btn) => (
                  <Button
                    fullWidth
                    key={btn.label}
                    className={styles.profileCardBtn}
                    color={btn.isCurrent ? 'primary' : 'default'}
                    classes={{ label: styles.profileCardBtnLabel }}
                    onClick={handleHistoryPush(btn.link)}
                  >
                    {btn.label}
                  </Button>
                ))}
              </Box>
              <Divider />
              <Box className={styles.btnsBlock}>
                <Button fullWidth className={styles.profileCardBtn} classes={{ label: styles.profileCardBtnLabel }} onClick={handleSignOut}>
                  {intl('SHARED.logOut')}
                </Button>
              </Box>
            </Box>
            <Box className={styles.linksBlock}>
              <a className={styles.link} href="https://sportendorse.com/our-story/">
                {intl('FOOTER.aboutUs')}
              </a>
              <a className={styles.link} href="https://sportendorse.com/faqs/">
                {intl('FOOTER.help')}
              </a>
              <a className={styles.link} href="https://sportendorse.com/contact-us">
                {intl('FOOTER.contactUs')}
              </a>
            </Box>
          </Box>
        </Drawer>
      </Hidden>
    </AppBar>
  );
};

import React, { useState, useEffect } from 'react';
import { Typography, Paper, Hidden, Box } from '@material-ui/core';
import clsx from 'clsx';
import { format, startOfDay, endOfDay, isEqual } from 'date-fns';
import { useMutation } from '@apollo/client';

import { intl, getCancellationFine, getOpportunityPrice } from 'helpers';
import { useAuth } from 'hooks/auth';
import { PAYMENT_PURPOSE, STATUSES as OPPORTUNITY_STATUS, STATUSES } from 'constants/index';
import { UserTypeEnum } from 'types/common';
import { VerificationRequestStatusesEnum } from 'types/talent';

import { CANCEL_OPPORTUNITY } from 'services/graphql/mutation/opportunity';
import { GET_OPPORTUNITY } from 'services/graphql/query/opportunity';

import ImageGallery from 'components/ImageGallery';
import { PaymentModal } from 'components/Modals/PaymentModal';
import { TalentValidationModal } from 'components/Modals/TalentValidationModal';

import { useStyles } from './styles';
// import { CallToAdminModal } from './CallToAdminModal';
import BrandView from './components/BrandView';
import TalentView from './components/TalentView';
import NoteModal from '../NoteModal';
import CancelModal from '../CancelModal';
import CancelRepeatedModal from '../CancelRepeatedModal';
import { PLATFORM_FEE_AMOUNT } from '../../../../../constants';

const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env;

const Details = ({ opportunity, handleApply, isBrandView, applyLoading, talentInfo, paymentPopup, togglePaymentPopup, hasConfirmedTalentStripe }) => {
  const classes = useStyles();
  const { getAssumedRole } = useAuth();
  const isTalent = getAssumedRole() === UserTypeEnum.talent;
  const isBrand = getAssumedRole() === UserTypeEnum.brand;
  const isLastRepeated = opportunity?.opportunityId === opportunity?.recurrence?.lastChildId;
  const isOpportunityOpen = opportunity?.status?.name === OPPORTUNITY_STATUS.OPEN.value;
  const isOpportunityConfirmed = opportunity?.status?.name === OPPORTUNITY_STATUS.CONFIRMED.value;
  const isRepeatedOpp = !isLastRepeated && !!opportunity?.recurrence?.repeatFrequency && isOpportunityOpen;
  const canTalentCancel = isTalent && (isOpportunityOpen || isOpportunityConfirmed);
  const [cancelEvent, { loading: cancelLoading, error: cancelError }] = useMutation(CANCEL_OPPORTUNITY);

  const [notePopup, setNotePopup] = useState(false);
  const [cancelModalOpen, setCancelModal] = useState(false);
  const [applyingPopup, setApplyingPopup] = useState(false);
  // const [callToAdminModal, setCallToAdminModal] = useState(false);
  const [talentErrors, setTalentErrors] = useState({
    hasPublicInfo: true,
    hasPrivateInfo: true,
    isVerified: true,
  });

  const isOverdue = cancelModalOpen || opportunity?.status?.name === STATUSES.CANCELED_BY_ADMIN_PAYMENT.value;
  const toggleNotePopup = () => setNotePopup((old) => !old);
  const toggleCancellationPopup = () => setCancelModal((old) => !old);

  const toggleApplyingPopup = () => setApplyingPopup((old) => !old);

  // const toggleCallToAdminModal = () => setCallToAdminModal((current) => !current);

  const formatDate = (date) => format(new Date(date), 'dd.MM.yy');

  const isCanceled = cancelModalOpen || opportunity?.status?.name === OPPORTUNITY_STATUS.CANCELED_BY_ADMIN_PAYMENT.value;

  const validateTalent = (info) => {
    const errors = {
      hasPublicInfo: true,
      hasPrivateInfo: true,
      isVerified: true,
    };

    if (
      !info.firstName?.length ||
      !info.lastName?.length ||
      info.city.name === null ||
      info.country.name === null ||
      info.gender === null ||
      info.image === null ||
      info.birth === null ||
      info.sport.name === null ||
      info.competition === null
    )
      errors.hasPublicInfo = false;
    // if (!info.hasWire || !info.phone) errors.hasPrivateInfo = false;
    if (info.verificationStatus !== VerificationRequestStatusesEnum.Verified) errors.isVerified = false;

    return errors;
  };

  const isTalentValid = (errs) => Object.values(errs).every((k) => k === true);

  useEffect(() => {
    if (talentInfo?.getCurrentTalent) {
      const { getCurrentTalent: info } = talentInfo;
      setTalentErrors(validateTalent(info));
    }
  }, [talentInfo]);

  const handleCancelEvent = async () => {
    // if (isBrand && !hasConfirmedTalentStripe) {
    //   toggleCallToAdminModal();
    //   toggleCancellationPopup();
    //   return;
    // }
    try {
      const fine =
        opportunity?.status?.name !== STATUSES.OPEN.value ? getCancellationFine(opportunity.startDate, getOpportunityPrice(opportunity)) : null;
      if (!fine || isTalent) {
        await cancelEvent({
          variables: {
            opportunityId: opportunity.opportunityId,
          },
          refetchQueries: [{ query: GET_OPPORTUNITY, variables: { opportunityId: opportunity.opportunityId } }],
          awaitRefetchQueries: true,
        });
        toggleCancellationPopup();
        return;
      }
      togglePaymentPopup();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('error', error);
    }
  };

  const handleCancelRepeated = async (strategy) => {
    try {
      await cancelEvent({
        variables: {
          opportunityId: opportunity.opportunityId,
          cancelStrategy: strategy,
        },
        refetchQueries: [{ query: GET_OPPORTUNITY, variables: { opportunityId: opportunity.opportunityId } }],
        awaitRefetchQueries: true,
      });
      toggleCancellationPopup();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('error', error);
    }
  };

  const hasEventTime = (startDate, endDate) => {
    const formattedStartDate = new Date(startDate);
    const formattedEndDate = new Date(endDate);

    if (!isEqual(formattedStartDate, startOfDay(formattedStartDate)) && !isEqual(formattedEndDate, endOfDay(formattedEndDate))) {
      return `${format(new Date(formattedStartDate), 'hh:mm a')} - ${format(new Date(formattedEndDate), 'hh:mm a')}`;
    }

    return false;
  };

  const paymentAmount = isCanceled
    ? getCancellationFine(opportunity.startDate, getOpportunityPrice(opportunity))
    : opportunity?.finalCondition?.offerAmount * PLATFORM_FEE_AMOUNT ?? opportunity?.redemption?.budget * PLATFORM_FEE_AMOUNT;

  const noteModalProps = {
    notePopup,
    toggleNotePopup,
    handleApply,
    opportunityId: opportunity.opportunityId,
  };

  const brandProps = {
    opportunity,
    hasEventTime,
    formatDate,
    toggleCancellationPopup,
    togglePaymentPopup,
    cancelLoading,
  };

  const talentProps = {
    opportunity,
    finalCondition: opportunity?.finalCondition,
    hasEventTime,
    formatDate,
    toggleNotePopup,
    applyLoading,
    toggleCancellationPopup,
    toggleApplyingPopup,
    isValid: isTalentValid(talentErrors),
  };

  const paymentModal = {
    amount: paymentAmount,
    isOpen: paymentPopup,
    toggle: togglePaymentPopup,
    opportunity,
    handleComplete: () => setCancelModal(false),
    paymentPurpose: isOverdue ? PAYMENT_PURPOSE.OVERDUE : PAYMENT_PURPOSE.OFFER,
  };

  const talentValidationModal = {
    open: applyingPopup,
    handleClose: toggleApplyingPopup,
    errors: talentErrors,
  };

  return (
    <>
      <Paper elevation={0} className={classes.details}>
        {isBrandView ? <BrandView {...brandProps} /> : <TalentView {...talentProps} />}
      </Paper>
      <Hidden xsDown>
        <Box
          className={clsx(classes.textContainer, {
            [classes.flexed]: !opportunity.description && !opportunity?.finalCondition?.description,
          })}
        >
          {opportunity.images.length || opportunity.description || opportunity?.finalCondition?.description ? (
            <Box>
              {opportunity.description && (
                <>
                  <Typography className={classes.title}>{intl('SHARED.description')}</Typography>
                  <Typography paragraph className={classes.description}>
                    {opportunity.description}
                  </Typography>
                </>
              )}
              {opportunity.images.length > 0 && <ImageGallery images={opportunity.images.map((src) => REACT_APP_AWSS3_URL_IMAGE_BEG + src)} />}
            </Box>
          ) : (
            <Typography className={classes.emptyBlock}>{intl('SHARED.noDescription')}</Typography>
          )}
          {opportunity.finalCondition?.description && (
            <Box>
              <Typography className={classes.title}>{intl('OPPORTUNITY.finalConditions')}</Typography>
              <Typography className={classes.description}>{opportunity?.finalCondition?.description}</Typography>
            </Box>
          )}
        </Box>
      </Hidden>
      {(!isRepeatedOpp || canTalentCancel) && (
        <CancelModal
          isOpen={cancelModalOpen}
          opportunity={opportunity}
          loading={cancelLoading}
          error={cancelError?.message || ''}
          handleClose={toggleCancellationPopup}
          handleConfirm={handleCancelEvent}
          isTalent={isTalent}
          isBrand={isBrand}
          hasConfirmedTalentStripe={hasConfirmedTalentStripe}
        />
      )}
      {isTalent && <NoteModal {...noteModalProps} />}
      {isBrand && <PaymentModal {...paymentModal} />}
      {isTalent && <TalentValidationModal {...talentValidationModal} />}
      {isBrand && isRepeatedOpp && (
        <CancelRepeatedModal
          open={cancelModalOpen}
          loading={cancelLoading}
          handleClose={toggleCancellationPopup}
          handleSubmit={handleCancelRepeated}
        />
      )}
      {/* <CallToAdminModal isOpen={callToAdminModal} handleClose={toggleCallToAdminModal} /> */}
    </>
  );
};

export default Details;

import React, { useState, useCallback, memo, useRef } from 'react';
import { TextField, InputAdornment, Box, IconButton, Popover } from '@material-ui/core';
import debounce from 'lodash/debounce';
import { Search } from '@material-ui/icons';

import { intl } from 'helpers';

import { ExportToCsvIcon, FilterIcon, FilterIconActive } from 'assets/svg-js';
import { BrandFilters } from '../BrandFilters';

import { useStyles } from './styles';

export const AdminSearchFilters = memo(
  ({
    onSearchChange,
    filters,
    handleApply,
    hiddenExport = false,
    filterComponent: FilterComponent = BrandFilters,
    availableFilters,
    filtersActive,
    saveToCSVLink,
  }) => {
    const classes = useStyles({});
    const [search, setSearch] = useState();

    const handleSearchChange = useCallback(
      debounce((value) => {
        onSearchChange(value);
      }, 1000),
      [],
    );

    const [popoverStatus, setPopoverStatus] = useState(false);
    const togglePopover = () => setPopoverStatus((old) => !old);

    const handleApplyFilter = (values) => {
      togglePopover();
      handleApply(values);
    };

    const filtersBtn = useRef();
    return (
      <Box>
        <TextField
          onChange={({ target: { value } }) => {
            setSearch(value);
            handleSearchChange(value);
          }}
          value={search}
          className={classes.input}
          placeholder={intl('SHARED.search')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        {!hiddenExport && (
          <a href={saveToCSVLink || '#'}>
            <IconButton>
              <ExportToCsvIcon />
            </IconButton>
          </a>
        )}

        <IconButton ref={filtersBtn} onClick={togglePopover} size="small">
          {filtersActive ? <FilterIconActive /> : <FilterIcon />}
        </IconButton>
        <Popover anchorEl={filtersBtn.current} open={popoverStatus} onClose={togglePopover}>
          <FilterComponent filters={filters} handleApply={handleApplyFilter} availableFilters={availableFilters} handleClose={togglePopover} />
        </Popover>
      </Box>
    );
  },
);

export const AdminSearch = ({ onSearchChange }) => (
  <TextField
    onChange={onSearchChange}
    placeholder={intl('SHARED.search')}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <Search />
        </InputAdornment>
      ),
    }}
  />
);

import React, { FC, useState } from 'react';
import { Box, Typography, IconButton } from '@material-ui/core';
import { Close as CloseIcon, Warning as WarningIcon } from '@material-ui/icons';

import { intl } from 'helpers';
import type { Opportunity } from 'types/opportunity';

import { RateModal } from 'components/Modals/RateModal';

import { useStyles } from './styles';
import type { Props } from './types';
import NotificationList from '../NotificationList';

const NotificationPanel: FC<Props> = ({ data, handleClose, error }) => {
  const classes = useStyles();

  const [openRate, setRateModal] = useState<boolean>(false);
  const [opportunity, setOpportunity] = useState<Opportunity>(null);

  const handleOpenRateModal = (selectedOpportunity: Opportunity) => {
    setOpportunity(selectedOpportunity);
    setRateModal(true);
  };

  const handleCloseRateModal = () => {
    setRateModal(false);
    setOpportunity(null);
  };

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.header}>
          <Typography className={classes.title}>{intl('NOTIFICATION_PANEL.title')}</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon className={classes.closeIco} />
          </IconButton>
        </Box>

        {error ? (
          <Box className={classes.errorBox}>
            <WarningIcon fontSize="small" className={classes.warnIcon} />
            <Typography variant="caption">{intl('ERROR.tryAgainLater')}</Typography>
          </Box>
        ) : (
          <NotificationList data={data} handleOpenRateModal={handleOpenRateModal} handleClose={handleClose} />
        )}
      </Box>
      {opportunity && openRate && (
        <RateModal open={openRate} handleSuccess={handleClose} handleClose={handleCloseRateModal} updateNotification opportunityId={opportunity.id} />
      )}
    </>
  );
};

export default NotificationPanel;

import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../theme/colors'

const useStyles = makeStyles(() => ({
  container: {
    fontSize: 12,
    fontWeight: 300,
    lineHeight: 1.66,
  },
  greenBackground: {
    background: 'rgba(131, 191, 36, 0.3)',
  },
  grayBackground: {
    border: '1px solid rgba(187, 187, 188, 0.1)',
    background: colors.BACKGROUND,
  },
  redBackground: {
    background: 'rgba(255, 81, 81, 0.2)',
  },
  icon: {
    display: 'none',
  },
  input: {
    padding: '4px 12px',
    textAlign: 'center',
  },
  select: {
    paddingRight: '12px!important',
  },
}))

export { useStyles }

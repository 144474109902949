import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  connectBlock: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
}))

import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from 'theme/colors';

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    maxWidth: 392,
  },
  content: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 3),
    },
  },
  sliderContainer: {
    marginTop: theme.spacing(2),
    alignItems: 'center',
  },
  icon: {
    fill: colors.PRIMARY_INPUT_HOVERED,
  },
  rail: {
    backgroundColor: colors.BACKGROUND2,
  },
  editorBox: {
    position: 'relative',
  },
  editor: {
    width: '328px!important',
    height: '328px!important',
  },
  btnYes: {
    padding: '11px 66px',
    background: colors.PRIMARY,
    color: colors.WHITE,
    '&:hover': {
      background: colors.PRIMARY_HOVERED,
    },
  },
  btnNo: {
    border: `1px solid ${colors.BORDER3}`,
    padding: '11px 66px',
  },
  btnEdit: {
    position: 'absolute',
    top: 6,
    right: 6,
    background: 'rgba(0, 0, 0, 0.5)',
    borderRadius: 4,
    '&:hover': {
      background: colors.BLACK,
    },
  },
  editIcon: {
    fill: colors.WHITE,
  },
}));

export { useStyles };

import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  box: {
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    marginTop: theme.spacing(4),
    fontSize: 24,
    fontWeight: 500,
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
        fontSize: 18,
        fontWeight: 400,
    },
  },
  subtitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    fontSize: 18,
    fontWeight: 400,
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
        fontSize: 16,
    },
  },
}));

import React, { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Storage } from 'aws-amplify';
import { AddPhotoAlternateOutlined as AddPhotoIcon } from '@material-ui/icons';
import { useDropzone } from 'react-dropzone';

import { intl } from 'helpers';

import { useStyles } from './styles';
import { Props } from './types';
import { MAX_IMAGES_COUNT } from '../../constants';

const ImageUploader: FC<Props> = ({ setValue, setWithLoader }) => {
  const classes = useStyles();

  const putToStorage = async (file: File): Promise<string> => {
    try {
      const parts = file.name.split('.');
      const normalizedName = `${new Date().valueOf()}-${Math.random()}${parts.length ? '.'.concat(parts[parts.length - 1]) : ''}`;
      const { key } = (await Storage.put(`temp/${normalizedName}`, file, {
        contentType: file.type,
      })) as any;
      return key;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('[Uploading error]', err);
      return null;
    }
  };
  const onDropAccepted = async (files: Array<File>) => {
    setWithLoader(new Array<string>(files.length).fill('loader'));
    const buffer = (await Promise.all(files.map((item: File) => putToStorage(item)))) as Array<string>;
    setValue(buffer);
  };

  const onDrop = (files: Array<File>) => {
    if (files.length > MAX_IMAGES_COUNT) {
      files.length = 5;
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    onDrop,
    accept: ['image/jpeg', 'image/jpg', 'image/png'],
    maxSize: 10485760, // * 10 mb
  });
  return (
    <Box {...getRootProps()} className={classes.box}>
      <AddPhotoIcon className={classes.img} />
      <Typography className={classes.txtAttach} variant="caption">
        {intl('IMAGE_UPLOADER.attach')}
      </Typography>
      <input {...getInputProps()} />
    </Box>
  );
};

export default ImageUploader;

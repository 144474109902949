import React from 'react';
import clsx from 'clsx';
import { Paper, Typography } from '@material-ui/core';

import { intl } from 'helpers';

import { useStyles } from './styles';

export const About = ({ className = '', talent, fromBrand = false }) => {
  const styles = useStyles({});
  return (
    (!fromBrand || talent?.about) && (
      <Paper className={clsx(styles.paper, styles.publicPageServices, className)}>
        <Typography className={styles.sectionHeading} component="h4">
          {intl('SHARED.about')}
        </Typography>
        <Typography className={styles.aboutText}>
          <pre className={styles.pre}>{talent?.about || intl('SHARED.notFilledYet')}</pre>
        </Typography>
      </Paper>
    )
  );
};

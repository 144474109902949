import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../../theme/colors';

export const useStyles = makeStyles({
  aboutContainer: {
    padding: 32,
    margin: '16px 0',
  },
  sectionHeader: {
    display: 'inline-block',
    color: colors.TEXT,
    fontWeight: 600,
    textTransform: 'uppercase',
    marginBottom: 16,
  },
  aboutBody: {},
  feedbackContainer: {
    marginTop: 16,
    padding: 32,
  },
  feedbackCounter: {
    marginLeft: 8,
  },
});

import React, { FC, useState, useRef } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Slider, Grid, Button, IconButton, Box } from '@material-ui/core';
import { Add as AddIcon, Remove as RemoveIcon, Create as CreateIcon } from '@material-ui/icons';

import { intl } from 'helpers';

import { Modal } from 'components/Modal';

import { useStyles } from './styles';
import type { Props } from './types';

const CroppedModal: FC<Props> = ({ open, image, handleImageEditClick, loading, handleClose, handleSaveImage }) => {
  const classes = useStyles();
  const [scale, setScale] = useState<number>(1);

  const ref = useRef(null);

  const handleChange = (_: any, newValue: number) => setScale(newValue);

  const getAvatarScale = (value: number): number => +Number(1 + value / 100).toFixed(2);

  const onIncreaseScale = () => scale < 100 && setScale(scale + 10);

  const onDecreaseScale = () => scale > 1 && setScale(scale - 10);

  const ModalActions = () => (
    <>
      <Button className={classes.btnNo} onClick={handleClose} disabled={loading}>
        {intl('SHARED.cancel')}
      </Button>
      <Button className={classes.btnYes} onClick={() => handleSaveImage(ref)} disabled={loading}>
        {intl('SHARED.save')}
      </Button>
    </>
  );

  return (
    <Modal
      isOpen={open}
      handleClose={handleClose}
      dialogActionsComponent={<ModalActions />}
      className={classes.modal}
      contentClassName={classes.content}
      title={intl('CROPPED_MODAL.title')}
    >
      {image && (
        <Box className={classes.editorBox}>
          <AvatarEditor ref={ref} className={classes.editor} scale={getAvatarScale(scale)} image={image} borderRadius={164} disableHiDPIScaling />
          <IconButton onClick={handleImageEditClick} className={classes.btnEdit}>
            <CreateIcon className={classes.editIcon} />
          </IconButton>
        </Box>
      )}
      <Grid container spacing={2} className={classes.sliderContainer}>
        <Grid item>
          <IconButton onClick={onDecreaseScale}>
            <RemoveIcon className={classes.icon} />
          </IconButton>
        </Grid>
        <Grid item xs>
          <Slider value={scale} onChange={handleChange} aria-labelledby="image-scale-slider" classes={{ rail: classes.rail }} />
        </Grid>
        <Grid item>
          <IconButton onClick={onIncreaseScale}>
            <AddIcon className={classes.icon} />
          </IconButton>
        </Grid>
      </Grid>
    </Modal>
  );
};

export { CroppedModal };

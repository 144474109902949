import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: 18,
    marginTop: theme.spacing(4),
    textAlign: 'center',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  container: {
    width: 392,
  },
  buttons: {
    width: 152,
    marginRight: theme.spacing(2),
    '&:last-child': {
      marginRight: 0,
    },
  },
  title: {
    borderBottom: 0,
  },
}))

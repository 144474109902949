import { makeStyles } from '@material-ui/core';
import { colors } from 'theme/colors';

export const useStyles = makeStyles((theme) => ({
  privateInfoFormAndNav: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
    marginLeft: 24,
    '@media (max-width: 768px)': {
      marginLeft: 0,
    },
  },
  privateInfoForm: {
    padding: 30,
    minHeight: 130,
    paddingBottom: 24,
    marginBottom: theme.spacing(2),
  },
  paymentContainer: {
    padding: 30,
    paddingBottom: 38,
    marginBottom: theme.spacing(2),
  },
  inviteContainer: {
    display: 'flex',
    padding: '20px 32px 20px 24px',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      paddingRight: theme.spacing(1),
    },
  },
  input: {
    marginBottom: 28,
    width: 312,
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '&:last-child': {
      marginBottom: 0,
      maxWidth: '100%',
    },
  },
  btn: {
    marginLeft: 'auto',
  },
  paymentInputsContainer: {
    marginTop: theme.spacing(3),
  },
  saveBarContainer: {
    paddingLeft: theme.spacing(3),
    '@media (max-width: 768px)': {
      paddingLeft: 0,
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  iconContainer: {
    backgroundColor: (props) => (props.error ? colors.LIGHT_ERROR2 : colors.BACKGROUND),
    borderRadius: 4,
    display: 'flex',
    height: 32,
    minWidth: 32,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    '& svg': {
      fill: colors.PRIMARY_INPUT_HOVERED,
    },
  },
  textContent: {
    fontSize: '0.875rem',
  },
}));

import { makeStyles } from '@material-ui/core'
import { colors } from '../../../theme/colors'

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 30,
    paddingBottom: 22,
    paddingTop: 56,
    display: 'flex',
    flexDirection: 'column',
    width: 460,
    maxWidth: '100%',
    marginTop: 24,
    '@media (max-width:480px)': {
      paddingTop: 32,
    },
  },
  input: {
    marginBottom: 36,
  },
  login: {
    marginBottom: theme.spacing(3),
  },
  loginText: {
    marginTop: 27,
    fontWeight: 500,
    '@media (max-width:480px)': {
      marginTop: 17,
    },
  },
  link: {
    color: colors.GRAY,
    textDecoration: 'none',
  },
}))

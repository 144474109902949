import { makeStyles } from '@material-ui/core'
import { colors } from '../../theme/colors'

export const useStyles = makeStyles((theme) => ({
  helperTextRight: {
    textAlign: 'right',
  },
  helperTextLeft: {
    textAlign: 'left',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  forgotPassword: {
    color: colors.GRAY,
    textDecoration: 'none',
    fontSize: '0.75rem',
  },
  link: {
    textDecoration: 'none',
    transform: 'translate(0px, -38px)',
    lineHeight: 1.2,
  },
  linkWithError: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
  input: {
    padding: '8px 0 9px'
  }
}))

import { gql } from '@apollo/client';

const OPPORTUNITY_TYPES = gql`
  query getOpportunityTypes {
    opportunityTypes {
      id
      name
    }
  }
`;

const GET_OPPORTUNITY_STATUSES = gql`
  query opportunityStatuses {
    opportunityStatuses {
      id
      name
    }
  }
`;

const LIST_CURRENCIES = gql`
  query listCurrencies {
    listCurrencies {
      id
      code
    }
  }
`;

export const LIST_OPPORTUNITIES_BRAND = gql`
  query listBrandOpportunities($filter: OpportunityFilter!) {
    listBrandOpportunities(filter: $filter) {
      applications {
        status
        total
      }
      items {
        id
        applicants {
          firstName
          image
          lastName
          status
          applicantId
        }
        endDate
        city {
          name
        }
        country {
          name
        }
        isRated
        name
        redemption {
          budget
          talentSalary
          isTaxed
          currency {
            id
            code
          }
        }
        startDate
        status {
          name
        }
      }
      meta {
        total
      }
    }
  }
`;

export const LIST_TALENT_OPPORTUNITIES = gql`
  query ListTalentOpportunities($filter: OpportunityTalentFilter, $search: String) {
    listTalentOpportunities(filter: $filter, search: $search) {
      applications {
        status
        total
      }
      items {
        applicants {
          firstName
          applicantId
          image
          lastName
          status
          statusId
        }
        brand {
          id
          image
          name
        }
        city {
          name
        }
        country {
          name
        }
        description
        endDate
        id
        isRated
        isApplied
        name
        createdAt
        finalCondition {
          description
          offerAmount
          talentSalary
          isTaxed
          invoiceNumber
        }
        redemption {
          budget
          talentSalary
          isTaxed
          currency {
            id
            code
          }
        }
        startDate
        status {
          name
        }
        type {
          name
        }
      }
      meta {
        total
      }
    }
  }
`;

export const GET_OPPORTUNITY_FOR_EDIT = gql`
  query GetOpportunity($opportunityId: ID!) {
    getOpportunity(opportunityId: $opportunityId) {
      id
      description
      startDate
      endDate
      name
      createdAt
      images
      brand {
        id
      }
      city {
        id
        name
      }
      country {
        id
        name
      }
      recurrence {
        repeatFrequency
        repeatUntil
        lastChildId
      }
      parentId
      redemption {
        budget
        isTaxed
        talentSalary
        currency {
          code
        }
      }
      type {
        id
        name
      }
      status {
        name
      }
      target {
        cities {
          id
          name
        }
        competition
        countries {
          id
          name
        }
        gender
        age {
          from
          to
        }
        sports {
          id
          name
        }
      }
      profitMargins {
        firstRange {
          percentage
          range {
            from
            to
          }
        }
        secondRange {
          percentage
          range {
            from
            to
          }
        }
        thirdRange {
          percentage
          range {
            from
            to
          }
        }
      }
    }
  }
`;

export const GET_OPPORTUNITY = gql`
  query GetOpportunity($opportunityId: ID!) {
    getOpportunity(opportunityId: $opportunityId) {
      applicants {
        firstName
        applicantId
        applicationId
        isShortlisted
        isFavourite
        image
        lastName
        status
        statusId
        hasStripe
        offer {
          id
          talentId
          talentSalary
          opportunityId
          description
          offerAmount
          isTaxed
          confirmedAt
          declinedAt
        }
      }
      recurrence {
        repeatFrequency
        repeatUntil
        lastChildId
      }
      parentId
      finalCondition {
        description
        offerAmount
        talentSalary
        isTaxed
        invoiceNumber
      }
      brand {
        id
        image
        name
      }
      city {
        name
      }
      country {
        name
      }
      description
      endDate
      id
      applicationId
      isRated
      isApplied
      name
      images
      createdAt
      redemption {
        budget
        isTaxed
        talentSalary
        currency {
          id
          code
        }
      }
      startDate
      status {
        name
      }
      type {
        name
      }
      profitMargins {
        firstRange {
          percentage
          range {
            from
            to
          }
        }
        secondRange {
          percentage
          range {
            from
            to
          }
        }
        thirdRange {
          percentage
          range {
            from
            to
          }
        }
      }
    }
  }
`;

const GET_COVER_LETTER = gql`
  query getCoverLetter($data: OpportunityApplicant!) {
    getCoverLetter(data: $data) {
      coverLetter
    }
  }
`;

const GET_BRAND_REQUIRED_FIELDS = gql`
  query GetCurrentBrand {
    getCurrentBrand {
      companyName
      verificationStatus
      country {
        name
      }
      city {
        name
      }
      industry {
        name
      }
      image
      privateInfo {
        street
        postalCode
        VATnumber
        IBAN
        bankAccount
      }
    }
    getBrandUser {
      firstName
      lastName
      phone
      image
      verificationStatus
    }
  }
`;

const GET_TALENT_REQUIRED_FIELDS = gql`
  query getCurrentTalent {
    getCurrentTalent {
      firstName
      lastName
      country {
        name
      }
      city {
        name
      }
      competition
      payout {
        wire {
          iban
          accountName
        }
        stripe {
          accountId
        }
      }
      gender
      image
      hasStripe
      hasWire
      phone
      birth
      verificationStatus
      sport {
        id
        name
      }
      competition
    }
  }
`;

export { OPPORTUNITY_TYPES, LIST_CURRENCIES, GET_COVER_LETTER, GET_TALENT_REQUIRED_FIELDS, GET_BRAND_REQUIRED_FIELDS, GET_OPPORTUNITY_STATUSES };

import { gql } from '@apollo/client';

const CREATE_POST = gql`
  mutation createPost($data: PostInput) {
    createPost(data: $data) {
      id
      description
      images
      author {
        authorId
        authorType
        authorName
        imageSrc
        firstLetters
      }
      createdAt
      canInteract
      feedMeta {
        likesCounter
        sharesCounter
      }
      isLiked
    }
  }
`;

const UPDATE_POST = gql`
  mutation updatePost($id: ID!, $inputData: PostInput) {
    updatePost(id: $id, inputData: $inputData) {
      id
      description
      images
      createdAt
      canInteract
      author {
        authorId
        authorType
        authorName
        imageSrc
        firstLetters
      }
      feedMeta {
        likesCounter
        sharesCounter
      }
      isLiked
    }
  }
`;

const DELETE_POST = gql`
  mutation deletePost($id: ID!) {
    deletePost(id: $id)
  }
`;

export { CREATE_POST, UPDATE_POST, DELETE_POST };

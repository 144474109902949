import { makeStyles } from '@material-ui/core';

import { colors } from 'theme/colors';

const flexBetween = {
  display: 'flex',
  justifyContent: 'space-between',
};

const headerStyle = {
  color: colors.TEXT,
  fontWeight: 600,
  fontSize: 16,
  textTransform: 'uppercase',
};

export const useStyles = makeStyles((theme) => ({
  analyticsWrapper: {
    paddingLeft: theme.spacing(3),
    '@media (max-width: 768px)': {
      paddingLeft: 0,
    },
  },
  header: {
    marginBottom: theme.spacing(1),
  },
  headerTitle: {
    fontWeight: 500,
  },
  datePicker: {
    position: 'absolute',
    right: 0,
  },
  dateRangeLabel: {
    fontSize: 12,
    color: '#838383',
    opacity: 0.87,
  },
  dates: {
    fontSize: 16,
    fontWeight: 500,
    color: colors.TEXT_H,
  },
  calendarIcon: {
    paddingTop: 4,
    color: '#989EB5',
  },
  generalContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(3),
  },
  generalItem: {
    ...flexBetween,
    alignItems: 'center',
    height: 48,
    padding: '0 24px',
    fontSize: '12px',
    '&:first-child': {
      ...headerStyle,
      height: 76,
    },
    '&:nth-child(3)': {
      opacity: 0.4,
    },
  },
  contentContainer: {
    marginTop: theme.spacing(2),
  },
  contentBlock: {
    flexGrow: 1,
    marginBottom: theme.spacing(1),
  },
  blockHeader: {
    padding: '0 24px',
    alignItems: 'center',
    height: 72,
    '& > :first-child': {
      ...headerStyle,
      flexBasis: '70%',
    },
    '& > :last-child': {
      ...headerStyle,
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'flex-end',
    },
  },
  blockItem: {
    padding: '0 24px',
    height: 65,
    paddingLeft: theme.spacing(2),
  },
  itemsWrapper: {
    height: 265,
    overflow: 'auto',
  },
  logo: {
    width: 40,
    height: 40,
  },
  blockDescription: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
  },
  agreementSentItem: {
    cursor: 'pointer',
  },
  name: {
    color: colors.TEXT,
    fontSize: 16,
  },
  opportunities: {
    opacity: 0.4,
    maxWidth: 232,
  },
}));

import { makeStyles } from '@material-ui/core'
import { colors } from 'theme/colors'

export const useStyles = makeStyles((theme) => ({
  companyName: {
    fontSize: 18,
  },
  mainText: {
    fontSize: 14,
  },
  date: {
    fontSize: 9,
    color: 'rgba(51, 51, 51, 0.6)',
  },
  logo: {
    minWidth: 60,
    width: 60,
    height: 60,
    borderRadius: 30,
    marginRight: 16,
  },
  feedback: {
    paddingBottom: 16,
    paddingTop: 16,
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${colors.BORDER2}`,
    '&:last-of-type': {
      borderBottom: 'none',
    },
  },
  nameAndDate: {
    // display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'flex-start',
      width: 'auto',
    },
  },
  companyNameContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  logoWithName: {
    display: 'flex',
    marginBottom: 12,
  },
  ratingColor: {
    color: '#535e83',
  },
  pre: {
    fontFamily: theme.typography.fontFamily,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
}))

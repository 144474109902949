export enum SocialMediaEnum {
  Instagram = 'instagram',
  Twitter = 'twitter',
  Facebook = 'facebook',
  Google = 'google',
}

export enum GenderEnum {
  Male = 'male',
  Female = 'female',
}

export enum VideoTypeEnum {
  Youtube = 'youtube',
  Video = 'video',
}

export enum VerificationRequestStatusesEnum {
  Pending = 'pending',
  NotVerified = 'not_verified',
  Verified = 'verified',
  Rejected = 'rejected',
}

export type SocialAccounts = {
  providerName?: SocialMediaEnum;
  accountName?: string;
  accountId?: string;
  impressionsCount?: number;
};

export type Sport = {
  id?: string;
  name?: string;
};

export type IdName = {
  id: number;
  name?: string;
};

export type Video = {
  id?: string;
  type: VideoTypeEnum;
  link?: string;
};

export type FeedbackAuthor = IdName & {
  image?: string;
};

export type Feedback = {
  id: number;
  rate: number;
  opportunityId: number;
  description?: string;
  createdAt?: number;
  author?: FeedbackAuthor;
};

export type TalentPayoutWire = {
  iban?: string;
  accountName?: string;
};

export type TalentPayoutStripe = {
  accountId: string;
};

export type TalentPayout = {
  wire?: TalentPayoutWire;
  stripe?: TalentPayoutStripe;
};

export type Agent = {
  id?: number;
  firstName?: string;
  lastName?: string;
  talent?: Talent;
};

export type Talent = {
  id?: number;
  firstName?: string;
  lastName?: string;
  sport?: Sport;
  country?: IdName;
  city?: IdName;
  phone?: string;
  competition?: boolean;
  isPrivate?: boolean;
  club?: Array<string>;
  tags?: Array<string>;
  about?: string;
  gender?: GenderEnum;
  image?: string;
  social?: Array<SocialAccounts>;
  videos?: Array<Video>;
  email?: string;
  feedbacks?: Array<Feedback>;
  averageFeedback?: number;
  payout?: TalentPayout;
  hasWire?: boolean;
  hasStripe?: boolean;
  verificationStatus?: VerificationRequestStatusesEnum;
  createdAt?: string;
  agent?: Agent;
};

export type TalentValidation = {
  hasPublicInfo: boolean;
  hasPrivateInfo: boolean;
  isVerified: boolean;
};

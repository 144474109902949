export const constants = {
  HEADER_HEIGHT: 64,
  FOOTER_HEIGHT: 64,
  HEADER_WRAPPER_WIDTH: 1024,
  FOOTER_WRAPPER_WIDTH: 1024,
  WRAPPER_WIDTH: 1024,
  ADDITIONAL_WRAPPER_WIDTH: 960,
  ADMIN_WRAPPER: 720,
  MODAL_WIDTH: 552,
  SAVE_BAR_HEIGHT_MOBILE: 124,
  SAVE_BAR_HEIGHT: 72,
}

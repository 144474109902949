import React from 'react';
import { Box, Hidden, Paper, Button } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { ChevronLeft } from '@material-ui/icons';

import { intl } from 'helpers';
import { GET_TALENT_BY_ID_FROM_BRAND } from 'services/graphql/talent';
import { useErrorHandler } from 'hooks/app/errorHandler';
import { useLink } from 'hooks/linkTo';
import { routes } from 'router/routesList';

import { Feedbacks } from 'components/Feedbacks';
import { ProfileCard } from 'components/ProfileCard';
import { ProfileTop } from 'components/ProfileTop';
import { Wrapper } from 'components/Wrapper';
import { Loader } from 'components/Loader';
import { Videos } from '../PublicPage/components/Videos/Videos';
import { Tags } from '../PublicPage/components/Tags';
import { About } from '../PublicPage/components/About';

import { useStyles } from './styles';

export const TalentFromBrand = () => {
  const styles = useStyles({});
  const { talentId, opportunityId } = useParams();
  const linkTo = useLink();
  const { redirectOnError } = useErrorHandler();

  const { data: talentData = {}, loading } = useQuery(GET_TALENT_BY_ID_FROM_BRAND, {
    variables: {
      talentId,
    },
    fetchPolicy: 'network-only',
    ...redirectOnError,
  });

  return (
    <Wrapper>
      <Box width="100%">
        <Button
          className={styles.backBtn}
          onClick={
            opportunityId
              ? linkTo(
                  // eslint-disable-next-line
                  `/${routes.BRAND}/${routes.OPPORTUNITIES}/${opportunityId}/applicants/${talentId}`,
                )
              : linkTo(`/${routes.BRAND}/${routes.TALENTS}`)
          }
          startIcon={<ChevronLeft />}
        >
          {opportunityId ? intl('TALENT_FROM_BRAND.backToOpportunityPage') : intl('TALENT_FROM_BRAND.backToTalentsPage')}
        </Button>
        <Box className={styles.publicPage}>
          <Hidden smDown>
            <ProfileCard
              fromBrand
              imgSrc={talentData?.getTalentProfile?.image}
              imgAlt={`${talentData?.getTalentProfile?.firstName?.[0] || ''} ${talentData?.getTalentProfile?.lastName?.[0] || ''}`}
              className={styles.profileCard}
            />
          </Hidden>
          <Box className={styles.publicPageContent}>
            {loading ? (
              <Paper className={styles.loaderPlaceholder}>
                <Loader />
              </Paper>
            ) : (
              <>
                <ProfileTop talent={talentData?.getTalentProfile} fromBrand />
                <Tags talent={talentData?.getTalentProfile} fromBrand />
                <About talent={talentData?.getTalentProfile} fromBrand />
                <Videos talent={talentData?.getTalentProfile} fromBrand />
                <Feedbacks feedbacks={talentData?.getTalentProfile?.feedbacks} />
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Wrapper>
  );
};

import React, { FC } from 'react'
import { Button, Box } from '@material-ui/core'
import clsx from 'clsx'

import { useStyles } from './styles'
import { Props } from './types'
import { intl } from '../../../../../helpers'

const PayPanel: FC<Props> = ({ handlePay, className }) => {
  const classes = useStyles()

  return (
    <Box className={clsx(classes.box, className)}>
      <Button className={classes.btnPay} onClick={handlePay}>
        {intl('SHARED.pay')}
      </Button>
    </Box>
  )
}

export default PayPanel

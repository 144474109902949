import React from 'react';
import { Typography, Box, Button } from '@material-ui/core';
import clsx from 'clsx';

import { STATUSES } from 'constants/index';
import { intl, getCurrencySymbol, formatNumber } from 'helpers';

import { useStyles } from '../Message/styles';

const OfferMessage = ({ message, clientsMessage, role, handleUpdateOffer, offerUpdating, opportunityStatus, isTalentValid, toggleTalentModal }) => {
  const classes = useStyles();

  const isOpportunityInaccessible =
    opportunityStatus === STATUSES.CANCELED_BY_BRAND.value ||
    opportunityStatus === STATUSES.CANCELED_BY_TALENT.value ||
    opportunityStatus === STATUSES.DECLINED.value ||
    opportunityStatus === STATUSES.DONE.value;

  const displayActions =
    !message.offer.confirmedAt && !message.offer.declinedAt && (role === 'talent' || role === 'agent') && !isOpportunityInaccessible;

  const onUpdateOffer = (answer) => {
    if (isTalentValid || answer === 'decline') {
      handleUpdateOffer(message.offer.opportunityId, answer);
    } else {
      toggleTalentModal();
    }
  };

  const checkStatus = (confirmedAt, declinedAt) => {
    if (!confirmedAt && !declinedAt) return <></>;

    return (
      <Typography
        variant="caption"
        className={clsx({
          [classes.status]: true,
          [classes.confirmed]: !!confirmedAt,
        })}
      >
        {declinedAt ? intl('STATUS.declined') : intl('STATUS.confirmed')}
      </Typography>
    );
  };

  return (
    <Box
      className={clsx({
        [classes.messageContainer]: true,
        [classes.clientsMessage]: !clientsMessage,
      })}
    >
      <Box className={classes.flexContainer}>
        <Typography className={classes.boldText}>{intl('MESSENGER.finalConditions')}</Typography>
        {checkStatus(message.offer.confirmedAt, message.offer.declinedAt)}
      </Box>
      <Typography className={clsx(classes.text, classes.offerText)}>{message.offer.description}</Typography>
      <Box className={classes.offerRedemption}>
        <Typography className={classes.boldText}>{`${formatNumber(message.offer.talentSalary)} ${getCurrencySymbol('Euro')}`} </Typography>
        <Typography variant="caption" className={classes.tax}>
          {message.offer.isTaxed ? intl('SHARED.taxIncluded') : intl('SHARED.taxExcluded')}
        </Typography>
      </Box>
      {displayActions && (
        <Box className={classes.actions}>
          <Button disabled={offerUpdating} className={classes.btnDecline} onClick={() => onUpdateOffer('decline')}>
            {intl('OPPORTUNITY.decline')}
          </Button>
          <Button disabled={offerUpdating} className={classes.btnAccept} onClick={() => onUpdateOffer('confirm')}>
            {intl('NOTE.confirm')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default OfferMessage;

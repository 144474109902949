import { useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client/react/hooks';
import { useParams } from 'react-router-dom';

import { GET_OPPORTUNITY } from 'services/graphql/query/opportunity';
import { GET_CONVERSATION } from 'services/graphql/query/messanger';
import { APPLICANT_STATUSES } from 'constants/index';
import { useAuth } from 'hooks/auth';

export const useOpportunity = () => {
  // @ts-ignore // TODO: remove it
  const [currentApplicant, setCurrentApplicant] = useState();
  const { id, tab } = useParams();
  const { getAssumedRole } = useAuth();
  const role = getAssumedRole();

  const [getConversation, { data: conversationData, loading: loadingConversation, subscribeToMore, fetchMore }] = useLazyQuery(GET_CONVERSATION, {
    fetchPolicy: 'network-only',
  });
  const { data: opportunity, loading: loadingOpportunity } = useQuery(GET_OPPORTUNITY, {
    variables: {
      opportunityId: id,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onCompleted: ({ getOpportunity }) => {
      const applicationId =
        getOpportunity?.applicants?.find((i) => i.status === APPLICANT_STATUSES.CONFIRMED.value)?.applicationId ||
        getOpportunity?.applicants[0]?.applicationId;
      getConversation({
        variables: {
          applicationId,
        },
      });
    },
  });

  const getOpportunityQuery = {
    query: GET_OPPORTUNITY,
    variables: {
      opportunityId: id,
    },
  };

  return {
    opportunity: opportunity?.getOpportunity,
    setOpportunity: () => {},
    loadingOpportunity,
    tab,
    role,
    applicantsProps: {
      opportunityId: opportunity?.getOpportunity?.id,
      applicants: opportunity?.getOpportunity?.applicants || [],
      baseQuery: getOpportunityQuery,
      redemption: opportunity?.getOpportunity?.redemption,
      isApplied: opportunity?.getOpportunity?.isApplied,
      status: opportunity?.getOpportunity?.status,
      conversationData,
      subscribeToMore,
      fetchMore,
      getConversation,
      loadingConversation,
      currentApplicant,
      setCurrentApplicant,
      profitMargins: opportunity?.getOpportunity?.profitMargins,
      unreadMessages: [], // TODO: does it need for Admin?
    },
    dealProps: {
      status: opportunity?.getOpportunity?.status || {},
      isApplied: opportunity?.getOpportunity?.isApplied,
      conversationData,
      opportunityId: opportunity?.getOpportunity?.id,
      subscribeToMore,
      fetchMore,
      getConversation,
      loadingConversation,
      currentApplicant,
      isTalentView: false,
      brandId: opportunity?.getOpportunity?.brand?.id,
      finalCondition: opportunity?.getOpportunity?.finalCondition,
      profitMargins: opportunity?.getOpportunity?.profitMargins,
      togglePaymentPopup: () => {},
      profile: {
        brandName: opportunity?.getOpportunity?.brand?.name,
        brandImage: opportunity?.getOpportunity?.brand?.image,
        isTaxed: opportunity?.getOpportunity?.redemption?.isTaxed,
        budget: opportunity?.getOpportunity?.redemption?.budget,
        currency: opportunity?.getOpportunity?.redemption?.currency?.code,
        description: opportunity?.getOpportunity?.description,
        applicant:
          opportunity?.getOpportunity?.applicants?.find((i) => i.status === APPLICANT_STATUSES.CONFIRMED.value) ||
          opportunity?.getOpportunity?.applicants?.find((i) => i.status === APPLICANT_STATUSES.CANCELED.value),
      },
    },
  };
};

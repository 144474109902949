/* eslint-disable max-len */
import React from 'react'

const InstagramIcon = ({ ...props }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.24635 0.414826C3.1611 0.463793 2.22046 0.729141 1.46091 1.48548C0.69871 2.24579 0.436683 3.19006 0.387595 4.26403C0.35708 4.93435 0.178637 9.99846 0.696057 11.3265C1.04498 12.2225 1.73222 12.9113 2.63638 13.2614C3.05828 13.4255 3.53988 13.5367 4.24635 13.5691C10.1536 13.8364 12.3433 13.6908 13.2667 11.3265C13.4306 10.9057 13.5433 10.4246 13.5745 9.71988C13.8445 3.79752 13.5307 2.51379 12.5012 1.48548C11.6846 0.67091 10.7241 0.116392 4.24635 0.414826ZM9.72636 3.45951C9.72636 3.89756 10.0826 4.25357 10.5224 4.25357C10.9622 4.25357 11.3191 3.89756 11.3191 3.45951C11.3191 3.02145 10.9622 2.66545 10.5224 2.66545C10.0826 2.66545 9.72636 3.02145 9.72636 3.45951ZM3.57499 6.99173C3.57499 8.86836 5.10005 10.3896 6.98134 10.3896C8.86263 10.3896 10.3877 8.86836 10.3877 6.99173C10.3877 5.11511 8.86263 3.59448 6.98134 3.59448C5.10005 3.59448 3.57499 5.11511 3.57499 6.99173ZM4.77039 6.99171C4.77039 5.77416 5.76012 4.78621 6.98136 4.78621C8.2026 4.78621 9.19234 5.77416 9.19234 6.99171C9.19234 8.20993 8.2026 9.19788 6.98136 9.19788C5.76012 9.19788 4.77039 8.20993 4.77039 6.99171Z"
      fill="#5B5B5B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.24635 0.414826C3.1611 0.463793 2.22046 0.729141 1.46091 1.48548C0.69871 2.24579 0.436683 3.19006 0.387595 4.26403C0.35708 4.93435 0.178637 9.99846 0.696057 11.3265C1.04498 12.2225 1.73222 12.9113 2.63638 13.2614C3.05828 13.4255 3.53988 13.5367 4.24635 13.5691C10.1536 13.8364 12.3433 13.6908 13.2667 11.3265C13.4306 10.9057 13.5433 10.4246 13.5745 9.71988C13.8445 3.79752 13.5307 2.51379 12.5012 1.48548C11.6846 0.67091 10.7241 0.116392 4.24635 0.414826ZM9.72636 3.45951C9.72636 3.89756 10.0826 4.25357 10.5224 4.25357C10.9622 4.25357 11.3191 3.89756 11.3191 3.45951C11.3191 3.02145 10.9622 2.66545 10.5224 2.66545C10.0826 2.66545 9.72636 3.02145 9.72636 3.45951ZM3.57499 6.99173C3.57499 8.86836 5.10005 10.3896 6.98134 10.3896C8.86263 10.3896 10.3877 8.86836 10.3877 6.99173C10.3877 5.11511 8.86263 3.59448 6.98134 3.59448C5.10005 3.59448 3.57499 5.11511 3.57499 6.99173ZM4.77039 6.99171C4.77039 5.77416 5.76012 4.78621 6.98136 4.78621C8.2026 4.78621 9.19234 5.77416 9.19234 6.99171C9.19234 8.20993 8.2026 9.19788 6.98136 9.19788C5.76012 9.19788 4.77039 8.20993 4.77039 6.99171Z"
      fill="#4065E0"
      fillOpacity="0.3"
    />
  </svg>
)

export default InstagramIcon

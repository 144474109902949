import React, { FC } from 'react';
import { Paper, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { intl } from 'helpers';

import { Feedback } from './components/Feedback';

import { useStyles } from './styles';

import type { Feedback as FeedbackType } from 'types/talent';
import type { Props } from './types';

export const Feedbacks: FC<Props> = ({ className, feedbacks = [] }) => {
  const styles = useStyles();

  if (!feedbacks.length) {
    return null;
  }

  return (
    <Paper className={clsx(styles.feedbackContainer, className)} elevation={0}>
      <Typography className={styles.sectionHeader} component="h4">
        {intl('SHARED.feedbacks')}
      </Typography>
      <Typography className={clsx(styles.sectionHeader, styles.feedbackCounter)}>
        {feedbacks.length}
      </Typography>

      {feedbacks.map((feedback: FeedbackType) => (
        <Feedback key={feedback.id} feedback={feedback} />
      ))}

      {/* <Button className={styles.showMore} variant="outlined">
        {intl('SHARED.showMore')}
      </Button> */}
    </Paper>
  )
};

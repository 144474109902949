import React from 'react';
import { Typography, Divider, Button, Hidden, Box } from '@material-ui/core';
import { Schedule, Event, Room, Category } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { useAuth } from 'hooks/auth';
import { routes } from 'router/routesList';
import { getCurrencySymbol, formatNumber, intl } from 'helpers';
import { UserTypeEnum } from 'types/common';
import { STATUSES as OPPORTUNITY_STATUS } from 'constants/index';

import OpportunityStatus from 'components/OpportunityStatus';
import FinalBudgetTooltip from 'components/FinalBudgetTooltip';
import ImageGallery from 'components/ImageGallery';

import { useStyles } from '../../styles';

const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env;

const BrandView = ({ opportunity, formatDate, hasEventTime, toggleCancellationPopup, togglePaymentPopup, cancelLoading }) => {
  const classes = useStyles();
  const { getRole } = useAuth();
  const role = getRole();

  const canEdit = opportunity.status.name === OPPORTUNITY_STATUS.OPEN.value;
  const canCancel = opportunity.status.name === OPPORTUNITY_STATUS.OPEN.value || opportunity.status.name === OPPORTUNITY_STATUS.CONFIRMED.value;
  const canRenew =
    opportunity.status.name === OPPORTUNITY_STATUS.CANCELED_BY_TALENT.value || opportunity.status.name === OPPORTUNITY_STATUS.EXPIRED.value;
  const canPay =
    (opportunity?.status?.name === OPPORTUNITY_STATUS.PAYMENT.value ||
      opportunity?.status?.name === OPPORTUNITY_STATUS.CANCELED_BY_ADMIN_PAYMENT.value) &&
    role !== UserTypeEnum.admin;

  return (
    <>
      <div>
        <Hidden smUp>
          <>
            <Typography variant="body1">{opportunity.name}</Typography>
            <Box className={classes.infoBox}>
              <Box className={classes.mobileTitle}>
                <Typography className={classes.mobileTitleTypo} variant="body1">
                  {`${getCurrencySymbol(opportunity.redemption.currency.code)} ${formatNumber(opportunity.redemption.budget)} `}
                </Typography>
                <OpportunityStatus status={opportunity.status.name} />
              </Box>
              <Typography variant="caption">{opportunity.redemption.isTaxed ? intl('SHARED.taxIncluded') : intl('SHARED.taxExcluded')}</Typography>
              <FinalBudgetTooltip
                simple
                bold
                isTaxed={opportunity.redemption.isTaxed}
                budget={opportunity.redemption.budget}
                profitMargins={opportunity.profitMargins}
              />
            </Box>
            <Divider className={classes.mobileDivider} />
          </>
        </Hidden>
        <Typography variant="body2" className={classes.detailsItem}>
          <Category fontSize="small" /> {opportunity.type.name}
        </Typography>
        <Typography variant="body2" className={classes.detailsItem}>
          <Room fontSize="small" /> {`${opportunity.country.name}, ${opportunity.city.name}`}
        </Typography>
        <Typography variant="body2" className={classes.detailsItem}>
          <Event fontSize="small" /> {`${formatDate(opportunity.startDate)} - ${formatDate(opportunity.endDate)}`}
        </Typography>
        {hasEventTime(opportunity.startDate, opportunity.endDate) && (
          <Typography variant="body2" className={classes.detailsItem}>
            <Schedule fontSize="small" /> {hasEventTime(opportunity.startDate, opportunity.endDate)}
          </Typography>
        )}
        <Divider />
        <Hidden xsDown>
          <>
            <Typography className={clsx(classes.budgetInfo, classes.budget)} variant="h5">
              <span>{`${getCurrencySymbol(opportunity.redemption.currency.code)} ${formatNumber(opportunity.redemption.budget)} `}</span>
              <small>{opportunity.redemption.isTaxed ? intl('SHARED.taxIncluded') : intl('SHARED.taxExcluded')}</small>
            </Typography>
            <FinalBudgetTooltip
              simple
              bold
              budget={opportunity.redemption.budget}
              isTaxed={opportunity.redemption.isTaxed}
              profitMargins={opportunity.profitMargins}
            />
            <Typography variant="caption" className={classes.postedDate}>
              {`${intl('OPPORTUNITY.posted')}: ${formatDate(opportunity.createdAt)}`}
            </Typography>
          </>
        </Hidden>
        <Hidden smUp>
          {opportunity.description && (
            <Typography variant="body1" className={clsx(classes.descriptionTitle, classes.margedTop)}>
              {intl('SHARED.description')}
            </Typography>
          )}
          <Typography
            className={clsx({
              [classes.mobileDesc]: true,
              [classes.emptyBlock]: !opportunity.description,
            })}
          >
            {opportunity.description || intl('SHARED.noDescription')}
          </Typography>
          {!!opportunity.images.length && (
            <ImageGallery className={classes.imagesBox} images={opportunity.images.map((src: string) => REACT_APP_AWSS3_URL_IMAGE_BEG + src)} />
          )}
        </Hidden>
      </div>
      {getRole() === UserTypeEnum.brand && (
        <div>
          {canEdit && (
            <Button
              component={Link}
              fullWidth
              to={`/${routes.BRAND}/${routes.EDIT_OPPORTUNITY}/${opportunity.opportunityId}`}
              className={classes.btnEdit}
            >
              {intl('SHARED.edit')}
            </Button>
          )}
          {canCancel && (
            <Button disabled={cancelLoading} onClick={toggleCancellationPopup} fullWidth className={classes.btnCancel}>
              {intl('OPPORTUNITY.cancelOpportunityText')}
            </Button>
          )}
          {canRenew && (
            <Button
              fullWidth
              className={classes.btnCancel}
              component={Link}
              to={`/${routes.BRAND}/${routes.EDIT_OPPORTUNITY}/${opportunity.opportunityId}`}
            >
              {intl('OPPORTUNITY.renewOpportunityText')}
            </Button>
          )}
        </div>
      )}
      <Hidden xsDown>
        {canPay && (
          <Button onClick={togglePaymentPopup} disabled={cancelLoading} fullWidth variant="contained" color="primary" className={classes.btnPay}>
            {intl('SHARED.pay')}
          </Button>
        )}
      </Hidden>
    </>
  );
};

export default BrandView;

import React from 'react';
import { Paper, Avatar, Typography, Box, Divider, Grid, Hidden, IconButton, Button } from '@material-ui/core';
import { People, Face, EmojiEvents, LocationOn, FavoriteBorder, Favorite } from '@material-ui/icons';
import clsx from 'clsx';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { cloneDeep } from 'lodash';

import { FemaleFace } from 'assets/svg-js';
import { intl, getLetterForAvatar } from 'helpers';
import { routes } from 'router/routesList';
import { GET_BRAND_TALENTS, UPDATE_USER_FAVOURITE } from 'services/graphql/brand';
import { GET_TALENT_BY_ID_FROM_BRAND } from 'services/graphql/talent';

import { useStyles } from './styles';

const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env;

const updateQueries = (cache, options, id) => {
  try {
    const data = cache.readQuery({
      query: GET_BRAND_TALENTS,
      ...options,
    });
    const { brandListTalents } = data;

    const updatedItems = cloneDeep(brandListTalents.items);
    const item = updatedItems.find(({ id: talentId }) => talentId === id);
    item.isFavourite = !item.isFavourite;

    cache.writeQuery({
      query: GET_BRAND_TALENTS,
      ...options,
      data: {
        brandListTalents: {
          ...brandListTalents,
          items: updatedItems,
        },
      },
    });
  } catch {
    // catch doesn't handled
  }

};

export const TalentListCard = ({ talent: talentListItem, queryOptions, id }) => {
  const styles = useStyles({});
  const history = useHistory();
  const [toggleFavourite] = useMutation(UPDATE_USER_FAVOURITE);

  const { data: { getTalentProfile: talent } = {} } = useQuery(GET_TALENT_BY_ID_FROM_BRAND, {
    variables: {
      talentId: talentListItem.id,
    },
  });
  const genderHidden = !talent?.fieldsVisibility?.find((field) => field.fieldName === 'gender')?.isVisible;
  const emptyCard = !talent?.sport?.name && !talent?.club?.length && !talent?.gender && (!talent?.country?.name || !talent?.city?.name);

  const handleClick = ({ target }) => {
    const optionsWithDifferentFavouritesFlag = cloneDeep(queryOptions);
    optionsWithDifferentFavouritesFlag.variables.filter.options.favourites = !queryOptions.variables.filter.options.favourites;
    if (target.dataset.type === 'favourite' || target.parentElement.dataset.type === 'favourite') {
      toggleFavourite({
        variables: {
          entityType: 'talent',
          entityId: talent.id,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateUserFavourite: {
            __typename: 'UserFavourite',
            entityType: 'talent',
            entityId: talent.id,
          },
        },
        update: (cache) => {
          updateQueries(cache, queryOptions, id);
        },
      });
    } else {
      history.push(`/${routes.BRAND}/${routes.TALENT}/${id}`);
    }
  };

  return (
    <Paper onClick={handleClick} className={styles.paper} data-type="card" id={id}>
      <Hidden xsDown>
        <Avatar className={styles.avatar} src={REACT_APP_AWSS3_URL_IMAGE_BEG + talent?.image}>
          {getLetterForAvatar(talent?.firstName, talent?.lastName)}
        </Avatar>
      </Hidden>
      <Box className={styles.rightSide}>
        <Box className={styles.topBarMobile}>
          <Hidden smUp>
            <Avatar src={REACT_APP_AWSS3_URL_IMAGE_BEG + talent?.image} className={styles.avatar}>
              {getLetterForAvatar(talent?.firstName, talent?.lastName)}
            </Avatar>
          </Hidden>
          <Box className={styles.topBar}>
            <Grid container item alignItems="center" xs={12}>
              <Typography className={styles.name}>
                {talent?.firstName} {talent?.lastName}
              </Typography>
              <Hidden xsDown>
                <IconButton data-type="favourite" className={styles.favouriteBtn}>
                  {talent?.isFavourite ? (
                    <Favorite className={styles.favourite} data-type="favourite" />
                  ) : (
                    <FavoriteBorder className={styles.favourite} data-type="favourite" />
                  )}
                </IconButton>
              </Hidden>
            </Grid>
            <Typography className={styles.joined}>
              {talent?.createdAt ? `${intl('TALENT_LIST_CARD.joined')} ${format(new Date(talent?.createdAt), 'dd.MM.yyyy')}` : ''}
            </Typography>
          </Box>
        </Box>

        {!emptyCard && (
          <Hidden xsDown>
            <Divider />
          </Hidden>
        )}
        <Hidden smUp>
          <Button variant="outlined" fullWidth data-type="favourite">
            {talent?.isFavourite ? intl('BRAND.TALENTS_LIST.removeFromFavourite') : intl('BRAND.TALENTS_LIST.addToFavourite')}
          </Button>
        </Hidden>

        <Grid container className={styles.info}>
          {talent?.sport?.name && (
            <Grid item xs={12} sm={6} className={clsx(styles.iconAndText, styles.iconTypoFirst)}>
              <EmojiEvents className={styles.icon} />
              <Box>
                <Typography>{talent?.sport?.name}</Typography>
              </Box>
            </Grid>
          )}
          {!genderHidden && talent?.gender && (
            <Grid item xs={12} sm={6} className={styles.iconAndText}>
              <Typography className={clsx(styles.typoWithIcon, styles.iconTypoFirst)}>
                {talent?.gender === 'male' ? <Face className={styles.icon} /> : <FemaleFace className={styles.icon} />}
                {talent?.gender && intl(`SHARED.${talent?.gender}`)}
              </Typography>
            </Grid>
          )}
          {!!talent?.club?.length && (
            <Grid item xs={12} sm={6} className={clsx(styles.iconAndText, styles.iconTypoFirst)}>
              <People className={styles.icon} />

              <Box>
                {talent?.club?.map((clubName) => (
                  <Typography key={clubName}>{clubName}</Typography>
                ))}
              </Box>
            </Grid>
          )}
          {(talent?.country?.name || talent?.city?.name) && (
            <Grid item xs={12} sm={6} className={clsx(styles.iconAndText, styles.iconTypoFirst)}>
              <LocationOn className={styles.icon} />
              <Box>
                <Typography component="span">{`${talent?.country?.name ?? ''}, ${talent?.city?.name ?? ''}`}</Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </Paper>
  );
};

import React, { FC } from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core';

import { getLetterForCompanyNameAvatar, clipText, intl } from 'helpers';
import { routes } from 'router/routesList';

import { useStyles } from './styles';

const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env;

type Props = {
  id: string;
  avatar: string | null;
  name: string;
  joinedAt: string;
  notAuthorized?: boolean;
};

const BrandListItem: FC<Props> = ({ id, avatar, name, joinedAt, notAuthorized }) => {
  const styles = useStyles();

  return (
    <ListItem className={styles.item} component={Link} to={notAuthorized ? routes.LOGIN : routes.TALENT_BRAND_PAGE(id, 'info')}>
      <ListItemAvatar>
        <Avatar className={styles.avatar} src={REACT_APP_AWSS3_URL_IMAGE_BEG + avatar}>
          {getLetterForCompanyNameAvatar(name)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        classes={{ primary: styles.primaryText, secondary: styles.secondaryText }}
        primary={clipText(name, 28)}
        secondary={`${intl('SHARED.joined')} ${format(new Date(joinedAt), 'MMM d, yyyy')}`}
      />
    </ListItem>
  );
};

export { BrandListItem };

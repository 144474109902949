import React, { FC, ReactNode } from 'react';
import { Button } from '@material-ui/core';

import { useStyles } from './styles';

type Props = {
  isCurrent?: boolean;
  onClick: () => void;
  children: ReactNode;
  fullWidth?: boolean;
};

export const ProfileButton: FC<Props> = ({ isCurrent = false, onClick, children, fullWidth = false }) => {
  const styles = useStyles();
  return (
    <Button className={styles.profileCardBtn} color={isCurrent ? 'primary' : 'default'} onClick={onClick} fullWidth={fullWidth}>
      {children}
    </Button>
  );
};

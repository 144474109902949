import { gql } from '@apollo/client'

const LIST_SPORTS = gql`
  query ListSports {
    listSports {
      id
      name
    }
  }
`
export { LIST_SPORTS }

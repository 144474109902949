import React from 'react';
import clsx from 'clsx';
import { Divider, Paper, Typography, Box, Button, Grid, Avatar, Hidden, IconButton } from '@material-ui/core';
import { EmojiEvents, People, Face, LocationOn, Favorite, FavoriteBorder } from '@material-ui/icons';
import { useMutation } from '@apollo/client';
import { differenceInYears, isValid } from 'date-fns';

import { AgeIcon, InstagramIcon, FacebookIcon, TwitterIcon, FemaleFace } from 'assets/svg-js';
import { intl, getSocialCount, getFormattedCount } from 'helpers';
import { useLink } from 'hooks/linkTo';
import { useAuth } from 'hooks/auth';
import { routes } from 'router/routesList';
import { PUBLIC_STATUS } from 'constants/index';
import { UPDATE_USER_FAVOURITE } from 'services/graphql/brand';
import { GET_TALENT_BY_ID_FROM_BRAND } from 'services/graphql/talent';
import { VerificationRequestStatusesEnum } from 'types/talent';

import { useStyles } from './styles';
import { Badge } from '../Badge';
import { SocialList } from '../SocialList';

const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env;

export const ProfileTop = ({ talent, isAdmin = false, fromBrand = false }) => {
  const styles = useStyles({});
  const linkTo = useLink();
  const { getRole } = useAuth();
  const userRole = getRole();
  const profileBadgeText = talent?.isPrivate ? PUBLIC_STATUS.private : PUBLIC_STATUS.visible;

  const ageHidden = fromBrand && !talent?.fieldsVisibility.find((field) => field.fieldName === 'age')?.isVisible;
  const genderHidden = fromBrand && !talent?.fieldsVisibility.find((field) => field.fieldName === 'gender').isVisible;

  const emptyProfile = fromBrand && !talent?.sport?.name && !talent?.club?.length && !talent?.country?.name && !talent?.gender;
  const emptySocials = !talent?.social || !talent?.social.some(({ accountName }) => accountName);

  const [toggleFavourite] = useMutation(UPDATE_USER_FAVOURITE);

  const handleClick = () => {
    toggleFavourite({
      variables: {
        entityType: 'talent',
        entityId: talent.id,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateUserFavourite: {
          __typename: 'UserFavourite',
          entityType: 'talent',
          entityId: talent.id,
        },
      },
      update: (cache) => {
        const query = cache.readQuery({
          query: GET_TALENT_BY_ID_FROM_BRAND,
          variables: {
            talentId: talent.id,
          },
        });
        const { getTalentProfile } = query;
        cache.writeQuery({
          query: GET_TALENT_BY_ID_FROM_BRAND,
          variables: {
            talentId: talent.id,
          },
          data: {
            getTalentProfile: {
              ...getTalentProfile,
              isFavourite: !getTalentProfile.isFavourite,
            },
          },
        });
      },
    });
  };

  return (
    <Box>
      <Hidden smUp>
        <Box className={styles.head}>
          <Typography className={styles.name}>{intl('SHARED.publicInfo').toUpperCase()}</Typography>
          {!fromBrand && (
            <Button variant="contained" color="primary" onClick={linkTo(routes.TALENT_PUBLIC_INFO)} disabled={userRole === 'agent'}>
              {intl('TALENT.PUBLIC_PAGE.PROFILE_TOP.editPublicInfo')}
            </Button>
          )}
        </Box>
      </Hidden>

      <Paper className={styles.paper}>
        <Hidden only={['xs', 'md', 'xl', 'lg']}>
          <Avatar src={REACT_APP_AWSS3_URL_IMAGE_BEG + talent?.image} className={styles.avatarMd}>
            {talent?.firstName?.[0]} {talent?.lastName?.[0]}
          </Avatar>
        </Hidden>
        <Box className={styles.insidePaper}>
          {!isAdmin && (
            <Box mb={2}>
              <Box className={styles.nameAndPhotoContainer}>
                <Hidden smUp>
                  <Avatar
                    className={styles.photo}
                    src={REACT_APP_AWSS3_URL_IMAGE_BEG + talent?.image}
                    alt={`${talent?.firstName} ${talent?.lastName}`}
                  >
                    {talent?.firstName?.[0]} {talent?.lastName?.[0]}
                  </Avatar>
                </Hidden>
                <Box display="flex" alignItems="center">
                  <Typography className={styles.name}>
                    {talent?.firstName} {talent?.lastName}
                  </Typography>
                  {fromBrand && (
                    <Hidden xsDown>
                      <IconButton data-type="favourite" className={styles.favouriteBtn} onClick={handleClick}>
                        {talent.isFavourite ? <Favorite className={styles.favourite} /> : <FavoriteBorder className={styles.favourite} />}
                      </IconButton>
                    </Hidden>
                  )}
                </Box>
                <Hidden xsDown>
                  {!fromBrand && (
                    <Button variant="contained" color="primary" onClick={linkTo(routes.TALENT_PUBLIC_INFO)} disabled={userRole === 'agent'}>
                      {intl('TALENT.PUBLIC_PAGE.PROFILE_TOP.editPublicInfo')}
                    </Button>
                  )}
                </Hidden>
              </Box>
              <Box className={styles.badges}>
                {!isAdmin && !fromBrand && <Badge text={profileBadgeText} />}
                <Badge text={talent?.verificationStatus ?? VerificationRequestStatusesEnum.NotVerified} />
                {talent && talent?.competition !== null && (
                  <Typography className={styles.competitionStatus} component="span">
                    {intl(`SHARED.${talent?.competition ? 'competitive' : 'retired'}`)}
                  </Typography>
                )}
              </Box>
            </Box>
          )}
          {!emptyProfile && (
            <Hidden xsDown>
              <Divider />
            </Hidden>
          )}
          {fromBrand && (
            <Hidden smUp>
              <Button className={styles.btnToggleFavorite} variant="outlined" fullWidth onClick={handleClick}>
                {talent.isFavourite ? intl('BRAND.TALENTS_LIST.removeFromFavourite') : intl('BRAND.TALENTS_LIST.addToFavourite')}
              </Button>
            </Hidden>
          )}

          {!emptyProfile && (
            <Grid
              container
              className={clsx({
                [styles.mainInfo]: !emptyProfile,
                [styles.adminMain]: isAdmin,
                [styles.borderedBottom]: !emptySocials,
              })}
            >
              <Grid item xs={12} md={6}>
                {(talent?.sport?.name || !fromBrand) && (
                  <Box className={clsx(styles.typoWithIcon, styles.iconTypoFirst)}>
                    <EmojiEvents className={styles.icon} />
                    <Box>
                      <Typography>{talent?.sport?.name || intl('SHARED.notFilledYet')}</Typography>
                    </Box>
                  </Box>
                )}

                {talent?.club?.length > 0 && (
                  <Box className={clsx(styles.typoWithIcon, styles.iconTypoFirst)}>
                    <People className={styles.icon} />

                    <Box>
                      {talent?.club?.map((clubName) => (
                        <Typography key={clubName}>{clubName}</Typography>
                      ))}
                    </Box>
                  </Box>
                )}
                {(!fromBrand || talent?.country?.name || talent?.city?.name) && (
                  <Box className={clsx(styles.typoWithIcon, styles.iconTypoFirst)}>
                    <Typography>
                      <LocationOn className={styles.icon} />
                      {talent?.country?.name ? `${talent?.country?.name || ''}, ${talent?.city?.name || ''}` : intl('SHARED.notFilledYet')}
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className={clsx(styles.typoWithIcon, styles.iconTypoFirst)}>
                  {((!genderHidden && talent?.gender) || !fromBrand) && (
                    <>
                      {talent?.gender === 'male' ? <Face className={styles.icon} /> : <FemaleFace className={styles.icon} />}
                      {talent?.gender ? talent?.gender && intl(`SHARED.${talent?.gender}`) : intl('SHARED.notFilledYet')}
                    </>
                  )}
                </Typography>
                {!ageHidden && (
                  <Typography className={styles.typoWithIcon}>
                    <AgeIcon className={styles.icon} />
                    {isValid(talent?.birth)
                      ? `${differenceInYears(Date.now(), new Date(talent?.birth))} ${intl('TALENT.PUBLIC_PAGE.yearsOld')}`
                      : intl('SHARED.notFilledYet')}
                  </Typography>
                )}
              </Grid>
            </Grid>
          )}
          <SocialList talent={talent} onlyConfirmed={userRole !== 'talent' && userRole !== 'agent' && userRole !== 'admin'} />
        </Box>
      </Paper>
    </Box>
  );
};

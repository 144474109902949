/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { TableRow, TableCell, Paper, Avatar, Box, Typography, Button } from '@material-ui/core';
import clsx from 'clsx';

import { SearchFilter } from 'assets/svg-js';
import { roundTo2, intl } from 'helpers';
import { useLink } from 'hooks/linkTo';
import { routes } from 'router/routesList';
import { GET_BRANDS_LIST } from 'services/graphql/admin';
import { useInviteUser } from '../hooks';
import { getStatus } from 'helpers/admin';

import { TableWithPagination } from 'components/TableWithPagination';
import { AdminSearchFilters } from 'components/AdminSearchFilters';
import { BrandFilters } from 'components/BrandFilters';
import { TableLoader } from 'components/TableLoader';
import { InvitationModal } from 'components/Modals/InvitationModal';

import { useStyles } from './styles';

const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env;

const thReal = [
  { name: 'ADMIN.BRANDS.logo', minWidth: 72, width: 92 },
  { name: 'ADMIN.BRANDS.brandName', minWidth: 104, width: 286, sortField: 'companyName' },
  { name: 'ADMIN.BRANDS.country', minWidth: 115, width: 185, sortField: 'countryId' },
  { name: 'ADMIN.BRANDS.city', minWidth: 115, width: 233, sortField: 'cityId' },
  { name: 'ADMIN.BRANDS.industry', minWidth: 144, width: 261, sortField: 'industryId' },
  { name: 'ADMIN.BRANDS.brandManager', minWidth: 103, width: 185 },
  { name: 'ADMIN.BRANDS.status', minWidth: 111, width: 111, sortField: 'verificationStatus' },
  { name: 'ADMIN.BRANDS.registrationDate', minWidth: 143, width: 230, sortField: 'createdAt' },
  { name: 'ADMIN.BRANDS.rating', minWidth: 104, width: 133, align: 'right', sortField: 'feedbacks' },
];

export const Brands = () => {
  const styles = useStyles({});
  const linkTo = useLink();
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(thReal[2].sortField);
  const { isInvitationModal, invitationResponseError, handleInvitationModal, submitInvitation } = useInviteUser('brand');

  const createSortHandler = (sortField) => () => {
    setOrderBy(sortField);
    setOrder((current) => (current === 'asc' ? 'desc' : 'asc'));
  };

  const { data, loading } = useQuery(GET_BRANDS_LIST, {
    onCompleted({ adminListBrands }) {
      setDataToDisplay(adminListBrands?.items || []);
    },
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        offset: rowsPerPage * page,
        limit: rowsPerPage,
        options: {
          industryId: filters.industry?.length ? filters.industry?.map((industry) => industry.id) : undefined,
          cityId: filters.city?.length ? filters.city?.map((city) => city.id) : undefined,
          countryId: filters.country?.length ? filters.country?.map((country) => country.id) : undefined,
          feedbacks: filters?.rating && Object.values(filters?.rating).some((k) => !!k) ? filters?.rating : undefined,
          datePeriod: filters?.filterDate && Object.values(filters?.filterDate).some((k) => !!k) ? filters?.filterDate : undefined,
        },
        sort: {
          field: orderBy,
          order,
        },
      },
      search,
    },
  });

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setPage(Math.floor((rowsPerPage / e.target.value) * page));
    setRowsPerPage(e.target.value);
  };

  const handleApply = (values) => {
    setPage(0);
    setFilters(values);
  };

  const filtersActive =
    filters.industry?.map((industry) => industry.id)?.length ||
    filters.city?.map((city) => city.id)?.length ||
    filters.country?.map((country) => country.id)?.length ||
    filters.rating?.from ||
    filters.rating?.to;

  useEffect(() => {
    setPage(0);
  }, [search]);

  const emptyTable = !dataToDisplay.length || loading;

  return (
    <Paper className={styles.paper}>
      <TableWithPagination
        tableLabel="Brands"
        thCells={thReal}
        emptyHead={emptyTable}
        className={clsx({
          [styles.fullHeight]: emptyTable,
        })}
        toolBarRightComponent={
          <AdminSearchFilters
            handleApply={handleApply}
            filterComponent={BrandFilters}
            onSearchChange={setSearch}
            filters={filters}
            availableFilters={data?.adminListBrands?.filters}
            filtersActive={filtersActive}
            saveToCSVLink={data?.adminListBrands?.listBrandsUrl}
          />
        }
        paginationProps={{
          count: data?.adminListBrands?.meta?.total,
          page,
          rowsPerPage,
          handleChangePage,
          handleChangeRowsPerPage,
        }}
        createSortHandler={createSortHandler}
        order={order}
        orderBy={orderBy}
      >
        {loading ? (
          <TableLoader columnCount={thReal.length} />
        ) : dataToDisplay.length ? (
          dataToDisplay.map((row) => (
            <TableRow className={styles.row} hover onClick={linkTo(`/${routes.ADMIN}/${routes.BRANDS}/${row.id}/company-info`)} key={row?.id}>
              <TableCell className={styles.tCell}>
                <Avatar className={styles.avatar} src={REACT_APP_AWSS3_URL_IMAGE_BEG + row.image}>
                  {row?.companyName?.[0]}
                </Avatar>
              </TableCell>
              <TableCell className={styles.tCell}>{row.companyName || '-'}</TableCell>
              <TableCell className={styles.tCell}>{row.country?.name || '-'}</TableCell>
              <TableCell className={styles.tCell}>{row.city?.name || '-'}</TableCell>
              <TableCell className={styles.tCell}>{row.industry?.name || '-'}</TableCell>
              <TableCell className={styles.tCell}>
                <Link onClick={(e) => e.stopPropagation()} to={`/${routes.ADMIN}/${routes.BRAND_MANAGERS}/${row?.manager?.[0]?.id}`}>
                  {row?.manager?.[0]?.firstName} {row?.manager?.[0]?.lastName}
                  {!row?.manager?.[0]?.lastName && !row?.manager?.[0]?.firstName && '-'}
                </Link>
              </TableCell>
              <TableCell className={styles.tCell}>{getStatus(row.verificationStatus) || '-'}</TableCell>
              <TableCell className={styles.tCell}>{format(new Date(row.createdAt), 'dd.MM.yyyy') || '-'}</TableCell>

              <TableCell className={styles.tCell} align="right">
                {roundTo2(row?.averageFeedback) ?? '-'}
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <Box className={styles.emptyBox}>
              <SearchFilter />
              <Typography className={styles.emptyText}>{intl('EMPTY_STATE.noResults')}</Typography>
            </Box>
          </TableRow>
        )}
      </TableWithPagination>
      <Button className={styles.inviteBtn} color="primary" variant="contained" onClick={handleInvitationModal}>
        {intl('ADMIN.TALENTS.inviteUser')}
      </Button>
      <InvitationModal
        isOpen={isInvitationModal}
        handleClose={handleInvitationModal}
        title={intl('INVITATION_MODAL.user')}
        bodyText={intl('INVITATION_MODAL.provideUser')}
        label={intl('INVITATION_MODAL.labelUser')}
        submit={submitInvitation}
        responseError={invitationResponseError}
      />
    </Paper>
  );
};

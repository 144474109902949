import { makeStyles } from '@material-ui/core';
import { colors } from 'theme/colors';

export const useStyles = makeStyles(() => ({
  box: {
    width: 120,
    height: 120,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    background: colors.BACKGROUND,
    borderRadius: 4,
    cursor: 'pointer',
  },
  img: {
    width: 34,
    height: 34,
    fill: '#535E83',
  },
  txtAttach: {
    marginTop: 20,
    textTransform: 'uppercase',
    fontWeight: 500,
    letterSpacing: '0.03em',
  },
}));

import { gql } from '@apollo/client';

const GET_CURRENT_TALENT = gql`
  query GetCurrentTalent {
    getCurrentTalent {
      id
      firstName
      lastName
      sport {
        id
        name
      }
      country {
        id
        name
      }
      city {
        id
        name
      }
      phone
      competition
      club
      tags
      about
      gender
      isPrivate
      image
      hasWire
      hasStripe
      social {
        impressionsCount
        accountId
        providerName
        accountName
        confirmed
      }
      videos {
        type
        id
        link
      }
      payout {
        wire {
          iban
          accountName
        }
        stripe {
          accountId
        }
      }
      feedbacks {
        id
        rate
        opportunityId
        description
        createdAt
        author {
          id
          name
          image
        }
      }
      agent {
        id
        email
        firstName
        lastName
        #        createdAt
        verificationStatus
      }
      birth
      fieldsVisibility {
        fieldName
        isVisible
      }
      verificationStatus
    }
  }
`;

const GET_TALENT_VERIFICATION_STATUS = gql`
  query GetCurrentTalent {
    getCurrentTalent {
      verificationStatus
    }
  }
`;

const UPDATE_TALENT_PROFILE = gql`
  mutation UpdateTalentProfile(
    $firstName: String
    $lastName: String
    $sportId: ID
    $cityId: ID
    $competition: Boolean
    $club: [String]
    $tags: [String]
    $about: String
    $gender: GenderEnum
    $image: String
    $isPrivate: Boolean
    $birth: Float
    $fieldsVisibility: [FieldVisibilityInput]
  ) {
    updateTalentProfile(
      data: {
        firstName: $firstName
        lastName: $lastName
        sportId: $sportId
        cityId: $cityId
        competition: $competition
        club: $club
        tags: $tags
        about: $about
        gender: $gender
        image: $image
        isPrivate: $isPrivate
        birth: $birth
        fieldsVisibility: $fieldsVisibility
      }
    ) {
      id
      firstName
      lastName
      sport {
        id
        name
      }
      country {
        id
        name
      }
      city {
        id
        name
      }
      phone
      competition
      club
      tags
      about
      gender
      isPrivate
      image
      social {
        impressionsCount
        accountId
        providerName
        accountName
        confirmed
      }
      videos {
        type
        link
        id
      }
      payout {
        wire {
          iban
          accountName
        }
        stripe {
          accountId
        }
      }
      feedbacks {
        id
        rate
        opportunityId
        description
        createdAt
        author {
          id
          name
          image
        }
      }
      birth
      fieldsVisibility {
        fieldName
        isVisible
      }
    }
  }
`;
const UPDATE_TALENT_PRIVATE_PROFILE = gql`
  mutation UpdateTalentPrivateProfile($phone: String, $payout: TalentPayoutInput) {
    updateTalentPrivateProfile(data: { phone: $phone, payout: $payout }) {
      id
      firstName
      lastName
      sport {
        id
        name
      }
      country {
        id
        name
      }
      city {
        id
        name
      }
      phone
      competition
      club
      tags
      about
      gender
      image
      social {
        impressionsCount
        accountId
        providerName
        accountName
        confirmed
      }
      videos {
        type
        link
        id
      }
      payout {
        wire {
          iban
          accountName
        }
        stripe {
          accountId
        }
      }
      feedbacks {
        id
        rate
        opportunityId
        description
        createdAt
        author {
          id
          name
          image
        }
      }
    }
  }
`;

const UPDATE_VIDEO_TALENT = gql`
  mutation UploadVideoTalent($type: VideoTypeEnum!, $link: String!) {
    uploadVideoTalent(data: { type: $type, link: $link }) {
      link
      type
      id
    }
  }
`;

const DELETE_VIDEO_TALENT = gql`
  mutation DeleteVideoTalent($videoId: ID!) {
    deleteVideoTalent(videoId: $videoId) {
      id
      link
      type
    }
  }
`;

const GET_TALENT_BY_ID = gql`
  query GetTalentProfile($talentId: ID!) {
    getTalentProfile(talentId: $talentId) {
      id
      firstName
      lastName
      createdAt
      birth
      sport {
        id
        name
      }
      country {
        id
        name
      }
      city {
        id
        name
      }
      fieldsVisibility {
        fieldName
        isVisible
      }
      phone
      competition
      club
      tags
      about
      gender
      image
      email
      social {
        impressionsCount
        accountId
        providerName
        accountName
        confirmed
      }
      videos {
        type
        link
      }
      payout {
        wire {
          iban
          accountName
        }
        stripe {
          accountId
        }
      }
      verificationStatus
      isPrivate
      feedbacks {
        id
        rate
        opportunityId
        description
        createdAt
        author {
          id
          name
          image
        }
      }
    }
  }
`;
const GET_TALENT_BY_ID_FROM_BRAND = gql`
  query GetTalentProfile($talentId: ID!) {
    getTalentProfile(talentId: $talentId) {
      id
      createdAt
      firstName
      lastName
      verificationStatus
      sport {
        id
        name
      }
      country {
        id
        name
      }
      city {
        id
        name
      }
      phone
      competition
      club
      tags
      about
      gender
      image
      birth
      social {
        impressionsCount
        accountId
        providerName
        accountName
        confirmed
      }
      videos {
        type
        link
      }
      fieldsVisibility {
        fieldName
        isVisible
      }
      feedbacks {
        id
        rate
        opportunityId
        description
        createdAt
        author {
          id
          name
          image
        }
      }
      isFavourite
    }
  }
`;
const UPDATE_LINKED_ACCOUNT = gql`
  mutation UpdateLinkedAccount($provider: SocialMediaEnum!, $payload: String!) {
    updateLinkedAccount(data: { provider: $provider, payload: $payload }) {
      count
      accountName
    }
  }
`;

const UPDATE_STRIPE_CONNECT_ACCOUNT = gql`
  mutation UpdateStripeConnectAccount($payload: String!) {
    updateStripeConnectAccount(data: { payload: $payload }) {
      clientId
    }
  }
`;

const GET_TWITTER_REQUEST_TOKEN = gql`
  query getTwitterRequestToken {
    getTwitterRequestToken {
      oauthToken
    }
  }
`;

const INVITE_AGENT = gql`
  mutation InviteAgent($email: AWSEmail!) {
    inviteAgent(email: $email)
  }
`;

const DELETE_AGENT = gql`
  mutation DeleteAgent($agentId: ID!) {
    deleteAgent(agentId: $agentId)
  }
`;

const GET_TALENT_ANALYTICS = gql`
  query TalentAnalytic($filter: DateFilter, $pagination: PaginationFilter, $tableType: TalentAnalyticBrandsTableEnum) {
    getTalentAnalytic(filter: $filter, pagination: $pagination, tableType: $tableType) {
      revenueDoneOpportunities
      revenueCanceledOpportunities
      revenueAveragePerOpportunity
      brandsWorkedForCount
      brandsWorkedFor {
        brandId
        brandName
        brandImage
        opportunityCompleteCount
      }
      agreementsSentCount
      brandsAgreementSent {
        brandId
        brandName
        brandImage
        opportunityId
        opportunityName
      }
      profileViewsCount
      brandsTalentProfileViewed {
        brandId
        brandName
        brandImage
        dateProfileView
      }
    }
  }
`;

const TALENTS_BRAND_LIST = gql`
  query talentListBrands($filter: BrandsAdminFilter, $search: String) {
    talentListBrands(filter: $filter, search: $search) {
      items {
        id
        createdAt
        image
        companyName
        isFavourite
        country {
          name
          id
        }
        city {
          id
          name
        }
        industry {
          id
          name
        }
      }
      meta {
        total
      }
      filters {
        cities
        countries
        industries
      }
    }
  }
`;

const UPDATE_INSTAGRAM_ACCOUNT = gql`
  mutation updateInstagramAccount($data: UpdateInstagramAccountInput!, $talentId: ID) {
    updateInstagramAccount(data: $data, talentId: $talentId) {
      count
      confirmed
      accountName
    }
  }
`;

const GET_NEWLY_REGISTERED_BRANDS = gql`
  query listNewlyBrands($pagination: PaginationFilter) {
    listNewlyBrands(pagination: $pagination) {
      items {
        id
        name
        image
        registeredAt
      }
      meta {
        total
      }
    }
  }
`;

export {
  GET_CURRENT_TALENT,
  GET_NEWLY_REGISTERED_BRANDS,
  GET_TALENT_BY_ID,
  UPDATE_TALENT_PROFILE,
  UPDATE_TALENT_PRIVATE_PROFILE,
  UPDATE_VIDEO_TALENT,
  DELETE_VIDEO_TALENT,
  GET_TALENT_BY_ID_FROM_BRAND,
  UPDATE_LINKED_ACCOUNT,
  UPDATE_STRIPE_CONNECT_ACCOUNT,
  GET_TWITTER_REQUEST_TOKEN,
  INVITE_AGENT,
  GET_TALENT_ANALYTICS,
  DELETE_AGENT,
  TALENTS_BRAND_LIST,
  GET_TALENT_VERIFICATION_STATUS,
  UPDATE_INSTAGRAM_ACCOUNT,
};

import { gql } from '@apollo/client'

const GET_GENERAL_ANALYTIC = gql`
  query GetPlatformGeneralAnalytic($filter: DateFilter) {
    getPlatformGeneralAnalytic(filter: $filter) {
      createdOpportunities
      openOpportunities
      confirmedOpportunities
      canceledByBrandOpportunities
      canceledByTalentOpportunities
      paymentOpportunities
      doneOpportunities
      applicantsPerOpportunities
    }
  }
`

const ADMIN_GET_TALENT_ANALYTIC = gql`
  query AdminGetTalentAnalytic($talentId: ID!) {
    adminGetTalentAnalytic(talentId: $talentId) {
      applied
      declined
      openOpportunities
      confirmedOpportunities
      canceledByBrandOpportunities
      canceledByTalentOpportunities
      paymentOpportunities
      doneOpportunities
    }
  }
`

const ADMIN_GET_BRAND_ANALYTIC = gql`
  query AdminGetBrandAnalytic($brandId: ID!) {
    adminGetBrandAnalytic(brandId: $brandId) {
      postedOpportunities
      totalApplicants
      acceptedApplicants
      declinedApplicants
      canceledByBrandOpportunities
      canceledByTalentOpportunities
    }
  }
`

const GET_BRAND_ANALYTIC = gql`
  query GetBrandAnalytic {
    getBrandAnalytic {
      postedOpportunities
      totalApplicants
      acceptedApplicants
      declinedApplicants
      canceledByBrandOpportunities
      canceledByTalentOpportunities
    }
  }
`

export {
  GET_GENERAL_ANALYTIC,
  ADMIN_GET_TALENT_ANALYTIC,
  ADMIN_GET_BRAND_ANALYTIC,
  GET_BRAND_ANALYTIC,
}

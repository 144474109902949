import { makeStyles } from '@material-ui/core'
import { colors } from '../../theme/colors'

export const useStyles = makeStyles(() => ({
  videoPlaceholder: {
    padding: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 4,
    background: colors.BACKGROUND,
    maxWidth: 316,
    height: 186,
    width: '100%',
  },
  btn: {
    '&:first-child': {
      marginBottom: 16,
    },
  },
}))

import { makeStyles } from '@material-ui/core/styles';

import { colors } from 'theme/colors';

export const useStyles = makeStyles((theme) => ({
  comingSoonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'relative',
    margin: '0 auto',
  },
  muteBtnContainer: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    backgroundColor: colors.GRAY,
    borderRadius: 4,
  },
  muteBtn: {
    borderRadius: 4,
    color: colors.BACKGROUND,
  },
  logoWrapper: {
    margin: theme.spacing(3.5, 'auto'),
  },
  videoContainer: {
    position: 'relative',
  },
  title: {
    marginTop: 40,
  },
  black: {
    color: colors.BLACK,
  },
  subtitle: {
    marginTop: theme.spacing(2),
  },
  emailForm: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    maxWidth: 400,
    width: '60%',
    minWidth: 300,
    margin: theme.spacing(5, 'auto'),
  },
  centered: {
    justifyContent: 'center',
    '& > :first-child': {
      marginRight: theme.spacing(1),
    },
  },
  emailInput: {
    color: colors.BLACK,
    flexGrow: 1,
  },
  sendBtn: {
    marginLeft: theme.spacing(3),
    flexBasis: 80,
  },
}));

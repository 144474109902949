import React from 'react'
import { Chip, TextField, IconButton } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { ClearRounded } from '@material-ui/icons'
import { useStyles } from './styles'
import { intl } from '../../helpers'

const MultiChipTextField = ({
  onChange,
  name,
  value = [],
  options = [],
  loading,
  label,
  className,
  classNameTextField,
}) => {
  const classes = useStyles()

  const chipRenderer = (val, getTagProps) => val.map((option, index) => (
    <Chip
      {...getTagProps({ index })}
      className={classes.chip}
      key={option.id}
      label={option.name}
      deleteIcon={
        <IconButton classes={{ label: classes.deleteIconLabel }} className={classes.deleteIcon}>
          <ClearRounded className={classes.icon} />
        </IconButton>
        }
    />
  ))

  const autocompleteProps = {
    name,
    value,
    options,
    loading,
    className,
    onChange,
  }

  return (
    <Autocomplete
      multiple
      getOptionLabel={(option) => option.name || ''}
      getOptionSelected={(option, val) => option.id === val.id}
      renderTags={chipRenderer}
      renderInput={(params) => (
        <TextField label={intl(label)} {...params} name={name} className={classNameTextField} />
      )}
      {...autocompleteProps}
    />
  )
}

export default MultiChipTextField

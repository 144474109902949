import type { Redemption, City, Country, OpportunityBrand, OpportunityType } from './opportunity';

export enum NewsFeedCollectionEnum {
  general = 'general',
  my_activity_feed = 'my_activity_feed',
  news_feed_from_favourites = 'news_feed_from_favourites',
}

export enum PostTypeEnum {}

export type FeedMeta = {
  likesCounter: number;
  sharesCounter: number;
  __typename: string;
};

export type PostAuthor = {
  authorId: string;
  authorType: PostTypeEnum;
  authorName: string;
  imageSrc: string;
  firstLetters: string;
  __typename: string;
};

export type PostFeed = {
  id: string;
  description: string;
  createdAt: number;
  canInteract: boolean;
  images: Array<string>;
  author: PostAuthor;
  feedMeta: FeedMeta;
  isLiked: boolean;
  __typename: string;
};

export type OpportunityFeed = {
  id: string;
  name: string;
  description: string;
  type: OpportunityType;
  startDate: number;
  endDate: number;
  country: Country;
  city: City;
  redemption: Redemption;
  brand: OpportunityBrand;
  createdAt: number;
  images: Array<string> | null;
  feedMeta: FeedMeta;
  isLiked: boolean;
  canInteract: boolean;
  __typename: string;
};

export type ActivityPost = PostFeed | OpportunityFeed;

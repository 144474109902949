import React from 'react';
import { Select as MaterialSelect, MenuItem, FormControl, InputLabel, FormHelperText } from '@material-ui/core';
import { nanoid } from 'nanoid';

export const Select = ({ options = [], label, containerProps, helperText, ...props }) => (
  <FormControl {...containerProps}>
    <InputLabel>{label}</InputLabel>
    <MaterialSelect {...props}>
      {options.map(({ name, value }) => (
        <MenuItem key={nanoid()} value={value}>
          {name}
        </MenuItem>
      ))}
    </MaterialSelect>
    <FormHelperText>{helperText}</FormHelperText>
  </FormControl>
);

import { useContext } from 'react';

import { AppContext } from 'hooks/app';
import { useAuth } from 'hooks/auth';

export const useIntroModal = () => {
  const {
    state: { isShowModal },
    dispatch,
  } = useContext(AppContext);
  const { getAssumedRole } = useAuth();
  const role = getAssumedRole();
  // eslint-disable-next-line
  const videoLink = `https://sport-endorse-dev.s3-eu-west-1.amazonaws.com/public/onboarding/${role}/intro.mp4`;
  const handleOpenModal = () => dispatch({ type: 'INTRO_MODAL', payload: true });
  const handleCloseModal = () => dispatch({ type: 'INTRO_MODAL', payload: false });

  return {
    isShowModal,
    videoLink,
    handleOpenModal,
    handleCloseModal,
  };
};

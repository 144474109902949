import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from 'theme/colors';

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: (props: any) => (props.errorImage ? 'rgba(255, 81, 81, 0.7)' : colors.PRIMARY),
    borderRadius: '100%',
    width: 176,
    height: 176,
    backgroundSize: 'cover',
    position: 'relative',
  },
  errorBox: {
    backgroundColor: 'rgba(255, 81, 81, 0.7)!important',
  },
  errorButton: {
    fill: 'rgba(255, 81, 81, 0.7)!important',
  },
  placeholderText: {
    textAlign: 'center',
    color: colors.WHITE,
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: '0.03em',
  },
  bigText: {
    fontSize: 72,
  },
  btnAdd: {
    maxWidth: 40,
    height: 40,
    borderRadius: '50%',
    position: 'absolute',
    right: 15,
    bottom: 0,
    backgroundColor: colors.WHITE,
  },
  addIcon: {
    fill: colors.PRIMARY,
  },
}));

export { useStyles };

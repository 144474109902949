import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';

import { VerificationRequestStatusesEnum } from 'types/talent';

import { useStyles } from './styles';
import type { Props, BackgroundColor } from './types';
import { PUBLIC_STATUS } from '../../constants/userPublicStatuses';
import { PROFILE_OPTIONS } from '../../constants/userProfileStatuses';

const Badge: FC<Props> = ({ text, className }) => {
  const styles = useStyles();

  let statusText: string;
  let color: BackgroundColor = 'gray';

  switch (text) {
    case VerificationRequestStatusesEnum.Rejected: {
      statusText = PROFILE_OPTIONS[1].label;
      color = 'red';
      break;
    }
    case VerificationRequestStatusesEnum.Verified: {
      statusText = PROFILE_OPTIONS[0].label;
      color = 'green';
      break;
    }
    case VerificationRequestStatusesEnum.Pending: {
      statusText = PROFILE_OPTIONS[3].label;
      color = 'gray';
      break;
    }
    case VerificationRequestStatusesEnum.NotVerified: {
      statusText = PROFILE_OPTIONS[2].label;
      color = 'gray';
      break;
    }
    case PUBLIC_STATUS.private: {
      statusText = PUBLIC_STATUS.private;
      color = 'red';
      break;
    }
    case PUBLIC_STATUS.visible: {
      statusText = PUBLIC_STATUS.visible;
      color = 'green';
      break;
    }
    default: {
      statusText = PROFILE_OPTIONS[1].label;
    }
  }

  return (
    <Typography
      variant="caption"
      className={clsx(
        styles.badge,
        {
          [styles.greenBackground]: color === 'green',
          [styles.grayBackground]: color === 'gray',
          [styles.redBackground]: color === 'red',
        },
        className,
      )}
    >
      {statusText}
    </Typography>
  );
};

export { Badge };

import { makeStyles } from '@material-ui/core'
import { colors } from '../../theme/colors'

export const useStyles = makeStyles((theme) => ({
  imgPlaceholder: {
    width: 32,
    height: 32,
    background: theme.palette.background.default,
    borderRadius: '50%',
    marginLeft: 8,
  },
  profileBar: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  avatar: {
    color: colors.WHITE,
    backgroundColor: colors.PRIMARY,
    textTransform: 'uppercase',
    marginLeft: 8,
  },
}))

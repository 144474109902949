import { makeStyles } from '@material-ui/core'
import { colors } from 'theme/colors'

export const useStyles = makeStyles(() => ({
  listItem: {
    cursor: 'pointer',
    '&:hover': {
      background: colors.BACKGROUND4,
    },
  },
  selected: {
    background: colors.BACKGROUND4,
    borderLeft: `2px solid ${colors.PRIMARY_FOCUSED}`,
  },
  listItemText: {
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '-0.0241em',
    color: colors.TEXT,
  },
  listItemTextWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  badge: {
    width: 23,
    height: 23,
    border: '2px solid',
    backgroundColor: colors.ERROR,
    borderRadius: '100%',
  },
  checkIcon: {
    width: 15,
    height: 15,
    fill: colors.PRIMARY_INPUT_HOVERED,
  },
}))

import React from 'react';
import { Box, Avatar, Typography } from '@material-ui/core';
import { isSameDay, format } from 'date-fns';
import clsx from 'clsx';

import { intl, getLetterForAvatar } from 'helpers';

import { useStyles } from './styles';
import PlainMessage from '../PlainMessage';
import OfferMessage from '../OfferMessage';

const Message = ({ clientID, role, message, handleUpdateOffer, offerUpdating, opportunityStatus, isTalentValid, toggleTalentModal }) => {
  const classes = useStyles();

  const { REACT_APP_AWSS3_URL_IMAGE_BEG } = process.env;

  const clientsMessage = clientID !== message.sender.id;

  const formattedCreatedAt = (date) => {
    const inputDate = new Date(date);
    if (isSameDay(inputDate, new Date())) {
      return `${intl('SHARED.today')} ${format(inputDate, 'h:mm a')}`;
    }
    return format(inputDate, 'E, MMM d h:mm a ');
  };

  const plainMessageProps = {
    message,
    clientsMessage,
  };

  const offerMessageProps = {
    message,
    clientsMessage,
    role,
    handleUpdateOffer,
    offerUpdating,
    opportunityStatus,
    isTalentValid,
    toggleTalentModal,
  };

  if (!message.coverLetter && message.body === 'undefined' && message.action !== 'GET_OFFER') {
    return <></>;
  }

  return (
    <Box
      className={clsx({
        [classes.container]: true,
        [classes.reversedContainer]: !clientsMessage,
      })}
    >
      <Avatar
        src={`${REACT_APP_AWSS3_URL_IMAGE_BEG}${message.sender.image}`}
        alt={`${message.sender.lastName}'s_avatar`}
        className={clsx({
          [classes.avatar]: true,
          [classes.reversedAvatar]: !clientsMessage,
        })}
      >
        {getLetterForAvatar(message.sender.firstName, message.sender.lastName)}
      </Avatar>
      <Box className={classes.main}>
        <Typography
          variant="caption"
          className={clsx({
            [classes.date]: true,
            [classes.reversedDate]: !clientsMessage,
          })}
        >
          {formattedCreatedAt(message.createdAt)}
        </Typography>
        {message.action === 'GET_OFFER' ? <OfferMessage {...offerMessageProps} /> : <PlainMessage {...plainMessageProps} />}
      </Box>
    </Box>
  );
};

export default Message;

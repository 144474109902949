import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  paper: {
    padding: 30,
    paddingBottom: 22,
    paddingTop: 56,
    display: 'flex',
    flexDirection: 'column',
    width: 460,
    maxWidth: '100%',
    marginTop: 24,
  },
  input: {
    marginBottom: 36,
  },
  send: {
    marginTop: 16,
    marginBottom: 16,
  },
  restoreText: {
    marginTop: 27,
    marginBottom: 24,
    fontWeight: 500,
  },
  text: {
    fontSize: 12,
    marginBottom: 12,
    textAlign: 'center',
  },
}))

import React, { useState, useCallback } from 'react'
import * as Yup from 'yup'

import { Box, Paper, TextField, Button, Typography } from '@material-ui/core'
import { useFormik } from 'formik'

import { useHistory } from 'react-router-dom'
import { useAuth } from '../../../hooks/auth'
import { useStyles } from './styles'
import { intl } from '../../../helpers'

import { SportEndorse } from '../../../components/SportEndorse'
import { LoginIcon } from '../../../assets/svg-js'
import { TextFieldPassword } from '../../../components/TextFieldPassword'

const CreateNewPasswordSchema = Yup.object({
  code: Yup.number().typeError('ERRORS.mustBeANumber').required('ERRORS.emptyField'),
  password: Yup.string()
    .matches(/((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,})/, 'ERRORS.incorrectPasswordFormat')
    .required('ERRORS.emptyField'),
})

export const CreateNewPassword = () => {
  const styles = useStyles({})
  const { forgotPasswordSubmit, forgotPassword } = useAuth()
  const history = useHistory()

  const email = history.location.search.split('?email=')[1]

  const [isPasswordShown, setPasswordShown] = useState(false)
  const [isAfterCodeSent, setIsAfterCodeSent] = useState(false)
  const togglePasswordShown = useCallback(
    () => setPasswordShown((passwordStatus) => !passwordStatus),
    [],
  )

  const handleCodeResend = async () => {
    const { error } = await forgotPassword({
      email,
    })
    if (error) {
      setFieldError('code', 'ERRORS.somethingWentWrong')
      return
    }
    setIsAfterCodeSent(true)
  }

  const handleRestore = async (values, formContext) => {
    const { error } = await forgotPasswordSubmit({
      code: String(values.code),
      password: values.password,
      email,
      redirectUrl: '/',
    })

    if (error?.code === 'CodeMismatchException') {
      formContext.setFieldError('code', 'ERRORS.incorrectCode')
    }
  }

  const { handleChange, values, handleSubmit, errors, setFieldError, touched } = useFormik({
    initialValues: {
      code: '',
      password: '',
    },
    validationSchema: CreateNewPasswordSchema,
    onSubmit: handleRestore,
  })

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      padding="30px"
      paddingX="8px"
      flexDirection="column"
    >
      <SportEndorse />
      <Paper className={styles.paper} component="form" onSubmit={handleSubmit}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          marginBottom="18px"
        >
          <LoginIcon />
          <Typography className={styles.restoreText} variant="h5">
            {intl('CREATE_NEW_PASSWORD.createNewPassword')}
          </Typography>
          <Typography className={styles.text}>
            {intl('CREATE_NEW_PASSWORD.enterTheCode')}
          </Typography>
        </Box>
        <TextField
          name="code"
          label={intl('SHARED.code')}
          className={styles.input}
          value={values.code}
          onChange={handleChange}
          onFocus={() => setIsAfterCodeSent(false)}
          error={errors.code && touched.code}
          helperText={intl(
            isAfterCodeSent
              ? 'CREATE_NEW_PASSWORD.passwordSuccessfullySent'
              : touched.code && errors.code,
          )}
        />
        <TextFieldPassword
          togglePassword={togglePasswordShown}
          showPassword={isPasswordShown}
          label={intl('CREATE_NEW_PASSWORD.newPassword')}
          name="password"
          value={values.password}
          onChange={handleChange}
          className={styles.input}
          error={errors.password && touched.password}
          helperText={intl(touched.password && errors.password)}
        />
        <Button type="submit" variant="contained" color="primary" className={styles.send}>
          {intl('CREATE_NEW_PASSWORD.confirm')}
        </Button>
        <Button onClick={handleCodeResend} variant="outlined">
          {intl('CREATE_NEW_PASSWORD.resendCode')}
        </Button>
      </Paper>
    </Box>
  )
}

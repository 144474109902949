import React, { FC } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';

import { intl } from 'helpers';
import { NotFoundImage } from 'assets/svg-js';

import { useStyles } from './styles';

const ErrorPage: FC<{}> = () => {
  const styles = useStyles();
  const { state } = useLocation() as { state:{ error: string } };

  return (
    <Box className={styles.container}>
      <Box className={styles.infoBox}>
        <Box className={styles.contentBox}>
          <Typography className={styles.text404}>{intl('EMPTY_STATE.oops')}</Typography>
          <Typography className={styles.txtNotExists}>{state?.error ?? intl('EMPTY_STATE.wentWrong')}</Typography>
          <Button color="primary" variant="contained" className={styles.btnHomepage} component={Link} to="/">
            {intl('EMPTY_STATE.goToHomepage')}
          </Button>
        </Box>
      </Box>
      <NotFoundImage className={styles.icon} />
    </Box>
  );
};

export { ErrorPage };

import { makeStyles } from '@material-ui/core'
import { colors } from '../../../theme/colors'

export const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(2),
  },
  paperLoading: {
    minHeight: 200,
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(2),
  },
  row: {
    cursor: 'pointer',
  },
  tCell: {
    padding: '8px 16px',
  },
  avatar: {
    color: colors.WHITE,
    backgroundColor: colors.PRIMARY,
    textTransform: 'uppercase',
    border: 'none',
  },
  toolBar: {
    fontSize: 20,
    fontWeight: 500,
    color: colors.BLACK,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  smallPaper: {
    width: 'fit-content',
    padding: theme.spacing(1.5, 2),
    display: 'flex',
  },
  applicantsPerOpportunity: {
    color: colors.INPUT_LABEL,
    opacity: 0.87,
    fontSize: 12,
  },
  amount: {
    fontSize: theme.spacing(4),
    fontWeight: 500,
    marginLeft: 120,
  },
  datePicker: {
    width: 200,
    '&:first-child': {
      marginRight: theme.spacing(3),
    },
  },
  loader: {
    minHeight: 200,
  },
}))

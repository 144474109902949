import React, { FC, ReactNode } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableCell,
  Toolbar,
  TablePagination,
  TableBody,
  Box,
  TableRow,
  Typography,
  TableSortLabel,
  useMediaQuery,
  Theme,
} from '@material-ui/core';

import { intl } from 'helpers';

import { useStyles } from './styles';

type PaginationProps = {
  count: number;
  rowsPerPage: number;
  page: number;
  handleChangePage: (_: any, newPage) => any;
  handleChangeRowsPerPage: (e: any) => void;
};

type Props = {
  children: ReactNode;
  tableLabel: string;
  toolBarRightComponent: JSX.Element;
  thCells: Array<any>;
  paginationProps: PaginationProps;
  createSortHandler: any;
  className: string;
  order: 'asc' | 'desc';
  orderBy: string;
  emptyHead: boolean;
};

const thCells2 = [
  { name: 'OPPORTUNITIES.opportunityName' },
  { name: 'OPPORTUNITIES.talent' },
  { name: 'OPPORTUNITIES.startDate' },
  { name: 'OPPORTUNITIES.endDate' },
  { name: 'OPPORTUNITIES.budget' },
  { name: 'OPPORTUNITIES.status', align: 'right' },
];

export const TableWithPagination: FC<Props> = ({
  children,
  tableLabel = intl('components.TABLE_WITH_PAGINATION.title'),
  toolBarRightComponent = <></>,
  thCells = thCells2,
  paginationProps = {},
  createSortHandler = () => {},
  className = '',
  order,
  orderBy,
  emptyHead = false,
}) => {
  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const styles = useStyles({});

  if (emptyHead) thCells = [];

  const headCellRender = (sortField, name) =>
    sortField ? (
      <TableSortLabel
        className={styles.headCell}
        active={orderBy === sortField}
        direction={orderBy === sortField ? order : 'asc'}
        onClick={createSortHandler(sortField)}
      >
        {intl(name)}
      </TableSortLabel>
    ) : (
      intl(name)
    );

  return (
    <Box className={styles.tablePaginationWrapper}>
      <Toolbar className={styles.toolBar}>
        <Typography className={styles.label}>{tableLabel}</Typography>
        {toolBarRightComponent}
      </Toolbar>
      <TableContainer className={className}>
        <Table className={styles.fullHeight} size={isXs ? 'small' : 'medium'}>
          <TableHead>
            <TableRow>
              {thCells.map((cell) => (
                <TableCell key={cell.name} align={cell.align} style={{ minWidth: cell.minWidth, width: cell.width }}>
                  {headCellRender(cell.sortField, cell.name)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={styles.fullHeight}>{children}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={isXs ? undefined : [10, 15, 20]}
        component="div"
        count={paginationProps.count || 0}
        rowsPerPage={paginationProps.rowsPerPage || 0}
        page={paginationProps.page || 0}
        onChangePage={paginationProps.handleChangePage}
        onChangeRowsPerPage={paginationProps.handleChangeRowsPerPage}
      />
    </Box>
  );
};
